import { ListItemIcon, ListItemText, Stack } from '@mui/material';
import { RoleAssignmentFragmentFragment } from 'gql/index';
import React from 'react';
import { UserAvatar } from '../../../components/UserAvatar/UserAvatar';
import { AllUsersPrincipal, SecurityGroupPrincipal, UserPrincipal } from './types';
import { useIntl } from 'react-intl';


interface Props {
  roleAssignment: RoleAssignmentFragmentFragment;
}

function isUser(obj: unknown): obj is UserPrincipal {
  return typeof obj === 'object' && !!obj && 'email' in obj;
}

function isSecurityGroup(obj: unknown): obj is SecurityGroupPrincipal {
  return typeof obj === 'object' && !!obj && 'name' in obj;
}

function isAllUsers(obj: unknown): obj is AllUsersPrincipal {
  return typeof obj === 'boolean' && !!obj && obj;
}

export const PrincipalItemContent: React.FC<Props> = ({ roleAssignment }) => {
  const { formatMessage } = useIntl();
  const principal = roleAssignment.sharedWithAllProjectMembers || (roleAssignment.user ?? roleAssignment.securityGroup);

  const getSecondaryText = () => {

    if (isUser(principal)) {
      if (principal.business && principal.title) {
        return `${principal.business} - ${principal.title}`;
      } else {
        return principal.business ?? principal.title ?? principal.email;
      }
    }
    else if (isSecurityGroup(principal) || isAllUsers(principal)) {
      return undefined;
    }
  };

  const getPrincipalName = () => {

    if (isUser(principal)) {
      return principal.fullName;
    }
    else if (isSecurityGroup(principal)) {
      return principal.name;
    }
    else if (isAllUsers(principal)) {
      return formatMessage({ id: 'All project members' });
    }
  };

  return <Stack direction='row' alignItems={'center'}>
    {!isAllUsers(principal) &&
      <ListItemIcon>
        <UserAvatar displayName={getPrincipalName()} size='large' />
      </ListItemIcon>
    }

    <ListItemText
      primary={getPrincipalName()}
      secondary={getSecondaryText()}
    />
  </Stack >;
};