import { useCallback } from 'react';
import { DomainError } from '../../gql/graphql-fetcher';
import { useNotification } from '../../utils/useNotification';

export const useErrorHandler = () => {
  const { notifyFailure } = useNotification();

  const handleError = useCallback((error: unknown) => {
    if (error instanceof DomainError) {
      notifyFailure(error.message);
    }
  }, [notifyFailure]);

  return { handleError };
};