import { ResponsiveDrawer } from '@components/Drawers';
import { DrawerSection } from '@components/Drawers/DrawerSection';
import { DisableableTooltip } from '@components/Tooltips/DisableableTooltip';
import { useCanEditUser } from '@modules/projects/utils/useCanEditUser';
import { UserDetails } from '@modules/users/components/UserDetails';
import { Edit } from '@mui/icons-material';
import { Button, Divider, DrawerProps, ListItem, ListItemText } from '@mui/material';
import { ApplicationUser, useSecurityGroupForUserInProjectQuery } from 'gql/index';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useCurrentProject } from '../../utils/useCurrentProject';
import { EditMemberSecurityGroupDrawer } from './EditMemberSecurityGroupDrawer';
import { ProjectMemberActions } from './ProjectMemberActions';

interface Props extends Omit<DrawerProps, 'open'> {
  user: Pick<ApplicationUser, 'id' | 'fullName' | 'email' | 'firstName' | 'lastName'>;
  projectId: number;
}

export const MemberInfoDrawer: React.FC<Props> = ({ user, projectId, ...drawerProps }) => {
  const { formatMessage } = useIntl();

  const { canManageProject } = useCurrentProject();
  const [isEditSecurityGroupDrawer, setEditSecurityGroupDrawer] = useState(false);

  const { canEditSecurityGroup } = useCanEditUser(user.id);

  const { data: securityGroup, isFetching } = useSecurityGroupForUserInProjectQuery({ projectId, userId: user.id }, { select: d => d.securityGroupForUserInProject });

  return (
    <ResponsiveDrawer
      open={!!user}
      header={formatMessage({ id: 'User profile' })}
      disablePadding
      isLoading={isFetching}
      actionButtons={!!user && canManageProject && securityGroup && (
        <ProjectMemberActions
          user={user}
          projectId={projectId}
        />
      )}
      {...drawerProps}
    >
      {user && securityGroup && <>
        <UserDetails user={user} />

        <Divider />

        <DrawerSection
          title={formatMessage({ id: 'Permissions' })}
          actionButton={canEditSecurityGroup !== 'doNotShow' && (
            <DisableableTooltip disabled={canEditSecurityGroup === true} title={canEditSecurityGroup}>
              <Button onClick={() => setEditSecurityGroupDrawer(true)} variant='contained' disabled={canEditSecurityGroup !== true} sx={{ minWidth: 'unset', p: 1 }}>
                <Edit />
              </Button>
            </DisableableTooltip>
          )}
        >
          <ListItem disableGutters>
            <ListItemText
              primary={securityGroup?.at(0)?.name}
              secondary={formatMessage({ id: 'Security group' })}
            />
          </ListItem>
        </DrawerSection>
      </>}

      <EditMemberSecurityGroupDrawer userId={user?.id ?? ''} open={isEditSecurityGroupDrawer} onClose={() => setEditSecurityGroupDrawer(false)} />
    </ResponsiveDrawer>
  );
};