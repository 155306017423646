import type { ButtonProps } from '@mui/material';
import { Button, useTheme } from '@mui/material';
import { merge } from 'lodash';
import { useSearchParams } from 'react-router-dom';

type ColorType = 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning' | undefined;

export interface FilterButtonsProps<T extends string | number | symbol> {
  options: (T | boolean)[];
  messages: Record<T, string>;
  queryParam?: string;
  defaultValue?: T;
  selectedColors?: Partial<Record<T, ColorType>> | ColorType;
  unselectedColors?: Partial<Record<T, ColorType>> | ColorType;
  selectedVariant?: 'text' | 'outlined' | 'contained';
  unselectedVariant?: 'text' | 'outlined' | 'contained';
  ButtonProps?: Omit<ButtonProps, 'onClick' | 'color' | 'variant'>;
  roundedRight?: boolean;
  disabled?: boolean;
  onChange?: (newValue: T) => void;
}

export const FilterButtons = <T extends string | number | symbol>(props: FilterButtonsProps<T>) => {
  const [searchParams, setSearch] = useSearchParams();
  const { palette: { mode } } = useTheme();
  const isDark = mode === 'dark';

  const currentValue = searchParams.get(props.queryParam ?? '') ?? props.defaultValue?.toString();

  const handleOnFilterClick = (newFilter: T) => () => {
    if (props.queryParam != null) {
      if (newFilter === props.defaultValue) {
        searchParams.delete(props.queryParam);
      } else {
        searchParams.set(props.queryParam, newFilter.toString());
      }
      setSearch(searchParams, { replace: true });
    }

    props.onChange && props.onChange(newFilter);
  };

  const selectedVariant = props.selectedVariant ?? 'contained';
  const unselectedVariant = props.unselectedVariant ?? 'text';

  const getVariant = (option: T) => {
    const optionString = option.toString();

    if (currentValue === optionString) {
      return selectedVariant;
    } else {
      return unselectedVariant;
    }
  };

  const getColor = (option: T): ColorType => {
    const optionString = option.toString();

    if (currentValue === optionString) {
      return typeof (props.selectedColors) === 'string' ? props.selectedColors : props.selectedColors?.[option];
    } else {
      return typeof (props.unselectedColors) === 'string' ? props.unselectedColors : props.unselectedColors?.[option];
    }
  };

  const getSxForContainedInherit = (option: T) => {
    if (getVariant(option) === 'contained' && getColor(option) === 'inherit') {
      return {
        color: 'common.black',
        ':hover': {
          border: '1px solid',
          borderColor: isDark ? 'common.white' : 'common.black'
        }
      };
    }
  };

  const lastIndex = props.options.length - 1;

  return <>
    {props.options.filter((o): o is T => typeof o !== 'boolean').map((option, index) => (
      <Button
        size='small'
        disabled={props.disabled}
        {...props.ButtonProps}
        key={index}
        onClick={handleOnFilterClick(option)}
        color={getColor(option)}
        variant={getVariant(option)}

        sx={merge({}, props.ButtonProps?.sx, {
          ...getSxForContainedInherit(option),
          ...(props.roundedRight && {

            borderTopRightRadius: index === lastIndex ? 4 : 0,
            borderBottomRightRadius: index === lastIndex ? 4 : 0,
          }),
          ...(getColor(option) === 'error' && {
            borderColor: 'error.main',
            ':hover': undefined
          }),
        })}
      >
        {props.messages[option]}
      </Button>
    ))}
  </>;
};