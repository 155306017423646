import { MultiStepFormDialog } from '@components/MultiStepForms/MultiStepFormDialog';
import { useCurrentProject } from '@modules/projects/utils/useCurrentProject';
import { DialogProps } from '@mui/material';
import { useNotification } from '@utils/useNotification';
import { useQueryInvalidator } from '@utils/useQueryInvalidator';
import dayjs from 'dayjs';
import { useAskSignatureMutation, useFolderEntriesQuery, useGetDocumentQuery, useProjectDocumentsQuery, useProjectTasksQuery, useTenantSettingsQuery } from 'gql/index';
import { useEffect, useMemo } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { FieldCoordinates } from '../SignaturePlacementControl';
import { RequestSignatureCoordinatesStep } from './RequestSignatureCoordinatesStep';
import { RequestSignatureDetailsStep } from './RequestSignatureDetailsStep';
import { RequestSignatureSelectDocumentStep } from './RequestSignatureSelectDocumentStep';

interface OwnProps extends Omit<DialogProps, 'onSubmit'> {
  documentId: number | undefined | '';
  onSubmit?: (formValues: AskSignatureFormValues) => void;
  isSubmitting?: boolean;
}

export interface AskSignatureFormValues {
  documentId: number | '';
  dueDate: string;
  signatoryCustomerId: string;
  customMessage: string;
  signatureCoordinates: FieldCoordinates | null;
}


export const RequestSignatureDialog: React.FC<OwnProps> = ({ documentId, onSubmit, isSubmitting, ...dialogProps }) => {
  const { formatMessage } = useIntl();
  const { notifySuccess } = useNotification();
  const invalidateQuery = useQueryInvalidator();

  const { projectId } = useCurrentProject();
  const { data: tenantSettings } = useTenantSettingsQuery({}, { staleTime: 600000, select: p => p.tenantSettings });

  const defaultValues: AskSignatureFormValues = useMemo(() => ({
    documentId: documentId ?? '',
    signatoryCustomerId: '',
    dueDate: dayjs().add(7, 'day').format('YYYY-MM-DD'),
    customMessage: '',
    signatureCoordinates: null
  }), [documentId]);

  const form = useForm<AskSignatureFormValues>({ defaultValues, reValidateMode: 'onSubmit' });
  const actualDocumentId = useWatch({ control: form.control, name: 'documentId' });

  const { handleSubmit, reset } = form;

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, dialogProps.open, reset]);

  const noCreditsLeft = useMemo(() => {
    return tenantSettings && tenantSettings.isSignDocumentByZohoEnabled && tenantSettings.remainingZohoSignatureRequests <= 0;
  }, [tenantSettings]);

  const signatureCoordinates = form.watch('signatureCoordinates');

  const { mutate: askSignature, isLoading: askSignatureIsLoading } = useAskSignatureMutation({
    onSuccess: () => {
      if (tenantSettings?.isSignDocumentByZohoEnabled) {
        notifySuccess(formatMessage({ id: 'Signature requested successfully. Remaining  signatures {nbSignatures}' }, { nbSignatures: noCreditsLeft ? 0 : tenantSettings?.remainingZohoSignatureRequests - 1 }));
      } else {
        notifySuccess(formatMessage({ id: 'Signature requested successfully.' }));
      }
      invalidateQuery(useTenantSettingsQuery);
      documentId && invalidateQuery(useGetDocumentQuery, { id: documentId });
      invalidateQuery(useProjectDocumentsQuery, { projectId });
      invalidateQuery(useFolderEntriesQuery, { projectId });
      invalidateQuery(useProjectTasksQuery, { projectId });
      dialogProps.onClose?.({}, 'backdropClick');
    }
  });

  const onSubmitInternal = (values: AskSignatureFormValues) => {
    if (onSubmit) {
      onSubmit(values);
      return;
    }

    if (!values.signatoryCustomerId || !values.documentId) return;

    askSignature({
      input: {
        documentId: values.documentId,
        projectId,
        signatoryCustomerId: values.signatoryCustomerId,
        dueDate: values.dueDate.trim() || undefined,
        customMessage: values.customMessage.trim() || undefined,
        signatureCoordinates: values.signatureCoordinates ? {
          page: values.signatureCoordinates.page,
          x: values.signatureCoordinates.x,
          y: values.signatureCoordinates.y
        } : undefined
      }
    });
  };


  return (
    <FormProvider {...form}>
      <MultiStepFormDialog
        dialogProps={{ ...dialogProps, maxWidth: 'md' }}

        isSubmitting={isSubmitting || askSignatureIsLoading}
        onSubmit={handleSubmit(onSubmitInternal)}
        disableSubmit={noCreditsLeft
          || tenantSettings?.isSignDocumentByZohoEnabled && signatureCoordinates == null
        }

        steps={[
          (!!documentId == false) && {
            title: formatMessage({ id: 'Select document' }),
            onNext: handleSubmit,
            disableNext: !actualDocumentId,
            content: () => <RequestSignatureSelectDocumentStep />
          },
          {
            title: formatMessage({ id: 'Ask for document signature' }),
            onNext: handleSubmit,
            disableNext: noCreditsLeft,
            content: () => <RequestSignatureDetailsStep />
          },
          (tenantSettings?.isSignDocumentByZohoEnabled && !noCreditsLeft) && {
            title: formatMessage({ id: 'Place signature field' }),
            subtitle: formatMessage({ id: 'Click on the PDF to place the signature field' }),
            content: () => actualDocumentId && <RequestSignatureCoordinatesStep documentId={actualDocumentId} />
          }
        ]}
      />
    </FormProvider>
  );
};