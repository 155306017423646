import { Close } from '@mui/icons-material';
import { AppBar, Dialog, DialogProps, IconButton, Stack, Toolbar, Typography } from '@mui/material';
import { useResponsive } from '@utils/useResponsive';
import { merge } from 'lodash';

export interface ResponsiveDialogProps extends DialogProps {
  title?: string;
  disableClose?: boolean;
  subtitle?: string;
}

export const ResponsiveDialog: React.FC<ResponsiveDialogProps> = ({ title, disableClose, subtitle, ...dialogProps }) => {
  const { isMobile } = useResponsive();

  return (
    <Dialog
      fullScreen={isMobile}
      {...dialogProps}
      onClose={disableClose ? undefined : dialogProps.onClose}
      PaperProps={merge({}, dialogProps.PaperProps, { style: { width: '100%' } })}
    >
      {(isMobile && dialogProps.onClose) ? (
        <AppBar position='static' sx={{ mb: 2 }}>
          <Toolbar>
            <IconButton color='inherit' disabled={disableClose} onClick={() => dialogProps.onClose?.({}, 'backdropClick')}>
              <Close />
            </IconButton>
            <Typography>
              {title}
            </Typography>
          </Toolbar>
        </AppBar>
      ) : title && (
        <Stack direction='row' justifyContent='space-between' alignItems='center' m={2}>
          <Stack>
            <Typography variant='h6' lineHeight={1}>
              {title}
            </Typography>

            {subtitle && (
              <Typography variant='subtitle1' color='text.secondary'>
                {subtitle}
              </Typography>
            )}
          </Stack>

          <IconButton color='inherit' disabled={disableClose} onClick={() => dialogProps.onClose?.({}, 'backdropClick')}>
            <Close />
          </IconButton>
        </Stack>
      )}

      {subtitle && isMobile && (
        <Stack px={2}>
          <Typography variant='subtitle2' lineHeight={1.4} color='text.secondary'>
            {subtitle}
          </Typography>
        </Stack>
      )}

      {dialogProps.children}
    </Dialog>
  );
};