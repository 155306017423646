import { CircularProgress, Stack } from '@mui/material';
import { useGetMe } from '@utils/useGetMe';
import { useFormTemplateForEditorQuery } from 'gql/index';
import React from 'react';
import { useParams } from 'react-router-dom';
import { FormTemplateEditorView } from './views/FormTemplateEditorView';
import { FormTemplateViewerView } from './views/FormTemplateViewerView';

export const FormTemplatePage: React.FC = () => {
  const { templateId } = useParams();
  const { me } = useGetMe();

  const { data: template } = useFormTemplateForEditorQuery({ id: Number(templateId) || 0 }, {
    cacheTime: 0,
    select: d => d.formTemplate
  });

  if (!template?.formDefinition) {
    return (
      <Stack height='100%' width='100%' alignItems='center' justifyContent='center'>
        <CircularProgress />
      </Stack>
    );
  }

  if (template.createdById !== me?.id) {
    return (
      <FormTemplateViewerView
        template={template}
        definition={template.formDefinition}
      />
    );
  }

  return (
    <FormTemplateEditorView
      template={template}
      definition={template.formDefinition}
    />
  );
};