import { useCurrentProject } from '@modules/projects/utils/useCurrentProject';
import { useProjectFormOverviewsQuery } from 'gql/index';
import { FormsTable } from './Tables/FormsTable';

export const FormsManagerView: React.FC = () => {
  const { projectId } = useCurrentProject();

  const { data: forms, isFetching } = useProjectFormOverviewsQuery({ projectId }, {
    select: d => d.projectFormOverviews
  });

  return (
    <>
      <FormsTable forms={forms ?? []} loading={isFetching} />
    </>
  );
};