import { Chip, ChipProps } from '@mui/material';
import { ProvisioningStatus } from 'gql/index';
import { useProvisioningStatusChangedSubscription } from 'gql/subscriptions';
import { useState } from 'react';
import { useIntl } from 'react-intl';

interface Props {
  id: string;
  defaultStatus: ProvisioningStatus;
}

export const TenantProvisioningChip: React.FC<Props> = ({ id: tenantId, defaultStatus }) => {
  const { formatMessage } = useIntl();

  const [currentStatus, setCurrentStatus] = useState<ProvisioningStatus>(defaultStatus);

  useProvisioningStatusChangedSubscription({
    variables: { tenantId },
    onData: data => {
      const status = data.data.data?.provisioningStatusChanged;
      if (status) {
        setCurrentStatus(status);
      }
    },
    skip: defaultStatus === ProvisioningStatus.Ready
  });

  const chipPropsByStatus: Record<ProvisioningStatus, ChipProps> = {
    NotStarted: {
      label: formatMessage({ id: 'Not started' }),
      color: 'warning'
    },
    Provisioning: {
      label: formatMessage({ id: 'Provisioning' }),
      color: 'info'
    },
    Ready: {
      label: formatMessage({ id: 'Ready' }),
      color: 'success'
    }
  };

  return <Chip {...chipPropsByStatus[currentStatus]} />;
};