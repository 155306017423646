import { DataTable } from '@components/DataTable';
import { TreeDataGroupingCell } from '@components/TreeDataGroupingCell';
import { Add, Delete } from '@mui/icons-material';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, Stack, Typography, useTheme } from '@mui/material';
import { GridActionsCellItem, GridColDef, useGridApiRef } from '@mui/x-data-grid-pro';
import { notNullOrUndefined } from '@utils/notNullOrUndefined';
import { useMoneyFormatter } from '@utils/numberUtils';
import _ from 'lodash';
import React, { useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { CommercialConstructionCalculatorData, CostEstimate } from '../types';
import { EstimatesGridToolbar } from './EstimatesGridToolbar';

type Props = {
  onChange: () => void;
};

type CostEstimateRow = {
  id: string,
  name: string,
  estimate: number,
  childCosts: CostEstimate[] | undefined,
  path: string[];
};

export const EstimatesSection: React.FC<Props> = ({ onChange }) => {
  const apiGridRef = useGridApiRef();
  const { control, setValue, getValues } = useFormContext<CommercialConstructionCalculatorData>();
  const { fields, update, append, remove } = useFieldArray({ control: control, name: 'calculatorValues.costEstimates', keyName: 'uid' });
  const { formatMessage } = useIntl();
  const theme = useTheme();
  const formatMoney = useMoneyFormatter();

  const childRows = fields.filter(p => (p.childCosts?.length ?? 0) > 0)
    .flatMap(p => p.childCosts?.map(q => ({ ...q, uid: q.id, path: [p.id, q.id] }))).filter(notNullOrUndefined);

  const rows = fields.map<CostEstimateRow>(p => ({ ...p, path: [p.id] })).concat(childRows);

  const columns: GridColDef<CostEstimateRow>[] = [
    {
      field: 'name',
      headerName: formatMessage({ id: 'Name' }),
      type: 'string',
      editable: true,
      flex: 1,
      minWidth: 200,
      renderCell: params => <Typography ml={(params.row.path.length - 1) * 2}>{params.value}</Typography>,
    },
    {
      field: 'estimate',
      headerName: formatMessage({ id: 'Estimate' }),
      type: 'number',
      width: 150,
      editable: true,
      valueFormatter: ({ value }) => isNaN(value) ? '' : formatMoney(value, false)
    },
    {
      field: 'actions',
      type: 'actions',
      width: 100,
      cellClassName: 'actions',
      getActions: (params) => [
        <GridActionsCellItem
          sx={{ visibility: params.row.path.length === 1 ? 'default' : 'hidden' }}
          icon={<Add color='action' />}
          label={formatMessage({ id: 'Add' })}

          onClick={() => {
            const fieldIndex = fields.findIndex(p => p.id === params.row.path[0]);
            const field = fields[fieldIndex];

            if (params.row.path.length === 1) {
              update(fieldIndex, {
                ...field,
                childCosts: [...(field.childCosts ?? []), {
                  id: crypto.randomUUID(),
                  name: formatMessage({ id: 'New soft cost' }),
                  childCosts: [],
                  estimate: 0
                }]
              });
            }
          }}
          color="inherit"
        />,
        <GridActionsCellItem
          icon={<Delete color='action' />}
          label={formatMessage({ id: 'Delete' })}

          onClick={() => {
            const fieldIndex = fields.findIndex(p => p.id === params.row.path[0]);

            if (params.row.path.length === 2) {
              const parent = fields[fieldIndex];
              const childIndex = parent.childCosts?.findIndex(p => p.id == params.id);
              if (parent.childCosts == undefined || childIndex == undefined) return;

              const child = parent.childCosts[childIndex];
              parent.childCosts.splice(childIndex, 1);

              const updatedParent = {
                ...parent,
                estimate: _.sumBy(parent.childCosts, p => p.estimate),
                childRows: parent.childCosts,
              };

              update(fieldIndex, updatedParent);
              setValue('calculatorValues.totalEstimate', _.sumBy(fields, p => isNaN(p.estimate) ? 0 : p.estimate) - child.estimate);
              onChange();
            }
            else if (params.row.path.length === 1) {
              if (fieldIndex >= 0) {
                remove(fieldIndex);
                setValue('calculatorValues.totalEstimate', _.sumBy(fields, p => isNaN(p.estimate) ? 0 : p.estimate) - fields[fieldIndex].estimate);
                onChange();
              }
            }
          }}
          color="inherit"
        />,
      ]
    }
  ];

  const [expanded, setExpanded] = useState(false);

  return <Accordion expanded={expanded} onChange={(_, expanded) => setExpanded(expanded)}>
    <AccordionSummary expandIcon={<ExpandMore />}>
      <Stack direction={'row'} width={'100%'} gap={2} flexWrap={'wrap'}>
        <Typography variant='h4' sx={{ mr: 4 }}>{formatMessage({ id: 'Step 1 - Estimated project costs' })}</Typography>
        <Typography variant='h4' color={'primary'}>{formatMoney(getValues('calculatorValues.totalEstimate'), false)}</Typography>
      </Stack>
    </AccordionSummary>
    <AccordionDetails>
      <Box maxWidth={600} >
        <DataTable<CostEstimateRow>
          variant='minimal'
          apiRef={apiGridRef}
          columns={columns}
          treeData
          getTreeDataPath={row => row.path}
          groupingColDef={{
            headerName: '',
            width: 40,
            renderCell: (params) => <TreeDataGroupingCell {...params} />
          }}
          isGroupExpandedByDefault={() => true}
          rows={rows}
          getRowId={row => row.id}
          hideFooter
          isCellEditable={params => params.colDef.field !== 'estimate' || (params.row.childCosts?.length ?? 0) === 0}

          disableColumnMenu
          disableColumnPinning
          disableColumnResize
          disableColumnReorder
          slots={{
            toolbar: EstimatesGridToolbar,
          }}
          slotProps={{
            toolbar: {
              onAddRow: () => {
                const rowId = crypto.randomUUID();
                append({ id: rowId, name: formatMessage({ id: 'New estimate' }), estimate: 0, childCosts: undefined });
                onChange();
              }
            }
          }}
          autoHeight
          localeText={{
            ...theme.components?.MuiDataGrid?.defaultProps?.localeText,
            noRowsLabel: formatMessage({ id: 'No estimates entered' }),
          }}
          processRowUpdate={(newRow, oldRow) => {

            const newEstimate = isNaN(newRow.estimate) ? 0 : newRow.estimate;
            const oldEstimate = isNaN(oldRow.estimate) ? 0 : oldRow.estimate;

            if (oldRow.path.length === 2) {
              const rowIndex = fields.findIndex(p => p.id === oldRow.path[0]);
              const parent = fields[rowIndex];
              if (parent.childCosts == undefined) return oldRow;

              const childIndex = parent.childCosts?.findIndex(p => p.id == newRow.id);

              parent.childCosts.splice(childIndex, 1, { ...newRow, estimate: newEstimate });

              const updatedParent = {
                ...parent,
                estimate: _.sumBy(parent.childCosts, p => p.estimate),
                childRows: parent.childCosts,
              };


              update(rowIndex, updatedParent);
              onChange();

            }
            else {
              const rowIndex = fields.findIndex(p => p.id === newRow.id);
              update(rowIndex, newRow);
            }

            setValue('calculatorValues.totalEstimate',
              _.sumBy(fields, p => isNaN(p.estimate) ? 0 : p.estimate)
              + (newEstimate - oldEstimate));
            onChange();

            return newRow;
          }}
          onCellClick={(params) => params.isEditable && apiGridRef.current.startCellEditMode({ field: params.field, id: params.id })}
        />
      </Box>
      <Stack direction={'row'} alignItems={'center'} justifyContent={'right'} spacing={2}>
        <Typography >{formatMessage({ id: 'Project Estimate' })}</Typography>
        <Typography fontWeight={600}>{formatMoney(getValues('calculatorValues.totalEstimate'))}</Typography>
      </Stack>
    </AccordionDetails>
  </Accordion >;
};