import { ActionMenu } from '@components/ActionMenu';
import { ConfirmDialog } from '@components/ConfirmDialog';
import { PermissionsModal } from '@modules/securityGroups/components/PermissionsModal/PermissionsModal';
import { ContentCopy, Delete, Draw, RemoveRedEye, Share } from '@mui/icons-material';
import { Button, ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { getTenantIdentifier } from '@utils/getTenantIdentifier';
import { useGetMe } from '@utils/useGetMe';
import { useNotification } from '@utils/useNotification';
import { useQueryInvalidator } from '@utils/useQueryInvalidator';
import { useResponsive } from '@utils/useResponsive';
import { FormTemplateFragmentFragment, ShareableResourceType, useDeleteFormTemplateMutation, useDuplicateFormTemplateMutation, useFormTemplatesQuery } from 'gql/index';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';

interface Props {
  template: FormTemplateFragmentFragment;
  onPreviewClick?: () => void;
}

export const FormTemplateActions: React.FC<Props> = ({ template, onPreviewClick }) => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const location = useLocation();
  const { notifySuccess } = useNotification();
  const invalidateQuery = useQueryInvalidator();
  const { isMobile } = useResponsive();
  const { hasTenantAdminRole, me } = useGetMe();

  const editPath = `/${getTenantIdentifier()}/templates/edit/${template.id}`;
  const isEditing = location.pathname.startsWith(editPath);

  const { mutate: deleteFormTemplate, isLoading } = useDeleteFormTemplateMutation({
    onSuccess: () => {
      notifySuccess(formatMessage({ id: 'Form template deleted successfully' }));
      invalidateQuery(useFormTemplatesQuery);
      if (isEditing) {
        navigate(`/${getTenantIdentifier()}/templates`, { replace: true });
      }
    }
  });

  const { mutate: duplicateFormTemplate } = useDuplicateFormTemplateMutation({
    onSuccess: (r) => {
      notifySuccess(formatMessage({ id: 'Form template duplicated successfully' }));
      invalidateQuery(useFormTemplatesQuery);
      navigate(`/${getTenantIdentifier()}/templates/edit/${r.duplicateFormTemplate.formTemplate?.id}`);
    }
  });

  const onDuplicate = () => {
    duplicateFormTemplate({ input: { templateId: template.id } });
  };

  const [confirmRemoveDialogOpen, setConfirmRemoveDialogOpen] = useState(false);
  const [permissionsDialogOpen, setPermissionsDialogDialogOpen] = useState(false);

  const onDeleteConfirm = () => {
    deleteFormTemplate({ id: template.id });
  };

  return <>
    {!isMobile && onPreviewClick && (
      <Button startIcon={<RemoveRedEye />} color='inherit' onClick={onPreviewClick}>
        {formatMessage({ id: 'Preview' })}
      </Button>
    )}

    <ActionMenu>
      {isMobile && onPreviewClick && (
        <MenuItem onClick={onPreviewClick}>
          <ListItemIcon><RemoveRedEye /></ListItemIcon>
          <ListItemText primary={formatMessage({ id: 'Preview' })} />
        </MenuItem>
      )}

      {(me?.id == template.createdById || hasTenantAdminRole) &&
        <MenuItem onClick={() => setPermissionsDialogDialogOpen(true)}>
          <ListItemIcon>
            <Share />
          </ListItemIcon>
          <ListItemText
            primary={formatMessage({ id: 'Share' })}
          />
        </MenuItem>
      }

      {(me?.id == template.createdById && !isEditing && !isMobile) &&
        <MenuItem onClick={() => navigate(editPath)}>
          <ListItemIcon>
            <Draw />
          </ListItemIcon>
          <ListItemText primary={formatMessage({ id: 'Edit' })} />
        </MenuItem>
      }

      <MenuItem onClick={onDuplicate}>
        <ListItemIcon>
          <ContentCopy />
        </ListItemIcon>
        <ListItemText primary={formatMessage({ id: 'Duplicate' })} />
      </MenuItem>

      {(me?.id == template.createdById || hasTenantAdminRole) &&
        <MenuItem onClick={() => setConfirmRemoveDialogOpen(true)}>
          <ListItemIcon>
            <Delete />
          </ListItemIcon>
          <ListItemText
            primary={formatMessage({ id: 'Delete' })}
          />
        </MenuItem>
      }
    </ActionMenu>

    <PermissionsModal
      open={permissionsDialogOpen}
      onClose={() => setPermissionsDialogDialogOpen(false)}
      resource={{
        resourceType: ShareableResourceType.FormTemplate,
        name: template.formDefinition?.name ?? '',
        ...template
      }}
    />

    <ConfirmDialog
      open={confirmRemoveDialogOpen}
      title={formatMessage({ id: 'Delete template' })}
      content={formatMessage({ id: 'This template will be deleted. Forms created using it will remain. Are you sure?' })}
      confirmText={formatMessage({ id: 'Delete' })}
      confirmColor='error'
      onCancel={() => setConfirmRemoveDialogOpen(false)}
      onConfirm={onDeleteConfirm}
      loading={isLoading}
    />
  </>;
};