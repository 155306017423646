import { useCurrentProject } from '@modules/projects/utils/useCurrentProject';
import { Edit } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { ProjectTask } from 'gql/index';
import { useState } from 'react';
import { TaskFormDrawer } from '../TaskFormDrawer';

interface OwnProps {
  task: Pick<ProjectTask, 'id'>;
}

export const TaskEditAction: React.FC<OwnProps> = ({ task }) => {
  const [isEditDrawerOpen, setIsEditDrawerOpen] = useState(false);
  const { canManageTasks } = useCurrentProject();

  if (!canManageTasks) {
    return undefined;
  }

  return (
    <>
      <IconButton onClick={() => setIsEditDrawerOpen(true)}>
        <Edit />
      </IconButton>

      <TaskFormDrawer
        taskId={task.id}
        open={isEditDrawerOpen}
        onClose={() => setIsEditDrawerOpen(false)}
      />
    </>
  );
};