import { DrawerSection } from '@components/Drawers/DrawerSection';
import { FormDrawer } from '@components/Drawers/FormDrawer';
import { DrawerProps, MenuItem, TextField } from '@mui/material';
import { useNotification } from '@utils/useNotification';
import { useQueryInvalidator } from '@utils/useQueryInvalidator';
import { useValidationRules } from '@utils/useValidationRules';
import { useAddTenantMutation, useGetTenantsQuery } from 'gql/index';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { allSupportedLocales, defaultLocale } from 'src/locales';

interface Props extends Omit<DrawerProps, 'onSubmit'> {
  onComplete: () => void;
}

export interface CreateTenantFormValues {
  name: string;
  identifier: string;
  adminEmail: string;
  adminFirstName: string;
  adminLastName: string;
  adminLanguage: string;
}

const defaultValues: CreateTenantFormValues = {
  name: '',
  identifier: '',
  adminEmail: '',
  adminFirstName: '',
  adminLastName: '',
  adminLanguage: defaultLocale.code
};

export const CreateTenantDrawer: React.FC<Props> = ({ onComplete, ...drawerProps }) => {
  const { formatMessage } = useIntl();
  const { notifySuccess } = useNotification();
  const { validateTenantIdentifier, notEmpty, validateEmail } = useValidationRules();
  const invalidateQuery = useQueryInvalidator();

  const { handleSubmit, control, setValue, formState: { dirtyFields, isDirty } } = useForm<CreateTenantFormValues>({ defaultValues });

  const { mutate: addTenant, isLoading } = useAddTenantMutation({
    onSuccess: () => {
      onComplete();
      notifySuccess(formatMessage({ id: 'Tenant created. Please wait for the data to be initialized.' }));
      invalidateQuery(useGetTenantsQuery);
    }
  });

  const onSubmit = (values: CreateTenantFormValues) => {
    addTenant({
      input: {
        adminInput: {
          email: values.adminEmail,
          firstName: values.adminFirstName,
          lastName: values.adminLastName,
          language: values.adminLanguage
        },
        tenantInput: {
          identifier: values.identifier,
          name: values.name
        }
      }
    });
  };

  return (
    <FormDrawer
      header={formatMessage({ id: 'Add tenant' })}
      showFooter
      onSave={handleSubmit(onSubmit)}
      disablePadding
      isSubmitting={isLoading}
      isFormDirty={isDirty}
      {...drawerProps}
    >
      <DrawerSection title={formatMessage({ id: 'Tenant details' })} sx={{ gap: 2 }}>
        <Controller
          control={control}
          name='name'
          rules={{ validate: notEmpty }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              onChange={e => {
                field.onChange(e);
                if (!dirtyFields.identifier) {
                  setValue('identifier', e.target.value.toLowerCase().replace(/[^a-z0-9]/g, '-'), { shouldDirty: false });
                }
              }}
              required
              disabled={isLoading}
              error={!!error}
              helperText={error?.message}
              label={formatMessage({ id: 'Name' })}
            />
          )}
        />

        <Controller
          control={control}
          name='identifier'
          rules={{ validate: { validateTenantIdentifier, notEmpty } }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              onChange={e => field.onChange(e.target.value.toLowerCase().replace(/ /g, '-'))}
              required
              disabled={isLoading}
              error={!!error}
              helperText={error?.message || formatMessage({ id: 'This will be used in the URL' })}
              label={formatMessage({ id: 'Identifier' })}
            />
          )}
        />
      </DrawerSection>

      <DrawerSection title={formatMessage({ id: 'Admin details' })} sx={{ gap: 2 }}>
        <Controller
          control={control}
          name='adminEmail'
          rules={{ validate: { notEmpty, validateEmail } }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              required
              disabled={isLoading}
              error={!!error}
              helperText={error?.message}
              label={formatMessage({ id: 'Email' })}
            />
          )}
        />

        <Controller
          control={control}
          name='adminFirstName'
          rules={{ validate: { notEmpty } }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              required
              disabled={isLoading}
              error={!!error}
              helperText={error?.message}
              label={formatMessage({ id: 'First name' })}
            />
          )}
        />

        <Controller
          control={control}
          name='adminLastName'
          rules={{ validate: { notEmpty } }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              required
              disabled={isLoading}
              error={!!error}
              helperText={error?.message}
              label={formatMessage({ id: 'Last name' })}
            />
          )}
        />


        <Controller
          control={control}
          name='adminLanguage'
          rules={{ validate: { notEmpty } }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              required
              disabled={isLoading}
              select
              error={!!error}
              helperText={error?.message}
              label={formatMessage({ id: 'Language' })}
            >
              <option value='' disabled style={{ display: 'none' }} />
              {allSupportedLocales.map(locale => (
                <MenuItem key={locale.code} value={locale.code}>
                  {formatMessage(locale.nameDescriptor)}
                </MenuItem>
              ))}
            </TextField>
          )}
        />
      </DrawerSection>
    </FormDrawer>
  );
};