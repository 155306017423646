import { DataTable } from '@components/DataTable';
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
import { GridColDef, GridColType, GridEditInputCell, GridRenderCellParams, GridRenderEditCellParams, GridTreeNodeWithRender, GridValidRowModel } from '@mui/x-data-grid-pro';
import { MatrixColumnType } from 'gql/index';
import { useCallback, useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { RenderFn } from '../../FormEditor/FormFields/configurations/MatrixFormField/MatrixUtils';
import { FormFillerValues } from '../../FormFiller/types';
import { MatrixColumnHeader } from '../../MatrixColumnHeader';
import { FieldViewerProps } from './FieldViewer';

type MatrixRow = FormFillerValues['values'][number]['matrixRows'][number];
type MatrixColumn = NonNullable<NonNullable<FieldViewerProps['field']>['matrix']>['columns'][number];

const valueGetter = (column: MatrixColumn) => ({ row }: GridRenderCellParams<MatrixRow>) => {
  const correctValue = row.values.find(v => v.columnId == column.id);

  const valueGetterByType: Record<MatrixColumn['fieldType'], unknown> = {
    Text: correctValue?.text,
    Boolean: correctValue?.boolean,
    Numerical: correctValue?.number
  };

  return valueGetterByType[column.fieldType];
};

const columnTypeToMatrixType: Record<MatrixColumn['fieldType'], GridColType> = {
  Text: 'string',
  Boolean: 'boolean',
  Numerical: 'number'
};


export const MatrixViewer: React.FC<FieldViewerProps> = ({ field, index }) => {
  const { formatMessage } = useIntl();

  const { control } = useFormContext<FormFillerValues>();

  const value = useWatch({ control, name: `values.${index}` });

  const valueRendererByColumnType: Record<MatrixColumnType, RenderFn | undefined> = useMemo(() => ({
    [MatrixColumnType.Boolean]: ({ value }) => (
      value === true ? formatMessage({ id: 'Yes' })
        : value === false ? formatMessage({ id: 'No' })
          : '-'
    ),
    [MatrixColumnType.Text]: undefined,
    [MatrixColumnType.Numerical]: undefined,
  }), [formatMessage]);

  const updateCellBoolValue = useCallback(<T extends GridValidRowModel>(newValue: boolean | '', params: GridRenderEditCellParams<T, unknown, unknown, GridTreeNodeWithRender>) => {
    if (params.value == newValue) {
      params.api.setEditCellValue({ id: params.id, field: params.field, value: '' });
    } else {
      params.api.setEditCellValue({ id: params.id, field: params.field, value: newValue });
    }
  }, []);

  const valueEditorByColumnType: Record<MatrixColumnType, React.FC<GridRenderEditCellParams>> = useMemo(() => ({
    [MatrixColumnType.Boolean]: (params) => (
      <RadioGroup row value={params.value === '' ? null : params.value} >
        <FormControlLabel value="true" onClick={() => updateCellBoolValue(true, params)} control={<Radio />} label={formatMessage({ id: 'Yes' })} />
        <FormControlLabel value="false" onClick={() => updateCellBoolValue(false, params)} control={<Radio />} label={formatMessage({ id: 'No' })} />
      </RadioGroup>
    ),
    [MatrixColumnType.Text]: (params) => <GridEditInputCell {...params} />,
    [MatrixColumnType.Numerical]: (params) => <GridEditInputCell {...params} />,
  }), [formatMessage, updateCellBoolValue]);


  const columns = field.matrix?.columns.map<GridColDef<MatrixRow>>(column => ({
    disableColumnMenu: true,
    disableExport: true,
    disableReorder: true,
    sortable: false,
    field: column.id.toString(),
    renderHeader: () => (
      <MatrixColumnHeader name={column.name} isRequired={field.isRequired} description={column.description} />
    ),
    flex: 1,
    type: columnTypeToMatrixType[column.fieldType],
    renderCell: valueRendererByColumnType[column.fieldType],
    renderEditCell: valueEditorByColumnType[column.fieldType],
    valueGetter: valueGetter(column)
  })) ?? [];

  return (
    <FormControl disabled>
      <FormLabel>{field.name}</FormLabel>
      <DataTable
        noDataMessage={formatMessage({ id: 'No values inputted.' })}
        columns={columns}
        rows={value.matrixRows ?? []}
      />
    </FormControl>
  );
};