import { FormDrawer } from '@components/Drawers/FormDrawer';
import { DrawerProps, Stack, TextField } from '@mui/material';
import { useGetMe } from '@utils/useGetMe';
import { useNotification } from '@utils/useNotification';
import { useQueryInvalidator } from '@utils/useQueryInvalidator';
import { getNormalizedPhoneNumber, useValidationRules } from '@utils/useValidationRules';
import { useEditExternalUserMutation, useEditUserMutation, useGetProjectMembersQuery, useGetProjectQuery, useGetUserQuery, useGetUsersFullQuery, useGetUsersQuery, useSecurityGroupQuery, useSecurityGroupsForProjectQuery } from 'gql/index';
import { MuiTelInput } from 'mui-tel-input';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

interface OwnProps extends DrawerProps {
  userId: string;
}

interface EditUserValues {
  email: string;
  firstName: string;
  lastName: string;
  title: string;
  business: string;
  phoneNumber: string;
}

const defaultValues: EditUserValues = {
  email: '',
  firstName: '',
  lastName: '',
  title: '',
  business: '',
  phoneNumber: '',
};

export const EditUserDetailsDrawer: React.FC<OwnProps> = ({ userId, ...drawerProps }) => {
  const { formatMessage } = useIntl();
  const invalidateQuery = useQueryInvalidator();
  const { notifySuccess } = useNotification();
  const { validatePhoneNumber } = useValidationRules();

  const { projectId: projectIdString } = useParams();
  const projectId = Number(projectIdString) || null;

  const { hasTenantAdminRole } = useGetMe();

  const form = useForm<EditUserValues>();
  const { reset, control, handleSubmit, formState: { isDirty } } = form;

  const { data: user, isFetching } = useGetUserQuery({ id: userId }, { select: d => d.user, enabled: drawerProps.open });

  useEffect(() => {
    if (user) {
      reset({
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        title: user.title ?? defaultValues.title,
        business: user.business ?? defaultValues.business,
        phoneNumber: user.phoneNumber ?? defaultValues.phoneNumber,
      });
    } else {
      reset(defaultValues);
    }
  }, [reset, user, drawerProps.open]);

  const onSuccess = () => {
    notifySuccess(formatMessage({ id: 'User updated successfully' }));
    invalidateQuery(useGetProjectQuery);
    invalidateQuery(useSecurityGroupsForProjectQuery);
    invalidateQuery(useGetProjectMembersQuery);
    invalidateQuery(useGetUserQuery, { id: userId });
    invalidateQuery(useGetUsersQuery);
    invalidateQuery(useGetUsersFullQuery);
    invalidateQuery(useSecurityGroupQuery);
    drawerProps.onClose?.({}, 'backdropClick');
  };

  const { mutate: editUser, isLoading: isEditingUser } = useEditUserMutation({ onSuccess });

  const { mutate: editExternalUser, isLoading: isEditingExternalUser } = useEditExternalUserMutation({ onSuccess });

  const isSubmitting = isEditingUser || isEditingExternalUser;
  const disabled = isSubmitting || isFetching;

  const onSubmit = (values: EditUserValues) => {
    if (!hasTenantAdminRole && projectId) {
      editExternalUser({
        input: {
          userId,
          firstName: values.firstName,
          lastName: values.lastName,
          title: values.title === '' ? undefined : values.title,
          business: values.business === '' ? undefined : values.business,
          phoneNumber: getNormalizedPhoneNumber(values.phoneNumber),
          projectId
        }
      });
    } else {
      editUser({
        input: {
          userId,
          firstName: values.firstName,
          lastName: values.lastName,
          title: values.title === '' ? undefined : values.title,
          business: values.business === '' ? undefined : values.business,
          phoneNumber: getNormalizedPhoneNumber(values.phoneNumber)
        }
      });
    }
  };

  return (
    <FormDrawer
      {...drawerProps}
      isSubmitting={isSubmitting}
      isLoading={isFetching || isSubmitting}
      isFormDirty={isDirty}
      header={formatMessage({ id: 'Edit user details' })}
      showFooter
      onSave={handleSubmit(onSubmit)}
    >
      <Stack spacing={2} p={{ xs: 1, md: 2 }}>
        <Controller
          control={control}
          name='firstName'
          rules={{ required: formatMessage({ id: 'This field is required' }) }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              label={formatMessage({ id: 'First name' })}
              required
              disabled={disabled}
              error={Boolean(error)}
              helperText={error?.message}
              {...field}
            />
          )}
        />

        <Controller
          control={control}
          name='lastName'
          rules={{ required: formatMessage({ id: 'This field is required' }) }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              label={formatMessage({ id: 'Last name' })}
              required
              disabled={disabled}
              error={Boolean(error)}
              helperText={error?.message}
              {...field}
            />
          )}
        />


        <Controller
          control={control}
          name='title'
          render={({ field }) => (
            <TextField
              label={formatMessage({ id: 'Title' })}
              disabled={disabled}
              {...field}
            />
          )}
        />

        <Controller
          control={control}
          name='business'
          render={({ field }) => (
            <TextField
              label={formatMessage({ id: 'Business' })}
              disabled={disabled}
              {...field}
            />
          )}
        />

        <Controller
          control={control}
          name='phoneNumber'
          rules={{
            validate: validatePhoneNumber
          }}
          render={({ field, fieldState: { error } }) => (
            <MuiTelInput
              defaultCountry='CA'
              forceCallingCode
              flagSize="small"
              disabled={disabled}
              disableDropdown
              label={formatMessage({ id: 'Phone number' })}
              {...field}
              error={Boolean(error)}
              helperText={error?.message}
            />
          )}
        />
      </Stack>
    </FormDrawer>
  );
};
