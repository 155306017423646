import { allSupportedLocales } from '@assets/../locales';
import React from 'react';
import { LanguageSwitcherButton } from './LanguageSwitcherButton';


export const LanguageSwitcher: React.FC = () => {

  return (
    <>
      {allSupportedLocales.map(locale => (
        <LanguageSwitcherButton
          key={locale.code}
          locale={locale}
        />
      ))}
    </>
  );
};