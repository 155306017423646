import { ChipColors } from '@modules/forms/components/types';
import { MeetingStatus } from 'gql/index';
import { defineMessages, MessageDescriptor } from 'react-intl';

export interface AddMeetingFormValues {
  meetingTypeId: number | undefined;
  proposedTimes: MeetingProposedTime[];
}

export interface MeetingProposedTime {
  start: string | '',
  end: string | '',
}


export const MeetingStatusType: Record<MeetingStatus, MessageDescriptor> = defineMessages({
  [MeetingStatus.Requested]: { id: 'Requested' },
  [MeetingStatus.Planned]: { id: 'Planned' },
  [MeetingStatus.Cancelled]: { id: 'Cancelled' },
});



export const MeetingStatusTypeColor: Record<MeetingStatus, ChipColors> = {
  [MeetingStatus.Requested]: 'info',
  [MeetingStatus.Planned]: 'primary',
  [MeetingStatus.Cancelled]: 'error',
};
