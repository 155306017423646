import { CheckCircleOutline } from '@mui/icons-material';
import { Backdrop, Container, Paper, Stack, Typography } from '@mui/material';
import { getTenantIdentifier } from '@utils/getTenantIdentifier';
import { ProjectAccessTokenQuery } from 'gql/index';
import React from 'react';
import { useIntl } from 'react-intl';
import { useResponsive } from '../../../utils/useResponsive';
import { SignUpBanner } from './SignUpBanner';

interface Props {
  content: NonNullable<ProjectAccessTokenQuery['projectAccessTokenContent']>;
}

export const AnonymousFormCompletedDialog: React.FC<Props> = ({ content }) => {
  const { isMobile } = useResponsive();
  const { formatMessage } = useIntl();

  const inner = (
    <Stack alignItems={'center'} justifyContent={'center'} padding={4}>
      <CheckCircleOutline color='success' sx={{ fontSize: 120 }} />
      <Typography variant='body1'>{formatMessage({ id: 'The form has been submitted successfully.' })} </Typography>
      <Typography variant='body2'>{formatMessage({ id: 'Thank you!' })} </Typography>
    </Stack>
  );

  const signUpBanner = (
    <SignUpBanner
      userFullName={content.forUser?.fullName ?? ''}
      tenantDisplayName={content?.tenant?.name ?? getTenantIdentifier() ?? ''}
      projectId={content?.project?.id ?? 0}
    />
  );

  if (isMobile) {
    return <>
      {inner}

      {signUpBanner}
    </>;
  }

  return (
    <Backdrop open sx={{ backdropFilter: 'blur(3px)', position: 'absolute' }}>
      <Container maxWidth='lg' component={Stack} spacing={2}>
        {signUpBanner}

        <Paper style={{ overflowY: 'auto' }}>
          {inner}
        </Paper>
      </Container>
    </Backdrop>
  );
};