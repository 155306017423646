import { EmptyState } from '@components/EmptyState';
import { Button, CircularProgress, DialogContent, List, ListItemButton, ListSubheader, Radio, Stack, Typography } from '@mui/material';
import { getTenantIdentifier } from '@utils/getTenantIdentifier';
import { useQueryInvalidator } from '@utils/useQueryInvalidator';
import { useCreateFormTemplateMutation, useFormTemplatesQuery } from 'gql/index';
import { useFormContext, useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { defaultFormNames } from '../FormEditor/messages';
import { AddFormForm } from './AddFormDialog';


export const TemplateSelectionStep = () => {
  const { formatMessage } = useIntl();
  const { setValue, getValues, control, register } = useFormContext<AddFormForm>();
  const { data: templates, isLoading } = useFormTemplatesQuery({}, { select: p => p.formTemplates });
  const formSource = useWatch({ control, name: 'formSource' });
  const selectedTemplateId = useWatch({ control, name: 'selectedTemplateId' });
  const invalidateQuery = useQueryInvalidator();
  const navigate = useNavigate();

  const { mutate: createFormTemplate } = useCreateFormTemplateMutation();

  const handleCreateNewTemplete = () => {
    createFormTemplate({
      input: {
        name: formatMessage(defaultFormNames.template)
      }
    }, {
      onSuccess: d => {
        navigate(`/${getTenantIdentifier()}/templates/edit/${d.createFormTemplate.formTemplate?.id}`);
        invalidateQuery(useFormTemplatesQuery);
      }
    });
  };

  register('selectedTemplateId', {
    validate: (value) => (formSource === 'template' && value == null) ? formatMessage({ id: 'Please select a template.' }) : undefined
  });

  if (isLoading) {
    return (
      <DialogContent>
        <Stack minHeight={400} justifyContent={'center'} alignItems={'center'}>
          <CircularProgress />
        </Stack>
      </DialogContent>
    );
  }

  return (
    <DialogContent>
      <Stack pt={2} px={2} gap={4} alignItems={'center'} flex={1}>
        <Typography variant='h4'>{formatMessage({ id: 'How would you like to create your form?' })}</Typography>

        <Stack gap={2}>
          <ListItemButton sx={{ border: '1px solid', borderColor: 'divider' }} onClick={() => {
            setValue('formSource', 'newForm');
            setValue('selectedTemplateId', undefined);
          }}>
            <Radio disableRipple checked={getValues('formSource') === 'newForm'} />
            {formatMessage({ id: 'Create a new form' })}
          </ListItemButton>

          <List
            sx={{ minWidth: 500, minHeight: 200, maxHeight: 450, overflow: 'auto' }}
            subheader={
              <ListSubheader>
                {formatMessage({ id: 'Use a template:' })}
              </ListSubheader>
            }
          >
            {templates?.length !== 0 && (
              templates?.map(p =>
                <ListItemButton
                  key={p.id}
                  selected={selectedTemplateId === p.id}
                  onClick={() => {
                    setValue('selectedTemplateId', p.id);
                    setValue('formSource', 'template');
                  }}
                >
                  <Radio checked={selectedTemplateId === p.id} disableRipple />
                  {p.formDefinition?.name}
                </ListItemButton>
              )
            )}

            {templates?.length === 0 && (
              <EmptyState
                title={formatMessage({ id: 'There are no reusable form templates.' })}
                svgSize={100}
                callToActionComponent={<Button onClick={handleCreateNewTemplete}>{formatMessage({ id: 'Create new template' })}</Button>}
              />
            )}
          </List>
        </Stack>
      </Stack>
    </DialogContent>
  );
};