import { UnstyledHashLink } from '@components/UnstyledHashLink';
import { UserAvatar } from '@components/UserAvatar/UserAvatar';
import { ProjectSectionListItem } from '@modules/projects/components/Sections/ProjectSectionList';
import { useCurrentProject } from '@modules/projects/utils/useCurrentProject';
import { Button, ListItemButton, ListItemProps, ListItemText } from '@mui/material';
import { getTenantIdentifier } from '@utils/getTenantIdentifier';
import { useGetMe } from '@utils/useGetMe';
import { WorkFlowStatus, WorkflowActionStatus, WorkflowInstanceFragmentFragment, WorkflowVariableValueTypes, useActionsOfWorkflowInstanceQuery } from 'gql/index';
import _ from 'lodash';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { useUserVariableResolver } from '../hooks/useUserVariableResolver';

type WorkflowListItemProps = ListItemProps & {
  workflow: WorkflowInstanceFragmentFragment;
};

export const WorkflowListItem: React.FC<WorkflowListItemProps> = ({ workflow, ...listItemProps }) => {
  const { formatMessage } = useIntl();
  const { projectId } = useCurrentProject();
  const navigate = useNavigate();
  const { me } = useGetMe();

  const { data: actions } = useActionsOfWorkflowInstanceQuery({ workflowId: workflow.id }, { select: d => d.workflowActionConfigurationsOfWorkflow.sort(a => a.order) });

  const currentAction = useMemo(() => actions?.find(a => a.status === WorkflowActionStatus.InProgress), [actions]);
  const actionsCount = (actions && actions?.length) ?? 0;

  const resolveUser = useUserVariableResolver();
  const participant = useMemo(() => resolveUser(currentAction?.fieldValues.find(v => v.value?.variableValueType === WorkflowVariableValueTypes.User)?.value), [currentAction?.fieldValues, resolveUser]);

  const getSecondaryText = () => {
    if (!actions || actionsCount === 0) return '';

    if (workflow.workFlowStatus === WorkFlowStatus.Completed) {
      return formatMessage({ id: 'Completed' });
    }

    return `${formatMessage({ id: 'Progress' })} ${_.sumBy(actions, p => p.status == WorkflowActionStatus.Completed ? 1 : 0)} / ${actionsCount}`;
  };

  return (
    <ProjectSectionListItem
      disablePadding
      {...listItemProps}
      key={workflow.id}
      onClick={undefined}
      secondaryAction={participant?.id === me?.id ?
        <Button variant='contained'>
          <UnstyledHashLink to={`/${getTenantIdentifier()}/projects/${projectId}/workflows/${workflow.id}#${currentAction?.id}`}>
            {formatMessage({ id: 'Your turn' })}
          </UnstyledHashLink>
        </Button>
        :
        <UserAvatar displayName={participant?.fullName} />
      }
    >
      <ListItemButton onClick={() => navigate(`/${getTenantIdentifier()}/projects/${projectId}/workflows/${workflow.id}`)}>
        <ListItemText
          primary={workflow.name}
          secondary={getSecondaryText()}
          primaryTypographyProps={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
        />
      </ListItemButton>
    </ProjectSectionListItem >
  );
};

