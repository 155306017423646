import { ContentCopy } from '@mui/icons-material';
import { IconButton, TextField, TextFieldProps } from '@mui/material';
import { useNotification } from '@utils/useNotification';
import { useRef } from 'react';
import { useIntl } from 'react-intl';

type Props = TextFieldProps;

export const CopyField: React.FC<Props> = (props) => {
  const { notifySuccess } = useNotification();
  const { formatMessage } = useIntl();

  const ref = useRef<HTMLInputElement>(null);

  return (
    <TextField
      {...props}
      inputRef={ref}
      inputProps={{
        onClick: () => {
          ref.current?.select();
        }
      }}
      InputProps={{
        readOnly: true,
        endAdornment: (
          <IconButton onClick={() => {
            navigator.clipboard.writeText(props.value as string);
            notifySuccess(formatMessage({ id: 'Copied to clipboard' }));
          }}>
            <ContentCopy />
          </IconButton>
        )
      }}
    />
  );
};