import { useCurrentProject } from '@modules/projects/utils/useCurrentProject';
import { ProjectTaskStatus, ProjectTaskType, useProjectTasksQuery } from 'gql/index';
import { useParams } from 'react-router-dom';

export const useGetPendingFillFormTasks = () => {
  const { projectId } = useCurrentProject();
  const { formId: formIdString } = useParams();
  const formId = Number(formIdString);
  const { data: tasks, isFetching } = useProjectTasksQuery({
    projectId, filter: {
      and: [
        { projectFormId: { eq: formId } },
        { type: { eq: ProjectTaskType.FillForm } },
        { status: { neq: ProjectTaskStatus.Done } }
      ]

    }
  }, { select: d => d.projectTasks });

  const pendingTasksCount = tasks?.length;

  return { tasks, isFetching, pendingTasksCount };
};