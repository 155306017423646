import { MenuItem, TextField } from '@mui/material';
import { forwardRef } from 'react';
import { useIntl } from 'react-intl';
import { BuiltInRoles } from '../../../gql';
import { userRoleDescriptionMessages, userRoleMessages } from '../../application/messages';

export type Props = {
  roles: BuiltInRoles[],
  value: BuiltInRoles | undefined
  error?: string,
  onChange: (role: BuiltInRoles) => void
}

export const RolePicker = forwardRef<HTMLDivElement, Props>(({ roles, value, error, onChange }, ref) => {
  const { formatMessage } = useIntl();
  const roleDescription = value ? userRoleDescriptionMessages[value] : undefined;

  return <TextField
    ref={ref}
    select
    label={formatMessage({ id: 'Role' })}
    onChange={(e) => { onChange(e.target.value as BuiltInRoles); }}
    error={Boolean(error)}
    helperText={error ?? (roleDescription ? formatMessage(roleDescription) : undefined)}
    FormHelperTextProps={{ sx: { color: 'text.primary', fontSize: '0.9em' } }}
    value={value}
  >
    <option value='' disabled style={{ display: 'none', visibility: 'hidden' }} />
    {roles.map((role, index) => (
      <MenuItem key={index} value={role}>
        {formatMessage(userRoleMessages[role])}
      </MenuItem>
    ))}
  </TextField>;
});