import { TextFieldProps } from '@mui/material';
import { WorkflowVariableType, WorkflowVariableValueTypes } from 'gql/index';
import React, { useContext, useMemo } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { VariableValueInputComponent } from '../Variables/VariableValueInputComponent';
import { WorkflowRolePicker } from '../Variables/WorkflowRolePicker';
import { WorkflowEditorContext } from '../WorkflowEditorContext';
import { WorkflowEditorActionFieldValue, WorkflowEditorActionValue } from '../types';


interface Props {
  fieldId: string;
  label: string;
  textFieldProps?: TextFieldProps;
}

export const WorkflowFieldInput: React.FC<Props> = ({ fieldId, label, textFieldProps }) => {
  const { control, setValue, getValues } = useFormContext<WorkflowEditorActionValue>();
  const { fields: fieldValues } = useFieldArray({ control: control, name: 'fieldValues', keyName: 'uniqueKey' });
  const { actionDefinitionsMap, disabled } = useContext(WorkflowEditorContext);

  const { formatMessage } = useIntl();
  const actionDefinitionId = getValues('workflowActionDefinitionId');

  const fieldValueIndex = fieldValues?.findIndex(p => p?.fieldId === fieldId);

  const actionField = useMemo(() => {
    const actionDefinition = actionDefinitionsMap[actionDefinitionId];
    if (actionDefinition == null) return null;

    const fieldIndex = actionDefinition.fields.findIndex(p => p.id === fieldId);

    const actionField = actionDefinition.fields[fieldIndex];
    return actionField;
  }, [actionDefinitionsMap, actionDefinitionId, fieldId]);

  const onChange = (fieldValue: WorkflowEditorActionFieldValue) => {
    setValue(`fieldValues.${fieldValueIndex}`, fieldValue, { shouldDirty: true });
  };

  if (actionField == null) {
    return undefined;
  }

  return <Controller
    control={control}
    name={`fieldValues.${fieldValueIndex}`}
    rules={{
      validate: (fieldValue) => (actionField.required && !fieldValue.value) ? formatMessage({ id: 'This field is required.' }) : true
    }}
    render={({ field, fieldState }) => {

      // At some point we can allow configuring a field with either a value or an available workflow variable (global variable or output from another action)
      if (actionField.variableType == WorkflowVariableValueTypes.User) {
        return <WorkflowRolePicker

          onChange={(variableId) => {
            onChange({
              fieldId: fieldId,
              value: { id: variableId ?? '', name: '', variableType: WorkflowVariableType.WorkflowVariable, variableValueType: WorkflowVariableValueTypes.User, value: '' }
            });
          }}
          value={field.value?.value?.id ?? ''}
          textFieldProps={{ label, required: actionField.required, error: !!fieldState.error?.message, helperText: fieldState.error?.message }}
        />;
      }

      return <VariableValueInputComponent
        label={label}
        textFieldProps={textFieldProps}
        onChange={(value) => onChange({
          fieldId: fieldId,
          value: value ?? '',
        })}
        variableType={actionField.variableType}
        value={field.value.value}
        error={fieldState.error?.message}
        required={actionField.required}
        disabled={disabled}
      />;
    }}
  />;
};