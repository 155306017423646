import { FormDrawer } from '@components/Drawers/FormDrawer';
import { DrawerProps, Stack } from '@mui/material';
import { useNotification } from '@utils/useNotification';
import { useQueryInvalidator } from '@utils/useQueryInvalidator';
import { BuiltInRoles, useEditUserRoleMutation, useGetUserQuery, useGetUsersFullQuery, useGetUsersQuery } from 'gql/index';
import { useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { RolePicker } from './RolePicker';
import { UserApplicationBuiltInRoles } from './types';

interface OwnProps extends Omit<DrawerProps, 'onSave'> {
  userId: string;
}

type EditUserRoleValues = {
  userId: string;
  role: BuiltInRoles;
};

const defaultValues: EditUserRoleValues = {
  userId: '',
  role: BuiltInRoles.Contributor
};

export const EditAdminRoleDrawer: React.FC<OwnProps> = ({ userId, ...drawerProps }) => {
  const { formatMessage } = useIntl();
  const invalidateQuery = useQueryInvalidator();
  const { notifySuccess } = useNotification();
  const form = useForm<EditUserRoleValues>({ defaultValues });

  const { reset, control, handleSubmit, formState: { isDirty } } = form;

  const { data: user } = useGetUserQuery({ id: userId }, { select: d => d.user, enabled: drawerProps.open });

  const currentUserRole = useMemo(() => {
    if (user?.builtInRoles.includes(BuiltInRoles.TenantAdministrator)) {
      return BuiltInRoles.TenantAdministrator;
    }
    else if (user?.builtInRoles.includes(BuiltInRoles.ProjectAdministrator)) {
      return BuiltInRoles.ProjectAdministrator;
    }
    else if (user?.builtInRoles.includes(BuiltInRoles.Contributor)) {
      return BuiltInRoles.Contributor;
    }
    return undefined;
  }, [user?.builtInRoles]);

  useEffect(() => {
    if (user) {
      reset({
        userId,
        role: currentUserRole
      });
    }
    else {
      reset(defaultValues);
    }
  }, [reset, drawerProps.open, user, userId, currentUserRole]);

  const editAdminRoleMutation = useEditUserRoleMutation({
    onSuccess: () => {
      invalidateQuery(useGetUsersQuery);
      invalidateQuery(useGetUsersFullQuery);
      drawerProps.onClose?.({}, 'backdropClick');
      notifySuccess(formatMessage({ id: 'Role updated' }));
    }
  });

  const onSubmit = (values: EditUserRoleValues) => {
    editAdminRoleMutation.mutate({
      input: {
        userId,
        role: values.role
      }
    });
  };

  return (
    <FormDrawer
      {...drawerProps}
      isSubmitting={editAdminRoleMutation.isLoading}
      isFormDirty={isDirty}
      header={formatMessage({ id: 'Edit Role' })}
      showFooter
      onSave={handleSubmit(onSubmit)}
    >
      <Stack spacing={2} component='form' noValidate autoComplete='off' p={{ xs: 1, md: 2 }}>
        <Controller
          control={control}
          name='role'
          rules={{
            required: formatMessage({ id: 'This field is required' })
          }}
          render={({ field, fieldState: { error } }) => (
            <RolePicker value={field.value} roles={UserApplicationBuiltInRoles} error={error?.message} onChange={(value) => form.setValue('role', value)} />
          )}
        />
      </Stack>
    </FormDrawer>
  );
};