import { DialogContent } from '@mui/material';
import { ResponsiveDialog } from '../../../components/Dialogs/ResponsiveDialog';
import { useResponsive } from '../../../utils/useResponsive';
import { RenewAccessTokenContent } from './RenewAccessTokenContent';

type RenewAccessTokenDialogProps = {
  token: string;
}

export const RenewAccessTokenDialog = (props: RenewAccessTokenDialogProps) => {
  const { token } = props;
  const { isMobile } = useResponsive();

  return !isMobile ? <ResponsiveDialog open maxWidth='lg'>
    <DialogContent>
      <RenewAccessTokenContent token={token} />

    </DialogContent>
  </ResponsiveDialog>
    : <RenewAccessTokenContent token={token} />;
};