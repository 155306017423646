import PersonIcon from '@mui/icons-material/Person';
import { Avatar, AvatarProps, Tooltip, Typography, TypographyVariant } from '@mui/material';
import React, { useMemo } from 'react';
import { getAvatarColor, getInitials } from '../../utils/userUtils';

interface OwnProps {
  displayName: string | undefined;
  size?: 'tiny' | 'small' | 'medium' | 'large' | 'xlarge' | 'xxlarge' | 'default';
  textVariant?: TypographyVariant;
}

export type UserAvatarProps =
  & AvatarProps
  & OwnProps;

export const UserAvatar: React.FC<UserAvatarProps> = (props) => {
  const { displayName, size, textVariant, ...avatarProps } = props;

  let length: string | undefined = undefined;
  let fontSize: string | undefined = undefined;
  switch (size) {
    case 'tiny':
      length = '16px';
      fontSize = '11px';
      break;
    case 'small':
      length = '24px';
      fontSize = '14px';
      break;
    case 'medium':
      length = '32px';
      fontSize = '12px';
      break;
    case 'large':
      length = '38px';
      fontSize = '16px';
      break;
    case 'xlarge':
      length = '52px';
      fontSize = '28px';
      break;
    case 'xxlarge':
      length = '80px';
      fontSize = '40px';
      break;
    case 'default':
      fontSize = '18px';
      break;
  }

  const avatarColor = useMemo(() => displayName ? getAvatarColor(displayName) : undefined, [displayName]);
  const textColor = '#FFFFFF';

  if (!displayName) {
    return (
      <Avatar sx={{ width: length, height: length }} {...avatarProps}>
        <PersonIcon />
      </Avatar>
    );
  }

  return (
    <Tooltip title={displayName}>
      <Avatar
        {...avatarProps}
        sx={{
          backgroundColor: avatarColor,
          WebkitTextFillColor: textColor,
          width: length,
          height: length,
          ...avatarProps.sx
        }}>
        <Typography variant={textVariant} fontSize={fontSize} color={(theme) => theme.palette.primary.contrastText}>{getInitials(displayName)}</Typography>
      </Avatar>
    </Tooltip>
  );
};

UserAvatar.defaultProps = {
  size: 'medium'
};