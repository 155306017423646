import { Stack } from '@mui/material';
import { FilterButtons, FilterButtonsProps } from './FilterButtons';

type Props<T extends string | number | symbol> = Pick<FilterButtonsProps<T>, 'options' | 'messages'> &
{
  value: T;
  onChange: (newValue: T) => void;
}

export const ToggleButtonGroupAlternate = <T extends string | number | symbol>(props: Props<T>) => {
  const { options, messages, value, onChange } = props;
  return <Stack direction={'row'} spacing={1}>
    <FilterButtons<T>
      options={options}
      messages={messages}
      defaultValue={value}
      selectedColors='secondary'
      unselectedColors='inherit'
      onChange={onChange}
    />
  </Stack>;
};