import { BudgetAndExpensesPermission, DocumentsPermission, FormsPermission, ManagePublicFileSharingPermission, TasksPermission, WorkflowsPermission } from 'gql/index';
import { MessageDescriptor, defineMessages } from 'react-intl';


export const budgetPermissionMessages: Record<BudgetAndExpensesPermission, MessageDescriptor> = defineMessages({
  NotAllowed: { id: 'Not allowed' },
  View: { id: 'Read' },
  Manage: { id: 'Full access' }
});

export const budgetPermissionDescriptionMessages: Record<BudgetAndExpensesPermission, MessageDescriptor> = defineMessages({
  NotAllowed: { id: 'Cannot view budget and expenses information.' },
  View: { id: 'Can view all budget and expenses information.' },
  Manage: { id: 'Can manage project budget and expenses.' }
});


export const formsPermissionMessages: Record<FormsPermission, MessageDescriptor> = defineMessages({
  LimitedAccess: { id: 'Limited access' },
  Manage: { id: 'Full access' }
});

export const formsPermissionDescriptionMessages: Record<FormsPermission, MessageDescriptor> = defineMessages({
  LimitedAccess: { id: 'Can fill forms sent to the user by an administrator.' },
  Manage: { id: 'Can manage and send forms to project members.' }
});


export const tasksPermissionMessages: Record<TasksPermission, MessageDescriptor> = defineMessages({
  LimitedAccess: { id: 'Limited access' },
  ViewAll: { id: 'Read' },
  Manage: { id: 'Full access' },
});

export const tasksPermissionDescriptionMessages: Record<TasksPermission, MessageDescriptor> = defineMessages({
  LimitedAccess: { id: 'Can view only tasks directly assigned to the user.' },
  ViewAll: { id: 'Can view all project tasks.' },
  Manage: { id: 'Can manage all project tasks.' },
});

export const documentsPermissionMessages: Record<DocumentsPermission, MessageDescriptor> = defineMessages({
  LimitedAccess: { id: 'Limited access' },
  Contributor: { id: 'Contributor' },
  Manage: { id: 'Full access' },
});

export const documentsPermissionDescriptionMessages: Record<DocumentsPermission, MessageDescriptor> = defineMessages({
  LimitedAccess: { id: 'Can view only documents directly assigned to the user.' },
  Contributor: { id: 'Can upload documents to folders they have been granted access to.' },
  Manage: { id: 'Can manage all project documents.' },
});

export const publicFileSharingPermissionMessages: Record<ManagePublicFileSharingPermission, MessageDescriptor> = defineMessages({
  NotAllowed: { id: 'Not allowed' },
  Allowed: { id: 'Full access' },
});

export const publicFileSharingPermissionDescriptionMessages: Record<ManagePublicFileSharingPermission, MessageDescriptor> = defineMessages({
  NotAllowed: { id: 'Cannot enable public access links to access files.' },
  Allowed: { id: 'Can enable public sharing on files which allows anyone with the link to view the file.' },
});


export const workflowsPermissionMessages: Record<WorkflowsPermission, MessageDescriptor> = defineMessages({
  LimitedAccess: { id: 'Limited access' },
  Manage: { id: 'Full access' },
});

export const workflowsPermissionDescriptionMessages: Record<WorkflowsPermission, MessageDescriptor> = defineMessages({
  LimitedAccess: { id: 'Can view only workflows user is a participant of.' },
  Manage: { id: 'Can manage all project workflows.' },
});