import { ReactComponent as BlankCanvas } from '@assets/images/undraw_blank_canvas.svg';
import { ReactComponent as ProjectTemplate } from '@assets/images/undraw_building_websites.svg';
import { Card, CardActionArea, CardContent, CardMedia, Divider, Stack, Typography, useTheme } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { NewProjectFormValues } from './AddProjectDialog';
import { NewProjectType } from './types';

type Props = {
  onTypeSelected: () => void;

};

export const SelectTypeOfProjectStep: React.FC<Props> = ({ onTypeSelected }) => {

  const { formatMessage } = useIntl();
  const { setValue } = useFormContext<NewProjectFormValues>();
  const theme = useTheme();

  return (<>
    <Stack pt={1} pb={4} gap={4} alignItems={'center'} flex={1}>
      <Stack direction={'row'} justifyContent={'center'} flexWrap={'wrap'} gap={6}>
        <Card elevation={2} sx={{ maxWidth: '300px' }}>
          <CardActionArea onClick={() => { setValue('projectType', NewProjectType.NewProject); onTypeSelected(); }} sx={{ height: '100%' }}>
            <CardMedia sx={{ pt: 2, px: 2, height: '180px' }}>
              <BlankCanvas fill={theme.palette.primary.main} width='100%' height='100%' />
            </CardMedia>

            <Divider style={{ width: '100%' }} />

            <CardContent sx={{ height: '100%' }}>
              <Typography variant="h5" color={'primary'}>
                {formatMessage({ id: 'Create a blank project' })}
              </Typography>
              <Typography variant="body2" pt={1}>
                {formatMessage({ id: 'If you plan to setup a project with initial content you might want to reuse, consider creating a project template.' })}
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>

        <Card elevation={2} sx={{ maxWidth: '300px', }} >
          <CardActionArea
            onClick={() => { setValue('projectType', NewProjectType.FromTemplate); onTypeSelected(); }}
            sx={{ height: '100%' }}>
            <CardMedia sx={{ pt: 2, px: 2, height: '180px' }}>
              <ProjectTemplate fill={theme.palette.primary.main} width='100%' height='100%' />
            </CardMedia>

            <Divider />

            <CardContent sx={{ height: '100%' }}>
              <Typography variant="h5" component="div" color={'primary'}>
                {formatMessage({ id: 'Use a template' })}
              </Typography>

              <Typography variant="body2" pt={1}>
                {formatMessage({ id: 'Start faster by using a pre-configured reusable project template.' })}
              </Typography>
            </CardContent>
          </CardActionArea>

        </Card>
      </Stack>
    </Stack >


  </>
  );
};