import Gantt, { EnrichedTask, viewMode } from 'frappe-gantt';
import { useEffect, useRef } from 'react';
import './GanttWrapper.module.css';
export type GanttOptions = Omit<Gantt.Options, 'on_date_change' | 'on_click' | 'on_progress_change' | 'on_view_change' | 'view_mode'>;

interface GanttWrapperProps {
  viewMode: Gantt.viewMode,
  options: GanttOptions,
  defaultTasks: Gantt.Task[],
  scrollYOffset?: number,
  className?: string,
  readOnly?: boolean,
  onTaskDateChange?: (task: Gantt.Task, start: Date, end: Date) => void;
  onViewModeChanged?: (viewMode: viewMode) => void;
  onTaskDoubleClick?: (task: EnrichedTask) => void;
}

// Converts a screen Y position to SVG units which have a viewBox transform
const screenYtoSVGUnits = (svg: SVGSVGElement, ctm: DOMMatrix, newYValue: number) => {
  const pt = svg.createSVGPoint();
  pt.x = 0;
  pt.y = newYValue;
  return pt.matrixTransform(ctm.inverse()).y;
};

export const GanttWrapper = (props: GanttWrapperProps) => {
  const chartDiv = useRef<HTMLDivElement>(null);
  const chartSvg = useRef<SVGSVGElement>(null);
  const ganttRef = useRef<Gantt>();

  useEffect(() => {
    ganttRef.current?.change_view_mode(props.viewMode);
  }, [props.viewMode]);

  useEffect(() => {
    ganttRef.current?.refresh(props.defaultTasks);
  }, [props.defaultTasks]);

  useEffect(() => {
    if (props.scrollYOffset == null) return;

    const svg = chartSvg.current;
    if (!svg) return;

    const ctm = svg.getCTM();
    let scrollY = props.scrollYOffset;
    if (ctm) {
      scrollY = screenYtoSVGUnits(svg, ctm, props.scrollYOffset);
    }

    const gridHeader = svg.getElementsByClassName('grid-header')[0];
    if (gridHeader) {
      gridHeader.setAttribute('y', scrollY.toString());
      gridHeader.setAttribute('zIndex', '1000');
      gridHeader.remove();
      svg.appendChild(gridHeader);
    }

    const dateHeader = svg.getElementsByClassName('date')[0];
    if (dateHeader) {
      dateHeader.setAttribute('transform', `translate(0, ${scrollY})`);
      dateHeader.remove();
      svg.appendChild(dateHeader);
    }
  }, [props.scrollYOffset]);

  useEffect(() => {
    if (ganttRef.current) {
      if (import.meta.env.DEV) {
        console.warn('DEV: GanttWrapper: Attempting to change options or callbacks after initialization.\nUpdated functions or options will be ignored - refresh page to see changes.');
      }
      return;
    }
    if (!chartSvg.current) {
      return;
    }

    ganttRef.current = new Gantt(chartSvg.current, props.defaultTasks, {
      ...props.options,
      view_mode: props.viewMode,
      on_date_change: props.onTaskDateChange,
      on_view_change: props.onViewModeChanged,
      on_click: props.onTaskDoubleClick
    });
    // We don't want to react on defaultTasks; that is handled by the refresh useEffect above. Same for viewMode.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.onTaskDateChange, props.onTaskDoubleClick, props.onViewModeChanged, props.options]);

  return (
    <div
      ref={chartDiv}
      onMouseDownCapture={(e) => !props.readOnly || e.nativeEvent.stopImmediatePropagation()}
      className={`${props.className} ${props.readOnly ? 'gantt-disabled' : ''}`}
    >
      <svg
        ref={chartSvg}
        width="100%"
        height="100%"
      />
    </div>
  );
};
