import { useCurrentProject } from '@modules/projects/utils/useCurrentProject';
import { AccordionDetails } from '@mui/material';
import { useGetMe } from '@utils/useGetMe';
import { ApplicationUser, WorkflowActionStatus } from 'gql/index';
import { ReactNode } from 'react';

interface OwnProps {
  actionStatus: WorkflowActionStatus
  participant?: Pick<ApplicationUser, 'id'>
  children?: ReactNode
}

export const ActionAccordionDetails: React.FC<OwnProps> = ({ participant, children }) => {
  const { me } = useGetMe();
  const { canManageWorkflows } = useCurrentProject();
  const canViewActionDetails = canManageWorkflows || participant?.id == me?.id;

  if (!canViewActionDetails) return undefined;

  return (
    <AccordionDetails sx={{ borderTop: (theme) => `1px solid ${theme.palette.divider}`, width: '100%', p: 2 }}>
      {children}
    </AccordionDetails >
  );
};