import { FormHelperText } from '@mui/material';
import { useIntl } from 'react-intl';
import { ActionConfigurationComponentProps } from '../../WorkflowEditor/Actions/types';
import { WorkflowFieldInput } from '../../WorkflowEditor/Fields/WorkflowFieldInput';
import { ConfigureDocumentSignatureActionDefinition } from './ConfigureDocumentSignatureActionDefinition';


export const ConfigureDocumentSignatureActionConfiguration: React.FC<ActionConfigurationComponentProps> = () => {
  const { formatMessage } = useIntl();

  return (
    <>
      <WorkflowFieldInput
        fieldId={ConfigureDocumentSignatureActionDefinition.FieldId_SignatureConfigureBy}
        label={formatMessage({ id: 'Signature to be configured by' })}

      />
      <FormHelperText>
        {formatMessage({ id: 'After the workflow is started and reaches this action, the user assigned with this role will be asked to select the document to sign, assign a signatory and place the signature fields over the PDF.' })}
      </FormHelperText>
    </>
  );
};