import { useCurrentProject } from '@modules/projects/utils/useCurrentProject';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { useMeetingTypesQuery } from 'gql/index';
import React from 'react';
import { MeetingTypeCard } from '../MeetingTypeCard';

type Props = {
  onSelected: (id: number) => void;
};

export const MeetingTypeSelector: React.FC<Props> = ({ onSelected }) => {
  const { projectId } = useCurrentProject();

  const { data: meetingTypes } = useMeetingTypesQuery({ projectId }, { select: d => d.meetingTypes });

  return <>
    <Grid2 container spacing={4}>
      {meetingTypes?.map(meetingType =>
        <Grid2 xs={12} sm={6} key={meetingType.id}>
          <MeetingTypeCard
            meetingType={meetingType}
            onClick={() => onSelected(meetingType.id)}
          />
        </Grid2>
      )}
    </Grid2>
  </>;
};