import { useDebounce } from '@utils/useDebounce';
import { useContext } from 'react';
import { useFillFormValueMutation, useFillFormValuePatMutation } from '../../../../../gql';
import { AnonymousContext } from '../../../../projectAccessTokens/AnonymousContextProvider';
import { FormFieldValues } from '../types';
import { useFormFillerHelpers } from '../useFormFillerHelpers';
import { formFillerValueToFormValueInput } from '../utils';

export const autoSaveTimeout = 500;

export const useFormFieldFillerForm = () => {
  const { accessToken } = useContext(AnonymousContext);

  const { onFormFillSaving, onFormFillSuccess: onSuccess, onFormFillError: onError } = useFormFillerHelpers();

  const { mutate: fillFormValue } = useFillFormValueMutation({ onError, onSuccess });
  const { mutate: fillFormValuePAT } = useFillFormValuePatMutation({ onError, onSuccess });

  const onSubmit = useDebounce((value: FormFieldValues) => {
    onFormFillSaving();
    if (accessToken == null) {
      fillFormValue({ input: { formValue: formFillerValueToFormValueInput(value) } });
    } else {
      fillFormValuePAT({ input: { accessToken: accessToken ?? '', formValue: formFillerValueToFormValueInput(value) } });
    }
  }, autoSaveTimeout);

  return { onSubmit };
};