import { ActionMenu } from '@components/ActionMenu';
import { ConfirmDialog } from '@components/ConfirmDialog';
import { Delete, Edit } from '@mui/icons-material';
import { ListItemIcon, MenuItem } from '@mui/material';
import { useNotification } from '@utils/useNotification';
import { useQueryInvalidator } from '@utils/useQueryInvalidator';
import { useDeleteWorkflowVariableMutation, useWorkflowTemplateQuery } from 'gql/index';
import { useState } from 'react';
import { useIntl } from 'react-intl';

interface Props {
  id: number;
  onEditClicked: () => void;
}

export const WorkflowRoleActions: React.FC<Props> = ({ id, onEditClicked }) => {
  const { formatMessage } = useIntl();
  const { notifySuccess } = useNotification();
  const invalidateQuery = useQueryInvalidator();

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const { mutate: deleteWorkflowRole } = useDeleteWorkflowVariableMutation({
    onSuccess: () => {
      notifySuccess(formatMessage({ id: 'Role deleted successfully' }));
      invalidateQuery(useWorkflowTemplateQuery);
    }
  });

  return <>
    <ActionMenu>
      <MenuItem onClick={onEditClicked}>
        <ListItemIcon>
          <Edit />
        </ListItemIcon>
        {formatMessage({ id: 'Edit' })}
      </MenuItem>

      <MenuItem onClick={() => setIsDeleteDialogOpen(true)}>
        <ListItemIcon>
          <Delete />
        </ListItemIcon>
        {formatMessage({ id: 'Delete' })}
      </MenuItem>
    </ActionMenu>

    <ConfirmDialog
      open={isDeleteDialogOpen}
      onCancel={() => setIsDeleteDialogOpen(false)}
      title={formatMessage({ id: 'Delete role' })}
      content={formatMessage({ id: 'Are you sure you want to delete this role? Any assignments will be cleared.' })}
      onConfirm={() => deleteWorkflowRole({ input: { workflowVariableId: id } })}
      confirmColor='error'
      confirmText={formatMessage({ id: 'Delete' })}
    />
  </>;
};