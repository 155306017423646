import { getTenantIdentifier } from '@utils/getTenantIdentifier';
import { useGetMe } from '@utils/useGetMe';
import Cookies from 'js-cookie';
import React, { useEffect } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { LoadingScreen } from '../../../components/LoadingScreen';
import { BuiltInRoles, SubscriptionStatus, useTenantSubscriptionDetailsQuery, useTenantThemeQuery } from '../../../gql';
import { InactiveSubscriptionModal } from '../components/InactiveSubscriptionModal';
import { NavigationMenu } from '../components/NavigationMenu';
import { SettingsDrawer } from '../components/SettingsDrawer';
import { TopBar } from '../components/TopBar/TopBar';
import { TrialEndedModal } from '../components/TrialEndedModal';
import { shouldShowJiraWidget } from '../utils';

interface Props {
  allowedRoles?: BuiltInRoles[];
}

function initializeJiraHelpDesk() {
  const jhdScript = document.createElement('script');
  jhdScript.type = 'text/javascript';
  jhdScript.setAttribute('data-jsd-embedded', '');
  jhdScript.setAttribute('data-key', 'aee7cf86-f96f-4222-acdd-c16a27a17ae8');
  jhdScript.setAttribute('data-base-url', 'https://jsd-widget.atlassian.com');
  jhdScript.src = 'https://jsd-widget.atlassian.com/assets/embed.js';

  jhdScript.onload = function () {
    const event = new Event('DOMContentLoaded');
    window.document.dispatchEvent(event);
  };

  document.getElementsByTagName('head')[0].appendChild(jhdScript);
}


export const AuthenticatedPageLayout: React.FC<Props> = ({ allowedRoles }) => {
  const { me, isLoading, isFetched, isExternalUser } = useGetMe();
  const { data: tenantTheme } = useTenantThemeQuery(undefined, { select: d => d.tenantTheme });

  const { data: subscriptionDetails } = useTenantSubscriptionDetailsQuery(undefined, { select: d => d.tenantSubscriptionDetails });

  // 'paused' status means a trial has ended and no payment method was yet provided
  const isTrialEndedAndPendingPayment = subscriptionDetails?.subscription?.status === SubscriptionStatus.Paused;
  const location = useLocation();

  const isOrganizationMember = !isExternalUser;

  const isAuthorized = allowedRoles == null
    ? (me?.builtInRoles.length ?? 0 > 0)
    : me?.builtInRoles.some(r => allowedRoles.includes(r));

  useEffect(() => {
    const isWidgetShown = shouldShowJiraWidget({
      isBrandingEnabled: tenantTheme?.isBrandingEnabled ?? true,
      isExternalUser
    });

    if (!isWidgetShown) return;

    const alreadyInitializedWidget = document.getElementById('jsd-widget');

    if (alreadyInitializedWidget) return;

    initializeJiraHelpDesk();
  }, [isAuthorized, isExternalUser, tenantTheme?.isBrandingEnabled]);

  if (isLoading) {
    return (<LoadingScreen />);
  }


  if (me == null && isFetched) {
    Cookies.remove('tenantIdentifier');
    const identifier = getTenantIdentifier();
    if (identifier) {
      return <Navigate to={`/${identifier}/login?returnUrl=${location.pathname}`} />;
    } else {
      return <Navigate to={`/?returnUrl=${location.pathname}`} />;
    }
  }

  if (me && isAuthorized === false) {
    return <Navigate to={'/' + getTenantIdentifier()} />;
  }

  return <>
    {(isTrialEndedAndPendingPayment && isOrganizationMember) && (
      <TrialEndedModal />
    )}
    {(subscriptionDetails?.subscription != null
      && subscriptionDetails.subscription.status !== SubscriptionStatus.Active
      && subscriptionDetails.subscription.status !== SubscriptionStatus.InTrial
      && isOrganizationMember) && (<InactiveSubscriptionModal />)}

    <TopBar />

    {!isExternalUser && (
      <NavigationMenu />
    )}

    <SettingsDrawer />

    <main style={{ flex: 1, display: 'flex', flexDirection: 'column', overflow: 'auto' }}>
      <Outlet />
    </main>
  </>;
};