import { useFolderName } from '@modules/folders/utils/folderUtils';
import { useCurrentProject } from '@modules/projects/utils/useCurrentProject';
import { AppBar, Skeleton, Stack, Typography } from '@mui/material';
import { useFolderPropertiesQuery } from 'gql/index';
import React from 'react';
import { FolderBasicProperties } from './FolderBasicProperties';
import { FolderPermissions } from './FolderPermissions';

interface Props {
  folderId: number;
}

export const FolderPropertiesList: React.FC<Props> = ({ folderId }) => {
  const { projectId } = useCurrentProject();
  const { canManageDocuments } = useCurrentProject();
  const { getFolderName } = useFolderName();
  const { data: folder } = useFolderPropertiesQuery({ folderId, projectId }, { select: d => d.folder });

  return (
    <>
      <AppBar position='static' elevation={1} sx={{ py: 1, px: 2, boxShadow: 'none', borderBottom: '1px solid', borderColor: 'divider' }}>
        {folder ? (
          <Typography variant='body1' color='text.primary'>
            {getFolderName(folder)}
          </Typography>
        ) : (
          <Skeleton />
        )}
      </AppBar>

      <Stack p={1} gap={2}>
        <FolderBasicProperties folderId={folderId} />

        {canManageDocuments && folder && (
          <FolderPermissions folder={folder} />
        )}
      </Stack>
    </>
  );
};