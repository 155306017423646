import { FormControl, FormControlLabel, FormHelperText, FormLabel, Radio, RadioGroup } from '@mui/material';
import { useValidationRules } from '@utils/useValidationRules';
import { Controller, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { FormFillerValues } from '../types';
import { FieldFillerProps } from './FieldFiller';
import { useFieldPresenceUpdateEvents } from './useFieldPresenceUpdateEvents';
import { useFormFieldFillerForm } from './useFormFieldFillerForm';

export const BooleanFiller: React.FC<FieldFillerProps> = ({ index, field: formField, disabled }) => {
  const { notEmpty } = useValidationRules();
  const { formatMessage } = useIntl();

  const { control, getValues } = useFormContext<FormFillerValues>();

  const updatePresenceEvents = useFieldPresenceUpdateEvents(formField.id);
  const { onSubmit } = useFormFieldFillerForm();

  const parseBooleanOption = (option: string): 'true' | 'false' | '' => {
    if (option === 'false') {
      return 'false';
    }

    if (option === 'true') {
      return 'true';
    }

    return '';
  };

  return (
    <Controller
      control={control}
      name={`values.${index}.boolean`}

      rules={{
        validate: {
          ...formField.isRequired && { notEmpty }
        }
      }}
      render={({ field, fieldState: { error } }) => {
        return (
          <FormControl required={formField.isRequired} error={!!error} disabled={disabled}>
            <FormLabel>{formField.name}</FormLabel>
            <RadioGroup
              row
              {...field}
              value={field.value ?? ''}

              {...updatePresenceEvents}
              onChange={(e) => {
                field.onChange(e);
                setTimeout(() => onSubmit({ ...getValues(`values.${index}`), boolean: parseBooleanOption(e.target.value) }));
              }}
            >
              <FormControlLabel value="true" control={<Radio />} label={formatMessage({ id: 'Yes' })} />
              <FormControlLabel value="false" control={<Radio />} label={formatMessage({ id: 'No' })} />
            </RadioGroup>
            <FormHelperText>{error?.message}</FormHelperText>
          </FormControl>
        );
      }}
    />
  );
};