import { useCurrentProject } from '@modules/projects/utils/useCurrentProject';
import { CircularProgress, Stack } from '@mui/material';
import { useProjectFormQuery } from 'gql/index';
import { useParams } from 'react-router-dom';
import { FormEditorView } from './views/FormEditorView';
import { FormViewerView } from './views/FormViewerView';

export const FormPage: React.FC = () => {
  const { formId: formIdString } = useParams();
  const formId = Number(formIdString);

  const { isArchived, canManageForms } = useCurrentProject();

  const { data: projectForm } = useProjectFormQuery({ formId }, { select: d => d.projectForm });

  if (!projectForm) {
    return (
      <Stack width='100%' height='100%' alignItems='center' justifyContent='center'>
        <CircularProgress />
      </Stack>
    );
  }

  const formViewer = <FormViewerView />;
  const formEditor = <FormEditorView />;

  if (isArchived) {
    return formViewer;
  }

  if (canManageForms) {
    return formEditor;
  }

  return formViewer;
};
