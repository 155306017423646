import { GridColType, GridRenderCellParams, GridRenderEditCellParams, GridTreeNodeWithRender, GridValidRowModel } from '@mui/x-data-grid-pro';
import { MatrixColumnType, MatrixValueInput } from 'gql/index';

export const matrixColumnTypeToGridColType: Record<MatrixColumnType, GridColType> = {
  [MatrixColumnType.Text]: 'string',
  [MatrixColumnType.Numerical]: 'number',
  [MatrixColumnType.Boolean]: 'boolean',
};

export const valueGetterByColumnType: Record<MatrixColumnType, (value?: Partial<MatrixValueInput>) => string | number | boolean | undefined | null> = {
  [MatrixColumnType.Text]: (value) => value?.text,
  [MatrixColumnType.Numerical]: (value) => value?.number,
  [MatrixColumnType.Boolean]: (value) => value?.boolean,
};

export const updateValueToFormValue = (value: unknown): MatrixValueInput => {
  if (typeof value == 'string') return { text: value, number: undefined, boolean: undefined };
  if (typeof value == 'number') return { text: undefined, number: value, boolean: undefined };
  if (typeof value == 'boolean') return { text: undefined, number: undefined, boolean: value };
  throw new Error('Unknown value type was entered into the matrix editor. Ignoring.');

};

export type RenderFn = (<T extends GridValidRowModel>(params: GridRenderCellParams<T, unknown, unknown, GridTreeNodeWithRender>) => React.ReactNode);
export type RenderEditorFn = (<T extends GridValidRowModel>(params: GridRenderEditCellParams<T, unknown, unknown, GridTreeNodeWithRender>) => React.ReactNode);