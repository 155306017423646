import { Navigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { getTenantIdentifier } from '../../../utils/getTenantIdentifier';
import { displayTenantSwitcherAfterLoginState } from '../../application/atoms';

export const TenantRootPage = () => {
  const [displayTenantSwitcherAfterLogin] = useRecoilState(displayTenantSwitcherAfterLoginState);

  if (displayTenantSwitcherAfterLogin) {
    return <Navigate to={`/${getTenantIdentifier()}/switch`} replace />;

  }

  return <Navigate to={`/${getTenantIdentifier()}/projects`} replace />;
};