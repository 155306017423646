import { Error, Warning } from '@mui/icons-material';
import { Alert, Paper, Typography } from '@mui/material';
import { useTenantSettingsQuery } from 'gql/index';
import { useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { FilePicker } from './FilePicker';
import { AskSignatureFormValues } from './RequestSignatureDialog';

export const RequestSignatureSelectDocumentStep = () => {
  const { formatMessage } = useIntl();

  const { control, setValue } = useFormContext<AskSignatureFormValues>();

  const { data: tenantSettings } = useTenantSettingsQuery({}, { staleTime: 600000, select: p => p.tenantSettings });

  const noCreditsLeft = useMemo(() => {
    return tenantSettings && tenantSettings.isSignDocumentByZohoEnabled && tenantSettings.remainingZohoSignatureRequests <= 0;
  }, [tenantSettings]);

  return <>
    {(tenantSettings?.isSignDocumentByZohoEnabled && tenantSettings?.remainingZohoSignatureRequests <= 3) &&
      <Alert color={noCreditsLeft ? 'error' : 'warning'} icon={noCreditsLeft ? <Error /> : <Warning />}>
        <Typography variant='body1'>{formatMessage({ id: 'You only have {nbSignatures} signatures left, contact Ariv to recharge {email}.' }, { nbSignatures: tenantSettings?.remainingZohoSignatureRequests, email: <a href='mailto: support@ariv.ca'>support@ariv.ca</a> })}</Typography>
      </Alert>
    }

    <Controller
      control={control}
      name='documentId'
      render={({ field }) =>
        <Paper sx={{ p: 2, maxHeight: 500, overflow: 'auto' }}>
          <FilePicker selectedDocumentId={field.value} onDocumentSelected={(documentId) => setValue('documentId', documentId ?? '')} />
        </Paper>
      }
    />
  </>;
};