import { Box, Button } from '@mui/material';
import { getTenantIdentifier } from '@utils/getTenantIdentifier';
import { useTenantSubscriptionDetailsQuery } from 'gql/index';
import { useIntl } from 'react-intl';
import { SectionTitle } from '../SectionTitle';

export const SubscriptionSection = () => {
  const { formatMessage } = useIntl();
  const { data: subscription } = useTenantSubscriptionDetailsQuery();

  if (!subscription?.tenantSubscriptionDetails.subscription == null) {
    return undefined;
  }

  const onManageSubscriptionClicked = async () => {
    location.replace(`/${getTenantIdentifier()}/api/Subscription/Manage`);
  };

  if (!subscription?.tenantSubscriptionDetails.hasSubscription) {
    return undefined;
  }

  return <Box padding={2}>
    <SectionTitle title={formatMessage({ id: 'Subscription' })} />
    <Button
      variant='text'
      onClick={onManageSubscriptionClicked}>
      {formatMessage({ id: 'Manage my subscription ' })}

    </Button>
  </Box >;
};