import { ActionMenu, ActionMenuProps } from '@components/ActionMenu';
import { useCurrentProject } from '@modules/projects/utils/useCurrentProject';
import { PermissionsModal } from '@modules/securityGroups/components/PermissionsModal/PermissionsModal';
import { AddTask, Delete, Draw, Share } from '@mui/icons-material';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { ProjectTask, ProjectTaskType, ShareableResourceType } from 'gql/index';
import { merge } from 'lodash';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { getTenantIdentifier } from '../../../../utils/getTenantIdentifier';
import { TaskFormDrawer } from '../TaskFormDrawer';
import { DeleteTaskDialog } from './DeleteTaskDialog';

interface Props extends Omit<ActionMenuProps, 'open' | 'onOpen'> {
  task: Pick<ProjectTask, 'id' | 'type' | 'name'>;
  hideEdit?: boolean;
}

export const TaskActions: React.FC<Props> = ({ task, hideEdit, ...moreMenuProps }) => {
  const { formatMessage } = useIntl();
  const [isConfirmRemoveDialogOpen, setIsConfirmRemoveDialogOpen] = useState(false);
  const navigate = useNavigate();

  const { projectId, canManageTasks, isArchived } = useCurrentProject();

  const [moreMenuDrawerOpen, setMoreMenuDrawerOpen] = useState(false);

  const [isSharingDialogOpen, setIsSharingDialogOpen] = useState(false);

  const [isEditDrawerOpen, setIsEditDrawerOpen] = useState(false);

  const [isAddingSubtaskDrawerOpen, setAddingSubtaskDrawerOpen] = useState(false);

  const onDeleteSuccess = () => {
    setMoreMenuDrawerOpen(false);
    setIsConfirmRemoveDialogOpen(false);
    navigate(`/${getTenantIdentifier()}/projects/${projectId}/tasks`);
  };

  if (!canManageTasks || isArchived) {
    return null;
  }

  return <>
    <ActionMenu
      {...moreMenuProps}
      open={moreMenuDrawerOpen}
      onOpen={setMoreMenuDrawerOpen}
      iconButtonProps={merge({}, { color: 'inherit' }, moreMenuProps.iconButtonProps)}
    >
      {!hideEdit && <MenuItem onClick={() => setIsEditDrawerOpen(true)}>
        <ListItemIcon><Draw /></ListItemIcon>
        <ListItemText primary={formatMessage({ id: 'Edit' })} />
      </MenuItem>}

      {task.type == ProjectTaskType.Custom && (
        <MenuItem onClick={() => setIsSharingDialogOpen(true)}>
          <ListItemIcon><Share /></ListItemIcon>
          <ListItemText primary={formatMessage({ id: 'Share' })} />
        </MenuItem>
      )}

      {task.type == ProjectTaskType.Custom &&
        <MenuItem onClick={() => setAddingSubtaskDrawerOpen(true)}>
          <ListItemIcon><AddTask /></ListItemIcon>
          <ListItemText primary={formatMessage({ id: 'Add subtask' })} />
        </MenuItem>}

      {task.type == ProjectTaskType.Custom &&
        <MenuItem onClick={() => setIsConfirmRemoveDialogOpen(true)}>
          <ListItemIcon><Delete /></ListItemIcon>
          <ListItemText primary={formatMessage({ id: 'Delete' })} />
        </MenuItem>}
    </ActionMenu>

    <TaskFormDrawer
      taskId={task.id}
      open={isEditDrawerOpen}
      onClose={() => setIsEditDrawerOpen(false)}
    />

    <TaskFormDrawer
      parentTaskId={task.id}
      open={isAddingSubtaskDrawerOpen}
      onClose={() => setAddingSubtaskDrawerOpen(false)}
    />

    <DeleteTaskDialog
      projectId={projectId}
      taskId={task.id}
      isOpen={isConfirmRemoveDialogOpen && document != null}
      onCancel={() => setIsConfirmRemoveDialogOpen(false)}
      onDeleteSuccess={onDeleteSuccess}
    />

    <PermissionsModal
      resource={{ resourceType: ShareableResourceType.Task, id: task.id, name: task.name }}
      open={isSharingDialogOpen}
      onClose={() => setIsSharingDialogOpen(false)}
    />
  </>;
};