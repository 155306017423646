import { EditorSavingStatus, SavingStatusContext } from '@components/Editor/TopBar/SavingStatusContext';
import { useEditorHelpers } from '@components/Editor/TopBar/useEditorHelpers';
import { Droppable } from '@hello-pangea/dnd';
import { alpha, Stack } from '@mui/material';
import { notNullOrUndefined } from '@utils/notNullOrUndefined';
import { useCreateFormSectionMutation, useDeleteFormSectionMutation, useEditFormSectionMutation, useReorderFormSectionsMutation } from 'gql/index';
import React, { useCallback, useContext, useEffect } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { getBorderColor } from '../../../../../components/Utils/DroppableComponentUtils';
import { DroppableEmptyState } from '../DroppableEmptyState';
import { useSectionCreatedListener, useSectionMovedListener } from '../events';
import { FormEditorDroppableIds, FormEditorDroppableTypes, FormEditorSectionValues, FormEditorValues } from '../types';
import { DraggableFormSection } from './DraggableFormSection';

export const DroppableFormSections: React.FC = () => {
  const { formatMessage } = useIntl();
  const { control, getValues, setValue } = useFormContext<FormEditorValues>();
  const formId = useWatch({ control, name: 'id' });

  const { fields: sections, insert, remove, update, move } = useFieldArray({ control, name: 'sections', keyName: 'uniqueId' });

  const { setEditorSavingStatus, editorSavingStatus } = useContext(SavingStatusContext);
  const { onEditionError, onEditionSuccess } = useEditorHelpers();

  const { mutate: createFormSection, isLoading: isCreatingFormSection } = useCreateFormSectionMutation({ onError: onEditionError, onSuccess: onEditionSuccess });
  const { mutate: editSection, isLoading: isEditingSection } = useEditFormSectionMutation({ onError: onEditionError, onSuccess: onEditionSuccess });
  const { mutate: reorderSections, isLoading: isReorderingProjectSections } = useReorderFormSectionsMutation({ onError: onEditionError, onSuccess: onEditionSuccess });
  const { mutate: deleteSection, isLoading: isDeletingSection } = useDeleteFormSectionMutation({ onError: onEditionError, onSuccess: onEditionSuccess });

  useEffect(() => {
    const isSaving = isCreatingFormSection || isEditingSection || isReorderingProjectSections || isDeletingSection;
    if (isSaving && editorSavingStatus !== EditorSavingStatus.Saving) {
      setEditorSavingStatus(EditorSavingStatus.Saving);
    }
  }, [editorSavingStatus, isCreatingFormSection, isEditingSection, isReorderingProjectSections, isDeletingSection, setEditorSavingStatus]);

  const onSectionSubmitted = useCallback((sectionIndex: number, section: FormEditorSectionValues) => {
    const sectionId = getValues(`sections.${sectionIndex}.id`);
    if (sectionId) {
      editSection({
        input: {
          formDefinitionId: formId,
          id: sectionId,
          name: section.name
        }
      }, {
        onSuccess: () => setValue(`sections.${sectionIndex}.name`, section.name)
      });
    } else {
      createFormSection({
        input: {
          formDefinitionId: formId,
          name: section.name,
          order: sectionIndex
        }
      }, {
        onSuccess: d => {
          const newId = d.createFormSection.formSection?.id;
          if (!newId) {
            remove(sectionIndex);
            return;
          }
          update(sectionIndex, { id: newId, name: section.name, fields: [] });
        },
        onError: () => {
          remove(sectionIndex);
        }
      });

    }
  }, [getValues, editSection, formId, setValue, createFormSection, update, remove]);

  useSectionCreatedListener(({ destinationId, createdAtIndex }) => {
    if (destinationId === FormEditorDroppableIds.fieldsSelectionPanel) return;
    insert(createdAtIndex, {
      name: '',
      fields: []
    });
  });

  useSectionMovedListener(({ sourceIndex, destinationIndex }) => {
    if (sourceIndex === destinationIndex) return;

    move(sourceIndex, destinationIndex);

    const sectionIds = sections.map(s => s.id).filter(notNullOrUndefined);

    const [removed] = sectionIds.splice(sourceIndex, 1);
    sectionIds.splice(destinationIndex, 0, removed);

    reorderSections({
      input: {
        formDefinitionId: formId,
        sectionIdsInOrder: sectionIds
      }
    }, {
      onError: () => {
        move(destinationIndex, sourceIndex);
      }
    });

  });


  const onSectionDeleted = (index: number) => () => {
    const section = getValues(`sections.${index}`);

    if (!section.id) return remove(index);
    deleteSection({
      input: {
        formDefinitionId: formId,
        id: section.id
      }
    }, {
      onError: () => {
        insert(index, section);
      }
    });

    remove(index);
  };



  return (
    <Stack pt={2} gap={2}>
      <Droppable droppableId={FormEditorDroppableIds.sections} type={FormEditorDroppableTypes.section}>
        {(provided, snapshot) => (
          <Stack gap={2} pb={15} {...provided.droppableProps} ref={provided.innerRef} sx={{
            bgcolor: t => snapshot.isDraggingOver ? alpha(t.palette.primary.main, t.palette.action.hoverOpacity) : t.palette.background.default,
            boxShadow: 'none',
            border: getBorderColor(snapshot.isDraggingOver, sections.length)
          }}>
            {sections.map((section, index) => (
              <DraggableFormSection
                key={section.uniqueId}
                uniqueId={section.uniqueId}
                onSubmit={section => onSectionSubmitted(index, section)}
                sectionIndex={index}
                section={section}
                actionsMenu={sections.length > 1}
                onDeleted={onSectionDeleted(index)}
              />
            ))}

            {provided.placeholder}

            {(sections.length === 0 || snapshot.isDraggingOver) && (
              <DroppableEmptyState placeholderText={formatMessage({ id: 'Sections can be dropped here' })} />
            )}
          </Stack>
        )}
      </Droppable>

    </Stack>
  );
};