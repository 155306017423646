import { CancelButton } from '@components/Buttons/CancelButton';
import { useCurrentProject } from '@modules/projects/utils/useCurrentProject';
import { LoadingButton } from '@mui/lab';
import { Dialog, DialogContent, DialogProps, DialogTitle, Stack, TextField } from '@mui/material';
import { useNotification } from '@utils/useNotification';
import { useQueryInvalidator } from '@utils/useQueryInvalidator';
import { useValidationRules } from '@utils/useValidationRules';
import { DocumentFragmentFragment, useFolderEntriesQuery, useProjectTasksQuery, useRenameDocumentMutation } from 'gql/index';
import { Controller, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';

interface RenameDocumentDialog extends DialogProps {
  document: DocumentFragmentFragment;
}

interface FormValues {
  filename: string;
}

export const RenameDocumentDialog: React.FC<RenameDocumentDialog> = ({ document, ...dialogProps }) => {
  const { formatMessage } = useIntl();
  const { notEmpty } = useValidationRules();
  const { notifySuccess } = useNotification();
  const invalidateQuery = useQueryInvalidator();
  const { projectId } = useCurrentProject();

  const { control, handleSubmit } = useForm<FormValues>({
    defaultValues: { filename: document.fileName }
  });

  const { mutate: renameDocument, isLoading } = useRenameDocumentMutation({
    onSuccess: () => {
      dialogProps.onClose?.({}, 'backdropClick');
      notifySuccess(formatMessage({ id: 'Document renamed successfully' }));
      invalidateQuery(useFolderEntriesQuery, { projectId, folderId: document.folderEntry?.folderId });
      invalidateQuery(useProjectTasksQuery, { projectId });
    }
  });

  const onSubmit = (values: FormValues) => {
    renameDocument({
      input: {
        projectId,
        documentId: document.id,
        fileName: values.filename
      }
    });
  };

  return (
    <Dialog {...dialogProps} fullWidth onClose={isLoading ? undefined : dialogProps.onClose}>
      <DialogTitle>{formatMessage({ id: 'Rename document' })}</DialogTitle>

      <DialogContent sx={{ overflow: 'visible' }}>
        <Controller
          name='filename'
          control={control}
          rules={{ validate: notEmpty }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              autoFocus
              fullWidth
              label={formatMessage({ id: 'Name' })}
              required
              error={!!error}
              helperText={error?.message}
            />
          )}
        />
      </DialogContent>

      <Stack direction='row' gap={1} p={1} width='100%' justifyContent='end'>
        <CancelButton onClick={() => dialogProps.onClose?.({}, 'backdropClick')} disabled={isLoading} />

        <LoadingButton variant='contained' onClick={handleSubmit(onSubmit)} loading={isLoading}>
          {formatMessage({ id: 'Submit' })}
        </LoadingButton>
      </Stack>
    </Dialog>
  );
};