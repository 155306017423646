import { UnstyledLink } from '@components/UnstyledLink';
import { List, ListItem, ListItemIcon, ListItemText, Paper, Stack, Typography } from '@mui/material';
import { ReactNode } from 'react';

interface OwnProps {
  titleText: string
  ItemIcon: ReactNode,
  ItemName: string,
  linkPath: string | undefined
}

export const CompletedActionContentItemLink: React.FC<OwnProps> = ({ titleText, ItemIcon, ItemName, linkPath }) => {
  return (
    <Stack gap={1}>
      <Typography variant='subtitle2'>{titleText}</Typography>
      <Paper>
        <List>
          <ListItem>
            <ListItemIcon>
              {ItemIcon}
            </ListItemIcon>
            {linkPath && <UnstyledLink to={linkPath} >
              {ItemName}
            </UnstyledLink>}
            {!linkPath && <ListItemText>{ItemName}</ListItemText>}
          </ListItem>
        </List>
      </Paper>
    </Stack>
  );
};