import { PageContainer } from '@components/Layout/PageContainer';
import { ResponsiveButton } from '@components/ResponsiveButton';
import { useCurrentProject } from '@modules/projects/utils/useCurrentProject';
import { Add } from '@mui/icons-material';
import { useExpensesQuery } from 'gql/index';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { ExpenseDrawer } from '../components/Expenses/ExpenseDrawer';
import { ExpensesTable } from '../components/Expenses/ExpensesTable';

export const ProjectExpensesPage: React.FC = () => {
  const { projectId, canManageBudgetAndExpenses } = useCurrentProject();
  const { formatMessage } = useIntl();

  const { data: expenses, isFetching: isFetchingExpenses } = useExpensesQuery({ projectId }, { select: d => d.expenses });

  const [isAddingRow, setIsAddingRow] = useState(false);

  return (
    <PageContainer
      title={formatMessage({ id: 'Expenses' })}
      action={canManageBudgetAndExpenses &&
        <ResponsiveButton variant='contained' color='primary' icon={<Add />} onClick={() => setIsAddingRow(true)}>
          {formatMessage({ id: 'Add expense' })}
        </ResponsiveButton>
      }
    >
      <ExpensesTable expenses={expenses ?? []} loading={isFetchingExpenses} />

      <ExpenseDrawer
        open={isAddingRow}
        onClose={() => setIsAddingRow(false)}
      />
    </PageContainer>
  );
};