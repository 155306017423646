import { useCurrentProject } from '@modules/projects/utils/useCurrentProject';
import { WorkflowVariableFragmentFragment, WorkflowVariableValueTypes, useGetProjectMembersQuery } from 'gql/index';
import { useCallback } from 'react';
import { IWorkflowPrincipal, validateWorkflowPrincipal } from '../types';

export const useUserVariableResolver = () => {
  const { projectId } = useCurrentProject();
  const { data: projectMembers } = useGetProjectMembersQuery({ projectId }, { select: p => p.projectMembers });

  const resolveUser = useCallback((variable: WorkflowVariableFragmentFragment | null | undefined) => {
    if (variable?.variableValueType != WorkflowVariableValueTypes.User || variable?.serializedValue == null) {
      return undefined;
    }

    const principalObject = JSON.parse(variable.serializedValue);


    const principalValidation = validateWorkflowPrincipal(principalObject);

    if (!principalValidation.success) return undefined;

    const principal = principalObject as IWorkflowPrincipal;

    return projectMembers?.find(p => p.id == principal?.id);
  }, [projectMembers]);

  return resolveUser;
};