import { Stack, TextField, TextFieldProps, styled } from '@mui/material';
import { ReactNode } from 'react';


type Props<T extends string> = {
  options: T[];
  value?: T;
  onChange?: (newValue: T) => void;
  optionIcons?: Record<T, ReactNode>;
  optionMessages?: Record<T, string>;
} & Omit<TextFieldProps, 'onChange' | 'select' | 'size' | 'value'>;

const MinimalTextField = styled(TextField)(() => ({
  'fieldset': {
    borderStyle: 'hidden'
  },
  '&:hover fieldset': {
    borderStyle: 'solid'
  },
}));

export const MinimalSelect = <T extends string>({ options, value, optionIcons, optionMessages, onChange, ...textFieldProps }: Props<T>) => {

  const currentIcon = value ? optionIcons?.[value] : undefined;

  return (
    <Stack direction='row' alignItems='center' gap={0.5}>
      {currentIcon}

      <MinimalTextField
        {...textFieldProps}
        select
        size='small'
        SelectProps={{ native: true, ...textFieldProps.SelectProps }}
        value={value}
        onChange={e => onChange?.(e.target.value as T)}
      >
        {options.map(option => (
          <option key={option} value={option}>
            {optionMessages?.[option] ?? option}
          </option>
        ))}
      </MinimalTextField>
    </Stack>
  );
};