import { PersonAdd } from '@mui/icons-material';
import { Box, Button, Stack, Typography, useTheme } from '@mui/material';
import React from 'react';
import { useIntl } from 'react-intl';
type Props = {
  logoUrlPreview: string | undefined | null;
  themeMode: 'dark' | 'light';
}
export const EmailPreview: React.FC<Props> = ({ logoUrlPreview, themeMode }) => {
  const { formatMessage } = useIntl();
  const theme = useTheme();

  return <Stack
    alignSelf='center'
    width={600}
    bgcolor={theme.palette.background.default}
    borderRadius={1}
    border='1px solid'
    borderColor={theme.palette.divider}
  >
    <Stack
      direction={'row'}
      p={2}
      justifyContent='space-between'
      alignItems='center'
      border='1px solid'
      borderColor={theme.palette.divider}

    >
      <Box> {logoUrlPreview && <img src={logoUrlPreview} height={32} />}</Box>
      <Typography>{formatMessage({ id: 'Project' })}</Typography>
    </Stack>
    <Stack
      justifyContent='center'
      alignItems='center'
      border='1px solid'
      borderColor={theme.palette.divider}
      gap={4}
      p={4}
    >

      <PersonAdd color='primary' sx={{ fontSize: '60px' }} />

      <Typography variant="body1">
        {formatMessage({ id: 'You were invited to join the customer portal.' })}
      </Typography>
    </Stack>

    <Stack
      bgcolor={themeMode == 'dark' ? theme.palette.background.paper : theme.palette.grey[200]}
      justifyContent='center'
      alignItems='center'
      border='1px solid'
      p={4}
      borderColor={theme.palette.divider}
    >
      <Button variant="contained" color="primary">
        {formatMessage({ id: 'Join platform' })}
      </Button>
    </Stack>
  </Stack >;
};