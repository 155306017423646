import { useCurrentProject } from '@modules/projects/utils/useCurrentProject';
import { Stack, Typography } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { ProjectTaskType, useGetProjectMembersQuery, useProjectTasksQuery } from 'gql/index';
import React from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { TaskImportFormValues } from '../types';
import { TaskImportUserMapping } from './TaskImportUserMapping';

export const TaskImportOptionsStep: React.FC<{ disabled?: boolean; }> = ({ disabled }) => {
  const { formatMessage, formatDate } = useIntl();

  const { control } = useFormContext<TaskImportFormValues>();

  const { projectId: currentProjectId } = useCurrentProject();

  const projectToImportFromId = useWatch({ control, name: 'projectToImportFromId' });

  const { data: tasks } = useProjectTasksQuery({
    projectId: projectToImportFromId ?? 0,
    filter: {
      type: {
        eq: ProjectTaskType.Custom
      }
    }
  }, {
    select: d => d.projectTasks,
    enabled: !!projectToImportFromId
  });

  const usersToFetch = tasks?.flatMap(t => t.assignees).map(a => a.id);

  const earliestDate = tasks?.map(t => dayjs(t.startDate)).sort((a, b) => a.diff(b))[0];

  const { data: selectedProjectMembers } = useGetProjectMembersQuery({
    projectId: projectToImportFromId ?? 0,
    filter: {
      id: {
        in: usersToFetch
      }
    }
  }, {
    select: d => d.projectMembers,
    enabled: !!projectToImportFromId && !!usersToFetch?.length
  });
  const { data: currentProjectMembers } = useGetProjectMembersQuery({ projectId: currentProjectId }, { select: d => d.projectMembers });

  return <>
    <Stack gap={2}>
      <Stack maxWidth='440px'>
        <Typography variant='h5'>
          {formatMessage({ id: 'Start date' })}
        </Typography>
        {earliestDate && (
          <Typography>
            {formatMessage({ id: 'The earliest date from the selected tasks is {date}. Select a new starting date in order to recalculate all dates.' }, {
              date: formatDate(earliestDate.toDate())
            })}
          </Typography>
        )}
      </Stack>

      <Controller
        control={control}
        name='newStartDate'
        render={({ field }) => (
          <DesktopDatePicker
            disabled={disabled}
            slotProps={{ field: { clearable: true } }}
            label={formatMessage({ id: 'Start date' })}
            value={field.value}
            onChange={date => field.onChange(date)}
            sx={{ maxWidth: '400px' }}
          />
        )}
      />

      <Stack maxWidth='440px'>
        <Typography variant='h5'>
          {formatMessage({ id: 'User mapping' })}
        </Typography>
        <Typography>
          {formatMessage({ id: 'Configure the user mapping to be used at import. Unmapped users will not be assigned to imported tasks.' })}
        </Typography>
      </Stack>

      <Stack gap={1}>
        <Stack direction='row' gap={4} alignItems='center'>
          <Typography fontWeight={600} sx={{ width: '250px', px: 2 }}>
            {formatMessage({ id: 'Source' })}
          </Typography>

          <div></div>

          <Typography fontWeight={600} px={4}>
            {formatMessage({ id: 'Destination' })}
          </Typography>
        </Stack>

        {selectedProjectMembers?.map(member => (
          <TaskImportUserMapping
            user={member}
            disabled={disabled}
            optionUsers={currentProjectMembers ?? []}
          />
        ))}
      </Stack>
    </Stack>
  </>;
};