import { Stack, TextField } from '@mui/material';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { ActionConfigurationComponentById, AllActionIds } from '../../Actions/types';
import { WorkflowEditorActionValue } from '../../types';

type Props = {
  actionConfiguration: WorkflowEditorActionValue;
};

export const ActionConfiguration: React.FC<Props> = ({ actionConfiguration }) => {

  const { control } = useFormContext<WorkflowEditorActionValue>();
  const { formatMessage } = useIntl();
  const Component = ActionConfigurationComponentById[actionConfiguration.workflowActionDefinitionId as AllActionIds];


  if (!Component) {
    console.error(`Action configuration component not found for ${actionConfiguration.workflowActionDefinitionId}`);
    return null;
  }

  return <>

    <Stack p={2} gap={2} flex={1}>
      <Controller
        control={control}
        name='name'
        rules={{ required: formatMessage({ id: 'This field is required.' }) }}
        render={({ field, fieldState }) => <TextField {...field}
          error={fieldState.error?.message != null}
          helperText={fieldState.error?.message}
          required
          label={formatMessage({ id: 'Action name' })} />}
      />
      <Component key={actionConfiguration.id} actionConfiguration={actionConfiguration} />
    </Stack>

  </>;
};