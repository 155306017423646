import { UserAvatar } from '@components/UserAvatar/UserAvatar';
import { Checkbox, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { ApplicationUser } from 'gql/index';
import { ProjectSectionListItem } from '../Sections/ProjectSectionList';


interface Props {
  member: Pick<ApplicationUser, 'id' | 'fullName' | 'business' | 'title' | 'email'>;
  onClick?: () => void;
  selected?: boolean;
  disabled?: boolean;
  disableAvatarIcon?: boolean;
  disableSecondaryText?: boolean;
  disableSorroundBorders?: boolean;
}

export const MemberListItem: React.FC<Props> = ({ member, onClick, selected, disabled, disableAvatarIcon, disableSecondaryText, disableSorroundBorders }) => {
  const getSecondaryText = () => {
    if (member.business && member.title) {
      return `${member.business} - ${member.title}`;
    } else {
      return member.business ?? member.title ?? member.email;
    }
  };

  const innerComponents = <>
    {!disableAvatarIcon &&

      <ListItemIcon>
        <UserAvatar displayName={member.fullName} size='large' />
      </ListItemIcon>
    }

    <ListItemText
      primary={member.fullName}
      secondary={disableSecondaryText ? undefined : getSecondaryText()}
    />
  </>;

  return (
    <ProjectSectionListItem
      disablePadding={Boolean(onClick)}
      secondaryAction={selected !== undefined && (
        <Checkbox checked={selected} disabled={disabled} onClick={onClick} />
      )}
      sx={{
        border: t => `1px solid ${t.palette.divider}`,
        borderBottomWidth: 0,
        ...disableSorroundBorders ? {
          borderLeftWidth: 0,
          borderRightWidth: 0,
          ':first-of-type': {
            borderTopWidth: 0
          }
        } : {
          ':last-child': { borderBottomWidth: 1 },
          ':first-of-type': { borderTopWidth: 1 },
        }
      }}
    >
      {onClick ? (
        <ListItemButton onClick={onClick} selected={selected} disabled={disabled}>
          {innerComponents}
        </ListItemButton>
      ) : innerComponents}
    </ProjectSectionListItem>
  );
};