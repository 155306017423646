import { UserAvatar } from '@components/UserAvatar/UserAvatar';
import { ArrowForward } from '@mui/icons-material';
import { Chip, MenuItem, Stack, TextField } from '@mui/material';
import { ProjectMemberFragmentFragment } from 'gql/index';
import React, { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { TaskImportFormValues } from '../types';

interface Props {
  user: ProjectMemberFragmentFragment;
  optionUsers: ProjectMemberFragmentFragment[];
  disabled?: boolean;
}

export const TaskImportUserMapping: React.FC<Props> = ({ user, optionUsers, disabled }) => {
  const { formatMessage } = useIntl();

  const { control, setValue } = useFormContext<TaskImportFormValues>();

  useEffect(() => {
    if (optionUsers.some(optionUser => optionUser.id === user.id)) {
      setValue(`userMapping.${user.id}`, user.id);
    }
  }, [optionUsers, setValue, user.id]);

  return (
    <Stack direction='row' gap={4} alignItems='center'>
      <Stack direction='row' alignItems='center' sx={{
        border: '1px solid',
        borderColor: t => t.palette.mode === 'light' ? 'rgba(0, 0, 0, 0.23)' : 'rgba(255, 255, 255, 0.23)',
        width: '250px', px: 2, height: '56px'
      }}>
        <Chip avatar={<UserAvatar displayName={user.fullName} />} label={user.fullName} />
      </Stack>

      <ArrowForward />

      <Controller
        control={control}
        name={`userMapping.${user.id}`}
        render={({ field }) => (

          <TextField
            select
            disabled={disabled}
            value={field.value ?? ''}
            onChange={e => field.onChange(e.target.value)}
            sx={{ width: '400px' }}
            SelectProps={{ sx: { height: '56px' }, displayEmpty: true }}
          >
            <MenuItem value=''>
              {formatMessage({ id: 'Do not map this user' })}
            </MenuItem>
            {optionUsers.map(optionUser => (
              <MenuItem key={optionUser.id} value={optionUser.id}>
                <Chip avatar={<UserAvatar displayName={optionUser.fullName} />} label={optionUser.fullName} />
              </MenuItem>
            ))}
          </TextField>
        )}
      />
    </Stack>
  );
};