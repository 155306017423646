import { CheckCircleOutline, RadioButtonChecked, RadioButtonUnchecked } from '@mui/icons-material';
import { ApplicationUser, FormSettings, FormStatus, ProjectForm, SubmissionStatus } from 'gql/index';
import { ReactNode } from 'react';
import { MessageDescriptor, defineMessages } from 'react-intl';

export interface ReactHookFormFieldArrayElement {
  uniqueId: string;
}

export const FormStatusType: Record<FormStatus, MessageDescriptor> = defineMessages({
  [FormStatus.Draft]: { id: 'Draft' },
  [FormStatus.Published]: { id: 'Published' },
  [FormStatus.Responded]: { id: 'Responded' },
  [FormStatus.Sent]: { id: 'Sent' },
});

export const FormSubmissionStatusMessage: Record<SubmissionStatus, MessageDescriptor> = defineMessages({
  [SubmissionStatus.IsResponding]: { id: 'Pending' },
  [SubmissionStatus.Submitted]: { id: 'Submitted' },
  [SubmissionStatus.Closed]: { id: 'Closed' },
});


export const FormSubmissionStatusIcon: Record<SubmissionStatus, ReactNode> = {
  IsResponding: <RadioButtonUnchecked fontSize='inherit' />,
  Submitted: <RadioButtonChecked fontSize='inherit' color='info' />,
  Closed: < CheckCircleOutline fontSize='inherit' color='success' />
};



export type ChipColors = 'default' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
export const FormStatusTypeColor: Record<FormStatus, ChipColors> = {
  [FormStatus.Draft]: 'info',
  [FormStatus.Published]: 'secondary',
  [FormStatus.Responded]: 'success',
  [FormStatus.Sent]: 'secondary',
};


export type FormRow = (Pick<ProjectForm, 'id' | 'status' | 'publishedOn'>
  & { 'formName': string; }
  & { 'createdBy': string | null; }
  & { 'submissionsCount': number; }
  & {
    'formSettings': Pick<FormSettings, 'formType' | 'allowedRespondents'>
    &
    { 'allowedUsersRespondents': Pick<ApplicationUser, 'id'>[]; }
    & {
      'allowedSecurityGroupsRespondents': { 'members': Pick<ApplicationUser, 'id'>[]; }[];
    };
  }
);