import { FormDrawer } from '@components/Drawers/FormDrawer';
import { EditorSavingStatus, SavingStatusContext } from '@components/Editor/TopBar/SavingStatusContext';
import { LoadingButton } from '@mui/lab';
import { useQueryInvalidator } from '@utils/useQueryInvalidator';
import { useResponsive } from '@utils/useResponsive';
import { useUpdateWorkflowActionConfigurationMutation, useWorkflowTemplateErrorsQuery } from 'gql/index';
import { useContext, useEffect } from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { WorkflowEditorContext } from '../../WorkflowEditorContext';
import { WorkflowEditorActionValue, WorkflowEditorValues } from '../../types';
import { mapActionFragmentToForm } from '../../utils';
import { ActionConfiguration } from './ActionConfiguration';

export const ActionStaticSidePanel = () => {
  const { formatMessage } = useIntl();
  const { getValues } = useFormContext<WorkflowEditorValues>();
  const invalidateQuery = useQueryInvalidator();

  const { isMobile } = useResponsive();

  const { selectedActionIndex, setSelectedActionIndex, workflowDefinition, actionDefinitionsMap, setNotConfiguredActionId } = useContext(WorkflowEditorContext);
  const selectedAction = selectedActionIndex == null ? null : getValues('actions')[selectedActionIndex];

  const form = useForm<WorkflowEditorActionValue>({ defaultValues: selectedAction ?? {} });
  const { editorSavingStatus, setEditorSavingStatus } = useContext(SavingStatusContext);
  const { mutate: updateAction, isLoading } = useUpdateWorkflowActionConfigurationMutation();

  useEffect(() => {
    if (isLoading && editorSavingStatus !== EditorSavingStatus.Saving) {
      setEditorSavingStatus(EditorSavingStatus.Saving);
    }
  }, [editorSavingStatus, isLoading, setEditorSavingStatus]);

  const mainWorkflowForm = useFormContext<WorkflowEditorValues>();
  useEffect(() => {
    selectedAction && form.reset(selectedAction, { keepDefaultValues: false });
  }, [form, selectedAction]);


  const onSubmit = (values: WorkflowEditorActionValue) => {
    if (selectedAction == null) return;

    updateAction({
      input: {
        workflowActionId: selectedAction?.id,
        workflowDefinitionId: workflowDefinition.id,
        actionName: values.name,
        fieldConfigurations: values.fieldValues.map(p => ({
          fieldId: p.fieldId,
          serializedValue: p.value ? JSON.stringify(p.value.value) : null,
          variableId: p.value?.id == '' ? null : p.value?.id
        }))
      }
    }, {
      onSuccess: (response) => {
        invalidateQuery(useWorkflowTemplateErrorsQuery, { workflowId: workflowDefinition.id });
        setEditorSavingStatus(EditorSavingStatus.Saved);
        if (selectedActionIndex != null && response.updateWorkflowActionConfiguration.workflowActionConfiguration != null) {
          const updatedAction = response.updateWorkflowActionConfiguration.workflowActionConfiguration;
          const actionDefinition = actionDefinitionsMap[updatedAction.workflowActionDefinitionId];
          if (actionDefinition) {
            mainWorkflowForm.setValue(`actions.${selectedActionIndex}`,
              mapActionFragmentToForm(updatedAction, actionDefinition));
          }
        }
        setNotConfiguredActionId(selectedAction.id, false);
        setSelectedActionIndex(null);
      },
      onError: () => setEditorSavingStatus(EditorSavingStatus.Failed)
    });
  };

  return (
    <FormDrawer variant='temporary' anchor='left'
      PaperProps={{ sx: { pt: '126px' } }}

      showFooter
      open={selectedActionIndex != null}
      header={formatMessage({ id: 'Configure action' })}
      isLoading={isLoading}
      isFormDirty={form.formState.isDirty}
      isSubmitting={isLoading}
      onSave={form.handleSubmit(onSubmit)}
      onClose={() => {
        setSelectedActionIndex(null);
        setNotConfiguredActionId(selectedAction?.id, false);
      }}
      actionButtons={<LoadingButton onClick={form.handleSubmit(onSubmit)} />}
      sx={{
        zIndex: theme => theme.zIndex.appBar - 1,
        '& .MuiDrawer-paper': {
          width: isMobile ? '100%' : '400px',
        },
      }}>
      <FormProvider {...form}>
        {selectedActionIndex != null && selectedAction != null && <>
          <ActionConfiguration actionConfiguration={selectedAction} />
        </>}

      </FormProvider>
    </FormDrawer>
  );
};