
import { EditorSavingStatus } from '@components/Editor/TopBar/SavingStatusContext';
import { useContextSafe } from '@utils/useContextSafe';
import { useCallback } from 'react';
import { useErrorHandler } from '../../../application/useErrorHandler';
import { FormFillerContext } from './FormFillerContextProvider';

export const useFormFillerHelpers = () => {
  const { handleError } = useErrorHandler();
  const { setFormSavingStatus } = useContextSafe(FormFillerContext);

  const onFormFillSaving = useCallback(() => {
    setFormSavingStatus(EditorSavingStatus.Saving);
  }, [setFormSavingStatus]);

  const onFormFillError = useCallback((error: unknown) => {
    setFormSavingStatus(EditorSavingStatus.Failed);
    handleError(error);
  }, [handleError, setFormSavingStatus]);

  const onFormFillSuccess = useCallback(() => {
    setFormSavingStatus(EditorSavingStatus.Saved);
  }, [setFormSavingStatus]);

  return { onFormFillError, onFormFillSaving, onFormFillSuccess };
};