import { ChevronRight, ExpandMore } from '@mui/icons-material';
import { ButtonProps, IconButton, Stack } from '@mui/material';
import { GridRenderCellParams, gridFilteredDescendantCountLookupSelector, useGridApiContext, useGridSelector } from '@mui/x-data-grid-pro';
import { ReactNode } from 'react';

type OwnProps = Pick<GridRenderCellParams, 'id' | 'field' | 'rowNode'> & {
  children?: ReactNode;
  depthOffsetAmount?: number;
};

export const TreeDataGroupingCell: React.FC<OwnProps> = ({ id, field, rowNode, children, depthOffsetAmount }) => {

  const apiRef = useGridApiContext();
  const filteredDescendantCountLookup = useGridSelector(apiRef, gridFilteredDescendantCountLookupSelector);

  const filteredDescendantCount = filteredDescendantCountLookup[rowNode.id] ?? 0;
  const hasChildren = filteredDescendantCount > 0;

  const handleClick: ButtonProps['onClick'] = (event) => {
    if (rowNode.type !== 'group') {
      return;
    }

    apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded);
    apiRef.current.setCellFocus(id, field);
    event.stopPropagation();
  };
  return (
    <Stack direction='row' alignItems='center' gap={2} sx={{ ml: rowNode.depth * (depthOffsetAmount ?? 4) }} overflow='hidden'>
      <IconButton size='small' onClick={handleClick} sx={{ visibility: hasChildren ? 'visible' : 'hidden' }}>
        {'childrenExpanded' in rowNode && rowNode.childrenExpanded ? (
          <ExpandMore />
        ) : (
          <ChevronRight />
        )}
      </IconButton>
      {children}
    </Stack>
  );
};