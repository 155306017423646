import { ProjectSectionListItem } from '@modules/projects/components/Sections/ProjectSectionList';
import { ListItemButton, ListItemProps, ListItemText } from '@mui/material';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router';
import { ProjectTaskFragmentFragment } from 'src/gql/index';
import { getTenantIdentifier } from '../../../utils/getTenantIdentifier';
import { useCurrentProject } from '../../projects/utils/useCurrentProject';
import { useDueDateFormatter } from '../useDueDateFormatter';

type CustomTaskListItemProps = ListItemProps & {
  task: ProjectTaskFragmentFragment;
};


export const CustomTaskListItem = (props: CustomTaskListItemProps) => {
  const { task, ...listItemProps } = props;
  const { projectId } = useCurrentProject();
  const formatDueDate = useDueDateFormatter();
  const navigate = useNavigate();
  return <>
    <ProjectSectionListItem
      disablePadding
      key={task.id}
      {...listItemProps}
      onClick={undefined}
    >

      <ListItemButton onClick={() => navigate(`/${getTenantIdentifier()}/projects/${projectId}/tasks/${task.id}`)}>

        <ListItemText
          primary={task.name}
          secondary={task.dueDate ? formatDueDate(task.dueDate) : ''}
          secondaryTypographyProps={{ color: dayjs(task.dueDate).isBefore(dayjs()) ? 'error' : 'inherit' }}
          primaryTypographyProps={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
        />
      </ListItemButton>
    </ProjectSectionListItem >
  </>;
};