import { UserAvatar } from '@components/UserAvatar/UserAvatar';
import { Stack, Typography } from '@mui/material';

import { userRoleMessages } from '@modules/application/messages';
import { useGetMe } from '@utils/useGetMe';
import { BuiltInRoles } from 'gql/index';
import { sortBy } from 'lodash';
import React from 'react';
import { useIntl } from 'react-intl';
import { SignOutButton } from './SignOutButton';
import { TenantSwitcher } from './TenantSwitcher';

const rolesSortedByPriority: Partial<Record<BuiltInRoles, number>> = {
  Customer: -1,
  LimitedAccess: 0,
  Reader: 1,
  Contributor: 2,
  ProjectAdministrator: 3,
  TenantAdministrator: 4,
  MultiTenantAdministrator: 5,
};

export const UserSettingsSection: React.FC = () => {
  const { formatMessage } = useIntl();
  const { me } = useGetMe();

  const sortedRoles = sortBy(me?.builtInRoles, role => rolesSortedByPriority[role]);
  const bestRole = sortedRoles[sortedRoles.length - 1];
  const currentRole = bestRole ? formatMessage(userRoleMessages[bestRole]) : undefined;

  return (
    <Stack alignItems="center" padding={2} gap={2}>
      <UserAvatar displayName={me?.fullName ?? ''} size='xxlarge' textVariant='h3' />

      <Stack alignItems="center">
        <Typography variant='h6'>{me?.fullName}</Typography>
        {currentRole && (
          <Typography variant='subtitle1' color='text.secondary'>{currentRole}</Typography>
        )}
      </Stack>

      <TenantSwitcher />

      <SignOutButton />
    </Stack>
  );
};