import { ReactComponent as Loader } from '@assets/icons/loader.svg';
import { Modal, Stack, StackProps, styled, useTheme } from '@mui/material';
import React from 'react';

const StyledStack = styled(Stack)<StackProps>(({ theme }) => ({
  background: theme.palette.primary.main,
}));

export const LoadingScreen: React.FC = () => {
  const { palette } = useTheme();

  return <>
    <Modal open>
      <StyledStack alignItems='center' justifyContent='center' height='100%'>
        <Stack
          direction="row"
          justifyContent="center"
          maxWidth="300px"
          width="100%"
        >
          <Loader fill={palette.secondary.main} />
        </Stack>
      </StyledStack>
    </Modal>
  </>;
};
