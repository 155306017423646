import { Chip } from '@mui/material';
import { WorkFlowStatus } from 'gql/index';
import { useIntl } from 'react-intl';
import { WorkflowStatusIcon, WorkflowStatusType, WorkflowStatusTypeColor } from './types';

export const WorkflowStatusChip = (props: { status: WorkFlowStatus }) => {
  const { formatMessage } = useIntl();
  return <Chip label={formatMessage(WorkflowStatusType[props.status])}
    icon={WorkflowStatusIcon[props.status]}
    color={WorkflowStatusTypeColor[props.status]}
    sx={{
      color: 'inherit',
    }}
    size='small' />;
};