import { useFolderName } from '@modules/folders/utils/folderUtils';
import { useCurrentProject } from '@modules/projects/utils/useCurrentProject';
import { Alert, AlertTitle, Box } from '@mui/material';
import { GridCellModes, GridCellModesModel, GridCellParams, GridColDef } from '@mui/x-data-grid';
import { useGridApiRef } from '@mui/x-data-grid-pro';
import { RequiredProperties, fieldNotNull } from '@utils/NonNullableField';
import { notNullOrUndefined } from '@utils/notNullOrUndefined';
import { useQueryInvalidator } from '@utils/useQueryInvalidator';
import dayjs from 'dayjs';
import { Folder, FolderEntryFragmentFragment, FolderType, useChildFoldersQuery, useFolderEntriesQuery, useFolderPropertiesQuery, useProjectDocumentsQuery, useProjectFoldersQuery, useProjectTasksQuery, useRenameDocumentMutation, useRenameFolderMutation } from 'gql/index';
import { isObject } from 'lodash';
import React, { ReactNode, useCallback, useContext, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { ActionMenu } from '../../../../components/ActionMenu';
import { DataTable } from '../../../../components/DataTable';
import { useResponsive } from '../../../../utils/useResponsive';
import { DocumentActions } from '../../../documents/components/DocumentActions';
import { ExternalLinkActions } from '../ExternalLinkActions';
import { FolderActions } from '../FolderActions';
import { FolderEntryMoveActions } from '../FolderEntryMoveActions';
import { FolderImagesCarousel } from '../FolderImagesCarousel';
import { ProjectDocumentsContext } from '../ProjectDocumentsContextProvider';
import { FolderEntryRowSharedWithCell } from './FolderEntryRowSharedWithCell';
import { FolderRowNameCell } from './FolderRowNameCell';
import { FolderRowSharedWithCell } from './FolderRowSharedWithCell';

type MyFolder = Pick<Folder, 'id' | 'name' | 'parentFolderId' | 'path' | 'folderType' | 'modifiedOn'>;

type DocumentRow = RequiredProperties<FolderEntryFragmentFragment, 'document'> & {
  rowType: 'document';
  uniqueId: string; newName?: string;
};

type ExternalLinkRow = RequiredProperties<FolderEntryFragmentFragment, 'externalLink'> & {
  rowType: 'externalLink';
  uniqueId: string;
  newName?: string;
};

type EntryRow = DocumentRow | ExternalLinkRow;

type FolderRow = MyFolder & { rowType: 'folder'; uniqueId: string; newName?: string; };

export type FolderTableRow = FolderRow | EntryRow;

const rowModifiedOnGetter: Record<FolderTableRow['rowType'], (row: FolderTableRow) => string | undefined> = {
  'folder': row => (row as FolderRow).modifiedOn,
  'document': row => (row as DocumentRow).document.modifiedOn,
  'externalLink': row => (row as ExternalLinkRow).externalLink.modifiedOn,
};

const rowSharedWithGetter: Record<FolderTableRow['rowType'], (row: FolderTableRow) => number> = {
  'folder': row => (row as FolderRow).id,
  'document': row => (row as DocumentRow).id,
  'externalLink': row => (row as ExternalLinkRow).id,
};

const rowSharedWithRenderCell: Record<FolderTableRow['rowType'], (row: FolderTableRow) => ReactNode> = {
  'folder': row => <FolderRowSharedWithCell resourceId={(row as FolderRow).id} />,
  'document': row => <FolderEntryRowSharedWithCell resourceId={(row as DocumentRow).document.id} rowType={row.rowType} />,
  'externalLink': row => <FolderEntryRowSharedWithCell resourceId={(row as ExternalLinkRow).externalLink.id} rowType={row.rowType} />,
};


interface Props {
  loading?: boolean;
  entries: FolderEntryFragmentFragment[];
  folders: MyFolder[];
}

const commonGridColDefOptions: Partial<GridColDef> = {
  disableColumnMenu: true,
  disableExport: true,
  disableReorder: true,
  sortable: false,
};

const entryToRow = (entry: FolderEntryFragmentFragment): EntryRow | undefined => {
  if (fieldNotNull(entry, 'document')) {
    return {
      rowType: 'document',
      uniqueId: `document.${entry.document.id}`,
      ...entry,
    };
  } else if (fieldNotNull(entry, 'externalLink')) {
    return {
      rowType: 'externalLink',
      uniqueId: `externalLink.${entry.externalLink.id}`,
      ...entry
    };
  }
};

export const FolderTable: React.FC<Props> = ({ loading, entries, folders }) => {
  const { formatMessage } = useIntl();
  const { getFolderName } = useFolderName();
  const gridApiRef = useGridApiRef();
  const { isMobile, formatDateResponsive } = useResponsive();
  const invalidateQuery = useQueryInvalidator();

  const rowNameGetter: Record<FolderTableRow['rowType'], (row: FolderTableRow) => string> = useMemo(() => ({
    folder: row => getFolderName(row as FolderRow) ?? '',
    document: row => (row as DocumentRow).document.fileName,
    externalLink: row => (row as ExternalLinkRow).externalLink.name,
  }), [getFolderName]);

  const { projectId, canManageDocuments } = useCurrentProject();
  const { currentFolderId, breadcrumbPath, setBreadcrumbPath, isArchived, selectedEntryId, setSelectedEntryId, movingEntry, movingFolder } = useContext(ProjectDocumentsContext);
  const { data: currentFolder } = useFolderPropertiesQuery({ folderId: currentFolderId, projectId });

  const { mutate: renameFile } = useRenameDocumentMutation();
  const { mutate: renameFolder } = useRenameFolderMutation();

  const [cellModesModel, setCellModesModel] = React.useState<GridCellModesModel>({});

  const rows: FolderTableRow[] = useMemo(() => [
    ...folders.map<FolderRow>(f => ({ ...f, rowType: 'folder', uniqueId: `folder.${f.id}` })),
    ...entries.map(entryToRow).filter(notNullOrUndefined),
  ], [entries, folders]);

  const isConfidentialAlertShow = useMemo(() => {
    return !movingEntry && !movingFolder && currentFolder?.folder?.folderType == FolderType.ConfidentialDocument;
  }, [currentFolder?.folder?.folderType, movingEntry, movingFolder]);

  const columns: GridColDef<FolderTableRow>[] = useMemo(() => {
    const columns: GridColDef<FolderTableRow>[] = [];

    columns.push({
      ...commonGridColDefOptions,
      field: 'name',
      flex: 1,
      editable: true,
      headerName: formatMessage({ id: 'Name' }),
      valueGetter: ({ row }) => rowNameGetter[row.rowType](row),
      valueSetter: ({ row, value }) => ({ ...row, newName: value }),
      renderCell: (props) => <FolderRowNameCell onImageDocumentClicked={setSelectedImageDocumentId} {...props} />
    });

    if (!isMobile) {
      columns.push({
        ...commonGridColDefOptions,
        field: 'modifiedOn',
        flex: 1,
        type: 'date',
        maxWidth: 150,
        headerName: formatMessage({ id: 'Modified on' }),
        valueGetter: ({ row }) => rowModifiedOnGetter[row.rowType](row) != null ? dayjs(rowModifiedOnGetter[row.rowType](row)).toDate() : undefined,
        valueFormatter: ({ value }) => value != null ? formatDateResponsive(value) : ''
      });

      if (canManageDocuments) {
        columns.push({
          ...commonGridColDefOptions,
          field: 'sharedWith',
          flex: 1,
          headerName: formatMessage({ id: 'Shared with' }),
          valueGetter: ({ row }) => rowSharedWithGetter[row.rowType](row),
          renderCell: ({ row }) => rowSharedWithRenderCell[row.rowType](row),
        });
      }
    }

    if (!isArchived) {
      columns.push({
        ...commonGridColDefOptions,
        field: 'actions',
        headerName: '',
        maxWidth: 32,

        align: 'center',
        renderCell: ({ row }) => {
          if (row.rowType === 'folder' && row.folderType === FolderType.Custom) {
            return <FolderActions folder={row} renameAction={isMobile ? undefined : () => gridApiRef.current.startCellEditMode({ id: row.uniqueId, field: 'name' })} />;
          } else if (row.rowType === 'externalLink') {
            return canManageDocuments && (
              <ActionMenu keepMounted>
                <ExternalLinkActions externalLink={row.externalLink} />
                <FolderEntryMoveActions entry={row} />
              </ActionMenu>
            );
          } else if (row.rowType === 'document') {
            return (
              <ActionMenu keepMounted>
                <DocumentActions
                  document={row.document}
                  renameAction={isMobile ? undefined : () => gridApiRef.current.startCellEditMode({ id: row.uniqueId, field: 'name' })}
                />

                {canManageDocuments && (
                  <FolderEntryMoveActions entry={row} />
                )}
              </ActionMenu>
            );
          }

          return null;
        }
      });
    }

    return columns;
  }, [canManageDocuments, formatDateResponsive, formatMessage, gridApiRef, isArchived, isMobile, rowNameGetter]);


  const resetCellModes = () => {
    setCellModesModel((prevModel) => {
      return {
        // Revert the mode of the other cells from other rows
        ...Object.keys(prevModel).reduce(
          (acc, id) => ({
            ...acc,
            [id]: Object.keys(prevModel[id]).reduce(
              (acc2, field) => ({
                ...acc2,
                [field]: { mode: GridCellModes.View },
              }),
              {},
            ),
          }),
          {},
        )
      };
    });
  };

  const drillDownIntoFolder = useCallback((folderId: number) => {
    resetCellModes();
    setBreadcrumbPath([...breadcrumbPath, folderId]);
  }, [breadcrumbPath, setBreadcrumbPath]);


  const handleCellClick = useCallback((params: GridCellParams<FolderTableRow>, event: React.MouseEvent) => {
    if (params.field === 'actions') {
      return;
    }

    // Ignore portal
    if (!event.currentTarget.contains(event.target as Element)) {
      return;
    }


    if (params.cellMode === GridCellModes.Edit) {
      return;
    }

    if (Object.values(cellModesModel).flatMap(p => Object.values(p)).some(p => p.mode === GridCellModes.Edit)) {
      return;
    }

    if (params.row.rowType === 'folder') {
      return drillDownIntoFolder(params.row.id);
    }

    if (params.row.rowType === 'document' || params.row.rowType === 'externalLink') {
      return setSelectedEntryId?.(params.row.id);
    }
  }, [cellModesModel, drillDownIntoFolder, setSelectedEntryId]);

  const onRowUpdate = useCallback((newRow: FolderTableRow, oldRow: FolderTableRow) => {
    if (newRow.rowType === 'folder' && oldRow.rowType === 'folder') {
      if (!newRow.newName || newRow.newName.trim() === oldRow.name.trim()) return newRow;

      renameFolder({ input: { projectId, folderId: newRow.id, newName: newRow.newName } }, {
        onSuccess: () => {
          if (oldRow.parentFolderId == null) {
            invalidateQuery(useProjectFoldersQuery, { projectId });
          } else {
            invalidateQuery(useChildFoldersQuery, { parentFolderId: oldRow.parentFolderId });
          }
        }
      });
    }

    if (newRow.rowType === 'document' && oldRow.rowType === 'document') {
      if (!newRow.newName || newRow.newName.trim() === oldRow.document.fileName.trim()) return newRow;

      renameFile({ input: { projectId, documentId: newRow.document.id, fileName: newRow.newName } }, {
        onSuccess: () => {
          invalidateQuery(useProjectDocumentsQuery, { projectId });
          invalidateQuery(useFolderEntriesQuery, { projectId });
          invalidateQuery(useProjectTasksQuery, { projectId });
        }
      });
    }

    return newRow;
  }, [renameFolder, invalidateQuery, renameFile, projectId]);

  const onRowUpdateError = useCallback((error: unknown) => {
    if (isObject(error) && Object.keys(error).length === 0) {

      return;
    }
  }, []);


  const [selectedImageDocumentId, setSelectedImageDocumentId] = React.useState<number | undefined>(undefined);
  const isFolderCarouselOpen = selectedImageDocumentId !== undefined;


  return <>
    {isConfidentialAlertShow &&
      <Alert severity='info' sx={{ mb: 1 }}>
        <AlertTitle>{formatMessage({ id: 'Confidential documents' })}</AlertTitle>
        {formatMessage({ id: 'Only administrators have access to the documents present in this folder and this folder cannot be shared with other users. Files in this folder can be shared if necessary.' })}
      </Alert>
    }
    <DataTable<FolderTableRow>
      apiRef={gridApiRef}
      columns={columns}
      height={isMobile ? undefined : '100%'}
      rows={rows}
      getRowId={row => row.uniqueId}
      groupingColDef={_ => ({
        headerName: '',
        renderHeader: _ => <Box sx={{ width: 0 }} />,
        maxWidth: 0,
        minWidth: 0,
        width: 0,
        valueGetter: () => ''
      })}
      disableColumnFilter
      disableColumnMenu
      disableColumnSelector
      disableDensitySelector
      disableEval

      cellModesModel={cellModesModel}
      onCellModesModelChange={setCellModesModel}
      onCellClick={handleCellClick}

      disableRowSelectionOnClick={false}

      rowSelectionModel={rows.filter(r => r.id === selectedEntryId).map(r => r.uniqueId)}

      processRowUpdate={onRowUpdate}
      onProcessRowUpdateError={onRowUpdateError}

      isRowSelectable={({ row }) => row.rowType !== 'folder'}

      loading={loading}
      rowHeight={67}
      sx={{ '& .MuiDataGrid-cell': { cursor: 'pointer' }, '& .MuiDataGrid-columnHeaders': { border: 'none' } }}
      paperProps={{
        elevation: 0,
        sx: { boxShadow: 'none', backgroundColor: 'transparent', height: '100%' }
      }}
      noDataMessage={formatMessage({ id: 'No documents or folders' })}
    />

    <FolderImagesCarousel
      defaultDocumentId={selectedImageDocumentId}
      folderId={currentFolderId}
      open={isFolderCarouselOpen}
      onClose={() => setSelectedImageDocumentId(undefined)}
    />
  </>;
};
