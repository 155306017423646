import { userRoleMessages } from '@modules/application/messages';
import { useRoleAssignment } from '@modules/forms/utils/useRoleAssignment';
import { RoleAssignmentActions } from '@modules/securityGroups/components/Permissions/RoleAssignmentActions';
import { RoleAssignmentListItem } from '@modules/securityGroups/components/Permissions/RoleAssignmentListItem';
import { ShareableResourceItem } from '@modules/securityGroups/components/Permissions/ShareableResourceItem';
import { ShareableResource } from '@modules/securityGroups/components/Permissions/types';
import { PermissionsModalContext } from '@modules/securityGroups/components/PermissionsModal/PermissionsModal';
import { Box, Button, Chip, Stack, Typography } from '@mui/material';
import React, { useContext } from 'react';
import { useIntl } from 'react-intl';


interface Props {
  resource: ShareableResource;
}

export const OrganizationManageAccessView: React.FC<Props> = ({ resource }) => {
  const { userAssignments, sharedWithAllAssignment } = useRoleAssignment(resource);
  const { formatMessage } = useIntl();

  const { goToAccessSummary, goToGrantAccess } = useContext(PermissionsModalContext);

  return (
    <Stack gap={2}>
      <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
        <Typography variant='h5'>{formatMessage({ id: 'Manage Access' })}</Typography>
        {!sharedWithAllAssignment?.sharedWithAllProjectMembers &&
          <Button variant='contained' size='small' onClick={goToGrantAccess}>
            {formatMessage({ id: 'Grant Access' })}
          </Button>
        }
      </Stack>

      <ShareableResourceItem resource={resource} />

      <Box>
        {userAssignments.map(assignment => (
          <RoleAssignmentListItem
            key={assignment.id}
            onClick={() => goToAccessSummary(assignment)}
            roleAssignment={assignment}
            secondaryAction={(
              <Stack direction='row' gap={1} alignItems='center'>
                <RoleAssignmentActions resource={resource} roleAssignment={assignment} />
                <Chip variant='outlined' color='info' label={assignment.applicationRole && formatMessage(userRoleMessages[assignment.applicationRole.builtInRole])} />
              </Stack>
            )}
          />
        ))}

        {userAssignments.length === 0 && (
          <Typography align='center'>
            {formatMessage({ id: 'No users with access' })}
          </Typography>
        )}
      </Box>
    </Stack>
  );
};