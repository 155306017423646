import { FormDefinitionFragmentFragment, FormValueFragmentFragment, FormValueInput, ProjectAccessTokenQuery } from 'gql/index';
import React from 'react';
import { FormFiller } from '../../forms/components/FormFiller';
import { SignUpBanner } from '../components/SignUpBanner';

interface Props {
  submissionId: number;
  content: ProjectAccessTokenQuery['projectAccessTokenContent'];
  formDefinition: FormDefinitionFragmentFragment;
  formValues: FormValueFragmentFragment[];
  token: string;
  isLoading: boolean;
  isSubmitting: boolean;
  onSubmit: (values: FormValueInput[]) => void;
}

export const AnonymousFormDesktopView: React.FC<Props> = ({ onSubmit, submissionId, content, isLoading, formDefinition, formValues, isSubmitting }) => {
  return (
    <FormFiller
      isSubmitting={isSubmitting}
      disabled={isLoading}
      onSubmit={onSubmit}
      formDefinition={formDefinition}
      values={formValues}
      signUpBanner={content != null && content.project != null && content.tenant != null && content.forUser != null &&
        <SignUpBanner projectId={content.project.id} tenantDisplayName={content?.tenant?.name} userFullName={content?.forUser?.fullName} />
      }
      submissionId={submissionId}
    />
  );
};