import { CancelButton } from '@components/Buttons/CancelButton';
import { DataTable } from '@components/DataTable';
import { ResponsiveDialog } from '@components/Dialogs/ResponsiveDialog';
import { useCurrentProject } from '@modules/projects/utils/useCurrentProject';
import { fixHierarchyPaths } from '@modules/tasks/hierarchyHelpers';
import { Close } from '@mui/icons-material';
import { Box, Button, FormControl, FormHelperText, IconButton, InputLabel, MenuItem, Select, Stack } from '@mui/material';
import { DataGridProProps } from '@mui/x-data-grid-pro';
import { ProjectTaskFragmentFragment, ProjectTaskType, useProjectTasksQuery } from 'gql/index';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { TaskTreeDataGroupingCell } from '../TaskTreeDataGroupingCell';

interface Props {
  value: number | null;
  onChange: (value: number | null) => void;
  error?: boolean;
  disabled?: boolean;
  helperText?: string;
}

export const TaskSelectInput: React.FC<Props> = ({ value, onChange, error, disabled, helperText }) => {
  const { formatMessage } = useIntl();
  const { projectId } = useCurrentProject();

  const { data: tasks } = useProjectTasksQuery({ projectId }, { select: d => d.projectTasks.filter(t => t.type === ProjectTaskType.Custom) });
  const hierarchyFixedTasks = useMemo(() => fixHierarchyPaths(tasks ?? [], t => t.hierarchyPath), [tasks]);

  const [isSelectingDialogOpen, setIsSelectingDialogOpen] = useState(false);

  const groupingColDef: DataGridProProps['groupingColDef'] = {
    headerName: formatMessage({ id: 'Name' }),
    flex: 1,
    renderCell: props => <Box sx={{ cursor: 'pointer' }} width={'100%'}>  <TaskTreeDataGroupingCell disableLink {...props} /></Box>
  };

  const getTreeDataPath = useCallback((row: ProjectTaskFragmentFragment) =>
    row.hierarchyPath ? row.hierarchyPath.split('/').filter(Boolean) : [], []);

  const [currentValue, setCurrentValue] = useState<number | null>(value);
  useEffect(() => setCurrentValue(value), [value, isSelectingDialogOpen]);
  const onSelectClicked = () => {
    onChange(currentValue);
    setIsSelectingDialogOpen(false);
  };

  return <>
    <FormControl focused={isSelectingDialogOpen}>
      <InputLabel error={error} disabled={disabled} shrink={isSelectingDialogOpen || !!value}>{formatMessage({ id: 'Parent' })}</InputLabel>
      <Select
        label={formatMessage({ id: 'Parent' })}
        onClick={() => !disabled && setIsSelectingDialogOpen(true)}
        value={value}
        MenuProps={{ style: { visibility: 'hidden' } }}
        notched={isSelectingDialogOpen || !!value}
        disabled={disabled}
        error={error}
        endAdornment={value && (
          <IconButton size='small' disabled={disabled} onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            onChange(null);
          }}>
            <Close />
          </IconButton>
        )}
        sx={{ minWidth: '200px', '.MuiSelect-icon': { display: 'none' } }}
      >
        {hierarchyFixedTasks.map(task => (
          <MenuItem key={task.id} value={task.id.toString()}>
            {task.name}
          </MenuItem>
        ))}
      </Select>
      {helperText && <FormHelperText error={error}>{helperText}</FormHelperText>}
    </FormControl>

    <ResponsiveDialog title={formatMessage({ id: 'Select task' })} open={isSelectingDialogOpen} onClose={() => setIsSelectingDialogOpen(false)}>
      <Stack p={2} gap={2} overflow='hidden'>
        <Stack overflow='auto' flexGrow={1}>
          <DataTable
            autoHeight
            columns={[]}
            rows={hierarchyFixedTasks}

            rowSelection
            disableRowSelectionOnClick={false}
            rowSelectionModel={currentValue ? [currentValue] : []}
            onRowSelectionModelChange={(model) => setCurrentValue(Number(model.at(0)))}

            treeData
            groupingColDef={groupingColDef}
            defaultGroupingExpansionDepth={0}
            getTreeDataPath={getTreeDataPath}
          />
        </Stack>

        <Stack width='100%' direction='row' justifyContent='end' gap={2}>
          <CancelButton onClick={() => setIsSelectingDialogOpen(false)} />

          <Button variant='contained' onClick={onSelectClicked}>
            {formatMessage({ id: 'Select' })}
          </Button>
        </Stack>
      </Stack>
    </ResponsiveDialog>
  </>;
};