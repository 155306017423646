import { FileUploadState, UploadManagerContext } from '@components/FileUpload/UploadManagerContext';
import { FileIcon } from '@components/Icons/FileIcon';
import { Close } from '@mui/icons-material';
import { IconButton, LinearProgress, ListItem, ListItemIcon, ListItemText, Stack, Typography } from '@mui/material';
import { FormattedFileSize } from '@utils/FormattedFileSize';
import { useContext } from 'react';

interface OwnProps {
  file: FileUploadState;
}

export const UploadListItem: React.FC<OwnProps> = ({ file }) => {
  const { cancelUpload } = useContext(UploadManagerContext);

  const handleCloseButton = () => {
    cancelUpload(file.id);
  };

  const document: File = (file.fileUpload.formData.get('File')?.valueOf() as File);
  const progressColor = (file.state === 'aborted' || file.state === 'failed') ? 'error' : 'primary';
  return (
    <ListItem sx={{ flexWrap: 'wrap' }}>
      <ListItemIcon>
        <FileIcon fileName={document.name} fontSize='large' />
      </ListItemIcon>

      <ListItemText
        primary={document.name}
        secondary={<>{FormattedFileSize({ bytes: document.size })} • {file.fileUpload.path}</>}
        sx={{ minWidth: '30ch', maxWidth: '25%', textOverflow: 'ellipsis' }}
      />

      <ListItemText
        sx={{ flex: '1 1 40%' }}
        primary={(
          <Stack direction='row' alignItems='center' gap={1} justifyContent='right'>
            <Stack minWidth='70%'>
              <LinearProgress variant='determinate' color={progressColor} value={file.uploadProgress} />
            </Stack>
            <Typography variant='body2'>
              {Math.round(file.uploadProgress)}%
            </Typography>

            <IconButton onClick={handleCloseButton} style={{ visibility: (file.state === 'queued' || file.state === 'uploading') ? 'visible' : 'hidden' }}>
              <Close />
            </IconButton>
          </Stack>
        )}
      />
    </ListItem>
  );
};