import { EditorSavingStatus, SavingStatusContext } from '@components/Editor/TopBar/SavingStatusContext';
import { Draggable } from '@hello-pangea/dnd';
import { DragHandle, Error as ErrorIcon, Warning } from '@mui/icons-material';
import { Card, CardActionArea, CardActions, CardContent, Chip, ListItemSecondaryAction, Stack, Theme, Typography } from '@mui/material';
import { notNullOrUndefined } from '@utils/notNullOrUndefined';
import { useContextSafe } from '@utils/useContextSafe';
import { WorkflowVariableValueTypes } from 'gql/index';
import React, { useContext, useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { ActionIconByType, ActionNameByType } from '../../WorflowInstance/Actions/Types';
import { AllActionIds } from '../Actions/types';
import { WorkflowEditorContext } from '../WorkflowEditorContext';
import { WorkflowEditorValues } from '../types';
import './CardStyles.css';
import { WorkflowActionConfigurationActions } from './WorkflowActionConfigurationActions';

interface Props {
  actionIndex: number;
}

export const ActionTile: React.FC<Props> = ({ actionIndex }) => {
  const { formatMessage } = useIntl();

  const { setSelectedActionIndex, selectedActionIndex, disabled, errors, notConfiguredActionIds } = useContextSafe(WorkflowEditorContext);
  const { editorSavingStatus } = useContextSafe(SavingStatusContext);
  const { control } = useFormContext<WorkflowEditorValues>();
  const { workflowDefinition, findField } = useContext(WorkflowEditorContext);
  const isSelected = actionIndex === selectedActionIndex;

  const action = useWatch({ control: control, name: `actions.${actionIndex}` });

  const isActionBeingCreated = action.id <= 0;

  const partiticipants = action.fieldValues
    .filter(p => findField(action.workflowActionDefinitionId, p.fieldId)?.variableType === WorkflowVariableValueTypes.User)
    .map(p => workflowDefinition.variables.find(q => q.id === p.value?.id))
    .filter(notNullOrUndefined);

  const notConfigured = notConfiguredActionIds.includes(action.id);

  const border = useMemo(() => {
    if (isSelected) {
      return '1px solid';
    }

    if (errors[action.id] && !notConfigured) {
      return (t: Theme) => `1px solid ${t.palette.error.main}`;
    } else if (notConfigured) {
      return (t: Theme) => `1px solid ${t.palette.warning.main}`;
    }


    return 'default';
  }, [action.id, errors, isSelected, notConfigured]);

  return (
    <Draggable isDragDisabled={editorSavingStatus === EditorSavingStatus.Saving || disabled} draggableId={action.id.toString()} index={actionIndex}>{provided => (
      <Stack ref={provided.innerRef} {...provided.draggableProps} alignItems={'center'}>
        <Card
          className={action.id == 0 ? 'loading-linear' : undefined}
          sx={{
            pointerEvents: (disabled || isActionBeingCreated) ? 'none' : 'inherit',
            position: 'relative',
            width: 400,
            border,
            backgroundColor: isSelected ? 'action.selected' : 'default'
          }}
        >
          <CardActionArea disabled={disabled} onClick={() => setSelectedActionIndex(actionIndex)}>
            <Stack p={1} justifyContent={'center'} alignItems={'center'} {...provided.dragHandleProps} sx={{ visibility: disabled ? 'hidden' : 'visible' }}>
              <DragHandle />
            </Stack>

            <CardContent sx={{ pt: 0 }}>
              <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} spacing={1} flex={1}>
                <Stack direction={'row'} alignItems={'center'} gap={1} flexWrap='wrap'>
                  <Stack gap={1} direction='row'>
                    {ActionIconByType[action.workflowActionDefinitionId as AllActionIds]}

                    <Typography variant='body1' fontWeight={600}>
                      {isActionBeingCreated ? formatMessage(ActionNameByType[action.workflowActionDefinitionId as AllActionIds]) : action.name}
                    </Typography>
                  </Stack>

                </Stack>
              </Stack>
            </CardContent>

            <CardActions sx={{ p: 2 }} >
              <Stack gap={2}>
                <Stack direction='row' spacing={2} flexWrap={'wrap'}>
                  {partiticipants.map(p => <Chip size='small' key={p.id} label={p.name}></Chip>)}
                </Stack>

                {notConfigured && (

                  <Chip size='small' color='warning' icon={<Warning />} label={formatMessage({ id: 'Not configured' })} />
                )}

                {errors[action.id] && !notConfigured && (

                  <Chip size='small' color='error' icon={<ErrorIcon />} label={formatMessage({ id: 'Invalid configuration' })} sx={{ textWrap: 'wrap' }} />

                )}
              </Stack>
            </CardActions>


          </CardActionArea>

          {!disabled && !isActionBeingCreated && (
            <ListItemSecondaryAction>
              <WorkflowActionConfigurationActions workflowTemplateId={workflowDefinition.id} workflowActionConfiguration={action} />
            </ListItemSecondaryAction>
          )}
        </Card>
      </Stack>
    )
    }</Draggable>
  );
};