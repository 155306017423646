import { ReactComponent as InternalFormIcon } from '@assets/icons/internal-form.svg';
import { FileIconType, getFileTypeIconAsUrl } from '@fluentui/react-file-type-icons';
import { Icon, IconProps, SxProps, Theme } from '@mui/material';
import React, { useMemo } from 'react';

interface FileTypeIconProps extends IconProps {
  fileName?: string;
  fileIconType?: FileIconType | 'InternalForm';
  iconSx?: SxProps<Theme>;
}

export const getFileExtension = (fileName: string) => fileName.split('.').reverse()[0];

export const FileIcon: React.FC<FileTypeIconProps> = ({ fileName, fileIconType, iconSx, ...iconProps }) => {
  const iconUrl = useMemo(() => fileIconType === 'InternalForm' ? undefined : getFileTypeIconAsUrl({
    extension: fileName ? getFileExtension(fileName) : undefined,
    size: 96,
    imageFileType: 'svg',
    type: fileIconType
  }), [fileIconType, fileName]);

  if (!fileName && !fileIconType) {
    console.error('FileIcon: either fileName or fileIconType must be provided');
    return null;
  }

  return (
    <Icon {...iconProps} sx={{ ...iconSx, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      {iconUrl && <img src={iconUrl} style={{ width: 'inherit', height: 'inherit' }} color='inherit' />}
      {fileIconType === 'InternalForm' && <InternalFormIcon style={{ width: 'inherit', height: 'inherit' }} />}
    </Icon>
  );
};
