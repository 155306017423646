import { getTenantIdentifier } from '@utils/getTenantIdentifier';
import { useNotification } from '@utils/useNotification';
import { useQueryInvalidator } from '@utils/useQueryInvalidator';
import { FormType, SubmissionStatus, useFillFormMutation, useProjectFormQuery, useProjectFormSubmissionQuery, useProjectFormSubmissionsQuery, useProjectTaskQuery, useProjectTasksQuery } from 'gql/index';
import { useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import { useCurrentProject } from '../../../../projects/utils/useCurrentProject';
import { FormFiller } from '../../../components/FormFiller';
import { FormViewerView } from './FormViewerView';

export const FormFillerView: React.FC = () => {
  const { formId: formIdString, submissionId: submissionIdString } = useParams();
  const { projectId } = useCurrentProject();
  const formId = Number(formIdString);
  const selectedSubmissionId = submissionIdString ? Number(submissionIdString) : undefined;

  const navigate = useNavigate();
  const { notifySuccess } = useNotification();
  const { formatMessage } = useIntl();
  const invalidateQuery = useQueryInvalidator();

  const { data: projectForm } = useProjectFormQuery({ formId }, {
    cacheTime: 0,
    keepPreviousData: false,
    select: d => d.projectForm,
  });

  const { data: selectedSubmissionData } = useProjectFormSubmissionQuery({ submissionId: selectedSubmissionId ?? -1 }, {
    cacheTime: 0,
    staleTime: 0,
    keepPreviousData: false,
    select: d => d.projectFormSubmission,
    enabled: !!selectedSubmissionId
  });

  const selectedSubmission = selectedSubmissionId ? selectedSubmissionData : undefined;

  const { mutate: fillForm, isLoading: isSubmitting } = useFillFormMutation({
    onSuccess: () => {
      invalidateQuery(useProjectTaskQuery, { projectId: projectForm?.projectId });
      invalidateQuery(useProjectTasksQuery, { projectId: projectForm?.projectId });
      invalidateQuery(useProjectFormSubmissionsQuery, { projectId, formId });
      invalidateQuery(useProjectFormSubmissionQuery, { submissionId: selectedSubmissionId ?? -1 });

      if (projectForm?.formSettings.formType === FormType.SelfServe) {
        navigate('..');
      } else {
        navigate(`/${getTenantIdentifier()}/projects/${projectForm?.projectId}/home`);
      }

      notifySuccess(formatMessage({ id: 'Form successfully submitted.' }));
    }
  });

  const onSubmit = () => {
    if (selectedSubmissionId == null) return;

    fillForm({
      input: {
        formSubmissionId: selectedSubmissionId
      }
    });
  };

  if (selectedSubmission && selectedSubmission.formDefinition && selectedSubmission.status === SubmissionStatus.IsResponding) {
    return (
      <FormFiller
        disabled={isSubmitting}
        onSubmit={onSubmit}
        isSubmitting={isSubmitting}
        values={selectedSubmission.formValues}
        formDefinition={selectedSubmission.formDefinition}
        submissionId={selectedSubmission.id}
      />
    );
  }

  return <FormViewerView />;
};
