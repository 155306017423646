import { ConfirmDialog } from '@components/ConfirmDialog';
import { Delete } from '@mui/icons-material';
import { ListItemIcon, MenuItem } from '@mui/material';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';

interface Props {
  onDelete: () => void;
}

export const FormConditionActions: React.FC<Props> = ({ onDelete }) => {
  const { formatMessage } = useIntl();
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  return <>
    <MenuItem onClick={() => setIsDeleteDialogOpen(true)}>
      <ListItemIcon><Delete /></ListItemIcon>
      {formatMessage({ id: 'Delete' })}
    </MenuItem>

    <ConfirmDialog
      title={formatMessage({ id: 'Delete this condition?' })}
      open={isDeleteDialogOpen}
      onCancel={() => setIsDeleteDialogOpen(false)}
      onConfirm={onDelete}
      confirmColor='error'
      loading={false}
      content={formatMessage({ id: 'This will remove the condition permanently.' })}
    />

  </>;
};