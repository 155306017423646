import { TreeDataGroupingCell } from '@components/TreeDataGroupingCell';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { ProjectTaskFragmentFragment } from 'gql/index';
import { TaskNameCell } from './Tables/TaskNameCell';

type Task = Pick<ProjectTaskFragmentFragment, 'id' | 'type' | 'name' | 'document' | 'projectForm'>;

type Props = GridRenderCellParams<Task> & {
  disableLink?: boolean;
  depthOffsetAmount?: number;
};

export const TaskTreeDataGroupingCell = (props: Props) => {
  const { row, disableLink } = props;

  return (
    <TreeDataGroupingCell {...props}>
      <TaskNameCell task={row} disableLink={disableLink} />
    </TreeDataGroupingCell>
  );
};