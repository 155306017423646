import { Divider, Paper, Stack, Typography, styled } from '@mui/material';
import { FormDefinitionFragmentFragment } from 'gql/index';
import React from 'react';
import { useFormFillerConditionHandler } from '../FormFiller/useFormFillerConditionHandler';
import { FieldViewer } from './fields/FieldViewer';

interface Props {
  editable?: boolean;
  definition: FormDefinitionFragmentFragment;
  disabled?: boolean;
  section: FormDefinitionFragmentFragment['sections'][number];
  fieldIndexOffset: number;
}

const BorderedPaper = styled(Paper)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
}));

export const FormViewerSection: React.FC<Props> = ({ section, definition, fieldIndexOffset, editable }) => {
  const { isVisible } = useFormFillerConditionHandler({ sectionId: section.id, conditions: definition.conditions });

  return (
    <BorderedPaper sx={{ visibility: isVisible ? 'visible' : 'collapse' }}>
      {section.name && <>
        <Typography variant='h5' p={2}>{section.name}</Typography>
        <Divider />
      </>}

      <Stack gap={3} p={2}>
        {section.fields.map((field, index) => (
          <FieldViewer
            editable={editable}
            definition={definition}
            key={field.id}
            field={field}
            index={fieldIndexOffset + index}
          />
        ))}
      </Stack>
    </BorderedPaper>
  );
};