import { ResponsiveDrawer } from '@components/Drawers';
import { Rule, Tag, TextFields } from '@mui/icons-material';
import { DrawerProps, FormControlLabel, ListItemIcon, ListItemText, MenuItem, Stack, Switch, TextField } from '@mui/material';
import { useValidationRules } from '@utils/useValidationRules';
import { MatrixColumnType } from 'gql/index';
import React, { ReactNode, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { matrixColumnTypeMessages } from '../../../messages';

interface Props extends Omit<DrawerProps, 'onClose'> {
  values: MatrixColumnFormData;
  onClose: (values: MatrixColumnFormData) => void;
}

export interface MatrixColumnFormData {
  name: string;
  description: string;
  type: MatrixColumnType | '';
  isRequired: boolean;
}

const defaultValues: MatrixColumnFormData = {
  name: '',
  type: '',
  description: '',
  isRequired: false
};

const matrixColumnTypeIconMap: Record<MatrixColumnType, ReactNode> = {
  [MatrixColumnType.Text]: (<TextFields />),
  [MatrixColumnType.Numerical]: (<Tag />),
  [MatrixColumnType.Boolean]: (<Rule />)
};

export const MatrixColumnConfigurationDrawer: React.FC<Props> = ({ values, onClose, ...drawerProps }) => {
  const { formatMessage } = useIntl();
  const { notEmpty } = useValidationRules();

  const { control, reset, handleSubmit } = useForm<MatrixColumnFormData>({ defaultValues });

  useEffect(() => {
    reset(values);
  }, [values, drawerProps.open, reset]);

  return (
    <ResponsiveDrawer
      variant='temporary'
      anchor='left'
      disablePadding
      header={formatMessage({ id: 'Column configuration' })}
      {...drawerProps}
      onClose={() => handleSubmit(onClose)()}
    >
      <Stack p={2} gap={2}>
        <Controller
          control={control}
          name='name'
          rules={{ validate: notEmpty }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              error={!!error}
              helperText={error?.message}
              fullWidth
              label={formatMessage({ id: 'Name' })}
            />
          )}
        />

        <Controller
          control={control}
          name='description'
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              multiline
              rows={2}
              error={!!error}
              helperText={error?.message}
              fullWidth
              label={formatMessage({ id: 'Description' })}
            />
          )}
        />

        <Controller
          control={control}
          name='type'
          render={({ field, fieldState: { error } }) => (
            <TextField
              fullWidth
              error={!!error}
              helperText={error?.message}
              {...field}
              onChange={e => field.onChange(e.target.value)}
              select
              label={formatMessage({ id: 'Type' })}
              sx={{ '& .MuiSelect-select': { p: 1.5 } }}
            >
              <option value='' style={{ display: 'none', visibility: 'hidden' }} />
              {Object.values(MatrixColumnType).map(type => (
                <MenuItem
                  key={type}
                  value={type}
                >
                  <Stack direction='row' alignItems='center'>
                    <ListItemIcon sx={{ minWidth: 32 }}>
                      {matrixColumnTypeIconMap[type]}
                    </ListItemIcon>

                    <ListItemText
                      primary={formatMessage(matrixColumnTypeMessages[type])}
                    />
                  </Stack>
                </MenuItem>
              ))}
            </TextField>
          )}
        />

        <Stack direction='row' justifyContent='end'>
          <Controller
            control={control}
            name='isRequired'
            render={({ field }) => (
              <FormControlLabel control={<Switch checked={field.value} onChange={(_, checked) => field.onChange(checked)} />} label={formatMessage({ id: 'Required' })} />
            )}
          />
        </Stack>

      </Stack>

    </ResponsiveDrawer>
  );
};