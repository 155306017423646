import { ActionMenu } from '@components/ActionMenu';
import { ConfirmDialog } from '@components/ConfirmDialog';
import { EditorSavingStatus, SavingStatusContext } from '@components/Editor/TopBar/SavingStatusContext';
import { InlineEditPaper } from '@components/InlineEditPaper';
import { Draggable } from '@hello-pangea/dnd';
import { Delete, DragIndicator } from '@mui/icons-material';
import { ListItemIcon, MenuItem, Stack } from '@mui/material';
import React, { useContext, useState } from 'react';
import { FormProvider, useForm, useFormContext, useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { ReactHookFormFieldArrayElement } from '../../types';
import { DroppableFormFields } from '../FormFields/DroppableFormFields';
import { FormEditorSectionValues, FormEditorValues } from '../types';
import { FormSectionConfiguration } from './FormSectionConfiguration';

interface Props {
  sectionIndex: number;
  uniqueId: string;
  section: FormEditorSectionValues & ReactHookFormFieldArrayElement;
  actionsMenu: boolean;
  onSubmit: (data: FormEditorSectionValues) => void;
  onDeleted?: () => void;
}

export const DraggableFormSection: React.FC<Props> = ({ sectionIndex, section, uniqueId, actionsMenu, onSubmit, onDeleted }) => {
  const { formatMessage } = useIntl();

  const outerForm = useFormContext<FormEditorValues>();

  const form = useForm<FormEditorSectionValues>({ defaultValues: section, mode: 'onBlur' });
  const { control, handleSubmit, formState: { errors }, getValues } = form;

  const conditions = useWatch({ control: outerForm.control, name: 'conditions' });

  const hasVisibilityEffect = conditions.filter(c => c.targetSectionId === section.id).length !== 0;

  const sectionId = useWatch({ control, name: 'id' });
  const error = errors.name;

  const { isEditorLoading, editorSavingStatus } = useContext(SavingStatusContext);

  const isLoading = isEditorLoading || editorSavingStatus === EditorSavingStatus.Saving;

  const [isInlineActive, setInlineActive] = useState(false);

  const [confirmRemoveDialogOpen, setConfirmRemoveDialogOpen] = useState(false);

  const onDeleteClicked = () => {
    const fields = getValues('fields');

    if (fields.length === 0) {
      onDeleted?.();
    } else {
      setConfirmRemoveDialogOpen(true);
    }
  };

  const onDeleteConfirm = () => {
    onDeleted?.();
  };

  const onSubmitSection = () => {
    handleSubmit(onSubmit)();
    setInlineActive(false);
  };

  return <>
    <Draggable draggableId={uniqueId} index={sectionIndex} isDragDisabled={isLoading}>
      {provided => (
        <div ref={provided.innerRef} {...provided.draggableProps}>
          <FormProvider {...form}>
            <InlineEditPaper
              isActive={isInlineActive}
              error={Boolean(error)}
              disabled={isLoading}
              onInactivate={handleSubmit(onSubmit)}
              onActiveChange={setInlineActive}

              startIcon={(
                <Stack height={42} width={32} justifyContent='center' alignItems='center' {...provided.dragHandleProps}><DragIndicator /></Stack>
              )}

              endIcon={actionsMenu && (
                <ActionMenu iconButtonProps={{ disabled: isLoading }}>
                  <MenuItem onClick={onDeleteClicked}>
                    <ListItemIcon>
                      <Delete />
                    </ListItemIcon>
                    {formatMessage({ id: 'Delete' })}
                  </MenuItem>
                </ActionMenu>
              )}
            >
              {isActive => <FormSectionConfiguration hasVisibilityEffect={hasVisibilityEffect} isActive={isActive} onSubmit={() => onSubmitSection()} />}
            </InlineEditPaper>
          </FormProvider>

          {sectionId && <DroppableFormFields sectionIndex={sectionIndex} sectionId={sectionId} />}
        </div>
      )}</Draggable>

    <ConfirmDialog
      open={confirmRemoveDialogOpen}
      title={formatMessage({ id: 'Delete section' })}
      content={formatMessage({ id: 'This section will be deleted, along with all its fields. Are you sure?' })}
      confirmText={formatMessage({ id: 'Delete' })}
      confirmColor='error'
      onCancel={() => setConfirmRemoveDialogOpen(false)}
      onConfirm={onDeleteConfirm}
      loading={isLoading}
    />
  </>;
};