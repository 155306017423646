import { Box } from '@mui/material';
import React from 'react';
import { useIntl } from 'react-intl';
import { SectionTitle } from '../SectionTitle';
import { ThemeSelector } from './ThemeSelector';

export const ThemeSettingsSection: React.FC = () => {
  const { formatMessage } = useIntl();

  return (
    <Box padding={2} gap={2}>
      <SectionTitle title={formatMessage({ id: 'Mode' })} />
      <ThemeSelector />
    </Box>
  );
};