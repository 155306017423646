import { PageContainer } from '@components/Layout/PageContainer';
import { PageTitle } from '@components/Layout/PageTitle';
import { ResponsiveButton } from '@components/ResponsiveButton';
import { Add, Timeline } from '@mui/icons-material';
import { Box, Tab, Tabs } from '@mui/material';
import { getTenantIdentifier } from '@utils/getTenantIdentifier';
import { useQueryInvalidator } from '@utils/useQueryInvalidator';
import { useAddWorkflowMutation, useWorkflowTemplatesQuery } from 'gql/index';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { WorkflowTemplatesTable } from '../components/WorkflowTemplatesTable';

export const WorkflowTemplatesPage: React.FC = () => {
  const { formatMessage } = useIntl();
  const invalidateQuery = useQueryInvalidator();
  const navigate = useNavigate();

  const { mutate: createFormTemplate } = useAddWorkflowMutation();

  const handleCreateNewTemplete = () => {
    createFormTemplate({
      input: {
        name: formatMessage({ id: 'New workflow' }),
      }
    }, {
      onSuccess: d => {
        navigate(`/${getTenantIdentifier()}/workflows/edit/${d.addWorkflowDefinition.workflowDefinition?.id}`);
        invalidateQuery(useWorkflowTemplatesQuery);
      }
    });
  };

  const [selectdTab, setSelectedTab] = useState<'mine' | 'sharedWithMe'>('mine');

  return (
    <>
      <PageTitle
        backgroundColor="secondary"
        title={formatMessage({ id: 'Workflow templates' })}
        icon={<Timeline />}
        actionButton={(
          <ResponsiveButton icon={<Add />} variant='contained' color='primary' onClick={handleCreateNewTemplete}>
            {formatMessage({ id: 'Add template' })}
          </ResponsiveButton>
        )}
      />
      <PageContainer>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
          <Tabs value={selectdTab} onChange={(_, newValue) => setSelectedTab(newValue)}>
            <Tab value='mine' label={formatMessage({ id: 'Mine' })} />
            <Tab value='sharedWithMe' label={formatMessage({ id: 'Shared with me' })} />
          </Tabs>
        </Box>
        <WorkflowTemplatesTable selectedTab={selectdTab} />
      </PageContainer>
    </>
  );
};