import React from 'react';
import { useRecoilState } from 'recoil';

import { ProjectNavigationMenuItem, ProjectNavigationMenuItemProps } from './ProjectNavigationMenuItem';
import { isProjectNavigationMenuCollapsedState, wasProjectNavigationMenuCollapsedTipDismissedState } from '@modules/projects/atoms';
import { MenuOpen } from '@mui/icons-material';
import { ClickAwayListener, Tooltip } from '@mui/material';
import { useIntl } from 'react-intl';

export const ProjectNavigationMenuCollapser: React.FC<ProjectNavigationMenuItemProps> = (props) => {
  const [isNavigationMenuCollapsed, setIsNavigationMenuCollapsed] = useRecoilState(isProjectNavigationMenuCollapsedState);
  const [isNavigationMenuCollapseTipDismissed, setNavigationMenuCollapseTipDismissed] = useRecoilState(wasProjectNavigationMenuCollapsedTipDismissedState);
  const toggleMenu = () => setIsNavigationMenuCollapsed(!isNavigationMenuCollapsed);
  const { formatMessage } = useIntl();

  return (
    <ProjectNavigationMenuItem
      icon={isNavigationMenuCollapsed ?
        <ClickAwayListener onClickAway={() => setNavigationMenuCollapseTipDismissed(true)}>
          <Tooltip open={isNavigationMenuCollapsed && !isNavigationMenuCollapseTipDismissed} title={formatMessage({ id: 'Tip: Click here to collapse the project menu.' })} arrow placement='right' onClose={() => setNavigationMenuCollapseTipDismissed(true)} >
            <MenuOpen sx={{ transform: 'rotate(180deg)' }} />
          </Tooltip>
        </ClickAwayListener>
        : undefined}
      onClick={toggleMenu}
      {...props}
    >
      <MenuOpen />
    </ProjectNavigationMenuItem>
  );
};