import { CssBaseline, ThemeProvider as MuiThemeProvider, createTheme, responsiveFontSizes } from '@mui/material';
import { enUS as coreEnUS, frFR as coreFrFR } from '@mui/material/locale';
import { enUS, frFR } from '@mui/x-data-grid/locales';
import { enUS as pickersEnUS, frFR as pickersFrFR } from '@mui/x-date-pickers';
import { ReactNode, useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { allThemes } from 'src/themes';
import { currentLocaleState, currentThemeNameState } from '../atoms';

export const ThemeProvider: React.FC<{ children: ReactNode; }> = ({ children }) => {
  const currentThemeName = useRecoilValue(currentThemeNameState) as keyof typeof allThemes;
  const currentLocale = useRecoilValue(currentLocaleState);

  const currentThemeObj = allThemes[currentThemeName] ?? allThemes.default;

  const currentTheme = useMemo(() => responsiveFontSizes(createTheme(
    currentThemeObj,
    currentLocale.includes('fr') ? frFR : enUS,
    currentLocale.includes('fr') ? pickersFrFR : pickersEnUS,
    currentLocale.includes('fr') ? coreFrFR : coreEnUS,
  )), [currentThemeObj, currentLocale]);


  return (
    <MuiThemeProvider theme={currentTheme}>
      <CssBaseline />

      {children}
    </MuiThemeProvider>
  );
};