import { ProjectDocumentDownloadButton } from '@components/Download/ProjectDocumentDownloadButton';
import { FormDrawer, FormDrawerProps } from '@components/Drawers/FormDrawer';
import { DocumentBasicProperties } from '@modules/folders/components/Properties/DocumentProperties/DocumentBasicProperties';
import { DocumentSignatureDetails } from '@modules/folders/components/Properties/DocumentProperties/DocumentSignatureDetails';
import { useCurrentProject } from '@modules/projects/utils/useCurrentProject';
import { Draw } from '@mui/icons-material';
import { Button, Divider, Stack } from '@mui/material';
import { useGetMe } from '@utils/useGetMe';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { DocumentFragmentFragment } from 'src/gql/index';
import { DocumentFileNameAndIcon } from './DocumentFilenameAndIcon';
import { SignatureDialog } from './DocumentSignatureDialog';

type DocumentInformationDrawerProps = Omit<FormDrawerProps, 'header'>
  & {
    document?: DocumentFragmentFragment | null;
  };

export const DocumentInformationDrawer: React.FC<DocumentInformationDrawerProps> = ({ document, ...drawerProps }) => {
  const { formatMessage } = useIntl();

  const { me } = useGetMe();
  const { isArchived } = useCurrentProject();

  const currentUserCanSign = document?.signedOn == null && document?.signatoryCustomerId === me?.id;

  const [isSignatureDialogOpen, setIsSignatureDialogOpen] = useState(false);

  return (
    <FormDrawer {...drawerProps}
      header={formatMessage({ id: 'Document information' })}
      disablePadding
      actionButtons={document && (
        <ProjectDocumentDownloadButton document={document} />
      )}
    >
      {document && <>
        <Stack p={2}>
          <DocumentFileNameAndIcon
            {...document}
            actionButton={currentUserCanSign && (
              <Button variant='contained' startIcon={<Draw />} onClick={() => setIsSignatureDialogOpen(true)} disabled={isArchived}>
                {formatMessage({ id: 'Sign now' })}
              </Button>
            )}
          />
        </Stack>

        <Divider />

        <Stack p={2} gap={2}>
          <DocumentBasicProperties document={document} />

          <DocumentSignatureDetails document={document} />
        </Stack>

        <SignatureDialog
          documentId={document.id}
          actionId={document.zohoActionId ?? ''}
          requestId={document.zohoRequestId ?? ''}
          open={isSignatureDialogOpen}
          onClose={() => setIsSignatureDialogOpen(false)}
        />
      </>}
    </FormDrawer>
  );
};