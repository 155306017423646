import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

type QueryFn<TVariables> = ((variables: TVariables) => unknown) & {
  getKey: (variables: TVariables) => (string | unknown)[];
};

// The first value of the key array is guaranteed to exist
const getBaseKey = <TVariables>(queryFn: QueryFn<TVariables>) => {
  const keyArray = queryFn.getKey({} as TVariables);
  const baseKey = keyArray[0];
  if (typeof baseKey !== 'string') {
    console.error('a query function\'s getKey was unexpectedly not a string');
    return;
  }
  return baseKey as string;
};

type NullablePartial<T> = {
  [P in keyof T]?: T[P] | null;
};

export const useQueryInvalidator = () => {
  const queryClient = useQueryClient();

  const invalidateQuery = useCallback(<T>(queryFn: QueryFn<T>, variables?: NullablePartial<T>) => {
    const baseKey = getBaseKey(queryFn);

    if (!baseKey) return;

    if (!variables) {
      return queryClient.invalidateQueries([baseKey]);
    } else {
      return queryClient.invalidateQueries([baseKey, variables]);
    }
  }, [queryClient]);

  return invalidateQuery;
};