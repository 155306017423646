import { TextProperty } from '@components/DataDisplay/TextProperty';
import { PageSectionPart } from '@components/PageSection/PageSectionPart';
import { CardActionArea, Radio, Stack, Typography } from '@mui/material';
import { useGetMe } from '@utils/useGetMe';
import { MeetingFragmentFragment, MeetingQuery } from 'gql/index';
import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { RespondToMeetingFormValues } from '../views/MeetingPage';

type Proposal = NonNullable<MeetingQuery['meeting']>['proposals'][0];

interface Props {
  conflictExists?: boolean;
  availableAtThisTime?: boolean;
  meeting: MeetingFragmentFragment;
  proposal: Proposal;
}

export const MeetingProposalPageSection: React.FC<Props> = ({ meeting, proposal, conflictExists, availableAtThisTime }) => {
  const { formatMessage, formatDate } = useIntl();
  const { me } = useGetMe();
  const { control, setValue } = useFormContext<RespondToMeetingFormValues>();
  const selectedProposedTime = useWatch({ control: control, name: 'selectedProposedTimeId' });

  const isMeetingTypeManager = meeting.meetingType?.meetingRequestsOwners.some(p => p.id === me?.id)
    || meeting.meetingType?.meetingRequestOwnerGroups.flatMap(g => g.members).some(p => p.id === me?.id);

  const canRespondToMeetingRequest = meeting.startTime == null && isMeetingTypeManager;

  const inner = <>
    <Stack direction='row' flexWrap='wrap' p={2}>
      <Stack gap={1}>
        <TextProperty
          propertyName={formatMessage({ id: 'Start time' })}
          propertyValue={formatDate(proposal.startTime, { dateStyle: 'full', timeStyle: 'short' })}
        />

        {availableAtThisTime && (
          <Typography color='success.main'>
            {formatMessage({ id: 'You are available' })}
          </Typography>
        )}

        {conflictExists && (
          <Typography color='error.main'>
            {formatMessage({ id: 'You have a conflicting event in your Outlook calendar' })}
          </Typography>
        )}
      </Stack>

      {canRespondToMeetingRequest && (
        <Stack flexGrow={1} direction='row' gap={2} justifyContent='end' alignItems='end' flexWrap='wrap'>
          <Radio disableRipple disableFocusRipple disableTouchRipple checked={proposal.id === selectedProposedTime} onChange={() => setValue('selectedProposedTimeId', proposal.id)}></Radio>
        </Stack>
      )}
    </Stack>
  </>;

  return (
    <PageSectionPart key={proposal.startTime} sx={{ padding: 0 }}>
      {canRespondToMeetingRequest ? (
        <CardActionArea onClick={() => setValue('selectedProposedTimeId', proposal.id)}>
          {inner}
        </CardActionArea>
      ) : inner}
    </PageSectionPart>
  );
};