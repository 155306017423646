import { FormLabel, styled } from '@mui/material';

export const FormFieldLabel = styled(FormLabel, {
  shouldForwardProp: prop => prop !== 'italic'
})<{
  italic?: boolean;
}>(({ italic, theme }) => ({
  fontWeight: 600,
  fontStyle: italic ? 'italic' : undefined,
  color: theme.palette.text.primary,
}));