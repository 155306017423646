import { Button, ButtonProps } from '@mui/material';
import React from 'react';
import { useIntl } from 'react-intl';

type Props = Omit<ButtonProps, 'color' | 'children' | 'variant'>;

export const CancelButton: React.FC<Props> = (buttonProps) => {
  const { formatMessage } = useIntl();
  return (
    <Button color='inherit' {...buttonProps}>
      {formatMessage({ id: 'Cancel' })}
    </Button>
  );
};