import { ReactNode, createContext, useState } from 'react';


type AnonymousContextType = {
  accessToken: string | undefined;
};

export const AnonymousContext = createContext<AnonymousContextType>({
  accessToken: undefined
});

export const AnonymousContextProvider = (props: { children: ReactNode, accessToken: string; }) => {
  const [accessToken, _] = useState<string>(props.accessToken);
  return (
    <AnonymousContext.Provider value={{ accessToken }}>
      {props.children}
    </AnonymousContext.Provider>
  );
};