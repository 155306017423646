import { LocalizationProvider as DateProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ReactNode, useEffect } from 'react';
import { IntlProvider, ReactIntlErrorCode } from 'react-intl';
import { useRecoilState } from 'recoil';
import { allSupportedLocales, defaultLocale } from 'src/locales';
import { currentLocaleState } from '../atoms';

interface ErrorWithCode extends Error {
  code: string;
}

export const LocalizationProvider: React.FC<{ children: ReactNode; }> = ({ children }) => {
  const [currentLocale, setCurrentLocale] = useRecoilState(currentLocaleState);
  const locale = allSupportedLocales.find(locale => locale.code.includes(currentLocale)) || defaultLocale;

  const ignoreMissingTranslations = (error: ErrorWithCode) => {
    if (error.code !== ReactIntlErrorCode.MISSING_TRANSLATION)
      throw error;
  };


  useEffect(() => {
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
    const language = params.get('preferredLanguage');
    if (language != null) {
      const locale = allSupportedLocales.find(p => p.code.toLocaleLowerCase() == language.toLocaleLowerCase());

      if (locale != null) {
        setCurrentLocale(locale.code);
      }
      url.search = params.toString();
      window.history.replaceState({}, '', url.toString());
    }

  }, [setCurrentLocale]);

  return (
    <IntlProvider
      locale={locale.code}
      messages={locale.messages}
      onError={ignoreMissingTranslations}
    >
      <DateProvider dateAdapter={AdapterDayjs} adapterLocale={currentLocale.toLowerCase()}>
        {children}
      </DateProvider>
    </IntlProvider>
  );
};