import { DataTable } from '@components/DataTable';
import { userRoleMessages } from '@modules/application/messages';
import { MemberListItem } from '@modules/projects/components/Members/MemberListItem';
import { UserActions } from '@modules/users/components/UserActions';
import { Chip, Stack } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { notNullOrUndefined } from '@utils/notNullOrUndefined';
import { ApplicationUser, ApplicationUserFilterInput, BuiltInRoles, useGetUsersFullQuery } from 'gql/index';
import { debounce } from 'lodash';
import React from 'react';
import { useIntl } from 'react-intl';

interface Props {
  onUserClicked?: (userId: string) => void;
}

type UserRow = Omit<ApplicationUser, 'firstName' | 'groups' | 'lastName' | 'language' | 'optedOutOfWeeklyEmails' | 'roles'>;

export const InternalUsersTable: React.FC<Props> = ({ onUserClicked }) => {
  const { formatMessage } = useIntl();
  const [searchTerm, setSearchTerm] = React.useState('');
  const onSearch = debounce((value: string) => {
    setSearchTerm(value);
  }, 500);

  const searchFilter: ApplicationUserFilterInput | undefined = searchTerm ? {
    or: [
      { firstName: { contains: searchTerm } },
      { lastName: { contains: searchTerm } },
    ]
  } : undefined;

  const adminFilter: ApplicationUserFilterInput = {
    roles: {
      some: {
        name: {
          neq: BuiltInRoles.LimitedAccess
        }
      }
    }
  };

  const { data: users, isFetching } = useGetUsersFullQuery({
    filter: {
      and: [searchFilter, adminFilter].filter(notNullOrUndefined)
    }
  }, { select: d => d.users });

  const getUserRole = (user: UserRow) => {
    if (user.builtInRoles.includes(BuiltInRoles.TenantAdministrator)) {
      return BuiltInRoles.TenantAdministrator;
    } else if (user.builtInRoles.includes(BuiltInRoles.ProjectAdministrator)) {
      return BuiltInRoles.ProjectAdministrator;
    } else if (user.builtInRoles.includes(BuiltInRoles.Contributor)) {
      return BuiltInRoles.Contributor;
    } else {
      throw new Error(`User ${user.fullName} unexpectedly does not have any valid application role.`);
    }
  };

  const columns: GridColDef<NonNullable<typeof users>[0]>[] = [
    {
      field: 'fullName',
      flex: 1,
      minWidth: 250,
      hideable: false,
      headerName: formatMessage({ id: 'Name' }),
      valueGetter: ({ row }) => row.fullName,
      renderCell: ({ row }) => (
        <MemberListItem
          member={row}
          disableSorroundBorders
          onClick={() => onUserClicked?.(row.id)}
        />
      )
    },
    {
      field: 'role',
      flex: 1,
      minWidth: 250,
      hideable: false,
      headerName: formatMessage({ id: 'Role' }),
      valueGetter: ({ row }) => getUserRole(row),
      renderCell: ({ row }) => (
        <Chip
          label={formatMessage(userRoleMessages[getUserRole(row)])}
        />
      )
    },
    {
      field: 'email',
      minWidth: 250,
      headerName: formatMessage({ id: 'Email' }),
      valueGetter: ({ row }) => row.email,
    },
    {
      field: 'actions',
      headerName: '',
      maxWidth: 64,
      disableColumnMenu: true,
      disableExport: true,
      disableReorder: true,
      sortable: false,
      renderCell: ({ row }) => (
        <UserActions user={row} />
      )
    }
  ];

  return (
    <Stack maxHeight='600px'>
      <DataTable
        onSearch={onSearch}
        loading={isFetching}
        disableColumnMenu
        rowHeight={72}
        rows={users ?? []}
        columns={columns}
        noDataMessage={formatMessage({ id: 'No users found.' })}
        noSearchResultsMessage={formatMessage({ id: 'No users found' })}
        sx={{
          '& .MuiDataGrid-cell:first-of-type': {
            padding: 0
          }
        }}
      />
    </Stack>
  );
};