import { FormField, MatrixRowFragmentFragment, MatrixValueInput } from 'gql/index';
import { matrixRowsToMatrixFillerRows } from './utils';

export interface FormFillerValues {
  values: FormFieldValues[];
}

export interface FormFieldValues {
  fieldId: number;
  submissionId: number;
  text: string;
  numerical: string;
  boolean: 'true' | 'false' | '';
  date: string;
  selectedOptions: number[];
  files: FormAttachment[];
  matrixRows: MatrixFillerRows[];
}

export interface FormAttachment {
  id: number;
  fileName: string;
  storageUrl?: string;
}

export interface MatrixFillerRows {
  id: number;
  isDefaultRow: boolean;
  values: MatrixFillerValue[];
}

export interface MatrixFillerValue extends MatrixValueInput {
  id: number;
  rowId: number,
  columnId: number;
}

type Field = Pick<FormField, 'id'> & {
  matrix?: {
    defaultRows: MatrixRowFragmentFragment[];
  } | null;
};

export const formFieldDefaultValues = (field: Field, submissionId: number): FormFieldValues => ({
  fieldId: field.id,
  submissionId,
  text: '',
  numerical: '',
  boolean: '',
  date: '',
  selectedOptions: [],
  files: [],
  matrixRows: matrixRowsToMatrixFillerRows(field.matrix?.defaultRows ?? [])
});

export interface MatrixFillerDataGridRow {
  id: number;
  isDefaultRow: boolean;
  values: MatrixFillerValue[];
  lastEditedColumnId?: number;
  newValue?: unknown;
}