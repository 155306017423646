import { CancelButton } from '@components/Buttons/CancelButton';
import { ResponsiveDialog } from '@components/Dialogs/ResponsiveDialog';
import { useFolderName } from '@modules/folders/utils/folderUtils';
import { useCurrentProject } from '@modules/projects/utils/useCurrentProject';
import { Button, FormControl, FormHelperText, InputLabel, MenuItem, Select, Stack } from '@mui/material';
import { useProjectFoldersQuery } from 'gql/index';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { FolderPicker } from './FolderPicker';

interface Props {
  value: number | null;
  onChange: (value: number | null) => void;
  label?: string;
  error?: boolean;
  disabled?: boolean;
  helperText?: string;
}

export const FolderPickerField: React.FC<Props> = ({ value, onChange, label, error, disabled, helperText }) => {
  const { formatMessage } = useIntl();
  const { projectId } = useCurrentProject();

  const { data: folders } = useProjectFoldersQuery({ projectId }, { select: d => d.projectFolders });
  const { getFolderName } = useFolderName();

  const [isSelectingDialogOpen, setIsSelectingDialogOpen] = useState(false);

  const [currentValue, setCurrentValue] = useState<number | null>(value);
  useEffect(() => setCurrentValue(value), [value, isSelectingDialogOpen]);

  const onSelectClicked = () => {
    onChange(currentValue);
    setIsSelectingDialogOpen(false);
  };

  return <>
    <FormControl focused={isSelectingDialogOpen}>
      <InputLabel error={error} disabled={disabled} shrink={isSelectingDialogOpen || !!value}>{label ?? formatMessage({ id: 'Folder' })}</InputLabel>
      <Select
        fullWidth
        label={label ?? formatMessage({ id: 'Folder' })}
        onClick={() => !disabled && setIsSelectingDialogOpen(true)}
        MenuProps={{ style: { visibility: 'hidden' } }}
        notched={isSelectingDialogOpen || !!value}
        disabled={disabled}
        error={error}
        sx={{ minWidth: '200px', '.MuiSelect-icon': { display: 'none' } }}
        value={value || ''}
      >
        <MenuItem value=''>
        </MenuItem>
        {folders?.map(folder => (
          <MenuItem key={folder.id} value={folder.id.toString()}>
            {getFolderName(folder)}
          </MenuItem>
        ))}
      </Select>
      {helperText && <FormHelperText error={error}>{helperText}</FormHelperText>}
    </FormControl>

    <ResponsiveDialog title={formatMessage({ id: 'Select folder' })} open={isSelectingDialogOpen} onClose={() => setIsSelectingDialogOpen(false)}>
      <Stack p={2} gap={2} overflow='hidden'>
        <Stack overflow='auto' flexGrow={1}>
          <FolderPicker
            selectedFolderId={currentValue}
            onFolderSelected={setCurrentValue}
          />
        </Stack>

        <Stack width='100%' direction='row' justifyContent='end' gap={2}>
          <CancelButton onClick={() => setIsSelectingDialogOpen(false)} />

          <Button variant='contained' onClick={onSelectClicked}>
            {formatMessage({ id: 'Select' })}
          </Button>
        </Stack>
      </Stack>
    </ResponsiveDialog>
  </>;
};