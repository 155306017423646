import { MultiStepFormDialog } from '@components/MultiStepForms/MultiStepFormDialog';
import { useCurrentProject } from '@modules/projects/utils/useCurrentProject';
import { DialogProps } from '@mui/material';
import { useNotification } from '@utils/useNotification';
import { useQueryInvalidator } from '@utils/useQueryInvalidator';
import { useImportTasksMutation, useProjectTasksQuery } from 'gql/index';
import { entries } from 'lodash';
import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { TaskImportOptionsStep } from './OptionsStep/TaskImportOptionsStep';
import { TaskImportProjectStep } from './TaskImportProjectStep';
import { TaskImportSummaryStep } from './TaskImportSummaryStep';
import { TaskImportTasksPicker } from './TasksStep/TaskImportTasksPicker';
import { TaskImportFormValues } from './types';

type Props = DialogProps;

const defaultValues: TaskImportFormValues = {
  projectToImportFromId: null,
  taskIds: [],
  newStartDate: null,
  userMapping: {}
};

export const TaskImportDrawer: React.FC<Props> = ({ ...dialogProps }) => {
  const { formatMessage } = useIntl();
  const invalidateQuery = useQueryInvalidator();
  const { notifySuccess } = useNotification();

  const { projectId } = useCurrentProject();

  const form = useForm<TaskImportFormValues>({ defaultValues });
  const { reset, handleSubmit } = form;

  const { mutate: importTasks, isLoading: isImportingTasks } = useImportTasksMutation({
    onSuccess: () => {
      invalidateQuery(useProjectTasksQuery, { projectId });
      notifySuccess(formatMessage({ id: 'Tasks imported successfully' }));
      dialogProps.onClose?.({}, 'escapeKeyDown');
    }
  });

  useEffect(() => {
    reset(defaultValues);
  }, [reset, dialogProps.open]);

  const onSubmit = (values: TaskImportFormValues) => {
    if (!values.projectToImportFromId) return;

    const userMapping = entries(values.userMapping).map(([key, value]) => ({ key, value }));

    importTasks({
      input: {
        projectId,
        projectToImportFromId: values.projectToImportFromId,
        taskIds: values.taskIds,
        userMapping,
        newStartDate: values.newStartDate
      }
    });
  };

  return (
    <FormProvider {...form}>
      <MultiStepFormDialog
        dialogProps={{
          ...dialogProps,
          maxWidth: 'md',
        }}

        onSubmit={handleSubmit(onSubmit)}
        isSubmitting={isImportingTasks}

        steps={[
          {
            title: formatMessage({ id: 'Pick a project' }),
            subtitle: formatMessage({ id: 'Select a project from which to import tasks' }),
            content: () => <TaskImportProjectStep />,
            onNext: (callback) => form.handleSubmit(callback)
          },
          {
            title: formatMessage({ id: 'Select tasks' }),
            subtitle: formatMessage({ id: 'Select the task you wish to import' }),
            content: () => <TaskImportTasksPicker />,
            onNext: (callback) => form.handleSubmit(callback)
          },
          {
            title: formatMessage({ id: 'Import options' }),
            subtitle: formatMessage({ id: 'Settings for the import of the selected tasks' }),
            content: () => <TaskImportOptionsStep />,
            onNext: (callback) => form.handleSubmit(callback)
          },
          {
            title: formatMessage({ id: 'Import summary' }),
            content: () => <TaskImportSummaryStep />
          }
        ]}
      />
    </FormProvider>
  );
};