import { Drawer, DrawerProps, Toolbar } from '@mui/material';
import { useResponsive } from '@utils/useResponsive';
import { ReactNode } from 'react';

const defaultDrawerWidth = 300;

type OwnProps = DrawerProps & {
  children?: ReactNode;
  drawerWidth?: number;
};

export const EditorSelectionPanel: React.FC<OwnProps> = ({ children, drawerWidth, ...drawerProps }) => {
  const { isMobile } = useResponsive();
  drawerWidth = drawerWidth ?? defaultDrawerWidth;
  return (
    <Drawer
      {...drawerProps}

      sx={{
        zIndex: theme => theme.zIndex.appBar - 2,
        width: isMobile ? '100%' : drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: isMobile ? '100%' : drawerWidth,
          boxSizing: 'border-box',
        },
      }}
      variant="persistent"
      anchor={isMobile ? 'bottom' : 'left'}

    >
      {!isMobile && <>
        <Toolbar /><Toolbar />
      </>}
      {children}
    </Drawer>
  );
};