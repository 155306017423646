import { useCollapsedGroups } from '@components/DataGrid/useCollapsedGroups';
import { DataTable, DataTableProps } from '@components/DataTable';
import { UserAvatar } from '@components/UserAvatar/UserAvatar';
import { UnfoldLess, UnfoldMore } from '@mui/icons-material';
import { Alert, Button, Chip, Stack } from '@mui/material';
import { DataGridProProps, GridColDef, GridToolbarContainer, useGridApiRef } from '@mui/x-data-grid-pro';
import dayjs from 'dayjs';
import { ProjectTaskFragmentFragment, ProjectTaskStatus, ProjectTaskType } from 'gql/index';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useRecoilState } from 'recoil';
import { getEnumValues } from '../../../utils/enumUtils';
import { tasksTableFilterModelState, tasksTableSortModelState } from '../atoms';
import { fixHierarchyPaths } from '../hierarchyHelpers';
import { TaskActions } from './Actions/TaskActions';
import { TaskEditAction } from './Actions/TaskEditAction';
import { TaskNameCell } from './Tables/TaskNameCell';
import { TaskStatusDisplay } from './TaskStatusDisplay';
import { TaskTreeDataGroupingCell } from './TaskTreeDataGroupingCell';



interface Props extends Omit<DataTableProps<ProjectTaskFragmentFragment>, 'columns' | 'rows'> {
  tasks: ProjectTaskFragmentFragment[];
  flex: boolean;
}

const commonColumnVariables: Partial<GridColDef> = {
  disableColumnMenu: true,
  disableReorder: true,
  sortable: true,
};

export const TasksTable: React.FC<Props> = ({ tasks, flex, ...dataTableProps }) => {
  const { formatMessage } = useIntl();
  const [sortModel, setSortModel] = useRecoilState(tasksTableSortModelState);
  const [filterModel, setFilterModel] = useRecoilState(tasksTableFilterModelState);
  const [wasFilterAlertDismissed, setWasFilterAlertDismissed] = useState(false);
  const [shouldShowFilterModel, setShouldShowFilterModel] = useState<boolean | undefined>(undefined);
  const apiRef = useGridApiRef();
  const { collapsedGroupingKeys } = useCollapsedGroups(apiRef);

  const hierarchyFixedTasks = useMemo(() => fixHierarchyPaths(tasks, t => t.hierarchyPath), [tasks]);

  // Has to be memoized due to MUI bug: https://github.com/mui/mui-x/issues/7771#issuecomment-1920224215
  const getTreeDataPath = useCallback((row: ProjectTaskFragmentFragment) =>
    row.hierarchyPath ? row.hierarchyPath.split('/').filter(Boolean) : [], []);

  const hasTreeData = hierarchyFixedTasks.map(getTreeDataPath).some(path => path.length > 1);

  useEffect(() => {
    if (filterModel?.items?.length ?? 0 > 0) {
      setShouldShowFilterModel(true);
    }

    // When filters change whe don't want to show the alert.
    // We only show it the first time the component is mounted if filters are applied.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns: (GridColDef<ProjectTaskFragmentFragment> | boolean)[] = [
    !hasTreeData && {
      ...commonColumnVariables,
      field: 'name',
      flex: 1,
      minWidth: 250,
      headerName: formatMessage({ id: 'Name' }),
      renderCell: ({ row: task }) => <TaskNameCell task={task} />
    },
    {
      ...commonColumnVariables,
      filterable: true,
      disableColumnMenu: false,
      field: 'startDate',
      type: 'date',
      flex: 1,
      minWidth: 150,
      headerName: formatMessage({ id: 'Start date' }),
      valueGetter: ({ row }) => row.startDate ? dayjs(row.startDate).toDate() : ''
    },
    {
      ...commonColumnVariables,
      filterable: true,
      disableColumnMenu: false,
      type: 'date',
      field: 'dueDate',
      flex: 1,
      minWidth: 150,
      headerName: formatMessage({ id: 'Due date' }),
      valueGetter: ({ row }) => row.dueDate ? dayjs(row.dueDate).toDate() : ''
    },
    {
      ...commonColumnVariables,
      disableColumnMenu: false,

      filterable: true,
      type: 'singleSelect',
      valueOptions: getEnumValues(ProjectTaskStatus),
      field: 'status',
      flex: 1,
      minWidth: 150,
      headerName: formatMessage({ id: 'Status' }),
      valueGetter: ({ row }) => row.status,
      renderCell: ({ row }) => (
        <TaskStatusDisplay status={row.status} />
      )
    },
    {
      ...commonColumnVariables,
      field: 'assignees',
      flex: 1,
      minWidth: 250,
      headerName: formatMessage({ id: 'Assigned to' }),
      valueGetter: ({ row }) => row.assignees.map(u => u.fullName),
      renderCell: ({ row }) => (
        <Stack direction='row' gap={0.5}>
          {row.assignees.map(u => u.fullName).map((name, index) => (
            <Chip key={index} label={name} avatar={<UserAvatar displayName={name} />} />
          ))}
          {row.assigneeGroups.map(g => (
            <Chip key={`group-${g.id}`} label={g.name} avatar={<UserAvatar displayName={g.name} />} />
          ))}
        </Stack>
      )
    },
    {
      ...commonColumnVariables,
      field: 'actions',
      headerName: '',
      minWidth: 110,
      maxWidth: 110,
      align: 'right',
      renderCell: ({ row }) => (
        <Stack direction='row' spacing={1}>
          <TaskEditAction task={row} />
          {row.type === ProjectTaskType.Custom &&
            <TaskActions task={row} hideEdit />
          }
        </Stack>
      )
    }
  ];

  const groupingColDef: DataGridProProps['groupingColDef'] = {
    headerName: formatMessage({ id: 'Name' }),
    flex: 2,
    minWidth: 250,
    renderCell: TaskTreeDataGroupingCell
  };

  const [isExpanded, _setIsExpanded] = useState(true);

  const setIsExpanded = (expanded: boolean) => {
    _setIsExpanded(expanded);
    for (const task of tasks) {
      try {
        apiRef.current.setRowChildrenExpansion(task.id, expanded);
      } catch (_) {
        // This will throw an error if the row isn't a group node
      }
    }
  };

  return (<>
    <Stack gap={2} flex={1}>
      {shouldShowFilterModel && !wasFilterAlertDismissed && (
        <Alert severity='info' onClose={() => setWasFilterAlertDismissed(true)}>
          {formatMessage({ id: 'Filters are currently applied to tasks.' })}
        </Alert>
      )}

      <DataTable
        apiRef={apiRef}
        flex={flex}
        treeData={hasTreeData}
        getTreeDataPath={getTreeDataPath}
        groupingColDef={groupingColDef}
        isGroupExpandedByDefault={e => !collapsedGroupingKeys.some(key => key === e.groupingKey)}
        autosizeOnMount
        columns={columns.filter(t => typeof t !== 'boolean')}
        disableColumnPinning
        disableColumnReorder
        disableColumnSelector
        getRowId={p => p.id}
        rows={hierarchyFixedTasks}
        {...dataTableProps}
        sortModel={sortModel}
        onSortModelChange={setSortModel}
        filterModel={filterModel}
        onFilterModelChange={setFilterModel}
        slots={{
          toolbar: () => (
            hasTreeData &&
            <GridToolbarContainer sx={{ p: 1 }}>
              {isExpanded ? (
                <Button size='small' startIcon={<UnfoldLess />} onClick={() => setIsExpanded(false)}>
                  {formatMessage({ id: 'Collapse all' })}
                </Button>
              ) : (
                <Button size='small' startIcon={<UnfoldMore />} onClick={() => setIsExpanded(true)}>
                  {formatMessage({ id: 'Expand all' })}
                </Button>
              )}
            </GridToolbarContainer>
          )
        }}
      />
    </Stack>
  </>
  );
};