import { EditorSelectionPanel } from '@components/Editor/EditorSelectionPanel';
import { formSideMenuWidth } from '@modules/forms/components/FormEditor/FormSideMenu/FormSideMenu';
import { Group, SettingsSuggest } from '@mui/icons-material';
import { Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Stack, Toolbar } from '@mui/material';
import { useContext, useState } from 'react';
import { useIntl } from 'react-intl';
import { ActionSelectionPanel } from './PanelTabs/ActionSelectionPanel';
import { WorkflowRolesPanel } from './PanelTabs/WorkflowRolesPanel';
import { WorkflowEditorContext } from './WorkflowEditorContext';

export const WorkflowEditorSidePanel: React.FC = () => {
  const { formatMessage } = useIntl();

  const { disabled } = useContext(WorkflowEditorContext);

  const [selectedTab, setSelectedTab] = useState('actions');

  return (

    <>
      <Drawer
        sx={{
          width: formSideMenuWidth,
          flexShrink: 0,
          zIndex: theme => theme.zIndex.appBar - 1,
          '& .MuiDrawer-paper': {
            transition: theme => theme.transitions.create('width', {
              easing: theme.transitions.easing.sharp,
            }),
            width: formSideMenuWidth,
            boxSizing: 'border-box',
          },
        }}
        open={true}
        variant='permanent'
      >
        <Toolbar />
        <Toolbar />

        {location.pathname.split('/').indexOf('form') > -1 && <Toolbar />}

        <List>
          <ListItem key={'fields'} disablePadding sx={{ display: 'block' }}>
            <ListItemButton
              selected={selectedTab === 'actions'}
              onClick={() => setSelectedTab('actions')}
              sx={{
                minHeight: 48,
                justifyContent: 'center',
                alignItems: 'center',
                px: 2.5,
                flexDirection: 'column'
              }}
            >
              <ListItemIcon
                sx={{
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <SettingsSuggest />
              </ListItemIcon>
              <ListItemText primary={formatMessage({ id: 'Actions' })} />
            </ListItemButton>
          </ListItem>
          <ListItem key={'logic'} disablePadding sx={{ display: 'block' }}>
            <ListItemButton
              selected={selectedTab === 'roles'}
              onClick={() => setSelectedTab('roles')}
              sx={{
                minHeight: 48,
                justifyContent: 'center',
                alignItems: 'center',
                px: 2.5,
                flexDirection: 'column'
              }}
            >
              <ListItemIcon
                sx={{
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <Group />
              </ListItemIcon>
              <ListItemText primary={formatMessage({ id: 'Roles' })} />
            </ListItemButton>
          </ListItem>
        </List>
      </Drawer>


      <EditorSelectionPanel open drawerWidth={320 + formSideMenuWidth}>
        <Stack pl={`${formSideMenuWidth}px`} width='100%'>
          {(selectedTab === 'actions' && !disabled) && <ActionSelectionPanel />}

          {(selectedTab === 'roles' || disabled) && <WorkflowRolesPanel />}
        </Stack>
      </EditorSelectionPanel>
    </>
  );
};