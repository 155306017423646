import { Folder, FolderType } from 'gql/index';
import { MessageDescriptor, defineMessages, useIntl } from 'react-intl';

type MyFolder = Pick<Folder, 'id' | 'path'>;

/** Is `parent` above `child` in the folder tree? */
export const isAncestorOf = (parent: MyFolder, child: MyFolder): boolean => {
  return child.path.startsWith(parent.path);
};

export const getFileNameWithoutExtension = (fileName: string) =>
  fileName.indexOf('.') === -1
    ? fileName
    : fileName.split('.').slice(0, -1).join('.');

export const folderNameByType: Record<FolderType, MessageDescriptor> = defineMessages({
  Custom: { id: 'Custom' },
  ConfidentialDocument: { id: 'Confidential documents' },
  Root: { id: 'Documents' },
  SharedWithAll: { id: 'Shared with all' }
});

export const useFolderName = () => {
  const { formatMessage } = useIntl();

  return {
    getFolderName: (folder?: Pick<Folder, 'name' | 'folderType'> | null) => {
      if (!folder?.folderType) return undefined;

      return folder.folderType === FolderType.Custom
        ? folder.name
        : formatMessage(folderNameByType[folder.folderType]);
    }
  };
};