import { FormDialog } from '@components/Dialogs/FormDialog';
import { DialogProps, TextField } from '@mui/material';
import { getTenantIdentifier } from '@utils/getTenantIdentifier';
import { useNotification } from '@utils/useNotification';
import { useQueryInvalidator } from '@utils/useQueryInvalidator';
import { useAddProjectTemplateMutation, useGetProjectsQuery } from 'gql/index';
import React, { useEffect, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

export interface ProjectFormValues {
  name: string;
  description: string;
  optInToBudgetFeature: boolean;

}

const defaultValues: ProjectFormValues = {
  name: '',
  description: '',
  optInToBudgetFeature: false,

};

export const AddProjectTemplateDialog: React.FC<DialogProps> = ({ ...dialogProps }) => {
  const { formatMessage } = useIntl();
  const invalidateQuery = useQueryInvalidator();
  const { notifySuccess } = useNotification();
  const navigate = useNavigate();

  const { mutate: addProjectTemplate, isLoading } = useAddProjectTemplateMutation({
    onSuccess: p => {
      invalidateQuery(useGetProjectsQuery);
      navigate(`/${getTenantIdentifier()}/projects/${p.addProjectTemplate.project?.id}`);
      notifySuccess(formatMessage({ id: 'Project template created successfully' }));
    }
  });

  const onSubmit = (values: ProjectFormValues) => {
    addProjectTemplate({
      input: {
        name: values.name,
        description: values.description ? values.description : undefined,
        hideBudgetFeature: !values.optInToBudgetFeature,
      }
    });

  };

  const disabled = isLoading;

  const { control, reset, handleSubmit } = useForm<ProjectFormValues>({ defaultValues });

  const nameRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    nameRef.current?.focus();
    if (defaultValues) {
      reset(defaultValues);
    }
  }, [reset]);

  return (
    <FormDialog
      title={formatMessage({ id: 'Create project template' })}
      {...dialogProps}
      maxWidth='sm'
      fullWidth
      onSubmit={handleSubmit(onSubmit)}
      isSubmitting={isLoading}
      disableClose={isLoading}
    >
      <Controller
        control={control}
        name='name'
        rules={{ required: formatMessage({ id: 'This field is required' }) }}
        render={({ field, fieldState: { error } }) => (
          <TextField
            inputRef={nameRef}
            {...field}
            disabled={disabled}
            required
            label={formatMessage({ id: 'Name' })}
            error={Boolean(error)}
            helperText={error?.message}
          />
        )}
      />

    </FormDialog>
  );
};