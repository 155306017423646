import { Badge, Box, Paper, Stack, Typography, styled } from '@mui/material';
import React, { ReactNode } from 'react';

interface Props {
  icon?: ReactNode;
  title?: string;
  notificationNumber?: number;
  children?: ReactNode;
  actionButton?: ReactNode;
  emptyState?: string;
}

const NotificationBox = styled(Box)(({ theme }) => ({
  borderLeftColor: theme.palette.error.main,
  borderLeftWidth: 4,
  borderLeftStyle: 'solid',
  marginLeft: theme.spacing(1.5),
  marginRight: theme.spacing(2.5),
  [theme.breakpoints.down('md')]: {
    borderLeftWidth: 2,
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(1)
  },
}));

const Icon = styled('div')(({ theme }) => ({
  color: theme.palette.primary.main,
  maxHeight: 28,
  '& .MuiSvgIcon-root': {
    fontSize: 28
  }
}));

export const PageSection: React.FC<Props> = ({ title, icon, notificationNumber, actionButton, children, emptyState }) => {
  const titleTypography = (
    <Typography variant='h6'>
      {title}
    </Typography>
  );

  const emptyStateNode = emptyState && (
    <Paper elevation={0} sx={{ p: 2, border: t => `1px solid ${t.palette.divider}` }}>
      <Typography color={'text.secondary'}>
        {emptyState}
      </Typography>
    </Paper>
  );

  return (
    <Stack>
      {title && (
        <Stack direction='row' spacing={2} alignItems='center' pt={2} pb={1}>
          {icon && (
            <Icon>
              {icon}
            </Icon>
          )}

          <Box flexGrow={1}>
            {notificationNumber ? (
              <Badge badgeContent={notificationNumber} color='error'>
                <Box p={'4px'}>
                  {titleTypography}
                </Box>
              </Badge>
            ) : titleTypography}
          </Box>

          {actionButton && (
            <Stack alignItems='end' pb={1}>
              {actionButton}
            </Stack>
          )}
        </Stack>
      )}

      {notificationNumber ? (
        <Stack direction='row'>
          <NotificationBox />

          <Stack width='100%'>
            {children || emptyStateNode}
          </Stack>
        </Stack>
      ) : (
        <Stack>
          {children || emptyStateNode}
        </Stack>
      )}
    </Stack>
  );
};