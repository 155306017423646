import { Card, CardActionArea, CardContent, CardMedia, DialogContent, Divider, Stack, Typography, useTheme } from '@mui/material';
import { FormType } from 'gql/index';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { AddFormForm } from './AddFormDialog';

import { ReactComponent as Messenger } from '@assets/images/undraw_messenger.svg';
import { ReactComponent as WaitInLine } from '@assets/images/undraw_wait_in_line.svg';

type Props = {
  onTypeSelected: () => void;
};

export const FormTypeStep: React.FC<Props> = (props: Props) => {
  const { formatMessage } = useIntl();
  const { setValue } = useFormContext<AddFormForm>();
  const theme = useTheme();
  const handleTypeSelected = (type: FormType) => {
    setValue('formType', type);
    props.onTypeSelected();
  };

  return (
    <DialogContent>
      <Stack pt={1} pb={4} gap={4} alignItems={'center'} flex={1}>
        <Typography variant='h4'>{formatMessage({ id: 'I want to...' })}</Typography>

        <Stack direction={'row'} justifyContent={'center'} flexWrap={'wrap'} gap={6}>
          <Card elevation={2} sx={{ maxWidth: '300px' }}>
            <CardActionArea onClick={() => handleTypeSelected(FormType.CollectResponse)} sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'start' }}>
              <CardMedia sx={{ pt: 2, px: 2, height: '180px' }}>
                <Messenger fill={theme.palette.primary.main} width='100%' height='100%' />
              </CardMedia>

              <Divider style={{ width: '100%' }} />

              <CardContent>
                <Typography variant="h5" color={'primary'}>
                  {formatMessage({ id: 'Collect information' })}
                </Typography>

                <Typography variant="body2" pt={1}>
                  {formatMessage({ id: 'Send a form to users which they can fill and send back.' })}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>

          <Card elevation={2} sx={{ maxWidth: '300px' }}>
            <CardActionArea onClick={() => handleTypeSelected(FormType.SelfServe)}>
              <CardMedia sx={{ pt: 2, px: 2, height: '180px' }}>
                <WaitInLine fill={theme.palette.primary.main} width='100%' height='100%' />
              </CardMedia>

              <Divider />

              <CardContent>
                <Typography variant="h5" component="div" color={'primary'}>
                  {formatMessage({ id: 'Create a self-serve form' })}
                </Typography>

                <Typography variant="body2" pt={1}>
                  {formatMessage({ id: 'Project members can use self-serve forms to initiate requests and can reuse the form multiple times. (e.g.: expense approval, reservation request)' })}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Stack>
      </Stack>
    </DialogContent>
  );
};