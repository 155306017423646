import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { LoadingButton } from '@mui/lab';
import { Divider, Stack, TextField, Typography } from '@mui/material';
import React, { useContext } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { PrincipalType, RoleAssignmentFragmentFragment, useReshareOrganizationResourceMutation, useReshareProjectResourceMutation } from '../../../../gql';
import { useNotification } from '../../../../utils/useNotification';
import { PrincipalItemContent } from '../../../users/components/PrincipalItemContent';
import { ShareableResourceItem } from '../Permissions/ShareableResourceItem';
import { ShareableResource } from '../Permissions/types';
import { PermissionsModalContext } from './PermissionsModal';

interface FormValues {
  message: string | '';
}

const defaultValues: FormValues = {
  message: '',
};

interface Props {
  resource: ShareableResource;
  roleAssignment: RoleAssignmentFragmentFragment;
}

export const SendNotificationView: React.FC<Props> = ({ resource, roleAssignment }) => {
  const { projectId: projectIdString } = useParams();
  const projectId = Number(projectIdString);
  const { formatMessage } = useIntl();
  const { notifySuccess } = useNotification();

  const { goToAccessSummary } = useContext(PermissionsModalContext);

  const { handleSubmit, control } = useForm<FormValues>({ defaultValues });

  const onSendNotificationSuccess = () => {
    notifySuccess(formatMessage({ id: 'Notification sent successfully.' }));
    goToAccessSummary(roleAssignment);
  };

  const { mutate: reshareProjectResource, isLoading: isSendingNotification } = useReshareProjectResourceMutation({
    onSuccess: onSendNotificationSuccess
  });

  const { mutate: reshareOrganizationResource, isLoading: isSendingOrganizationNotification } = useReshareOrganizationResourceMutation({
    onSuccess: onSendNotificationSuccess
  });

  const isLoading = isSendingNotification || isSendingOrganizationNotification;

  const onSubmit = (values: FormValues) => {
    const principalId = roleAssignment.securityGroup?.id ?? roleAssignment.user?.id;

    if (!principalId) {
      if (import.meta.env.DEV) console.error('SendNotificationView: Principal id is not defined');
      return;
    }

    if (projectId) {
      reshareProjectResource({
        input: {
          projectId,
          principalId,
          principalType: roleAssignment.user ? PrincipalType.User : PrincipalType.SecurityGroup,
          resourceId: resource.id,
          resourceType: resource.resourceType,
          message: values.message
        }
      });
    } else {
      reshareOrganizationResource({
        input: {
          principalId,
          principalType: roleAssignment.user ? PrincipalType.User : PrincipalType.SecurityGroup,
          resourceId: resource.id,
          resourceType: resource.resourceType,
          message: values.message
        }
      });
    }
  };

  return <Stack gap={2}>
    <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
      <Typography variant='h5'>{formatMessage({ id: 'Send Notification' })}</Typography>
    </Stack>

    <Stack direction={'row'} spacing={2} mb={2} justifyContent={'left'} alignItems={'center'} onClick={() => goToAccessSummary(roleAssignment)} sx={{ cursor: 'pointer' }}>
      <ArrowBackIcon />
      <Typography>{formatMessage({ id: 'Access Summary' })}</Typography>
    </Stack>

    <Divider />

    <Stack direction={'row'} spacing={3} mb={2} justifyContent={'left'} alignItems={'center'} sx={{ userSelect: 'none' }}>
      <Typography>{formatMessage({ id: 'Recipient' })}</Typography>
      <PrincipalItemContent roleAssignment={roleAssignment} />
    </Stack>

    <Stack direction={'row'} spacing={3} mb={2} justifyContent={'left'} alignItems={'center'} sx={{ userSelect: 'none' }}>
      <Typography>{formatMessage({ id: 'Subject' })}</Typography>
      <ShareableResourceItem resource={resource} />
    </Stack>

    <Divider />
    <Stack gap={3} >
      <Controller
        control={control}
        name='message'
        rules={{
          maxLength: { value: 250, message: formatMessage({ id: 'The message must be at most {count} characters.' }, { count: 250 }) }
        }}
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            multiline
            minRows={6}
            maxRows={10}
            label={formatMessage({ id: 'Message' })}
            error={!!error}
            helperText={error?.message}
            InputLabelProps={{ shrink: true }}
            placeholder={formatMessage({ id: 'Type to personalize email notification...' })}
          />
        )}
      />
    </Stack>

    <Stack direction='row' justifyContent='right' >
      <LoadingButton variant='contained' loading={isLoading} onClick={handleSubmit(onSubmit)}>{formatMessage({ id: 'Send Notification' })}</LoadingButton>
    </Stack>
  </Stack>;
};