import { Property } from '@components/DataDisplay/Property';
import { PropertyGroup } from '@components/DataDisplay/PropertyGroup';
import { TextProperty } from '@components/DataDisplay/TextProperty';
import { UserAvatar } from '@components/UserAvatar/UserAvatar';
import { Chip, Link as MuiLink, Stack } from '@mui/material';
import dayjs from 'dayjs';
import { ExternalLinkFragmentFragment } from 'gql/index';
import React from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

interface Props {
  externalLink: ExternalLinkFragmentFragment;
}

export const ExternalLinkBasicProperties: React.FC<Props> = ({ externalLink }) => {
  const { formatMessage } = useIntl();

  return (
    <PropertyGroup title={formatMessage({ id: 'Properties' })}>
      <TextProperty propertyName={formatMessage({ id: 'Name' })} propertyValue={externalLink.name} />

      <Property propertyName={formatMessage({ id: 'Link' })}>
        <MuiLink component={Link} to={externalLink.link}>
          {externalLink.link}
        </MuiLink>
      </Property>

      <Property propertyName={formatMessage({ id: 'Created by' })}>
        <Stack direction='row'>
          {externalLink.createdBy && (
            <Chip
              avatar={<UserAvatar displayName={externalLink.createdBy.fullName} />}
              label={externalLink.createdBy.fullName}
            />
          )}
        </Stack>
      </Property>

      <TextProperty propertyName={formatMessage({ id: 'Created on' })} propertyValue={dayjs(externalLink.createdOn).format('LL')} />
    </PropertyGroup>
  );
};