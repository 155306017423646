import { Document } from '../../gql';
import { getTenantIdentifier } from '../../utils/getTenantIdentifier';
import { DownloadLinkButton } from './DownloadLinkButton';

type ProjectDocumentDownloadButtonProps = {
  document: Pick<Document, 'id' | 'fileName'>;
  color?: string;
}
export const ProjectDocumentDownloadButton = (props: ProjectDocumentDownloadButtonProps) => {
  const { document } = props;
  return <DownloadLinkButton link={`/${getTenantIdentifier()}/api/Documents/${document.id}`} fileName={document.fileName} />;
};