import { TableRow } from '@mui/material';
import { HeaderCell } from './HeaderCell';
import { ValueCell } from './ValueCell';

interface OwnProps {
  header: string;
}

export const EmptyRow: React.FC<OwnProps> = ({ header }) => {
  return (
    <TableRow>
      <HeaderCell>{header}</HeaderCell>
      <ValueCell size='small' />
      <ValueCell size='small' />
      <ValueCell size='small' />
    </TableRow>
  );
};