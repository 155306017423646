import { DataTable } from '@components/DataTable';
import { Delete, Looks3, LooksOne, LooksTwo } from '@mui/icons-material';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Alert, Box, Chip, Divider, Stack, TextField, ToggleButton, ToggleButtonGroup, Typography, useTheme } from '@mui/material';
import { GridActionsCellItem, GridColDef, useGridApiRef } from '@mui/x-data-grid-pro';
import { useMoneyFormatter } from '@utils/numberUtils';
import _ from 'lodash';
import React, { useState } from 'react';
import { Controller, useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { CommercialConstructionCalculatorData, RentalRevenue, calculateAdditionalRent, calculateAnnualBaseRent, calculateAnnualRent, calculateRentableSquareFootage } from '../../types';
import { AdditionalRevenuesTable } from './AdditionalRevenuesTable';
import { RevenuesGridToolbar } from './RevenuesGridToolbar';

type Props = {
  onChange: () => void;
};

export const RevenuesSection: React.FC<Props> = ({ onChange }) => {
  const apiGridRef = useGridApiRef();
  const { control, setValue, getValues } = useFormContext<CommercialConstructionCalculatorData>();
  const { fields, update, append, remove } = useFieldArray({ control: control, name: 'revenues.rentalRevenues', keyName: 'uid' });

  const { formatMessage, formatNumber } = useIntl();
  const theme = useTheme();
  const formatMoney = useMoneyFormatter();

  const operatingCosts = useWatch({ control: control, name: 'operatingCosts' });
  const totalOperatingCosts = _.sumBy(operatingCosts, p => p.annualCost);
  const selectedMortgageScenario = useWatch({ control: control, name: 'revenues.selectedMortgageScenario' });
  const targetRevenuePercent = useWatch({ control: control, name: 'revenues.targetRevenuePercent' });
  const vacancy = useWatch({ control: control, name: 'revenues.vacancy' });
  const totalUsableSquareFootage = useWatch({ control: control, name: 'revenues.totalUsableSquareFootage' });
  const commonAreaPercentage = useWatch({ control: control, name: 'revenues.commonAreaPercentage' });

  const commonAreaSquareFootage = totalUsableSquareFootage / (1 - commonAreaPercentage) - totalUsableSquareFootage;
  const totalRentableSquareFootage = totalUsableSquareFootage + commonAreaSquareFootage;

  const additionalRevenues = useWatch({ control: control, name: 'revenues.additionalRevenue' });
  const potentialRevenue = _.sumBy(fields, p => calculateAnnualBaseRent(p, commonAreaPercentage)) + _.sumBy(additionalRevenues, p => p.annualCost);
  const annualPayment = getValues(`mortgageCalculator.scenarios.${selectedMortgageScenario}.annualPayment`);
  const targetRevenue = annualPayment + annualPayment * targetRevenuePercent;
  const projectedNetRevenue = (1 - vacancy) * potentialRevenue;
  const revenueDifferenceWithTarget = projectedNetRevenue - targetRevenue;
  const targetAnnualCostPerSquareFoot = Math.round(targetRevenue / totalRentableSquareFootage * 100) / 100;

  const columns: GridColDef<RentalRevenue>[] = [
    {
      field: 'name',
      headerName: formatMessage({ id: 'Name' }),
      type: 'string',
      editable: true,
      cellClassName: 'editable',
      flex: 1,
      minWidth: 200
    },
    {
      field: 'usableSquareFootage',
      headerName: formatMessage({ id: 'Usable square footage' }),
      type: 'number',
      width: 200,
      editable: true,
      cellClassName: 'editable',
      valueFormatter: ({ value }) => isNaN(value) ? '' : formatNumber(value, { maximumFractionDigits: 2 })
    },
    {
      field: 'rentableSquareFootage',
      headerName: formatMessage({ id: 'Rentable square footage' }),
      type: 'number',
      width: 200,
      editable: false,
      valueGetter: (params) => params.row.usableSquareFootage / (1 - commonAreaPercentage),
      valueFormatter: ({ value }) => isNaN(value) ? '' : formatNumber(value, { maximumFractionDigits: 0 })
    },
    {
      field: 'annualCostPerSquareFootage',
      headerName: formatMessage({ id: 'Base rent per sq. ft.' }),
      type: 'number',
      width: 200,
      editable: true,
      cellClassName: 'editable',
      valueFormatter: ({ value }) => isNaN(value) ? '' : formatNumber(value, { style: 'currency', currency: 'CAD', maximumFractionDigits: 2 })
    },
    {
      field: 'baseAnnualRent',
      headerName: formatMessage({ id: 'Base annual rent' }),
      type: 'number',
      width: 150,
      editable: false,
      valueGetter: (params) => calculateRentableSquareFootage(params.row, commonAreaPercentage) * params.row.annualCostPerSquareFootage,
      valueFormatter: ({ value }) => isNaN(value) ? '' : formatMoney(value)
    },
    {
      field: 'additionalRentPerSquareFoot',
      headerName: formatMessage({ id: 'Additional rent per sq. ft.' }),
      type: 'number',
      width: 150,
      editable: false,
      valueGetter: (params) => calculateAdditionalRent(params.row, totalUsableSquareFootage, totalOperatingCosts) / calculateRentableSquareFootage(params.row, commonAreaPercentage),
      valueFormatter: ({ value }) => isNaN(value) ? '' : formatNumber(value, { style: 'currency', currency: 'CAD', maximumFractionDigits: 2 })
    },
    {
      field: 'additionalAnnualRent',
      headerName: formatMessage({ id: 'Additional rent' }),
      type: 'number',
      width: 150,
      editable: false,
      valueGetter: (params) => calculateAdditionalRent(params.row, totalUsableSquareFootage, totalOperatingCosts),
      valueFormatter: ({ value }) => isNaN(value) ? '' : formatMoney(value)
    },
    {
      field: 'annualRent',
      headerName: formatMessage({ id: 'Annual rent' }),
      type: 'number',
      width: 150,
      editable: false,
      valueGetter: params => calculateAnnualRent(params.row, commonAreaPercentage, totalOperatingCosts, totalUsableSquareFootage),
      valueFormatter: ({ value }) => isNaN(value) ? '' : formatMoney(value)
    },
    {
      field: 'annualCostPerSquareFoot',
      headerName: formatMessage({ id: 'Annual rent per sq. ft.' }),
      type: 'number',
      width: 200,
      editable: false,
      valueGetter: params => calculateAnnualRent(params.row, commonAreaPercentage, totalOperatingCosts, totalUsableSquareFootage) / calculateRentableSquareFootage(params.row, commonAreaPercentage),
      valueFormatter: ({ value }) => isNaN(value) ? '' : formatNumber(value, { style: 'currency', currency: 'CAD', maximumFractionDigits: 2 }),
    },
    {
      field: 'actions',
      type: 'actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => [
        <GridActionsCellItem
          icon={<Delete color='action' />}
          label={formatMessage({ id: 'Delete' })}

          onClick={() => {
            const fieldIndex = fields.findIndex(p => p.id === id);
            if (fieldIndex >= 0) {
              remove(fieldIndex);
              onChange();
            }
          }}
          color="inherit"
        />,
      ]
    }
  ];

  const [expanded, setExpanded] = useState(false);

  return <Accordion expanded={expanded} onChange={(_, expanded) => setExpanded(expanded)}>
    <AccordionSummary expandIcon={<ExpandMore />}>
      <Stack direction={'row'} gap={2} flexWrap={'wrap'}>
        <Typography variant='h4'>{formatMessage({ id: 'Step 4 - Revenues' })}</Typography>
        <Stack direction={'row'} spacing={2} alignItems={'center'}>
          <Typography variant='h4' mr={4} color='primary'>{formatMoney(projectedNetRevenue, false)}</Typography>
          <Chip
            color={revenueDifferenceWithTarget >= 0 ? 'primary' : 'error'}
            label={`${revenueDifferenceWithTarget >= 0 ? '+' : ''} ${formatMoney(revenueDifferenceWithTarget, false)}`}
          />
        </Stack>
      </Stack>
    </AccordionSummary>
    <AccordionDetails>
      <Stack gap={2}>
        <Stack direction={'row'} alignItems={'center'} gap={2}>
          <Typography >{formatMessage({ id: 'Mortgage scenario' })}</Typography>
          <Controller
            control={control}
            name='revenues.selectedMortgageScenario'
            render={({ field }) => <ToggleButtonGroup
              value={field.value}
              exclusive
              onChange={(_, value) => {
                setValue('revenues.selectedMortgageScenario', Number(value));
                onChange();
              }}
            >
              <ToggleButton value={0} aria-label="left aligned">
                <LooksOne />
              </ToggleButton>
              <ToggleButton value={1} aria-label="centered">
                <LooksTwo />
              </ToggleButton>
              <ToggleButton value={2} aria-label="right aligned">
                <Looks3 />
              </ToggleButton>
            </ToggleButtonGroup>}
          />

        </Stack>
        <Stack direction={'row'} alignItems={'center'} gap={2}>
          <Typography >{formatMessage({ id: 'Annual cost:' })}</Typography>
          <Typography fontWeight={600}>
            {formatMoney(annualPayment)}
          </Typography>
        </Stack>
        <Stack direction={'row'} alignItems={'center'} gap={2}>
          <Typography >{formatMessage({ id: 'Target revenue %:' })}</Typography>
          <Controller
            control={control}
            name='revenues.targetRevenuePercent'
            render={({ field }) => <TextField
              type='number'
              variant='outlined'

              InputProps={{ endAdornment: '%' }}
              value={field.value * 100}
              sx={{ maxWidth: 140 }}
              onChange={(e) => {
                setValue('revenues.targetRevenuePercent', Number(e.currentTarget.value) / 100);
                onChange();
              }} />}
          />
        </Stack>
        <Stack direction={'row'} alignItems={'center'} gap={2}>
          <Typography >{formatMessage({ id: 'Target revenue' })}</Typography>
          <Typography fontWeight={600}>
            {formatMoney(targetRevenue)}
          </Typography>
        </Stack>
        <Divider />
        <Stack direction={'row'} alignItems={'center'} gap={2}>
          <Typography >{formatMessage({ id: 'Total usable square footage:' })}</Typography>
          <Controller
            control={control}
            name='revenues.totalUsableSquareFootage'
            render={({ field }) => <TextField
              type='number'
              variant='outlined'
              value={field.value}
              sx={{ maxWidth: 140 }}
              onChange={(e) => {
                setValue('revenues.totalUsableSquareFootage', Number(e.currentTarget.value));
                onChange();
              }} />}
          />
        </Stack>
        <Stack direction={'row'} alignItems={'center'} gap={2}>
          <Typography >{formatMessage({ id: 'Common area percentage:' })}</Typography>
          <Controller
            control={control}
            name='revenues.commonAreaPercentage'
            render={({ field }) => <TextField
              type='number'
              variant='outlined'
              value={formatNumber(field.value * 100, { maximumFractionDigits: 2 })}
              sx={{ maxWidth: 140 }}
              InputProps={{ endAdornment: '%' }}
              onChange={(e) => {
                setValue('revenues.commonAreaPercentage', Number(e.currentTarget.value) / 100);
                onChange();
              }} />}
          />
        </Stack>
        <Stack direction={'row'} alignItems={'center'} gap={2}>
          <Typography >{formatMessage({ id: 'Estimated total rentable space:' })}</Typography>
          <Typography fontWeight={600}>
            {formatNumber(totalRentableSquareFootage, { maximumFractionDigits: 0 })}
          </Typography>
        </Stack>
        <Stack direction={'row'} alignItems={'center'} gap={2}>
          <Typography >{formatMessage({ id: 'Target rent per square foot:' })}</Typography>
          <Typography fontWeight={600}>
            {formatNumber(targetAnnualCostPerSquareFoot, { style: 'currency', currency: 'CAD', maximumFractionDigits: 2 })}
          </Typography>
        </Stack>
        <Stack sx={{ pt: 4 }} gap={2}>
          <Typography variant='h5'>{formatMessage({ id: 'Rental revenues' })}</Typography>
          <Box
            sx={{
              '& .editable': {
                fontWeight: 600,

              }
            }}>
            <DataTable<RentalRevenue>
              variant='minimal'
              apiRef={apiGridRef}
              columns={columns}
              rows={fields}
              getRowId={row => row.id}
              hideFooter
              disableColumnMenu
              disableColumnPinning
              disableColumnResize
              disableColumnReorder
              slots={{
                toolbar: RevenuesGridToolbar,
              }}
              slotProps={{
                toolbar: {
                  onAddRow: () => {
                    const rowId = crypto.randomUUID();
                    append({ id: rowId, name: formatMessage({ id: 'Unit' }) + ` ${fields.length + 1}`, annualCostPerSquareFootage: targetAnnualCostPerSquareFoot ?? 0, usableSquareFootage: 0 });
                    onChange();
                  }
                }
              }}
              autoHeight
              localeText={{
                ...theme.components?.MuiDataGrid?.defaultProps?.localeText,
                noRowsLabel: formatMessage({ id: 'No revenues entered' }),
              }}
              processRowUpdate={(newRow, oldRow) => {
                const rowIndex = fields.findIndex(p => p.id === oldRow.id);

                if (rowIndex >= 0) {
                  update(rowIndex, newRow);
                }

                onChange();
                return newRow;
              }}
              onCellClick={(params) => params.isEditable && apiGridRef.current.startCellEditMode({ field: params.field, id: params.id })}
            />
          </Box>
          {_.sumBy(fields, p => p.usableSquareFootage) !== totalUsableSquareFootage
            && <Alert severity='warning'>
              {formatMessage({ id: 'The sum of usable rental space does not match the specified total usable space.' })}
            </Alert>
          }
        </Stack>

        <AdditionalRevenuesTable onChange={onChange} />

        <Stack maxWidth={400} gap={2} mt={4}>
          <Stack direction={'row'} alignItems={'center'} gap={2} justifyContent={'space-between'} flexWrap={'wrap'}>
            <Typography >{formatMessage({ id: 'Total potential revenues:' })}</Typography>
            <Typography fontWeight={600}>{formatMoney(potentialRevenue)}</Typography>
          </Stack>
          <Stack direction={'row'} alignItems={'center'} gap={2} justifyContent={'space-between'} flexWrap={'wrap'}>
            <Typography >{formatMessage({ id: 'Vacancy (%):' })}</Typography>
            <Controller
              control={control}
              name='revenues.vacancy'
              render={({ field }) => <TextField
                type='number'
                variant='outlined'
                InputProps={{ endAdornment: '%' }}
                value={field.value * 100}
                sx={{ maxWidth: 140 }}
                onChange={(e) => {
                  setValue('revenues.vacancy', Number(e.currentTarget.value) / 100);
                  onChange();
                }} />}
            />
          </Stack>
          <Stack direction={'row'} alignItems={'center'} gap={2} justifyContent={'space-between'} flexWrap={'wrap'}>
            <Typography >{formatMessage({ id: 'Projected net revenue:' })}</Typography>
            <Typography fontWeight={600}>{formatMoney(projectedNetRevenue)}</Typography>
          </Stack>
          <Stack direction={'row'} alignItems={'center'} gap={2} justifyContent={'space-between'} flexWrap={'wrap'}>
            <Typography >{formatMessage({ id: 'Difference with target revenue:' })}</Typography>
            <Typography fontWeight={600} color={revenueDifferenceWithTarget > 0 ? 'primary' : 'error'} >
              {(revenueDifferenceWithTarget > 0 ? '+' : '') + formatMoney(revenueDifferenceWithTarget)}</Typography>
          </Stack>
        </Stack>
      </Stack>

    </AccordionDetails>
  </Accordion >;
};