import { CircularProgress, Divider } from '@mui/material';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useRecoilState } from 'recoil';
import { ResponsiveDrawer } from '../../../../components/Drawers';
import { UpdateMyPreferencesInput } from '../../../../gql';
import { useGetMe } from '../../../../utils/useGetMe';
import { isSettingsMenuOpenState } from '../../atoms';
import { LanguageSettingsSection } from './LanguageSettingsSection';
import { NotificationsSection } from './NotificationsSection/NotificationsSection';
import { SubscriptionSection } from './SubscriptionSection/SubscriptionSection';
import { ThemeSettingsSection } from './ThemeSettingsSection';
import { UserSettingsSection } from './UserSettingsSection';

export const SettingsDrawer: React.FC = () => {
  const { formatMessage } = useIntl();
  const [isSettingsMenuOpen, setIsSettingsMenuOpen] = useRecoilState(isSettingsMenuOpenState);
  const closeMenu = () => setIsSettingsMenuOpen(false);
  const { me } = useGetMe();

  const form = useForm<UpdateMyPreferencesInput>({ defaultValues: { preferredLanguage: me?.language, optOutOfWeeklyEmails: me?.optedOutOfWeeklyEmails } });

  return (
    <ResponsiveDrawer
      open={isSettingsMenuOpen}
      onClose={closeMenu}
      header={formatMessage({ id: 'User settings' })}
      disablePadding
    >
      {!me && <CircularProgress />}
      {me &&
        <FormProvider {...form}>
          <UserSettingsSection />
          <Divider />

          <LanguageSettingsSection />
          <Divider />
          <ThemeSettingsSection />
          <Divider />
          <NotificationsSection />
          <Divider />
          <SubscriptionSection />
        </FormProvider>
      }
    </ResponsiveDrawer>
  );
};