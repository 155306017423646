import { useCallback } from 'react';
import { useIntl } from 'react-intl';

export const useMoneyFormatter = () => {
  const { formatNumber } = useIntl();

  const formatMoney = useCallback((value: number | undefined, showDecimals = true) => {
    if (!value) {
      return '';
    }

    return formatNumber(value,
      {
        style: 'currency',
        currency: 'CAD',
        minimumFractionDigits: showDecimals ? 2 : 0,
        maximumFractionDigits: showDecimals ? 2 : 0
      });
  }, [formatNumber]);

  return formatMoney;
};