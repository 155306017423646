import { UploadManagerContext } from '@components/FileUpload/UploadManagerContext';
import { ExpandMore } from '@mui/icons-material';
import { AccordionDetails, AccordionSummary, CircularProgress, Accordion as MuiAccordion, Stack, Typography, styled } from '@mui/material';
import { useContext, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { UploadListItem } from './UploadListItem';

interface OwnProps {
  uploadsBatchId: string;
}

const Accordion = styled(MuiAccordion)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  boxShadow: 'none',
  '.MuiAccordionSummary-content': {
    margin: '8px 0'
  },
  '.MuiAccordionSummary-root': {
    minHeight: 'unset',
  },
  '.MuiAccordionSummary-root.Mui-expanded': {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
}));

export const UploadList: React.FC<OwnProps> = ({ uploadsBatchId }) => {
  const { formatMessage } = useIntl();
  const { uploads: allUploads } = useContext(UploadManagerContext);
  const uploads = useMemo(() => allUploads.filter(p => p.batchId === uploadsBatchId), [allUploads, uploadsBatchId]);

  return (
    <Accordion defaultExpanded sx={{ display: uploads.length > 0 ? 'auto' : 'none' }}>
      <AccordionSummary expandIcon={<ExpandMore />} sx={{
        flexDirection: 'row-reverse',
      }}>
        <Stack direction='row' alignItems='center' gap={3} ml={1}>
          <Typography variant='body1'>{formatMessage({ id: 'Upload in progress' })}</Typography>
          <CircularProgress size={16} />
        </Stack>
      </AccordionSummary>
      <AccordionDetails sx={{ p: 0 }}>
        <Stack direction='column' pt={1} gap={1}>
          {uploads.map((file, index) =>
            <UploadListItem key={index} file={file} />
          )}
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};