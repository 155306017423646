import { PageContainer } from '@components/Layout/PageContainer';
import { PageTitle } from '@components/Layout/PageTitle';
import { ResponsiveButton } from '@components/ResponsiveButton';
import { Add, People } from '@mui/icons-material';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { InternalUsersTable } from '../components/InternalUsersTable';
import { InviteUserDrawer } from '../components/InviteUserDrawer';
import { UserDrawer } from '../components/UserDrawer';

export const InternalUsersPage: React.FC = () => {
  const { formatMessage } = useIntl();

  const [invitingUser, setIsInvitingUser] = useState(false);

  const [userId, setUserId] = useState<string>();

  return <>
    <PageTitle
      title={formatMessage({ id: 'Internal users' })}
      icon={<People />}
      actionButton={(
        <ResponsiveButton icon={<Add />} color='primary' variant='contained' onClick={() => setIsInvitingUser(true)}>
          {formatMessage({ id: 'Invite internal user' })}
        </ResponsiveButton>
      )}
      backgroundColor='secondary'
    />

    <PageContainer>
      <InviteUserDrawer open={invitingUser} onClose={() => setIsInvitingUser(false)} />

      <UserDrawer userId={userId} onClose={() => setUserId(undefined)} />


      <InternalUsersTable onUserClicked={setUserId} />
    </PageContainer>
  </>;
};