import { Cancel } from '@mui/icons-material';
import { DialogContent, Stack, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import { ResponsiveDialog } from '../../../components/Dialogs/ResponsiveDialog';
import { useResponsive } from '../../../utils/useResponsive';


export const UnauthorizedAccessDialog = () => {
  const { isMobile } = useResponsive();
  const { formatMessage } = useIntl();

  const inner = (
    <Stack alignItems='center' justifyContent='center' p={3} spacing={5}>
      <Cancel color='error' style={{ height: '100px', width: 'auto' }} />

      <Stack spacing={0.5}>
        <Typography fontWeight={600} variant='h4' textAlign='center'>{formatMessage({ id: 'You no longer have access to this resource' })}</Typography>
        <Typography variant='h5' textAlign='center'>{formatMessage({ id: 'Your access might have been revoked or the resource may have been deleted.' })}</Typography>
      </Stack>
    </Stack>
  );

  if (isMobile) return inner;

  return (
    <ResponsiveDialog open maxWidth='lg'>
      <DialogContent>
        {inner}
      </DialogContent>
    </ResponsiveDialog>
  );
};