import { DataTable, DataTableProps } from '@components/DataTable';
import { ImageWithThumbnail, ImagesCarousel } from '@components/Navigation/ImagesCarousel';
import { UnstyledLink } from '@components/UnstyledLink';
import { getDocumentThumbnailUrl, getDocumentUrl } from '@modules/folders/utils/documentUrls';
import { useCurrentProject } from '@modules/projects/utils/useCurrentProject';
import { Download, Visibility } from '@mui/icons-material';
import { Chip, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';
import { getTenantIdentifier } from '@utils/getTenantIdentifier';
import { useMoneyFormatter } from '@utils/numberUtils';
import dayjs from 'dayjs';
import { DocumentThumbnailStatus, ExpenseFragmentFragment } from 'gql/index';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { ExpenseStatusChip } from '../CommercialConstructionCalculator/components/ExpenseStatusChip';
import { ExpenseActions } from './ExpenseActions';

interface Props extends Omit<DataTableProps<ExpenseFragmentFragment>, 'rows' | 'columns'> {
  expenses: ExpenseFragmentFragment[];
}

const commonColumnOptions: Partial<GridColDef<ExpenseFragmentFragment>> = {
  disableExport: true,
  disableReorder: true,
  editable: false
};

export const ExpensesTable: React.FC<Props> = ({ expenses, ...dataTableProps }) => {
  const { formatMessage } = useIntl();
  const { isArchived, canManageBudgetAndExpenses } = useCurrentProject();

  const formatMoney = useMoneyFormatter();

  const [viewingThumbnailId, setViewingThumbnailId] = useState<number | null>(null);

  const viewingThumbnailDocument = expenses.flatMap(e => e.attachments).find(a => a.id === viewingThumbnailId);

  const viewingThumbnail: ImageWithThumbnail | undefined = viewingThumbnailDocument ? {
    id: viewingThumbnailDocument.id,
    fileName: viewingThumbnailDocument.fileName,
    imageUrl: getDocumentUrl(viewingThumbnailDocument.id),
    thumbnailUrl: viewingThumbnailDocument.thumbnailStatus === DocumentThumbnailStatus.Ready
      ? getDocumentThumbnailUrl(viewingThumbnailDocument.id)
      : undefined,
    thumbnailStatus: viewingThumbnailDocument.thumbnailStatus
  } : undefined;

  const columns: GridColDef<ExpenseFragmentFragment>[] = [
    {
      field: 'name',
      headerName: formatMessage({ id: 'Name' }),
      minWidth: 200,
      flex: 1
    },
    {
      field: 'status',
      headerName: formatMessage({ id: 'Status' }),
      flex: 1,
      minWidth: 150,
      valueGetter: ({ row }) => row.status,
      renderCell: ({ row }) => (
        <ExpenseStatusChip status={row.status} size='small' />
      )
    },
    {
      field: 'expendedAmount',
      minWidth: 200,
      headerName: formatMessage({ id: 'Amount' }),
      valueFormatter: ({ value }) => formatMoney(value),
      flex: 1
    },
    {
      field: 'expendedDate',
      minWidth: 200,
      headerName: formatMessage({ id: 'Date' }),
      valueGetter: ({ row }) => row.expenseDate ? dayjs(row.expenseDate).toDate() : undefined,
      flex: 1,
      type: 'date'
    },
    {
      field: 'budgetItem',
      minWidth: 200,
      headerName: formatMessage({ id: 'Budget item' }),
      flex: 1,
      valueGetter: ({ row }) => row.budgetItem?.name ?? formatMessage({ id: 'Unassigned' }),
      renderCell: ({ value, row }) => (
        <Typography fontStyle={!row.budgetItem ? 'italic' : 'normal'}>
          {value}
        </Typography>
      )
    },
    {
      field: 'Invoice',
      minWidth: 200,
      headerName: formatMessage({ id: 'Invoice' }),
      flex: 1,
      valueGetter: ({ row }) => row.attachments.at(0),
      renderCell: ({ row }) => {
        const value = row.attachments.at(0);

        if (!value) return null;

        return value.thumbnailStatus !== DocumentThumbnailStatus.NotAnImage ? (
          <Chip
            label={value.fileName}
            clickable
            onClick={() => setViewingThumbnailId(value.id)}
            icon={<Visibility />}
          />
        ) : (
          <UnstyledLink target="_blank" rel="noreferrer" to={`/${getTenantIdentifier()}/api/Documents/${value.id}`}>
            <Chip
              label={value.fileName}
              clickable
              icon={<Download />}
            />
          </UnstyledLink>
        );
      }
    }
  ];

  if (canManageBudgetAndExpenses) {
    columns.push(
      {
        field: 'action',
        headerName: '',
        width: 64,
        sortable: false,
        disableColumnMenu: true,
        renderCell: isArchived ? undefined : ({ row }) => (
          <ExpenseActions expense={row} />
        ),
      });
  }

  return (
    <>
      <DataTable
        columns={columns.map(c => ({ ...commonColumnOptions, ...c }))}
        rows={expenses}
        {...dataTableProps}
      />

      <ImagesCarousel
        open={!!viewingThumbnail}
        images={viewingThumbnail ? [viewingThumbnail] : []}
        onClose={() => setViewingThumbnailId(null)}
      />
    </>
  );
};