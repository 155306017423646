import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Stack, Typography } from '@mui/material';
import React from 'react';
import { useIntl } from 'react-intl';
import { PageTitle } from '../../../components/Layout/PageTitle';
import { FormDefinitionFragmentFragment, FormValueFragmentFragment, FormValueInput, ProjectAccessTokenQuery, SubmissionStatus } from '../../../gql';
import { FormFiller } from '../../forms/components/FormFiller';
import { SignUpBanner } from '../components/SignUpBanner';

type Props = {
  submissionId: number;
  isLoading: boolean;
  content: NonNullable<ProjectAccessTokenQuery['projectAccessTokenContent']>;
  formDefinition: FormDefinitionFragmentFragment;
  formValues: FormValueFragmentFragment[];
  token: string;
  isSubmitting: boolean;
  onSubmit: (values: FormValueInput[]) => void;
};

export const AnonymousFormMobileView: React.FC<Props> = ({ onSubmit, submissionId, content, formDefinition, formValues, isLoading, isSubmitting }) => {
  const formSubmission = content?.formSubmission;
  const { formatMessage } = useIntl();

  const canFill = formSubmission?.status === SubmissionStatus.IsResponding && formSubmission?.belongsToUserId == content.forUser?.id;

  return (
    <Stack overflow='auto' height='100%'>
      {content.projectForm && (
        <Stack flex={1}>
          {formSubmission?.status !== SubmissionStatus.IsResponding && (
            <>
              <PageTitle loading={isLoading} title={content?.project?.name ?? ''} />

              <Stack alignItems={'center'} justifyContent={'center'} padding={4} flex={1}>
                <CheckCircleOutlineIcon color='success' sx={{ fontSize: 120 }} />
                <Typography variant='body1'>{formatMessage({ id: 'The form has been submitted successfully.' })} </Typography>
                <Typography variant='body2'>{formatMessage({ id: 'Thank you!' })} </Typography>
              </Stack>
            </>
          )}

          {canFill && (
            <FormFiller
              isSubmitting={isSubmitting}
              disabled={isLoading}
              onSubmit={onSubmit}
              formDefinition={formDefinition}
              values={formValues}
              submissionId={submissionId}
            />
          )}
        </Stack>
      )}

      {content.project && (
        <SignUpBanner userFullName={content.forUser?.fullName ?? ''} tenantDisplayName={content.tenant?.name ?? ''} projectId={content.project?.id} />
      )}
    </Stack>
  );
};