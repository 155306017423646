import { useCurrentProject } from '@modules/projects/utils/useCurrentProject';
import { getTenantIdentifier } from '@utils/getTenantIdentifier';
import { useNotification } from '@utils/useNotification';
import { Folder, FolderEntryFragmentFragment } from 'gql/index';
import React, { createContext, useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';

type MyFolder = Pick<Folder, 'id' | 'name' | 'path' | 'parentFolderId'>;

interface ProjectDocumentsContextProps {
  breadcrumbPath: number[];
  setBreadcrumbPath: (path: number[]) => void;
  currentFolderId: number;

  selectedEntryId?: number;
  setSelectedEntryId?: (id?: number) => void;

  movingFolder?: MyFolder;
  setMovingFolder?: (folder?: MyFolder) => void;
  movingEntry?: FolderEntryFragmentFragment;
  setMovingEntry?: (entry?: FolderEntryFragmentFragment) => void;

  isArchived: boolean;
}

export const ProjectDocumentsContext = createContext<ProjectDocumentsContextProps>({
  currentFolderId: 0,
  breadcrumbPath: [],
  setBreadcrumbPath: (_: number[]) => { /* Default function */ },
  isArchived: false
});

interface Props {
  children: React.ReactNode;
}

export const ProjectDocumentsContextProvider: React.FC<Props> = ({ children }) => {
  const { formatMessage } = useIntl();
  const { notifyFailure } = useNotification();
  const navigate = useNavigate();

  const { rootFolder, isArchived, projectId } = useCurrentProject();

  const { '*': splatUnprotected } = useParams();
  const splat = splatUnprotected ?? '';

  const breadcrumbPath = splat.split('/').map(Number).filter(n => !!n) ?? [];
  const currentFolderId = breadcrumbPath[breadcrumbPath.length - 1] ?? rootFolder?.id;

  const setBreadcrumbPath = useCallback((pathIds: number[]) => {
    const path = pathIds.join('/');
    navigate(`/${getTenantIdentifier()}/projects/${projectId}/documents/${path}`);

    const newCurrentFolderId = pathIds[pathIds.length - 1];
    if (newCurrentFolderId != currentFolderId) {
      setSelectedEntryId(undefined);
    }
  }, [currentFolderId, navigate, projectId]);

  const [movingFolder, _setMovingFolder] = useState<MyFolder | undefined>(undefined);
  const [movingEntry, _setMovingEntry] = useState<FolderEntryFragmentFragment | undefined>(undefined);

  const setMovingFolder = useCallback((folder?: MyFolder) => {
    if (movingEntry && folder !== undefined) {
      return notifyFailure(formatMessage({ id: 'You are already moving something. Please confirm or cancel the current move first.' }));
    }
    _setMovingFolder(folder);
  }, [formatMessage, movingEntry, notifyFailure]);

  const setMovingEntry = useCallback((entry?: FolderEntryFragmentFragment) => {
    if (movingFolder && entry !== undefined) {
      return notifyFailure(formatMessage({ id: 'You are already moving something. Please confirm or cancel the current move first.' }));
    }
    _setMovingEntry(entry);
  }, [formatMessage, movingFolder, notifyFailure]);

  const [selectedEntryId, setSelectedEntryId] = useState<number | undefined>(undefined);

  if (!currentFolderId) {
    return null;
  }

  return (
    <ProjectDocumentsContext.Provider value={{
      breadcrumbPath, setBreadcrumbPath, currentFolderId, movingFolder, setMovingFolder, isArchived: isArchived ?? false,
      movingEntry, setMovingEntry, selectedEntryId, setSelectedEntryId
    }}>
      {children}
    </ProjectDocumentsContext.Provider>
  );
};