import { useMoneyFormatter } from '@utils/numberUtils';
import { useIntl } from 'react-intl';
import { isPercentageBasedCalculationMethod, OperatingCost, OperatingCostCalculationMethod } from '../../types';

export const useOperatingCostFormatter = () => {
  const { formatMessage, formatNumber } = useIntl();
  const formatMoney = useMoneyFormatter();

  const formatCost = (cost: OperatingCost) => {
    if (isNaN(cost.cost) || cost.id == null) {
      return '-';
    }

    if (isPercentageBasedCalculationMethod(cost.calculationMethod)) {
      return formatNumber(cost.cost, { style: 'percent', maximumFractionDigits: 2 });
    }

    if (cost.calculationMethod === OperatingCostCalculationMethod.CostPerSquareFootage) {
      return formatNumber(cost.cost, { maximumFractionDigits: 2 }) + formatMessage({ id: '$ per sq. ft.' });
    }

    return formatMoney(cost.cost);
  };

  return { formatCost };
};