import { useContextSafe } from '@utils/useContextSafe';
import { FieldType, FormDefinitionFragmentFragment } from 'gql/index';
import React, { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { PresenceDecorator } from '../../../../../components/Tooltips/PresenceDecorator';
import { FormFillerContext } from '../FormFillerContextProvider';
import { FormFillerValues } from '../types';
import { useFormFillerConditionHandler } from '../useFormFillerConditionHandler';
import { useFormFillerPresence } from '../useFormFillerPresence';
import { BooleanFiller } from './BooleanFiller';
import { DateFiller } from './DateFiller';
import { FileFiller } from './File/FileFiller';
import { MatrixFiller } from './Matrix/MatrixFiller';
import { NumericalFiller } from './NumericalFiller';
import { SelectionFiller } from './SelectionFiller';
import { TextFiller } from './TextFiller';

export interface FieldFillerProps {
  index: number;
  disabled?: boolean;
  field: FormDefinitionFragmentFragment['sections'][number]['fields'][number];
}

const fieldTypeToComponentMap: Record<FieldType, React.FC<FieldFillerProps>> = {
  [FieldType.Text]: TextFiller,
  [FieldType.Numerical]: NumericalFiller,
  [FieldType.Boolean]: BooleanFiller,
  [FieldType.Date]: DateFiller,
  [FieldType.Matrix]: MatrixFiller,
  [FieldType.Selection]: SelectionFiller,
  [FieldType.File]: FileFiller
};

export const FieldFiller: React.FC<FieldFillerProps> = (props) => {
  const { formDefinition } = useContextSafe(FormFillerContext);

  const { clearErrors } = useFormContext<FormFillerValues>();

  const FieldComponent = useMemo(() => fieldTypeToComponentMap[props.field.fieldType], [props.field.fieldType]);

  const { submissionId } = useContextSafe(FormFillerContext);
  const { presences } = useFormFillerPresence(submissionId);

  const userPresence = useMemo(() => presences.find(p => p.fieldId === props.field.id && p.matrixRowId == null), [presences, props.field.id]);

  const { isVisible } = useFormFillerConditionHandler({ fieldId: props.field.id, conditions: formDefinition.conditions });

  if (!isVisible) {
    clearErrors(`values.${props.index}`);
    return null;
  }

  return (
    <PresenceDecorator key={props.field.id} userDisplayName={userPresence?.displayName}>
      <FieldComponent key={props.field.id}  {...props} />
    </PresenceDecorator>
  );
};