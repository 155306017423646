import { PageContainer } from '@components/Layout/PageContainer';
import { ResponsiveButton } from '@components/ResponsiveButton';
import { useCurrentProject } from '@modules/projects/utils/useCurrentProject';
import { Add } from '@mui/icons-material';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { AddProjectMemberDrawer } from '../../projects/components/Members/AddProjectMemberDrawer';
import { ProjectMembersTable } from '../components/ProjectMembersTable';

export const PermissionsUsersView = () => {
  const { projectId } = useCurrentProject();
  const { formatMessage } = useIntl();

  const [isAddAdminDrawerOpen, setIsAddProjectMemberDrawerOpen] = useState(false);

  return (
    <PageContainer
      title={formatMessage({ id: 'Members' })}
      action={(
        <ResponsiveButton icon={<Add />} variant='contained' onClick={() => setIsAddProjectMemberDrawerOpen(true)}>
          {formatMessage({ id: 'Add member' })}
        </ResponsiveButton>
      )}
    >
      <ProjectMembersTable projectId={projectId} />

      <AddProjectMemberDrawer open={isAddAdminDrawerOpen} onClose={() => setIsAddProjectMemberDrawerOpen(false)} />

    </PageContainer >
  );
};