import { useIntl } from 'react-intl';
import { ActionConfigurationComponentProps } from '../../WorkflowEditor/Actions/types';
import { WorkflowFieldInput } from '../../WorkflowEditor/Fields/WorkflowFieldInput';
import { FillFormActionDefinition } from './FillFormActionDefinition';


export const FillFormActionConfiguration: React.FC<ActionConfigurationComponentProps> = () => {
  const { formatMessage } = useIntl();

  return (
    <>
      <WorkflowFieldInput fieldId={FillFormActionDefinition.FieldId_FormTemplate} label={formatMessage({ id: 'Form template' })} />
      <WorkflowFieldInput fieldId={FillFormActionDefinition.FieldId_Responder} label={formatMessage({ id: 'Responder' })} />
    </>
  );
};