import React from 'react';
import { CompleteDocumentSignatureActionConfiguration } from '../../Actions/CompleteDocumentSignature.tsx/CompleteDocumentSignatureActionConfiguration';
import { CompleteDocumentSignatureActionDefinition } from '../../Actions/CompleteDocumentSignature.tsx/CompleteDocumentSignatureActionDefinition';
import { ConfigureDocumentSignatureActionConfiguration } from '../../Actions/ConfigureDocumentSignature/ConfigureDocumentSignatureActionConfiguration';
import { ConfigureDocumentSignatureActionDefinition } from '../../Actions/ConfigureDocumentSignature/ConfigureDocumentSignatureActionDefinition';
import { FillFormActionConfiguration } from '../../Actions/FillForm/FillFormActionConfiguration';
import { FillFormActionDefinition } from '../../Actions/FillForm/FillFormActionDefinition';
import { RequestUserToBookAMeetingConfiguration } from '../../Actions/RequestUserToBookAMeeting/RequestUserToBookAMeetingConfiguration';
import { RequestUserToBookAMeetingDefinition } from '../../Actions/RequestUserToBookAMeeting/RequestUserToBookAMeetingDefinition';
import { TaskActionConfiguration } from '../../Actions/Task/TaskActionConfiguration';
import { TaskActionDefinition } from '../../Actions/Task/TaskActionDefinition';
import { WorkflowEditorActionValue } from '../types';

export type ActionConfigurationComponentProps = {
  actionConfiguration: WorkflowEditorActionValue;
};

export const allActions = [
  ConfigureDocumentSignatureActionDefinition,
  CompleteDocumentSignatureActionDefinition,
  FillFormActionDefinition,
  TaskActionDefinition,
  RequestUserToBookAMeetingDefinition
] as const;
export type AllActionIds = typeof allActions[number]['ActionDefinitionId'];

export const ActionConfigurationComponentById: Record<AllActionIds, React.FC<ActionConfigurationComponentProps>> = {
  'Internal.FillForm': FillFormActionConfiguration,
  'Internal.Task': TaskActionConfiguration,
  'Internal.ConfigureDocumentSignature': ConfigureDocumentSignatureActionConfiguration,
  'Internal.CompleteDocumentSignature': CompleteDocumentSignatureActionConfiguration,
  'Internal.RequestUserToBookAMeeting': RequestUserToBookAMeetingConfiguration
};