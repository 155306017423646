import { UnstyledLink } from '@components/UnstyledLink';
import InfoIcon from '@mui/icons-material/Info';
import { Alert, Button, Chip, Stack, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import { UserAvatar } from '../../../components/UserAvatar/UserAvatar';
import { getTenantIdentifier } from '../../../utils/getTenantIdentifier';
import { useResponsive } from '../../../utils/useResponsive';

type SignUpBannerProps = {
  userFullName: string;
  tenantDisplayName: string;
  projectId: number;
};

export const SignUpBanner = (props: SignUpBannerProps) => {
  const { formatMessage } = useIntl();
  const { isMobile } = useResponsive();
  return (
    <Alert color='info' icon={<InfoIcon />} sx={{
      '& .MuiAlert-message': { width: '100%' },
      '& .MuiAlert-icon': { alignItems: 'center' },
      width: '100%'
    }}>
      <Stack direction='row' gap={1} flexWrap='wrap' justifyContent='space-between' alignItems='center' width='100%'>
        <Stack justifyContent='center' gap={isMobile ? 2 : 0}>
          <Typography variant='h5'>
            {formatMessage({ id: 'Viewing document shared with ' })}
            <Chip variant='outlined' avatar={<UserAvatar size='tiny' displayName={props.userFullName} />} color='info' label={props.userFullName} />
          </Typography>

          <Typography>
            {formatMessage({ id: 'You are invited to join the project to collaborate with {tenant}!' }, { tenant: props.tenantDisplayName })}
          </Typography>
        </Stack>

        <UnstyledLink to={`/${getTenantIdentifier()}/?returnUrl=/${getTenantIdentifier()}/projects/${props.projectId}/home`}>
          <Button color='inherit' variant='outlined' sx={{ height: 'min-content', borderWidth: '2px', fontWeight: 600 }}>
            {formatMessage({ id: 'Join project' })}
          </Button>
        </UnstyledLink>
      </Stack>
    </Alert >
  );
};