import { DataTable } from '@components/DataTable';
import { UserAvatar } from '@components/UserAvatar/UserAvatar';
import { useGetPendingFillFormTasks } from '@modules/forms/utils/useGetPendingTasks';
import { Error } from '@mui/icons-material';
import { Chip, Stack, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';
import dayjs, { Dayjs } from 'dayjs';
import { ProjectTaskStatus } from 'gql/index';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { RequestActions } from './RequestActions';

export type RequestRow = {
  id: number,
  formId: number,
  collectFrom: string,
  requestedBy: string,
  dueDate: Dayjs,
  isPending: boolean
}

const commonColumnVariables: Partial<GridColDef> = {
  disableColumnMenu: true,
  disableReorder: true,
  sortable: true,
};

const dateTimeValueGetter: GridColDef<RequestRow>['valueGetter'] = ({ row }) => {
  if (!row.dueDate) return undefined;
  const dayjsDate = dayjs(row.dueDate);
  return dayjsDate.isValid() ? dayjsDate.toDate() : undefined;
};

export const RequestTable: React.FC = () => {
  const { formatMessage, formatDate } = useIntl();
  const { formId: formIdString } = useParams();
  const formId = Number(formIdString);
  const { tasks, isFetching } = useGetPendingFillFormTasks();

  const rows = tasks?.map<RequestRow>(t => ({
    id: t.id,
    formId: formId,
    collectFrom: t.assignees.at(0)?.fullName ?? '',
    requestedBy: t.createdBy?.fullName ?? '',
    dueDate: dayjs(t.dueDate),
    isPending: t.status != ProjectTaskStatus.Done
  }));

  const columns: GridColDef<RequestRow>[] = [
    {
      ...commonColumnVariables,
      field: 'collectFrom',
      flex: 3,
      minWidth: 300,
      headerName: formatMessage({ id: 'Collect from' }),
      valueGetter: ({ row }) => row.collectFrom ?? '',
      renderCell: ({ value }) => <Chip icon={<UserAvatar displayName={value} size='small' />} label={value} />
    },
    {
      ...commonColumnVariables,
      field: 'requestedBy',
      flex: 3,
      minWidth: 300,
      headerName: formatMessage({ id: 'Requested by' }),
      valueGetter: ({ row }) => row.requestedBy ?? '',
      renderCell: ({ value }) => <Chip icon={<UserAvatar displayName={value} size='small' />} label={value} />
    },
    {
      ...commonColumnVariables,
      field: 'dueDate',
      type: 'dateTime',
      flex: 1,
      headerName: formatMessage({ id: 'Due date' }),
      valueGetter: dateTimeValueGetter,
      renderCell:
        ({ value, row }) => (
          value && dayjs() > value && row.isPending ?
            <Stack direction='row' gap={1}>
              <Typography color='error'>
                {formatDate(value, { dateStyle: 'long' })}
              </Typography>
              <Error color='error' />
            </Stack> :
            <Typography>
              {formatDate(value, { dateStyle: 'long' })}
            </Typography>
        )
    },
    {
      ...commonColumnVariables,
      field: 'actions',
      headerName: '',
      maxWidth: 64,
      renderCell: ({ row }) => (
        <RequestActions request={row} />
      )
    }
  ];

  return (
    <DataTable<RequestRow>
      columns={columns}
      rows={rows ?? []}
      loading={isFetching}
      noDataMessage={formatMessage({ id: 'No pending request yet' })}
    />
  );
};