import { EditorTopBar } from '@components/Editor/TopBar/EditorTopBar';
import { TemplateTitle } from '@components/Editor/TopBar/TemplateTitle';
import { PageContainer } from '@components/Layout/PageContainer';
import { Info } from '@mui/icons-material';
import { Alert } from '@mui/material';
import { FieldType, FormDefinitionFragmentFragment, FormTemplateFragmentFragment, FormValueFragmentFragment } from 'gql/index';
import React from 'react';
import { useIntl } from 'react-intl';
import { FormTemplateActions } from '../../../components/FormTemplateActions';
import { FormViewer } from '../../../components/FormViewer';

interface Props {
  template: FormTemplateFragmentFragment;
  definition: FormDefinitionFragmentFragment;
}

export const FormTemplateViewerView: React.FC<Props> = ({ template, definition }) => {
  const { formatMessage } = useIntl();

  const matrixDefaultValues = definition.sections
    .flatMap(p => p.fields)
    .filter(p => p.fieldType === FieldType.Matrix)
    .map<FormValueFragmentFragment>(p => ({
      id: p.id,
      documents: [],
      selectedOptions: [],
      fieldId: p.id,
      matrixRows: p.matrix?.defaultRows ?? []
    }));

  return <>
    <EditorTopBar
      title={<TemplateTitle title={definition.name} />}
      topBarActions={(
        <FormTemplateActions template={template} />
      )}
    />

    <PageContainer>
      <Alert color='info' icon={<Info />} sx={{ mb: 2 }}>
        {formatMessage({ id: 'You cannot edit form templates created by another user. You may duplicate it to have a copy to edit.' })}
      </Alert>

      <FormViewer
        editable
        values={matrixDefaultValues}
        definition={definition}
      />
    </PageContainer>
  </>;
};