import { CancelButton } from '@components/Buttons/CancelButton';
import { useCurrentProject } from '@modules/projects/utils/useCurrentProject';
import { LoadingButton } from '@mui/lab';
import { Dialog, DialogContent, DialogProps, DialogTitle, Stack, TextField } from '@mui/material';
import { useNotification } from '@utils/useNotification';
import { useQueryInvalidator } from '@utils/useQueryInvalidator';
import { useValidationRules } from '@utils/useValidationRules';
import { Folder, useChildFoldersQuery, useProjectFoldersQuery, useRenameFolderMutation } from 'gql/index';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';


interface RenameFolderDialogProps extends DialogProps {
  folder: Pick<Folder, 'id' | 'parentFolderId' | 'name'>;
}

interface FormValues {
  name: string;
}

export const RenameFolderDialog: React.FC<RenameFolderDialogProps> = ({ folder, ...dialogProps }) => {
  const { formatMessage } = useIntl();
  const { notEmpty } = useValidationRules();
  const { notifySuccess } = useNotification();
  const invalidateQuery = useQueryInvalidator();

  const { projectId } = useCurrentProject();

  const { control, handleSubmit } = useForm<FormValues>({
    defaultValues: { name: folder.name }
  });

  const { mutate: renameFolder, isLoading } = useRenameFolderMutation({
    onSuccess: () => {
      dialogProps.onClose?.({}, 'backdropClick');
      notifySuccess(formatMessage({ id: 'Folder renamed successfully' }));
      invalidateQuery(useProjectFoldersQuery, { projectId });
      invalidateQuery(useChildFoldersQuery, { parentFolderId: folder.parentFolderId });
    }
  });

  const onSubmit = (values: FormValues) => {
    renameFolder({
      input: {
        projectId,
        folderId: folder.id,
        newName: values.name
      }
    });
  };

  return (
    <Dialog {...dialogProps} fullWidth onClose={isLoading ? undefined : dialogProps.onClose}>
      <DialogTitle>{formatMessage({ id: 'Rename folder' })}</DialogTitle>

      <DialogContent sx={{ overflow: 'visible' }}>
        <Controller
          name='name'
          control={control}
          rules={{ validate: notEmpty }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              autoFocus
              fullWidth
              label={formatMessage({ id: 'Name' })}
              required
              error={!!error}
              helperText={error?.message}
            />
          )}
        />
      </DialogContent>

      <Stack direction='row' gap={1} p={1} width='100%' justifyContent='end'>
        <CancelButton onClick={() => dialogProps.onClose?.({}, 'backdropClick')} disabled={isLoading} />

        <LoadingButton variant='contained' onClick={handleSubmit(onSubmit)} loading={isLoading}>
          {formatMessage({ id: 'Save' })}
        </LoadingButton>
      </Stack>
    </Dialog>
  );
};