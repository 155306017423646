import React from 'react';
import { Typography } from '@mui/material';

interface OwnProps {
    title: string;
}

export type SectionTitleProps =
    & OwnProps;

export const SectionTitle: React.FC<SectionTitleProps> = (props) => {
  return (
    <Typography variant='body2' fontWeight='700' textTransform='uppercase'>
      {props.title}
    </Typography>
  );
};