import { ConfirmDialog, ConfirmDialogProps } from '@components/ConfirmDialog';
import { useCurrentProject } from '@modules/projects/utils/useCurrentProject';
import { DialogContentText, TextField } from '@mui/material';
import { getTenantIdentifier } from '@utils/getTenantIdentifier';
import { useQueryInvalidator } from '@utils/useQueryInvalidator';
import { MeetingFragmentFragment, useDeclineMeetingRequestMutation, useMeetingsQuery } from 'gql/index';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

interface Props extends Omit<ConfirmDialogProps, 'title' | 'onConfirm'> {
  meeting: MeetingFragmentFragment;
}

export const MeetingDeclineDialog: React.FC<Props> = ({ meeting, ...dialogProps }) => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const { projectId } = useCurrentProject();
  const invalidateQuery = useQueryInvalidator();

  const { mutate: declineMeeting, isLoading } = useDeclineMeetingRequestMutation({
    onSuccess: () => {
      invalidateQuery(useMeetingsQuery, { projectId });
      if (window.location.pathname.includes('meetings')) {
        navigate(`/${getTenantIdentifier()}/projects/${projectId}/meetings`);
      }
    }
  });

  const [declineReason, setDeclineReason] = useState('');

  const onDecline = () => {
    declineMeeting({
      input: {
        meetingId: meeting.id,
        projectId,
        declineReason
      }
    });
  };

  return (
    <ConfirmDialog
      {...dialogProps}
      title={formatMessage({ id: 'Decline meeting?' })}
      content={<>
        <DialogContentText>
          {formatMessage({ id: 'Are you sure you want to decline this meeting? The user who requested it will be notified, with a reason if you provide it.' })}
        </DialogContentText>

        <TextField
          label={formatMessage({ id: 'Reason for declining' })}
          value={declineReason}
          onChange={(e) => setDeclineReason(e.target.value)}
          fullWidth
          multiline
          rows={2}
          sx={{ mt: 2 }}
        />
      </>}
      onConfirm={onDecline}
      confirmColor='error'
      confirmText={formatMessage({ id: 'Decline' })}
      loading={isLoading}
    />
  );
};