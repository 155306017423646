import { DocumentTaskListItem } from '@modules/tasks/components/DocumentTaskListItem';
import { FormTaskListItem } from '@modules/tasks/components/FormTaskListItem';
import { ProjectTaskFragmentFragment } from 'gql/index';
import React from 'react';
import { CustomTaskListItem } from './CustomTaskListItem';

interface Props {
  task: ProjectTaskFragmentFragment;
}

export const TaskListItem: React.FC<Props> = ({ task }) => {
  if (task.projectForm) {
    return <FormTaskListItem task={task} form={task.projectForm} />;
  } else if (task.document) {
    return <DocumentTaskListItem task={task} document={task.document} />;
  } else {
    return <CustomTaskListItem task={task} />;
  }
};