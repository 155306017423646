import { Stack, SxProps, Typography, TypographyProps } from '@mui/material';
import { ReactNode } from 'react';

type DrawerSectionProps = {
  title: string;
  titleProps?: TypographyProps;
  children: ReactNode;
  actionButton?: ReactNode;
  sx?: SxProps;
};

export const DrawerSection = (props: DrawerSectionProps) => {
  const { title, children, actionButton, titleProps } = props;

  return (
    <Stack gap={2} p={2}>
      <Stack direction='row' alignItems='center' justifyContent='space-between'>
        <Typography variant='h6' {...titleProps}>{title}</Typography>

        {actionButton}
      </Stack>

      <Stack ml={2} sx={props.sx}>
        {children}
      </Stack>
    </Stack>
  );
};