import { FormDefinitionFragmentFragment } from 'gql/index';
import { useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { FormFieldValues, FormFillerValues } from './types';

const stringToBoolean = (value: '' | 'true' | 'false'): boolean | null => {
  if (value == 'true') return true;
  if (value == 'false') return false;
  return null;
};

const determineIsTriggered = (condition: FormDefinitionFragmentFragment['conditions'][number], triggerField: FormFieldValues | undefined): boolean => {
  if (!condition) return false;

  if (!triggerField) return false;

  if (condition.trigger.valueEquality) {
    return stringToBoolean(triggerField.boolean) === condition.trigger.valueEquality?.boolean;
  }

  return false;
};

interface Options {
  fieldId?: number;
  sectionId?: number;
  conditions: FormDefinitionFragmentFragment['conditions'];
}

export const useFormFillerConditionHandler = ({ fieldId, sectionId, conditions: allConditions }: Options) => {
  const { control } = useFormContext<FormFillerValues>();

  const conditions = useMemo(() => allConditions.filter(p => p.effect.targetFieldId == fieldId && p.effect.targetSectionId == sectionId), [allConditions, fieldId, sectionId]);

  const fields = useWatch({ control, name: 'values' });

  const isVisible: boolean = useMemo(() => {
    for (const condition of conditions) {
      const triggerFieldValue = fields.find(p => p.fieldId === condition.trigger.sourceFieldId);

      const isTriggered = determineIsTriggered(condition, triggerFieldValue);

      if (isTriggered) {
        return condition?.effect.visibility?.visible ?? false;
      }
    }

    // If an effect makes a field visible, but it's not triggered, we must hide the field
    if (conditions.some(c => c.effect.visibility?.visible === true)) {
      return false;
    }

    return true;
  }, [conditions, fields]);

  return { isVisible };
};