import { CheckCircle, LoopOutlined, RadioButtonChecked, RadioButtonUnchecked } from '@mui/icons-material';
import { GetTaskNameFragmentFragment, ProjectTaskStatus, ProjectTaskType } from 'gql/index';
import { ReactNode, useCallback, useMemo } from 'react';
import { MessageDescriptor, defineMessages, useIntl } from 'react-intl';


type MessageGetter = (value: GetTaskNameFragmentFragment) => string;

export const useTaskMessages = () => {
  const { formatMessage } = useIntl();

  const taskNameGetterByType: Record<ProjectTaskType, MessageGetter> = useMemo(() => ({
    FillForm: value => formatMessage({ id: 'Fill the form {formName}' }, { formName: value.projectForm?.formDefinition?.name }),
    SignDocument: value => formatMessage({ id: 'Sign the document {documentName}' }, { documentName: value.document?.fileName }),
    Custom: value => value.name ?? ''
  }), [formatMessage]);


  const getTaskName = useCallback((task?: GetTaskNameFragmentFragment | null) => {
    if (!task) return '';
    return taskNameGetterByType[task.type](task);
  }, [taskNameGetterByType]);

  return { taskNameGetterByType, getTaskName };
};

const taskStatusOrder: Record<ProjectTaskStatus, number> = ({
  Draft: 0,
  Ready: 1,
  InProgress: 2,
  Done: 3
});

export const getTaskStatusOrderingKey = (status: ProjectTaskStatus) => taskStatusOrder[status];

export const taskStatusMessage: Record<ProjectTaskStatus, MessageDescriptor> = defineMessages({
  Draft: { id: 'Draft' },
  Ready: { id: 'Planned' },
  Done: { id: 'Done' },
  InProgress: { id: 'In progress' },
});

export const taskStatusIndicator: Record<ProjectTaskStatus, ReactNode> = {
  Draft: <RadioButtonUnchecked fontSize='inherit' />,
  Ready: <RadioButtonChecked fontSize='inherit' color='info' />,
  Done: <CheckCircle fontSize='inherit' color='success' />,
  InProgress: <LoopOutlined fontSize='inherit' color='info' />
};