import { Close } from '@mui/icons-material';
import { Container, ContainerProps, DialogProps } from '@mui/material';
import { merge } from 'lodash';
import React, { ReactNode } from 'react';
import { DialogTopBar, DialogTopBarProps } from './DialogTopBar';
import { SlideDialogBase } from './SlideDialogBase';

export interface SlideDialogProps extends Omit<DialogProps, 'title'> {
  children?: ReactNode;
  topbar: Omit<DialogTopBarProps, 'leftIcon' | 'leftIconAction'>;
  containerProps?: ContainerProps;
  disablePadding?: boolean;
}

export const SlideDialog: React.FC<SlideDialogProps> = ({ children, topbar, disablePadding, containerProps, ...dialogProps }) => {
  return (
    <SlideDialogBase direction="up" {...dialogProps}>
      <DialogTopBar leftIcon={<Close />} leftIconAction={() => dialogProps.onClose?.({}, 'backdropClick')} {...topbar} />

      <Container
        maxWidth='md'
        {...containerProps}
        sx={merge({}, {
          ...!disablePadding ? {
            mt: { xs: 2, md: 3 },
            mb: 3,
            px: { xs: 1, md: 3 }
          } : { p: 0 },
        }, containerProps?.sx)}
      >
        {children}
      </Container>
    </SlideDialogBase>
  );
};