import { LoadingButton } from '@mui/lab';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { autoSaveTimeout } from './FormFields/useFormFieldFillerForm';
import { FormFillerValues } from './types';

interface FormSubmitButtonProps {
  loading: boolean;
  onSubmit: (values: FormFillerValues) => void;
}

export const FormFillerSubmitButton: React.FC<FormSubmitButtonProps> = ({ loading, onSubmit }) => {
  const { formatMessage } = useIntl();

  const [isSubmitFormQueued, setIsSubmitFormQueued] = useState(false);
  const [readyToSubmitValues, setReadyToSubmitValues] = useState<FormFillerValues | undefined>(undefined);

  const isSubmitting = loading || isSubmitFormQueued;
  const { handleSubmit } = useFormContext<FormFillerValues>();

  useEffect(() => {
    if (readyToSubmitValues && !isSubmitting) {
      onSubmit?.(readyToSubmitValues);
      setReadyToSubmitValues(undefined);
      setIsSubmitFormQueued(false);
    }
    else if (readyToSubmitValues) {
      console.warn('Waiting on field auto save to finish to submit form.');
      setIsSubmitFormQueued(false);
    }
  }, [onSubmit, isSubmitting, readyToSubmitValues]);

  const onSubmitClicked = (values: FormFillerValues) => {
    if (isSubmitFormQueued) return;

    setIsSubmitFormQueued(true);
    setTimeout(() => {
      setReadyToSubmitValues(values);
    }, autoSaveTimeout + 500);
  };

  return (
    <LoadingButton loading={isSubmitting} disabled={isSubmitting} variant='contained' onClick={handleSubmit(onSubmitClicked, console.log)}>
      {formatMessage({ id: 'Submit' })}
    </LoadingButton>
  );
};