import { DraggableItemType } from '@components/Editor/DraggableItemType';
import { EditorSelectionPanel } from '@components/Editor/EditorSelectionPanel';
import { Droppable } from '@hello-pangea/dnd';
import { Flaky, GridOn, Notes, RadioButtonChecked, ViewAgenda } from '@mui/icons-material';
import FileIcon from '@mui/icons-material/FileOpenOutlined';
import TagIcon from '@mui/icons-material/Tag';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import { Box, Stack, Toolbar } from '@mui/material';
import { CalendarIcon } from '@mui/x-date-pickers';
import { useIntl } from 'react-intl';
import { formSideMenuWidth } from '../FormSideMenu/FormSideMenu';
import { FormEditorDroppableIds, FormEditorDroppableTypes } from '../types';
import { AccordionSelectionPanel } from './AccordionSelectionPanel';

type Props = {
  open: boolean;
};
export const FieldSelectionPanel: React.FC<Props> = ({ open }) => {
  const { formatMessage } = useIntl();

  return (
    <EditorSelectionPanel drawerWidth={300 + formSideMenuWidth} open={open}>
      <Stack direction='row'>
        <Box width={formSideMenuWidth} />
        <Stack width='100%'>
          {location.pathname.split('/').indexOf('form') > -1 && <Toolbar />}

          <Box style={{ height: '100%', width: '100%' }} mt={1}>
            <AccordionSelectionPanel
              defaultExpanded
              header={formatMessage({ id: 'Layout' })}
              headerProps={{ sx: { paddingLeft: 4 } }}
            >
              <Droppable droppableId={FormEditorDroppableIds.layoutSelectionPanel} type={FormEditorDroppableTypes.section} isDropDisabled={true}>
                {provided => (
                  <Box ref={provided.innerRef} {...provided.droppableProps}>
                    <DraggableItemType key='section' id='section' index={0} icon={<ViewAgenda />} text={formatMessage({ id: 'Section' })} />
                  </Box>
                )}
              </Droppable>
            </AccordionSelectionPanel>
            <AccordionSelectionPanel
              defaultExpanded
              header={formatMessage({ id: 'Fields' })}
              headerProps={{ sx: { paddingLeft: 4 } }}
            >
              <Droppable droppableId={FormEditorDroppableIds.fieldsSelectionPanel} type={FormEditorDroppableTypes.field} isDropDisabled={true}>
                {provided => (
                  <Box ref={provided.innerRef} {...provided.droppableProps}>
                    <DraggableItemType key='string' id='text' index={0} icon={<TextFieldsIcon />} text={formatMessage({ id: 'Text (single line)' })} />

                    <DraggableItemType key='multiline' id='multiline' index={1} icon={<Notes />} text={formatMessage({ id: 'Text (multiline)' })} />

                    <DraggableItemType key='numberic' id='numeric' index={2} icon={<TagIcon />} text={formatMessage({ id: 'Numeric' })} />

                    <DraggableItemType key='yesNo' id='boolean' index={3} icon={<Flaky />} text={formatMessage({ id: 'Yes/No' })} />

                    <DraggableItemType key='date' id='date' index={3} icon={<CalendarIcon />} text={formatMessage({ id: 'Date' })} />

                    <DraggableItemType key='selection' id='selection' index={4} icon={<RadioButtonChecked />} text={formatMessage({ id: 'Selection' })} />

                    <DraggableItemType key='file' id='file' index={4} icon={<FileIcon />} text={formatMessage({ id: 'File(s)' })} />

                    <DraggableItemType key='matrix' id='matrix' index={5} icon={<GridOn />} text={formatMessage({ id: 'Matrix' })} />
                  </Box>
                )}
              </Droppable>
            </AccordionSelectionPanel>
          </Box>
        </Stack>
      </Stack>
    </EditorSelectionPanel>
  );
};