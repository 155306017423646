import { TreeViewBaseItem } from '@mui/x-tree-view/models';

export const buildTreeItemHierarchy = <TLeaf, TParent>(
  items: TLeaf[],
  parents: TParent[],
  idGetter: (item: TLeaf) => string,
  labelGetter: (item: TLeaf) => string,
  parentIdGetter: (item: TParent) => string,
  parentLabelGetter: (item: TParent) => string,
  pathGetter: (item: TLeaf) => string) => {
  const root: TreeViewBaseItem[] = [];

  const findOrCreateNode = (hierarchyPathParts: string[], item: TLeaf, parentNodeItems: TreeViewBaseItem[]) => {
    const parentId = hierarchyPathParts.shift();
    if (!parentId) return;


    const internalParentId = `parent-${parentId}`;
    let parentNode = parentNodeItems.find(item => item.id === internalParentId);
    const parent = parents.find(p => parentIdGetter(p) == parentId);

    if (!parentNode) {
      parentNode = { id: internalParentId, label: parent ? parentLabelGetter(parent) : internalParentId, children: [] };
      parentNodeItems.push(parentNode);
    }

    if (hierarchyPathParts.length === 0) {
      const childNode = { id: idGetter(item), label: labelGetter(item), children: [] };
      parentNode.children?.push(childNode);
    } else {
      findOrCreateNode(hierarchyPathParts, item, parentNode.children ?? []);
    }
  };

  items.forEach((item) => {
    const hierarchyIds = pathGetter(item).split('/').filter(Boolean);
    findOrCreateNode(hierarchyIds, item, root);
  });

  return root;
};