import { SignaturePadPaper } from '@components/SignaturePadPaper/SignaturePadPaper';
import { CheckCircleOutline } from '@mui/icons-material';
import { Icon, Paper, Stack, Typography } from '@mui/material';
import { useSignDocumentAnonymouslyMutation } from 'gql/index';
import React from 'react';
import { useIntl } from 'react-intl';

interface Props {
  token: string;
  onClose: () => void;
}

export const AnonymousSignaturePaper: React.FC<Props> = ({ token, onClose }) => {
  const { formatMessage } = useIntl();
  const signDocumentMutation = useSignDocumentAnonymouslyMutation({
  });

  const onDrawnSignature = (signature: string) => {
    const signatureBase64String = signature.split(',').at(1);

    if (!signatureBase64String) return;

    signDocumentMutation.mutate({
      input: { signatureBase64String, token }
    });
  };

  const onTextualSignature = (signature: string) => {
    signDocumentMutation.mutate({
      input: {
        token,
        textSignature: signature
      }
    });
  };

  const signatureComplete = signDocumentMutation.isSuccess && signDocumentMutation.data.signDocumentAnonymously.document?.id;

  return signatureComplete ? (
    <Paper>
      <Stack justifyContent='center' alignItems='center' p={2} py={4} spacing={2}>
        <Icon color='success' style={{ height: 120, width: 120 }} >
          <CheckCircleOutline style={{ height: 120, width: 120 }} />
        </Icon>

        <Typography variant='h5'>
          {formatMessage({ id: 'The document has been signed successfully' })}
        </Typography>

        <Typography variant='body2'>
          {formatMessage({ id: 'Thank you.' })}
        </Typography>
      </Stack>
    </Paper>
  ) : (
    <Paper>
      <SignaturePadPaper
        onDrawnSignatureComplete={onDrawnSignature}
        onTextualSignatureComplete={onTextualSignature}
        onClose={onClose}
        loading={signDocumentMutation.isLoading}
      />
    </Paper>
  );
};