import { useCurrentProject } from '@modules/projects/utils/useCurrentProject';
import { DialogProps } from '@mui/material';
import { notNullOrUndefined } from '@utils/notNullOrUndefined';
import { DocumentThumbnailStatus, useFolderImageDocumentsQuery } from 'gql/index';
import { ImageWithThumbnail, ImagesCarousel } from '../../../components/Navigation/ImagesCarousel';
import { getDocumentThumbnailUrl, getDocumentUrl } from '../utils/documentUrls';

interface Props extends DialogProps {
  folderId: number;
  defaultDocumentId?: number;
}

export const FolderImagesCarousel: React.FC<Props> = ({ folderId, defaultDocumentId, ...slideDialogProps }) => {
  const { projectId } = useCurrentProject();

  const { data: imageDocumentsUnsorted, isFetching } = useFolderImageDocumentsQuery({ folderId, projectId }, {
    select: d => d.folderEntries.map(e => e.document).filter(notNullOrUndefined),
    enabled: slideDialogProps.open,
    // If the folder has images without thumbnails, enable refetching
    refetchInterval: data => {
      const existsImageWithoutThumbnail = data?.some(
        d => d.thumbnailStatus === DocumentThumbnailStatus.NotStarted
          || d.thumbnailStatus === DocumentThumbnailStatus.Generating
      );

      if (existsImageWithoutThumbnail) {
        return 5000; // 5 seconds
      }

      return false;
    }
  });

  const imageDocuments = imageDocumentsUnsorted?.map<ImageWithThumbnail>(p => ({
    id: p.id,
    fileName: p.fileName,
    imageUrl: getDocumentUrl(p.id),
    thumbnailUrl: p.thumbnailStatus === DocumentThumbnailStatus.Ready ? getDocumentThumbnailUrl(p.id) : undefined,
    thumbnailStatus: p.thumbnailStatus
  }));

  return (
    <ImagesCarousel images={imageDocuments ?? []} isLoading={isFetching} defaultImageId={defaultDocumentId} {...slideDialogProps} />
  );
};