import { PageContainer } from '@components/Layout/PageContainer';
import { ResponsiveButton } from '@components/ResponsiveButton';
import { useCurrentProject } from '@modules/projects/utils/useCurrentProject';
import { Add } from '@mui/icons-material';
import { useMeetingTypesQuery } from 'gql/index';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { MeetingTypeDrawer } from '../components/MeetingTypeDrawer';
import { MeetingTypesTable } from '../components/MeetingTypesTable';

export const MeetingTypesPage = () => {
  const { formatMessage } = useIntl();
  const { projectId, canManageProject } = useCurrentProject();

  const { data: meetingTypes } = useMeetingTypesQuery({ projectId }, { select: d => d.meetingTypes });

  const [isAddingMeetingType, setIsAddingMeetingType] = useState(false);

  return (
    <PageContainer
      title={formatMessage({ id: 'Meeting types' })}
      action={canManageProject && (
        <ResponsiveButton icon={<Add />} variant='contained' color='primary' onClick={() => setIsAddingMeetingType(true)}>
          {formatMessage({ id: 'Add meeting type' })}
        </ResponsiveButton>
      )}
    >
      <MeetingTypesTable
        meetingTypes={meetingTypes ?? []}
      />

      <MeetingTypeDrawer
        open={isAddingMeetingType}
        onClose={() => setIsAddingMeetingType(false)}
      />
    </PageContainer>
  );
};