import { DisableableTooltip } from '@components/Tooltips/DisableableTooltip';
import { isProjectNavigationMenuCollapsedState } from '@modules/projects/atoms';
import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { ProjectNavigationMenuItem, ProjectNavigationMenuItemProps } from './ProjectNavigationMenuItem';

interface OwnProps {
  disableTooltip?: boolean;
  to: string;
  isActiveByParent?: string;
}

export type ProjectNavigationMenuLinkProps = OwnProps & ProjectNavigationMenuItemProps;

export const ProjectNavigationMenuLink: React.FC<ProjectNavigationMenuLinkProps> = ({ disableTooltip, to, isActiveByParent, disabled, ...navigationMenuItemProps }) => {
  const { pathname } = useLocation();
  const isNavigationMenuCollapsed = useRecoilValue(isProjectNavigationMenuCollapsedState);

  const menuItem = <ProjectNavigationMenuItem {...navigationMenuItemProps} />;

  if (disabled) {
    return (
      <div>
        {menuItem}
      </div>
    );
  } else {
    return (
      <DisableableTooltip disabled={disableTooltip} title={isNavigationMenuCollapsed ? navigationMenuItemProps.title : ''} arrow placement='right'>
        <NavLink to={to} className={`${isActiveByParent && pathname.includes(isActiveByParent) ? 'active' : ''}`} style={{ textDecoration: 'none' }} >
          {menuItem}
        </NavLink>
      </DisableableTooltip>
    );
  }
};