import { UserAvatar } from '@components/UserAvatar/UserAvatar';
import { useCurrentProject } from '@modules/projects/utils/useCurrentProject';
import { AllActionIds } from '@modules/workflow/components/WorkflowEditor/Actions/types';
import { ExpandMore } from '@mui/icons-material';
import { AccordionSummary, Chip, Stack, Typography } from '@mui/material';
import { useGetMe } from '@utils/useGetMe';
import { WorkflowActionConfigurationFragmentFragment } from 'gql/index';
import { WorkflowActionStatusChip } from '../ActionStatusChip';
import { ActionIconByType } from '../Types';

type OwnProps = {
  action: WorkflowActionConfigurationFragmentFragment;
  subTitle?: string;
};

export const ActionAccordionSummary: React.FC<OwnProps> = ({ action, subTitle }) => {
  const { me } = useGetMe();
  const { canManageWorkflows } = useCurrentProject();
  const canViewActionDetails = canManageWorkflows || action.participants.some(p => p.id == me?.id);
  return (
    <AccordionSummary expandIcon={<ExpandMore sx={{ display: canViewActionDetails ? 'inherit' : 'none' }} />} >
      <Stack flex={1} gap={2}>

        <Stack direction='row' justifyContent='space-between' alignItems='center' pr={1}>
          <Stack direction='row' gap={1}>
            {ActionIconByType[action.workflowActionDefinitionId as AllActionIds]}
            <Stack>
              <Typography variant='body1' fontWeight={600}>{action.name}</Typography>
              {subTitle && <Typography variant='body2'>{subTitle}</Typography>}
            </Stack>
          </Stack>
          {WorkflowActionStatusChip({ actionStatus: action.status })}
        </Stack>

        <Stack direction='row' gap={0} alignItems='center'>
          <>

            {action.participants.map(participant => (
              <Chip
                key={participant.id}
                size='small'
                avatar={(
                  <UserAvatar displayName={participant?.fullName} />
                )}
                label={participant?.fullName}
              />))}
          </>
        </Stack>
      </Stack>
    </AccordionSummary >
  );
};