import { UnstyledLink } from '@components/UnstyledLink';
import { isProjectNavigationMenuOpenState } from '@modules/projects/atoms';
import { useCurrentProject } from '@modules/projects/utils/useCurrentProject';
import Menu from '@mui/icons-material/Menu';
import { IconButton, LinearProgress, Stack, Toolbar, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { getTenantIdentifier } from '@utils/getTenantIdentifier';
import React from 'react';
import { useIntl } from 'react-intl';
import { useRecoilState } from 'recoil';
import { AppTopBar } from './AppTopBar';

interface Props {
  loading?: boolean;
}



export const MobileProjectTopBar: React.FC<Props> = ({ loading }) => {
  const { isArchived, name: projectName, projectId } = useCurrentProject();

  const [isNavigationMenuOpen, setIsNavigationMenuOpen] = useRecoilState(isProjectNavigationMenuOpenState);
  const { formatMessage } = useIntl();
  return (
    <AppTopBar sx={{ backgroundColor: isArchived ? 'action.disabled' : 'primary.main' }} >
      <Toolbar >
        <Stack direction='row' alignItems='center' flex={1} spacing={2} color={'white'}>
          <IconButton onClick={() => setIsNavigationMenuOpen(!isNavigationMenuOpen)}>
            <Menu sx={{ fill: grey[50] }} />
          </IconButton>
          <UnstyledLink to={`/${getTenantIdentifier()}/projects/${projectId}/home`} sx={{ '&:hover': { textDecorationLine: 'underline' } }}>
            <Typography variant='h5' sx={{ maxWidth: '80ch' }}>
              {isArchived ? formatMessage({ id: '{projectName} (archived)' }, { projectName: projectName }) : projectName}
            </Typography>
          </UnstyledLink>
        </Stack>
      </Toolbar>
      <LinearProgress style={{ visibility: loading ? 'visible' : 'hidden' }} />
    </AppTopBar>
  );
};