import Cookies from 'js-cookie';

const reservedTenantIdentifiers = ['login', 'global', 'hangfire', 'graphql', 'api', 'public', 'error', 'trial'];

export const getTenantIdentifier = () => {
  const value = window.location.pathname.split('/')[1];

  if (reservedTenantIdentifiers.includes(value)) {
    return;
  }

  if (!value) {
    return Cookies.get('tenantIdentifier');
  }

  return value;
};
