import { ResponsiveButton } from '@components/ResponsiveButton';
import { PictureAsPdf } from '@mui/icons-material';
import { Alert, Container, Stack, TextField, Typography, debounce } from '@mui/material';
import { useResponsive } from '@utils/useResponsive';
import React, { useEffect, useState } from 'react';
import { Controller, FormProvider, useForm, useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { useCommercialProjectCalculatorsQuery, useUpdateCommercialProjectCalculatorMutation } from '../../../../../gql';
import { useQueryInvalidator } from '../../../../../utils/useQueryInvalidator';
import { useCurrentProject } from '../../../../projects/utils/useCurrentProject';
import { OperatingCostsSection } from '../components/Charges/OperatingCostsSection';
import { EstimatesSection } from '../components/EstimatesSection';
import { MortgageCalculator } from '../components/MortgageCalculator';
import { RevenuesSection } from '../components/Revenues/RevenuesSection';
import { SummarySection } from '../components/SummarySection';
import { CommercialConstructionCalculatorData, MortgageCalculatorForm } from '../types';
import { useCommercialConstructionPDFGenerator } from '../useCommercialConstructionPDFGenerator';

type Props = {
  defaultValues: CommercialConstructionCalculatorData;
};

export const CommercialConstructionCalculatorForm: React.FC<Props> = ({ defaultValues }) => {
  const { projectId, canManageBudgetAndExpenses } = useCurrentProject();
  const { id } = useParams();
  const [purchasePrice, setPurchasePrice] = useState(defaultValues.calculatorValues.totalEstimate);
  const invalidateQuery = useQueryInvalidator();
  const { formatMessage } = useIntl();
  const { mutate: updateCalculator } = useUpdateCommercialProjectCalculatorMutation();
  const { isMobile } = useResponsive();
  const { generatePDF } = useCommercialConstructionPDFGenerator();

  const form = useForm<CommercialConstructionCalculatorData>({
    defaultValues: { ...defaultValues }
  });

  const onFormSubmit = (values: CommercialConstructionCalculatorData) => {

    if (canManageBudgetAndExpenses) {
      updateCalculator(
        {
          input: {
            id: Number(id),
            projectId: projectId,
            name: values.name,
            jsonData: JSON.stringify(values)
          }
        },
        {
          onSuccess: () => invalidateQuery(useCommercialProjectCalculatorsQuery, { projectId: projectId })
        });
    }
  };

  const handleMortgageForm = debounce((values: MortgageCalculatorForm) => {
    form.setValue('mortgageCalculator', values);
    form.handleSubmit(onFormSubmit)();
  }, 2000);

  const handleFormSubmit = debounce(form.handleSubmit(onFormSubmit), 1000);
  const projectCost = useWatch({ control: form.control, name: 'calculatorValues.totalEstimate' });
  useEffect(() => {
    setPurchasePrice(projectCost);
  }, [form, projectCost]);

  const onPrint = () => {
    const data = form.getValues();
    generatePDF(data);
  };

  const formActions = !isMobile && <>
    <ResponsiveButton variant='contained' icon={<PictureAsPdf />} onClick={onPrint}>
      {formatMessage({ id: 'PDF' })}
    </ResponsiveButton>
  </>;

  return (
    <Container maxWidth='xl' sx={{ pb: 4 }}>
      <FormProvider {...form}>
        <Stack gap={4} pt={4}>
          {canManageBudgetAndExpenses && (
            <Stack direction='row' alignItems='center' justifyContent='space-between'>
              <Controller
                control={form.control}
                name='name'
                render={({ field }) => (
                  <TextField
                    variant='standard'
                    {...field}
                    InputProps={{ style: { fontSize: 40 } }}
                    sx={{ width: '100ch' }}
                    onChange={e => {
                      field.onChange(e);
                      handleFormSubmit();
                    }}
                  />
                )}
              />
              {formActions}
            </Stack>
          )}

          {!canManageBudgetAndExpenses && <>
            <Stack direction='row' alignItems='center' justifyContent='space-between'>
              <Typography variant='h4'>{form.getValues('name')}</Typography>
              {formActions}
            </Stack>

            <Alert severity='warning'>{formatMessage({ id: 'Your current permissions do not allow you to update budget information. You may however still change values in the calculator. Changes will not be saved.' })}</Alert>
          </>}

          <EstimatesSection onChange={handleFormSubmit} />

          <MortgageCalculator onCalculatorUpdated={handleMortgageForm} defaultValues={defaultValues.mortgageCalculator} purchasePrice={purchasePrice} />

          <OperatingCostsSection onChange={handleFormSubmit} />

          <RevenuesSection onChange={handleFormSubmit} />

          <SummarySection />
        </Stack>
      </FormProvider>
    </Container >
  );
};