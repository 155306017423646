import { TextField } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { FormFillerValues } from '../../FormFiller/types';
import { FieldViewerProps } from './FieldViewer';

export const TextViewer: React.FC<FieldViewerProps> = ({ field, index }) => {
  const { control } = useFormContext<FormFillerValues>();

  return (
    <Controller
      control={control}
      name={`values.${index}.text`}
      render={({ field: { value } }) => (
        <TextField
          label={field.name}
          value={value ?? ''}
          rows={4}
          multiline={field.isMultiline}
          disabled
        />
      )}
    />
  );
};