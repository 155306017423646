import { CardActionArea, ClickAwayListener, ListItemSecondaryAction, Paper, styled } from '@mui/material';
import React, { ReactNode, useState } from 'react';

const PaperWithDivider = styled(Paper, {
  shouldForwardProp: prop => prop !== 'error' && prop !== 'leftIcon' && prop !== 'rightIcon'
})<{
  error?: boolean;
  leftIcon?: boolean;
  rightIcon?: boolean;
}>(({ theme, error, leftIcon, rightIcon }) => ({
  position: 'relative',
  boxShadow: 'none',
  ...error ? {
    border: `1px solid ${theme.palette.error.main}`,
  } : {
    border: `1px solid ${theme.palette.divider}`,
  },
  borderLeftWidth: '5px',
  '&:hover .showOnHover': {
    visibility: 'visible'
  },
  ...leftIcon && {
    paddingLeft: '48px'
  },
  ...rightIcon && {
    paddingRight: '48px'
  }
}));

const CardActionAreaWithIcon = styled(CardActionArea, {
  shouldForwardProp: prop => prop !== 'leftIcon' && prop !== 'rightIcon'
})<{ leftIcon?: boolean; rightIcon?: boolean; }>(({ leftIcon, rightIcon }) => ({
  ...leftIcon && {
    paddingLeft: '48px'
  },
  ...rightIcon && {
    paddingRight: '48px'
  }
}));

const ActivePaper = styled(PaperWithDivider)(({ theme }) => ({
  borderLeft: `5px solid ${theme.palette.primary.main}`
}));

interface Props {
  isActive?: boolean;
  error?: boolean;
  defaultOpen?: boolean;
  children: (isActive: boolean) => ReactNode;
  disabled?: boolean;
  onActiveChange?: (active: boolean) => void;
  onInactivate?: () => void;

  startIcon?: ReactNode;
  endIcon?: ReactNode;
}

export const InlineEditPaper: React.FC<Props> = ({
  isActive: controlledIsActive, error, disabled, defaultOpen,
  children,
  onInactivate, onActiveChange,
  startIcon, endIcon
}) => {
  const [uncontrolledIsActive, setUncontrolledIsActive] = useState(defaultOpen ?? false);

  const isActive = controlledIsActive ?? uncontrolledIsActive;

  const activate = () => {
    setUncontrolledIsActive(true);
    onActiveChange?.(true);
  };

  const onClickAway = () => {
    if (error || disabled) return;

    onInactivate?.();
    onActiveChange?.(false);
    setUncontrolledIsActive(false);
  };

  const icons = <>
    {startIcon && (
      <ListItemSecondaryAction sx={{ left: 16, right: 'inherit' }}>
        {startIcon}
      </ListItemSecondaryAction>
    )}

    {endIcon && (
      <ListItemSecondaryAction className='showOnHover' sx={{ visibility: 'hidden' }}>
        {endIcon}
      </ListItemSecondaryAction>
    )}
  </>;

  return isActive ? (
    <ClickAwayListener mouseEvent='onMouseDown' onClickAway={onClickAway}>
      <ActivePaper error={error} leftIcon={Boolean(startIcon)} rightIcon={Boolean(endIcon)}>
        {children(isActive)}

        {icons}
      </ActivePaper>
    </ClickAwayListener>
  ) : (
    <PaperWithDivider error={error}>
      <CardActionAreaWithIcon
        disabled={disabled}
        onClick={activate}
        leftIcon={Boolean(startIcon)}
        rightIcon={Boolean(endIcon)}
      >
        {children(isActive)}
      </CardActionAreaWithIcon>

      {icons}
    </PaperWithDivider>
  );
};