import { expenseStatusMessages } from '@modules/budget/messages';
import { Chip, ChipProps } from '@mui/material';
import { ExpenseStatus } from 'gql/index';
import React from 'react';
import { useIntl } from 'react-intl';

interface Props extends ChipProps {
  status: ExpenseStatus;
}

const color: Record<ExpenseStatus, ChipProps['color']> = {
  NotPaid: 'warning',
  Paid: 'success'
};

export const ExpenseStatusChip: React.FC<Props> = ({ status, ...chipProps }) => {
  const { formatMessage } = useIntl();

  return (
    <Chip color={color[status]} label={formatMessage(expenseStatusMessages[status])} {...chipProps} />
  );
};