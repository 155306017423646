import { DownloadLinkButton } from '@components/Download/DownloadLinkButton';
import { DocumentInformationDrawer } from '@modules/documents/components/DocumentInformationDrawer';
import { ProjectSectionListItem } from '@modules/projects/components/Sections/ProjectSectionList';
import { ListItemButton, ListItemProps, ListItemText } from '@mui/material';
import { getTenantIdentifier } from '@utils/getTenantIdentifier';
import dayjs from 'dayjs';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { DocumentFragmentFragment, ProjectTaskFragmentFragment } from 'src/gql/index';
import { getFileNameWithoutExtension } from '../../folders/utils/folderUtils';
import { useDueDateFormatter } from '../useDueDateFormatter';

type DocumentListItemProps = ListItemProps & {
  task: ProjectTaskFragmentFragment;
  document: DocumentFragmentFragment;
};


export const DocumentTaskListItem = (props: DocumentListItemProps) => {
  const { document, task, ...listItemProps } = props;
  const { formatMessage } = useIntl();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const formatDueDate = useDueDateFormatter();
  const fileName = getFileNameWithoutExtension(document.fileName);


  return <>
    <ProjectSectionListItem
      disablePadding
      key={document.id}
      secondaryAction={(
        <DownloadLinkButton color='action' link={`/${getTenantIdentifier()}/api/Documents/${document.id}`} fileName={document.fileName} />
      )}
      {...listItemProps}
      onClick={undefined}
    >

      <ListItemButton onClick={() => setIsDrawerOpen(true)} alignItems='center' sx={{ py: 1.5 }}>

        <ListItemText
          primary={formatMessage({ id: 'Sign the document {documentName}' }, { documentName: fileName })}
          secondary={formatDueDate(task.dueDate)}
          secondaryTypographyProps={{ color: dayjs(task.dueDate).isBefore(dayjs()) ? 'error' : 'inherit' }}
          primaryTypographyProps={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
        />
      </ListItemButton>
    </ProjectSectionListItem >
    <DocumentInformationDrawer
      open={isDrawerOpen}
      onClose={() => setIsDrawerOpen(false)}
      document={document}
    />
  </>;
};