import { Autocomplete, TextField } from '@mui/material';
import { useFormTemplatesQuery } from 'gql/index';
import React from 'react';
import { useIntl } from 'react-intl';

type Props = {
  selectedTemplateId: number | undefined;
  onChange: (selectedTemplateId?: number) => void;
  required?: boolean;
  error?: string;
  disabled?: boolean;
};

export const WorkflowEditorTemplateInput: React.FC<Props> = ({ selectedTemplateId, onChange, required, error, disabled }) => {
  const { formatMessage } = useIntl();

  const { data: templates } = useFormTemplatesQuery(undefined, { select: d => d.formTemplates, staleTime: 120000 });

  const value = templates?.find(template => template.id === selectedTemplateId) ?? null;

  return (
    <Autocomplete
      value={value}
      onChange={(_, newValue) => onChange(newValue?.id)}
      options={templates ?? []}
      getOptionLabel={template => template.formDefinition?.name ?? ''}
      renderInput={params => (
        <TextField
          label={formatMessage({ id: 'Form template' })}
          required={required}
          error={error != null}
          helperText={error}
          {...params}
          disabled={disabled}
        />
      )}
    />
  );
};