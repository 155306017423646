import { MultiStepFormDialog } from '@components/MultiStepForms/MultiStepFormDialog';
import { DialogProps } from '@mui/material';
import { getTenantIdentifier } from '@utils/getTenantIdentifier';
import { useNotification } from '@utils/useNotification';
import { useQueryInvalidator } from '@utils/useQueryInvalidator';
import { useAddProjectMutation, useCreateProjectFromTemplateMutation, useGetProjectsQuery } from 'gql/index';
import React, { useEffect } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { NewProjectStep } from './NewProjectStep';
import { SelectProjectTemplateStep } from './SelectProjectTemplateStep';
import { SelectTypeOfProjectStep } from './SelectTypeOfProjectStep';
import { NewProjectType } from './types';

export interface NewProjectFormValues {
  projectType: NewProjectType;
  name: string;
  description: string;
  optInToBudgetFeature: boolean;
  projectTemplateId?: number;

}

const defaultValues: NewProjectFormValues = {
  projectType: NewProjectType.NewProject,
  name: '',
  description: '',
  optInToBudgetFeature: false,
};

type Props = DialogProps & {
  projectTemplateId?: number;
  onAddTemplateRequested?: () => void;
};


export const AddProjectDialog: React.FC<Props> = ({ projectTemplateId, onAddTemplateRequested, ...dialogProps }) => {
  const { formatMessage } = useIntl();
  const invalidateQuery = useQueryInvalidator();
  const { notifySuccess } = useNotification();
  const navigate = useNavigate();

  const { data: templates } = useGetProjectsQuery({ filter: { isTemplate: { eq: true } } }, { select: p => p.projects });
  const { mutate: addProject, isLoading: isCreating } = useAddProjectMutation({
    onSuccess: p => {
      invalidateQuery(useGetProjectsQuery);
      navigate(`/${getTenantIdentifier()}/projects/${p.addProject.project?.id}`);
      notifySuccess(formatMessage({ id: 'Project created successfully' }));
    }
  });

  const { mutate: createProjectFromTemplate, isLoading: isCreatingFromTemplate } = useCreateProjectFromTemplateMutation({
    onSuccess: p => {
      invalidateQuery(useGetProjectsQuery);
      const projectId = p.createProjectFromTemplate.project?.id;
      navigate(`/${getTenantIdentifier()}/projects/${projectId}`);
    }
  });

  const isLoading = isCreating || isCreatingFromTemplate;

  const onSubmit = (values: NewProjectFormValues) => {
    if (values.projectTemplateId) {
      createProjectFromTemplate({
        input: {
          name: values.name,
          description: values.description ? values.description : undefined,
          projectTemplateId: values.projectTemplateId
        }
      });
    } else {
      addProject({
        input: {
          name: values.name,
          description: values.description ? values.description : undefined,
          hideBudgetFeature: !values.optInToBudgetFeature,
        }
      });
    }

  };

  const form = useForm<NewProjectFormValues>({ defaultValues });
  const selectedProjectTemplateId = useWatch({ control: form.control, name: 'projectTemplateId' });
  const projectTemplate = templates?.find(p => p.id == selectedProjectTemplateId);

  useEffect(() => {
    if (projectTemplateId) {
      form.reset({ ...defaultValues, projectTemplateId, projectType: NewProjectType.FromTemplate });
    } else {
      form.reset(defaultValues);
    }
  }, [form, projectTemplateId, dialogProps.open]);

  const projectType = useWatch({ control: form.control, name: 'projectType' });

  return (
    <FormProvider {...form}>
      <MultiStepFormDialog
        onSubmit={form.handleSubmit(onSubmit)}
        isSubmitting={isLoading}
        steps={[
          !projectTemplateId && {
            title: formatMessage({ id: 'Select an option' }),
            subtitle: formatMessage({ id: 'Create a blank project or select a template' }),
            content: (opts) => <SelectTypeOfProjectStep onTypeSelected={opts.nextStep} />,
            onNext: (callback) => form.handleSubmit(callback),
            hideNext: true
          },
          !projectTemplateId && projectType == NewProjectType.FromTemplate && {
            title: formatMessage({ id: 'Select a template' }),
            content: (options) => <SelectProjectTemplateStep onTemplateSelected={options.nextStep} onAddTemplateClicked={() => { dialogProps.onClose?.({}, 'backdropClick'); onAddTemplateRequested?.(); }} />,
            onNext: (callback) => form.handleSubmit(callback),
            hideNext: true
          },
          {
            title: formatMessage({ id: 'Name your project' }),
            subtitle: projectType == NewProjectType.FromTemplate ? `${formatMessage({ id: 'Using template: ' })}${projectTemplate?.name}` : undefined,
            content: () => <NewProjectStep />,
            onNext: (callback) => form.handleSubmit(callback)
          }
        ]} dialogProps={{
          ...dialogProps,
          maxWidth: 'md'
        }} />
    </FormProvider>
  );
};