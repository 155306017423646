import { ErrorDetails } from '@modules/application/components/ErrorDetails';
import { ReactComponent as UnexpectedErrorImage } from '@assets/images/not-found.svg';
import { Container } from '@mui/material';
import { useIntl } from 'react-intl';

export const NoProjectsCustomerView: React.FC = () => {
  const { formatMessage } = useIntl();

  return (
    <Container maxWidth="sm" sx={{ mt: 4 }}>
      <ErrorDetails
        image={<UnexpectedErrorImage />}
        title={formatMessage({ id: 'You are not part of any projects' })}
        message={formatMessage({ id: 'If you were invited to a project, it may no longer be accessible.' })}
      />
    </Container>
  );
};