import { LoadingButtonProps } from '@mui/lab';
import { Button, CircularProgress, Icon, styled } from '@mui/material';

const InnerButton = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  color: theme.palette.text.primary,
  backgroundColor: theme.palette.background.paper,
  borderColor: theme.palette.grey[300],
  borderRadius: '2px',
  borderStyle: 'solid',
  borderWidth: '1px',
  minWidth: '300px',
  justifyContent: 'flex-start',
  padding: 0,
  '& .MuiButton-startIcon': {
    padding: '15px',
    margin: '0px 10px 0px 0px',
    background: theme.palette.common.white,
    borderColor: theme.palette.grey[300],
    borderRadius: '0px',
    borderStyle: 'solid',
    borderWidth: '0px 1px 0px 0px',
    height: '50px',
    width: '50px',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
  '&:hover': {
    background: theme.palette.grey[100],
  }
}));

export const LoginButton: React.FC<LoadingButtonProps> = ({ loading, ...props }) => {
  return (
    <InnerButton
      {...props}
      startIcon={loading ? (
        <Icon>
          <CircularProgress size='1em' style={{ display: 'inline' }} />
        </Icon>
      ) : props.startIcon}
    />
  );
};