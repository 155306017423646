import { DisableableTooltip } from '@components/Tooltips/DisableableTooltip';
import { Delete } from '@mui/icons-material';
import { CircularProgress, IconButton, Stack } from '@mui/material';
import React from 'react';
import { useIntl } from 'react-intl';
import { MatrixFillerDataGridRow } from '../../types';
import { useMatrixRowFiller } from './useMatrixRowFiller';

interface Props {
  row: MatrixFillerDataGridRow;
  fieldId: number,
  disabled?: boolean;
  onDelete?: () => void;
}

export const MatrixRowActions: React.FC<Props> = ({ row, fieldId, disabled, onDelete }) => {
  const { formatMessage } = useIntl();
  const { deleteRow, isRemoveRowLoading } = useMatrixRowFiller();

  const onDeleteRow = () => {
    if (isRemoveRowLoading) {
      return;
    }

    deleteRow(row.id, fieldId);

    onDelete?.();
  };

  return (
    <Stack width={'100%'} justifyContent={'center'} alignItems={'center'}>
      <DisableableTooltip fullWidth disabled={!row.isDefaultRow} disableInteractive title={formatMessage({ id: 'Cannot delete a default row.' })}>
        <IconButton disabled={disabled || row.isDefaultRow} onClick={() => onDeleteRow()}>
          {(isRemoveRowLoading) ? <CircularProgress size={30} /> : <Delete />}
        </IconButton>
      </DisableableTooltip>
    </Stack>
  );
};