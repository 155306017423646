import { max } from 'lodash';


type Node = {
  id: number;
};

type HierarchyPath = string | null | undefined;

const getPathParts = (path: HierarchyPath) => path?.split('/').filter(Boolean) ?? [];

const pathLevel = (path: HierarchyPath) => getPathParts(path).length;

type Hierarchy<T> = {
  path: string;
  task: T;
  children: Hierarchy<T>[];
};

const getHierarchyParent = <T extends Node>(hierarchy: Hierarchy<T>[], path: HierarchyPath): Hierarchy<T> | undefined => {
  if (!path) return;

  let parent: Hierarchy<T> | undefined = undefined;

  for (; ;) {
    hierarchy = parent?.children ?? hierarchy;

    const foundParent = hierarchy.find(h => path.startsWith(h.path));

    if (!foundParent) {
      return parent;
    }

    parent = foundParent;
  }
};

type Output<T> = T & { hierarchyPath: string; };

const traverse = <T extends Node>(hierarchy: Hierarchy<T>, pathBase: string): Output<T>[] => {
  const result: Output<T>[] = [{ ...hierarchy.task, hierarchyPath: pathBase }];

  for (const child of hierarchy.children) {
    result.push(...traverse(child, `${pathBase}${child.task.id}/`));
  }

  return result;
};

export const fixHierarchyPaths = <T extends Node>(tasks: T[] | undefined, getPath: (t: T) => string | null | undefined): Output<T>[] => {
  if (!tasks) return [];

  const maxLevel = max(tasks.map(task => getPath(task)).map(pathLevel)) ?? 0;

  const hierarchy: Hierarchy<T>[] = [];
  let level = 0;

  while (level <= maxLevel) {
    const levelTasks = tasks.filter(task => pathLevel(getPath(task)) === level);

    for (const task of levelTasks) {
      const path = getPath(task);
      if (!path) continue;

      const parent = getHierarchyParent(hierarchy, getPath(task));

      if (!parent) {
        hierarchy.push({ path, task, children: [] });
      } else {
        parent.children.push({ path, task, children: [] });
      }
    }

    level += 1;
  }

  const result: Output<T>[] = [];

  for (const child of hierarchy) {
    result.push(...traverse(child, `/${child.task.id}/`));
  }


  return result;
};