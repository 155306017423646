import { ReactComponent as ViaCentralIcon } from '@assets/images/Via/ViaCentralLight.svg';
import { ReactComponent as CollaborationImage } from '@assets/images/collaboration.svg';
import LoginImage from '@assets/images/login.jpg';
import { Button, ButtonGroup, Link as MuiLink, Paper, Stack, Typography, styled } from '@mui/material';
import { useResponsive } from '@utils/useResponsive';
import dayjs from 'dayjs';
import { useIntl } from 'react-intl';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { currentLocaleState } from '../atoms';

const LoginBgStack = styled(Stack)(() => ({
  backgroundImage: `url(${LoginImage})`,
  backgroundPosition: 'center center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  flexGrow: 1
}));

export const LoginLayout: React.FC = () => {
  const { isMobile } = useResponsive();
  const { formatMessage } = useIntl();
  const [currentLocale, setCurrentLocale] = useRecoilState(currentLocaleState);

  const location = useLocation();

  const isTrialPage = location.pathname.includes('trial');

  return (
    <Stack direction='row' flex={1}>
      {!isMobile && (
        <LoginBgStack />
      )}

      <Paper elevation={8} sx={{ display: 'flex', flexDirection: 'column', flex: 1, maxWidth: { xs: 'unset', md: isTrialPage ? '700px' : '500px' }, transition: 'max-width 1s' }}>
        <Stack flex={1} sx={{ backgroundColor: 'background.paper' }}>
          <Stack
            sx={{
              flexShrink: isTrialPage ? '1' : '0',
              transition: 'flex-shrink 1s',
              '@media (max-height: 750px)': {
                flexBasis: 0
              }
            }}
            p={1}
            spacing={2}
            bgcolor='primary.main'
            color='primary.contrastText'
            justifyContent='center'
            alignItems='center'
          >
            <Stack direction={'row'} spacing={2} flex={1} alignSelf={'start'}>
              <ButtonGroup variant='text'>
                <Button key={'fr'} color='inherit'
                  onClick={() => setCurrentLocale('fr-CA')}
                  sx={{
                    textDecoration: currentLocale == 'fr-CA' ? 'underline' : 'inherit',
                    fontWeight: currentLocale == 'fr-CA' ? 600 : 'inherit',
                  }}>
                  FR
                </Button>
                <Button key={'en'} color='inherit'
                  onClick={() => setCurrentLocale('en-CA')}
                  sx={{
                    textDecoration: currentLocale == 'en-CA' ? 'underline' : 'inherit',
                    fontWeight: currentLocale == 'en-CA' ? 600 : 'inherit',
                  }}>EN</Button>
              </ButtonGroup>
            </Stack>
            <ViaCentralIcon style={{ height: 'auto', width: '100%', maxWidth: '180px' }} />

            <Typography variant='subtitle1' fontStyle='italic'>
              {formatMessage({ id: 'More than just a customer portal' })}
            </Typography>

            <Stack alignItems='center' width='100%' display={{ xs: 'none', md: 'flex' }}>
              <CollaborationImage width='100%' height='auto' color='#FFF' style={{ maxWidth: '300px' }} />
            </Stack>
          </Stack>

          <Outlet />
        </Stack>

        <Paper elevation={4} sx={{ width: '100%' }}>
          <Stack
            alignItems='center'
            justifyContent='center'
            direction='row'
            fontSize={{ xs: '0.7rem', md: '1rem' }}
            py={{ xs: 0.5, md: 2 }}
            gap={0.5}
          >
            <Typography variant='subtitle2' color='text.secondary' fontSize='inherit'>
              {`© ${dayjs().year()}`}
            </Typography>

            {' | '}

            <Typography variant='subtitle2' color='text.secondary' fontSize='inherit'>
              {'ARIV Inc.'}
            </Typography>

            {' | '}

            <Typography variant='subtitle2' color='text.secondary' fontSize='inherit'>
              {formatMessage({ id: 'All rights reserved' })}
            </Typography>

            {' | '}

            <MuiLink component={Link} to='https://ariv.ca/contact' target="_blank">
              {formatMessage({ id: 'Contact us' })}
            </MuiLink>
          </Stack>
        </Paper>
      </Paper >
    </Stack >
  );
};