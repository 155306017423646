import { FileIcon } from '@components/Icons/FileIcon';
import { FileIconType } from '@fluentui/react-file-type-icons';
import { Link as LinkIcon, Lock } from '@mui/icons-material';
import { Badge, Stack, Typography } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { getTenantIdentifier } from '@utils/getTenantIdentifier';
import { DocumentThumbnailStatus, FolderType } from 'gql/index';
import React, { useCallback } from 'react';
import { FolderTableRow } from './FolderTable';

type Props = GridRenderCellParams<FolderTableRow> & {
  onImageDocumentClicked: (documentId: number) => void;
};

export const FolderRowNameCell: React.FC<Props> = ({ row, value, onImageDocumentClicked }) => {
  const downloadFile = useCallback((documentId: number) => {
    const link = document.createElement('a');
    link.href = `/${getTenantIdentifier()}/api/Documents/${documentId}`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }, []);

  return (
    <Stack direction='row' spacing={2} justifyContent='center' alignItems='center' sx={{ pl: 0 }} overflow='hidden'>
      {(row.rowType === 'folder' && row.folderType === FolderType.ConfidentialDocument) && (
        <Badge
          badgeContent={<Lock />}
          color='default'
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          sx={{
            '& .MuiBadge-badge': {
              right: 4,
              bottom: 10,
            }
          }}
        >
          <FileIcon fileIconType={FileIconType.folder} fontSize='large' />
        </Badge>
      )}

      {(row.rowType === 'folder' && row.folderType != FolderType.ConfidentialDocument) && (
        <FileIcon fileIconType={FileIconType.folder} fontSize='large' />
      )}

      {row.rowType === 'document' && (
        <FileIcon fileName={row.document.fileName} fontSize='large' />
      )}

      {row.rowType === 'externalLink' && (
        <LinkIcon fontSize='large' />
      )}

      <Stack overflow='hidden'>
        <Typography
          title={value}
          sx={{ ':hover': { textDecoration: 'underline', cursor: 'pointer' } }}
          onClick={() => {
            if (row.rowType === 'document') {
              if (row.document.thumbnailStatus !== DocumentThumbnailStatus.NotAnImage) {
                onImageDocumentClicked(row.document.id);
              } else {
                downloadFile(row.document.id);
              }
            } else if (row.rowType === 'externalLink') {
              window.open(row.externalLink.link, '_blank');
            }
          }}
        >
          {value}
        </Typography>

        {row.rowType === 'externalLink' && (
          <Typography title={row.externalLink.link} variant='caption' color='text.secondary' overflow='hidden' textOverflow='ellipsis'>
            {row.externalLink.link}
          </Typography>
        )}
      </Stack>
    </Stack>

  );
};