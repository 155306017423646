import { PageSection } from '@components/PageSection/PageSection';
import { ResponsiveButton } from '@components/ResponsiveButton';
import { useCurrentProject } from '@modules/projects/utils/useCurrentProject';
import { Add } from '@mui/icons-material';
import { ProjectTaskQuery, useProjectTaskSubtasksQuery } from 'gql/index';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { TaskFormDrawer } from '../TaskFormDrawer';
import { TasksTable } from '../TasksTable';

interface Props {
  task: NonNullable<ProjectTaskQuery['projectTask']>;
}

export const TaskSubtasksSection: React.FC<Props> = ({ task }) => {
  const { formatMessage } = useIntl();
  const { canManageTasks } = useCurrentProject();

  const [isAddingSubtask, setIsAddingSubtask] = useState(false);

  const { data: subtasks } = useProjectTaskSubtasksQuery({ taskId: task.id }, {
    select: d => d.projectTaskSubtasks
  });

  return <>
    <PageSection
      title={formatMessage({ id: 'Subtasks' })}
      actionButton={canManageTasks && (
        <ResponsiveButton variant='contained' icon={<Add />} onClick={() => setIsAddingSubtask(true)}>
          {formatMessage({ id: 'Add subtask' })}
        </ResponsiveButton>
      )}
    >
      {subtasks && (
        <TasksTable

          tasks={subtasks}
          flex={false}
          noDataMessage={!canManageTasks ?
            formatMessage({ id: 'No subtasks found. There may be subtasks that you do not have access to.' }) :
            formatMessage({ id: 'No subtasks.' })}
        />
      )}
    </PageSection>

    <TaskFormDrawer
      parentTaskId={task.id}
      open={isAddingSubtask}
      onClose={() => setIsAddingSubtask(false)}
    />
  </>;
};