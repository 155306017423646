import { FieldType } from 'gql/index';
import { createEvent } from 'react-event-hook';
import { FormEditorFieldValues } from './types';

interface SectionMovedEvent {
  sourceIndex: number;
  destinationIndex: number;
}

interface SectionCreatedEvent {
  destinationId: string;
  createdAtIndex: number;
}

export const { emitSectionCreated, useSectionCreatedListener } = createEvent('sectionCreated')<SectionCreatedEvent>();

export const { emitSectionMoved, useSectionMovedListener } = createEvent('sectionMoved')<SectionMovedEvent>();

interface FieldCreatedEvent {
  destinationId: string;
  createdAtIndex: number;
  fieldType: FieldType;
  isMultiline: boolean;
}

export const { emitFieldCreated, useFieldCreatedListener } = createEvent('fieldCreated')<FieldCreatedEvent>();

interface FieldMovedEvent {
  sourceSectionId: string;
  destinationSectionId: string;
  sourceIndex: number;
  destinationIndex: number;
  field: FormEditorFieldValues;
}

export const { emitFieldMoved, useFieldMovedListener } = createEvent('fieldMoved')<FieldMovedEvent>();

interface AllFieldsMovedEvent {
  destinationSectionId: string;
  fields: FormEditorFieldValues[];
}

export const { emitAllFieldsMoved, useAllFieldsMovedListener } = createEvent('allFieldsMoved')<AllFieldsMovedEvent>();