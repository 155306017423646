import { List, ListItem, ListItemProps, ListProps, styled, } from '@mui/material';


export const ProjectSectionList = styled(List)<ListProps>(({ theme }) => ({
  maxHeight: theme.spacing(30),
  overflow: 'auto',
  border: `1px solid ${theme.palette.divider}`,
  borderRight: 0,
  borderLeft: 0
}));


export const ProjectSectionListItem = styled(ListItem)<ListItemProps>(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  borderBottomWidth: 0,
  ':last-child': { borderBottomWidth: 0 },
  ':first-of-type': { borderTopWidth: 0 }
}));