import { EditorTopBar } from '@components/Editor/TopBar/EditorTopBar';
import { IndexSwitcher } from '@components/Navigation/IndexSwitcher';
import { Container, Stack } from '@mui/material';
import { ProjectFormSubmissionFragmentFragment, SubmissionStatus } from 'gql/index';
import React from 'react';
import { AppTopBar } from '../../../components/Layout/AppTopBar';

interface Props {
  selectedSubmissionId: number;
  submissions: ProjectFormSubmissionFragmentFragment[];
  onSubmissionSwitch: (submissionId: number) => void;
  isLoading: boolean;
}



export const FormViewerTopBar: React.FC<Props> = ({ submissions: _submissions, selectedSubmissionId, onSubmissionSwitch, isLoading }) => {
  const submissions = _submissions.filter(s => s.status === SubmissionStatus.Submitted || s.status === SubmissionStatus.Closed);
  const selectedSubmission = submissions.find(s => s.id === selectedSubmissionId);

  const selectedSubmissionIndex = submissions.findIndex(s => s.id === selectedSubmissionId);

  return (
    <>
      <EditorTopBar title={selectedSubmission?.projectForm?.formDefinition?.name ?? ''} isLoading={isLoading} />
      <AppTopBar sx={{ backgroundColor: 'background.paper', padding: 0, color: 'inherit' }} elevation={1}>
        <Container maxWidth='lg'>
          <Stack direction='row' width='100%' paddingTop={1} justifyContent='space-between' alignItems='center'>
            {submissions.length > 1 && (
              <Stack height='100%' justifyContent='end'>
                <IndexSwitcher
                  currentIndex={selectedSubmissionIndex}
                  length={submissions.length}
                  onIndexChange={index => onSubmissionSwitch(submissions[index].id)}
                />
              </Stack>
            )}


          </Stack>
        </Container>


      </AppTopBar >
    </>
  );
};