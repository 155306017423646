import { ProjectSectionListItem } from '@modules/projects/components/Sections/ProjectSectionList';
import { useCurrentProject } from '@modules/projects/utils/useCurrentProject';
import { ListItemButton, ListItemProps, ListItemText } from '@mui/material';
import { getTenantIdentifier } from '@utils/getTenantIdentifier';
import dayjs from 'dayjs';
import { ApplicationUser, FormDefinition, FormSettings, ProjectForm, ProjectTaskFragmentFragment, useRequestFormSubmissionMutation } from 'gql/index';
import React from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { useDueDateFormatter } from '../useDueDateFormatter';

type FormListItemProps = ListItemProps & {
  task: ProjectTaskFragmentFragment,
  form: Pick<ProjectForm, 'id' | 'publishedOn' | 'createdOn' | 'status'> & {
    allowedUsers: Pick<ApplicationUser, 'id'>[];
    formDefinition?: Pick<FormDefinition, 'name'> | null;
    formSettings?: Pick<FormSettings, 'formType'>;
  },
};

export const FormTaskListItem: React.FC<FormListItemProps> = ({ form, task, ...listItemProps }) => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const { projectId } = useCurrentProject();
  const formatDueDate = useDueDateFormatter();

  const { mutate: requestSubmission, isLoading: isRequestingSubmission } = useRequestFormSubmissionMutation();

  const onRequestSubmissionClicked = () => {
    requestSubmission({ input: { formId: form.id } }, {
      onSuccess: d => {
        const id = d.requestFormSubmission.projectFormSubmission?.id;
        if (!id) return;
        navigate(`/${getTenantIdentifier()}/projects/${projectId}/form/${form.id}/${id.toString()}`);
      }
    });
  };


  return (
    <ProjectSectionListItem
      disablePadding
      {...listItemProps}
    >
      <ListItemButton disabled={isRequestingSubmission} alignItems='center' sx={{ py: 1.5 }} onClick={onRequestSubmissionClicked}>
        <ListItemText
          primary={formatMessage({ id: 'Fill the form {formName}' }, { formName: form.formDefinition?.name })}
          secondary={formatDueDate(task.dueDate)}
          secondaryTypographyProps={{ color: dayjs(task.dueDate).isBefore(dayjs()) ? 'error' : 'inherit' }}
          primaryTypographyProps={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
        />
      </ListItemButton>
    </ProjectSectionListItem >
  );
};