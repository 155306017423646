import { PrincipalPicker } from '@modules/users/components/PrincipalPicker/PrincipalPicker';
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Stack } from '@mui/material';
import { FormType } from 'gql/subscriptions';
import React from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { AllowedRespondents } from '../../../../gql';
import { FormSettingsType } from './types';

interface Props {
  disabled?: boolean;
}

export const FormSettings: React.FC<Props> = ({ disabled }) => {
  const { formatMessage } = useIntl();
  const { control, getValues } = useFormContext<FormSettingsType>();
  const allowedResponders = useWatch({ control, name: 'allowedResponders' });

  if (getValues('formType') === FormType.CollectResponse) {
    return undefined;
  }

  return <Stack gap={2}>

    <Controller
      control={control}
      name='allowedResponders'
      render={({ field }) =>
        <FormControl disabled={disabled}>
          <FormLabel>{formatMessage({ id: 'Who can respond?' })}</FormLabel>
          <RadioGroup {...field}>
            <FormControlLabel disabled={disabled} value={AllowedRespondents.AllProjectMembers} control={<Radio />} label={formatMessage({ id: 'All project members' })} />
            <FormControlLabel disabled={disabled} value={AllowedRespondents.SpecificUsersOrGroups} control={<Radio />} label={formatMessage({ id: 'Only specific people can respond' })} />

          </RadioGroup>
        </FormControl>
      }
    />

    <Controller
      control={control}
      name='specificResponders'
      rules={{ validate: value => allowedResponders === AllowedRespondents.SpecificUsersOrGroups && value.length == 0 ? formatMessage({ id: 'Select at least one responder.' }) : undefined }}
      render={({ field, fieldState }) =>
        <FormControl sx={{ display: allowedResponders === AllowedRespondents.SpecificUsersOrGroups ? 'default' : 'none' }}>

          <PrincipalPicker
            multiple
            value={field.value}
            onChange={field.onChange}
            label={formatMessage({ id: 'Responders' })}
            disabled={disabled}
            error={fieldState.error?.message}
          />
        </FormControl>
      }
    />


  </Stack>;
};