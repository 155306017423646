import { DataTable } from '@components/DataTable';
import { useCurrentProject } from '@modules/projects/utils/useCurrentProject';
import { budgetPermissionDescriptionMessages, budgetPermissionMessages, documentsPermissionDescriptionMessages, documentsPermissionMessages, formsPermissionDescriptionMessages, formsPermissionMessages, publicFileSharingPermissionDescriptionMessages, publicFileSharingPermissionMessages, tasksPermissionDescriptionMessages, tasksPermissionMessages, workflowsPermissionDescriptionMessages, workflowsPermissionMessages } from '@modules/securityGroups/messages';
import { Info } from '@mui/icons-material';
import { Box, MenuItem, Stack, TextField, Tooltip, Typography } from '@mui/material';
import { GridColDef, useGridApiRef } from '@mui/x-data-grid-pro';
import { BudgetAndExpensesPermission, DocumentsPermission, FormsPermission, ManagePublicFileSharingPermission, SecurityGroupFragmentFragment, TasksPermission, WorkflowsPermission, useSecurityGroupsForProjectQuery, useUpdateSecurityGroupRolesMutation } from 'gql/index';
import sortBy from 'lodash/sortBy';
import { useCallback, useMemo } from 'react';
import { MessageDescriptor, defineMessages, useIntl } from 'react-intl';
import { PermissionType, permissionRoleSortOrder } from './types';

const permissions = ['budget', 'expenses', 'forms', 'tasks', 'documents', 'publicFileSharing', 'workflows'] as const;
type PermissionGroup = typeof permissions[number];

const permissionsMessages: Record<PermissionGroup, MessageDescriptor> = defineMessages({
  budget: { id: 'Budget and expenses' },
  expenses: { id: 'Expenses' },
  forms: { id: 'Forms' },
  tasks: { id: 'Tasks' },
  documents: { id: 'Documents' },
  publicFileSharing: { id: 'Public file sharing' },
  workflows: { id: 'Workflows' }
});

interface Row {
  permissionGroup: PermissionGroup;
  editEvent?: { groupId: string; updatedPermission: PermissionGroup; newRole: PermissionType; };
  availablePermissionLevels: PermissionType[];
}

const rows: Row[] = [
  { permissionGroup: 'budget', availablePermissionLevels: Object.values(BudgetAndExpensesPermission) },
  { permissionGroup: 'forms', availablePermissionLevels: Object.values(FormsPermission) },
  { permissionGroup: 'tasks', availablePermissionLevels: Object.values(TasksPermission) },
  { permissionGroup: 'documents', availablePermissionLevels: Object.values(DocumentsPermission) },
  { permissionGroup: 'publicFileSharing', availablePermissionLevels: Object.values(ManagePublicFileSharingPermission) },
  { permissionGroup: 'workflows', availablePermissionLevels: Object.values(WorkflowsPermission) }
];


const sortByRole = (a: PermissionType, b: PermissionType) => permissionRoleSortOrder[a] - permissionRoleSortOrder[b];

const domainRoleGetter: Record<PermissionGroup, (group: SecurityGroupFragmentFragment) => PermissionType> = {
  budget: group => group.permissions.budgetPermission,
  expenses: _ => { throw new Error('Deprecated role'); },
  forms: group => group.permissions.formsPermission,
  tasks: group => group.permissions.tasksPermission,
  documents: group => group.permissions.documentsPermission,
  publicFileSharing: group => group.permissions.managePublicFileSharingPermission,
  workflows: group => group.permissions.workflowsPermission
};

const domainMessageGetter: Record<PermissionGroup, (role: PermissionType) => MessageDescriptor> = {
  budget: role => budgetPermissionMessages[role as BudgetAndExpensesPermission],
  expenses: _ => { throw new Error('Deprecated role'); },
  forms: role => formsPermissionMessages[role as FormsPermission],
  tasks: role => tasksPermissionMessages[role as TasksPermission],
  documents: role => documentsPermissionMessages[role as DocumentsPermission],
  publicFileSharing: role => publicFileSharingPermissionMessages[role as ManagePublicFileSharingPermission],
  workflows: role => workflowsPermissionMessages[role as WorkflowsPermission]
};

const permissionDescriptionsGetter: Record<PermissionGroup, (role: PermissionType) => MessageDescriptor> = {
  budget: role => budgetPermissionDescriptionMessages[role as BudgetAndExpensesPermission],
  expenses: _ => { throw new Error('Deprecated role'); },
  forms: role => formsPermissionDescriptionMessages[role as FormsPermission],
  tasks: role => tasksPermissionDescriptionMessages[role as TasksPermission],
  documents: role => documentsPermissionDescriptionMessages[role as DocumentsPermission],
  publicFileSharing: role => publicFileSharingPermissionDescriptionMessages[role as ManagePublicFileSharingPermission],
  workflows: role => workflowsPermissionDescriptionMessages[role as WorkflowsPermission]
};

const useFilteredRows = () => {
  const { hideBudgetFeature } = useCurrentProject();

  const relevantRows = hideBudgetFeature ? rows.filter(d => d.permissionGroup !== 'budget' && d.permissionGroup !== 'expenses') : rows;

  return relevantRows;
};

export const PermissionsTable = () => {
  const { projectId, canManageProject } = useCurrentProject();
  const { formatMessage } = useIntl();

  const rows = useFilteredRows();

  const { data: securityGroups, isFetching: isFetchingSecurityGroups, refetch } = useSecurityGroupsForProjectQuery({ projectId }, {
    select: d => sortBy(d.securityGroupsForProject, p => p.isDefaultProjectAdminGroup, p => p.name)
  });

  const { mutateAsync: updateGroupRoles, isLoading: isUpdatingRole } = useUpdateSecurityGroupRolesMutation({ onSuccess: () => refetch() });

  const processRowUpdate = useCallback(async (newRow: Row, oldRow: Row) => {
    if (!newRow.editEvent) return oldRow;
    const { groupId, updatedPermission, newRole } = newRow.editEvent;

    const group = securityGroups?.find(g => g.id === groupId);
    if (!group) return oldRow;

    updateGroupRoles({
      input: {
        projectId,
        securityGroupId: groupId,
        budgetPermission: updatedPermission === 'budget' ? newRole as BudgetAndExpensesPermission : group.permissions.budgetPermission,
        formsPermission: updatedPermission === 'forms' ? newRole as FormsPermission : group.permissions.formsPermission,
        tasksPermission: updatedPermission === 'tasks' ? newRole as TasksPermission : group.permissions.tasksPermission,
        documentsPermission: updatedPermission === 'documents' ? newRole as DocumentsPermission : group.permissions.documentsPermission,
        managePublicFileSharingPermission: updatedPermission === 'publicFileSharing' ? newRole as ManagePublicFileSharingPermission : group.permissions.managePublicFileSharingPermission,
        workflowsPermission: updatedPermission === 'workflows' ? newRole as WorkflowsPermission : group.permissions.workflowsPermission
      }
    });

    return oldRow;
  }, [projectId, securityGroups, updateGroupRoles]);

  const loading = isFetchingSecurityGroups || isUpdatingRole;

  const apiRef = useGridApiRef();

  const columns = useMemo(() => {
    if (!securityGroups) return [];

    const columns: GridColDef<Row>[] = [];

    columns.push({
      field: 'domain',
      flex: 1,
      minWidth: 200,
      maxWidth: 200,
      headerName: '',
      hideable: false,
      disableColumnMenu: true,
      sortable: false,
      pinnable: true,
      renderCell: (params) => <Typography>{params.value}</Typography>,
      valueGetter: ({ row }) => formatMessage(permissionsMessages[row.permissionGroup])
    });

    for (const group of securityGroups) {
      columns.push({
        field: group.id,
        flex: 1,
        minWidth: 250,
        editable: !group.isDefaultProjectAdminGroup && !loading && canManageProject,
        sortable: false,
        disableColumnMenu: true,
        headerName: group.name,
        renderHeader: () => <>
          <Box paddingRight={1}>
            {group.name}
          </Box>

          {group.description && (
            <Tooltip title={group.description}>
              <Info fontSize='small' />
            </Tooltip>
          )}
        </>,
        valueGetter: ({ row }) => {
          // If recently edited, show updated value
          if (row.editEvent && row.editEvent.groupId === group.id) return row.editEvent.newRole;

          const domainRole = domainRoleGetter[row.permissionGroup](group);

          return domainRole;
        },
        renderCell: (params) => (
          <Tooltip title={formatMessage(permissionDescriptionsGetter[params.row.permissionGroup](params.value))}>
            <Typography color={params.colDef.editable ? 'text' : 'text.secondary'}>
              {formatMessage(domainMessageGetter[params.row.permissionGroup](params.value))}
            </Typography>
          </Tooltip>
        ),
        valueSetter: ({ row, value }) => ({ ...row, editEvent: { groupId: group.id, updatedPermission: row.permissionGroup, newRole: value } }),
        renderEditCell: ({ row, value }) => {
          return (
            <TextField
              select
              fullWidth
              autoFocus
              sx={{ height: '100%' }}
              value={value}
              onChange={e => {
                const newRole = e.target.value as PermissionType;
                apiRef.current.setEditCellValue({ id: row.permissionGroup, field: group.id, value: newRole });
                apiRef.current.stopCellEditMode({ id: row.permissionGroup, field: group.id });
              }}
              SelectProps={{
                renderValue: (value) => <Typography>{formatMessage(domainMessageGetter[row.permissionGroup](value as PermissionType))}</Typography>
              }}
            >
              {row.availablePermissionLevels.toSorted(sortByRole).map(role => (
                <MenuItem key={role} value={role}>
                  <Stack>
                    <Typography>{formatMessage(domainMessageGetter[row.permissionGroup](role))}</Typography>
                    <Typography variant='body2'>{formatMessage(permissionDescriptionsGetter[row.permissionGroup](role))}</Typography>
                  </Stack>
                </MenuItem>
              ))}
            </TextField>
          );
        }
      });
    }

    return columns;
  }, [apiRef, formatMessage, loading, securityGroups]);

  return (
    <DataTable<Row>
      apiRef={apiRef}
      columns={columns}
      rows={rows}
      pinnedColumns={{ left: ['domain'] }}
      getRowId={row => row.permissionGroup}
      processRowUpdate={processRowUpdate}
      onCellClick={cell => !loading && cell.isEditable && cell.cellMode === 'view' && apiRef.current.startCellEditMode({ id: (cell.row as Row).permissionGroup, field: cell.colDef.field })}
      loading={loading}
    />
  );
};