import { TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator } from '@mui/lab';
import { Box, Stack, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { RecentEvent } from './types';

export const RecentEventTimelineItem = (props: { recentEvent: RecentEvent, isFirstItem: boolean, isLastItem: boolean }) => {
  return (<TimelineItem>
    <TimelineSeparator>
      {!props.isFirstItem && <TimelineConnector />}
      <TimelineDot variant='outlined' sx={{ backgroundColor: 'background.paper' }}>
        {props.recentEvent.icon}
      </TimelineDot>
      {!props.isLastItem && <TimelineConnector />}
    </TimelineSeparator>
    <TimelineContent sx={{ py: '12px', px: 2 }}>
      <Typography variant="body2" color='text.secondary' component="span">
        {dayjs(props.recentEvent.eventDate).format('LT')}
      </Typography>
      <Stack direction={'row'} spacing={1} flexWrap={'wrap'}>
        <Box>

          <Typography display={'inline'} sx={{ mr: '1ch' }}>
            {props.recentEvent.eventText}
          </Typography>

        </Box>
      </Stack>

    </TimelineContent>
  </TimelineItem>);
};