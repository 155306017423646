import { ListItem, ListItemButton } from '@mui/material';
import { RoleAssignmentFragmentFragment } from 'gql/index';
import React from 'react';
import { PrincipalItemContent } from '../../../users/components/PrincipalItemContent';


interface Props {
  roleAssignment: RoleAssignmentFragmentFragment;
  secondaryAction?: React.ReactNode;
  onClick?: () => void;
}

export const RoleAssignmentListItem: React.FC<Props> = ({ roleAssignment, secondaryAction, onClick }) => {
  return (
    <ListItem
      disablePadding={!!onClick}
      secondaryAction={secondaryAction}
      sx={{
        border: t => `1px solid ${t.palette.divider}`,
        borderBottomWidth: 0,

        borderLeftWidth: 0,
        borderRightWidth: 0,
        ':first-of-type': {
          borderTopWidth: 0
        },
        minHeight: 72
      }}>
      {onClick ? (
        <ListItemButton onClick={onClick} sx={{ minHeight: 72 }}>
          <PrincipalItemContent roleAssignment={roleAssignment} />
        </ListItemButton>
      ) : (
        <PrincipalItemContent roleAssignment={roleAssignment} />
      )}
    </ListItem>
  );
};