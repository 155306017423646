import { ReactNode, createContext, useState } from 'react';

interface SavingStatusContext {
  isEditorLoading: boolean;
  editorSavingStatus: EditorSavingStatus | undefined;
  setEditorSavingStatus: (value: EditorSavingStatus) => void;
}

export enum EditorSavingStatus {
  Saving = 0,
  Saved = 1,
  Failed = 2
}

export const SavingStatusContext = createContext<SavingStatusContext>({
  editorSavingStatus: undefined, setEditorSavingStatus: () => { /* default value */ },
  isEditorLoading: false
});


export const SavingStatusContextProvider = (props: { children: ReactNode, isEditorLoading: boolean }) => {
  const [editorSavingStatus, setEditorSavingStatus] = useState<EditorSavingStatus | undefined>();
  return (

    <SavingStatusContext.Provider value={{ editorSavingStatus, setEditorSavingStatus, isEditorLoading: props.isEditorLoading }}>
      {props.children}
    </SavingStatusContext.Provider>
  );
};