import { Box, FormLabel, Pagination, PaginationItem, Stack, styled, Typography, useTheme } from '@mui/material';
import { useState } from 'react';
import { Document, Page } from 'react-pdf';
import typia from 'typia';

export type FieldCoordinates = { x: number, y: number, page: number; };

type Props = {
  documentUrl: string,
  signatureCoordinates: FieldCoordinates | null;
  onCoordinatesChanged: (coordinates: FieldCoordinates) => void;
  error?: string;
};

const PDFContainer = styled(Stack)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    ['& .react-pdf__Page']: {
      position: 'relative',
      width: 'min-content'
    },
    ['& .react-pdf__Document']: {
      display: 'flex',
      justifyContent: 'center'
    }
  }
}));

type CanvasElement = Pick<HTMLCanvasElement, 'className'>;

export const SignaturePlacementControl: React.FC<Props> = ({ documentUrl, error, signatureCoordinates, onCoordinatesChanged }) => {
  const [displayedPage, setDisplayedPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const theme = useTheme();

  return (
    <Stack gap={2} alignItems={'center'} height='100%'>
      <PDFContainer
        width='100%'
        maxHeight='600px'
        flexGrow={1}
        overflow={'auto'}
        position={'relative'}
        border={error && `1px solid ${theme.palette.error.main}`}
        onClick={(e) => {
          if (typia.is<CanvasElement>(e.target) && !e.target.className.includes('react-pdf__Page')) {
            return;
          }
          onCoordinatesChanged({ x: e.nativeEvent.offsetX, y: e.nativeEvent.offsetY, page: displayedPage });
        }}
      >
        <Document file={documentUrl} onLoadSuccess={(document) => setTotalPages(document.numPages)}>
          <Page pageNumber={displayedPage} renderAnnotationLayer={false} renderTextLayer={false}>
            {signatureCoordinates && (
              <Box sx={{
                position: 'absolute',
                userSelect: 'none',
                p: 1,
                top: signatureCoordinates.y,
                color: 'black',
                left: signatureCoordinates.x,
                border: '1px solid blue',
                backgroundColor: 'lightblue',
                'color &diabled': 'black',
                width: '20ch',
                pointerEvents: 'none',
                display: displayedPage === signatureCoordinates.page ? 'block' : 'none'
              }}>
                <Typography sx={{ cursor: 'default', pointerEvents: 'none' }}>{'Signature'}</Typography>
              </Box>
            )}
          </Page>
        </Document>

      </PDFContainer>
      {error && <FormLabel error sx={{ flex: 1 }}>{error}</FormLabel>}

      <Pagination
        count={totalPages}
        page={displayedPage}
        renderItem={item => (
          <PaginationItem
            {...item}
            variant={item.page === signatureCoordinates?.page && item.type == 'page' ? 'outlined' : 'text'}
          />
        )}
        onChange={(_, page) => {
          setDisplayedPage(page);
        }}
        size="small"
      />
    </Stack >
  );
};