import { Paper, Stack, Typography, styled } from '@mui/material';
import { FormDefinitionFragmentFragment, FormValueFragmentFragment } from 'gql/index';
import React, { useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FormFillerValues } from '../FormFiller/types';
import { backendValuesToFormFillerValues } from '../FormFiller/utils';
import { FormViewerSection } from './FormViewerSection';

interface Props {
  editable?: boolean;
  definition: FormDefinitionFragmentFragment;
  values: FormValueFragmentFragment[];
}

const BorderedPaper = styled(Paper)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
}));

export const FormViewer: React.FC<Props> = ({ definition, values, editable }) => {
  const allFields = useMemo(() => definition.sections.flatMap(p => p.fields), [definition.sections]);

  const form = useForm<FormFillerValues>({
    defaultValues: {
      values: backendValuesToFormFillerValues(allFields, values, -1)
    }
  });

  useEffect(() => form.reset({ values: backendValuesToFormFillerValues(allFields, values, -1) }, { keepDefaultValues: false }), [allFields, form, values]);

  return (
    <Stack gap={2} height='100%'>
      <BorderedPaper sx={{ p: 2 }}>
        <Typography variant='h5'>
          {definition.name}
        </Typography>

        <Typography variant='body2'>
          {definition.description}
        </Typography>
      </BorderedPaper>

      <FormProvider {...form}>
        {definition.sections.map((section, index) => (
          <FormViewerSection
            editable={editable}
            key={section.id}
            definition={definition}
            section={section}
            fieldIndexOffset={definition.sections.filter((_, i) => i < index).flatMap(p => p.fields).length}
          />
        ))}
      </FormProvider>
    </Stack>
  );
};