import { FileIcon } from '@components/Icons/FileIcon';
import { AskSignatureFormValues, RequestSignatureDialog } from '@modules/documents/components/RequestSignatureDialog/RequestSignatureDialog';
import { useCurrentProject } from '@modules/projects/utils/useCurrentProject';
import { getFieldValue } from '@modules/workflow/components/types';
import { Draw, Warning } from '@mui/icons-material';
import { Alert } from '@mui/material';
import { useNotification } from '@utils/useNotification';
import { useQueryInvalidator } from '@utils/useQueryInvalidator';
import { AskSignatureInput, useActionsOfWorkflowInstanceQuery, useGetDocumentQuery, useSubmitWorkflowActionMutation, useWorkflowInstanceQuery, useWorkflowInstancesQuery, WorkflowActionStatus } from 'gql/index';
import { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { ActionAccordion } from '../../WorflowInstance/Actions/ActionAccordion/ActionAccordion';
import { CompletedActionContentItemLink } from '../../WorflowInstance/Actions/ActionAccordion/CompletedActionContentItemLink';
import { ActionAccordionProps } from '../../WorflowInstance/Actions/Types';
import { ConfigureDocumentSignatureActionDefinition } from './ConfigureDocumentSignatureActionDefinition';

export const ConfigureDocumentSignatureActionExecution: React.FC<ActionAccordionProps> = (props) => {
  const [isConfigureSignatureDialogOpen, setIsConfigureSignatureDialogOpen] = useState(false);
  const { formatMessage } = useIntl();
  const { action, workflowId } = props;
  const { projectId } = useCurrentProject();
  const { notifySuccess } = useNotification();
  const invalidateQuery = useQueryInvalidator();
  const { mutate: submitWorkflowAction, isLoading: isSubmitting } = useSubmitWorkflowActionMutation({
    onSuccess: () => {
      invalidateQuery(useWorkflowInstanceQuery, { projectId, workflowId });
      invalidateQuery(useActionsOfWorkflowInstanceQuery, { workflowId });
      invalidateQuery(useWorkflowInstancesQuery, { projectId });
      setIsConfigureSignatureDialogOpen(false);
      notifySuccess(formatMessage({ id: 'Workflow action completed successfully.' }));
    }
  });

  const documentId = useMemo(() => getFieldValue<number>(action, ConfigureDocumentSignatureActionDefinition.FieldId_Document), [action]);

  const { data: document, isFetching } = useGetDocumentQuery({ id: Number(documentId) }, {
    enabled: typeof (documentId) == 'number',
    select: p => p.document,
    meta: { nullable: true }
  });

  const isDocumentMissing = !document && !isFetching && !!documentId;

  const handleOnClick = () => setIsConfigureSignatureDialogOpen(true);

  const submitAction = (values: AskSignatureFormValues) => {
    const actionPayload: AskSignatureInput = {
      documentId: Number(values.documentId),
      signatoryCustomerId: values.signatoryCustomerId,
      customMessage: values.customMessage,
      dueDate: values.dueDate,
      signatureCoordinates: values.signatureCoordinates,
      projectId
    };

    submitWorkflowAction({
      input: {
        workflowActionId: action.id,
        workflowInstanceId: workflowId,
        jsonPayload: JSON.stringify(actionPayload)
      }
    });
  };

  return (<>
    <ActionAccordion
      action={action}
      submitAction={{
        loading: isSubmitting,
        buttonIcon: <Draw />,
        buttonText: formatMessage({ id: 'Request signature' }),
        onClick: handleOnClick
      }}
    >
      {(document && action.status === WorkflowActionStatus.Completed) &&
        <CompletedActionContentItemLink
          ItemIcon={<FileIcon fileName={document.fileName} />}
          ItemName={document.fileName ?? ''}
          linkPath={undefined}
          titleText={formatMessage({ id: 'Signature requested' })}
        />
      }

      {isDocumentMissing && (
        <Alert color='warning' icon={<Warning />}>
          {formatMessage({ id: 'Document not found - it may have been deleted.' })}
        </Alert>
      )}
    </ActionAccordion>

    <RequestSignatureDialog
      documentId={documentId}
      onSubmit={submitAction}
      isSubmitting={isSubmitting}
      open={isConfigureSignatureDialogOpen}
      onClose={() => setIsConfigureSignatureDialogOpen(false)} />
  </>
  );
};

