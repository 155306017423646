import { FormDrawer, FormDrawerProps } from '@components/Drawers/FormDrawer';
import { userRoleDescriptionMessages, userRoleMessages } from '@modules/application/messages';
import { Alert, MenuItem, Stack, TextField } from '@mui/material';
import { useNotification } from '@utils/useNotification';
import { useQueryInvalidator } from '@utils/useQueryInvalidator';
import { getNormalizedPhoneNumber, useValidationRules } from '@utils/useValidationRules';
import { BuiltInRoles, useGetUsersFullQuery, useGetUsersQuery, useInviteUserMutation } from 'gql/index';
import { MuiTelInput } from 'mui-tel-input';
import { useEffect } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { UserApplicationBuiltInRoles } from './types';

type Props = Omit<FormDrawerProps, 'onSave'> & {
  onAdminInvited?: (id: string) => void;
};

type InviteAdminFormValues = {
  email: string;
  firstName: string;
  lastName: string;
  title: string;
  phoneNumber: string;
  phoneNumberExtension: string;
  role: BuiltInRoles;
};

const defaultValues: InviteAdminFormValues = {
  email: '',
  firstName: '',
  lastName: '',
  title: '',
  phoneNumber: '',
  phoneNumberExtension: '',
  role: BuiltInRoles.Contributor
};

export const InviteUserDrawer: React.FC<Props> = ({ onAdminInvited, ...drawerProps }) => {
  const { formatMessage } = useIntl();
  const { validateEmail, validatePhoneNumber } = useValidationRules();
  const invalidateQuery = useQueryInvalidator();
  const { notifySuccess } = useNotification();

  const form = useForm<InviteAdminFormValues>({ defaultValues });

  const role = useWatch({ control: form.control, name: 'role' });
  const roleDescription = userRoleDescriptionMessages[role];
  const { reset, control, handleSubmit, formState: { isDirty } } = form;

  useEffect(() => {
    reset(defaultValues);
  }, [reset, drawerProps.open]);

  const inviteUserMutation = useInviteUserMutation({
    onSuccess: (user) => {
      invalidateQuery(useGetUsersQuery);
      invalidateQuery(useGetUsersFullQuery);
      if (user.inviteUser.applicationUser) {
        onAdminInvited?.(user.inviteUser.applicationUser.id);
      }
      drawerProps.onClose?.({}, 'backdropClick');
      notifySuccess(formatMessage({ id: 'User invited' }));
    }
  });

  const onSubmit = (values: InviteAdminFormValues) => {
    inviteUserMutation.mutate({
      input: {
        ...values,
        phoneNumber: getNormalizedPhoneNumber(values.phoneNumber),
        phoneNumberExtension: Number(values.phoneNumberExtension.trim()) || undefined
      }
    });
  };

  const disabled = inviteUserMutation.isLoading;


  return (
    <FormDrawer
      {...drawerProps}
      showFooter
      header={formatMessage({ id: 'Invite an internal user' })}
      isFormDirty={isDirty}
      onSave={handleSubmit(onSubmit)}
      isSubmitting={inviteUserMutation.isLoading}
    >
      <Stack spacing={2} component='form' noValidate autoComplete='off' p={{ xs: 1, md: 2 }}>
        <Alert severity='info'>{formatMessage({ id: 'Looking to add a client to a project? Head over to the project members page.' })}</Alert>
        <Controller
          control={control}
          name='email'
          rules={{
            required: formatMessage({ id: 'This field is required' }),
            validate: validateEmail
          }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              label={formatMessage({ id: 'Email' })}
              required
              disabled={disabled}
              error={Boolean(error)}
              helperText={error?.message}
              {...field}
            />
          )}
        />

        <Controller
          control={control}
          name='firstName'
          rules={{ required: formatMessage({ id: 'This field is required' }) }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              label={formatMessage({ id: 'First name' })}
              required
              disabled={disabled}
              error={Boolean(error)}
              helperText={error?.message}
              {...field}
            />
          )}
        />

        <Controller
          control={control}
          name='lastName'
          rules={{ required: formatMessage({ id: 'This field is required' }) }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              label={formatMessage({ id: 'Last name' })}
              required
              disabled={disabled}
              error={Boolean(error)}
              helperText={error?.message}
              {...field}
            />
          )}
        />

        <Controller
          control={control}
          name='title'
          render={({ field }) => (
            <TextField
              disabled={disabled}
              label={formatMessage({ id: 'Title' })}
              {...field}
            />
          )}
        />


        <Stack direction='row'>
          <Controller
            control={control}
            name='phoneNumber'
            rules={{
              validate: validatePhoneNumber
            }}
            render={({ field, fieldState: { error } }) => (
              <MuiTelInput
                defaultCountry='CA'
                forceCallingCode
                disableDropdown
                flagSize="small"
                fullWidth
                disabled={disabled}
                label={formatMessage({ id: 'Phone number' })}
                {...field}
                error={Boolean(error)}
                helperText={error?.message}
              />
            )}
          />

          <Controller
            control={control}
            name='phoneNumberExtension'
            rules={{
              validate: {
                withPhoneNumber: (value: string, formValues) => {
                  if (value.trim() && !formValues.phoneNumber.trim()) {
                    return formatMessage({ id: 'Phone number is required' });
                  }
                  return true;
                },
                isPositiveInteger: (value: string) => {
                  if (value.trim() && !/^\d+$/.test(value)) {
                    return formatMessage({ id: 'Extension must be a number' });
                  }
                  return true;
                }
              },
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                disabled={disabled}
                label={formatMessage({ id: 'Extension' })}
                {...field}
                error={Boolean(error)}
                helperText={error?.message}
              />
            )}
          />
        </Stack>
        <Controller
          control={control}
          name='role'
          rules={{
            required: formatMessage({ id: 'This field is required' })
          }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              select
              label={formatMessage({ id: 'Role' })}
              {...field}
              value={field.value}
              error={Boolean(error)}
              helperText={error?.message ?? (roleDescription ? formatMessage(roleDescription) : undefined)}
              FormHelperTextProps={{ sx: { fontSize: '0.9em', color: 'text.primary' } }}
            >
              <option value='' disabled style={{ display: 'none', visibility: 'hidden' }} />
              {UserApplicationBuiltInRoles.map((role, index) => (
                <MenuItem key={index} value={role}>
                  {formatMessage(userRoleMessages[role])}
                </MenuItem>
              ))}
            </TextField>
          )}
        />
      </Stack>
    </FormDrawer>
  );
};