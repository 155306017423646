import { TextProperty } from '@components/DataDisplay/TextProperty';
import { PageSection } from '@components/PageSection/PageSection';
import { PageSectionPart } from '@components/PageSection/PageSectionPart';
import { Stack } from '@mui/material';
import { useGetProjectsQuery } from 'gql/index';
import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { FolderImportFoldersPicker } from './FoldersStep/FolderImportFoldersPicker';
import { FolderImportFormValues } from './types';

export const FolderImportSummaryStep: React.FC = () => {
  const { formatMessage } = useIntl();

  const { data: projects } = useGetProjectsQuery(undefined, { select: d => d.projects });

  const folderImportForm = useFormContext<FolderImportFormValues>();

  const { projectToImportFromId: folderImportProjectId, folderIds } = useWatch({ control: folderImportForm.control });

  const project = projects?.find(p => p.id === folderImportProjectId);

  return <>
    <Stack gap={2}>
      <PageSection>
        <PageSectionPart gap={2}>
          <TextProperty propertyName={formatMessage({ id: 'Project' })} propertyValue={project?.name ?? ''} />

          <TextProperty propertyName={formatMessage({ id: 'Number of folders' })} propertyValue={String(folderIds?.length ?? 0)} />
        </PageSectionPart>
      </PageSection>

      <FolderImportFoldersPicker displaySelectionOnly />
    </Stack>
  </>;
};