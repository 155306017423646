import { FormHelperText } from '@mui/material';
import { useIntl } from 'react-intl';
import { ActionConfigurationComponentProps } from '../../WorkflowEditor/Actions/types';
import { WorkflowFieldInput } from '../../WorkflowEditor/Fields/WorkflowFieldInput';
import { RequestUserToBookAMeetingDefinition } from './RequestUserToBookAMeetingDefinition';

export const RequestUserToBookAMeetingConfiguration: React.FC<ActionConfigurationComponentProps> = () => {
  const { formatMessage } = useIntl();

  return (
    <>
      <WorkflowFieldInput fieldId={RequestUserToBookAMeetingDefinition.FieldId_MeetingRequestedBy} label={formatMessage({ id: 'User who must book a meeting' })} />
      <FormHelperText>
        {formatMessage({ id: 'Upon starting the workflow in a project, you will be able to select a meeting type available in that project.' })}
      </FormHelperText>
    </>
  );
};