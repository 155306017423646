import { FormDrawer } from '@components/Drawers/FormDrawer';
import { useCurrentProject } from '@modules/projects/utils/useCurrentProject';
import { DrawerProps, InputAdornment, Stack, TextField } from '@mui/material';
import { useNotification } from '@utils/useNotification';
import { useQueryInvalidator } from '@utils/useQueryInvalidator';
import { useValidationRules } from '@utils/useValidationRules';
import { useEditProjectProgressMutation, useGetProjectQuery } from 'gql/index';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';

type OwnProps = DrawerProps & {
  progressValue?: string
};

interface FormValues {
  progressValue: string
}

const defaultValues = {
  progressValue: ''
};

export const EditProgressDrawer: React.FC<OwnProps> = ({ progressValue, ...props }) => {
  const { formatMessage } = useIntl();
  const { notifySuccess } = useNotification();
  const { projectId } = useCurrentProject();
  const { notEmpty, validateNumberRange, validateNumber } = useValidationRules();
  const invalidateQuery = useQueryInvalidator();

  const { mutate: editProgress, isLoading } = useEditProjectProgressMutation();
  const { reset, control, handleSubmit, formState: { isDirty } } = useForm<FormValues>({ defaultValues });

  const onSubmit = (value: FormValues) => {
    editProgress({
      input: {
        progress: Number(value.progressValue),
        projectId
      }
    }, {
      onSuccess: () => {
        invalidateQuery(useGetProjectQuery, { id: projectId });
        notifySuccess(formatMessage({ id: 'Project progress has been updated.' }));
        props.onClose?.({}, 'backdropClick');
      }
    });
  };

  useEffect(() => {
    if (progressValue) {
      reset({ progressValue });
    }
    else {
      reset(defaultValues);
    }
  }, [reset, progressValue, props.open]);

  return (
    <FormDrawer
      {...props}
      showFooter
      isSubmitting={isLoading}
      onSave={handleSubmit(onSubmit)}
      header={formatMessage({ id: 'Edit project progress' })}
      isFormDirty={isDirty}
    >
      <Stack p={2} gap={2}>

        <Controller
          control={control}
          name='progressValue'
          rules={{ validate: { notEmpty, validateNumber, validateNumberRange } }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}

              type='number'
              label={formatMessage({ id: 'Progress' })}
              error={!!error}
              helperText={error?.message}
              disabled={isLoading}
              fullWidth
              inputProps={{ style: { textAlign: 'right' } }}
              InputProps={{

                endAdornment: <InputAdornment position="end">%</InputAdornment>
              }}
            />
          )}
        />
      </Stack>
    </FormDrawer>
  );
};