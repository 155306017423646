import { CircularProgressWithLabel } from '@components/Progress/CircularProgressWithLabel';
import { Stack, Typography } from '@mui/material';

interface OwnProps {
  title: string,
  value?: number
}

export const ProgressWithTitle: React.FC<OwnProps> = ({ title, value }) => {
  return (
    <Stack alignItems='center'>
      <CircularProgressWithLabel value={value ?? 0} size={85}
        sx={{
          borderRadius: '50%',
          boxShadow: 'inset 0 0 0 5.7px #d1d1d1',
          color: theme => theme.palette.success.light
        }}
      />
      <Typography variant="body1">{title}</Typography>
    </Stack>
  );
};