import { BuiltInRoles, useGetMeQuery } from 'gql/index';
import { useMemo } from 'react';

export const useGetMe = () => {
  const { data: me, ...query } = useGetMeQuery(undefined, { select: d => d.me, staleTime: 120000 });

  const hasProjectAdminRole = useMemo(() => me?.builtInRoles.includes(BuiltInRoles.ProjectAdministrator), [me?.builtInRoles]);
  const hasMultiTenantAdminRole = useMemo(() => me?.builtInRoles.includes(BuiltInRoles.MultiTenantAdministrator), [me?.builtInRoles]);
  const hasContributorRole = useMemo(() => me?.builtInRoles.includes(BuiltInRoles.Contributor), [me?.builtInRoles]);
  const hasTenantAdminRole = useMemo(() => me?.builtInRoles.includes(BuiltInRoles.TenantAdministrator), [me?.builtInRoles]);
  const isExternalUser = useMemo(() => !hasProjectAdminRole && !hasTenantAdminRole && !hasContributorRole, [hasContributorRole, hasProjectAdminRole, hasTenantAdminRole]);
  const isLoggedIn = useMemo(() => query.isFetched && me?.id != null, [me?.id, query.isFetched]);
  const canCreateProject = useMemo(() => hasTenantAdminRole || hasProjectAdminRole, [hasProjectAdminRole, hasTenantAdminRole]);

  return { me, hasMultiTenantAdminRole, hasTenantAdminRole, hasContributorRole, canCreateProject, isExternalUser, canViewProjectsList: !isExternalUser, hasProjectAdminRole, isLoggedIn, ...query };
};