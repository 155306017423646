import { useCurrentProject } from '@modules/projects/utils/useCurrentProject';
import { PrincipalPicker } from '@modules/users/components/PrincipalPicker';
import { SecurityPrincipalShim } from '@modules/users/components/types';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { LoadingButton } from '@mui/lab';
import { Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, MenuItem, Radio, RadioGroup, Stack, TextField, Typography } from '@mui/material';
import { useNotification } from '@utils/useNotification';
import React, { useContext } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { AllowedRespondents, BuiltInRoles, PrincipalType, useShareProjectResourceMutation } from '../../../../../gql';
import { useValidationRules } from '../../../../../utils/useValidationRules';
import { userRoleMessages } from '../../../../application/messages';
import { useShareableResourceInvalidator } from '../../Permissions/invalidator';
import { ShareableResource } from '../../Permissions/types';
import { PermissionsModalContext } from '../PermissionsModal';

interface FormValues {
  shareWithAll: AllowedRespondents;
  userIds: string[];
  groupIds: string[];
  role: BuiltInRoles | '';
  message: string | '';
  sendNotificationEmail: boolean;
}

interface Props {
  resource: ShareableResource;
}

export const GrantAccessView: React.FC<Props> = ({ resource }) => {
  const { projectId: projectIdString } = useParams();
  const projectId = Number(projectIdString);
  const { formatMessage } = useIntl();
  const { isTemplate } = useCurrentProject();

  const { goToManageAccess } = useContext(PermissionsModalContext);

  const { notEmpty } = useValidationRules();
  const invalidator = useShareableResourceInvalidator(resource);
  const { notifySuccess } = useNotification();

  const defaultValues: FormValues = {
    shareWithAll: AllowedRespondents.SpecificUsersOrGroups,
    userIds: [],
    groupIds: [],
    role: BuiltInRoles.Reader,
    message: '',
    sendNotificationEmail: false
  };

  const { handleSubmit, control, setValue, register, formState: { errors: { userIds: userErrors } } } = useForm<FormValues>({ defaultValues });

  const onSharingSuccess = () => {
    invalidator();
    notifySuccess(formatMessage({ id: 'Resource shared successfully.' }));
    goToManageAccess();
  };

  const { mutate: shareResource, isLoading } = useShareProjectResourceMutation({ onSuccess: onSharingSuccess });

  const onSubmit = (values: FormValues) => {
    for (const userId of values.userIds) {
      shareResource({
        input: {
          principalId: userId,
          principalType: PrincipalType.User,
          projectId: projectId,
          resourceId: resource.id,
          resourceType: resource.resourceType,
          sendNotification: values.sendNotificationEmail,
          notificationMessage: values.message
        }
      });
    }

    for (const groupId of values.groupIds) {
      shareResource({
        input: {
          principalId: groupId,
          principalType: PrincipalType.SecurityGroup,
          projectId: projectId,
          resourceId: resource.id,
          resourceType: resource.resourceType,
          sendNotification: values.sendNotificationEmail,
          notificationMessage: values.message
        }
      });
    }

    if (values.shareWithAll === AllowedRespondents.AllProjectMembers) {
      shareResource({
        input: {
          principalId: 'all',
          principalType: PrincipalType.SharedWithAllProjectMembers,
          projectId: projectId,
          resourceId: resource.id,
          resourceType: resource.resourceType,
          sendNotification: values.sendNotificationEmail,
          notificationMessage: values.message
        }
      });
    }
  };

  const userIds = useWatch({ control, name: 'userIds' });
  const groupIds = useWatch({ control, name: 'groupIds' });
  const accessLevel = useWatch({ control, name: 'shareWithAll' });
  const shareWithAllProjectMembers = accessLevel === AllowedRespondents.AllProjectMembers;

  const value: SecurityPrincipalShim[] = [
    ...userIds.map<SecurityPrincipalShim>(id => ({ id, principalType: PrincipalType.User })),
    ...groupIds.map<SecurityPrincipalShim>(id => ({ id, principalType: PrincipalType.SecurityGroup }))
  ];

  const sendNotificationEmail = useWatch({ control, name: 'sendNotificationEmail' });

  register('userIds', {
    validate: (_, formValues) => {
      if (formValues.groupIds.length === 0 && formValues.userIds.length === 0 && !accessLevel) return formatMessage({ id: 'At least one user or group must be selected.' });
    }
  });

  return <Stack gap={2}>
    <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
      <Typography variant='h5'>{formatMessage({ id: 'Grant Access' })}</Typography>
    </Stack>

    <Stack direction={'row'} spacing={2} justifyContent={'left'} alignItems={'center'} onClick={() => goToManageAccess()} sx={{ cursor: 'pointer' }}>
      <ArrowBackIcon />
      <Typography>{formatMessage({ id: 'Manage Access' })}</Typography>
    </Stack>

    <Stack p={{ xs: 1, md: 2 }} gap={3}>
      <Controller
        control={control}
        name='shareWithAll'
        render={({ field }) => (
          <FormControl disabled={isLoading}>
            <FormLabel>{formatMessage({ id: 'Share with:' })}</FormLabel>
            <RadioGroup {...field}>
              <FormControlLabel
                disabled={isLoading}
                value={AllowedRespondents.AllProjectMembers}
                control={
                  <Radio />
                }
                label={formatMessage({ id: 'All project members' })}
              />
              <FormControlLabel
                disabled={isLoading}
                value={AllowedRespondents.SpecificUsersOrGroups}
                control={<Radio />}
                label={formatMessage({ id: 'A user or a group' })}
              />
            </RadioGroup>
          </FormControl>
        )}
      />


      {!shareWithAllProjectMembers && <>
        <PrincipalPicker
          label={formatMessage({ id: 'Groups / users' })}
          disabled={isLoading || shareWithAllProjectMembers}
          multiple
          value={value}
          error={userErrors?.at?.(0)?.message}
          onChange={principals => {
            const users = principals.filter(v => v.principalType === PrincipalType.User);
            const groups = principals.filter(v => v.principalType === PrincipalType.SecurityGroup);

            setValue('userIds', users.map(u => u.id));
            setValue('groupIds', groups.map(g => g.id));
          }}
        />

        <Controller
          control={control}
          name='role'
          rules={{
            validate: notEmpty
          }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              select
              // Currently no other roles are supported
              disabled={true || shareWithAllProjectMembers}
              SelectProps={{ disabled: true }}
              {...field}
              required
              label={formatMessage({ id: 'Role' })}
              error={!!error}
              helperText={error?.message}
            >
              <MenuItem value={BuiltInRoles.Reader}>
                {formatMessage(userRoleMessages[BuiltInRoles.Reader])}
              </MenuItem>
            </TextField>
          )}
        />

        {!isTemplate && (
          <Controller
            control={control}
            name='message'
            rules={{
              maxLength: { value: 250, message: formatMessage({ id: 'The message must be at most {count} characters.' }, { count: 250 }) }
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                multiline
                disabled={isLoading || !sendNotificationEmail || shareWithAllProjectMembers}
                minRows={6}
                maxRows={10}
                label={formatMessage({ id: 'Message' })}
                error={!!error}
                helperText={error?.message}
                InputLabelProps={{ shrink: true }}
                placeholder={formatMessage({ id: 'Type to personalize email notification...' })}
              />
            )}
          />
        )}
      </>}
    </Stack>

    <Stack direction='row' justifyContent={!isTemplate ? 'space-between' : 'end'}>
      {!isTemplate && (
        <Controller
          control={control}
          name='sendNotificationEmail'
          render={({ field }) => (
            <FormGroup sx={{ px: 2 }}>
              <FormControlLabel
                disabled={isLoading || shareWithAllProjectMembers}
                control={<Checkbox checked={field.value} onChange={e => field.onChange(e.target.checked)} />}
                label={formatMessage({ id: 'Send Notification' })}
              />
            </FormGroup>
          )}
        />
      )}

      <LoadingButton loading={isLoading} variant='contained' onClick={handleSubmit(onSubmit)}>{formatMessage({ id: 'Grant Access' })}</LoadingButton>
    </Stack>
  </Stack>;
};