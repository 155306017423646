import { ResponsiveDialog } from '@components/Dialogs/ResponsiveDialog';
import { DialogProps } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { MultiStepForm, MultiStepFormProps, MultiStepFormStep } from './MultiStepForm';

type Step = MultiStepFormStep & {
  title: string;
  subtitle?: string;
};

type Props = Omit<MultiStepFormProps, 'steps'> & {
  dialogProps: DialogProps;
  steps: (Step | boolean | undefined)[];
};

export const MultiStepFormDialog: React.FC<Props> = ({ dialogProps, steps: unfilteredSteps, ...multiStepFormProps }) => {
  const steps = useMemo(() => unfilteredSteps.filter(s => typeof s !== 'boolean' && s != null), [unfilteredSteps]);

  const [uncontrolledActiveStep, setUncontrolledActiveStep] = useState(0);

  const activeStep = multiStepFormProps.activeStep ?? uncontrolledActiveStep;
  const setActiveStep = multiStepFormProps.onStepChange ?? setUncontrolledActiveStep;

  const currentStep = useMemo(() => steps[activeStep], [activeStep, steps]);

  useEffect(() => {
    setActiveStep(0);
  }, [dialogProps.open, setActiveStep]);

  return (
    <ResponsiveDialog {...dialogProps} title={currentStep.title} subtitle={currentStep.subtitle}>
      <MultiStepForm
        steps={steps}
        activeStep={activeStep}
        onStepChange={setActiveStep}
        {...multiStepFormProps}
      />
    </ResponsiveDialog>
  );
};