import { SlideDialog } from '@components/Dialogs/SlideDialog';
import { DialogProps } from '@mui/material';
import { useFolderEntryQuery } from 'gql/index';
import React, { useContext } from 'react';
import { ProjectDocumentsContext } from '../ProjectDocumentsContextProvider';
import { DocumentPropertiesList } from './DocumentProperties/DocumentPropertiesList';

type Props = Omit<DialogProps, 'open' | 'onClose'>;

export const FolderEntryPropertiesDrawer: React.FC<Props> = ({ ...dialogProps }) => {
  const { selectedEntryId, setSelectedEntryId } = useContext(ProjectDocumentsContext);

  const { data: entry } = useFolderEntryQuery({ id: selectedEntryId || 0 }, { select: d => d.folderEntry, enabled: !!selectedEntryId });

  return (
    <SlideDialog topbar={{ title: entry?.document?.fileName ?? '' }} open={!!selectedEntryId} onClose={() => setSelectedEntryId?.(undefined)} {...dialogProps}>
      {entry?.document && (
        <DocumentPropertiesList documentId={entry.document.id} />
      )}
    </SlideDialog>
  );
};