import { Alert, AlertTitle } from '@mui/material';
import { useContext } from 'react';
import { useIntl } from 'react-intl';
import { WorkflowEditorContext } from './WorkflowEditorContext';

export const WorkflowEditorMainAlert = () => {
  const { errors, notConfiguredActionIds } = useContext(WorkflowEditorContext);
  const { formatMessage } = useIntl();

  if (Object.keys(errors).filter(actionId => notConfiguredActionIds.indexOf(Number(actionId)) === -1).length > 0) {
    return <Alert severity='error'>
      <AlertTitle>
        {formatMessage({ id: 'This workflow contains actions which require configuration.' })}
      </AlertTitle>
    </Alert>;
  }

  if (notConfiguredActionIds.filter(Boolean).length > 0) {
    return <Alert severity='warning'>
      <AlertTitle>
        {formatMessage({ id: 'This workflow contains unconfigured actions.' })}
      </AlertTitle>
    </Alert>;
  }

  return undefined;
};