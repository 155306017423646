import { useNotification } from '@utils/useNotification';
import { useQueryInvalidator } from '@utils/useQueryInvalidator';
import { useChildFoldersQuery, useFolderEntriesQuery, useFolderPermissionsQuery, useMoveFolderEntryMutation, useMoveFolderMutation, useProjectDocumentsQuery, useProjectFoldersQuery } from 'gql/index';
import { useContext } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { ProjectDocumentsContext } from '../components/ProjectDocumentsContextProvider';

interface Props {
  onSuccess?: () => void;
}

export const useProjectDocumentsMoveMutations = (props?: Props) => {
  const { projectId: projectIdString } = useParams();
  const projectId = Number(projectIdString) || 0;

  const { formatMessage } = useIntl();
  const { notifySuccess } = useNotification();
  const invalidateQuery = useQueryInvalidator();

  const { setMovingFolder, movingFolder, movingEntry, setMovingEntry } = useContext(ProjectDocumentsContext);

  const { mutate: moveFolder } = useMoveFolderMutation({
    onSuccess: (_, variables) => {
      notifySuccess(formatMessage({ id: 'Folder moved successfully' }));
      invalidateQuery(useProjectFoldersQuery, { projectId });
      invalidateQuery(useFolderPermissionsQuery, { folderId: movingFolder?.id, projectId });
      invalidateQuery(useChildFoldersQuery, { parentFolderId: movingFolder?.parentFolderId });
      invalidateQuery(useChildFoldersQuery, { parentFolderId: variables.input.destinationFolderId || undefined });
      setMovingFolder?.(undefined);
      props?.onSuccess?.();
    }
  });


  const { mutate: moveEntry } = useMoveFolderEntryMutation({
    onSuccess: (_, variables) => {
      notifySuccess(formatMessage({ id: 'Document moved successfully' }));
      invalidateQuery(useProjectDocumentsQuery, { projectId });
      invalidateQuery(useFolderEntriesQuery, { projectId, folderId: movingEntry?.folderId || undefined });
      invalidateQuery(useFolderEntriesQuery, { projectId, folderId: variables.input.destinationFolderId || undefined });
      setMovingEntry?.(undefined);
      props?.onSuccess?.();
    }
  });

  return { moveFolder, moveEntry };
};