import { Tooltip, TooltipProps } from '@mui/material';
import React, { ReactNode } from 'react';

export interface DisableableTooltipProps extends Omit<TooltipProps, 'title' | 'children'> {
  title?: React.ReactNode;
  disabled?: boolean;
  children: ReactNode;
  fullWidth?: boolean;
}

export const DisableableTooltip: React.FC<DisableableTooltipProps> = ({ title, disabled, children, fullWidth, ...tooltipProps }) => {
  const shouldRenderTooltip = !disabled && title != null;

  // We must wrap the children with span in case there's a disabled element
  // which won't trigger the tooltip properly: https://mui.com/material-ui/react-tooltip/#disabled-elements
  return shouldRenderTooltip ? (
    <Tooltip title={title} {...tooltipProps}>
      <span style={fullWidth ? { width: '100%', height: '100%' } : undefined}>
        {children}
      </span>
    </Tooltip>
  ) : children;
};