import { ListItemText, Stack, Switch, TextField } from '@mui/material';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { NewProjectFormValues } from './AddProjectDialog';
import { NewProjectType } from './types';

export const NewProjectStep = () => {

  const { formatMessage } = useIntl();
  const { control } = useFormContext<NewProjectFormValues>();
  const projectType = useWatch({ control: control, name: 'projectType' });
  return (<>
    <Controller
      control={control}
      name='name'
      rules={{ required: formatMessage({ id: 'This field is required' }) }}
      render={({ field, fieldState: { error } }) => (
        <TextField
          autoFocus
          {...field}
          required
          label={formatMessage({ id: 'Name' })}
          error={Boolean(error)}
          helperText={error?.message}
        />
      )}
    />

    <Controller
      control={control}
      name='description'
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          label={formatMessage({ id: 'Description' })}
          error={Boolean(error)}
          helperText={error?.message}
        />
      )}
    />

    {projectType == NewProjectType.NewProject && <Controller
      control={control}
      name='optInToBudgetFeature'
      render={({ field }) => (
        <Stack direction='row' justifyContent='space-between' alignItems='center'>
          <ListItemText
            primary={formatMessage({ id: 'Opt in to budget feature' })}
            secondary={formatMessage({ id: 'This setting can be changed in the future.' })}
          />
          <Switch checked={field.value} onChange={(_, checked) => field.onChange(checked)} />
        </Stack>
      )}
    />}
  </>
  );
};