import '@fontsource/dancing-script';
import { ThemeOptions } from '@mui/material';
import type { } from '@mui/x-data-grid-pro/themeAugmentation';
import { merge } from 'lodash';

const printOptions: ThemeOptions = {
  components: {
    MuiDataGrid: {
      styleOverrides: {
        cellContent: {
          whiteSpace: 'pre-wrap',
          wordBreak: 'break-all',
          fontSize: '8px'
        },
        columnHeaders: {
          maxHeight: 'none !important',
        },
        columnHeader: {
          height: 'auto !important',
        },
        columnHeaderTitle: {
          whiteSpace: 'pre-wrap',
          lineHeight: 'normal'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          display: 'none'
        }
      }
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          display: 'none'
        }
      }
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          display: 'none'
        }
      }
    },
    MuiAccordion: {
      defaultProps: {
        classes: { root: 'break-after-page' },
      },
      styleOverrides: {
        root: {
          border: '1px solid black',
          boxShadow: 'none',
          '.MuiAccordionSummary-expandIconWrapper': {
            display: 'none'
          }
        },
      }
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          maxWidth: '100%',
          padding: 0,
        }
      }
    }
  }
};

const commonPalette = {
  primary: {
    main: '#2196F3'
  },
  secondary: {
    main: '#222F5C'
  },
  error: {
    main: '#D32F2F'
  },
  warning: {
    main: '#ED6C02'
  },
  info: {
    main: '#0288D1'
  },
  success: {
    main: '#2E7D32'
  }
};

export const commonThemeOptions: ThemeOptions = {
  palette: commonPalette,
  components: {

    MuiTabs: {
      defaultProps: {
        sx: {
          minHeight: 40,
          height: 40
        }
      }
    },
    MuiTab: {
      defaultProps: {
        sx: {
          minHeight: 40,
          height: 40
        }
      }
    },
    MuiLink: {
      styleOverrides: {
        root: {
          cursor: 'pointer',
        }
      }
    },

    MuiButton: {
      defaultProps: {
        variant: 'outlined',
      }
    },
    MuiTextField: {
      defaultProps: {
        variant: 'outlined'
      },
    },
    MuiDataGrid: {
      defaultProps: {
        columnHeaderHeight: 44,
      },
      styleOverrides: {
        columnHeader: {

          userSelect: 'none',
          ':focus': {
            outline: 'none'
          }
        },
        pinnedColumnHeaders: {

          boxShadow: 'none',
          backgroundImage: 'none',
        },
        pinnedColumns: {
          '& .MuiDataGrid-cell:focus': {
            padding: 0,
            outline: 'none !important',
          }
        },
        overlay: {
          backgroundColor: 'transparent'
        },
        'cell--editing': {
          border: `1px solid ${commonPalette.primary.main}`,
          // The last row in the table hides the bottom border color
          borderBottomColor: `${commonPalette.primary.main} !important`
        }
      }
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          '& ::-webkit-scrollbar': {
            width: 8
          },
          '& ::-webkit-scrollbar-track': {
            backgroundColor: 'rgba(0, 0, 0, 0.12)',
          },
          '& ::-webkit-scrollbar-thumb': {
            backgroundColor: '#2196F3',
          }
        }
      }
    }
  }
};

export const lightTheme: ThemeOptions = {
  palette: {
    mode: 'light',
    background: {
      default: '#FBFBFB',
      paper: '#FFFFFF'
    }
  },
  components: {
    MuiList: {
      styleOverrides: {
        root: {
          backgroundColor: '#FFF',
        }
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: '#FFF'
        }
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#FFF'
        }
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          color: '#000'
        }
      }
    }
  }
};

export const darkTheme: ThemeOptions = {
  palette: {
    mode: 'dark',
    background: {
      default: '#252a31',
      paper: '#2f343c'
    }
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: '#31373e'
        }
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          backgroundColor: '#31373e',
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          colorScheme: 'dark'
        }
      }
    }
  }
};


export const allThemes = {
  default: merge({}, lightTheme, commonThemeOptions),
  light: merge({}, lightTheme, commonThemeOptions),
  dark: merge({}, darkTheme, commonThemeOptions),
  print: merge({}, lightTheme, commonThemeOptions, printOptions)
};