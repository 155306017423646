import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { ErrorBoundaryPage } from '@modules/application/views/ErrorBoundaryPage';
import { ErrorResponse } from '@remix-run/router';
import React from 'react';
import { useRouteError } from 'react-router';
import { isRouteErrorResponse } from 'react-router-dom';
import appInsights from '../Telemetry';
import { NotFoundPage } from '../views/NotFoundPage';

const isErrorResponse = (error: unknown): error is ErrorResponse => isRouteErrorResponse(error);

export const RouterErrorBoundary: React.FC<{ children?: React.ReactNode; }> = () => {
  const error = useRouteError();

  if (error instanceof Error) {
    appInsights.trackException({
      error: error,
      exception: error,
      severityLevel: SeverityLevel.Error,
    });
  }

  if (isErrorResponse(error) && error.status === 404)
    return <NotFoundPage />;

  return <ErrorBoundaryPage />;
};