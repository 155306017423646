import { ActionMenu } from '@components/ActionMenu';
import { ConfirmDialog } from '@components/ConfirmDialog';
import { useCurrentProject } from '@modules/projects/utils/useCurrentProject';
import { Delete, Edit } from '@mui/icons-material';
import { ListItemIcon, MenuItem } from '@mui/material';
import { useNotification } from '@utils/useNotification';
import { useQueryInvalidator } from '@utils/useQueryInvalidator';
import { MeetingTypesQuery, useDeleteMeetingTypeMutation, useMeetingTypesQuery } from 'gql/index';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { MeetingTypeDrawer } from './MeetingTypeDrawer';

interface Props {
  meetingType: MeetingTypesQuery['meetingTypes'][number];
}

export const MeetingTypeActions: React.FC<Props> = ({ meetingType }) => {
  const { formatMessage } = useIntl();
  const { notifySuccess } = useNotification();
  const invalidateQuery = useQueryInvalidator();
  const { projectId, canManageProject } = useCurrentProject();

  const [isEditingMeetingType, setIsEditingMeetingType] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const { mutate: deleteMeetingType, isLoading } = useDeleteMeetingTypeMutation({
    onSuccess: () => {
      invalidateQuery(useMeetingTypesQuery);
      notifySuccess(formatMessage({ id: 'Deleted meeting type successfully' }));
    }
  });

  if (!canManageProject) {
    return undefined;
  }

  return <>
    <ActionMenu>
      <MenuItem onClick={() => setIsEditingMeetingType(true)}>
        <ListItemIcon>
          <Edit />
        </ListItemIcon>
        {formatMessage({ id: 'Edit' })}
      </MenuItem>
      <MenuItem onClick={() => setIsDeleting(true)}>
        <ListItemIcon>
          <Delete />
        </ListItemIcon>
        {formatMessage({ id: 'Delete' })}
      </MenuItem>
    </ActionMenu>

    <MeetingTypeDrawer
      meetingType={meetingType}
      open={isEditingMeetingType}
      onClose={() => setIsEditingMeetingType(false)}
    />

    <ConfirmDialog
      open={isDeleting}
      onCancel={() => setIsDeleting(false)}
      title={formatMessage({ id: 'Delete meeting type?' })}
      confirmText={formatMessage({ id: 'Delete' })}
      confirmColor='error'
      content={formatMessage({ id: 'This action cannot be undone. Meetings created of this type will remain.' })}
      loading={isLoading}
      onConfirm={() => deleteMeetingType({ input: { id: meetingType.id, projectId } })}
    />
  </>;
};