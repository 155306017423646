import { useIntl } from 'react-intl';
import { RouterProvider as ReactRouterProvider, createBrowserRouter } from 'react-router-dom';
import routes from 'src/routes';
import { BatchCompleted, UploadManagerContextProvider } from '../../../components/FileUpload/UploadManagerContext';
import { useNotification } from '../../../utils/useNotification';

const router = createBrowserRouter(routes);

export const RouterProvider: React.FC = () => {
  const { notifyFailure, notifySuccess } = useNotification();
  const { formatMessage } = useIntl();

  const onBatchComplete = (batch: BatchCompleted) => {
    if (batch.allAborted) return;

    if (batch.hasErrors) {
      notifyFailure(formatMessage({ id: 'File upload terminated with errors.' }));
    } else {
      notifySuccess(formatMessage({ id: 'File upload(s) completed successfully.' }));
    }
  };

  return (
    <UploadManagerContextProvider onBatchComplete={onBatchComplete}>
      <ReactRouterProvider router={router} />
    </UploadManagerContextProvider>
  );
};