import dayjs from 'dayjs';
import { isPossiblePhoneNumber } from 'libphonenumber-js';
import { useIntl } from 'react-intl';

export const isPhoneNumberValid = (value: string) => isPossiblePhoneNumber(value, 'CA');

export const isEmailValid = (value: string | undefined | null) => value != null && /\b[A-z0-9._%+-]+@[A-z0-9.-]+\.[A-z]{2,}\b/.test(value);

export const isValidDecimalNumber = (value?: string | null) => !!value && /^-?\d*(\d+\.\d+)?$/.test(value);

const isValidUrl = (url: string) => {
  try {
    new URL(url);
    return true;
  } catch {
    return false;
  }
};

type InputValue = string | null | undefined;

export const getNormalizedPhoneNumber = (value: string) => {
  if (!value) return undefined;
  if (value.trim() === '+1' || value.trim() === '') return;
  return isPhoneNumberValid(value) ? value : undefined;
};

export const useValidationRules = () => {
  const { formatMessage } = useIntl();

  const validatePhoneNumber = (value: InputValue) => {
    if (!value) return undefined;
    if (value.trim() === '+1') return;
    return isPhoneNumberValid(value) ? undefined : formatMessage({ id: 'Not a valid phone number' });
  };

  const validateEmail = (value: InputValue) => {

    return isEmailValid(value) ? undefined : formatMessage({ id: 'Not a valid email' });
  };

  const notEmpty = (value: InputValue | boolean | number | '' | unknown[]) => {
    if (!Array.isArray(value)) {
      return (value === true || value === false || value) ? undefined : formatMessage({ id: 'This field is required' });
    } else {
      return value.length > 0 ? undefined : formatMessage({ id: 'This field is required' });
    }
  };

  const validateNumber = (value: InputValue) => {
    if (!value) return;
    return isValidDecimalNumber(value) ? undefined : formatMessage({ id: 'Not a valid number' });
  };

  const validateIsPositive = (value: InputValue) => {
    if (!value) return;
    return Number(value) > 0 ? undefined : formatMessage({ id: 'Value must be positive' });
  };

  const validateNumberRange = (value: InputValue) => {
    if (!value) return;
    return Number(value) > 0 && Number(value) <= 100 ? undefined : formatMessage({ id: 'Value must be between 0 and 100.' });
  };

  const validateDateInFuture = (value: InputValue) => {
    if (!value) return;
    return dayjs(value).isAfter(dayjs().startOf('day')) ? undefined : formatMessage({ id: 'Date must be in the future.' });
  };

  const validateTenantIdentifier = (value: InputValue) => {

    if (!value) return;

    const cannotStartWithDash = (value: string) => value[0] === '-';
    const cannotHaveMoreThanOneDashInARow = (value: string) => /(-)\1/.test(value);
    const cannotEndWithDash = (value: string) => value[value.length - 1] === '-';
    const cannotHaveSpecialCharacters = (value: string) => /[^a-z-]/.test(value);

    if (cannotStartWithDash(value)) return formatMessage({ id: 'Tenant identifier cannot start with a dash' });
    if (cannotHaveMoreThanOneDashInARow(value)) return formatMessage({ id: 'Tenant identifier cannot have more than one dash in a row' });
    if (cannotEndWithDash(value)) return formatMessage({ id: 'Tenant identifier cannot end with a dash' });
    if (cannotHaveSpecialCharacters(value)) return formatMessage({ id: 'Tenant identifier can only have alphabetic characters' });

    return undefined;
  };

  const validateUrl = (value: InputValue) => {
    if (!value) return;
    return isValidUrl(value) ? undefined : formatMessage({ id: 'Not a valid URL' });
  };

  return {
    validatePhoneNumber,
    validateEmail,
    validateNumber,
    validateNumberRange,
    validateTenantIdentifier,
    validateUrl,
    notEmpty,
    validateIsPositive,
    validateDateInFuture
  };
};