import { DisableableTooltip } from '@components/Tooltips/DisableableTooltip';
import { UserAvatar } from '@components/UserAvatar/UserAvatar';
import { useCurrentProject } from '@modules/projects/utils/useCurrentProject';
import { InfoOutlined } from '@mui/icons-material';
import { AutocompleteRenderGetTagProps, Box, Chip } from '@mui/material';
import { PrincipalType } from 'gql/index';
import { useIntl } from 'react-intl';
import { isRemovedPrincipal, removedPrincipalLabelByType } from '.';
import { PrincipalPickerValue } from './PrincipalPicker';

interface Props {
  onInviteClicked: () => void;
  getTagProps: AutocompleteRenderGetTagProps;
  principal: PrincipalPickerValue;
  index: number;
}

export const PrincipalPickerChip: React.FC<Props> = ({ getTagProps, principal: option, index, onInviteClicked }) => {
  const { formatMessage } = useIntl();

  const { canManageProject } = useCurrentProject();

  if (isRemovedPrincipal(option)) {
    return (
      <Chip
        avatar={(
          <Box color='warning.main'>
            <InfoOutlined sx={{ color: 'warning.main' }} />
          </Box>
        )}
        label={formatMessage(removedPrincipalLabelByType[option.principalType])}
        {...getTagProps({ index })}
      />
    );
  } else if (typeof option === 'string') {
    return (
      <DisableableTooltip disabled={!canManageProject} arrow disableInteractive title={formatMessage({ id: 'No user was found. You may click to invite this user.' })}>
        <Chip
          clickable={canManageProject}
          onClick={canManageProject ? onInviteClicked : undefined}
          avatar={(
            <Box color='warning.main'>
              <InfoOutlined sx={{ color: 'warning.main' }} />
            </Box>
          )}
          label={option}
          {...getTagProps({ index })}
        />
      </DisableableTooltip>
    );
  } else if (option.principalType === PrincipalType.User) {
    return (
      <Chip
        avatar={(
          <UserAvatar displayName={option.fullName} />
        )}
        label={option.fullName}
        {...getTagProps({ index })}
      />
    );
  } else if (option.principalType === PrincipalType.SecurityGroup) {
    return (
      <Chip
        avatar={(
          <UserAvatar displayName={option.name} />
        )}
        label={option.name}
        {...getTagProps({ index })}
      />
    );
  }
};