import ExpandMore from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, TextFieldProps, Typography } from '@mui/material';
import { useMoneyFormatter } from '@utils/numberUtils';
import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { MortgageCalculatorForm, MortgageCalculatorScenario } from '../types';
import { EmptyRow } from './CalculatorComponents/EmptyRow';
import { HeaderCell } from './CalculatorComponents/HeaderCell';
import { ValueCell } from './CalculatorComponents/ValueCell';
import { ValueRow } from './CalculatorComponents/ValueRow';

const SmallNumberFieldProps: TextFieldProps = { sx: { width: '15ch' }, type: 'number', inputProps: { step: '0.01' } };

type Props = {
  defaultValues: MortgageCalculatorForm,
  onCalculatorUpdated: (values: MortgageCalculatorForm) => void;
  purchasePrice: number;
};

export const MortgageCalculator: React.FC<Props> = ({ defaultValues, onCalculatorUpdated, purchasePrice }) => {
  const { control, setValue, getValues, handleSubmit, reset } = useForm<MortgageCalculatorForm>({ defaultValues });
  const { formatMessage, formatNumber } = useIntl();

  const [expanded, setExpanded] = useState(false);

  const { fields } = useFieldArray({ control: control, name: 'scenarios' });
  const scenarios = fields;

  const formatMoney = useMoneyFormatter();
  const [prevPurchasePrice, setPrevPurchasePrice] = useState<number | undefined>();

  const recalculateScenario = useCallback((index: number) => {
    const scenario = getValues(`scenarios.${index}`);
    const loanAmount = purchasePrice * (1 - scenario.downPaymentPercent);

    const compoundingFrequency = 2; // canadian mortgages compound 2 times per year
    const effectiveAnnualRate = (1 + scenario.annualInterestRate / compoundingFrequency) ** compoundingFrequency - 1;
    const amortizationMonths = scenario.amortizationPeriod * 12;
    const monthlyRate = (1 + effectiveAnnualRate) ** (1 / 12) - 1;
    const monthlyPayment = monthlyRate * loanAmount / (1 - (1 + monthlyRate) ** -amortizationMonths);
    const totalPayment = monthlyPayment * amortizationMonths;
    const totalInterestsPayment = totalPayment - loanAmount;

    const newValue: MortgageCalculatorScenario = {
      ...scenario,
      loanAmount: loanAmount,
      monthlyInterestRate: monthlyRate * 100,
      monthlyPayment: monthlyPayment,
      annualPayment: monthlyPayment * 12,
      totalPayment: totalPayment,
      totalInterestsPayment: totalInterestsPayment
    };

    setValue(`scenarios.${index}`, newValue);

    if (JSON.stringify(scenario) != JSON.stringify(newValue)) {
      handleSubmit(onCalculatorUpdated)();
    }
  }, [getValues, handleSubmit, onCalculatorUpdated, purchasePrice, setValue]);


  useEffect(() => {
    if (prevPurchasePrice != null && prevPurchasePrice != purchasePrice) {
      getValues('scenarios').forEach((_, index) => recalculateScenario(index));
    }
    setPrevPurchasePrice(purchasePrice);
  }, [getValues, prevPurchasePrice, purchasePrice, recalculateScenario]);

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  return <Accordion expanded={expanded} onChange={(_, expanded) => setExpanded(expanded)}>
    <AccordionSummary expandIcon={<ExpandMore />}>
      <Typography variant='h4'>{formatMessage({ id: 'Step 2 - Mortgage Calculator' })}</Typography>
    </AccordionSummary>
    <AccordionDetails>
      <TableContainer component={Box}>
        <Table sx={{ minWidth: 650, borderSpacing: '16px 0', borderCollapse: 'separate' }} aria-label="simple table" >
          <TableHead>
            <TableRow >
              <TableCell key='firstColumn' sx={{ border: 0 }}></TableCell>
              <HeaderCell key='scenario-1' align="center">{formatMessage({ id: 'Scenario {id}' }, { id: 1 })}</HeaderCell>
              <HeaderCell key='scenario-2' align="center">{formatMessage({ id: 'Scenario {id}' }, { id: 2 })}</HeaderCell>
              <HeaderCell key='scenario-3' align="center">{formatMessage({ id: 'Scenario {id}' }, { id: 3 })}</HeaderCell>
            </TableRow>
          </TableHead>

          <TableBody>

            <EmptyRow key='mortgageInfoHeader' header={formatMessage({ id: 'Mortgage information' })} />

            <ValueRow key={'cashDown'} header={formatMessage({ id: 'Down payment' })}>
              {scenarios.map((scenario, index) => (
                <Controller
                  key={scenario.id}
                  control={control}
                  name={`scenarios.${index}.downPaymentPercent`}
                  render={({ field }) => (
                    <ValueCell align="right">
                      <Stack direction={'row'} spacing={2} justifyContent={'right'}>
                        <TextField
                          InputProps={{ endAdornment: '%' }}
                          {...SmallNumberFieldProps}
                          inputProps={{ min: 0, max: 100 }}
                          value={field.value * 100}
                          onChange={e => {
                            setValue(`scenarios.${index}.downPaymentPercent`, Number(e.currentTarget.value) / 100);
                            recalculateScenario(index);
                          }}
                        />
                        <TextField
                          disabled
                          type='text'
                          sx={{ width: '20ch' }}
                          inputProps={{ step: '1' }}
                          InputProps={{ endAdornment: '$' }}
                          value={isNaN(field.value)
                            ? ''
                            : formatMoney(field.value * purchasePrice)}
                        />
                      </Stack>
                    </ValueCell>
                  )}
                />
              ))}
            </ValueRow>
            <ValueRow key={'loanAmount'} header={formatMessage({ id: 'Loan amount' })}>
              {scenarios.map((scenario, index) => (
                <Controller
                  key={scenario.id}
                  control={control}
                  name={`scenarios.${index}.loanAmount`}
                  render={({ field }) => (
                    <ValueCell align="right">
                      {formatMoney(field.value)}
                    </ValueCell>
                  )}
                />
              ))}
            </ValueRow>
            <ValueRow key={'interestRateAnnual'} header={formatMessage({ id: 'Interest rate' })}>
              {scenarios.map((scenario, index) => (
                <Controller
                  key={scenario.id}
                  control={control}
                  name={`scenarios.${index}.annualInterestRate`}
                  render={({ field }) => (
                    <ValueCell align="right">
                      <TextField
                        {...SmallNumberFieldProps}
                        inputProps={{ step: '0.01', max: 100 }}
                        value={field.value * 100}
                        InputProps={{ endAdornment: '%' }}
                        onChange={e => {
                          setValue(`scenarios.${index}.annualInterestRate`, (Number(e.currentTarget.value) / 100));
                          recalculateScenario(index);
                        }}
                      />
                    </ValueCell>
                  )}
                />
              ))}
            </ValueRow>
            <ValueRow key={'amortizationYear'} header={formatMessage({ id: 'Amortization' })} isLast>
              {scenarios.map((scenario, index) => (
                <Controller
                  key={scenario.id}
                  control={control}
                  name={`scenarios.${index}.amortizationPeriod`}
                  render={({ field }) => (
                    <ValueCell align="right" isLast>
                      <TextField
                        {...SmallNumberFieldProps}
                        {...field}
                        inputProps={{ step: '0' }}
                        InputProps={{ endAdornment: formatMessage({ id: 'years' }) }}
                        onChange={e => { field.onChange(e); recalculateScenario(index); }} />
                    </ValueCell>
                  )}
                />
              ))}
            </ValueRow>

            <EmptyRow key='Payment' header={formatMessage({ id: 'Payment' })} />

            <ValueRow key={'monthlyRate'} header={formatMessage({ id: 'Monthly rate' })}>
              {scenarios.map((scenario, index) => (
                <Controller
                  key={scenario.id}
                  control={control}
                  name={`scenarios.${index}.monthlyInterestRate`}
                  render={({ field }) => (
                    <ValueCell key={scenario.id} align="right">
                      <Typography>
                        {isNaN(field.value) ? '-' : formatNumber(field.value, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%'}
                      </Typography>
                    </ValueCell>
                  )}
                />
              ))}
            </ValueRow>
            <ValueRow key={'monthlyPayment'} header={formatMessage({ id: 'Monthly payment' })} isLast>
              {scenarios.map((scenario, index) => (
                <Controller
                  key={scenario.id}
                  control={control}
                  name={`scenarios.${index}.monthlyPayment`}
                  render={({ field }) => (
                    <ValueCell align="right" isLast>
                      <Typography>
                        {formatMoney(field.value)}
                      </Typography>
                    </ValueCell>
                  )}
                />
              ))}
            </ValueRow>

            <EmptyRow key='totals' header={formatMessage({ id: 'Totals' })} />

            <ValueRow key={'numberOfPayments'} header={formatMessage({ id: 'Number of payments' })}>
              {scenarios.map((scenario, index) => (
                <Controller
                  key={scenario.id}
                  control={control}
                  name={`scenarios.${index}.amortizationPeriod`}
                  render={({ field }) => <ValueCell align="right">
                    <Typography>
                      {field.value * 12}
                    </Typography>
                  </ValueCell>}
                />
              ))}
            </ValueRow>
            <ValueRow key={'totalPayment'} header={formatMessage({ id: 'Total payment' })}>
              {scenarios.map((scenario, index) => (
                <Controller
                  key={scenario.id}
                  control={control}
                  name={`scenarios.${index}.totalPayment`}
                  render={({ field }) => <ValueCell align="right">
                    <Typography>
                      {formatMoney(field.value)}
                    </Typography>
                  </ValueCell>}
                />
              ))}
            </ValueRow>
            <ValueRow key={'totalInterest'} header={formatMessage({ id: 'Total interest' })}>
              {scenarios.map((scenario, index) => (
                <Controller
                  key={scenario.id}
                  control={control}
                  name={`scenarios.${index}.totalInterestsPayment`}
                  render={({ field }) => <ValueCell align="right">
                    <Typography>
                      {formatMoney(field.value)}
                    </Typography>
                  </ValueCell>}
                />
              ))}
            </ValueRow>
          </TableBody>
        </Table>
      </TableContainer>
    </AccordionDetails>
  </Accordion>;
};