import { DesktopDatePicker } from '@mui/x-date-pickers';
import { useValidationRules } from '@utils/useValidationRules';
import dayjs, { Dayjs } from 'dayjs';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormFillerValues } from '../types';
import { FieldFillerProps } from './FieldFiller';
import { useFieldPresenceUpdateEvents } from './useFieldPresenceUpdateEvents';
import { useFormFieldFillerForm } from './useFormFieldFillerForm';

export const DateFiller: React.FC<FieldFillerProps> = ({ index, field: formField, disabled }) => {
  const { notEmpty } = useValidationRules();

  const { control, getValues } = useFormContext<FormFillerValues>();

  const updatePresenceEvents = useFieldPresenceUpdateEvents(formField.id);
  const { onSubmit } = useFormFieldFillerForm();

  return (
    <Controller
      key={formField.id}
      control={control}
      name={`values.${index}.date`}
      rules={{
        validate: {
          ...formField.isRequired && { notEmpty }
        }
      }}
      render={({ field, fieldState: { error } }) => (
        <DesktopDatePicker<Dayjs>
          key={formField.id}
          {...field}
          {...updatePresenceEvents}
          value={dayjs(field.value)}
          onChange={day => {
            const dateValue = day?.isValid() ? day.utc().format() : '';
            field.onChange(dateValue);
            setTimeout(() => onSubmit({ ...getValues(`values.${index}`), date: dateValue }));
          }}
          disabled={disabled}
          label={formField.name}
          slotProps={{
            textField: {
              required: formField.isRequired,
              error: Boolean(error),
              helperText: error?.message,
              ...updatePresenceEvents,
            },
            openPickerButton: {
              color: error ? 'error' : undefined,
            }
          }}
        />
      )}
    />
  );
};