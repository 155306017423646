import { PageSection } from '@components/PageSection/PageSection';
import { PageSectionSkeleton } from '@components/Skeletons/PageSectionSkeleton';
import { useCurrentProject } from '@modules/projects/utils/useCurrentProject';
import { Timeline } from '@mui/icons-material';
import { List } from '@mui/material';
import { WorkFlowStatus, useWorkflowInstancesQuery } from 'gql/index';
import { useIntl } from 'react-intl';
import { WorkflowListItem } from './WorkflowListItem';

export const WorkflowSection: React.FC = () => {
  const { formatMessage } = useIntl();

  const { projectId } = useCurrentProject();

  const { data: workflows, isFetching } = useWorkflowInstancesQuery({
    projectId, filter: {
      workFlowStatus: {
        eq: WorkFlowStatus.InProgress
      }
    }
  }, { select: d => d.projectWorkflows });

  if (!workflows && isFetching) {
    return (
      <PageSectionSkeleton icon={<Timeline />} title={formatMessage({ id: 'Workflows' })} />
    );
  }

  return (
    <PageSection
      title={formatMessage({ id: 'Workflows' })}
      icon={<Timeline />}
      emptyState={formatMessage({ id: 'There are no workflow in progress.' })}
    >
      {(workflows?.length ?? 0 > 0) &&
        <List disablePadding sx={{ borderTop: t => `1px solid ${t.palette.divider}`, borderBottom: t => `1px solid ${t.palette.divider}` }}>
          {workflows?.map(workflow =>
            <WorkflowListItem key={workflow.id} workflow={workflow} />
          )}
        </List>
      }
    </PageSection>
  );
};