import { EditorSavingStatus } from '@components/Editor/TopBar/SavingStatusContext';
import { Chip, ChipProps, styled } from '@mui/material';
import React from 'react';
import { useIntl } from 'react-intl';

interface Props {
  status: EditorSavingStatus;
}

const StyledChip = styled(Chip)(({ theme }) => ({
  borderColor: 'inherit',
  color: theme.palette.primary.contrastText
}));

export const SavingStatusChip: React.FC<Props> = ({ status }) => {
  const { formatMessage } = useIntl();

  const commonProps: Partial<ChipProps> = {
    size: 'small'
  };

  const chipPropsByStatus: Record<EditorSavingStatus, ChipProps> = {
    [EditorSavingStatus.Saved]: { label: formatMessage({ id: 'Saved' }), color: 'success' },
    [EditorSavingStatus.Saving]: { label: formatMessage({ id: 'Saving...' }), color: 'info' },
    [EditorSavingStatus.Failed]: { label: formatMessage({ id: 'Save failed' }), color: 'error' }
  };

  return (<StyledChip {...chipPropsByStatus[status]} {...commonProps} />);
};