import { ActionMenu } from '@components/ActionMenu';
import { useProjectDocumentsUploadManager } from '@components/FileUpload/useProjectDocumentsUploadManager';
import { FolderImportDrawer } from '@modules/projects/components/TaskImportDrawer/FolderImportDrawer';
import { useCurrentProject } from '@modules/projects/utils/useCurrentProject';
import { Add, CreateNewFolder, DriveFileMove, DriveFolderUpload, Link, UploadFile } from '@mui/icons-material';
import { Divider, ListItemIcon, MenuItem, Stack } from '@mui/material';
import { useResponsive } from '@utils/useResponsive';
import { RefObject, useContext, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useIntl } from 'react-intl';
import { AddExternalLinkDialog } from '../AddExternalLinkDialog';
import { AddFolderDialog } from '../AddFolderDialog';
import { ProjectDocumentsContext } from '../ProjectDocumentsContextProvider';

interface Props {
  folderUploadRef: RefObject<HTMLInputElement>;
}

export const AddButtonMenu = ({ folderUploadRef }: Props) => {
  const { isArchived, canManageDocuments, canUploadDocuments, projectId } = useCurrentProject();
  const [isAddingFolder, setIsAddingFolder] = useState(false);

  const [isAddingExternalLink, setIsAddingExternalLink] = useState(false);
  const [isImportingFolder, setIsImportingFolder] = useState(false);
  const { formatMessage } = useIntl();
  const { isMobile } = useResponsive();

  const uploadFolderClicked = () => {
    folderUploadRef.current?.click();
  };


  const { currentFolderId } = useContext(ProjectDocumentsContext);
  const { queueFiles } = useProjectDocumentsUploadManager(`project-${projectId}`);

  const { open: uploadFile, getInputProps, getRootProps } = useDropzone({
    noClick: true,
    noKeyboard: true,
    disabled: !canUploadDocuments,
    onDropAccepted: files => queueFiles(files, currentFolderId),
  });

  if (isArchived || (!canUploadDocuments && !canManageDocuments)) return undefined;

  return <>
    <Stack
      direction='row'
      flexWrap='wrap'

      gap={2}
      {...getRootProps}
    >
      <ActionMenu variant='button' buttonProps={{ children: formatMessage({ id: 'Add' }), size: 'medium', variant: 'contained', startIcon: <Add /> }}>
        {canUploadDocuments &&
          <MenuItem onClick={uploadFile}>
            <ListItemIcon><UploadFile /></ListItemIcon>
            {formatMessage({ id: 'Upload file' })}
          </MenuItem>
        }

        {!isMobile && canManageDocuments && (
          <MenuItem onClick={uploadFolderClicked}>
            <ListItemIcon><DriveFolderUpload /></ListItemIcon>
            {formatMessage({ id: 'Upload folder' })}
          </MenuItem>
        )}

        {canManageDocuments &&
          <MenuItem onClick={() => setIsAddingExternalLink(true)}>
            <ListItemIcon><Link /></ListItemIcon>
            {formatMessage({ id: 'Add external link' })}
          </MenuItem>
        }


        {canManageDocuments && <Divider />}

        {canManageDocuments && <MenuItem onClick={() => setIsAddingFolder(true)}>
          <ListItemIcon><CreateNewFolder /></ListItemIcon>
          {formatMessage({ id: 'New folder' })}
        </MenuItem>}

        {canManageDocuments && <MenuItem onClick={() => setIsImportingFolder(true)}>
          <ListItemIcon><DriveFileMove /></ListItemIcon>
          {formatMessage({ id: 'Import folders' })}
        </MenuItem>}
      </ActionMenu>

      <input {...getInputProps()} width={0} height={0} />
      <AddFolderDialog
        projectId={projectId}
        parentFolderId={currentFolderId}
        open={isAddingFolder}
        onClose={() => setIsAddingFolder(false)}
      />

      <AddExternalLinkDialog
        folderId={currentFolderId}
        open={isAddingExternalLink}
        onClose={() => setIsAddingExternalLink(false)}
      />

      <FolderImportDrawer
        open={isImportingFolder}
        onClose={() => setIsImportingFolder(false)}
      />
    </Stack>
  </>;
};