import { DataTable } from '@components/DataTable';
import { TaskTreeDataGroupingCell } from '@modules/tasks/components/TaskTreeDataGroupingCell';
import { FormControl, FormHelperText, Stack } from '@mui/material';
import { DataGridProProps, GridRowSelectionModel } from '@mui/x-data-grid-pro';
import { ProjectTaskType, useProjectTasksQuery } from 'gql/index';
import { useCallback, useMemo } from 'react';
import { Controller, ControllerRenderProps, useFormContext, useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { TaskImportFormValues } from '../types';
import { treeSelectionUpdated } from '../utils';


type Props = { disabled?: boolean; };

export const TaskImportTasksPicker = ({ disabled }: Props) => {
  const { formatMessage } = useIntl();

  const { control } = useFormContext<TaskImportFormValues>();

  const projectToImportFromId = useWatch({ control, name: 'projectToImportFromId' });
  const selectedTaskIds = useWatch({ control, name: 'taskIds' });

  const { data: tasks, isFetching: isFetchingTasks } = useProjectTasksQuery({
    projectId: projectToImportFromId ?? 0,
    filter: {
      type: {
        eq: ProjectTaskType.Custom
      }
    }
  }, {
    select: d => d.projectTasks,
    enabled: !!projectToImportFromId
  });

  const tasksToShow = useMemo(() => {
    if (disabled) {
      return tasks?.filter(t => selectedTaskIds.includes(t.id));
    }
    return tasks;
  }, [disabled, selectedTaskIds, tasks]);

  const groupingColDef: DataGridProProps['groupingColDef'] = {
    headerName: formatMessage({ id: 'Name' }),
    flex: 1,
    renderCell: params => <TaskTreeDataGroupingCell {...params} disableLink />
  };

  // Has to be memoized due to MUI bug: https://github.com/mui/mui-x/issues/7771#issuecomment-1920224215
  const getTreeDataPath = useCallback((row: NonNullable<typeof tasks>[number]) => row.hierarchyPath?.split('/').filter(Boolean) ?? [], []);

  const getTreeSelectionUpdate = useCallback((row: GridRowSelectionModel, field: ControllerRenderProps<TaskImportFormValues, 'taskIds'>) => treeSelectionUpdated(row as number[], field.value as number[], tasks?.map(t => ({ id: t.id, hierarchyPath: t.hierarchyPath })) ?? [], field.onChange), [tasks]);

  return (
    <Controller
      control={control}
      name='taskIds'
      rules={{ required: formatMessage({ id: 'At least one task must be selected' }) }}
      render={({ field, fieldState: { error } }) => (
        <FormControl>
          <Stack sx={{ outline: error ? t => `1px solid ${t.palette.error.main}` : undefined }}>
            <DataTable
              treeData
              noDataMessage={formatMessage({ id: 'This project has no tasks.' })}
              getTreeDataPath={getTreeDataPath}
              groupingColDef={groupingColDef}

              rows={tasksToShow ?? []}
              // The groupingColDef contains the name
              columns={[]}

              loading={isFetchingTasks}
              checkboxSelection={!disabled}
              rowSelectionModel={field.value}
              onRowSelectionModelChange={taskIds => getTreeSelectionUpdate(taskIds, field)}
            />
          </Stack>

          {error && <FormHelperText error>{error?.message}</FormHelperText>}
        </FormControl>
      )}
    />
  );
};