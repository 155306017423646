import { useGetMe } from '@utils/useGetMe';
import { useNotification } from '@utils/useNotification';
import { isExternalUser } from '@utils/userUtils';
import { useEffect, useMemo } from 'react';
import { PrincipalType, useGetProjectMembersQuery, useSecurityGroupsForProjectQuery } from '../../../gql';
import { useCurrentProject } from '../../projects/utils/useCurrentProject';
import { SecurityGroupPrincipal, SecurityPrincipal, UserPrincipal } from './types';

export type Props = {
  disabled?: boolean;
  disableGroups?: boolean;
  disableUsers?: boolean;
  disableExternalUsers?: boolean;
  includeMe?: boolean;
};

export const useProjectPrincipals = ({ disabled, includeMe, disableGroups, disableUsers, disableExternalUsers }: Props) => {
  const { projectId } = useCurrentProject({ disabled });
  const { notifyFailure } = useNotification();
  const { me } = useGetMe();

  const { data: projectUsers, isFetching: isFetchingUsers, } = useGetProjectMembersQuery({ projectId: projectId }, {
    select: p => p.projectMembers,
    enabled: !disableUsers && !disabled,
    cacheTime: 300000,
    staleTime: 300000,
  });

  const { data: projectGroups, isFetching: isFetchingGroups } = useSecurityGroupsForProjectQuery({ projectId: projectId }, {
    select: p => p.securityGroupsForProject,
    enabled: !disableGroups && !disabled,
    cacheTime: 300000,
    staleTime: 300000,
  });

  useEffect(() => {
    if (disableGroups && disableUsers && import.meta.env.DEV) {
      notifyFailure('DEV: useProjectPrincipals: disableGroups and disableUsers are both set. See console for details.');
      console.error('useProjectPrincipals: disableGroups and disableUsers are both set');
    }
  }, [disableUsers, notifyFailure, disableGroups]);

  const principals = useMemo(() => {
    let securityPrincipals: SecurityPrincipal[] = [];

    const userPrincipals = projectUsers
      ?.filter(p => !disableExternalUsers || !isExternalUser(p))
      .filter(p => includeMe || p.id !== me?.id)
      .map<UserPrincipal>(p => ({
        ...p,
        principalType: PrincipalType.User,
      })) ?? [];

    if (!disableUsers) {
      securityPrincipals = securityPrincipals.concat(userPrincipals);
    }

    const groupPrincipals = projectGroups?.map<SecurityGroupPrincipal>(p => ({
      ...p,
      principalType: PrincipalType.SecurityGroup,
    })) ?? [];


    if (!disableGroups) {
      securityPrincipals = securityPrincipals.concat(groupPrincipals);
    }

    return securityPrincipals;
  }, [disableUsers, includeMe, disableExternalUsers, me?.id, projectGroups, projectUsers, disableGroups]);

  return { principals, isFetching: isFetchingGroups || isFetchingUsers };
};