import { PageContainer } from '@components/Layout/PageContainer';
import { WorkflowSection } from '@modules/workflow/components/WorkflowSection';
import { Grid, Stack } from '@mui/material';
import { useFeatureFlagsQuery } from 'gql/index';
import React from 'react';
import { useIntl } from 'react-intl';
import { MeetingsSection } from '../components/Sections/MeetingsSection';
import { MembersSection } from '../components/Sections/MembersSection';
import { ProgressWidget } from '../components/Sections/ProgressWidget';
import { RecentActivitySection } from '../components/Sections/RecentActivitySection';
import { TasksSection } from '../components/Sections/TasksSection';

export const ProjectHomePage: React.FC = () => {
  const { data: featureFlags } = useFeatureFlagsQuery({}, { select: p => p.featureFlags, staleTime: 300000 });
  const { formatMessage } = useIntl();
  return (
    <PageContainer title={formatMessage({ id: 'Home' })}>
      <Stack gap={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <Stack gap={2}>
              <MeetingsSection />

              <TasksSection />

              {featureFlags?.workflowsEnabled && <WorkflowSection />}

              <RecentActivitySection />
            </Stack>
          </Grid>

          <Grid item xs={12} md={4}>
            <MembersSection />

            <ProgressWidget />
          </Grid>
        </Grid>
      </Stack>
    </PageContainer>
  );
};
