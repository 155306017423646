import LockClockIcon from '@mui/icons-material/LockClock';
import { LoadingButton } from '@mui/lab';
import { Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useRenewAccessTokenMutation } from '../../../gql';
import { AccessTokenRenewed } from './AccessTokenRenewed';

type RenewAccessTokenContentProps = {
  token: string;
}

export const RenewAccessTokenContent = (props: RenewAccessTokenContentProps) => {
  const { token } = props;
  const { formatMessage } = useIntl();
  const { isLoading, mutate, data } = useRenewAccessTokenMutation();

  const renewToken = useCallback(() => {
    if (token) {
      mutate({ token: token });
    }
  }, [mutate, token]);

  return (<>
    {data != null && data?.renewAccessTokenFromExpiredToken.renewAccessTokenFromExpiredTokenResult?.sentToEmail
      && <AccessTokenRenewed emailSentTo={data?.renewAccessTokenFromExpiredToken.renewAccessTokenFromExpiredTokenResult?.sentToEmail} />}

    {data == null && <Stack alignItems='center' justifyContent='center' p={3} spacing={5}>
      <LockClockIcon color='warning' style={{ height: '100px', width: 'auto' }} />

      <Stack spacing={0.5}>
        <Typography fontWeight={600} variant='h4' textAlign='center'>{formatMessage({ id: 'The link you used has expired for security reasons.' })}</Typography>
        <Typography variant='h5' textAlign='center'>{formatMessage({ id: 'You can request a new link that will be sent to the original email it was sent to.' })}</Typography>
      </Stack>

      <LoadingButton loading={isLoading} variant='contained' onClick={() => renewToken()}>{formatMessage({ id: 'Send me a new link' })}</LoadingButton>
    </Stack>}
  </>);
};
