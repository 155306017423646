import { UserAvatar } from '@components/UserAvatar/UserAvatar';
import { useCurrentProject } from '@modules/projects/utils/useCurrentProject';
import { AvatarGroup, Chip } from '@mui/material';
import { useFolderPermissionsQuery } from 'gql/index';
import { useIntl } from 'react-intl';

interface OwnProps {
  resourceId: number;
}

export const FolderRowSharedWithCell: React.FC<OwnProps> = ({ resourceId }) => {
  const { formatMessage } = useIntl();
  const { projectId } = useCurrentProject();
  const { data: permissions, isFetching: isFetchingPermissions } = useFolderPermissionsQuery({ projectId, folderId: resourceId }, { select: d => d.folderPermissions });

  const users = permissions?.filter(ra => ra.user != null).map(ra => ({ ...ra.user }));
  const groups = permissions?.filter(ra => ra.securityGroup != null).map(ra => ({ ...ra.securityGroup }));
  const sharedWithAllProjectMembers = permissions?.find(ra => ra.sharedWithAllProjectMembers === true)?.sharedWithAllProjectMembers;


  return (
    <>
      {!isFetchingPermissions &&
        <>
          {sharedWithAllProjectMembers ?
            <Chip label={formatMessage({ id: 'All' })} />
            :
            <AvatarGroup >
              {users?.map(user =>
                <UserAvatar key={user.id} displayName={user.fullName} />
              )}
              {groups?.map(group =>
                <UserAvatar key={group.id} displayName={group.name} />
              )}
            </AvatarGroup>
          }
        </>
      }
    </>
  );
};