import { ActionMenu, ActionMenuProps } from '@components/ActionMenu';
import { useCurrentProject } from '@modules/projects/utils/useCurrentProject';
import { ContentCopy, Delete } from '@mui/icons-material';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { merge } from 'lodash';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { ConfirmDialog } from '../../../../../../components/ConfirmDialog';
import { useCommercialProjectCalculatorsQuery, useDuplicateCommercialCalculatorMutation, useRemoveCommercialProjectCalculatorMutation } from '../../../../../../gql';
import { getTenantIdentifier } from '../../../../../../utils/getTenantIdentifier';
import { useQueryInvalidator } from '../../../../../../utils/useQueryInvalidator';
interface Props extends Omit<ActionMenuProps, 'open' | 'onOpen'> {
  calculatorId: number
}

export const CommercialEstimateCalculatorActions: React.FC<Props> = ({ calculatorId, ...moreMenuProps }) => {
  const { formatMessage } = useIntl();
  const [isConfirmRemoveDialogOpen, setIsConfirmRemoveDialogOpen] = useState(false);
  const invalidateQuery = useQueryInvalidator();

  const onDeleteSuccess = () => {
    setMoreMenuDrawerOpen(false);
    setIsConfirmRemoveDialogOpen(false);
    invalidateQuery(useCommercialProjectCalculatorsQuery);
    navigate(`/${getTenantIdentifier()}/projects/${projectId}/budget`);
  };


  const onDuplicateSuccess = () => {
    setMoreMenuDrawerOpen(false);
    invalidateQuery(useCommercialProjectCalculatorsQuery);
  };

  const { mutate: deleteCalculator, isLoading: isDeleting } = useRemoveCommercialProjectCalculatorMutation({ onSuccess: onDeleteSuccess });
  const { mutate: duplicateCalculator } = useDuplicateCommercialCalculatorMutation({ onSuccess: onDuplicateSuccess });
  const navigate = useNavigate();

  const { projectId, canManageProject, isArchived } = useCurrentProject();

  const [moreMenuDrawerOpen, setMoreMenuDrawerOpen] = useState(false);



  if (!canManageProject || isArchived) {
    return null;
  }

  return <>
    <ActionMenu
      {...moreMenuProps}
      open={moreMenuDrawerOpen}
      onOpen={setMoreMenuDrawerOpen}
      iconButtonProps={merge({}, { color: 'inherit' }, moreMenuProps.iconButtonProps)}
    >


      <MenuItem onClick={() => duplicateCalculator({
        input: {
          id: calculatorId,
          projectId: projectId
        }
      })} >
        <ListItemIcon><ContentCopy /></ListItemIcon>
        <ListItemText primary={formatMessage({ id: 'Duplicate' })} />
      </MenuItem>
      <MenuItem onClick={() => setIsConfirmRemoveDialogOpen(true)}>
        <ListItemIcon><Delete /></ListItemIcon>
        <ListItemText primary={formatMessage({ id: 'Delete' })} />
      </MenuItem>
    </ActionMenu >

    <ConfirmDialog
      open={isConfirmRemoveDialogOpen}
      onCancel={() => setIsConfirmRemoveDialogOpen(false)}
      onConfirm={() => deleteCalculator({ input: { id: calculatorId, projectId: projectId } })}

      title={formatMessage({ id: 'Delete estimate?' })}
      content={formatMessage({
        id: 'This estimate will be deleted permanently. Are you sure?'
      })}
      confirmText={formatMessage({ id: 'Remove' })}
      confirmColor='error'
      loading={isDeleting}
    />
  </>;
};