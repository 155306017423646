import DeleteIcon from '@mui/icons-material/Delete';
import { CircularProgress, IconButton, ListItem } from '@mui/material';
import { useContextSafe } from '@utils/useContextSafe';
import { useContext } from 'react';
import { useRemoveFileAttachmentMutation, useRemoveFileAttachmentPatMutation } from '../../../../../../gql';
import { getTenantIdentifier } from '../../../../../../utils/getTenantIdentifier';
import { DocumentFileNameAndIcon } from '../../../../../documents/components/DocumentFilenameAndIcon';
import { AnonymousContext } from '../../../../../projectAccessTokens/AnonymousContextProvider';
import { FormFillerContext } from '../../FormFillerContextProvider';
import { FormAttachment } from '../../types';

interface Props {
  fieldId: number,
  formAttachment: FormAttachment,
  canDelete?: boolean;
  onDeleted: () => void;
}

export const FileAttachmentListItem: React.FC<Props> = ({ fieldId, formAttachment, canDelete, onDeleted }) => {
  const { accessToken } = useContext(AnonymousContext);
  const { submissionId } = useContextSafe(FormFillerContext);

  const { mutateAsync: deleteAttachment, isLoading: deletingAttachment } = useRemoveFileAttachmentMutation();
  const { mutateAsync: deleteAttachmentPAT, isLoading: deletingAttachmentPAT } = useRemoveFileAttachmentPatMutation();

  const isDeleting = deletingAttachment || deletingAttachmentPAT;

  const onDeleteAttachment = async (attachmentId: number) => {
    if (accessToken != null) {
      await deleteAttachmentPAT({
        input: {
          deleteFormAttachmentInput: {
            attachmentId,
            fieldId,
            submissionId
          },
          token: accessToken
        }
      });
    }
    else {
      await deleteAttachment({
        input: {
          attachmentId,
          fieldId,
          submissionId
        }
      });
    }

    onDeleted();
  };

  return (
    <ListItem disablePadding sx={{ border: t => `1px solid ${t.palette.divider}` }}>
      <DocumentFileNameAndIcon
        fileName={formAttachment.fileName}
        downloadLink={`/${getTenantIdentifier()}/api/Form/Submission/${submissionId}/Attachments/${formAttachment.id}`}
        actionButton={canDelete && (
          <IconButton
            disabled={deletingAttachment || deletingAttachmentPAT}
            onClick={() => onDeleteAttachment(formAttachment.id)}
          >
            {isDeleting ? <CircularProgress size={24} /> : <DeleteIcon />}
          </IconButton>
        )}
      />
    </ListItem>
  );
};