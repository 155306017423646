import { Clear, Search } from '@mui/icons-material';
import { IconButton, InputAdornment, TextField, TextFieldProps } from '@mui/material';
import { merge } from 'lodash';
import { useState } from 'react';
import { useIntl } from 'react-intl';

type Props = TextFieldProps & {
  expandable?: boolean;
  onExpandChange?: (expanded: boolean) => void;
};

export const ExpandableSearchBar: React.FC<Props> = ({ expandable, onExpandChange, ...props }) => {
  const { formatMessage } = useIntl();

  const [_expanded, _setExpanded] = useState(!expandable);
  const setExpanded = (expanded: boolean) => {
    _setExpanded(expanded);
    if (expandable) {
      onExpandChange?.(expanded);
    }
  };
  const expanded = !expandable || _expanded;

  const startAdornment = (
    <InputAdornment position='start'><Search /></InputAdornment>
  );

  const endAdornment = (
    <IconButton onClick={() => {
      props.onChange?.({ target: { value: '' } } as unknown as React.ChangeEvent<HTMLInputElement>);
      setExpanded(false);
    }} disableRipple style={{ visibility: props.value ? 'visible' : 'hidden' }}>
      <Clear />
    </IconButton>
  );

  const onBlur: React.FocusEventHandler<HTMLTextAreaElement | HTMLInputElement> = e => {
    props.onBlur?.(e);
    if (!e.target.value) {
      setExpanded(false);
    }
  };

  return expanded ? (
    <TextField
      autoFocus={expandable}
      placeholder={formatMessage({ id: 'Search' })}
      {...props}
      size={props.size ?? 'small'}
      onBlur={expandable ? onBlur : props.onBlur}
      InputProps={merge({}, { startAdornment, endAdornment }, props.InputProps)}
    />
  ) : (
    <IconButton onClick={() => setExpanded(true)}>
      <Search />
    </IconButton>
  );
};