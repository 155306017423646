import { useCurrentProject } from '@modules/projects/utils/useCurrentProject';
import { Document, ShareableResourceType, useDocumentPermissionsQuery } from 'gql/index';
import React from 'react';
import { PropertyGroupSkeleton } from '../../../../../components/DataDisplay/PropertyGroupSkeleton';
import { PermissionsProperty } from '../../PermissionsProperty';

interface Props {
  document: Pick<Document, 'id' | 'fileName' | 'publicSlug'>;
}

export const DocumentPermissions: React.FC<Props> = ({ document }) => {
  const { projectId } = useCurrentProject();

  const { data: permissions } = useDocumentPermissionsQuery({ projectId, documentId: document.id }, { select: d => d.documentPermissions });


  if (permissions == null) {
    return (
      <PropertyGroupSkeleton propertiesCount={2} />
    );
  }

  return (
    <>
      <PermissionsProperty
        resource={{ ...document, resourceType: ShareableResourceType.Document }}
        roleAssignments={permissions ?? []}
      />
    </>
  );
};