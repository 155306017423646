import { PageContainer } from '@components/Layout/PageContainer';
import { PageTitle } from '@components/Layout/PageTitle';
import { ResponsiveButton } from '@components/ResponsiveButton';
import { ProgressWithTitle } from '@modules/projects/components/ProgressWidget/ProgressWithTitle';
import LabelIcon from '@mui/icons-material/Label';
import { LoadingButton } from '@mui/lab';
import { Alert, AlertTitle, Backdrop, Box, FormControl, FormControlLabel, FormHelperText, Paper, Stack, Switch, Typography, useTheme } from '@mui/material';
import { getTenantIdentifier } from '@utils/getTenantIdentifier';
import { useNotification } from '@utils/useNotification';
import { useQueryInvalidator } from '@utils/useQueryInvalidator';
import { useTenantThemeQuery, useUpdateBrandingConfigurationMutation } from 'gql/index';
import React, { useCallback, useEffect, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { BrandingPreview } from '../components/brandingPreview/BrandingPreview';
import { useBrandImagesUploadManager } from '../hooks/useBrandImagesUploadManager';
import { BrandingFormValues, ImageInfo, readImageContent } from '../types';

const topBarLogoMinWidth = 200;
const topBarLogoMinHeight = 64;
const loginImageMinWidth = 1920;
const loginImageMinHeight = 1080;
const squareLogoMinSize = 256;

export const BrandingConfigurationPage: React.FC = () => {
  const { formatMessage } = useIntl();
  const [lightLogoPreview, setLightLogoPreview] = useState<ImageInfo>();
  const [darkLogoPreview, setDarktLogoPreview] = useState<ImageInfo>();
  const [loginBackgroundPreview, setLoginBackgroundPreview] = useState<ImageInfo>();
  const [lightSquareLogoPreview, setLightSquareLogoPreview] = useState<ImageInfo>();
  const [darkSquareLogoPreview, setDarkSquareLogoPreview] = useState<ImageInfo>();
  const { data: tenantTheme, isLoading: isLoadingTheme } = useTenantThemeQuery({}, { select: p => p.tenantTheme });
  const form = useForm<BrandingFormValues>();
  const theme = useTheme();
  const { queueFiles, upload } = useBrandImagesUploadManager();
  const { notifyFailure } = useNotification();
  const invalidateQuery = useQueryInvalidator();

  const { mutate: saveBrandingSettings, isLoading: isSavingSettings } = useUpdateBrandingConfigurationMutation();
  useEffect(() => {
    if (tenantTheme) {
      form.reset({
        isBrandingEnabled: tenantTheme.isBrandingEnabled,
        primaryColor: tenantTheme.primaryColor ?? theme.palette.primary.main,
        secondaryColor: tenantTheme.secondaryColor ?? theme.palette.secondary.main,
      }, { keepDefaultValues: false });
    }
  }, [form, tenantTheme, theme.palette.primary.main, theme.palette.secondary.main]);

  const savingMessage = (upload?.state == 'uploading' && upload?.uploadProgress != 100) ? formatMessage({ id: 'Uploading images' })
    : isSavingSettings ? formatMessage({ id: 'Saving settings' }) : formatMessage({ id: 'Processing images' });

  const onSave = useCallback((values: BrandingFormValues) => {
    queueFiles(
      {
        logoLight: lightLogoPreview?.file,
        logoDark: darkLogoPreview?.file,
        loginBackgroundImage: loginBackgroundPreview?.file,
        squareLogoLight: lightSquareLogoPreview?.file,
        squareLogoDark: darkSquareLogoPreview?.file,
      },
      (e) => notifyFailure(formatMessage({ id: 'Upload of images failed: {error}' }, { error: e })),
      () => {
        saveBrandingSettings({
          input: {
            isWhiteLabelEnabled: values.isBrandingEnabled,
            primaryColor: values.primaryColor,
            secondaryColor: values.secondaryColor
          }
        }, {
          onSuccess: () => invalidateQuery(useTenantThemeQuery)
        });
      });
  }, [queueFiles, lightLogoPreview?.file, darkLogoPreview?.file, loginBackgroundPreview?.file, lightSquareLogoPreview?.file, darkSquareLogoPreview?.file, notifyFailure, formatMessage, saveBrandingSettings, invalidateQuery]);

  return <>
    <PageTitle
      title={formatMessage({ id: 'Branding configuration' })}
      icon={<LabelIcon />}
      backgroundColor='secondary'
    />
    <Stack height={'calc(100% - 64px)'}>
      <PageContainer fullPageLoading={isLoadingTheme} >
        <Stack gap={2} height={'100%'}>
          <Backdrop open={upload?.state == 'uploading'} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
            <ProgressWithTitle value={(upload?.uploadProgress ?? 0) * 0.9} title={savingMessage} />
          </Backdrop>
          <Box
            component="form"
            noValidate
            autoComplete="off"
            display={'flex'}
            flexDirection={'column'}
            flex={1}
            gap={2}
            height={'100%'}
            overflow={'auto'}
          >
            <Alert severity='info' sx={{ justifyContent: 'center' }}>
              <AlertTitle>{formatMessage({ id: 'Once branding is enabled, use this URL to login to your organization.' })}</AlertTitle>
              <Typography sx={{ backgroundColor: 'background.paper', p: 2 }}>{`https://${location.host}/${getTenantIdentifier()}`} </Typography>
            </Alert>
            <FormProvider {...form}>
              <Stack gap={2} flex={1} flexWrap='wrap' flexDirection={'row'}>

                <Paper sx={{ display: 'flex', flex: 1, p: 2 }}>

                  <Stack gap={2} flex={1} >
                    <Typography variant='h6'>{formatMessage({ id: 'Options' })}</Typography>
                    <Controller
                      control={form.control}
                      name='isBrandingEnabled'
                      render={({ field }) => <FormControlLabel
                        sx={{ display: 'flex', justifyContent: 'space-between' }}
                        control={<Switch checked={field.value} onChange={(_, checked) => field.onChange(checked)} />}
                        label={formatMessage({ id: 'Enable custom branding' })}
                        labelPlacement='start'
                      />}
                    />
                    <Controller
                      control={form.control}
                      name='primaryColor'
                      render={({ field }) => <FormControlLabel
                        sx={{ display: 'flex', justifyContent: 'space-between' }}

                        label={formatMessage({ id: 'Primary color' })}
                        control={<input {...field} type='color' style={{ border: '1px solid', width: '2.5em', height: '2.5em' }} />}
                        labelPlacement='start'
                      />
                      }
                    />
                    <Controller
                      control={form.control}
                      name='secondaryColor'
                      render={({ field }) => <FormControlLabel
                        sx={{ display: 'flex', justifyContent: 'space-between' }}

                        label={formatMessage({ id: 'Secondary color' })}
                        control={<input {...field} type='color' style={{ border: '1px solid', width: '2.5em', height: '2.5em' }} />}
                        labelPlacement='start'
                      />
                      }
                    />
                    <Controller
                      control={form.control}
                      name='topBarLogoLight'
                      rules={{
                        validate: {
                          minResolution: () => lightLogoPreview == null
                            || (lightLogoPreview.width >= topBarLogoMinWidth && lightLogoPreview?.height >= topBarLogoMinHeight)
                            || formatMessage({ id: 'Image resolution is too small' })
                        }
                      }}
                      render={({ fieldState }) =>
                        <FormControl error={!!fieldState.error?.message}>
                          <FormControlLabel
                            sx={{ alignItems: 'start' }}
                            label={formatMessage({ id: 'Top bar logo - light mode (at least {minWidth}x{minHeight})' },
                              { minWidth: topBarLogoMinWidth, minHeight: topBarLogoMinHeight }
                            )}
                            control={<input type='file'
                              accept='.png,.webp,.jpg'
                              onChange={e => {
                                readImageContent(e, setLightLogoPreview);
                              }} />
                            }

                            labelPlacement='top'
                          />
                          <FormHelperText>{fieldState.error?.message}</FormHelperText>
                        </FormControl>} />

                    <Controller
                      control={form.control}
                      name='topBarLogoDark'
                      rules={{
                        validate: {
                          minResolution: () => darkLogoPreview == null
                            || (darkLogoPreview.width >= topBarLogoMinWidth && darkLogoPreview?.height >= topBarLogoMinHeight)
                            || formatMessage({ id: 'Image resolution is too small' })
                        }
                      }}
                      render={({ fieldState }) =>
                        <FormControl error={!!fieldState.error?.message}>
                          <FormControlLabel
                            sx={{ alignItems: 'start' }}
                            label={formatMessage({ id: 'Top bar logo - dark mode (at least {minWidth}x{minHeight})' },
                              { minWidth: topBarLogoMinWidth, minHeight: topBarLogoMinHeight }
                            )}
                            control={<input type='file'
                              accept='.png,.webp,.jpg'
                              onChange={e => readImageContent(e, setDarktLogoPreview)} />}
                            labelPlacement='top'
                          />
                          <FormHelperText>{fieldState.error?.message}</FormHelperText>
                        </FormControl>}
                    />

                    <Controller
                      control={form.control}
                      name='loginBackground'
                      rules={{
                        validate: {
                          minResolution: () => loginBackgroundPreview == null
                            || (loginBackgroundPreview.width >= loginImageMinWidth && loginBackgroundPreview?.height >= loginImageMinHeight)
                            || formatMessage({ id: 'Image resolution is too small' })
                        }
                      }}
                      render={({ fieldState }) =>
                        <FormControl error={!!fieldState.error?.message}>
                          <FormControlLabel
                            sx={{ alignItems: 'start' }}
                            label={formatMessage({ id: 'Login background image (at least {minWidth}x{minHeight})' },
                              { minWidth: loginImageMinWidth, minHeight: loginImageMinHeight }
                            )}
                            control={<input type='file'
                              accept='.png,.webp,.jpg'
                              onChange={e => {
                                readImageContent(e, setLoginBackgroundPreview);
                              }} />}
                            labelPlacement='top'
                          />
                          <FormHelperText>{fieldState.error?.message}</FormHelperText>
                        </FormControl>}
                    />
                    <Controller
                      control={form.control}
                      name='squareLogoLight'
                      rules={{
                        validate: {
                          minResolution: () => lightSquareLogoPreview == null
                            || (lightSquareLogoPreview.width >= squareLogoMinSize && lightSquareLogoPreview?.height >= squareLogoMinSize)
                            || formatMessage({ id: 'Image resolution is too small' }),
                          square: () => lightSquareLogoPreview == null
                            || lightSquareLogoPreview.width == lightSquareLogoPreview?.height
                            || formatMessage({ id: 'Image must be square' })
                        }
                      }}
                      render={({ fieldState }) =>
                        <FormControl error={!!fieldState.error?.message}>
                          <FormControlLabel
                            sx={{ alignItems: 'start' }}
                            label={formatMessage({ id: 'Square logo - light mode (at least {minWidth}x{minHeight})' },
                              { minWidth: squareLogoMinSize, minHeight: squareLogoMinSize }
                            )}
                            control={<input type='file'
                              accept='.png,.webp,.jpg'
                              onChange={e => {
                                readImageContent(e, setLightSquareLogoPreview);
                              }} />}
                            labelPlacement='top'
                          />
                          <FormHelperText>{fieldState.error?.message}</FormHelperText>
                        </FormControl>}
                    />
                    <Controller
                      control={form.control}
                      name='squareLogoDark'
                      rules={{
                        validate: {
                          minResolution: () => darkSquareLogoPreview == null
                            || (darkSquareLogoPreview.width >= squareLogoMinSize && darkSquareLogoPreview?.height >= squareLogoMinSize)
                            || formatMessage({ id: 'Image resolution is too small' }),
                          square: () => darkSquareLogoPreview == null
                            || darkSquareLogoPreview.width == darkSquareLogoPreview?.height
                            || formatMessage({ id: 'Image must be square' })
                        }
                      }}
                      render={({ fieldState }) =>
                        <FormControl error={!!fieldState.error?.message}>
                          <FormControlLabel
                            sx={{ alignItems: 'start' }}
                            label={formatMessage({ id: 'Square logo - dark mode (at least {minWidth}x{minHeight})' },
                              { minWidth: squareLogoMinSize, minHeight: squareLogoMinSize }
                            )}
                            control={<input type='file'
                              accept='.png,.webp,.jpg'
                              onChange={e => {
                                readImageContent(e, setDarkSquareLogoPreview);
                              }} />}
                            labelPlacement='top'
                          />
                          <FormHelperText>{fieldState.error?.message}</FormHelperText>
                        </FormControl>}
                    />
                  </Stack>
                </Paper>
                <Stack flex={4} >
                  <BrandingPreview
                    lightLogoPreviewUrl={lightLogoPreview?.dataURL}
                    darkLogoPreviewUrl={darkLogoPreview?.dataURL}
                    lightSquareLogoPreviewUrl={lightSquareLogoPreview?.dataURL}
                    darkSquareLogoPreviewUrl={darkSquareLogoPreview?.dataURL}
                    loginPageBackgroundPreviewUrl={loginBackgroundPreview?.dataURL}
                    enableCustomBranding={() => form.setValue('isBrandingEnabled', true)}
                  />
                </Stack>
              </Stack>
            </FormProvider>

          </Box>
          <Stack color='inherit'>
            <Paper>
              <Stack justifyContent='right' direction='row' spacing={1} p={{ xs: 1, md: 2 }}>
                <ResponsiveButton onClick={() => form.reset({})}>{formatMessage({ id: 'Discard changes' })}</ResponsiveButton>
                <LoadingButton
                  loading={false}
                  onClick={form.handleSubmit(onSave)}

                  variant='contained'
                  color='primary'
                >
                  {formatMessage({ id: 'Save' })}
                </LoadingButton>
              </Stack>
            </Paper>
          </Stack>
        </Stack>
      </PageContainer >
    </Stack>
  </>;
};