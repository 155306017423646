import { AppBar, Toolbar, styled } from '@mui/material';
import React from 'react';
import { TopBarAppLogo } from './TopBarAppLogo';

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  zIndex: theme.zIndex.drawer + 1,
  boxShadow: 'none',
  borderBottomStyle: 'solid',
  borderBottomWidth: '1px',
  borderBottomColor: theme.palette.divider
}));

export const AnonymousTopbar: React.FC = () => {
  return (
    <StyledAppBar
      color="default"
      position="sticky"
      elevation={1}
    >
      <Toolbar disableGutters sx={{ paddingX: 2.5 }}>
        <TopBarAppLogo />
      </Toolbar>
    </StyledAppBar>
  );
};