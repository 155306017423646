import { FormDrawer } from '@components/Drawers/FormDrawer';
import { UploadZoneRoot } from '@components/FileUpload/UploadZoneRoot';
import { expenseStatusMessages } from '@modules/budget/messages';
import { DocumentFileNameAndIcon } from '@modules/documents/components/DocumentFilenameAndIcon';
import { useCurrentProject } from '@modules/projects/utils/useCurrentProject';
import { Delete, UploadFile } from '@mui/icons-material';
import { DrawerProps, FormLabel, IconButton, Link, ListItem, MenuItem, Stack, TextField, Typography } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { useNotification } from '@utils/useNotification';
import { useQueryInvalidator } from '@utils/useQueryInvalidator';
import { useValidationRules } from '@utils/useValidationRules';
import dayjs, { Dayjs } from 'dayjs';
import { ExpenseFragmentFragment, ExpenseStatus, useAddExpenseMutation, useBudgetItemsQuery, useDeleteExpenseAttachmentMutation, useEditExpenseMutation, useExpensesQuery, useGetProjectBudgetQuery } from 'gql/index';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useExpenseAttachmentDropzone } from './useExpenseAttachmentDropzone';

interface FormValues {
  name: string;
  expendedAmount: string;
  expendedDate: Dayjs;
  status: ExpenseStatus;
  budgetItemId: number | NoBudgetItemId;
}

const noBudgetItemId = '';
type NoBudgetItemId = typeof noBudgetItemId;

const defaultValues: FormValues = {
  name: '',
  expendedAmount: '',
  expendedDate: dayjs(),
  status: ExpenseStatus.NotPaid,
  budgetItemId: noBudgetItemId
};

interface Props extends DrawerProps {
  expense?: ExpenseFragmentFragment;
  budgetItemId?: number;
}

type RequestSender = (expenseId: number, onComplete: () => void) => void;

export const ExpenseDrawer: React.FC<Props> = ({ expense, budgetItemId, ...props }) => {
  const { notEmpty, validateNumber, validateIsPositive } = useValidationRules();
  const { formatMessage } = useIntl();
  const { projectId } = useCurrentProject();
  const invalidateQuery = useQueryInvalidator();
  const { notifySuccess } = useNotification();

  const [currentFileName, setCurrentFileName] = useState<string | null>(null);
  const [fileSender, setFileSender] = useState<RequestSender | null>(null);

  const { reset, control, handleSubmit } = useForm<FormValues>({ defaultValues });

  const { data: budgetItems } = useBudgetItemsQuery({ projectId }, { select: d => d.budgetItems });

  useEffect(() => {
    if (expense) {
      reset({
        name: expense.name,
        expendedAmount: expense.expendedAmount.toString(),
        expendedDate: dayjs(expense.expenseDate),
        status: expense.status,
        budgetItemId: expense.budgetItem?.id ?? noBudgetItemId
      });
      setCurrentFileName(expense.attachments.at(0)?.fileName ?? null);
      setFileSender(null);
      setIsUploadingFile(false);
    } else {
      reset({
        ...defaultValues,
        budgetItemId: budgetItemId ?? noBudgetItemId
      });
      setCurrentFileName(null);
      setFileSender(null);
      setIsUploadingFile(false);
    }
  }, [expense, reset, props.open, budgetItemId]);


  const onSuccess = () => {
    invalidateQuery(useExpensesQuery, { projectId });
    invalidateQuery(useGetProjectBudgetQuery, { id: projectId });
    invalidateQuery(useBudgetItemsQuery, { projectId });
    notifySuccess(formatMessage({ id: 'Expense updated successfully.' }));
    props.onClose?.({}, 'backdropClick');
    setIsUploadingFile(false);
  };

  const { mutateAsync: addExpense, isLoading: isAdding } = useAddExpenseMutation();

  const { mutateAsync: editExpense, isLoading: isEditing } = useEditExpenseMutation();

  const { mutateAsync: deleteExpenseAttachment, isLoading: isDeletingExpenseAttachment } = useDeleteExpenseAttachmentMutation();

  const [isUploadingFile, setIsUploadingFile] = useState(false);

  const isLoading = isAdding || isEditing || isUploadingFile || isDeletingExpenseAttachment;

  const onSubmit = async (data: FormValues) => {
    let expenseId = expense?.id;
    let isSuccesss = false;
    if (expense) {
      const result = await editExpense({
        input: {
          id: expense.id,
          name: data.name,
          expendedAmount: Number(data.expendedAmount),
          expenseDate: data.expendedDate.toISOString(),
          status: data.status,
          budgetItemId: data.budgetItemId === noBudgetItemId ? undefined : data.budgetItemId,
          projectId
        }
      });

      isSuccesss = !result.editExpense.errors?.length;
    } else {
      const result = await addExpense({
        input: {
          name: data.name,
          expendedAmount: Number(data.expendedAmount),
          expenseDate: data.expendedDate.toISOString(),
          status: data.status,
          budgetItemId: data.budgetItemId === noBudgetItemId ? undefined : data.budgetItemId,
          projectId
        }
      });

      isSuccesss = !result.addExpense.errors?.length;
      expenseId = result.addExpense.expense?.id;
    }

    if (!expenseId || !isSuccesss) {
      return;
    }

    if (fileSender) {
      setIsUploadingFile(true);

      fileSender(expenseId, () => {
        setCurrentFileName(null);
        setFileSender(null);
        onSuccess();
      });
    } else {
      const didUserRemoveFile = !currentFileName;

      if (didUserRemoveFile) {
        await deleteExpenseAttachment({ input: { expenseId, projectId } });
      }

      onSuccess();
    }
  };

  const { getInputProps, getRootProps, isDragActive } = useExpenseAttachmentDropzone({
    disabled: isLoading,
    onFileUploadReady: (sender, filename) => {
      setCurrentFileName(filename);
      setFileSender(() => sender);
    }
  });

  return (
    <FormDrawer
      {...props}
      onSave={handleSubmit(onSubmit)}
      showFooter
      isSubmitting={isLoading}
      header={expense ? formatMessage({ id: 'Edit Expense' }) : formatMessage({ id: 'Add Expense' })}
    >
      <Stack p={2} gap={2}>
        <Controller
          name='name'
          control={control}
          rules={{ validate: { notEmpty } }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              required
              label={formatMessage({ id: 'Name' })}
              error={!!error}
              helperText={error?.message}
              disabled={isLoading}
            />
          )}
        />

        <Controller
          name='expendedAmount'
          control={control}
          rules={{ validate: { notEmpty, validateNumber, validateIsPositive } }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              label={formatMessage({ id: 'Expense Amount' })}
              required
              error={!!error}
              helperText={error?.message}
              disabled={isLoading}
            />
          )}
        />

        <Controller
          name='expendedDate'
          control={control}
          render={({ field, fieldState: { error } }) => (
            <DesktopDatePicker
              {...field}
              label={formatMessage({ id: 'Expense Date' })}
              disabled={isLoading}
              slotProps={{
                textField: {
                  error: !!error,
                  helperText: error?.message
                }
              }}
            />
          )}
        />

        <Controller
          name='status'
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField select
              {...field}
              label={formatMessage({ id: 'Status' })}
              error={!!error}
              helperText={error?.message}
              disabled={isLoading}
            >
              {Object.values(ExpenseStatus).map(status => (
                <MenuItem key={status} value={status}>
                  {formatMessage(expenseStatusMessages[status])}
                </MenuItem>
              ))}
            </TextField>
          )}
        />

        <Controller
          name='budgetItemId'
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label={formatMessage({ id: 'Budget Item' })}
              select
              disabled={isLoading || budgetItemId != null}
            >
              <MenuItem value={noBudgetItemId} sx={{ fontStyle: 'italic' }}>{formatMessage({ id: 'None' })}</MenuItem>
              {budgetItems?.map(bi => (
                <MenuItem key={bi.id} value={bi.id}>{bi.name}</MenuItem>
              ))}
            </TextField>
          )}
        />


        <Stack>
          <FormLabel>{formatMessage({ id: 'Invoice' })}</FormLabel>
          {currentFileName ? (
            <ListItem disablePadding sx={{ border: t => `1px solid ${t.palette.divider}` }}>
              <DocumentFileNameAndIcon
                fileName={currentFileName}

                actionButton={!isLoading && (
                  <IconButton
                    disabled={isLoading}
                    onClick={() => {
                      setCurrentFileName(null);
                      setFileSender(null);
                    }}
                  >
                    <Delete />
                  </IconButton>
                )}
              />
            </ListItem>
          ) : (
            <UploadZoneRoot isDragActive={isDragActive} dropZoneRootProps={getRootProps()}>
              <UploadFile color='primary' />
              <input {...getInputProps()} />
              <Stack alignItems='center' gap={1}>
                <Typography variant='body1'>
                  <Link href='#'>
                    {formatMessage({ id: 'Click to upload' })}
                  </Link>
                  {' '}{formatMessage({ id: 'or drag and drop' })}
                </Typography>
                <Typography variant='body1' color='grey.600'>{formatMessage({ id: '(10MB max)' })}</Typography>
              </Stack>
            </UploadZoneRoot>
          )}
        </Stack>
      </Stack>
    </FormDrawer>
  );
};