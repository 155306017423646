import { FormFieldFragmentFragment, MatrixColumnInput, MatrixFragmentFragment, MatrixInput, MatrixRowInput, MatrixValueInput } from 'gql/index';
import { FormEditorFieldValues, FormEditorMatrixColumnValues, FormEditorMatrixRowValues, FormEditorMatrixValues, MatrixEditorValue } from './types';

export const mapFieldFragmentToFormValues = (f: FormFieldFragmentFragment): FormEditorFieldValues => ({
  id: f.id,
  fieldType: f.fieldType,
  name: f.name,
  isRequired: f.isRequired,
  isMultiline: f.isMultiline,
  isMultiselect: f.isMultiselect,
  documentUploadFolderId: f.documentUploadFolderId || null,
  shouldUploadDocumentsToFolder: f.shouldUploadDocumentsToFolder,
  selectionOptions: f.options?.map(o => ({ id: o.id.toString(), label: o.label })) ?? [],
  matrixValues: matrixQueryValuesToFormValues(f.matrix),
});

export const matrixQueryValuesToFormValues = (matrixQueryValues?: MatrixFragmentFragment | null): FormEditorMatrixValues | undefined => {
  if (matrixQueryValues == null) return undefined;

  const columns = matrixQueryValues.columns.map<FormEditorMatrixColumnValues>(c => ({
    columnId: c.id.toString(),
    name: c.name,
    description: c.description ?? '',
    isRequired: c.isRequired,
    type: c.fieldType,
  }));


  const defaultRows = matrixQueryValues.defaultRows.map<FormEditorMatrixRowValues>(row => ({
    rowId: row.id.toString(),
    values: row.values.map<MatrixEditorValue>(value => ({
      id: value.id,
      rowId: row.id.toString(),
      columnId: value.matrixColumnId.toString(),
      text: value.text ?? undefined,
      number: value.number,
      boolean: value.boolean ?? undefined,
    }))
  }));

  return { defaultRows, columns };
};

export const matrixFormValuesToMutationValues = (matrix?: FormEditorMatrixValues): MatrixInput | undefined => {
  if (!matrix) return undefined;

  const columns = matrix.columns.map<MatrixColumnInput>((column, index) => ({
    name: column.name,
    order: index,
    description: column.description,
    isRequired: column.isRequired,
    type: column.type
  }));

  const defaultRows = matrix.defaultRows.map<MatrixRowInput>(r => ({
    values: matrix.columns.map<MatrixValueInput>(c => {
      const columnValue = r.values.find(p => p.columnId == c.columnId);
      return { boolean: columnValue?.boolean, text: columnValue?.text, number: columnValue?.number };
    })
  }));

  return { columns, defaultRows };
};