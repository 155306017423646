import { WorkflowActionConfigurationFragmentFragment, WorkflowActionDefinitionFragmentFragment, WorkflowVariableFragmentFragment } from 'gql/index';
import { WorkflowEditorActionFieldValue, WorkflowEditorActionValue, WorkflowVariableFormValue } from './types';

export const mapActionFragmentToForm = (action: WorkflowActionConfigurationFragmentFragment, actionDefinition: WorkflowActionDefinitionFragmentFragment): WorkflowEditorActionValue => {
  return ({
    id: action.id,
    workflowActionDefinitionId: action.workflowActionDefinitionId,
    name: action.name,
    order: action.order,
    status: action.status,
    fieldValues: actionDefinition.fields
      .map<WorkflowEditorActionFieldValue>(field => {
        const existingValue = action.fieldValues.find(p => p.fieldId == field.id);
        if (existingValue) {
          return ({
            fieldId: existingValue.fieldId,
            value: mapWorkflowVariableToFormValue(existingValue.value)
          });
        } else {
          return ({
            fieldId: field.id,
            value: undefined,
          });
        }
      }) ?? [],
  });
};

export const mapWorkflowVariableToFormValue = (variable: WorkflowVariableFragmentFragment | null | undefined): WorkflowVariableFormValue | undefined =>
  variable == null ? undefined : ({
    id: variable.id,
    name: variable.name ?? '',
    variableType: variable.variableType,
    variableValueType: variable.variableValueType,
    value: variable.serializedValue == null ? null : JSON.parse(variable.serializedValue)
  });