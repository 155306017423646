import { Draw, Keyboard } from '@mui/icons-material';
import { BottomNavigation, BottomNavigationAction, Container, Stack } from '@mui/material';
import { useResponsive } from '@utils/useResponsive';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { DrawSignature } from './DrawSignature';
import { TextualSignature } from './TextualSignature';

interface Props {
  onDrawnSignatureComplete: (signature: string) => void;
  onTextualSignatureComplete: (signature: string) => void;
  onClose?: () => void;
  loading?: boolean;
}

export const SignaturePadPaper: React.FC<Props> = ({ onDrawnSignatureComplete, onTextualSignatureComplete, onClose, loading }) => {
  const { formatMessage } = useIntl();
  const { isMobile, isHorizontal } = useResponsive();

  const [currentSignType, setCurrentDrawType] = useState<'draw' | 'type'>('draw');

  return (
    <Container maxWidth='md' style={{ padding: isMobile ? 0 : 'unset' }}>
      <Stack px={isHorizontal ? 4 : 0} spacing={2} pb={2}>
        <BottomNavigation showLabels value={currentSignType} onChange={(_, value) => setCurrentDrawType(value)} sx={{ backgroundColor: 'transparent' }}>
          <BottomNavigationAction label={formatMessage({ id: 'Draw' })} value='draw' icon={<Draw />} />
          <BottomNavigationAction label={formatMessage({ id: 'SignaturePadPaper.Type', defaultMessage: 'Type' })} value='type' icon={<Keyboard />} />
        </BottomNavigation>

        {currentSignType === 'draw' && (
          <DrawSignature loading={loading} onClose={onClose} onSignatureComplete={onDrawnSignatureComplete} />
        )}

        {currentSignType === 'type' && (
          <TextualSignature loading={loading} onClose={onClose} onSignatureComplete={onTextualSignatureComplete} />
        )}
      </Stack>
    </Container>
  );
};