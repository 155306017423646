import { DataTable, DataTableProps } from '@components/DataTable';
import { UserAvatar } from '@components/UserAvatar/UserAvatar';
import { useCurrentProject } from '@modules/projects/utils/useCurrentProject';
import { Chip, Stack, Tooltip, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';
import { getEnumValues } from '@utils/enumUtils';
import dayjs from 'dayjs';
import { FormStatus, FormType } from 'gql/index';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { FormActions } from '../FormActions';
import { FormStatusChip } from '../FormStatusChip';
import { FormRow } from '../types';
import { FormNameCell } from './FormNameCell';


interface Props extends Omit<DataTableProps<FormRow>, 'columns' | 'rows'> {
  forms: FormRow[];
}

const commonColumnVariables: Partial<GridColDef> = {
  disableColumnMenu: true,
  disableReorder: true,
  sortable: true,
};

export const FormsTable: React.FC<Props> = ({ forms, ...dataTableProps }) => {
  const { formatMessage, formatDate } = useIntl();
  const { canManageForms, isTemplate } = useCurrentProject();

  const columns = useMemo(() => {

    const columns: GridColDef<FormRow>[] = [
      {
        ...commonColumnVariables,
        field: 'name',
        flex: 3,
        minWidth: 300,
        headerName: formatMessage({ id: 'Name' }),
        valueGetter: ({ row }) => row.formName ?? '',
        renderCell: (params) => <FormNameCell {...params} />
      },

    ];

    if (!isTemplate) {
      columns.push({
        ...commonColumnVariables,
        filterable: true,
        disableColumnMenu: false,
        field: 'numberOfFilledForms',
        type: 'number',
        align: 'left',
        headerAlign: 'left',
        minWidth: 200,
        headerName: formatMessage({ id: 'Filled forms' }),
        valueGetter: ({ row }) => row.submissionsCount
      });
    }

    if (canManageForms) {


      columns.push({
        ...commonColumnVariables,
        field: 'createdBy',

        minWidth: 250,
        headerName: formatMessage({ id: 'Created by' }),
        valueGetter: ({ row }) => row.createdBy,
        renderCell: ({ row }) => (
          <Chip label={row.createdBy} avatar={<UserAvatar displayName={row.createdBy ?? ''} />} />
        )
      });

      columns.push({
        ...commonColumnVariables,
        disableColumnMenu: false,

        filterable: true,
        type: 'text',
        field: 'selfServe',
        minWidth: 200,
        renderHeader: () => (
          <Tooltip title={formatMessage({ id: 'Forms configured as self-serve allow project members to use them multiple times to submit requests.' })}>
            <span>{formatMessage({ id: 'Self-serve' })}</span>
          </Tooltip>
        ),
        valueGetter: ({ row }) => row.formSettings.formType == FormType.SelfServe ? formatMessage({ id: 'Yes' }) : formatMessage({ id: 'No' })
      });

      columns.push({
        ...commonColumnVariables,
        disableColumnMenu: false,

        filterable: true,
        type: 'singleSelect',
        valueOptions: getEnumValues(FormStatus),
        field: 'status',
        minWidth: 200,
        headerName: formatMessage({ id: 'Status' }),
        renderCell: ({ row }) => (
          <FormStatusChip formStatus={row.status} />

        )
      });

      columns.push({
        ...commonColumnVariables,
        field: 'lastSentOnPublishedOn',

        minWidth: 250,
        headerName: formatMessage({ id: 'Last sent on / Published on' }),
        valueGetter: ({ row }) => row.publishedOn ? dayjs(row.publishedOn).toDate() : undefined,
        valueFormatter: ({ value }) => {
          if (value) {
            return formatDate(value, { dateStyle: 'short', timeStyle: 'short' });
          }
        },
        renderCell: ({ row, formattedValue }) => (
          <Typography>
            {formattedValue ??
              (row.formSettings.formType === FormType.SelfServe
                ? formatMessage({ id: 'Not published' })
                : formatMessage({ id: 'Not sent' }))}
          </Typography>
        )
      });

      columns.push({
        ...commonColumnVariables,
        field: 'actions',
        headerName: '',
        maxWidth: 64,
        renderCell: ({ row }) => (
          <FormActions projectForm={row} />
        )
      });
    }

    return columns;
  }, [formatMessage, isTemplate, canManageForms, formatDate]);

  return (<>
    <Stack gap={2}>
      <DataTable<FormRow>
        noDataMessage={formatMessage({ id: 'No forms found.' })}
        autosizeOnMount
        columns={columns}
        disableColumnPinning
        disableColumnReorder
        disableColumnSelector
        getRowId={p => p.id}
        rows={forms}
        {...dataTableProps}



      />
    </Stack>
  </>
  );
};