import { ActionMenu } from '@components/ActionMenu';
import { ConfirmDialog } from '@components/ConfirmDialog';
import { ResponsiveButton } from '@components/ResponsiveButton';
import { PermissionsModal } from '@modules/securityGroups/components/PermissionsModal/PermissionsModal';
import { Archive, Delete, Share, Start, Unarchive } from '@mui/icons-material';
import { ListItemIcon, MenuItem, Stack } from '@mui/material';
import { getTenantIdentifier } from '@utils/getTenantIdentifier';
import { useNotification } from '@utils/useNotification';
import { useQueryInvalidator } from '@utils/useQueryInvalidator';
import { ShareableResourceType, useDeleteProjectTemplateMutation, useEditProjectMutation, useGetProjectsQuery, useProjectDocumentsQuery, useProjectFormsQuery } from 'gql/index';
import { template } from 'lodash';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { useCurrentProject } from '../utils/useCurrentProject';
import { AddProjectDialog } from './AddProjectDialog/AddProjectDialog';

export const ProjectActions = () => {
  const { formatMessage } = useIntl();
  const { projectId, name, isArchived, isTemplate, query: { refetch }, isCurrentProjectAdmin, canManageProject } = useCurrentProject();
  const invalidateQuery = useQueryInvalidator();
  const { notifySuccess } = useNotification();
  const navigate = useNavigate();
  const [permissionsDialogOpen, setPermissionsDialogDialogOpen] = useState(false);

  const editProjectMutation = useEditProjectMutation({
    onSuccess: () => {
      refetch();
      invalidateQuery(useProjectDocumentsQuery, { projectId });
      invalidateQuery(useProjectFormsQuery, { projectId });
      setArchiveDialogOpen(false);
      setUnarchiveDialogOpen(false);
    }
  });

  const deleteProjectTemplateMutation = useDeleteProjectTemplateMutation();

  const [isArchiveDialogOpen, setArchiveDialogOpen] = useState(false);
  const [isDeleteTemplateDialogOpen, setIsDeletedTemplateDialogOpen] = useState(false);
  const [isUnarchiveDialogOpen, setUnarchiveDialogOpen] = useState(false);
  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);

  const onArchivingProject = (isArchived: boolean) => {
    editProjectMutation.mutate({
      input: {
        projectId, name: name ?? '', isArchived
      }
    }, {
      onSuccess: () => isArchived
        ? notifySuccess(formatMessage({ id: 'Project archived successfully' }))
        : notifySuccess(formatMessage({ id: 'Project reactivated successfully' }))
    });
  };

  const onDeleteProjectTemplate = () => {
    deleteProjectTemplateMutation.mutate({
      input: {
        projectId
      }
    }, {
      onSuccess: () => {
        invalidateQuery(useGetProjectsQuery);
        notifySuccess(formatMessage({ id: 'Project template deleted successfully' }));
        navigate(`/${getTenantIdentifier()}/projects`);
      }
    }
    );
  };

  if (!canManageProject) {
    return undefined;
  }

  return <>
    <Stack direction='row' gap={2}>
      <ResponsiveButton icon={<Start />} variant='contained' onClick={() => setIsAddDialogOpen(true)}>
        {formatMessage({ id: 'Start project' })}
      </ResponsiveButton>

      <ActionMenu variant='squareIcon' buttonProps={{ variant: 'contained' }}>
        {isTemplate && isCurrentProjectAdmin &&
          <MenuItem onClick={() => setPermissionsDialogDialogOpen(true)}>
            <ListItemIcon><Share /></ListItemIcon>
            {formatMessage({ id: 'Share' })}
          </MenuItem>
        }

        {isArchived === false && !isTemplate && (
          <MenuItem onClick={() => setArchiveDialogOpen(true)}>
            <ListItemIcon><Archive /></ListItemIcon>
            {formatMessage({ id: 'Archive' })}
          </MenuItem>
        )}

        {isArchived && !isTemplate && (
          <MenuItem onClick={() => setUnarchiveDialogOpen(true)}>
            <ListItemIcon><Unarchive /></ListItemIcon>
            {formatMessage({ id: 'Reactivate' })}
          </MenuItem>
        )}

        {isTemplate && (
          <MenuItem onClick={() => setIsDeletedTemplateDialogOpen(true)}>
            <ListItemIcon><Delete /></ListItemIcon>
            {formatMessage({ id: 'Delete' })}
          </MenuItem>
        )}
      </ActionMenu>
    </Stack>

    <ConfirmDialog
      title={formatMessage({ id: 'Archive this project?' })}
      open={isArchiveDialogOpen}
      onCancel={() => setArchiveDialogOpen(false)}
      onConfirm={() => onArchivingProject(true)}
      confirmColor='warning'
      loading={editProjectMutation.isLoading}
      content={formatMessage({ id: 'You will no longer be able to modify documents and forms, but you will still be able to manage access to the project.' })}
    />

    <ConfirmDialog
      title={formatMessage({ id: 'Reactivate this project?' })}
      open={isUnarchiveDialogOpen}
      onCancel={() => setUnarchiveDialogOpen(false)}
      onConfirm={() => onArchivingProject(false)}
      confirmColor='info'
      loading={editProjectMutation.isLoading}
      content={formatMessage({ id: 'This will restore the project to an editable state.' })}
    />

    <ConfirmDialog
      title={formatMessage({ id: 'Delete this project template?' })}
      open={isDeleteTemplateDialogOpen}
      onCancel={() => setIsDeletedTemplateDialogOpen(false)}
      onConfirm={() => onDeleteProjectTemplate()}
      confirmColor='error'
      loading={editProjectMutation.isLoading}
      content={formatMessage({ id: 'Once deleted, this project template cannot be restored.' })}
    />

    <PermissionsModal
      open={permissionsDialogOpen}
      onClose={() => setPermissionsDialogDialogOpen(false)}
      resource={{
        id: projectId,
        resourceType: ShareableResourceType.ProjectTemplate,
        name: name ?? '',
        ...template
      }}
    />

    <AddProjectDialog
      projectTemplateId={projectId}
      open={isAddDialogOpen}
      onClose={() => setIsAddDialogOpen(false)}
    />

  </>;
};