import { PageTitle } from '@components/Layout/PageTitle';
import { useCurrentProject } from '@modules/projects/utils/useCurrentProject';
import { ArrowBack } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { useProjectFormQuery } from 'gql/index';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FormActions } from '../../../components/FormActions';
import { FilledFormsTab } from './FilledFormsTab';


export const FormViewerView: React.FC = () => {
  const { formId: formIdString } = useParams();
  const formId = Number(formIdString);

  const navigate = useNavigate();
  const { isCurrentProjectAdmin } = useCurrentProject();

  const { data: form, isFetching: isFetchingForm } = useProjectFormQuery({ formId }, { select: d => d.projectForm });


  return <>
    <PageTitle
      size='small'
      icon={(
        <IconButton onClick={() => navigate('..')}>
          <ArrowBack />
        </IconButton>
      )}
      title={form?.formDefinition?.name ?? ''}
      loading={isFetchingForm}
      pageLoading={isFetchingForm && !form}
      actionButton={isCurrentProjectAdmin && form && (
        <FormActions projectForm={form} />
      )}
    />

    <FilledFormsTab />
  </>;
};