import { Drawer, DrawerProps, Stack, styled } from '@mui/material';
import React from 'react';
import { useResponsive } from '@utils/useResponsive';

interface OwnProps {
  defaultWidth: string;
  children?: React.ReactNode;
}

export type ResponsiveDrawerProps =
  & DrawerProps
  & OwnProps;

const StyledDrawer = styled(
  Drawer,
  { shouldForwardProp: (prop) => prop !== 'isMobile' && prop !== 'defaultWidth' }
)<ResponsiveDrawerProps & { isMobile: boolean; }>(({ theme, isMobile, defaultWidth }) => ({
  zIndex: theme.zIndex.drawer + 1,
  '& .MuiDrawer-paper': {
    width: isMobile ? '100%' : defaultWidth
  }
}));

export const ResponsiveDrawerBase: React.FC<ResponsiveDrawerProps> = (props) => {
  const { children, ...drawerProps } = props;
  const { isMobile } = useResponsive();

  return (
    <StyledDrawer isMobile={isMobile} {...drawerProps}>
      <Stack sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        {children}
      </Stack>
    </StyledDrawer>
  );
};