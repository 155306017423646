import { Property } from '@components/DataDisplay/Property';
import { TextProperty } from '@components/DataDisplay/TextProperty';
import { FileIcon } from '@components/Icons/FileIcon';
import { PageSection } from '@components/PageSection/PageSection';
import { PageSectionPart } from '@components/PageSection/PageSectionPart';
import { ResponsiveButton } from '@components/ResponsiveButton';
import { UnstyledLink } from '@components/UnstyledLink';
import { UserAvatar } from '@components/UserAvatar/UserAvatar';
import { DocumentFileNameAndIcon } from '@modules/documents/components/DocumentFilenameAndIcon';
import { SignatureDialog } from '@modules/documents/components/DocumentSignatureDialog';
import { useCurrentProject } from '@modules/projects/utils/useCurrentProject';
import { useTaskMessages } from '@modules/tasks/taskUtils';
import { Edit } from '@mui/icons-material';
import { Button, Chip, ListItemText, Link as MuiLink, Skeleton, Stack, Typography } from '@mui/material';
import { getTenantIdentifier } from '@utils/getTenantIdentifier';
import { useGetMe } from '@utils/useGetMe';
import { useResponsive } from '@utils/useResponsive';
import { ProjectTaskQuery, ProjectTaskType, useParentTaskQuery } from 'gql/index';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { TaskFormDrawer } from '../TaskFormDrawer';

interface Props {
  task: NonNullable<ProjectTaskQuery['projectTask']>;
}

export const TaskDetailsSection: React.FC<Props> = ({ task }) => {
  const { formatMessage } = useIntl();
  const { formatDateResponsive } = useResponsive();
  const { projectId, canManageTasks, isArchived } = useCurrentProject();
  const { getTaskName } = useTaskMessages();
  const { me } = useGetMe();

  const [isAskSignatureDialogOpen, setIsAskSignatureDialogOpen] = useState(false);
  const [isEditDrawerOpen, setIsEditDrawerOpen] = useState(false);

  const EditButton = (
    <ResponsiveButton variant='contained' onClick={() => setIsEditDrawerOpen(true)} icon={<Edit />}>
      {formatMessage({ id: 'Edit' })}
    </ResponsiveButton>
  );

  const { data: parentTask, isFetching: isFetchingParentTask } = useParentTaskQuery({ taskId: task.id, projectId }, {
    select: d => d.parentTask,
    meta: { nullable: true }
  });

  return (
    <PageSection title={formatMessage({ id: 'Information' })} actionButton={canManageTasks && !isArchived ? EditButton : undefined} >
      <PageSectionPart direction='row' gap={2}>
        <TextProperty
          propertyName={formatMessage({ id: 'Start date' })}
          propertyValue={formatDateResponsive(task?.startDate)}
          emptyText={formatMessage({ id: 'No start date set' })}
        />
        <TextProperty
          propertyName={formatMessage({ id: 'Due date' })}
          propertyValue={formatDateResponsive(task?.dueDate)}
          emptyText={formatMessage({ id: 'No due date set' })}
        />
      </PageSectionPart>

      <PageSectionPart>
        <TextProperty
          propertyName={formatMessage({ id: 'Description' })}
          propertyValue={task?.description}
          emptyText={formatMessage({ id: 'No description provided' })}
        />
      </PageSectionPart>

      <PageSectionPart>
        <Property propertyName={formatMessage({ id: 'Assigned to' })} emptyState={formatMessage({ id: 'Nobody assigned yet' })}>
          {task && task.assignees.length > 0 && (
            <Stack direction='row' gap={1}>
              {task.assignees.map((user, index) => (
                <Chip
                  key={index}
                  label={user.fullName}
                  avatar={<UserAvatar displayName={user.fullName} />}
                  style={{ width: 'min-content' }}
                />
              ))}
            </Stack>
          )}
        </Property>
      </PageSectionPart>

      <PageSectionPart>
        <Property propertyName={formatMessage({ id: 'Parent' })}>
          {isFetchingParentTask && (
            <Skeleton sx={{ width: '100%' }} />
          )}

          {parentTask && (
            <MuiLink component={Link} to={`/${getTenantIdentifier()}/projects/${projectId}/tasks/${parentTask.id}`}>
              {getTaskName(parentTask)}
            </MuiLink>
          )}


          {!isFetchingParentTask && !parentTask && (
            <Typography fontStyle='italic'>
              {formatMessage({ id: 'No parent task' })}
            </Typography>
          )}
        </Property>
      </PageSectionPart>

      {task?.document && (
        <PageSectionPart>
          <Property propertyName={formatMessage({ id: 'Document' })}>
            <DocumentFileNameAndIcon
              disablePadding
              fileName={task.document.fileName}
              modifiedOn={task.document.modifiedOn || task.document.createdOn}
              downloadLink={`/${getTenantIdentifier()}/api/Documents/${task.document.id}`}
              actionButton={!task.document.isSigned && task.document.signatoryCustomerId === me?.id && (
                <Button variant='contained' onClick={() => setIsAskSignatureDialogOpen(true)}>
                  {formatMessage({ id: 'Sign now' })}
                </Button>
              )}
            />
          </Property>
        </PageSectionPart>
      )}

      {task?.projectForm && (
        <PageSectionPart>
          <Property propertyName={formatMessage({ id: 'Form' })}>
            <Stack direction='row' gap={1} pt={1}>
              <FileIcon fileIconType='InternalForm' fontSize='large' />

              <Stack width='100%' direction='row' alignItems='center' justifyContent='space-between'>
                <ListItemText
                  primary={task.projectForm.formDefinition?.name}
                  sx={{ minWidth: '30ch', maxWidth: '25%', textOverflow: 'ellipsis' }}
                />

                <UnstyledLink to={`/${getTenantIdentifier()}/projects/${projectId}/form/${task.projectForm.id}`}>
                  <Button variant='contained'>
                    {formatMessage({ id: 'Go to form' })}
                  </Button>
                </UnstyledLink>
              </Stack>
            </Stack>
          </Property>
        </PageSectionPart>
      )}


      {task?.type === ProjectTaskType.SignDocument && task.document && (
        <SignatureDialog
          documentId={task.document.id}
          actionId={task.document.zohoActionId ?? ''}
          requestId={task.document.zohoRequestId ?? ''}
          open={isAskSignatureDialogOpen}
          onClose={() => setIsAskSignatureDialogOpen(false)}
        />
      )}

      <TaskFormDrawer
        taskId={task?.id}
        open={isEditDrawerOpen}
        onClose={() => setIsEditDrawerOpen(false)}
      />

    </PageSection>
  );
};