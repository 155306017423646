import { GridApiPro, GridKeyValue } from '@mui/x-data-grid-pro';
import { useEffect, useState } from 'react';

export const useCollapsedGroups = (apiRef: React.MutableRefObject<GridApiPro>) => {
  const [collapsedGroupingKeys, setCollapsedGroupingKeys] = useState<GridKeyValue[]>([]);

  useEffect(() => {

    if (apiRef.current) {
      apiRef.current.subscribeEvent('rowExpansionChange', (e) => {

        if (e.childrenExpanded) {

          setCollapsedGroupingKeys((prevValues) => prevValues?.filter(p => p != e.groupingKey));
        }
        else if (e.groupingKey != null) {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          setCollapsedGroupingKeys((prevValues) => [...prevValues ?? [], e.groupingKey!]);
        }
      });
    }
  }, [apiRef]);

  return { collapsedGroupingKeys };
};