import { TableCell, TableCellProps } from '@mui/material';

export const HeaderCell: React.FC<TableCellProps> = (props) => {
  const { children, ...cellProps } = props;
  return (
    <TableCell {...cellProps}
      sx={{
        p: 0,
        py: 2
      }}>
      <strong>
        {children}
      </strong>
    </TableCell>
  );
};