import { LoadingButton } from '@mui/lab';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import React from 'react';
import { useIntl } from 'react-intl';
import { CancelButton } from './Buttons/CancelButton';

export interface ConfirmDialogProps {
  open: boolean;
  title: string;
  content?: string | React.ReactNode;
  confirmText?: string;
  confirmColor?: 'error' | 'inherit' | 'primary' | 'secondary' | 'success' | 'info' | 'warning';
  onCancel: () => void;
  onConfirm: () => void;
  loading?: boolean;
}

export const ConfirmDialog: React.FC<ConfirmDialogProps> = ({ open, onCancel, title, content, confirmText, confirmColor, onConfirm, loading }) => {
  const { formatMessage } = useIntl();

  return (
    <Dialog open={open} onClose={!loading ? onCancel : undefined} sx={{ zIndex: (theme) => theme.zIndex.modal + 10 }}>
      <DialogTitle>
        {title}
      </DialogTitle>

      {content && (
        <DialogContent>
          {typeof content === 'string' ? (
            <DialogContentText>
              {content}
            </DialogContentText>
          ) : content}
        </DialogContent>
      )}

      <DialogActions>
        <CancelButton onClick={onCancel} autoFocus disabled={loading} />

        <LoadingButton loading={loading} onClick={onConfirm} color={confirmColor} variant='contained'>
          {confirmText ?? formatMessage({ id: 'Confirm' })}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};