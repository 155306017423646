import { EditorSavingStatus, SavingStatusContext } from '@components/Editor/TopBar/SavingStatusContext';
import { useEditorHelpers } from '@components/Editor/TopBar/useEditorHelpers';
import { Droppable } from '@hello-pangea/dnd';
import { DroppableEmptyState } from '@modules/forms/components/FormEditor/DroppableEmptyState';
import { Stack, alpha } from '@mui/material';
import { useQueryInvalidator } from '@utils/useQueryInvalidator';
import { WorkflowActionStatus, useAddChildActionMutation, useMoveChildActionMutation, useWorkflowTemplateErrorsQuery } from 'gql/index';
import { useContext, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { getBorderColor } from '../../../../../components/Utils/DroppableComponentUtils';
import { ActionNameByType } from '../../WorflowInstance/Actions/Types';
import { AllActionIds } from '../Actions/types';
import { WorkflowEditorContext } from '../WorkflowEditorContext';
import { useChildActionAddRequestedListener, useChildActionMovedListener } from '../events';
import { WorkflowEditorActionValue, WorkflowEditorDroppableIds, WorkflowEditorDroppableTypes } from '../types';
import { mapActionFragmentToForm } from '../utils';
import { ActionTile } from './ActionTile';


export const DroppableWorkflowAction: React.FC = () => {
  const { formatMessage } = useIntl();
  const invalidateQuery = useQueryInvalidator();

  const {
    actionDefinitions,
    setSelectedActionIndex,
    workflowDefinition,
    actionsFieldArray: { insert, update, remove, move, fields: actions },
    setNotConfiguredActionId
  } = useContext(WorkflowEditorContext);
  const { setEditorSavingStatus, editorSavingStatus } = useContext(SavingStatusContext);
  const { onEditionError, onEditionSuccess } = useEditorHelpers();


  const { mutate: addChildAction, isLoading: isAddingChildAction } = useAddChildActionMutation({ onError: onEditionError, onSuccess: onEditionSuccess });
  const { mutate: moveChildAction, isLoading: isMovingChildAction } = useMoveChildActionMutation({ onError: onEditionError, onSuccess: onEditionSuccess });


  useEffect(() => {
    if ((isAddingChildAction || isMovingChildAction) && editorSavingStatus !== EditorSavingStatus.Saving) {
      setEditorSavingStatus(EditorSavingStatus.Saving);
    }
  }, [editorSavingStatus, isAddingChildAction, isMovingChildAction, setEditorSavingStatus]);

  useChildActionAddRequestedListener(({ createdAtIndex, actionDefinitionId }) => {
    const actionDefinition = actionDefinitions.find(p => p.actionDefinitionId === actionDefinitionId);

    const placeholderAction: WorkflowEditorActionValue = {
      id: 0,
      workflowActionDefinitionId: actionDefinitionId,
      name: actionDefinition?.name ?? '',
      fieldValues: [],
      order: createdAtIndex,
      status: WorkflowActionStatus.NotStarted
    };

    insert(createdAtIndex, placeholderAction);

    // until we allow more complex flow actions, all child actions go under the root Sequence Action
    const rootSequenceActionId = workflowDefinition.rootAction?.id ?? -1;

    addChildAction({
      input: {
        actionConfigurationId: rootSequenceActionId,
        actionDefinitionId: actionDefinitionId,
        orderIndex: createdAtIndex,
        workflowDefinitionId: workflowDefinition.id,
        defaultName: formatMessage(ActionNameByType[actionDefinitionId as AllActionIds])
      }
    }, {
      onSuccess: (response) => {
        invalidateQuery(useWorkflowTemplateErrorsQuery, { workflowId: workflowDefinition.id });
        const createdAction = response.addChildAction.workflowActionConfiguration;
        const actionDefinition = actionDefinitions.find(p => p.actionDefinitionId == createdAction?.workflowActionDefinitionId);
        createdAction && actionDefinition &&
          update(createdAtIndex, mapActionFragmentToForm(createdAction, actionDefinition));
        setNotConfiguredActionId(createdAction?.id, true);
      },
      onError: () => remove(createdAtIndex)
    });
  });

  useChildActionMovedListener(({ sourceIndex, destinationIndex }) => {
    if (workflowDefinition.rootAction == null) return;

    moveChildAction({
      input: {
        parentActionConfigurationId: workflowDefinition.rootAction.id,
        movedActionConfigurationId: actions[sourceIndex].id,
        orderIndex: destinationIndex,
        workflowDefinitionId: workflowDefinition.id
      }
    });

    setSelectedActionIndex(null);
    move(sourceIndex, destinationIndex);
  });

  return (
    <Stack pt={2} gap={2}>
      <Droppable droppableId={WorkflowEditorDroppableIds.steps} type={WorkflowEditorDroppableTypes.step}>
        {(provided, snapshot) => (
          // extra bottom padding ensures it is easier to drag an extra workflow action
          <Stack gap={2} pb={10} {...provided.droppableProps} ref={provided.innerRef} sx={{
            bgcolor: t => snapshot.isDraggingOver ? alpha(t.palette.primary.main, t.palette.action.hoverOpacity) : t.palette.background.default,
            boxShadow: 'none',
            border: getBorderColor(snapshot.isDraggingOver, actions.length)
          }}>
            {actions.map((action, index) => (
              <ActionTile
                actionIndex={index}
                key={action.uniqueId}
              />
            ))}

            {provided.placeholder}

            {(actions.length === 0 || snapshot.isDraggingOver) && (
              <DroppableEmptyState placeholderText={formatMessage({ id: 'Steps can be dropped here' })} />
            )}
          </Stack>
        )}
      </Droppable>

    </Stack>
  );
};
