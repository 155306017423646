import { LicenseInfo } from '@mui/x-data-grid-pro';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { App } from './modules/application/App';

const licenseKey = import.meta.env.VITE_DATAGRIDPRO_LICENSE_KEY;
if (!licenseKey) throw new Error('Missing license key for data grid pro');

LicenseInfo.setLicenseKey(licenseKey);

// Required to allow directory uploads: https://github.com/facebook/react/issues/3468#issuecomment-1031366038
declare module 'react' {
  interface InputHTMLAttributes<T> extends HTMLAttributes<T> {
    // extends React's HTMLAttributes
    directory?: string;
    webkitdirectory?: string;
  }
}

const jsdWidgetDrawerHideObserver = new MutationObserver(() => {
  const jsdWidgtet = document.getElementById('jsd-widget');

  if (!jsdWidgtet) return;

  let hasDrawer = false;
  const drawers = document.getElementsByClassName('MuiDrawer-modal');
  for (let index = 0; index < drawers.length; index++) {
    const drawer = drawers[index];
    if (!drawer.classList.contains('MuiModal-hidden')) {
      hasDrawer = true;
      break;
    }
  }

  const hasDialog = document.getElementsByClassName('MuiDialog-root').length > 0;

  if (hasDrawer || hasDialog) {
    jsdWidgtet.style.visibility = 'hidden';
  } else {
    jsdWidgtet.style.visibility = 'visible';
  }
});

jsdWidgetDrawerHideObserver.observe(document.body, { childList: true });


ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
