import { getFromLocalStorage } from '@utils/storageUtils';
import { Chance } from 'chance';
import dayjs from 'dayjs';
import _, { isArray } from 'lodash';
import { ReactNode, createContext, useCallback, useEffect, useState } from 'react';

export type FileUpload = {
  fileName: string,
  formData: FormData,
  path?: string,
  openRequest: () => XMLHttpRequest;
  onSuccess: (response: unknown) => void;
  onError?: (modelStateError: string) => void;
};

type UploadManagerContextType = {
  queueUpload: (batchId: string, fileUpload: FileUpload) => void,
  cancelUpload: (id: string) => void,
  removeUpload: (id: string) => void,
  uploads: FileUploadState[];
};

export type FileUploadState = {
  batchId: string;
  id: string;
  fileUpload: FileUpload;
  uploadProgress: number;
  state: 'queued' | 'uploading' | 'success' | 'failed' | 'aborted';
  request?: XMLHttpRequest;
};


export type BatchCompleted = {
  batchId: string;
  hasErrors: boolean;
  allAborted: boolean;
  failedFileNames: string[];
};

export const UploadManagerContext = createContext<UploadManagerContextType>({
  queueUpload: (_batchId: string, _fileUpload: FileUpload) => { /* default */ },
  cancelUpload: (_id: string) => { /* default */ },
  removeUpload: (_id: string) => { /* default */ },
  uploads: []
});


export const UploadManagerContextProvider = (props: { children: ReactNode, onBatchComplete: (batch: BatchCompleted) => void; }) => {
  const [uploadQueue, setUploadQueue] = useState<FileUploadState[]>([]);


  const updateUploadQueueItem = useCallback((id: string, valueUpdater: (oldValue: FileUploadState) => FileUploadState) => {
    setUploadQueue(oldQueue => oldQueue.map(oldValue => oldValue.id == id ? valueUpdater(oldValue) : oldValue));
  }, []);

  const queueUpload = (batchId: string, fileUpload: FileUpload) => {
    setUploadQueue((prevState) => ([
      ...prevState,
      {
        id: Chance().guid(),
        batchId: batchId,
        fileUpload,
        state: 'queued',
        uploadProgress: 0
      }
    ]));
  };

  const cleanupQueue = useCallback(() => {
    const batches = _.groupBy(uploadQueue, p => p.batchId);

    Object.keys(batches).forEach(batchId => {
      const batch = batches[batchId];
      const isBatchDone = !batch.some(p => p.state === 'queued' || p.state === 'uploading');

      if (!isBatchDone) return;

      const failedFileNames = batch.filter(p => p.state === 'failed').map(p => p.fileUpload.fileName);

      setUploadQueue(prevState => prevState.filter(p => p.batchId != batchId));

      props.onBatchComplete({
        batchId,
        allAborted: batch.every(p => p.state === 'aborted'),
        hasErrors: failedFileNames.length > 0,
        failedFileNames
      });
    });

  }, [props, uploadQueue]);

  const cancelUpload = useCallback((id: string) => {
    const upload = uploadQueue.find(p => p.id === id);

    if (upload?.request) {
      upload.request.abort();
    }

    if (upload) {
      updateUploadQueueItem(upload.id, (oldValue) => ({ ...oldValue, state: 'aborted' }));
    }
  }, [updateUploadQueueItem, uploadQueue]);

  const removeUpload = useCallback((id: string) => {
    const uploadIndex = uploadQueue.findIndex(p => p.id === id);

    if (uploadIndex > -1) {
      const upload = uploadQueue[uploadIndex];
      upload.request?.abort();
      uploadQueue.splice(uploadIndex);
      setUploadQueue(uploadQueue);
    }
  }, [uploadQueue]);


  const uploadFile = useCallback(() => {
    const isUploading = uploadQueue.some(p => p.state === 'uploading');

    const queuedUpload = uploadQueue.find(p => p.state === 'queued');

    if (queuedUpload == null || isUploading) {
      return;
    }

    updateUploadQueueItem(queuedUpload.id, p => ({ ...p, state: 'uploading' }));

    const request = queuedUpload.fileUpload.openRequest();
    request.setRequestHeader('Accept-Language', getFromLocalStorage('preferredLocale') ?? dayjs.locale());

    request.upload.addEventListener('progress', (e) => {

      const uploadProgress = (e.loaded / e.total) * 100;
      updateUploadQueueItem(queuedUpload.id, p => ({ ...p, uploadProgress, state: 'uploading' }));
    });

    request.addEventListener('abort', () => {
      updateUploadQueueItem(queuedUpload.id, p => ({ ...p, uploadProgress: 0, state: 'aborted' }));
    });

    request.addEventListener('error', () => {
      updateUploadQueueItem(queuedUpload.id, p => ({ ...p, uploadProgress: 0, state: 'failed' }));

    });

    request.addEventListener('load', () => {
      if (request.status === 200) {
        updateUploadQueueItem(queuedUpload.id, p => ({ ...p, uploadProgress: 100, state: 'success' }));

        queuedUpload.fileUpload.onSuccess(request.response);
      } else {

        let error: string;

        try {
          error = Object.values(JSON.parse(request.responseText)['errors'])
            .flatMap(value => isArray(value) ? value.map(p => p as string) : value as string)
            .reduce((a, b) => `${a}\n${b}`);

        }
        catch {
          error = 'Unknown server error';
        }

        updateUploadQueueItem(queuedUpload.id, p => ({ ...p, uploadProgress: 0, state: 'failed' }));

        queuedUpload.fileUpload.onError && queuedUpload.fileUpload.onError(error);
      }
    });

    request.send(queuedUpload.fileUpload.formData);


    updateUploadQueueItem(queuedUpload.id, p => ({ ...p, request }));
  }, [updateUploadQueueItem, uploadQueue]);

  useEffect(() => {
    uploadFile();
  }, [uploadFile, uploadQueue]);

  useEffect(() => {
    cleanupQueue();
  }, [cleanupQueue, uploadQueue]);

  return (
    <UploadManagerContext.Provider value={{ queueUpload, uploads: uploadQueue, cancelUpload, removeUpload }}>
      {props.children}
    </UploadManagerContext.Provider>
  );
};