import { Theme, useMediaQuery } from '@mui/material';
import { useIntl } from 'react-intl';

export const useResponsive = () => {
  const { formatDate } = useIntl();

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const isHorizontal = useMediaQuery((theme: Theme) => theme.breakpoints.up(568));

  const formatDateResponsive = (value: string | number | Date | null | undefined) =>
    value ? formatDate(value, { dateStyle: isMobile ? 'short' : 'long' }) : '';

  return { isMobile, isHorizontal, formatDateResponsive };
};