import { useIntl } from 'react-intl';
import { ConfirmDialog } from '../../../../components/ConfirmDialog';
import { useDeleteTaskMutation, useMyTasksQuery, useProjectTasksQuery } from '../../../../gql';
import { useNotification } from '../../../../utils/useNotification';
import { useQueryInvalidator } from '../../../../utils/useQueryInvalidator';

interface Props {
  isOpen: boolean;
  projectId: number;
  taskId: number;
  onDeleteSuccess?: () => void;
  onCancel?: () => void;
}

export const DeleteTaskDialog: React.FC<Props> = ({ projectId, taskId, isOpen, onCancel, onDeleteSuccess }) => {
  const { formatMessage } = useIntl();
  const { notifySuccess } = useNotification();
  const invalidateQuery = useQueryInvalidator();
  const deleteTaskMutation = useDeleteTaskMutation({
    onSuccess: () => {
      notifySuccess(formatMessage({ id: 'Task deleted successfully' }));
      invalidateQuery(useMyTasksQuery, { projectId });
      invalidateQuery(useProjectTasksQuery, { projectId });
      onDeleteSuccess && onDeleteSuccess();
    }
  });

  const deleteTask = async () => {
    await deleteTaskMutation.mutateAsync({ projectId: projectId, taskId: taskId });
  };

  return <ConfirmDialog
    open={isOpen}
    onCancel={() => onCancel && onCancel()}
    onConfirm={deleteTask}
    title={formatMessage({ id: 'Delete task?' })}
    content={formatMessage({
      id: 'This task, and all its subtasks, will be permanently deleted. Are you sure?'
    })}
    confirmText={formatMessage({ id: 'Remove' })}
    confirmColor='error'
    loading={deleteTaskMutation.isLoading}
  />;
};