import { Close } from '@mui/icons-material';
import { IconButton, LinearProgress, ModalProps, Paper, Stack, styled } from '@mui/material';
import React from 'react';

interface SettingsDrawerHeaderProps {
  children?: React.ReactNode;
  onClose: ModalProps['onClose'];
  disabled?: boolean;
  loading?: boolean;
}

const HeaderBox = styled(Paper)(({ theme }) => ({
  boxShadow: 'none',
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  zIndex: theme.zIndex.drawer + 1,
}));

export const DrawerHeader: React.FC<SettingsDrawerHeaderProps> = (props) => {
  return (
    <HeaderBox square elevation={16}>
      <Stack direction='row' alignItems='center' minHeight='57px'>
        <IconButton color='inherit' disabled={props.disabled} onClick={() => props.onClose?.({}, 'backdropClick')} sx={{ ml: 1 }}>
          <Close />
        </IconButton>
        {props.children}
      </Stack>

      <LinearProgress style={{ visibility: props.loading ? 'visible' : 'hidden' }} />
    </HeaderBox>
  );
};
