export const getFromLocalStorage = (key: string): string | undefined => {
  try {
    return localStorage.getItem(key) ?? undefined;
  } catch (error) {
    console.error('Failed to retrieve a value from the local storage.', error);
    return undefined;
  }
};

export const trySetInLocalStorage = (key: string, item: string): boolean => {
  try {
    localStorage.setItem(key, item);
    return true;
  } catch (error) {
    console.error('Failed to store a value in the local storage.', error);
    return false;
  }
};

export const tryRemoveFromLocalStorage = (key: string): boolean => {
  try {
    localStorage.removeItem(key);
    return true;
  } catch (error) {
    console.error('Failed to remove an item from the local storage.');
    return false;
  }
};