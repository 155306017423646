import { FormHelperText } from '@mui/material';
import { useIntl } from 'react-intl';
import { ActionConfigurationComponentProps } from '../../WorkflowEditor/Actions/types';
import { WorkflowFieldInput } from '../../WorkflowEditor/Fields/WorkflowFieldInput';
import { CompleteDocumentSignatureActionDefinition } from './CompleteDocumentSignatureActionDefinition';

export const CompleteDocumentSignatureActionConfiguration: React.FC<ActionConfigurationComponentProps> = () => {
  const { formatMessage } = useIntl();

  return (
    <>
      <WorkflowFieldInput
        fieldId={CompleteDocumentSignatureActionDefinition.FieldId_SignatureRequestId}
        textFieldProps={{ disabled: true }}
        label={formatMessage({ id: 'Signature request to complete' })}
      />
      <FormHelperText>
        {formatMessage({ id: 'This action will complete once the signature request has been completed.' })}
      </FormHelperText>
    </>
  );
};