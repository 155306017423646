import { MemberInfoDrawer } from '@modules/projects/components/Members/MemberInfoDrawer';
import { MemberListItem } from '@modules/projects/components/Members/MemberListItem';
import { useCurrentProject } from '@modules/projects/utils/useCurrentProject';
import { Breadcrumbs, Link as MuiLink, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { GridColDef } from '@mui/x-data-grid';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { DataTable } from '../../../components/DataTable';
import { BuiltInRoles, SecurityGroup, useSecurityGroupQuery } from '../../../gql';
import { getTenantIdentifier } from '../../../utils/getTenantIdentifier';

import { PageContainer } from '@components/Layout/PageContainer';
import { GroupMemberActions } from '../components/GroupMemberActions';
import { SecurityGroupActions } from '../components/SecurityGroupActions';

type MemberRow = Pick<Pick<SecurityGroup, 'members'>['members'][0], 'id' | 'fullName' | 'email' | 'builtInRoles'>;

export const GroupMembersView = () => {
  const { groupId } = useParams();

  const { projectId } = useCurrentProject();

  const { formatMessage } = useIntl();
  const { data: securityGroup, isFetching: isLoadingGroup } = useSecurityGroupQuery({ projectId, securityGroupId: groupId ?? '' }, { select: d => d.securityGroup });

  const [selectedUserId, setSelectedUserId] = useState<string>();
  const selectedMember = securityGroup?.members?.find(m => m.id === selectedUserId);

  const columns: GridColDef<MemberRow>[] = [
    {
      field: 'name',
      headerName: formatMessage({ id: 'Name' }),
      minWidth: 250,
      flex: 1,
      sortable: false,
      valueGetter: p => p.row.fullName,
      renderCell: ({ row }) => (
        <MemberListItem
          member={row}
          disableAvatarIcon
          disableSecondaryText
          disableSorroundBorders
          onClick={() => setSelectedUserId(row.id)}
        />
      )
    },
    {
      field: 'email',
      headerName: formatMessage({ id: 'Email' }),
      minWidth: 250,
      sortable: false,
      valueGetter: p => p.row.email
    },
    {
      field: 'type',
      headerName: formatMessage({ id: 'Type' }),
      width: 200,
      sortable: false,
      valueGetter: p => p.row.builtInRoles.find(p => p === BuiltInRoles.LimitedAccess) ? formatMessage({ id: 'External User' }) : formatMessage({ id: 'User' })
    },
    {
      field: 'actions',
      headerName: '',
      maxWidth: 64,
      sortable: false,
      renderCell: p => <GroupMemberActions projectId={Number(projectId)} securityGroupId={securityGroup?.id ?? ''} userId={p.row.id} />
    },
  ];

  return <>
    <PageContainer>
      <Stack gap={2}>
        <Stack direction='row' justifyContent='space-between' alignItems='center'>
          <Breadcrumbs>
            <MuiLink
              component={Link}
              to={`/${getTenantIdentifier()}/projects/${projectId}/permissions/groups`}
              color={'inherit'}
              underline='hover'
              sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
            >
              <Typography
                key={'permissions'}
                color={'inherit'}
                sx={{ display: 'flex', alignItems: 'center' }}
              >
                {formatMessage({ id: 'Permissions' })}
              </Typography>
            </MuiLink>
            <MuiLink
              component={Link}
              to='..'
              color={'inherit'}
              underline='hover'
            >
              <Typography
                key={'groups'}
                color={'inherit'}
                sx={{ display: 'flex', alignItems: 'center' }}
              >
                {formatMessage({ id: 'Groups' })}
              </Typography>
            </MuiLink>
            <Typography
              key={'folder'}
              color={'text.primary'}
              sx={{ display: 'flex', alignItems: 'center' }}
            >
              {securityGroup?.name}
            </Typography>
          </Breadcrumbs>

          {securityGroup && (
            <SecurityGroupActions securityGroup={securityGroup} actionMenuVariant='squareIcon' />
          )}
        </Stack>

        <Stack direction='row' justifyContent='space-between' alignItems='center' flexWrap={'wrap'}>
          <Typography variant='body1'>{formatMessage({ id: 'Members' })}</Typography>

        </Stack>

        <DataTable
          noDataMessage={formatMessage({ id: 'No members yet.' })}
          loading={isLoadingGroup}
          rows={securityGroup?.members ?? []}
          getRowId={p => p.id}
          columns={columns}
          disableColumnFilter
          disableColumnMenu
          disableColumnSelector
          disableDensitySelector
          disableRowSelectionOnClick
          sx={{
            '& .MuiDataGrid-cell:first-of-type': {
              padding: 0
            }
          }}
        />
      </Stack>
    </PageContainer>

    {selectedMember && (
      <MemberInfoDrawer
        user={selectedMember}
        projectId={Number(projectId)}
        onClose={() => setSelectedUserId(undefined)}
      />
    )}
  </>;
};