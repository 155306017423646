import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Button } from '@mui/material';
import { Stack } from '@mui/system';
import React, { ReactNode, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

export type MultiStepFormStep = {
  content: (opts: { nextStep: () => void; }) => ReactNode;
  // Callback handleSubmit
  onNext?: (callback: () => void) => () => void;
  disableNext?: boolean;
  hideNext?: boolean;
  disablePadding?: boolean;
};

export type MultiStepFormProps = {
  activeStep?: number;
  onStepChange?: (value: number) => void;

  onSubmit: () => void;
  isSubmitting: boolean;
  disableSubmit?: boolean;

  steps: (MultiStepFormStep | boolean | undefined)[];
};

export const MultiStepForm: React.FC<MultiStepFormProps> = ({ activeStep: controlledActiveStep, onStepChange: controlledSetActiveStep, onSubmit, isSubmitting, disableSubmit, steps: unfilteredSteps }) => {
  const { formatMessage } = useIntl();

  const steps = useMemo(() => unfilteredSteps.filter(s => typeof s !== 'boolean' && s != null), [unfilteredSteps]);

  const [uncontrolledActiveStep, uncontrolledSetActiveStep] = useState(0);
  const activeStep = controlledActiveStep ?? uncontrolledActiveStep;
  const setActiveStep = controlledSetActiveStep ?? uncontrolledSetActiveStep;

  const previousStep = () => setActiveStep(activeStep - 1);
  const nextStep = () => setActiveStep(activeStep + 1);

  const isLastStep = activeStep === steps.length - 1;

  const currentStep = useMemo(() => steps[activeStep], [activeStep, steps]);

  return (
    <Stack height='100%' overflow='hidden'>
      <Stack p={currentStep.disablePadding ? 0 : 2} flexGrow={1} overflow='auto' gap={2}>
        {currentStep.content({ nextStep })}
      </Stack>

      <Stack p={2} direction='row' gap={2} width='100%' justifyContent='end'>
        {activeStep > 0 && (
          <Button disabled={isSubmitting} onClick={previousStep} startIcon={<ChevronLeft />}>
            {formatMessage({ id: 'Back' })}
          </Button>
        )}

        {isLastStep && (
          <LoadingButton loading={isSubmitting} disabled={disableSubmit} variant='contained' onClick={onSubmit}>
            {formatMessage({ id: 'Submit' })}
          </LoadingButton>
        )}

        {!isLastStep && currentStep.onNext && currentStep.hideNext != true && (
          <Button variant='contained' endIcon={<ChevronRight />} onClick={currentStep.onNext(nextStep)} disabled={currentStep.disableNext}>
            {formatMessage({ id: 'Next' })}
          </Button>
        )}
      </Stack>
    </Stack>
  );
};