import { DisableableTooltip } from '@components/Tooltips/DisableableTooltip';
import { useCanEditUser } from '@modules/projects/utils/useCanEditUser';
import { Edit, Email, Phone } from '@mui/icons-material';
import { Button, Divider, Grid, IconButton, ListItem, ListItemIcon, ListItemText, Stack } from '@mui/material';
import { ApplicationUser } from 'gql/index';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { DrawerSection } from '../../../components/Drawers/DrawerSection';
import { EditUserDetailsDrawer } from './EditUserDetailsDrawer';

interface Props {
  user: Pick<ApplicationUser, 'id' | 'firstName' | 'lastName' | 'business' | 'title' | 'email' | 'phoneNumber'>;
}

export const UserDetails: React.FC<Props> = ({ user }) => {
  const { formatMessage } = useIntl();

  const [isEditUserOpen, setEditUserOpen] = useState(false);

  const { canEditUserDetails } = useCanEditUser(user.id);

  return (
    <Stack>
      <DrawerSection title={formatMessage({ id: 'Personal details' })} actionButton={canEditUserDetails !== 'doNotShow' && (
        <DisableableTooltip disabled={canEditUserDetails === true} title={canEditUserDetails}>
          <Button variant='contained' onClick={() => setEditUserOpen(true)} disabled={canEditUserDetails !== true} sx={{ minWidth: 'unset', p: 1 }}>
            <Edit />
          </Button>
        </DisableableTooltip>
      )}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <ListItemText
              primary={user.firstName}
              secondary={formatMessage({ id: 'First name' })}
            />
          </Grid>

          <Grid item xs={6}>
            <ListItemText
              primary={user.lastName}
              secondary={formatMessage({ id: 'Last name' })}
            />
          </Grid>

          <Grid item xs={6}>
            <ListItemText
              primaryTypographyProps={{ sx: { fontStyle: user.business == null ? 'italic' : 'normal' } }}
              primary={user.business ?? formatMessage({ id: 'Not set' })}
              secondary={formatMessage({ id: 'Business' })}
            />
          </Grid>

          <Grid item xs={6}>
            <ListItemText
              primaryTypographyProps={{ sx: { fontStyle: user.title == null ? 'italic' : 'normal' } }}
              primary={user.title || formatMessage({ id: 'Not set' })}
              secondary={formatMessage({ id: 'Title' })}
            />
          </Grid>
        </Grid>
      </DrawerSection>

      <Divider />

      <DrawerSection title={formatMessage({ id: 'Contact details' })}>

        <ListItem disableGutters>
          <ListItemText
            primaryTypographyProps={{ sx: { fontStyle: user.email == null ? 'italic' : 'normal' } }}
            primary={user.email || formatMessage({ id: 'Not set' })}
            secondary={formatMessage({ id: 'Email' })}
          />

          {user.email && (
            <ListItemIcon>
              <IconButton color='primary' LinkComponent='a' href={`mailto:${user.email}`}>
                <Email />
              </IconButton>
            </ListItemIcon>
          )}
        </ListItem>

        <ListItem disableGutters>
          <ListItemText
            primary={user.phoneNumber ?? formatMessage({ id: 'Not set' })}
            secondary={formatMessage({ id: 'Phone number' })}
          />

          {user.phoneNumber && (
            <ListItemIcon>
              <IconButton color='primary' LinkComponent='a' href={`tel:${user.phoneNumber}`}>
                <Phone />
              </IconButton>
            </ListItemIcon>
          )}
        </ListItem>

      </DrawerSection>

      <EditUserDetailsDrawer userId={user.id} open={isEditUserOpen} onClose={() => setEditUserOpen(false)} />
    </Stack>
  );
};