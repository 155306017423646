import { useCurrentProject } from '@modules/projects/utils/useCurrentProject';
import { useProjectFormSubmissionsQuery } from 'gql/index';
import { useParams } from 'react-router-dom';

export const useGetFilledForms = () => {
  const { projectId } = useCurrentProject();
  const { formId: formIdString } = useParams();
  const formId = Number(formIdString);
  const { data: submissions, isFetching } = useProjectFormSubmissionsQuery({ projectId, formId }, { select: d => d.projectFormSubmissions });

  const submissionsCount = submissions?.length;

  return { submissions, isFetching, submissionsCount };
};