import { ActionMenu } from '@components/ActionMenu';
import { EditMemberSecurityGroupDrawer } from '@modules/projects/components/Members/EditMemberSecurityGroupDrawer';
import { useCurrentProject } from '@modules/projects/utils/useCurrentProject';
import { EditUserDetailsDrawer } from '@modules/users/components/EditUserDetailsDrawer';
import { Edit, Group, RemoveCircle } from '@mui/icons-material';
import { ListItemIcon, ListItemText, MenuItem, Tooltip } from '@mui/material';
import { useGetMe } from '@utils/useGetMe';
import { useQueryInvalidator } from '@utils/useQueryInvalidator';
import { useRemoveSecurityGroupMemberMutation, useSecurityGroupQuery, useSecurityGroupsForProjectQuery } from 'gql/index';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { ConfirmDialog } from '../../../components/ConfirmDialog';
import { useNotification } from '../../../utils/useNotification';

interface Props {
  projectId: number;
  securityGroupId: string;
  userId: string;
}

export const GroupMemberActions: React.FC<Props> = ({ securityGroupId, projectId, userId }) => {
  const { formatMessage } = useIntl();
  const { notifySuccess } = useNotification();
  const invalidateQuery = useQueryInvalidator();
  const { me } = useGetMe();
  const { canManageProject } = useCurrentProject();

  const [editUserDetailsOpen, setEditUserDetailsOpen] = useState(false);
  const [editSecurityGroupsOpen, setEditSecurityGroupsOpen] = useState(false);
  const [confirmRemoveDialogOpen, setConfirmRemoveDialogOpen] = useState(false);

  const { mutate: removeUser, isLoading } = useRemoveSecurityGroupMemberMutation({
    onSuccess: (_, vars) => {
      notifySuccess(formatMessage({ id: 'User removed successfully' }));
      invalidateQuery(useSecurityGroupsForProjectQuery, { projectId });
      invalidateQuery(useSecurityGroupQuery, { securityGroupId: vars.input.securityGroupId });
    },
    onSettled: () => setConfirmRemoveDialogOpen(false)
  });

  const onRemoveConfirm = () => {
    removeUser({ input: { projectId: projectId, securityGroupId: securityGroupId, userId } });
  };

  const EditSecurityGroupMenuItem = (
    <MenuItem onClick={() => setEditSecurityGroupsOpen(true)} disabled={me?.id == userId}>
      <ListItemIcon><Group /></ListItemIcon>
      <ListItemText primary={formatMessage({ id: 'Edit security group' })} />
    </MenuItem>
  );

  if (!canManageProject) {
    return undefined;
  }

  return (
    <>
      <ActionMenu>
        <MenuItem onClick={() => setEditUserDetailsOpen(true)}>
          <ListItemIcon><Edit /></ListItemIcon>
          <ListItemText primary={formatMessage({ id: 'Edit user' })} />
        </MenuItem>
        {me?.id == userId
          ?
          <Tooltip title={formatMessage({ id: 'As a project administrator, you cannot update your security group' })} arrow placement='right'>
            <span>{EditSecurityGroupMenuItem}</span>
          </Tooltip>
          :
          EditSecurityGroupMenuItem
        }
        <MenuItem onClick={() => setConfirmRemoveDialogOpen(true)}>
          <ListItemIcon><RemoveCircle /></ListItemIcon>
          <ListItemText primary={formatMessage({ id: 'Remove' })} />
        </MenuItem>
      </ActionMenu>

      <EditUserDetailsDrawer
        open={editUserDetailsOpen}
        userId={userId}
        onClose={() => setEditUserDetailsOpen(false)}
      />
      <EditMemberSecurityGroupDrawer
        open={editSecurityGroupsOpen}
        userId={userId}
        onClose={() => setEditSecurityGroupsOpen(false)}
      />

      <ConfirmDialog
        open={confirmRemoveDialogOpen}
        title={formatMessage({ id: 'Remove member' })}
        content={formatMessage({ id: 'The user will be removed from the group and lose access to the project. Are you sure?' })}
        confirmText={formatMessage({ id: 'Remove' })}
        confirmColor='error'
        onCancel={() => setConfirmRemoveDialogOpen(false)}
        onConfirm={onRemoveConfirm}
        loading={isLoading}
      />
    </>
  );
};