import { useMemo } from 'react';
import { MessageDescriptor, defineMessages, useIntl } from 'react-intl';

export type EstimatesData = {
  costEstimates: CostEstimate[],
  totalEstimate: number,
};

export type RentalRevenue = {
  id: string,
  name: string,
  annualCostPerSquareFootage: number,
  usableSquareFootage: number,
};

export type AdditionalRevenue = {
  id: string,
  name: string,
  annualCost: number,
};

export enum OperatingCostCalculationMethod {
  FixedCost = 0,
  CostPerSquareFootage = 1,
  PercentageOfPurchasePrice = 2,
  PercentageOfRevenue = 3
}

export type OperatingCost = {
  id: string,
  name: string,
  calculationMethod: OperatingCostCalculationMethod,
  cost: number,
  annualCost: number,
};


export type CostEstimate = {
  id: string,
  name: string,
  estimate: number,
  childCosts: CostEstimate[] | undefined
}

export type MortgageCalculatorForm = {
  scenarios: MortgageCalculatorScenario[],
}

export type MortgageCalculatorScenario = {
  id: string,
  downPaymentPercent: number,
  loanAmount: number,
  annualInterestRate: number,
  termDuration: number,
  amortizationPeriod: number,
  monthlyInterestRate: number,
  monthlyPayment: number,
  annualPayment: number,
  totalPayment: number,
  totalInterestsPayment: number
}

export type RevenuesDetails = {
  rentalRevenues: RentalRevenue[],
  additionalRevenue: AdditionalRevenue[],
  totalUsableSquareFootage: number,
  commonAreaPercentage: number,
  targetRevenuePercent: number,
  selectedMortgageScenario: number,
  vacancy: number
}

export type CommercialConstructionCalculatorData = {
  name: string,
  calculatorValues: EstimatesData,
  mortgageCalculator: MortgageCalculatorForm,
  revenues: RevenuesDetails,

  operatingCosts: OperatingCost[]

}

export const useDefaultCalculatorValues = () => {
  const { formatMessage } = useIntl();
  const defaultValues = useMemo<CommercialConstructionCalculatorData>(() => ({
    name: formatMessage({ id: 'Project Estimate' }),
    mortgageCalculator: {
      purchasePrice: 0,
      scenarios: [{
        id: crypto.randomUUID(),
        downPaymentPercent: 0.20,
        loanAmount: 0,
        termDuration: 0,
        annualPayment: 0,
        monthlyPayment: 0,
        totalInterestsPayment: 0,
        monthlyInterestRate: 0,
        totalPayment: 0,
        amortizationPeriod: 25,
        annualInterestRate: 0
      },
      {
        id: crypto.randomUUID(),
        downPaymentPercent: 0.20,
        loanAmount: 0,
        termDuration: 0,
        annualPayment: 0,
        monthlyPayment: 0,
        totalInterestsPayment: 0,
        monthlyInterestRate: 0,
        totalPayment: 0,
        amortizationPeriod: 25,
        annualInterestRate: 0
      },
      {
        id: crypto.randomUUID(),
        downPaymentPercent: 0.20,
        loanAmount: 0,
        termDuration: 0,
        annualPayment: 0,
        monthlyPayment: 0,
        totalInterestsPayment: 0,
        monthlyInterestRate: 0,
        totalPayment: 0,
        amortizationPeriod: 25,
        annualInterestRate: 0
      }]
    },
    calculatorValues: {
      totalEstimate: 0,
      costEstimates: [{
        id: crypto.randomUUID(),
        name: formatMessage({ id: 'Building acquisition' }),
        estimate: 0,
        childCosts: undefined
      },
      {
        id: crypto.randomUUID(),
        name: formatMessage({ id: 'Soft costs' }),
        estimate: 0,
        childCosts: [{
          id: crypto.randomUUID(),
          name: formatMessage({ id: 'Soft cost 1' }),
          estimate: 0,
          childCosts: undefined
        },
        {
          id: crypto.randomUUID(),
          name: formatMessage({ id: 'Soft cost 2' }),
          estimate: 0,
          childCosts: undefined
        }]
      },
      {
        id: crypto.randomUUID(),
        name: formatMessage({ id: 'Construction' }),
        estimate: 0,
        childCosts: undefined
      }]
    },
    revenues: {
      rentalRevenues: [],
      additionalRevenue: [],
      targetRevenuePercent: 0.1,
      targetRevenue: 0,
      totalUsableSquareFootage: 0,
      commonAreaPercentage: 0,
      selectedMortgageScenario: 0,
      commercialRentalSquareFootage: undefined,
      vacancy: 0.05

    },
    operatingCosts: [{
      id: crypto.randomUUID(),
      name: formatMessage({ id: 'Taxes' }),
      calculationMethod: OperatingCostCalculationMethod.FixedCost,
      annualCost: 0,
      cost: 0
    },
    {
      id: crypto.randomUUID(),
      name: formatMessage({ id: 'Insurance' }),
      calculationMethod: OperatingCostCalculationMethod.FixedCost,
      annualCost: 0,
      cost: 0
    },
    {
      id: crypto.randomUUID(),
      name: formatMessage({ id: 'Public services' }),
      calculationMethod: OperatingCostCalculationMethod.FixedCost,
      annualCost: 0,
      cost: 0
    },
    {
      id: crypto.randomUUID(),
      name: formatMessage({ id: 'Maintenance and repairs' }),
      calculationMethod: OperatingCostCalculationMethod.PercentageOfRevenue,
      annualCost: 0,
      cost: 0.01
    },
    {
      id: crypto.randomUUID(),
      name: formatMessage({ id: 'Management' }),
      calculationMethod: OperatingCostCalculationMethod.FixedCost,
      annualCost: 0,
      cost: 0
    },
    {
      id: crypto.randomUUID(),
      name: formatMessage({ id: 'Structural reserve' }),
      calculationMethod: OperatingCostCalculationMethod.PercentageOfRevenue,
      annualCost: 0,
      cost: 0.02
    },
    {
      id: crypto.randomUUID(),
      name: formatMessage({ id: 'Professional fees' }),
      calculationMethod: OperatingCostCalculationMethod.FixedCost,
      annualCost: 0,
      cost: 0
    },]
  }), [formatMessage]);

  return defaultValues;
};

export const costCalculationMethodMessages: Record<OperatingCostCalculationMethod, MessageDescriptor> = defineMessages({
  [OperatingCostCalculationMethod.FixedCost]: { id: 'Fixed' },
  [OperatingCostCalculationMethod.CostPerSquareFootage]: { id: '$ per sq. ft.' },
  [OperatingCostCalculationMethod.PercentageOfPurchasePrice]: { id: '% of purchase price' },
  [OperatingCostCalculationMethod.PercentageOfRevenue]: { id: '% of revenue' },
});


export const isPercentageBasedCalculationMethod = (method: OperatingCostCalculationMethod) => {
  return method === OperatingCostCalculationMethod.PercentageOfPurchasePrice
    || method === OperatingCostCalculationMethod.PercentageOfRevenue;
};

export const calculateAnnualOperatingCost = (operatingCost: OperatingCost, purchasePrice: number, potentialRevenue: number, totalRentableSquareFootage: number) => {
  if (operatingCost.calculationMethod === OperatingCostCalculationMethod.CostPerSquareFootage) {
    return operatingCost.cost * totalRentableSquareFootage;
  }
  else if (operatingCost.calculationMethod === OperatingCostCalculationMethod.PercentageOfPurchasePrice) {
    return operatingCost.cost * purchasePrice;
  }
  else if (operatingCost.calculationMethod === OperatingCostCalculationMethod.PercentageOfRevenue) {
    return operatingCost.cost * potentialRevenue;
  }
  else {
    return operatingCost.cost;
  }
};

export const calculateRentableSquareFootage = (rentalRevenue: RentalRevenue, commonAreaFactor: number) => {
  return Math.round(rentalRevenue.usableSquareFootage / (1 - commonAreaFactor));
};

export const calculateAdditionalRent = (rentalRevenue: RentalRevenue, totalUsableSquareFootage: number, totalOperatingCosts: number) => {
  const rentalSpaceFactor = rentalRevenue.usableSquareFootage / totalUsableSquareFootage;
  return rentalSpaceFactor * totalOperatingCosts;
};


export const calculateAnnualBaseRent = (rentalRevenue: RentalRevenue, commonAreaFactor: number) => {
  const rentableSquareFootage = calculateRentableSquareFootage(rentalRevenue, commonAreaFactor);
  const annualBaseRent = rentableSquareFootage * rentalRevenue.annualCostPerSquareFootage;

  return annualBaseRent;
};

export const calculateAnnualRent = (rentalRevenue: RentalRevenue, commonAreaFactor: number, totalOperatingCosts: number, totalUsableSquareFootage: number) => {
  const annualBaseRent = calculateAnnualBaseRent(rentalRevenue, commonAreaFactor);

  const additionalRent = calculateAdditionalRent(rentalRevenue, totalUsableSquareFootage, totalOperatingCosts);

  return annualBaseRent + additionalRent;
};