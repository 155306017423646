import { ActionMenu, ActionMenuProps } from '@components/ActionMenu';
import { ConfirmDialog } from '@components/ConfirmDialog';
import { useCurrentProject } from '@modules/projects/utils/useCurrentProject';
import { PermissionsModal } from '@modules/securityGroups/components/PermissionsModal/PermissionsModal';
import { ArrowDownward, Delete, Share, TrendingUp } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { useNotification } from '@utils/useNotification';
import { useQueryInvalidator } from '@utils/useQueryInvalidator';
import { Folder, FolderType, ShareableResourceType, useChildFoldersQuery, useDeleteFolderMutation, useProjectFoldersQuery } from 'gql/index';
import { merge } from 'lodash';
import { useContext, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { isAncestorOf } from '../utils/folderUtils';
import { useProjectDocumentsMoveMutations } from '../utils/useProjectDocumentsMoveMutations';
import { ProjectDocumentsContext } from './ProjectDocumentsContextProvider';
import { RenameFolderDialog } from './RenameFolderDialog';

interface Props extends Omit<ActionMenuProps, 'open' | 'onOpen'> {
  folder: Pick<Folder, 'id' | 'name' | 'path' | 'parentFolderId' | 'folderType'>;
  className?: string;
  renameAction?: () => void;
}

export const FolderActions: React.FC<Props> = ({ folder, renameAction, className, ...moreMenuProps }) => {
  const { formatMessage } = useIntl();
  const invalidateQuery = useQueryInvalidator();
  const [isConfirmRemoveDialogOpen, setIsConfirmRemoveDialogOpen] = useState(false);
  const { notifySuccess } = useNotification();

  const { projectId, canManageDocuments } = useCurrentProject();
  const [moreMenuDrawerOpen, setMoreMenuDrawerOpen] = useState(false);
  const [renameDialogOpen, setRenameDialogOpen] = useState(false);

  const { movingFolder, setMovingFolder, movingEntry, breadcrumbPath, setBreadcrumbPath, isArchived } = useContext(ProjectDocumentsContext);

  const deleteFolderMutation = useDeleteFolderMutation({
    onSuccess: () => {
      notifySuccess(formatMessage({ id: 'Folder deleted successfully' }));
      invalidateQuery(useProjectFoldersQuery, { projectId });
      invalidateQuery(useChildFoldersQuery, { parentFolderId: folder.parentFolderId });
      setMoreMenuDrawerOpen(false);
      setIsConfirmRemoveDialogOpen(false);
      setBreadcrumbPath(breadcrumbPath.filter(id => id !== folder.id));
    }
  });

  const deleteDocument = async () => {
    if (!document) return;

    await deleteFolderMutation.mutateAsync({ input: { projectId: projectId, folderId: folder.id } });
  };

  const { moveFolder, moveEntry } = useProjectDocumentsMoveMutations({
    onSuccess: () => {
      setMoreMenuDrawerOpen(false);
    }
  });

  const onMoveBeginClicked = () => {
    setMovingFolder?.(folder);
    setMoreMenuDrawerOpen(false);
  };

  const onMoveEndClicked = () => {
    if (movingFolder) {
      moveFolder({
        input: {
          projectId,
          folderId: movingFolder.id,
          destinationFolderId: folder.id
        }
      });
    }

    if (movingEntry) {
      moveEntry({
        input: {
          projectId,
          entryId: movingEntry.id,
          destinationFolderId: folder.id
        }
      });
    }
  };

  const onRenameClicked = () => {
    if (renameAction) {
      setMoreMenuDrawerOpen(false);
      renameAction();
    }
  };

  const isMoving = movingFolder != null || movingEntry != null;

  const isValidMovingDestination = useMemo(() => {
    if (movingEntry != null) return true;

    if (movingFolder != null) {
      if (isAncestorOf(movingFolder, folder)) {
        return false;
      }

      if (movingFolder.id === folder.id) {
        return false;
      }

      if (!setMovingFolder) {
        return false;
      }
    }

    return true;
  }, [folder, movingEntry, movingFolder, setMovingFolder]);

  const [isAddingRoleAssignment, setIsAddingRoleAssignment] = useState(false);
  const onShareClicked = () => {
    setIsAddingRoleAssignment(true);
    setMoreMenuDrawerOpen(false);
  };

  if (isArchived || !canManageDocuments || folder.folderType !== FolderType.Custom) {
    return null;
  }

  return <>
    <ActionMenu
      {...moreMenuProps}
      open={moreMenuDrawerOpen}
      onOpen={setMoreMenuDrawerOpen}
      iconButtonProps={merge({}, { color: 'inherit', className }, moreMenuProps.iconButtonProps)}
    >
      {renameAction != null ? (
        <MenuItem key='rename' onClick={onRenameClicked}>
          <ListItemIcon><EditIcon /></ListItemIcon>
          <ListItemText primary={formatMessage({ id: 'Rename' })} />
        </MenuItem>
      ) : (
        <MenuItem key='rename' onClick={() => {
          setRenameDialogOpen(true);
          setMoreMenuDrawerOpen(false);
        }}>
          <ListItemIcon><EditIcon /></ListItemIcon>
          <ListItemText primary={formatMessage({ id: 'Rename' })} />
        </MenuItem>
      )}

      {!isMoving && setMovingFolder && (
        <MenuItem key='move' onClick={onMoveBeginClicked}>
          <ListItemIcon><TrendingUp /></ListItemIcon>
          <ListItemText primary={formatMessage({ id: 'Move' })} />
        </MenuItem>
      )}

      <MenuItem key='delete' onClick={() => setIsConfirmRemoveDialogOpen(true)}>
        <ListItemIcon><Delete /></ListItemIcon>
        <ListItemText primary={formatMessage({ id: 'Delete' })} />
      </MenuItem>

      <MenuItem key='share' onClick={onShareClicked}>
        <ListItemIcon><Share /></ListItemIcon>
        <ListItemText primary={formatMessage({ id: 'Share' })} />
      </MenuItem>

      {(isMoving && isValidMovingDestination) && (
        <MenuItem key='moveConfirm' onClick={onMoveEndClicked}>
          <ListItemIcon><ArrowDownward /></ListItemIcon>
          <ListItemText primary={formatMessage({ id: 'Move here' })} />
        </MenuItem>
      )}
    </ActionMenu>

    <PermissionsModal open={isAddingRoleAssignment} resource={({ resourceType: ShareableResourceType.Folder, ...folder })} onClose={() => setIsAddingRoleAssignment(false)} />

    <ConfirmDialog
      open={isConfirmRemoveDialogOpen && document != null}
      onCancel={() => setIsConfirmRemoveDialogOpen(false)}
      onConfirm={deleteDocument}
      title={formatMessage({ id: 'Delete folder?' })}
      content={formatMessage({
        id: '{fileName} and its contents will be permanently deleted. Are you sure?'
      }, { fileName: folder.name })}
      confirmText={formatMessage({ id: 'Remove' })}
      confirmColor='error'
      loading={deleteFolderMutation.isLoading}
    />

    <RenameFolderDialog folder={folder} open={renameDialogOpen} onClose={() => setRenameDialogOpen(false)} />
  </>;
};