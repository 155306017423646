import { PageContainer } from '@components/Layout/PageContainer';
import { PageTitle } from '@components/Layout/PageTitle';
import { ResponsiveButton } from '@components/ResponsiveButton';
import { Add, Business } from '@mui/icons-material';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { CreateTenantDrawer } from '../components/CreateTenantDrawer';
import { TenantsTable } from '../components/TenantsTable';

export const TenantsPage = () => {
  const { formatMessage } = useIntl();

  const [isAddingNewTenant, setIsAddingNewTenant] = useState(false);

  return <>
    <PageTitle
      icon={<Business />}
      title={formatMessage({ id: 'Tenants' })}
      backgroundColor='secondary'
      actionButton={(
        <ResponsiveButton variant='contained' color='primary' icon={<Add />} onClick={() => setIsAddingNewTenant(true)}>
          {formatMessage({ id: 'Add tenant' })}
        </ResponsiveButton>
      )}
    />

    <PageContainer>
      <TenantsTable />
    </PageContainer>

    <CreateTenantDrawer
      open={isAddingNewTenant}
      onClose={() => setIsAddingNewTenant(false)}
      onComplete={() => setIsAddingNewTenant(false)}
    />
  </>;
};