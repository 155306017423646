import { DataTable } from '@components/DataTable';
import { DisableableTooltip } from '@components/Tooltips/DisableableTooltip';
import { UserAvatar } from '@components/UserAvatar/UserAvatar';
import { Chip, Stack, Typography, debounce } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { GridCellParams, GridTreeNode } from '@mui/x-data-grid-pro';
import { useGetMe } from '@utils/useGetMe';
import { useResponsive } from '@utils/useResponsive';
import { FormTemplateFilterInput, useFormTemplatesQuery } from 'gql/index';
import React, { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { getTenantIdentifier } from '../../../utils/getTenantIdentifier';
import { FormTemplateActions } from './FormTemplateActions';

interface OwnProps {
  selectedTab: 'mine' | 'sharedWithMe';
}

export const FormTemplatesTable: React.FC<OwnProps> = ({ selectedTab }) => {
  const { formatMessage, formatDate } = useIntl();
  const { me } = useGetMe();
  const { isMobile } = useResponsive();

  const navigate = useNavigate();

  const [searchTerm, setSearchTerm] = useState('');
  const onSearch = debounce((value: string) => {
    setSearchTerm(value);
  }, 500);

  const searchFilter: FormTemplateFilterInput | undefined = useMemo(() =>
    searchTerm.trim() ? {
      or: [{
        formDefinition: {
          name: {
            contains: searchTerm
          }
        }

      }, {
        formDefinition: {
          description: {
            contains: searchTerm
          }
        }
      }]
    } : undefined, [searchTerm]);

  const filter: FormTemplateFilterInput | undefined = useMemo(() => {
    if (selectedTab == 'mine') {
      return {
        createdById: {
          eq: me?.id
        },
        ...searchFilter
      };
    }
    else if (selectedTab == 'sharedWithMe') {
      return {
        createdById: {
          neq: me?.id
        },
        ...searchFilter
      };
    }
    else {
      return undefined;
    }
  }, [selectedTab, me?.id, searchFilter]);

  const { data: templates, isFetching } = useFormTemplatesQuery({ filter }, { select: d => d.formTemplates });
  type Template = NonNullable<typeof templates>[0];

  const columns: GridColDef<Template>[] = [
    {
      field: 'name',
      flex: 2,
      minWidth: 150,
      headerName: formatMessage({ id: 'Name' }),

      valueGetter: ({ row }) => row.formDefinition?.name,
      renderCell: ({ value }) => (
        <DisableableTooltip enterTouchDelay={0} disableInteractive disabled={!isMobile} title={formatMessage({ id: 'Forms cannot be edited on mobile.' })}>
          <Stack sx={{ cursor: 'pointer' }} overflow='hidden' textOverflow='ellipsis'>
            <Typography title={value}>
              {value}
            </Typography>
          </Stack>
        </DisableableTooltip>
      )
    },
    {
      field: 'createdOn',
      flex: 1,
      minWidth: 150,
      headerName: formatMessage({ id: 'Created on' }),
      valueGetter: ({ row }) => row.createdOn,
      valueFormatter: ({ value }) => formatDate(value)
    },
    {
      field: 'createdBy',
      flex: 1,
      minWidth: 150,
      headerName: formatMessage({ id: 'Created by' }),
      valueGetter: ({ row }) => row.createdBy?.fullName,
      renderCell: ({ value }) => <Chip avatar={<UserAvatar displayName={value} />} label={value} />
    },
    {
      field: 'actions',
      headerName: '',
      sortable: false,
      disableColumnMenu: true,
      disableExport: true,
      maxWidth: 64,
      renderCell: ({ row }) => <FormTemplateActions template={row} />
    }
  ];

  return <>
    <DataTable<Template>
      disableColumnMenu
      loading={isFetching}
      columns={columns}
      rows={templates ?? []}
      onSearch={onSearch}
      noSearchResultsMessage={formatMessage({ id: 'No templates found' })}
      noDataMessage={formatMessage({ id: 'No templates have been created yet' })}
      onCellClick={(params: GridCellParams<Template, unknown, unknown, GridTreeNode>) => {
        if (params.field === 'actions') return;

        if (isMobile) return;

        navigate(`/${getTenantIdentifier()}/templates/edit/${params.row.id}`);
      }}
    />
  </>;
};