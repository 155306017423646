import { FileIcon } from '@components/Icons/FileIcon';
import { UserAvatar } from '@components/UserAvatar/UserAvatar';
import { SignatureDialog } from '@modules/documents/components/DocumentSignatureDialog';
import { useCurrentProject } from '@modules/projects/utils/useCurrentProject';
import { CompleteDocumentSignatureActionDefinition } from '@modules/workflow/components/Actions/CompleteDocumentSignature.tsx/CompleteDocumentSignatureActionDefinition';
import { getFieldValue } from '@modules/workflow/components/types';
import { IDocumentSignatureRequest } from '@modules/workflow/types';
import { Draw, Warning } from '@mui/icons-material';
import { Alert, Chip, Stack } from '@mui/material';
import { useGetMe } from '@utils/useGetMe';
import { useQueryInvalidator } from '@utils/useQueryInvalidator';
import { useActionsOfWorkflowInstanceQuery, useGetDocumentQuery, useWorkflowInstanceQuery, useWorkflowInstancesQuery, WorkflowActionStatus } from 'gql/index';
import { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { ActionAccordion } from '../../WorflowInstance/Actions/ActionAccordion/ActionAccordion';
import { CompletedActionContentItemLink } from '../../WorflowInstance/Actions/ActionAccordion/CompletedActionContentItemLink';
import { ActionAccordionProps } from '../../WorflowInstance/Actions/Types';

export const CompleteDocumentSignatureActionExecution: React.FC<ActionAccordionProps> = (props) => {
  const { formatMessage } = useIntl();
  const { action, workflowId } = props;
  const { projectId } = useCurrentProject();
  const invalidateQuery = useQueryInvalidator();
  const { me } = useGetMe();


  const documentRequest = useMemo(() => getFieldValue<IDocumentSignatureRequest>(action, CompleteDocumentSignatureActionDefinition.FieldId_SignatureRequestId) ?? undefined, [action]);

  const { data: document, isFetching } = useGetDocumentQuery({ id: Number(documentRequest?.documentId) }, {
    enabled: !!documentRequest?.documentId,
    select: p => p.document,
    meta: { nullable: true }
  });

  const isDocumentMissing = !document && !isFetching && !!documentRequest?.documentId;

  const isCurrentUserSignatory = me?.id === document?.signatoryCustomerId;

  const [isSignatureDialogOpen, setIsSignatureDialogOpen] = useState(false);

  return (<>
    <ActionAccordion
      action={action}
      submitAction={isCurrentUserSignatory ? {
        buttonIcon: <Draw />,
        buttonText: formatMessage({ id: 'Sign now' }),
        onClick: () => setIsSignatureDialogOpen(true)
      } : undefined}
    >
      {document && <>
        {action.status === WorkflowActionStatus.Completed &&
          <CompletedActionContentItemLink
            ItemIcon={<FileIcon fileName={document.fileName} />}
            ItemName={document.fileName}
            linkPath={undefined}
            titleText={formatMessage({ id: 'Signature completed' })}
          />
        }


        {action.status === WorkflowActionStatus.InProgress && (
          <Stack direction='row' alignItems='center' gap={1}>
            {formatMessage({ id: 'Waiting for signature from ' })}

            <Chip
              avatar={<UserAvatar displayName={document.signatoryCustomer?.fullName} />}
              label={document.signatoryCustomer?.fullName}
            />
          </Stack>
        )}
      </>}

      {isDocumentMissing && (
        <Alert color='warning' icon={<Warning />}>
          {formatMessage({ id: 'Document not found - it may have been deleted. This workflow cannot be completed.' })}
        </Alert>
      )}
    </ActionAccordion>

    {documentRequest &&
      <SignatureDialog
        documentId={documentRequest.documentId}
        open={isSignatureDialogOpen}
        onClose={() => setIsSignatureDialogOpen(false)}
        onSuccess={() => {
          invalidateQuery(useWorkflowInstanceQuery, { projectId, workflowId });
          invalidateQuery(useActionsOfWorkflowInstanceQuery, { workflowId });
          invalidateQuery(useWorkflowInstancesQuery, { projectId });
        }}
        onError={(errors) => {
          if (errors.some(p => p.__typename == 'WorkflowExecutionFatalError')) {
            setIsSignatureDialogOpen(false);
            invalidateQuery(useWorkflowInstanceQuery);
          }
        }}
      />
    }
  </>
  );
};

