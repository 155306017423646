import React, { createContext, ReactNode, useCallback, useRef } from 'react';
import { useFieldArray, UseFieldArrayReturn, useFormContext } from 'react-hook-form';
import { FormMenuItem } from './FormSideMenu/FormSideMenu';
import { FormEditorValues } from './types';

interface FormEditorContext {
  prefillNewCondition: (targetFieldId: number) => void;
  selectedMenuItem: FormMenuItem;
  setSelectedMenuItem: (menuItem: FormMenuItem) => void;
  conditionsFieldArray: UseFieldArrayReturn<FormEditorValues, 'conditions', 'uniqueId'>;
}

export const FormEditorContext = createContext<FormEditorContext>(undefined as unknown as FormEditorContext);

export const FormEditorContextProvider: React.FC<{
  selectedMenuItem: FormMenuItem;
  setSelectedMenuItem: (menuItem: FormMenuItem) => void;
  children: ReactNode;
}> = ({ children, selectedMenuItem, setSelectedMenuItem }) => {

  const conditionsFormRef = useRef<HTMLDivElement>(null);

  const form = useFormContext<FormEditorValues>();

  const conditionsFieldArray = useFieldArray({ control: form.control, name: 'conditions', keyName: 'uniqueId' });

  const prefillNewCondition = useCallback((targetFieldId: number) => {
    conditionsFieldArray.append({
      targetFieldId: targetFieldId,
      targetSectionId: '',
      conditionFieldId: '',
      conditionFieldWhenValueBool: true,
      id: ''
    });
    setSelectedMenuItem('logic');
    setTimeout(() => conditionsFormRef.current?.scrollTo(0, document.body.scrollHeight), 1000);
  }, [conditionsFieldArray, setSelectedMenuItem]);

  return (
    <FormEditorContext.Provider value={{ prefillNewCondition, selectedMenuItem, setSelectedMenuItem, conditionsFieldArray }}>
      {children}
    </FormEditorContext.Provider>
  );
};