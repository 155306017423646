import { Check, Error, LoopOutlined, Schedule, StopCircle } from '@mui/icons-material';
import { WorkFlowStatus, WorkflowActionConfigurationFragmentFragment, WorkflowActionStatus, WorkflowInstance } from 'gql/index';
import { MessageDescriptor, defineMessages } from 'react-intl';

export type WorkflowRow = (Pick<WorkflowInstance, 'id' | 'workFlowStatus' | 'name' | 'variables' | 'stoppedOn' | 'completedOn' | 'createdOn'>);

export const WorkflowStatusType: Record<WorkFlowStatus, MessageDescriptor> = defineMessages({
  [WorkFlowStatus.NotStarted]: { id: 'Not started' },
  [WorkFlowStatus.InProgress]: { id: 'In progress' },
  [WorkFlowStatus.Completed]: { id: 'Completed' },
  [WorkFlowStatus.Error]: { id: 'Completed with errors' },
  [WorkFlowStatus.Stopped]: { id: 'Stopped' },
});

type ChipColors = 'default' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
export const WorkflowStatusTypeColor: Record<WorkFlowStatus, ChipColors> = {
  [WorkFlowStatus.NotStarted]: 'info',
  [WorkFlowStatus.InProgress]: 'success',
  [WorkFlowStatus.Completed]: 'secondary',
  [WorkFlowStatus.Error]: 'error',
  [WorkFlowStatus.Stopped]: 'warning',
};

export const WorkflowActionStatusTypeColor: Record<WorkflowActionStatus, ChipColors> = {
  [WorkflowActionStatus.NotStarted]: 'warning',
  [WorkflowActionStatus.InProgress]: 'info',
  [WorkflowActionStatus.Completed]: 'success',
  [WorkflowActionStatus.Error]: 'error',
  [WorkflowActionStatus.Stopped]: 'warning',
};


export const WorkflowStatusIcon: Record<WorkFlowStatus, React.ReactElement> = {
  [WorkFlowStatus.NotStarted]: <Schedule color='inherit' />,
  [WorkFlowStatus.Completed]: <Check color='inherit' />,
  [WorkFlowStatus.InProgress]: <LoopOutlined color='inherit' />,
  [WorkFlowStatus.Stopped]: <StopCircle color='inherit' />,
  [WorkFlowStatus.Error]: <Error color='inherit' />,
};


export function getFieldValue<T>(action: WorkflowActionConfigurationFragmentFragment, fieldId: string, assertFunc?: (input: unknown) => T): T | undefined {
  const fieldValue = action?.fieldValues.find(v => v.fieldId === fieldId)?.value;
  if (fieldValue?.serializedValue == null) return undefined;

  const parsedObject = JSON.parse(fieldValue.serializedValue);
  return assertFunc && parsedObject != null ? assertFunc(parsedObject) : parsedObject as T;

}
