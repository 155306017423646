import { TableCell, TableRow } from '@mui/material';
import { ReactNode } from 'react';

interface OwnProps {
  header: string
  isLast?: boolean
  children?: ReactNode
}

export const ValueRow: React.FC<OwnProps> = ({ header, children, isLast }) => {
  return (
    <TableRow>
      <TableCell align='right' sx={isLast ? { border: 0 } : {}}>{header}</TableCell>
      {children}
    </TableRow>
  );
};