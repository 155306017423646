import { Autocomplete, TextField } from '@mui/material';
import { uniqBy } from 'lodash';
import React, { forwardRef, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { GetTaskNameFragmentFragment, useProjectTaskQuery, useProjectTasksQuery, useValidTaskPredecessorsQuery } from '../../../gql';
import { useCurrentProject } from '../../projects/utils/useCurrentProject';
import { useTaskMessages } from '../taskUtils';

interface Props {
  taskId: number | undefined;
  disabled: boolean;
  value: number[],
  onChange: (...event: unknown[]) => void;
}

export const TaskPredecessorsPicker: React.FC<Props> = forwardRef(({ taskId, disabled, ...fieldProps }, ref) => {
  const { projectId } = useCurrentProject();
  const { getTaskName } = useTaskMessages();
  const { formatMessage } = useIntl();

  const { data: task, isFetching: isFetchingTask } = useProjectTaskQuery({ taskId: taskId ?? 0, projectId }, {
    enabled: !!taskId,
    select: p => p.projectTask
  });
  const { data: validPredecessors, isFetching: isFetchingPredecessors } = useValidTaskPredecessorsQuery({ taskId: taskId ?? -1 }, {
    enabled: taskId != null,
    select: p => p.validPredecessorTasks
  });
  const { data: allTasks, isFetching: isFetchingAllTasks } = useProjectTasksQuery({ projectId }, {
    select: d => d.projectTasks
  });

  const isLoading = isFetchingTask || isFetchingPredecessors || isFetchingAllTasks;

  const [inputValue, setInputValue] = React.useState('');

  const allowedPredecessors: GetTaskNameFragmentFragment[] = useMemo(() => {
    const result = [];

    if (taskId && task) {
      result.push(...task.predecessors);
    }

    if (taskId && validPredecessors) {
      result.push(...validPredecessors);
    }

    if (!taskId && allTasks) {
      result.push(...allTasks);
    }

    return uniqBy(result, t => t.id);
  }, [allTasks, task, taskId, validPredecessors]);

  return (
    <Autocomplete
      ref={ref}
      {...fieldProps}

      onChange={(_, data) => fieldProps.onChange(data)}
      options={allowedPredecessors.map(p => p.id)}
      getOptionLabel={(id) => {
        const task = allowedPredecessors.find(p => p.id === id);
        return task ? getTaskName(task) : '';
      }}
      disabled={disabled}
      loading={isLoading}
      multiple
      inputValue={inputValue}
      onInputChange={(_, newInputValue) => {
        setInputValue(newInputValue);
      }}

      filterSelectedOptions
      renderOption={(props, id) => {
        const task = allowedPredecessors.find(p => p.id === Number(id));
        return (
          <li {...props} key={id}>
            {task ? getTaskName(task) : id}
          </li>
        );
      }}
      renderInput={(params) => <TextField key={params.id} {...params} label={formatMessage({ id: 'Predecessors' })} />}
    />
  );
});