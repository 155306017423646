import { SlideDialog } from '@components/Dialogs/SlideDialog';
import { DownloadLinkButton } from '@components/Download/DownloadLinkButton';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { Box, Button, CircularProgress, DialogProps, MobileStepper, Stack, Typography } from '@mui/material';
import { placeInFirst } from '@utils/arrayUtils';
import { useKeyboardEventHandler } from '@utils/useKeyboardEventHandler';
import { DocumentThumbnailStatus } from 'gql/index';
import { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

export interface ImageWithThumbnail {
  id: number,
  fileName: string,
  imageUrl: string,
  thumbnailUrl?: string,
  thumbnailStatus?: DocumentThumbnailStatus | null;
}

interface Props extends DialogProps {
  images: ImageWithThumbnail[];
  defaultImageId?: number;
  isLoading?: boolean;
}

export const ImagesCarousel: React.FC<Props> = ({ images, defaultImageId, isLoading, ...slideDialogProps }) => {
  const { formatMessage } = useIntl();

  const imageDocuments = placeInFirst(images, d => d.id === defaultImageId);

  const maxSteps = imageDocuments?.length ?? 0;
  const [activeStep, setActiveStep] = useState(0);

  useEffect(() => {
    setActiveStep(0);
  }, [slideDialogProps.open]);

  const currentImageDocument = imageDocuments.length > 0 ? imageDocuments[activeStep] : undefined;

  const handleNext = () => {
    if (activeStep === maxSteps - 1) return;
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setLoadingImage(true);
  };

  const handleBack = () => {
    if (activeStep === 0) return;
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setLoadingImage(true);
  };

  useKeyboardEventHandler('ArrowRight', handleNext);
  useKeyboardEventHandler('ArrowLeft', handleBack);

  const [loadingImage, setLoadingImage] = useState(true);

  const infoMessages: Record<DocumentThumbnailStatus, string> = useMemo(() => ({
    NotAnImage: '',
    NotStarted: formatMessage({ id: 'Currently generating the preview for "{imageName}". Please stand by, this page will automatically refresh when it is available.' }, { imageName: currentImageDocument?.fileName ?? '' }),
    Generating: formatMessage({ id: 'Currently generating the preview for "{imageName}". Please stand by, this page will automatically refresh when it is available.' }, { imageName: currentImageDocument?.fileName ?? '' }),
    Ready: '',
    Failed: formatMessage({ id: 'A preview for this image is not available. Please download it to view it.' })
  }), [currentImageDocument?.fileName, formatMessage]);

  const currentInfoMessage = currentImageDocument?.thumbnailStatus ? infoMessages[currentImageDocument.thumbnailStatus] : undefined;

  return (
    <SlideDialog
      topbar={{
        title: imageDocuments?.[activeStep]?.fileName ?? '',
        loading: isLoading,
        actionButton: currentImageDocument && (
          <DownloadLinkButton fileName={currentImageDocument.fileName} link={currentImageDocument.imageUrl} variant='IconButton' />
        )
      }}
      {...slideDialogProps}
      containerProps={{ sx: { height: '100%' } }}
    >
      <Stack height='100%' justifyContent='space-between'>
        <Stack height='100%' position='relative' alignItems='center' justifyContent='center' gap={2}>
          {currentImageDocument && (currentImageDocument.thumbnailUrl ? (
            <Box
              component='img'
              sx={{
                position: 'absolute', top: 0, bottom: 0, left: 0, right: 0,
                width: '100%', height: '100%',
                objectFit: 'contain',
                visibility: loadingImage ? 'hidden' : 'visible'
              }}
              src={currentImageDocument.thumbnailUrl}
              onLoad={() => setLoadingImage(false)}
              loading='eager'
            />
          ) : <>
            {currentInfoMessage && (
              <Typography maxWidth='300px' textAlign='center'>
                {currentInfoMessage}
              </Typography>
            )}

            {currentImageDocument?.thumbnailStatus === DocumentThumbnailStatus.Failed && (
              <DownloadLinkButton fileName={currentImageDocument.fileName} link={currentImageDocument.imageUrl} variant='Button' />
            )}
          </>)}

          {(!currentImageDocument || (loadingImage && currentImageDocument?.thumbnailUrl)) && (
            <CircularProgress sx={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, margin: 'auto' }} />
          )}
        </Stack>

        {images.length > 1 && (
          <MobileStepper
            steps={maxSteps}
            position="static"
            activeStep={activeStep}
            backButton={(
              <Button
                startIcon={<ChevronLeft />}
                size="small"
                onClick={handleBack}
                disabled={activeStep === 0}
              >
                {formatMessage({ id: 'Back' })}
              </Button>
            )}
            nextButton={(
              <Button
                endIcon={<ChevronRight />}
                size="small"
                onClick={handleNext}
                disabled={activeStep === maxSteps - 1}
              >
                {formatMessage({ id: 'Next' })}
              </Button>
            )}
          />
        )}
      </Stack>
    </SlideDialog>
  );
};