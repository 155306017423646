import { DataTable } from '@components/DataTable';
import { PageSection } from '@components/PageSection/PageSection';
import { ResponsiveButton } from '@components/ResponsiveButton';
import { Add } from '@mui/icons-material';
import { Link as MuiLink, Stack } from '@mui/material';
import { GridColDef, useGridApiRef } from '@mui/x-data-grid-pro';
import { useQueryInvalidator } from '@utils/useQueryInvalidator';
import { CommercialProjectCalculator, useCommercialProjectCalculatorsQuery, useCreateCommercialProjectCalculatorMutation } from 'gql/index';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { getTenantIdentifier } from '../../../../../utils/getTenantIdentifier';
import { useCurrentProject } from '../../../../projects/utils/useCurrentProject';
import { useDefaultCalculatorValues } from '../types';
import { CommercialEstimateCalculatorActions } from './CalculatorComponents/CommercialEstimateCalculatorActions';
type RowType = Pick<CommercialProjectCalculator, 'id' | 'name'>;
interface Props {
  isLoading: boolean,
  calculators: RowType[];
}

const commonColumnOptions: Partial<GridColDef<RowType>> = {
  sortable: false,
  disableColumnMenu: true,
  disableExport: true,
  disableReorder: true,
  editable: true,
};

export const CalculatorsTable: React.FC<Props> = ({ calculators, isLoading }) => {
  const { formatMessage } = useIntl();
  const { projectId, canManageBudgetAndExpenses } = useCurrentProject();
  const { isArchived } = useCurrentProject();
  const invalidateQuery = useQueryInvalidator();
  const apiRef = useGridApiRef();

  const defaultValues = useDefaultCalculatorValues();
  const { mutate: addCalculator } = useCreateCommercialProjectCalculatorMutation();

  const addConstructionEstimate = () => {
    addCalculator({ input: { projectId: projectId, jsonData: JSON.stringify(defaultValues) } },
      {
        onSuccess: () => {
          invalidateQuery(useCommercialProjectCalculatorsQuery, { projectId: projectId });
        }
      });
  };

  const columns: GridColDef<RowType>[] = [
    {
      ...commonColumnOptions,
      field: 'name',
      headerName: formatMessage({ id: 'Name' }),
      flex: 1,
      renderCell: (params) => <Stack width={'100%'} overflow='hidden'>
        <MuiLink component={Link} to={`/${getTenantIdentifier()}/projects/${projectId}/budget/calculator/${params.row.id}`}>
          {params.formattedValue}
        </MuiLink>
      </Stack>
    },
    {
      ...commonColumnOptions,
      field: 'action',
      headerName: '',
      width: 64,
      editable: false,
      renderCell: (params) => <CommercialEstimateCalculatorActions calculatorId={params.row.id} />
    }
  ];



  return (
    <PageSection
      title={formatMessage({ id: 'Estimates' })}
      actionButton={!isArchived && canManageBudgetAndExpenses && (
        <ResponsiveButton onClick={addConstructionEstimate} variant='contained' color='primary' icon={<Add />}>
          {formatMessage({ id: 'Add estimate' })}
        </ResponsiveButton>
      )
      }
    >
      <DataTable<RowType>
        apiRef={apiRef}
        editMode='row'
        loading={isLoading}
        onCellClick={(params) => {
          params.row.id;
        }}

        columns={columns}
        rows={calculators}
      />
    </PageSection >
  );
};