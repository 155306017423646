import { UserAvatar } from '@components/UserAvatar/UserAvatar';
import { useCurrentProject } from '@modules/projects/utils/useCurrentProject';
import LinkIcon from '@mui/icons-material/Link';
import { AvatarGroup, Chip, Stack, Tooltip } from '@mui/material';
import { useDocumentPermissionsQuery, useExternalLinkPermissionsQuery } from 'gql/index';
import { useIntl } from 'react-intl';
import { FolderTableRow } from './FolderTable';
interface OwnProps {
  resourceId: number;
  rowType: FolderTableRow['rowType'];
}

export const FolderEntryRowSharedWithCell: React.FC<OwnProps> = ({ resourceId, rowType }) => {
  const { formatMessage } = useIntl();
  const { projectId } = useCurrentProject();

  const { data: documentPermissions, isFetching: isFetchingDocumentPermissions } = useDocumentPermissionsQuery({ projectId, documentId: resourceId }, {
    select: d => d.documentPermissions,
    enabled: rowType === 'document'
  });
  const { data: linkPermissions, isFetching: isFetchingLinkPermissions } = useExternalLinkPermissionsQuery({ projectId, linkId: resourceId }, {
    select: d => d.externalLinkPermissions,
    enabled: rowType === 'externalLink'
  });

  const isFetching = isFetchingDocumentPermissions || isFetchingLinkPermissions;

  const permissions = documentPermissions ?? linkPermissions;

  const users = permissions?.filter(ra => ra.user != null).map(ra => ({ ...ra.user }));
  const groups = permissions?.filter(ra => ra.securityGroup != null).map(ra => ({ ...ra.securityGroup }));
  const sharedWithAllProjectMembers = permissions?.find(ra => ra.sharedWithAllProjectMembers === true)?.sharedWithAllProjectMembers;
  const sharedWithPublicLink = permissions?.some(ra => ra.isSharedWithPublicLink === true);

  if (isFetching) return null;

  if (sharedWithAllProjectMembers) {
    return <Chip label={formatMessage({ id: 'All' })} />;
  }

  return (
    <Stack direction='row' alignItems='center' justifyContent={'center'} spacing={2}>
      <AvatarGroup>
        {users?.map(user =>
          <UserAvatar key={user.id} displayName={user.fullName} />
        )}
        {groups?.map(group =>
          <UserAvatar key={group.id} displayName={group.name} />
        )}

      </AvatarGroup>
      {sharedWithPublicLink &&
        <Tooltip title={formatMessage({ id: 'This document is accessible by anyone with the link.' })}>
          <LinkIcon fontSize='large' />
        </Tooltip>
      }
    </Stack>
  );
};

