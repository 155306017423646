import { Stack, Typography, TypographyVariant } from '@mui/material';
import { ProjectTaskStatus } from 'gql/index';
import React from 'react';
import { useIntl } from 'react-intl';
import { taskStatusIndicator, taskStatusMessage } from '../taskUtils';

interface Props {
  status: ProjectTaskStatus;
  variant?: TypographyVariant;
  iconOnly?: boolean;
}

export const TaskStatusDisplay: React.FC<Props> = ({ status, variant, iconOnly }) => {
  const { formatMessage } = useIntl();

  return (
    <Stack direction='row' alignItems='center' gap={1}>
      {taskStatusIndicator[status]}

      {!iconOnly && <Typography variant={variant}>
        {formatMessage(taskStatusMessage[status])}
      </Typography>}
    </Stack>
  );
};