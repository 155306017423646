import { ArrowForwardIos } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionProps, AccordionSummary, AccordionSummaryProps, Typography, styled } from '@mui/material';
import { ReactNode } from 'react';

type OwnProps = AccordionProps & {
  header: string,
  children: ReactNode;
  headerProps?: AccordionSummaryProps;
};

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  '&::before': {
    display: 'none',
  },
  borderTop: `1px solid ${theme.palette.divider}`
}));

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  padding: 0,
  borderTop: `1px solid ${theme.palette.divider}`
}));

export const AccordionSelectionPanel: React.FC<OwnProps> = (props) => {
  const { header, children, ...accodionProps } = props;

  return (
    <StyledAccordion {...accodionProps} square disableGutters>
      <StyledAccordionSummary expandIcon={<ArrowForwardIos fontSize='small' />} {...props.headerProps}>
        <Typography fontWeight={700}>{header}</Typography>
      </StyledAccordionSummary>
      <StyledAccordionDetails>
        {children}
      </StyledAccordionDetails>
    </StyledAccordion>
  );
};