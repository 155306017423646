import { DataTable } from '@components/DataTable';
import { useCurrentProject } from '@modules/projects/utils/useCurrentProject';
import { Link as MuiLink, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { SecurityGroupFragmentFragment } from 'gql/index';
import React from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { getTenantIdentifier } from '../../../utils/getTenantIdentifier';
import { SecurityGroupActions } from './SecurityGroupActions';

interface Props {
  securityGroups: SecurityGroupFragmentFragment[];
  projectId: number;
  loading?: boolean;
}

const commonGridColDefOptions: Partial<GridColDef> = {
  disableColumnMenu: true,
  disableExport: true,
  disableReorder: true,
  sortable: false
};

export const SecurityGroupsTable: React.FC<Props> = ({ projectId, securityGroups, loading }) => {
  const { formatMessage } = useIntl();

  const { isTemplate } = useCurrentProject();

  const columns: GridColDef<SecurityGroupFragmentFragment>[] = [
    {
      ...commonGridColDefOptions,
      field: 'name',
      flex: 1,
      minWidth: 250,
      headerName: formatMessage({ id: 'Name' }),
      renderCell: ({ row: { name, id } }) => isTemplate ?
        <Typography>{name}</Typography> :
        <MuiLink
          title={name}
          component={Link}
          to={`/${getTenantIdentifier()}/projects/${projectId}/permissions/groups/${id}`}
        >
          {name}
        </MuiLink>

    },
    {
      ...commonGridColDefOptions,
      field: 'description',
      flex: 2,
      minWidth: 300,
      headerName: formatMessage({ id: 'Description' })
    },
    {
      ...commonGridColDefOptions,
      field: 'actions',
      headerName: '',
      maxWidth: 64,
      renderCell: ({ row }) => (
        <SecurityGroupActions securityGroup={row} />
      )
    }
  ];

  return (
    <DataTable
      rows={securityGroups}
      columns={columns}
      loading={loading}
      getRowId={row => row.id}
      disableColumnMenu
      disableColumnFilter
      disableColumnSelector
      disableDensitySelector
      disableEval
      disableRowSelectionOnClick
    />
  );
};