import { AllActionIds } from '../../WorkflowEditor/Actions/types';
import { ActionAccordionProps, ActionExecutionAccordionByType } from './Types';

export const ActionComponent: React.FC<ActionAccordionProps> = (props) => {

  const Action = ActionExecutionAccordionByType[props.action.workflowActionDefinitionId as AllActionIds];

  return (
    <Action {...props} />
  );
};