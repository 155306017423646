import { createTheme, CssBaseline, Theme, ThemeProvider } from '@mui/material';
import { useTenantThemeQuery } from 'gql/index';
import { merge } from 'lodash';
import { ReactNode } from 'react';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';
import { TenantThemeContextProvider } from './TenantThemeContextProvider';

export const TenantThemeProvider = ({ children }: { children: ReactNode; }) => {
  const { formatMessage } = useIntl();
  const { data: tenantTheme, isLoading } = useTenantThemeQuery(undefined, { select: d => d.tenantTheme });

  if (isLoading) {
    return undefined;
  }

  if (!tenantTheme?.isBrandingEnabled) {
    return children;
  }



  return (
    <ThemeProvider theme={(theme: Theme) => {
      const newTheme = createTheme(merge({}, theme, {
        palette: {

          primary: {
            main: tenantTheme?.primaryColor || theme.palette.primary.main
          },
          secondary: {
            main: tenantTheme?.secondaryColor || theme.palette.secondary.main
          },
        },
        components: {
          MuiCssBaseline: {
            styleOverrides: {
              body: {
                '& ::-webkit-scrollbar': {
                  width: 8
                },
                '& ::-webkit-scrollbar-track': {
                  backgroundColor: 'rgba(0, 0, 0, 0.12)',
                },
                '& ::-webkit-scrollbar-thumb': {
                  backgroundColor: `${tenantTheme?.primaryColor || theme.palette.primary.main}`,
                }
              }
            }
          }
        }
      }));

      return newTheme;
    }}
    >
      <CssBaseline />
      <Helmet link={[{
        rel: 'icon',
        type: 'image/png',
        id: 'favicon',
        href: tenantTheme.isBrandingEnabled && tenantTheme.faviconUrl ? tenantTheme.faviconUrl : '/favicon.png'
      }]}>
        {!tenantTheme.isBrandingEnabled ? <>
          <title>{'Via Central'}</title>
          <meta name="description" content="Bien plus qu'un espace client"></meta>
        </> : (
          <title>{formatMessage({ id: 'Portal' })} - {tenantTheme.name}</title>
        )
        }
      </Helmet>
      <TenantThemeContextProvider tenantTheme={tenantTheme}>
        {children}
      </TenantThemeContextProvider>
    </ThemeProvider >
  );
};