
import { ActionMenu } from '@components/ActionMenu';
import { ConfirmDialog } from '@components/ConfirmDialog';
import { DisableableTooltip } from '@components/Tooltips/DisableableTooltip';
import { useCanEditUser } from '@modules/projects/utils/useCanEditUser';
import { useCurrentProject } from '@modules/projects/utils/useCurrentProject';
import { EditUserDetailsDrawer } from '@modules/users/components/EditUserDetailsDrawer';
import { Edit, Group, RemoveCircle } from '@mui/icons-material';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { useNotification } from '@utils/useNotification';
import { useQueryInvalidator } from '@utils/useQueryInvalidator';
import { ApplicationUser, useGetProjectMembersQuery, useGetProjectQuery, useGetProjectsQuery, useRemoveProjectMemberMutation, useSecurityGroupsForProjectQuery } from 'gql/index';
import React from 'react';
import { useIntl } from 'react-intl';
import { EditMemberSecurityGroupDrawer } from './EditMemberSecurityGroupDrawer';

interface Props {
  user: Pick<ApplicationUser, 'id' | 'fullName'>;
  projectId: number;
  onUserRemoved?: () => void;
}

export const ProjectMemberActions: React.FC<Props> = ({ user, projectId }) => {
  const { formatMessage } = useIntl();
  const invalidateQuery = useQueryInvalidator();
  const { notifySuccess } = useNotification();
  const { canManageProject } = useCurrentProject();

  const { canEditUserDetails, canEditSecurityGroup } = useCanEditUser(user.id);

  const [editUserDetailsOpen, setEditUserDetailsOpen] = React.useState(false);
  const [editSecurityGroupsOpen, setEditSecurityGroupsOpen] = React.useState(false);
  const [confirmRemoveDialogOpen, setConfirmRemoveDialogOpen] = React.useState(false);
  const [isMoreMenuOpen, setMoreMenuOpen] = React.useState(false);

  const removeMemberMutation = useRemoveProjectMemberMutation({
    onSuccess: () => {
      setMoreMenuOpen(false);
      setConfirmRemoveDialogOpen(false);
      invalidateQuery(useGetProjectQuery, { id: projectId });
      invalidateQuery(useSecurityGroupsForProjectQuery, { projectId });
      invalidateQuery(useGetProjectsQuery);
      invalidateQuery(useGetProjectMembersQuery, { projectId });
      notifySuccess(formatMessage({ id: 'User removed from project' }));
    }
  });

  const onRemoveCurrentUser = () => {
    removeMemberMutation.mutate({
      input: {
        projectId,
        userId: user.id
      }
    });
  };

  const editSecurityGroupMenuItem = (
    <MenuItem onClick={() => setEditSecurityGroupsOpen(true)} disabled={canEditSecurityGroup !== true}>
      <ListItemIcon><Group /></ListItemIcon>
      <ListItemText primary={formatMessage({ id: 'Edit security group' })} />
    </MenuItem>
  );

  const editUserMenuItem = (
    <MenuItem onClick={() => setEditUserDetailsOpen(true)} disabled={canEditUserDetails !== true}>
      <ListItemIcon><Edit /></ListItemIcon>
      <ListItemText primary={formatMessage({ id: 'Edit user' })} />
    </MenuItem>
  );

  return <>
    <ActionMenu open={isMoreMenuOpen} onOpen={setMoreMenuOpen}>
      {canEditUserDetails !== 'doNotShow' && (
        <DisableableTooltip disabled={canEditUserDetails === true} title={canEditUserDetails} arrow>
          {editUserMenuItem}
        </DisableableTooltip>
      )}

      {canEditSecurityGroup !== 'doNotShow' && (
        <DisableableTooltip disabled={canEditSecurityGroup === true} title={canEditSecurityGroup} arrow>
          {editSecurityGroupMenuItem}
        </DisableableTooltip>
      )}

      {canManageProject && (
        <MenuItem onClick={() => setConfirmRemoveDialogOpen(true)}>
          <ListItemIcon><RemoveCircle /></ListItemIcon>
          <ListItemText primary={formatMessage({ id: 'Remove from project' })} />
        </MenuItem>
      )}
    </ActionMenu>

    <EditUserDetailsDrawer
      open={editUserDetailsOpen}
      userId={user.id}
      onClose={() => setEditUserDetailsOpen(false)}
    />
    <EditMemberSecurityGroupDrawer
      open={editSecurityGroupsOpen}
      userId={user.id}
      onClose={() => setEditSecurityGroupsOpen(false)}
    />

    <ConfirmDialog
      open={confirmRemoveDialogOpen}
      onCancel={() => setConfirmRemoveDialogOpen(false)}
      onConfirm={onRemoveCurrentUser}
      title={formatMessage({ id: 'Remove from project?' })}
      content={formatMessage({
        id: '{fullName} will be removed from this project and no longer have access to it.'
      }, { fullName: user.fullName })}
      confirmText={formatMessage({ id: 'Remove' })}
      confirmColor='error'
      loading={removeMemberMutation.isLoading}
    />
  </>;
};