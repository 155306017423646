import { useCurrentProject } from '@modules/projects/utils/useCurrentProject';
import { getTenantIdentifier } from '@utils/getTenantIdentifier';
import { notNullOrUndefined } from '@utils/notNullOrUndefined';
import { useNotification } from '@utils/useNotification';
import { useQueryInvalidator } from '@utils/useQueryInvalidator';
import { FolderFragmentFragment, useChildFoldersQuery, useFolderEntriesQuery, useProjectDocumentsQuery, useProjectFoldersQuery } from 'gql/index';
import { debounce } from 'lodash';
import { useCallback, useContext } from 'react';
import { FileUpload, UploadManagerContext } from './UploadManagerContext';



const namesFromPath = (path: string, folders: FolderFragmentFragment[]) => {
  return path.split('/').map(Number)
    .map(part => folders.find(f => f.id === part))
    .filter(notNullOrUndefined)
    .map(f => f.name)
    .join('/');
};

const ensureSlash = (path: string) => path.startsWith('/') ? path : '/' + path;

export const useProjectDocumentsUploadManager = (batchId: string) => {
  const invalidateQuery = useQueryInvalidator();
  const { notifyFailure } = useNotification();
  const { projectId } = useCurrentProject();

  const { data: folders } = useProjectFoldersQuery({ projectId }, { select: d => d.projectFolders });

  const { uploads, queueUpload: queueFileUpload, cancelUpload } = useContext(UploadManagerContext);

  const queueUpload = useCallback((fileUpload: FileUpload) => queueFileUpload(batchId, fileUpload), [batchId, queueFileUpload]);

  const debouncedInvalidateQuery = debounce((folderId?: number) => {
    invalidateQuery(useProjectDocumentsQuery);
    invalidateQuery(useFolderEntriesQuery, { projectId, folderId });
    invalidateQuery(useChildFoldersQuery, { parentFolderId: folderId });
    invalidateQuery(useProjectFoldersQuery, { projectId });
  }, 1000);

  const queueFiles = useCallback((acceptedFiles: File[], currentFolderId?: number) => {
    const currentFolder = folders?.find(f => f.id === currentFolderId);

    const path = namesFromPath(currentFolder?.path ?? '', folders ?? []);

    for (const file of acceptedFiles) {
      const extensionIndex = file.name.lastIndexOf('.');
      const hasExtension = extensionIndex > -1;

      const fileName = hasExtension ? file.name.substring(0, extensionIndex) : file.name;
      const fileExtension = hasExtension ? file.name.substring(extensionIndex, file.name.length) : '';

      const formData = new FormData();

      formData.append('File', file);
      formData.append('FileName', fileName + fileExtension);
      if (currentFolderId) {
        formData.append('FolderId', currentFolderId.toString());
      }

      formData.append('ProjectId', projectId.toString());
      const folderPath = 'path' in file && typeof file.path === 'string' ? file.path || file.webkitRelativePath : file.webkitRelativePath;
      if (folderPath.trim() != '') {
        formData.append('Path', folderPath);
      }

      const openRequest = () => {
        const request = new XMLHttpRequest();
        request.open('POST', `/${getTenantIdentifier()}/api/Documents/Upload`);
        return request;
      };

      queueUpload({
        fileName,
        formData,
        onSuccess: () => debouncedInvalidateQuery(currentFolderId),
        path: path + ensureSlash(folderPath),
        onError: (error) => {
          notifyFailure(error);
        },
        openRequest
      });
    }
  }, [debouncedInvalidateQuery, folders, notifyFailure, projectId, queueUpload]);


  return {
    uploads: uploads.filter(p => p.batchId === batchId),
    queueFiles,
    queueUpload,
    cancelUpload
  };
};