import { Timeline } from '@mui/lab';
import {
  timelineItemClasses,
} from '@mui/lab/TimelineItem';
import dayjs from 'dayjs';
import _ from 'lodash';
import { RecentEventsDayGrouping } from './RecentEventsDayGrouping';
import { RecentEvent } from './types';

export const RecentEventsTimeline = (props: { recentEvents: RecentEvent[] }) => {
  const eventsByDate = _.groupBy(props.recentEvents, p => dayjs(p.eventDate).startOf('day').toString());
  return <Timeline position='right' sx={{
    [`& .${timelineItemClasses.root}:before`]: {
      flex: 0,
      padding: 0,
    },
    marginBlockStart: 0,
  }}>
    {Object.entries(eventsByDate).map(([date, events]) =>
      <RecentEventsDayGrouping key={date} recentEvents={events} />
    )}
  </Timeline>;
};