import { Property } from '@components/DataDisplay/Property';
import { PropertyGroup } from '@components/DataDisplay/PropertyGroup';
import { PageSection } from '@components/PageSection/PageSection';
import { DisableableTooltip } from '@components/Tooltips/DisableableTooltip';
import { UserAvatar } from '@components/UserAvatar/UserAvatar';
import { PermissionsModal } from '@modules/securityGroups/components/PermissionsModal/PermissionsModal';
import { ArrowUpward } from '@mui/icons-material';
import { Button, Chip, Paper, Stack, Typography } from '@mui/material';
import { FolderType, RoleAssignmentFragmentFragment, ShareableResourceType } from 'gql/index';
import { noop } from 'lodash';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { ShareableResource } from '../../securityGroups/components/Permissions/types';

interface Props {
  resource: ShareableResource;
  roleAssignments: RoleAssignmentFragmentFragment[];
  variant?: 'pageSection' | 'propertyGroup' | 'noPaper';
}

const NullIcon = () => null;

export const PermissionsProperty: React.FC<Props> = ({ resource, roleAssignments, variant }) => {

  const { formatMessage } = useIntl();

  const users = roleAssignments.filter(ra => ra.user != null).map(ra => ({ ...ra.user, isInherited: ra.isInherited }));

  const groups = roleAssignments.filter(ra => ra.securityGroup != null).map(ra => ({ ...ra.securityGroup, isInherited: ra.isInherited }));
  const sharedWithAll = roleAssignments.find(ra => ra.sharedWithAllProjectMembers)?.sharedWithAllProjectMembers;
  const sharedWithPublicLink = roleAssignments.some(ra => ra.isSharedWithPublicLink);
  const [isAddingRoleAssignment, setIsAddingRoleAssignment] = useState(false);

  const innerProperties = <>
    <Property propertyName={formatMessage({ id: 'Public link access' })}>

      <Typography>
        {sharedWithPublicLink ? formatMessage({ id: 'Shared with anyone with the public link' })
          : formatMessage({ id: 'Public link access is disabled' })}
      </Typography>

    </Property>
    <Property propertyName={formatMessage({ id: 'Users with access' })}>
      {sharedWithAll ?
        <Typography>
          {formatMessage({ id: 'All' })}
        </Typography>
        :
        <>
          {users.length > 0 ? (
            <Stack direction='row' flexWrap={'wrap'} gap={1}>
              {users.map(user => (
                <DisableableTooltip fullWidth key={user.id} disabled={!user.isInherited} title={formatMessage({ id: 'Inherited' })}>
                  <Chip
                    key={user.id}
                    avatar={<UserAvatar displayName={user.fullName} />}
                    label={user.fullName}
                    deleteIcon={user.isInherited ? <ArrowUpward /> : <NullIcon />}
                    onDelete={noop}
                  />
                </DisableableTooltip>
              ))}
            </Stack>
          ) : (
            <Typography>
              {formatMessage({ id: 'No users with access' })}
            </Typography>
          )}
        </>
      }
    </Property>

    {!sharedWithAll &&
      <Property propertyName={formatMessage({ id: 'Groups with access' })}>
        {groups.length > 0 ? (
          <Stack direction={'row'} flexWrap={'wrap'} gap={1}>
            {groups.map(group => (
              <DisableableTooltip fullWidth key={group.id} disabled={!group.isInherited} title={formatMessage({ id: 'Inherited' })}>
                <Chip
                  key={group.id}
                  avatar={<UserAvatar displayName={group.name} />}
                  label={group.name}
                  deleteIcon={group.isInherited ? <ArrowUpward /> : <NullIcon />}
                  onDelete={noop}
                />
              </DisableableTooltip>
            ))}
          </Stack>
        ) : (
          <Typography>
            {formatMessage({ id: 'No groups with access' })}
          </Typography>
        )}
      </Property>
    }
  </>;

  const actionButton = (resource.resourceType === ShareableResourceType.Folder && resource.folderType != FolderType.Custom) ?
    undefined
    :
    <Button variant='text' onClick={() => setIsAddingRoleAssignment(true)}>
      {formatMessage({ id: 'Manage' })}
    </Button>;

  return <>
    {variant === 'pageSection' ? (
      <PageSection title={formatMessage({ id: 'Permissions' })} actionButton={actionButton}>
        <Paper sx={{ p: 2 }}>
          <Stack gap={2}>
            {innerProperties}
          </Stack>
        </Paper>
      </PageSection>
    ) : (
      <Paper sx={{ p: 2 }}>
        <PropertyGroup title={formatMessage({ id: 'Permissions' })} actionButton={actionButton}>
          {innerProperties}
        </PropertyGroup>
      </Paper >
    )}

    <PermissionsModal open={isAddingRoleAssignment} resource={resource} onClose={() => setIsAddingRoleAssignment(false)} />
  </>;
};

PermissionsProperty.defaultProps = {
  variant: 'propertyGroup'
};