import { Divider, Paper, Stack, Typography } from '@mui/material';
import { useContextSafe } from '@utils/useContextSafe';
import { FormDefinitionFragmentFragment } from 'gql/index';
import { useFormContext } from 'react-hook-form';
import { FieldFiller } from './FormFields/FieldFiller';
import { FormFillerContext } from './FormFillerContextProvider';
import { FormFillerValues } from './types';
import { useFormFillerConditionHandler } from './useFormFillerConditionHandler';

interface Props {
  section: FormDefinitionFragmentFragment['sections'][number];
  disabled?: boolean;
  fieldIndexOffset: number;
}

export const FormFillerSection = ({ section, disabled, fieldIndexOffset }: Props) => {
  const { formDefinition } = useContextSafe(FormFillerContext);
  const { clearErrors } = useFormContext<FormFillerValues>();

  const { isVisible } = useFormFillerConditionHandler({ sectionId: section.id, conditions: formDefinition.conditions });

  if (!isVisible) {
    for (let i = 0; i < section.fields.length; i++) {
      clearErrors(`values.${fieldIndexOffset + i}`);
    }
    return null;
  }

  return (
    <Paper key={section.id}>
      {section.name && <>
        <Typography variant='h5' p={2}>{section.name}</Typography>
        <Divider />
      </>}

      <Stack p={1} gap={2}>
        {section.fields.map((field, index) => (
          <FieldFiller
            disabled={disabled}
            key={field.id}
            index={fieldIndexOffset + index}
            field={field}
          />
        ))}
      </Stack>
    </Paper>
  );

};