import { ReactComponent as SelectOrganization } from '@assets/images/undraw_select_organization.svg';
import { LoadingScreen } from '@components/LoadingScreen';
import { Login } from '@mui/icons-material';
import { Button, Container, Stack, Typography, useTheme } from '@mui/material';
import { whereFieldIsNotNull } from '@utils/NonNullableField';
import { getTenantIdentifier } from '@utils/getTenantIdentifier';
import { useResponsive } from '@utils/useResponsive';
import { useUserTenantsQuery } from 'gql/index';
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Navigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { displayTenantSwitcherAfterLoginState } from '../../application/atoms';

export const SwitchTenantsPage: React.FC = () => {
  const { formatMessage } = useIntl();
  const { isMobile } = useResponsive();
  const theme = useTheme();
  const { data: userTenants, isFetching } = useUserTenantsQuery(undefined, { select: d => d.userTenants });
  const [_, setDisplayTenantSwitcherAfterLogin] = useRecoilState(displayTenantSwitcherAfterLoginState);

  useEffect(() => {
    setDisplayTenantSwitcherAfterLogin(false);
  }, [setDisplayTenantSwitcherAfterLogin]);

  if (isFetching) {
    return <LoadingScreen />;
  }

  if (userTenants?.length === 1) {
    return <Navigate to={`/${userTenants[0].identifier}/projects`} />;
  }

  return (
    <Container component={Stack} maxWidth='md' justifyContent='center' py={4} spacing={2} textAlign='center'>
      <SelectOrganization fill={theme.palette.primary.main} style={{ width: 'auto', height: 'auto', maxWidth: isMobile ? '200px' : '300px' }} />

      <Typography variant='h4'>{formatMessage({ id: 'Select an organization' })}</Typography>
      <Typography>{formatMessage({ id: 'You belong to multiple organizations, please select which one you would like to enter now.' })}</Typography>

      <Stack gap={2}>
        {whereFieldIsNotNull(userTenants, 'identifier').map(tenant => (
          <form key={tenant.identifier} action={`/${getTenantIdentifier()}/api/Tenants/SwitchTenant`} method='post'>
            <input hidden name='returnUrl' value={`/${getTenantIdentifier()}/projects`} />
            <Button
              type='submit' name='identifier' value={tenant.identifier}
              endIcon={<Login />}
              variant='contained'
            >
              {tenant.name}
            </Button>
          </form>
        ))}
      </Stack>
    </Container>
  );
};