import { EditorSavingStatus } from '@components/Editor/TopBar/SavingStatusContext';
import { FormDefinitionFragmentFragment } from 'gql/index';
import React, { ReactNode, createContext, useState } from 'react';

interface FormFillerContext {
  submissionId: number;
  formSavingStatus: EditorSavingStatus | undefined;
  setFormSavingStatus: (value: EditorSavingStatus) => void;
  formDefinition: FormDefinitionFragmentFragment;
}

export const FormFillerContext = createContext<FormFillerContext>(undefined as unknown as FormFillerContext); // This should only ever be used in the ContextProvider

export const FormFillerContextProvider: React.FC<{
  children: ReactNode;
  submissionId: number;
  formDefinition: FormDefinitionFragmentFragment;
}> = ({ children, submissionId, formDefinition }) => {
  const [formSavingStatus, setFormSavingStatus] = useState<EditorSavingStatus | undefined>();

  return (
    <FormFillerContext.Provider value={{ formSavingStatus, setFormSavingStatus, submissionId, formDefinition }}>
      {children}
    </FormFillerContext.Provider>
  );
};