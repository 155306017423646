import { isProjectNavigationMenuCollapsedState } from '@modules/projects/atoms';
import { Box, BoxProps, styled } from '@mui/material';
import { useResponsive } from '@utils/useResponsive';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { projectCollapsedMenuWidth, projectExpandedMenuWidth } from '.';

type CollapsibleSpacerProps =
  & BoxProps
  & { collapsed: boolean; };

const StyledSpacer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'collapsed'
})<CollapsibleSpacerProps>(({ theme, collapsed }) => ({
  '@media print': {
    display: 'none'
  },
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: collapsed ? theme.transitions.duration.leavingScreen : theme.transitions.duration.enteringScreen,
  }),
  marginLeft: collapsed ? `${projectCollapsedMenuWidth}px` : `${projectExpandedMenuWidth}px`,
}));

export const ProjectNavigationMenuSpacer: React.FC = () => {
  const isNavigationMenuCollapsed = useRecoilValue(isProjectNavigationMenuCollapsedState);
  const { isMobile } = useResponsive();

  if (isMobile) {
    return null;
  }

  return (
    <StyledSpacer collapsed={isNavigationMenuCollapsed} />
  );
};