import { getTenantIdentifier } from '@utils/getTenantIdentifier';

export const getDocumentThumbnailUrl = (documentId: number) =>
  `/${getTenantIdentifier()}/api/documents/${documentId}/thumbnail`;

export const getDocumentUrl = (documentId: number) =>
  `/${getTenantIdentifier()}/api/documents/${documentId}`;


export const getFormAttachmentThumbnailUrl = (formId: number, attachmentId: number) =>
  `/${getTenantIdentifier()}/api/Form/${formId}/AttachmentThumbnails/${attachmentId}`;


export const getFormAttachmentUrl = (formId: number, attachmentId: number) =>
  `/${getTenantIdentifier()}/api/Form/${formId}/Attachments/${attachmentId}`;