import { isNavigationMenuOpenState } from '@modules/application/atoms';
import { Business, People, Timeline, ViewList, Web } from '@mui/icons-material';
import Label from '@mui/icons-material/Label';
import { Box, Drawer, Toolbar, styled } from '@mui/material';
import { getTenantIdentifier } from '@utils/getTenantIdentifier';
import { useGetMe } from '@utils/useGetMe';
import { useFeatureFlagsQuery } from 'gql/index';
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { expandedMenuWidth } from '.';
import { NavigationMenuLink } from './NavigationMenuLink';

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  flexShrink: 0,
  '& .MuiDrawer-paper': {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
    }),
    overflowX: 'hidden',
    width: `${expandedMenuWidth}px`,
    borderRight: 'none',
    boxShadow: 'none',
    boxSizing: 'border-box',
  },
  '& .collapsed h6': {
    visibility: 'hidden'
  },
  '& nav': {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    paddingTop: theme.spacing(2)
  }
}));

export const NavigationMenu: React.FC = () => {
  const { formatMessage } = useIntl();
  const location = useLocation();

  const [isNavigationMenuOpen, setIsNavigationMenuOpen] = useRecoilState(isNavigationMenuOpenState);
  const { data: featureFlags } = useFeatureFlagsQuery({}, { select: p => p.featureFlags, staleTime: 300000 });
  const tenantIdentifier = getTenantIdentifier();
  const prefixWithTenant = (path: string) => `/${tenantIdentifier}/${path}`;

  useEffect(() => {
    setIsNavigationMenuOpen(false);
  }, [location, setIsNavigationMenuOpen]);

  const { hasTenantAdminRole, hasMultiTenantAdminRole } = useGetMe();

  return (
    <StyledDrawer
      open={isNavigationMenuOpen}
      onClose={() => setIsNavigationMenuOpen(false)}
      anchor="left"
    >
      <Toolbar sx={{ marginBottom: 1 }} />
      <Box component="nav">
        <NavigationMenuLink icon={<Web htmlColor='inherit' />} title={formatMessage({ id: 'Projects' })} to={prefixWithTenant('projects')} />
        <NavigationMenuLink icon={<ViewList htmlColor='inherit' />} title={formatMessage({ id: 'Form templates' })} to={prefixWithTenant('templates')} />
        {featureFlags?.workflowsEnabled && <NavigationMenuLink icon={<Timeline color='inherit' />} title={formatMessage({ id: 'Workflow templates' })} to={prefixWithTenant('workflows')} />}

        {hasTenantAdminRole && <>
          <NavigationMenuLink icon={<People htmlColor='inherit' />} title={formatMessage({ id: 'Internal users' })} to={prefixWithTenant('users')} />
          <NavigationMenuLink icon={<Label htmlColor='inherit' />} title={formatMessage({ id: 'Branding' })} to={prefixWithTenant('branding')} />
        </>}
        {hasMultiTenantAdminRole && (
          <NavigationMenuLink icon={<Business htmlColor='inherit' />} title={formatMessage({ id: 'Tenants' })} to={prefixWithTenant('tenants')} />
        )}
      </Box>
    </StyledDrawer>
  );
};