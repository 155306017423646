import { PageSection } from '@components/PageSection/PageSection';
import { PageSectionSkeleton } from '@components/Skeletons/PageSectionSkeleton';
import { useCurrentProject } from '@modules/projects/utils/useCurrentProject';
import { TaskAlt } from '@mui/icons-material';
import { List } from '@mui/material';
import { useMyTasksQuery } from 'gql/index';
import { useIntl } from 'react-intl';
import { TaskListItem } from '../../../tasks/components/TaskListItem';

export const TasksSection: React.FC = () => {
  const { formatMessage } = useIntl();

  const { projectId } = useCurrentProject();

  const { data: tasks, isFetching } = useMyTasksQuery({ projectId }, { select: d => d.myTasks });

  if (!tasks && isFetching) {
    return (
      <PageSectionSkeleton icon={<TaskAlt />} title={formatMessage({ id: 'Tasks' })} />
    );
  }

  return <>
    <PageSection
      title={formatMessage({ id: 'My Tasks' })}
      icon={<TaskAlt />}
      notificationNumber={tasks?.length}
      emptyState={formatMessage({ id: 'There are no pending tasks.' })}
    >
      {(tasks?.length ?? 0 > 0) &&
        <List disablePadding sx={{ borderTop: t => `1px solid ${t.palette.divider}`, borderBottom: t => `1px solid ${t.palette.divider}` }}>
          {tasks?.map(task => (
            <TaskListItem key={task.id} task={task} />
          ))}
        </List>}
    </PageSection>
  </>;
};