import { LoadingScreen } from '@components/LoadingScreen';
import { GoogleLoginButton, MicrosoftLoginButton } from '@components/LoginButtons';
import { UnstyledLink } from '@components/UnstyledLink';
import { Button, Stack, Typography } from '@mui/material';
import { getTenantIdentifier } from '@utils/getTenantIdentifier';
import { useGetMe } from '@utils/useGetMe';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Navigate, useSearchParams } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { useNotification } from '../../../utils/useNotification';
import { displayTenantSwitcherAfterLoginState } from '../atoms';

export const LoginPage: React.FC = () => {
  const { formatMessage } = useIntl();
  const [loggingInProvider, setLoggingInProvider] = useState<string>();
  const setDisplayTenantSwitcherAfterLoginState = useSetRecoilState(displayTenantSwitcherAfterLoginState);
  const [params, setParams] = useSearchParams();
  const [returnUrl, setReturnUrl] = useState<string>();

  const { notifyFailure } = useNotification();

  const { me, isFetching, isFetched } = useGetMe();
  const signedIntoTenant = getTenantIdentifier();

  useEffect(() => {
    const returnUrlParam = params.get('returnUrl');
    if (returnUrlParam) {
      setParams((prevParams) => {
        prevParams.delete('returnUrl');
        return prevParams;
      });

      setReturnUrl(returnUrlParam);
      setDisplayTenantSwitcherAfterLoginState(false);
    } else if (!signedIntoTenant && !returnUrl) {
      setDisplayTenantSwitcherAfterLoginState(true);
    } else {
      setDisplayTenantSwitcherAfterLoginState(false);
    }
  }, [isFetched, me, notifyFailure, params, returnUrl, setDisplayTenantSwitcherAfterLoginState, setParams, signedIntoTenant]);

  useEffect(() => {
    const loginError = params.get('error');
    if (loginError) {
      notifyFailure(loginError);
      setParams((prevParams) => {
        prevParams.delete('error');
        return prevParams;
      });
    }
  }, [notifyFailure, params, setParams]);

  if (isFetching) {
    return <LoadingScreen />;
  }


  if (me != null && signedIntoTenant) {
    return <Navigate to={`/${getTenantIdentifier()}`} />;
  }

  return <>
    <Stack flexBasis={'50%'} flexGrow={1} spacing={2} pt={4}>
      <Stack spacing={1} alignItems='center'>
        <Typography variant='h3' color='primary.main'>
          {formatMessage({ id: 'Welcome' })}
        </Typography>

        <Typography textAlign='center'>
          {formatMessage({ id: 'Please identify yourself to access the application' })}
        </Typography>
      </Stack>

      <Stack flex={1} spacing={2} alignItems='center'>
        <form id="external-account" action='/api/Account/ExternalLogin' method="post">
          {returnUrl && <input name='returnUrl' type='hidden' value={returnUrl} />}
          <MicrosoftLoginButton
            loading={loggingInProvider === 'OpenIdConnect'}
            disabled={Boolean(loggingInProvider) && loggingInProvider !== 'OpenIdConnect'}
            fullWidth
            type='submit' name='provider' value='OpenIdConnect'
            onClickCapture={() => setLoggingInProvider('OpenIdConnect')}
          />
        </form>
        <form id="external-account" action='/api/Account/ExternalLogin' method="post">
          {returnUrl && <input name='returnUrl' type='hidden' value={returnUrl} />}
          <GoogleLoginButton
            fullWidth
            loading={loggingInProvider === 'Google'}
            disabled={Boolean(loggingInProvider) && loggingInProvider !== 'Google'}
            type='submit' name='provider' value='Google'
            onClickCapture={() => setLoggingInProvider('Google')}
          />
        </form>
      </Stack>
    </Stack>

    <Stack p={{ xs: 2, md: 4 }} gap={{ xs: 1, md: 2 }}>
      <Typography>
        {formatMessage({ id: 'Interested in our app for your organization?' })}
      </Typography>

      <UnstyledLink to='trial' sx={{ width: '100%' }}>
        <Button fullWidth variant='contained' color='primary'>
          {formatMessage({ id: 'Start a trial' })}
        </Button>
      </UnstyledLink>
    </Stack>
  </>;
};