import { Stack, styled } from '@mui/material';

export const PageSectionPart = styled(Stack)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  padding: theme.spacing(2),
  borderBottom: 'none',
  '&:last-child': {
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  backgroundColor: theme.palette.background.paper,
}));