import { EmptyState } from '@components/EmptyState';
import { Button, CircularProgress, FormControl, FormHelperText, List, ListItemButton, Radio, Stack } from '@mui/material';
import { getTenantIdentifier } from '@utils/getTenantIdentifier';
import { useWorkflowTemplatesQuery } from 'gql/index';
import { useFormContext, useFormState, useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { StartWorkflowFormValues } from '../types';


export const SelectWorkflowTemplateStep = () => {
  const { formatMessage } = useIntl();

  const { setValue, control, register } = useFormContext<StartWorkflowFormValues>();
  const { data: templates, isLoading } = useWorkflowTemplatesQuery({}, { select: p => p.workflowTemplates });
  const navigate = useNavigate();
  const selectedTemplateId = useWatch({ control, name: 'workflowTemplateId' });

  register('workflowTemplateId', { required: formatMessage({ id: 'Please select a workflow template.' }) });
  const workflowTemplateFieldState = useFormState({ control, name: 'workflowTemplateId' });

  const errorMessage = workflowTemplateFieldState.errors.workflowTemplateId?.message;

  if (isLoading && templates == null) {
    return (
      <Stack minHeight='300px' height='100%' width='100%' alignItems='center' justifyContent='center'>
        <CircularProgress />
      </Stack>
    );
  }

  return (
    <Stack>
      <FormControl
        required
        error={errorMessage != null}
        component="fieldset"
        sx={{ m: 0 }}
        variant="standard"
      >

        <List
          sx={{ maxHeight: 350, overflow: 'auto', borderWidth: 1, borderColor: 'error.main', borderStyle: errorMessage ? 'solid' : 'none' }}
          disablePadding
        >
          {templates?.length !== 0 && (
            templates?.map(p =>
              <ListItemButton
                key={p.id}
                selected={selectedTemplateId === p.id}
                onClick={() => {
                  setValue('workflowTemplateId', p.id);
                }}
              >
                <Radio checked={selectedTemplateId === p.id} disableRipple />
                {p.name}
              </ListItemButton>
            )
          )}

          {templates?.length === 0 && (
            <EmptyState
              title={formatMessage({ id: 'There are no workflow templates.' })}
              svgSize={100}
              callToActionComponent={<Button onClick={() => navigate(`/${getTenantIdentifier()}/workflows`)}>
                {formatMessage({ id: 'Create new workflow' })}
              </Button>
              }
            />
          )}
        </List>
        <FormHelperText>{errorMessage}</FormHelperText>
      </FormControl>
    </Stack>
  );
};