import { MultiStepFormDialog } from '@components/MultiStepForms/MultiStepFormDialog';
import { ProjectDocumentsContext } from '@modules/folders/components/ProjectDocumentsContextProvider';
import { useCurrentProject } from '@modules/projects/utils/useCurrentProject';
import { DialogProps } from '@mui/material';
import { useNotification } from '@utils/useNotification';
import { useQueryInvalidator } from '@utils/useQueryInvalidator';
import { useChildFoldersQuery, useFolderEntriesQuery, useImportFoldersMutation, useProjectFoldersQuery } from 'gql/index';
import { useContext, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { FolderImportProjectStep } from './FolderImportProjectStep';
import { FolderImportSummaryStep } from './FolderImportSummaryStep';
import { FolderImportFoldersPicker } from './FoldersStep/FolderImportFoldersPicker';
import { FolderImportFormValues } from './types';

type Props = DialogProps;

const defaultValues: FolderImportFormValues = {
  projectToImportFromId: null,
  folderIds: [],
};

export const FolderImportDrawer: React.FC<Props> = ({ ...dialogProps }) => {
  const { formatMessage } = useIntl();
  const invalidateQuery = useQueryInvalidator();
  const { notifySuccess } = useNotification();

  const { projectId } = useCurrentProject();
  const { currentFolderId } = useContext(ProjectDocumentsContext);

  const form = useForm<FolderImportFormValues>({ defaultValues });
  const { reset, handleSubmit } = form;

  const { mutate: importFolders, isLoading: isImporting } = useImportFoldersMutation({
    onSuccess: () => {
      invalidateQuery(useProjectFoldersQuery, { projectId });
      invalidateQuery(useChildFoldersQuery, { parentFolderId: currentFolderId });
      invalidateQuery(useFolderEntriesQuery, { projectId, folderId: currentFolderId });
      notifySuccess(formatMessage({ id: 'Folders imported successfully' }));
      dialogProps.onClose?.({}, 'escapeKeyDown');
    }
  });

  useEffect(() => {
    reset(defaultValues);
  }, [reset, dialogProps.open]);

  const onSubmit = (values: FolderImportFormValues) => {
    if (!values.projectToImportFromId) return;

    importFolders({
      input: {
        projectId,
        projectToImportFromId: values.projectToImportFromId,
        folderIds: values.folderIds,
        currentFolderId
      }
    });
  };

  return (
    <FormProvider {...form}>
      <MultiStepFormDialog
        dialogProps={{
          ...dialogProps,
          maxWidth: 'md',
        }}

        onSubmit={handleSubmit(onSubmit)}
        isSubmitting={isImporting}

        steps={[
          {
            title: formatMessage({ id: 'Pick a project' }),
            subtitle: formatMessage({ id: 'Select a project from which to import tasks' }),
            content: () => <FolderImportProjectStep disabled={isImporting} />,
            onNext: (callback) => form.handleSubmit(callback)
          },
          {
            title: formatMessage({ id: 'Select folders' }),
            subtitle: formatMessage({ id: 'Select the folders you wish to import' }),
            content: () => <FolderImportFoldersPicker />,
            onNext: (callback) => form.handleSubmit(callback)
          },
          {
            title: formatMessage({ id: 'Import summary' }),
            content: () => <FolderImportSummaryStep />
          }
        ]}
      />
    </FormProvider>
  );
};