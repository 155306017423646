import { DataTable } from '@components/DataTable';
import { MemberInfoDrawer } from '@modules/projects/components/Members/MemberInfoDrawer';
import { MemberListItem } from '@modules/projects/components/Members/MemberListItem';
import { ProjectMemberActions } from '@modules/projects/components/Members/ProjectMemberActions';
import { Stack } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { deburrSearch } from '@utils/deburrSearch';
import { useSecurityGroupsForProjectQuery } from 'gql/index';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';

interface Props {
  projectId: number;
  onUserClicked?: (userId: string) => void;
}

export const ProjectMembersTable: React.FC<Props> = ({ projectId }) => {
  const { formatMessage } = useIntl();
  const [searchTerm, onSearch] = useState('');

  const { data: groups, isFetching } = useSecurityGroupsForProjectQuery({ projectId }, { select: d => d.securityGroupsForProject });
  const users = groups?.flatMap(p => p.members).filter(u => deburrSearch(u.fullName, searchTerm));

  const [selectedUserId, setSelectedUserId] = useState<string>();
  const selectedUser = users?.find(m => m.id === selectedUserId);

  const columns: GridColDef<NonNullable<typeof users>[0]>[] = [
    {
      field: 'fullName',
      flex: 1,
      minWidth: 250,
      hideable: false,
      headerName: formatMessage({ id: 'Name' }),
      valueGetter: ({ row }) => row.fullName,
      renderCell: ({ row }) => (
        <MemberListItem
          member={row}
          disableSorroundBorders
          onClick={() => setSelectedUserId(row.id)}
        />
      )
    },
    {
      field: 'email',
      minWidth: 250,
      headerName: formatMessage({ id: 'Email' }),
      valueGetter: ({ row }) => row.email,
    },
    {
      field: 'group',
      minWidth: 250,
      headerName: formatMessage({ id: 'Group' }),
      valueGetter: ({ row }) => groups?.find(p => p.members.some(q => q.id === row.id))?.name,
    },
    {
      field: 'actions',
      headerName: '',
      maxWidth: 64,
      disableColumnMenu: true,
      disableExport: true,
      disableReorder: true,
      sortable: false,
      renderCell: ({ row }) => {
        const securityGroup = groups?.find(g => g.members.some(u => u.id == row.id));
        if (!securityGroup) return null;
        return <ProjectMemberActions user={row} projectId={projectId} />;
      }
    }
  ];

  return (
    <Stack flex={1}>
      <DataTable
        onSearch={onSearch}
        loading={isFetching}
        disableColumnMenu
        rowHeight={72}
        rows={users ?? []}
        columns={columns}
        noDataMessage={formatMessage({ id: 'No users found.' })}
        noSearchResultsMessage={formatMessage({ id: 'No users found' })}
        sx={{
          '& .MuiDataGrid-cell:first-of-type': {
            padding: 0
          }
        }}
      />

      {selectedUser && (
        <MemberInfoDrawer
          user={selectedUser}
          projectId={projectId}
          onClose={() => setSelectedUserId(undefined)}
        />
      )}
    </Stack>
  );
};