import { useCurrentProject } from '@modules/projects/utils/useCurrentProject';
import { Box, Paper } from '@mui/material';
import { Folder, ShareableResourceType, useFolderPermissionsQuery } from 'gql/index';
import React from 'react';
import { PropertyGroupSkeleton } from '../../../../../components/DataDisplay/PropertyGroupSkeleton';
import { PermissionsProperty } from '../../PermissionsProperty';

interface Props {
  folder: Pick<Folder, 'id' | 'name' | 'folderType'>;
}

export const FolderPermissions: React.FC<Props> = ({ folder }) => {
  const { projectId } = useCurrentProject();

  const { data: permissions, isFetching: isFetchingPermissions } = useFolderPermissionsQuery({ projectId, folderId: folder.id }, { select: d => d.folderPermissions });


  return (
    <>
      {isFetchingPermissions && <Paper sx={{ p: 2 }}>
        <PropertyGroupSkeleton propertiesCount={2} />
      </Paper>}

      <Box visibility={isFetchingPermissions ? 'hidden' : 'inherit'}>
        <PermissionsProperty
          resource={{ ...folder, resourceType: ShareableResourceType.Folder }}
          roleAssignments={permissions ?? []}
        />
      </Box>
    </>
  );
};