import { AnonymousContext } from '@modules/projectAccessTokens/AnonymousContextProvider';
import { useContextSafe } from '@utils/useContextSafe';
import { useAddMatrixRowMutation, useAddMatrixRowPatMutation, useRemoveMatrixRowMutation, useRemoveMatrixRowPatMutation } from 'gql/index';
import { useCallback, useContext } from 'react';
import { FormFillerContext } from '../../FormFillerContextProvider';
import { useFormFillerHelpers } from '../../useFormFillerHelpers';

export const useMatrixRowFiller = () => {
  const { accessToken } = useContext(AnonymousContext);
  const { submissionId } = useContextSafe(FormFillerContext);

  const { onFormFillSaving, onFormFillSuccess: onSuccess, onFormFillError: onError } = useFormFillerHelpers();

  const { mutateAsync: addNewRowMutate, isLoading: isAddingRow } = useAddMatrixRowMutation({ onSuccess, onError });
  const { mutateAsync: addNewRowPAT, isLoading: isAddingRowPAT } = useAddMatrixRowPatMutation({ onSuccess, onError });

  const { mutateAsync: deleteRowMutate, isLoading: isDeletingRow } = useRemoveMatrixRowMutation({ onSuccess, onError });
  const { mutateAsync: deleteRowPAT, isLoading: isDeletingRowPAT } = useRemoveMatrixRowPatMutation({ onSuccess, onError });

  const deleteRow = useCallback((rowId: number, matrixFieldId: number) => {
    onFormFillSaving();

    if (accessToken == null) {
      return deleteRowMutate({ input: { rowId, matrixFieldId, submissionId } });
    } else {
      return deleteRowPAT({
        input: { accessToken, matrixFieldId, rowId, submissionId }
      });
    }
  }, [accessToken, deleteRowMutate, deleteRowPAT, onFormFillSaving, submissionId]);

  const addNewRow = useCallback(async (matrixFieldId: number) => {
    onFormFillSaving();
    if (accessToken == null) {
      const result = await addNewRowMutate({ input: { matrixFieldId, submissionId } });
      return result.addMatrixRow.matrixRow?.id;
    } else {
      const result = await addNewRowPAT({ input: { accessToken, matrixFieldId, submissionId } });
      return result.addMatrixRowPAT.matrixRow?.id;
    }
  }, [accessToken, addNewRowMutate, addNewRowPAT, onFormFillSaving, submissionId]);

  const isAddRowLoading = isAddingRow || isAddingRowPAT;
  const isRemoveRowLoading = isDeletingRow || isDeletingRowPAT;

  return { addNewRow, deleteRow, isAddRowLoading, isRemoveRowLoading };
};