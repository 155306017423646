
import { AppTopBar } from '@components/Layout/AppTopBar';
import { UserAvatar } from '@components/UserAvatar/UserAvatar';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { AvatarGroup, IconButton, LinearProgress, Stack, Typography } from '@mui/material';
import { useContextSafe } from '@utils/useContextSafe';
import { useGetMe } from '@utils/useGetMe';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AnonymousContext } from '../../../projectAccessTokens/AnonymousContextProvider';
import { SavingStatusChip } from '../../../../components/Editor/TopBar/SavingStatusChip';
import { FormFillerContext } from './FormFillerContextProvider';
import { useFormFillerPresence } from './useFormFillerPresence';
import { EditorSavingStatus } from '@components/Editor/TopBar/SavingStatusContext';

interface Props {
  title: string;
}

export const FormFillerTopBar: React.FC<Props> = ({ title }) => {
  const { formSavingStatus, submissionId } = useContextSafe(FormFillerContext);
  const navigate = useNavigate();
  const isLoading = formSavingStatus === EditorSavingStatus.Saving;
  const { presences } = useFormFillerPresence(submissionId);

  const { isLoggedIn } = useGetMe();
  const { accessToken } = useContext(AnonymousContext);

  return (
    <AppTopBar sx={{ backgroundColor: 'primary.main', padding: 0 }}>
      <Stack direction='row' justifyContent='space-between' alignItems='center' flex={1} py={isLoggedIn ? 1 : 2} px={2}>
        <Stack direction='row' spacing={2} alignItems='center'>
          {isLoggedIn && !accessToken && (
            <IconButton color='inherit' onClick={() => navigate(-1)}>
              <ArrowBackIcon />
            </IconButton>
          )}

          <Typography color='inherit' variant='h5' sx={{ maxWidth: '80ch' }}>
            {title}
          </Typography>

          {formSavingStatus !== undefined && (
            <SavingStatusChip status={formSavingStatus} />
          )}
        </Stack>

        <Stack direction='row' alignItems='center' gap={1}>
          {presences && presences.length > 0 && (
            <AvatarGroup>
              {presences.map((presence, index) => (
                <UserAvatar key={index} displayName={presence.displayName} />
              ))}
            </AvatarGroup>
          )}
        </Stack>
      </Stack>

      <LinearProgress style={{ visibility: isLoading ? 'visible' : 'hidden' }} />
    </AppTopBar >
  );
};