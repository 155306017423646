import { FormDialog } from '@components/Dialogs/FormDialog';
import { DialogProps, TextField } from '@mui/material';
import { useNotification } from '@utils/useNotification';
import { useQueryInvalidator } from '@utils/useQueryInvalidator';
import { useValidationRules } from '@utils/useValidationRules';
import { useAddFolderMutation, useChildFoldersQuery, useProjectFoldersQuery } from 'gql/index';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';


interface Props extends DialogProps {
  projectId: number;
  parentFolderId: number;
}

interface FormValues {
  name: string;
}

export const AddFolderDialog: React.FC<Props> = ({ projectId, parentFolderId, ...dialogProps }) => {
  const { formatMessage } = useIntl();
  const { notEmpty } = useValidationRules();
  const { notifySuccess } = useNotification();
  const invalidateQuery = useQueryInvalidator();

  const { control, handleSubmit, reset } = useForm<FormValues>({
    defaultValues: { name: '' }
  });

  const { mutate: addFolder, isLoading } = useAddFolderMutation({
    onSuccess: () => {
      dialogProps.onClose?.({}, 'backdropClick');
      notifySuccess(formatMessage({ id: 'Folder added successfully' }));
      invalidateQuery(useProjectFoldersQuery, { projectId });
      invalidateQuery(useChildFoldersQuery, { parentFolderId });
    }
  });

  useEffect(() => {
    if (dialogProps.open) {
      reset();
    }
  }, [dialogProps.open, reset]);

  const onSubmit = (values: FormValues) => {
    addFolder({
      input: {
        name: values.name,
        projectId,
        parentFolderId
      }
    });
  };

  return (
    <FormDialog
      {...dialogProps}
      title={formatMessage({ id: 'Add new folder' })}
      onSubmit={handleSubmit(onSubmit)}
      isSubmitting={isLoading}
    >
      <Controller
        name='name'
        control={control}
        rules={{ validate: notEmpty }}
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            fullWidth
            label={formatMessage({ id: 'Name' })}
            required
            error={!!error}
            helperText={error?.message}
            autoComplete='off'
          />
        )}
      />
    </FormDialog>
  );
};