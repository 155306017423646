import { AnonymousContext } from '@modules/projectAccessTokens/AnonymousContextProvider';
import { useDebounce } from '@utils/useDebounce';
import { FillMatrixValueInput, useFillMatrixValueMutation, useFillMatrixValuePatMutation } from 'gql/index';
import { useCallback, useContext } from 'react';
import { useFormFillerHelpers } from '../../useFormFillerHelpers';
import { autoSaveTimeout } from '../useFormFieldFillerForm';

export const useMatrixFiller = () => {
  const { accessToken } = useContext(AnonymousContext);

  const { onFormFillSaving, onFormFillSuccess: onSuccess, onFormFillError: onError } = useFormFillerHelpers();

  const { mutate: fillMatrixValue, mutateAsync: fillMatrixValueAsync } = useFillMatrixValueMutation({ onError, onSuccess });
  const { mutate: fillMatrixValuePAT, mutateAsync: fillMatrixValuePATAsync } = useFillMatrixValuePatMutation({ onError, onSuccess });

  const onValueUpdated = useDebounce((matrixValueId: number, input: Omit<FillMatrixValueInput, 'matrixValueId'>) => {
    onFormFillSaving();
    if (accessToken == null) {
      fillMatrixValue({ input: { ...input, matrixValueId } });
    } else {
      fillMatrixValuePAT({ input: { accessToken, input: { ...input, matrixValueId } } });
    }
  }, autoSaveTimeout);

  const addNewValue = useCallback(async (input: Omit<FillMatrixValueInput, 'matrixValueId'>) => {
    onFormFillSaving();
    if (accessToken == null) {
      const result = await fillMatrixValueAsync({ input });
      return result.fillMatrixValue.matrixValue?.id;
    } else {
      const result = await fillMatrixValuePATAsync({ input: { accessToken, input } });
      return result.fillMatrixValuePAT.matrixValue?.id;
    }
  }, [accessToken, fillMatrixValueAsync, fillMatrixValuePATAsync, onFormFillSaving]);

  return { onValueUpdated, addNewValue };
};