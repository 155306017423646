import { FormDialog } from '@components/Dialogs/FormDialog';
import { useCurrentProject } from '@modules/projects/utils/useCurrentProject';
import { DialogProps, TextField } from '@mui/material';
import { useNotification } from '@utils/useNotification';
import { useQueryInvalidator } from '@utils/useQueryInvalidator';
import { useValidationRules } from '@utils/useValidationRules';
import { useAddExternalLinkMutation, useFolderEntriesQuery } from 'gql/index';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';

interface FormValues {
  name: string;
  link: string;
}

const defaultValues: FormValues = {
  name: '',
  link: '',
};

type Props = DialogProps & {
  folderId: number;
};


export const AddExternalLinkDialog: React.FC<Props> = ({ folderId, ...dialogProps }) => {
  const { formatMessage } = useIntl();
  const { validateUrl, notEmpty } = useValidationRules();
  const { notifySuccess } = useNotification();
  const { projectId } = useCurrentProject();
  const invalidateQuery = useQueryInvalidator();

  const { control, handleSubmit } = useForm<FormValues>({ defaultValues });

  const { mutate: addExternalLink, isLoading } = useAddExternalLinkMutation({
    onSuccess: () => {
      dialogProps.onClose?.({}, 'backdropClick');
      notifySuccess(formatMessage({ id: 'External link added successfully' }));
      invalidateQuery(useFolderEntriesQuery, { projectId });
    }
  });

  const onSubmit = (values: FormValues) => {
    addExternalLink({
      input: {
        name: values.name,
        link: values.link,
        folderId,
        projectId
      }
    });
  };

  return (
    <FormDialog
      disableClose={isLoading}
      {...dialogProps}
      title={formatMessage({ id: 'Add external link' })}
      subtitle={formatMessage({ id: 'A link to an external website. This link can be managed like a document.' })}
      onSubmit={handleSubmit(onSubmit)}
      isSubmitting={isLoading}
    >
      <Controller
        name='name'
        control={control}
        rules={{ validate: notEmpty }}
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            label={formatMessage({ id: 'Name' })}
            fullWidth
            required
            error={!!error}
            helperText={error?.message}
          />
        )}
      />

      <Controller
        name='link'
        control={control}
        rules={{ validate: { notEmpty, validateUrl } }}
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            label={formatMessage({ id: 'Link' })}
            fullWidth
            required
            error={!!error}
            helperText={error?.message}
          />
        )}
      />
    </FormDialog>
  );
};