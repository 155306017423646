import { useQueryInvalidator } from '@utils/useQueryInvalidator';
import { useEditFormDefinitionMutation, useFormTemplatesQuery, useProjectFormDefinitionQuery, useProjectFormQuery, useProjectFormsQuery } from 'gql/index';
import React, { useContext } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { EditorTitleSection, EditorTitleValues } from '@components/Editor/EditorTitleSection';
import { useEditorHelpers } from '@components/Editor/TopBar/useEditorHelpers';
import { useFormTemplateQuery } from 'gql/index';
import { useIntl } from 'react-intl';
import { EditorSavingStatus, SavingStatusContext } from '../../../../components/Editor/TopBar/SavingStatusContext';
import { defaultFormNames } from './messages';
import { FormEditorValues } from './types';


export const TitleSection: React.FC = () => {
  const { formatMessage } = useIntl();
  const invalidateQuery = useQueryInvalidator();

  const { control, setValue }
    = useFormContext<FormEditorValues>();
  const { onEditionError, onEditionSuccess } = useEditorHelpers();

  const formDefinitionId = useWatch({ control, name: 'id' });
  const name = useWatch({ control, name: 'name' });
  const description = useWatch({ control, name: 'description' });

  const { editorSavingStatus, isEditorLoading } = useContext(SavingStatusContext);

  const { mutate: editFormContent } = useEditFormDefinitionMutation({
    onError: onEditionError,
    onSuccess: () => {
      onEditionSuccess();
      invalidateQuery(useFormTemplateQuery);
      invalidateQuery(useFormTemplatesQuery);
      invalidateQuery(useProjectFormQuery);
      invalidateQuery(useProjectFormsQuery);
      invalidateQuery(useProjectFormDefinitionQuery);
    }
  });

  const isLoading = editorSavingStatus === EditorSavingStatus.Saving || isEditorLoading;

  const onTitleSectionInactivated = (values: EditorTitleValues) => {
    if (!name.trim()) return;
    setValue('name', values.name);
    setValue('description', values.description);

    editFormContent({
      input: {
        formDefinitionId,
        formDefinition: {
          ...values
        }
      }
    }, {
      onSuccess: () => {
        invalidateQuery(useFormTemplatesQuery);
        invalidateQuery(useProjectFormQuery);
        invalidateQuery(useProjectFormsQuery);
        invalidateQuery(useProjectFormDefinitionQuery);
      }
    });
  };

  return (
    <EditorTitleSection
      defaultOpen={name === formatMessage(defaultFormNames.form) || name === formatMessage(defaultFormNames.template)}
      values={{ name, description }}
      isLoading={isLoading}
      onSubmit={onTitleSectionInactivated}
    />
  );
};