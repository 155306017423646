import { CancelButton } from '@components/Buttons/CancelButton';
import { ConfirmDialog } from '@components/ConfirmDialog';
import { LoadingButton } from '@mui/lab';
import { Stack } from '@mui/material';
import React from 'react';
import { useIntl } from 'react-intl';
import { ResponsiveDrawer, ResponsiveDrawerProps } from '.';

export interface FormDrawerProps extends Omit<ResponsiveDrawerProps, 'open'> {
  open?: boolean;
  isFormDirty?: boolean;
  saveButtonMessage?: string;
  disabled?: boolean;
  onSave?: () => void;
  showFooter?: boolean;
  isSubmitting?: boolean;
  clickOutsideSaves?: boolean;
}

export const FormDrawer: React.FC<FormDrawerProps> = ({
  children,
  open,
  onClose,
  onSave,
  isFormDirty,
  saveButtonMessage,
  disabled: _disabled,
  showFooter,
  clickOutsideSaves,
  isSubmitting,
  ...drawerProps
}) => {
  const { formatMessage } = useIntl();


  const disabled = isSubmitting || _disabled;

  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = React.useState(false);

  const onConfirmClose = () => {
    setIsConfirmDialogOpen(false);
    onClose?.({}, 'escapeKeyDown');
  };

  const handleCloseDrawer = () => {
    if (isFormDirty) {
      setIsConfirmDialogOpen(true);
    } else {
      onConfirmClose();
    }
  };

  const onDrawerHeaderClose = () => {
    handleCloseDrawer();
  };

  const onClickOutside = () => {
    if (clickOutsideSaves) {
      onSave?.();
    } else {
      handleCloseDrawer();
    }
  };

  return (
    <>
      <ResponsiveDrawer
        {...drawerProps}
        isLoading={isSubmitting || drawerProps.isLoading}
        disabled={disabled}
        disablePadding
        open={open}
        onClose={onClickOutside}
        drawerHeaderOnClose={onDrawerHeaderClose}
      >
        <Stack sx={{ flex: 1, overflowY: 'auto' }}>
          {children}
        </Stack>
        {showFooter && <>
          <Stack color='inherit' width='100%' sx={{ boxShadow: t => t.shadows[6] }}>

            <Stack justifyContent='right' direction='row' spacing={1} p={{ xs: 1, md: 2 }}>
              <CancelButton onClick={handleCloseDrawer} disabled={disabled} />
              <LoadingButton
                loading={isSubmitting}
                onClick={onSave}
                disabled={disabled}
                variant='contained'
                color='primary'
              >
                {saveButtonMessage ?? formatMessage({ id: 'Submit' })}
              </LoadingButton>
            </Stack>
          </Stack>
        </>}
      </ResponsiveDrawer>
      <ConfirmDialog
        open={isConfirmDialogOpen}
        title={formatMessage({ id: 'Unsaved changes' })}
        content={formatMessage({ id: 'Are you sure you want to close without saving your changes?' })}
        onCancel={() => setIsConfirmDialogOpen(false)}
        onConfirm={onConfirmClose}
        confirmColor='warning'
        confirmText={formatMessage({ id: 'Close' })}
      />
    </>
  );
};