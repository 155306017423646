import { AddMeetingFormValues } from '@modules/meetings/types';
import { Badge } from '@mui/material';
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import { useFormContext, useWatch } from 'react-hook-form';

export function AvailabilityDay(props: PickersDayProps<Dayjs>) {

  const { control } = useFormContext<AddMeetingFormValues>();

  const proposedTimes = useWatch({ control, name: 'proposedTimes' });

  const isProposed = proposedTimes.some(p => dayjs(props.day).isSame(dayjs(p.start), 'day'));

  const inner = <PickersDay {...props} />;

  if (isProposed) {
    return (
      <Badge
        key={props.day.toString()}
        color='primary'
        overlap="circular"
        variant='dot'
      >
        {inner}
      </Badge>
    );
  }

  return inner;
}