import { CircularProgress, Stack } from '@mui/material';
import { useWorkflowActionDefinitionsQuery, useWorkflowTemplateErrorsQuery, WorkflowActionDefinitionFragmentFragment, WorkflowActionFieldFragmentFragment, WorkflowDefinitionFullFragmentFragment } from 'gql/index';
import React, { createContext, useCallback, useMemo, useState } from 'react';
import { useFieldArray, UseFieldArrayReturn, useFormContext } from 'react-hook-form';
import { WorkflowEditorValues } from './types';

export interface WorkflowEditorContext {
  disabled?: boolean;
  workflowDefinition: WorkflowDefinitionFullFragmentFragment;
  actionDefinitions: WorkflowActionDefinitionFragmentFragment[];
  actionDefinitionsMap: Record<string, WorkflowActionDefinitionFragmentFragment>;
  findField: (actionDefinitionId: string, fieldId: string) => WorkflowActionFieldFragmentFragment | undefined;
  selectedActionIndex: number | null;
  setSelectedActionIndex: (actionIndex: number | null) => void;
  actionsFieldArray: UseFieldArrayReturn<WorkflowEditorValues, 'actions', 'uniqueId'>;

  errors: Record<number, string>;
  notConfiguredActionIds: number[];
  setNotConfiguredActionId: (actionId: number | undefined, notConfigured: boolean) => void;
}

export const WorkflowEditorContext = createContext<WorkflowEditorContext>({} as WorkflowEditorContext);

export const WorkflowEditorContextProvider: React.FC<{
  children: React.ReactNode;
  disabled?: boolean;
  workflowDefinition: WorkflowDefinitionFullFragmentFragment;
}> = ({ children, workflowDefinition, disabled }) => {
  const [selectedActionIndex, setSelectedActionIndex] = useState<number | null>(null);

  const { data: actionDefinitions } = useWorkflowActionDefinitionsQuery(undefined, { select: d => d.workflowActionDefinitions });
  const { control } = useFormContext<WorkflowEditorValues>();
  const actionsFieldArray = useFieldArray({ control, name: 'actions', keyName: 'uniqueId' });

  const actionDefinitionsMap = useMemo(() => {
    const map: Record<string, WorkflowActionDefinitionFragmentFragment> = {};

    if (!actionDefinitions) return map;

    for (const action of actionDefinitions) {
      map[action.actionDefinitionId] = action;
    }

    return map;
  }, [actionDefinitions]);

  const findField = useCallback((actionDefinitionId: string, fieldId: string) => {
    return actionDefinitionsMap[actionDefinitionId].fields.find(f => f.id == fieldId);
  }, [actionDefinitionsMap]);

  const [notConfiguredActionIds, setNotConfiguredActionIds] = useState<number[]>([]);
  const setNotConfiguredActionId = useCallback((actionId: number | undefined, notConfigured: boolean) => {
    if (!actionId) return;
    if (notConfigured) {
      setNotConfiguredActionIds(prev => [...prev, actionId]);
    } else {
      setNotConfiguredActionIds(prev => prev.filter(p => p !== actionId));
    }
  }, []);

  const { data: errorData } = useWorkflowTemplateErrorsQuery({ workflowId: workflowDefinition.id });

  const errors = useMemo(() => {
    const errors: Record<number, string> = {};

    if (!errorData) return errors;

    for (const error of errorData.workflowTemplateErrors) {
      if (errors[error.actionId]) continue;
      errors[error.actionId] = error.message;
    }

    return errors;
  }, [errorData]);

  return (
    <WorkflowEditorContext.Provider value={{
      disabled,
      workflowDefinition,
      selectedActionIndex,
      setSelectedActionIndex,
      actionDefinitions: actionDefinitions ?? [],
      actionDefinitionsMap,
      actionsFieldArray,
      findField,

      errors,
      notConfiguredActionIds,
      setNotConfiguredActionId
    }}>
      {actionDefinitions ? children : (
        <Stack height='100%' width='100%' alignItems='center' justifyContent='center'>
          <CircularProgress />
        </Stack>
      )}
    </WorkflowEditorContext.Provider>
  );
};