import { Stack, styled, tooltipClasses } from '@mui/material';
import { useIntl } from 'react-intl';
import { getAvatarColor } from '../../utils/userUtils';
import { DisableableTooltip, DisableableTooltipProps } from './DisableableTooltip';

const StyledTooltip = styled(({ className, ...props }: DisableableTooltipProps) => (
  <DisableableTooltip fullWidth {...props} classes={{ popper: className }} />
), {
  shouldForwardProp: p => p != 'backgroundColor'
})<{
  backgroundColor?: string;
}>(({ backgroundColor }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor,
  }
}));

interface Props extends DisableableTooltipProps {
  userDisplayName: string | undefined;
  fullWidth?: boolean;
}

export const PresenceDecorator = ({ userDisplayName, fullWidth, ...tooltipProps }: Props) => {
  const { formatMessage } = useIntl();
  const outlineColor = userDisplayName ? getAvatarColor(userDisplayName) : undefined;

  return (
    <StyledTooltip
      {...tooltipProps}
      backgroundColor={outlineColor}
      disabled={userDisplayName == null}
      title={formatMessage({ id: '{user} is editing this field' }, { user: userDisplayName })}
      disableInteractive
    >
      <Stack
        {...fullWidth && { width: '100%', height: '100%', justifyContent: 'center' }}
        sx={{ outlineWidth: 1, outlineStyle: userDisplayName ? 'solid' : 'none', outlineColor, p: 1 }}
      >
        {tooltipProps.children}
      </Stack>
    </StyledTooltip>
  );
};