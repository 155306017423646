import { FieldType, FormDefinitionFragmentFragment } from 'gql/index';
import { useFormFillerConditionHandler } from '../../FormFiller/useFormFillerConditionHandler';
import { BooleanViewer } from './BooleanViewer';
import { DateViewer } from './DateViewer';
import { FilesViewer } from './FilesViewer';
import { MatrixViewer } from './MatrixViewer';
import { NumericViewer } from './NumericViewer';
import { SelectionViewer } from './SelectionViewer';
import { TextViewer } from './TextViewer';

type Field = FormDefinitionFragmentFragment['sections'][number]['fields'][number];

export interface FieldViewerProps {
  index: number;
  editable?: boolean;
  definition: FormDefinitionFragmentFragment;
  field: Field;
}

const fieldViewerMap: Record<FieldType, React.FC<FieldViewerProps>> = {
  Text: TextViewer,
  Boolean: BooleanViewer,
  Date: DateViewer,
  File: FilesViewer,
  Matrix: MatrixViewer,
  Numerical: NumericViewer,
  Selection: SelectionViewer,
};

export const FieldViewer: React.FC<FieldViewerProps> = (props) => {
  const ComponentToShow = fieldViewerMap[props.field.fieldType];

  const { isVisible } = useFormFillerConditionHandler({ fieldId: props.field.id, conditions: props.definition.conditions });

  if (!isVisible) {
    return null;
  }

  return <ComponentToShow {...props} />;
};