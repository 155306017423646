import { useEffect, useState } from 'react';

interface Rewardful {
  referral: string;
}

declare global {
  interface Window {
    Rewardful: Rewardful;
  }
}

export const useAffiliateTracking = () => {
  const [referralId, setReferralId] = useState<string>('');

  const saveReferral = () => {
    setReferralId(window.Rewardful.referral ?? '');
  };

  useEffect(() => {
    window.addEventListener('Rewardful.initialized', saveReferral);

    return () => window.removeEventListener('Rewardful.initialized', saveReferral);
  }, []);

  return [referralId];
};