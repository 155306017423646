import { PageSection } from '@components/PageSection/PageSection';
import { useCurrentProject } from '@modules/projects/utils/useCurrentProject';
import { ProjectTaskQuery } from 'gql/index';
import React from 'react';
import { useIntl } from 'react-intl';
import { TasksTable } from '../TasksTable';

interface Props {
  task: NonNullable<ProjectTaskQuery['projectTask']>;
}

export const TaskPredecessorsSection: React.FC<Props> = ({ task }) => {
  const { formatMessage } = useIntl();
  const { canManageTasks } = useCurrentProject();

  return (
    <PageSection
      title={formatMessage({ id: 'Predecessors' })}

    >
      <TasksTable
        tasks={task.predecessors}
        flex={false}
        noDataMessage={!canManageTasks ?
          formatMessage({ id: 'No predecessors found. There may be predecessors that you do not have access to.' }) :
          formatMessage({ id: 'No predecessors.' })}
      />
    </PageSection>
  );
};