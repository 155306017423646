import { AppBar, IconButton, LinearProgress, styled, Toolbar, Typography } from '@mui/material';
import React from 'react';

export interface DialogTopBarProps {
  leftIcon: React.ReactNode,
  leftIconAction: () => void,
  actionButton?: React.ReactNode;
  title: string;
  disabled?: boolean;
  loading?: boolean;
}

const StyledToolbar = styled(Toolbar)(() => ({
  '& .MuiTypography-root': {
    textTransform: 'uppercase'
  }
}));

export const DialogTopBar: React.FC<DialogTopBarProps> = (props) => {
  return (
    <AppBar position='sticky'>
      <StyledToolbar sx={{ display: 'flex', flexDirection: 'row', px: { xs: 1, md: 2 } }}>
        <IconButton
          onClick={props.leftIconAction}
          disabled={props.disabled}
          sx={{ mr: { xs: 1, md: 2 } }}
        >
          {props.leftIcon}
        </IconButton>
        <Typography variant='h6' overflow='hidden' textOverflow='ellipsis' noWrap flexGrow={1}>
          {props.title}
        </Typography>

        {props.actionButton}
      </StyledToolbar>

      {props.loading && (
        <LinearProgress />
      )}
    </AppBar>
  );
};