import React from 'react';
import { NavLink } from 'react-router-dom';
import { NavigationMenuItem, NavigationMenuItemProps } from './NavigationMenuItem';

interface Props extends NavigationMenuItemProps {
  to: string;
}

export const NavigationMenuLink: React.FC<Props> = (props) => {
  const { to, ...navigationMenuItemProps } = props;

  const menuItem = <NavigationMenuItem {...navigationMenuItemProps} />;

  if (props.disabled) {
    return (
      <div>
        {menuItem}
      </div>
    );
  } else {
    return (
      <NavLink to={to} className={({ isActive }) => isActive ? 'active' : ''} style={{ textDecoration: 'none' }}>
        {menuItem}
      </NavLink>
    );
  }
};