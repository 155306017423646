import { Chip, Divider, Stack } from '@mui/material';
import dayjs from 'dayjs';
import { RecentEventTimelineItem } from './RecentEventTimelineItem';
import { RecentEvent } from './types';

export const RecentEventsDayGrouping = (props: { recentEvents: RecentEvent[] }) => {
  if (props.recentEvents.length === 0) {
    return null;
  }

  return (
    <>

      <Stack direction='row' flex={1} justifyContent={'center'} alignItems={'center'} sx={{ ml: '-16px' }}>
        <Divider orientation='horizontal' sx={{ flex: 1 }} />
        <Chip sx={{ mx: 1, borderColor: theme => theme.palette.divider, backgroundColor: 'background.paper' }} variant='outlined' size='small' label={dayjs(props.recentEvents[0].eventDate).format('LL')} />
        <Divider orientation='horizontal' sx={{ flex: 1 }} />

      </Stack>

      {props.recentEvents.map((p, index) =>
        <RecentEventTimelineItem key={`${p.eventDate}-${index}`} recentEvent={p} isFirstItem={index === 0} isLastItem={index === props.recentEvents.length - 1} />
      )}
    </>
  );
};