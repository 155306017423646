import { ReactComponent as TimeManagement } from '@assets/images/undraw_time_management.svg';
import { ResponsiveDialog } from '@components/Dialogs/ResponsiveDialog';
import { Button, Stack, Typography, useTheme } from '@mui/material';
import { getTenantIdentifier } from '@utils/getTenantIdentifier';
import React from 'react';
import { useIntl } from 'react-intl';

export const TrialEndedModal: React.FC = () => {
  const { formatMessage } = useIntl();
  const onManageSubscriptionClicked = async () => {
    location.replace(`/${getTenantIdentifier()}/api/Subscription/Manage`);
  };

  const theme = useTheme();
  return (
    <ResponsiveDialog open sx={{ backdropFilter: 'blur(3px)' }} maxWidth='md'>
      <Stack alignItems='center' justifyContent='center' p={4} gap={2}>
        <TimeManagement fill={theme.palette.primary.main} style={{ width: 'auto', height: 'auto', maxWidth: '300px' }} />

        <Typography mt={4} variant='h4' color='primary.main'>
          {formatMessage({ id: 'Your trial has ended' })}
        </Typography>

        <Typography >
          {formatMessage({ id: 'Please contact ARIV to continue using the platform.' })}
        </Typography>

        <Button
          onClick={onManageSubscriptionClicked}
          sx={{ mt: 2 }}
          variant='contained'>
          {formatMessage({ id: 'Upgrade now' })}
        </Button>
      </Stack>
    </ResponsiveDialog>
  );
};