import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { FormFillerValues } from '../../FormFiller/types';
import { FieldViewerProps } from './FieldViewer';

export const BooleanViewer: React.FC<FieldViewerProps> = ({ field, index, editable }) => {
  const { formatMessage } = useIntl();

  const { control, setValue, getValues } = useFormContext<FormFillerValues>();

  const onRadioButtonClicked = (newValue: string) => {
    if (getValues(`values.${index}.boolean`) == newValue) {
      setValue(`values.${index}.boolean`, '');
    } else {
      setValue(`values.${index}.boolean`, newValue as '' | 'true' | 'false');
    }
  };

  return (
    <FormControl disabled={!editable}>
      <FormLabel>{field.name}</FormLabel>
      <Controller
        control={control}
        name={`values.${index}.boolean`}
        render={({ field: { value } }) => (
          <RadioGroup row value={value === '' ? null : value} >
            <FormControlLabel value="true" onClick={() => onRadioButtonClicked('true')} control={<Radio />} label={formatMessage({ id: 'Yes' })} />
            <FormControlLabel value="false" onClick={() => onRadioButtonClicked('false')} control={<Radio />} label={formatMessage({ id: 'No' })} />
          </RadioGroup>
        )}
      />
    </FormControl>
  );
};