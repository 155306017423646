import React from 'react';
import { ReactComponent as GoogleIcon } from '@assets/icons/logo-google.svg';
import { useIntl } from 'react-intl';
import { LoginButton } from './LoginButton';
import { LoadingButtonProps } from '@mui/lab';

export const GoogleLoginButton: React.FC<LoadingButtonProps> = (props) => {
  const { formatMessage } = useIntl();

  return (
    <LoginButton {...props} startIcon={<GoogleIcon />}>
      {formatMessage({ id: 'Log in with Google' })}
    </LoginButton>
  );
};