import { KeyboardArrowDown, KeyboardArrowUp, MoreVert } from '@mui/icons-material';
import { Button, ButtonProps, IconButton, IconButtonProps, Menu, MenuProps, styled } from '@mui/material';
import { isArray } from 'lodash';
import React, { ReactNode, useRef } from 'react';

export interface ActionMenuProps extends Omit<MenuProps, 'open' | 'variant'> {
  open?: boolean;
  onOpen?: (value: boolean) => void;
  variant?: 'button' | 'squareIcon';
  buttonProps?: ButtonProps;
  iconButtonProps?: IconButtonProps;
}

export const SquareIconButton = styled(Button)(({ theme }) => ({
  '.MuiButton-endIcon': {
    margin: 0
  },
  padding: theme.spacing(1),
  minWidth: 'unset'
}));

type Variant = ActionMenuProps['variant'];

const isEmpty = (children: ReactNode) => {
  if (!children) {
    return true;
  }

  if (isArray(children) && children.filter(Boolean).length === 0) {
    return true;
  }

  return false;
};

export const ActionMenu: React.FC<ActionMenuProps> = ({ children, open: controlledOpen, onOpen, buttonProps, variant, iconButtonProps, ...menuProps }) => {
  const ref = useRef<HTMLButtonElement | null>(null);

  const [uncontrolledOpen, setUncontrolleOpen] = React.useState(false);

  const onMenuClick = () => {
    onOpen?.(true);
    setUncontrolleOpen(!uncontrolledOpen);
  };

  const onClose = () => {
    onOpen?.(false);
    setUncontrolleOpen(false);
  };

  const open = controlledOpen ?? uncontrolledOpen;

  const variantMap: Record<NonNullable<Variant> | 'icon', ReactNode> = {
    button: (
      <Button
        ref={ref}
        onClick={onMenuClick}
        endIcon={open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
        {...buttonProps}
        style={open ? { visibility: 'visible' } : undefined}
      >
        {buttonProps?.children}
      </Button>
    ),
    icon: (
      <IconButton
        color='inherit'
        ref={ref}
        onClick={onMenuClick}
        {...iconButtonProps}
        style={open ? { visibility: 'visible' } : undefined}
      >
        <MoreVert />
      </IconButton>
    ),
    squareIcon: (
      <SquareIconButton
        ref={ref}
        onClick={onMenuClick}
        endIcon={<MoreVert />}
        {...buttonProps}
        style={open ? { visibility: 'visible' } : undefined}
      />
    )
  };

  if (isEmpty(children)) {
    return null;
  }

  return <>
    {variantMap[variant ?? 'icon']}

    <Menu anchorEl={ref.current} open={open} onClose={onClose} onClick={onClose} {...menuProps}>
      {children}
    </Menu>
  </>;
};