import { LoadingScreen } from '@components/LoadingScreen';
import { getTenantIdentifier } from '@utils/getTenantIdentifier';
import { useGetMe } from '@utils/useGetMe';
import { useResponsive } from '@utils/useResponsive';
import { useProjectAccessTokenQuery } from 'gql/index';
import { useMemo } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { AnonymousDocumentDetailsDialog } from '../components/AnonymousDocumentDetailsDialog';
import { AnonymousProjectPreview } from '../components/AnonymousProjectPreview';
import { RenewAccessTokenDialog } from '../components/RenewAccessTokenDialog';
import { UnauthorizedAccessDialog } from '../components/UnauthorizedAccessDialog';

export const AnonymousDocumentPage: React.FC = () => {
  const { token } = useParams();
  const { isMobile } = useResponsive();

  const { data: content, isFetching } = useProjectAccessTokenQuery({ token: token ?? '' }, { select: d => d.projectAccessTokenContent });
  const { me } = useGetMe();

  const inner = useMemo(() => {
    if (!token || !content) return;

    if (content.tokenValidationError && content.isTokenRenewable) {
      return <RenewAccessTokenDialog token={token} />;
    }

    if (content.tokenValidationError && !content.isTokenRenewable) {
      return <UnauthorizedAccessDialog />;
    }

    return <AnonymousDocumentDetailsDialog content={content} token={token} />;
  }, [content, token]);

  if (isFetching) {
    return <LoadingScreen />;
  }

  if (me && me.id == content?.forUser?.id && me.builtInRoles.length > 0 && content?.project) {
    return <Navigate to={`/${getTenantIdentifier()}/projects/${content?.project?.id}/home`} />;
  }

  if (content?.document == null) {
    return <Navigate to='/error/404' />;
  }

  return (
    <div style={{ position: 'relative', height: '100%', ...!isMobile && { overflow: 'hidden' } }}>
      <AnonymousProjectPreview isLoading={isFetching} projectName={content?.project?.name ?? ''} />

      {inner}
    </div>
  );
};