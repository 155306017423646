import { SubmissionStatus, useProjectFormSubmissionsQuery } from 'gql/index';
import { useCurrentProject } from '../../projects/utils/useCurrentProject';
import { SubmissionsTable } from './SubmissionsTable';

export const FilledFormsView: React.FC = () => {
  const { projectId } = useCurrentProject();
  const { data: submissions, isFetching } = useProjectFormSubmissionsQuery({
    projectId, filter: {
      status: { neq: SubmissionStatus.IsResponding }
    }
  }, {
    select: d => d.projectFormSubmissions
  });

  return (
    <>
      <SubmissionsTable submissions={submissions ?? []} loading={isFetching} />
    </>
  );
};