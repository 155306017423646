import { Button, CircularProgress, ListItemText, Stack, TextField, Typography } from '@mui/material';
import { whereFieldIsNotNull } from '@utils/NonNullableField';
import { getTenantIdentifier } from '@utils/getTenantIdentifier';
import { useUserTenantsQuery } from 'gql/index';
import { useIntl } from 'react-intl';

export const TenantSwitcher = () => {
  const { formatMessage } = useIntl();

  const { data: userTenants, isFetching } = useUserTenantsQuery(undefined, { select: d => d.userTenants });
  const currentTenantIdentifier = getTenantIdentifier();

  if (userTenants == null || userTenants.length === 1) {
    return null;
  }

  return (
    <TextField
      select
      label={formatMessage({ id: 'Organization' })}
      value={currentTenantIdentifier}
      disabled={isFetching}
      SelectProps={{
        renderValue: !isFetching
          ? () => (
            <Typography>
              {userTenants.find(t => t.identifier === currentTenantIdentifier)?.name ?? formatMessage({ id: 'Loading...' })}
            </Typography>
          )
          : () => (
            <Stack width='100%' height='100%' alignItems='center' justifyContent='center'>
              <CircularProgress size='24px' />
            </Stack>
          )
      }}
      sx={{ minWidth: '200px' }}
    >
      {whereFieldIsNotNull(userTenants, 'identifier').map(tenant => (
        <form key={tenant.identifier} action={`/${getTenantIdentifier()}/api/Tenants/SwitchTenant`} method='post'>
          <input hidden name='identifier' value={tenant.identifier} />
          <Button variant='text' fullWidth color='inherit' type='submit'>
            <ListItemText primary={tenant.name} />
          </Button>
        </form>
      ))}
    </TextField>
  );
};