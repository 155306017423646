import { useCurrentProject } from '@modules/projects/utils/useCurrentProject';
import { ExternalLinkFragmentFragment, ShareableResourceType, useExternalLinkPermissionsQuery } from 'gql/index';
import React from 'react';
import { PropertyGroupSkeleton } from '../../../../../components/DataDisplay/PropertyGroupSkeleton';
import { PermissionsProperty } from '../../PermissionsProperty';

interface Props {
  externalLink: ExternalLinkFragmentFragment;
}

export const ExternalLinkPermissions: React.FC<Props> = ({ externalLink }) => {
  const { projectId } = useCurrentProject();

  const { data: permissions, isLoading } = useExternalLinkPermissionsQuery({ projectId, linkId: externalLink.id }, { select: d => d.externalLinkPermissions });


  if (isLoading) {
    return (
      <PropertyGroupSkeleton propertiesCount={2} />
    );
  }

  return (
    <>
      <PermissionsProperty
        resource={{ ...externalLink, resourceType: ShareableResourceType.ExternalLink }}
        roleAssignments={permissions ?? []}
      />
    </>
  );
};