import { FileIcon } from '@components/Icons/FileIcon';
import { Share, Start } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Backdrop, Box, Container, Divider, ListItemText, Paper, Stack, Typography } from '@mui/material';
import { getTenantIdentifier } from '@utils/getTenantIdentifier';
import { useResponsive } from '@utils/useResponsive';
import { ProjectAccessTokenQuery, useRequestFormSubmissionAnonymousMutation } from 'gql/index';
import React from 'react';
import { useIntl } from 'react-intl';
import { AnonymousProjectPreview } from './AnonymousProjectPreview';
import { SignUpBanner } from './SignUpBanner';

export interface Props {
  token: string;
  content: NonNullable<ProjectAccessTokenQuery['projectAccessTokenContent']>;
  onSubmissionStarted: (submissionId: number) => void;
}

export const AnonymousFormDetailsDialog: React.FC<Props> = ({ token, content, onSubmissionStarted }) => {
  const { isMobile } = useResponsive();
  const { formatMessage } = useIntl();

  const publishedBy = content.projectForm?.publishedBy;

  const { mutate: requestFormSubmission, isLoading: isRequestingSubmission } = useRequestFormSubmissionAnonymousMutation({
    onSuccess: d => onSubmissionStarted(d.requestFormSubmissionAnonymous.projectFormSubmission?.id ?? 0)
  });

  const formNameAndIcon = (
    <Stack direction='row' minWidth={!isMobile ? '400px' : undefined} alignItems='center' gap={2} py={1} px={2} border={t => `1px solid ${t.palette.divider}`} my={2}>
      <FileIcon fileIconType='InternalForm' fontSize='large' />

      <ListItemText
        primary={content.projectForm?.name}
        secondary={content.projectForm?.description}
        sx={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}
      />
    </Stack>
  );

  const inner = (
    <Stack direction='column' alignItems='center' pt={8} pb={4} px={2}>
      <Stack alignItems='center' pb={6} gap={3}>
        <Stack fontSize='84px'>
          <Share fontSize='inherit' color='primary' />
        </Stack>

        <Typography variant='h5'>
          {formatMessage({ id: '{name} has shared a form with you' }, { name: publishedBy?.fullName })}
        </Typography>
      </Stack>

      <Divider sx={{ width: '100%' }} />

      <Typography mt={4}>
        {formatMessage({ id: 'Click the button below to begin filling the form.' })}
      </Typography>

      {formNameAndIcon}

      <Box>
        <LoadingButton size='large' startIcon={<Start />} variant='contained' onClick={() => requestFormSubmission({ input: { token } })} loading={isRequestingSubmission}>
          {formatMessage({ id: 'Fill form' })}
        </LoadingButton>
      </Box>
    </Stack>
  );

  return <>
    <div style={{ position: 'relative', overflow: !isMobile ? 'hidden' : undefined }}>
      <AnonymousProjectPreview projectName={content.project?.name ?? ''} isLoading={false} />

      {isMobile ? (
        <Stack gap={4}>
          {inner}

          <SignUpBanner
            userFullName={content.forUser?.fullName ?? ''}
            tenantDisplayName={content.tenant?.name ?? getTenantIdentifier() ?? ''}
            projectId={content.project?.id ?? 0}
          />
        </Stack>
      ) : (
        <Backdrop open sx={{ backdropFilter: 'blur(3px)', position: 'absolute' }}>
          <Container maxWidth='lg' component={Stack} spacing={2}>
            <SignUpBanner
              userFullName={content.forUser?.fullName ?? ''}
              tenantDisplayName={content?.tenant?.name ?? getTenantIdentifier() ?? ''}
              projectId={content?.project?.id ?? 0} />

            <Paper style={{ overflowY: 'auto' }}>
              {inner}
            </Paper>
          </Container>
        </Backdrop>
      )}
    </div>
  </>;
};
