import { FolderPickerField } from '@modules/documents/components/FolderPicker/FolderPickerField';
import { useCurrentProject } from '@modules/projects/utils/useCurrentProject';
import { FormControlLabel, Stack, Switch, TextField } from '@mui/material';
import { useEffect } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { useValidationRules } from '../../../../../../../utils/useValidationRules';
import { FormEditorFieldValues } from '../../../types';
import { InlineEditableComponentProps } from '../../DraggableFormField';
import { FormFieldLabel } from '../../FormFieldLabel';
import { FormUploadFileInputSample } from './FormUploadFileInputSample';


type Props = InlineEditableComponentProps;

export const FileFormFieldConfiguration = (props: Props) => {
  const { projectId: projectIdString } = useParams();
  const projectId = projectIdString ? Number(projectIdString) : undefined;

  const isTemplate = !projectId;


  const { formatMessage } = useIntl();
  const { notEmpty } = useValidationRules();

  const { control, setValue } = useFormContext<FormEditorFieldValues>();

  const { rootFolder } = useCurrentProject({ disabled: isTemplate });

  const shouldUploadDocumentsToFolder = useWatch({ control: control, name: 'shouldUploadDocumentsToFolder' });
  const documentUploadFolderId = useWatch({ control: control, name: 'documentUploadFolderId' });

  useEffect(() => {
    if (!isTemplate && !documentUploadFolderId) {
      setValue('documentUploadFolderId', rootFolder?.id || null);
    }
  }, [documentUploadFolderId, isTemplate, rootFolder?.id, setValue]);

  const question = useWatch({ control: control, name: 'name' });
  const required = useWatch({ control: control, name: 'isRequired' });

  const sampleControl = (
    <FormUploadFileInputSample />
  );

  return props.isActive ? <>
    <Stack p={2} pb={0} gap={1}>
      <Controller
        name='name'
        control={control}
        rules={{ validate: notEmpty }}
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            autoFocus
            fullWidth
            required
            error={Boolean(error)}
            helperText={error?.message}
            label={formatMessage({ id: 'Question' })}
          />
        )}
      />

      {sampleControl}

      <Stack>
        <Controller
          control={control}
          name='isMultiselect'
          render={({ field }) => (
            <FormControlLabel
              control={<Switch checked={field.value} onChange={(_, checked) => field.onChange(checked)} />}
              label={formatMessage({ id: 'Allow multiple files' })}
              labelPlacement='start'
            />
          )}
        />

        {!isTemplate && <>
          <Controller
            control={control}
            name='shouldUploadDocumentsToFolder'
            render={({ field }) => (
              <FormControlLabel
                control={<Switch checked={field.value} onChange={(_, checked) => field.onChange(checked)} />}
                label={formatMessage({ id: 'Save uploaded files in documents' })}
                labelPlacement='start'
              />
            )}
          />

          {shouldUploadDocumentsToFolder && (
            <Controller
              control={control}
              name='documentUploadFolderId'
              rules={{ validate: notEmpty }}
              render={({ field, fieldState: { error } }) => (
                <FolderPickerField
                  label={field.value ? formatMessage({ id: 'Specific folder' }) : formatMessage({ id: 'Specific folder?' })}
                  value={field.value || null}
                  onChange={field.onChange}
                  error={Boolean(error)}
                  helperText={error?.message}
                />
              )}
            />
          )}
        </>}
      </Stack>
    </Stack>
  </> : (
    <Stack p={2}>
      <FormFieldLabel required={required} italic={!question}>
        {question || formatMessage({ id: 'No name set' })}
      </FormFieldLabel>

      {sampleControl}
    </Stack>
  );
};