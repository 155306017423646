import { AnonymousContextProvider } from '@modules/projectAccessTokens/AnonymousContextProvider';
import React from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { SettingsDrawer } from '../components/SettingsDrawer';
import { AnonymousTopbar } from '../components/TopBar/AnonymousTopbar';

export const AnonymousPageLayout: React.FC = () => {
  const { token } = useParams();
  return <>
    <AnonymousContextProvider accessToken={token ?? ''}>
      <AnonymousTopbar />

      <SettingsDrawer />

      <main style={{ flex: 1, display: 'flex', flexDirection: 'column', overflow: 'auto' }}>

        <Outlet />

      </main>
    </AnonymousContextProvider>
  </>;
};