import { OnDataOptions } from '@apollo/client';
import { uniqueClientId } from '@modules/application/apollo-client';
import { Container, Paper, Stack, Typography } from '@mui/material';
import { FormDefinitionFragmentFragment, FormValueFragmentFragment, FormValueInput } from 'gql/index';
import React, { ReactNode, useCallback, useContext, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FormValueEditedSubscription, useFormValueEditedSubscription } from '../../../../gql/subscriptions';
import { AnonymousContext } from '../../../projectAccessTokens/AnonymousContextProvider';
import { FormFillerContextProvider } from './FormFillerContextProvider';
import { FormFillerSection } from './FormFillerSection';
import { FormFillerSubmitButton } from './FormFillerSubmitButton';
import { FormFillerTopBar } from './FormFillerTopBar';
import { FormFillerValues } from './types';
import { useFormFillerPresence } from './useFormFillerPresence';
import { backendValuesToFormFillerValues, formFillerValuesToMutationValues, formValueToFormFillerValue } from './utils';

export interface FormFillerProps {
  disabled?: boolean;
  submissionId: number;
  formDefinition: FormDefinitionFragmentFragment;
  values: FormValueFragmentFragment[];
  onSubmit?: (values: FormValueInput[]) => void;
  isSubmitting: boolean;
  signUpBanner?: ReactNode;
}

export const FormFiller: React.FC<FormFillerProps> = (props) => {
  const { formDefinition, onSubmit, values, signUpBanner, disabled, submissionId } = props;

  const { accessToken } = useContext(AnonymousContext);

  const definitionFields = formDefinition.sections.flatMap(s => s.fields);

  const defaultValues: FormFillerValues = useMemo(() => ({
    values: backendValuesToFormFillerValues(definitionFields, values, submissionId)
  }), [definitionFields, submissionId, values]);

  const form = useForm<FormFillerValues>({ defaultValues, mode: 'onSubmit' });
  const { setValue, getValues } = form;

  const nonEmptySections = formDefinition.sections.filter(s => s.fields.length > 0);

  const onFormValueEdited = useCallback((onDataOptions: OnDataOptions<FormValueEditedSubscription>) => {
    const event = onDataOptions.data.data?.formValueEdited;
    const formValue = event?.formValue;

    if (!formValue) return;

    if (event.initiatorUniqueClientId === uniqueClientId) return;

    const currentValues = getValues('values');
    let valueIndex = currentValues.findIndex(p => p.fieldId === formValue.fieldId);
    if (valueIndex === -1) {
      valueIndex = currentValues.length;
    }

    const newValue = formValueToFormFillerValue(formValue, submissionId);
    setValue(`values.${valueIndex}`, newValue);
  }, [getValues, setValue, submissionId]);

  useFormValueEditedSubscription({
    onData: onFormValueEdited,
    variables: { submissionId, accessToken }
  });

  const { addFormPresence, removeFormPresence } = useFormFillerPresence(submissionId);
  useEffect(() => {
    addFormPresence();
    return () => removeFormPresence();
  }, [addFormPresence, removeFormPresence]);

  const onSubmitClicked = (values: FormFillerValues) => {
    if (!props.isSubmitting) {
      onSubmit?.(formFillerValuesToMutationValues(values));
    }
  };

  return (
    <FormFillerContextProvider submissionId={submissionId} formDefinition={formDefinition}>
      <FormFillerTopBar title={formDefinition.name} />

      <Container maxWidth='xl' sx={{ px: 1, py: 2 }}>
        {signUpBanner && (
          <Stack pb={2}>
            {signUpBanner}
          </Stack>
        )}

        <Paper sx={{ p: 2 }}>
          <Typography variant='h4'>{formDefinition.name}</Typography>
          {formDefinition.description && (
            <Typography variant='body1'>{formDefinition.description}</Typography>
          )}
        </Paper>

        <FormProvider {...form}>
          <Stack gap={2} pt={2}>
            {nonEmptySections.map((section, index) => (
              <FormFillerSection
                section={section}
                key={section.id}
                disabled={disabled}
                fieldIndexOffset={nonEmptySections.filter((_, i) => i < index).flatMap(s => s.fields).length}
              />
            ))}
          </Stack>

          <Stack py={2} alignItems='end'>
            <FormFillerSubmitButton onSubmit={onSubmitClicked} loading={props.isSubmitting} />
          </Stack>
        </FormProvider>
      </Container>
    </FormFillerContextProvider>
  );
};