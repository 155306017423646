import { Close } from '@mui/icons-material';
import { AppBar, IconButton, Stack, Typography } from '@mui/material';
import { useFolderEntryQuery } from 'gql/index';
import { useContext } from 'react';
import { getFolderEntryName } from '../utils/getFolderEntryName';
import { ProjectDocumentsContext } from './ProjectDocumentsContextProvider';
import { DocumentPropertiesList } from './Properties/DocumentProperties/DocumentPropertiesList';
import { ExternalLinkPropertiesList } from './Properties/ExternalLinkProperties/ExternalLinkPropertiesList';
import { FolderPropertiesList } from './Properties/FolderProperties/FolderPropertiesList';

export const ExplorerDesktopSidebar = () => {
  const { currentFolderId, selectedEntryId, setSelectedEntryId } = useContext(ProjectDocumentsContext);


  const { data: selectedEntry } = useFolderEntryQuery({ id: selectedEntryId || 0 }, {
    select: d => d.folderEntry,
    enabled: !!selectedEntryId
  });

  return (
    <Stack width='350px' minWidth='350px' overflow='auto' sx={{ borderLeft: '1px solid', borderColor: 'divider' }}>
      {selectedEntry && <>
        <AppBar position='static' elevation={1} sx={{
          py: 1, px: 2,
          display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between',
          boxShadow: 'none', borderBottom: '1px solid', borderColor: 'divider'
        }}>
          <Typography variant='body1' color='text.primary'>
            {getFolderEntryName(selectedEntry)}
          </Typography>

          <IconButton onClick={() => setSelectedEntryId?.(undefined)}>
            <Close />
          </IconButton>
        </AppBar>

        <Stack sx={{ overflow: 'auto' }}>
          {selectedEntry.document && (
            <DocumentPropertiesList documentId={selectedEntry.document.id} />
          )}

          {selectedEntry.externalLink && (
            <ExternalLinkPropertiesList externalLink={selectedEntry.externalLink} />
          )}
        </Stack>
      </>
      }

      {(!selectedEntryId) && (
        <FolderPropertiesList folderId={currentFolderId} />
      )}
    </Stack>
  );
};