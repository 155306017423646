import { Divider, TableCell, TableCellProps, styled } from '@mui/material';

const TableCellStyled = styled(TableCell)<TableCellProps>(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  border: `1px solid ${theme.palette.divider}`,
  borderBottom: 0,
  borderTop: 0,
  position: 'relative'
}));

const StyledDivider = styled(Divider)(() => ({
  position: 'absolute',
  bottom: 0,
  left: '5%',
  width: '90%'
}));

type OwnProps = TableCellProps
  & {
    isLast?: boolean
  }

export const ValueCell: React.FC<OwnProps> = ({ isLast, children, ...cellProps }) => {
  return (
    <TableCellStyled {...cellProps} >
      {children}
      {!isLast &&
        <StyledDivider sx={{ pb: 2 }} />
      }
    </TableCellStyled>
  );
};