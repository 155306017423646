import { Box, Modal, ModalProps, styled } from '@mui/material';

const StyledBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[24],
  padding: theme.spacing(2)
}));

export const BasicModal: React.FC<ModalProps> = (modalProps) => {
  return <Modal {...modalProps} >
    <StyledBox maxWidth={'100vw'}>
      {modalProps.children}
    </StyledBox>
  </Modal>;
};