import React from 'react';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';

import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';

import { useRecoilState } from 'recoil';
import { currentThemeNameState } from '../../../atoms';
import { useIntl } from 'react-intl';

export const ThemeSelector: React.FC = () => {
  const { formatMessage } = useIntl();
  const [preferredThemeName, setPreferredThemeName] = useRecoilState(currentThemeNameState);

  const renderThemeButton = (themeName: string, title: string, icon: React.ReactNode) => (
    <ToggleButton
      value={themeName}
      selected={themeName === preferredThemeName}
      onClick={() => setPreferredThemeName(themeName)}
    >
      {icon} {title}
    </ToggleButton>
  );

  return (
    <ToggleButtonGroup color="primary" exclusive fullWidth sx={{ py: 1 }}>
      {renderThemeButton('light', formatMessage({ id: 'Light' }), <LightModeIcon sx={{ mr: 1 }} />)}
      {renderThemeButton('dark', formatMessage({ id: 'Dark' }), <DarkModeIcon sx={{ mr: 1 }} />)}
    </ToggleButtonGroup>
  );
};