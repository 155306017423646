import { UnstyledLink } from '@components/UnstyledLink';
import DownloadIcon from '@mui/icons-material/Download';
import { Button, IconButton, ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

interface Props {
  size?: 'small' | 'medium' | 'large';
  variant?: 'IconButton' | 'Button' | 'MenuItem';
  link: string,
  fileName: string,
  color?: string;
  onClick?: () => void;
}


export const DownloadLinkButton: React.FC<Props> = ({ variant, link, fileName, color, onClick, size }) => {
  const [isDisabled, setIsDisabled] = useState(false);
  const { formatMessage } = useIntl();

  useEffect(() => {
    if (isDisabled) {
      const timeout = setTimeout(() => setIsDisabled(false), 2000);
      return () => clearTimeout(timeout);
    }
  }, [isDisabled]);

  return (
    <UnstyledLink
      to={link}
      download={fileName}
      target="_blank"
      rel="noreferrer"
      onClickCapture={e => isDisabled ? e.preventDefault() : null}
      onClick={(e) => { e.stopPropagation(); setIsDisabled(true); }}
    >
      {(!variant || variant === 'IconButton') && (
        <IconButton size={size} color={color ? 'default' : 'inherit'} aria-label="download" onClick={onClick} sx={{ color: color ?? 'action' }}>
          <DownloadIcon color='inherit' />
        </IconButton>
      )}

      {variant === 'Button' && (
        <Button size={size} variant='contained' startIcon={<DownloadIcon htmlColor={color ?? 'action'} />} onClick={onClick}>
          {formatMessage({ id: 'Download' })}
        </Button>
      )}

      {variant === 'MenuItem' && (
        <MenuItem onClick={onClick}>
          <ListItemIcon><DownloadIcon /></ListItemIcon>
          <ListItemText primary={formatMessage({ id: 'Download' })} />
        </MenuItem>
      )}
    </UnstyledLink>
  );
};