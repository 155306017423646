import { DataTable } from '@components/DataTable';
import { useGetTenantsQuery } from 'gql/index';
import { useIntl } from 'react-intl';
import { TenantProvisioningChip } from './TenantProvisioningChip';

export const TenantsTable = () => {
  const { formatMessage } = useIntl();

  const { data: tenants, isFetching } = useGetTenantsQuery(undefined, { select: d => d.tenants });

  return (
    <DataTable
      rows={tenants ?? []}
      loading={isFetching}
      columns={[{
        field: 'name',
        flex: 1,
        headerName: formatMessage({ id: 'Name' })
      }, {
        field: 'identifier',
        flex: 1,
        headerName: formatMessage({ id: 'Identifier' })
      }, {
        field: 'provisioningStatus',
        flex: 1,
        headerName: formatMessage({ id: 'Provisioning status' }),
        renderCell: ({ row, value }) => row.id && <TenantProvisioningChip id={row.id} defaultStatus={value} />
      }]}
    />
  );
};