import { PrincipalPicker } from '@modules/users/components/PrincipalPicker';
import { Error, Warning } from '@mui/icons-material';
import { Alert, TextField, Typography } from '@mui/material';
import { useValidationRules } from '@utils/useValidationRules';
import dayjs from 'dayjs';
import { PrincipalType, useTenantSettingsQuery } from 'gql/index';
import { useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { AskSignatureFormValues } from './RequestSignatureDialog';

export const RequestSignatureDetailsStep = () => {
  const { formatMessage } = useIntl();
  const { notEmpty } = useValidationRules();

  const { control } = useFormContext<AskSignatureFormValues>();

  const { data: tenantSettings } = useTenantSettingsQuery({}, { staleTime: 600000, select: p => p.tenantSettings });

  const noCreditsLeft = useMemo(() => {
    return tenantSettings && tenantSettings.isSignDocumentByZohoEnabled && tenantSettings.remainingZohoSignatureRequests <= 0;
  }, [tenantSettings]);

  return <>
    {(tenantSettings?.isSignDocumentByZohoEnabled && tenantSettings?.remainingZohoSignatureRequests <= 3) &&
      <Alert color={noCreditsLeft ? 'error' : 'warning'} icon={noCreditsLeft ? <Error /> : <Warning />}>
        <Typography variant='body1'>{formatMessage({ id: 'You only have {nbSignatures} signatures left, contact Ariv to recharge {email}.' }, { nbSignatures: tenantSettings?.remainingZohoSignatureRequests, email: <a href='mailto: support@ariv.ca'>support@ariv.ca</a> })}</Typography>
      </Alert>
    }

    <Controller
      control={control}
      name='signatoryCustomerId'
      rules={{ required: formatMessage({ id: 'This field is required' }) }}
      render={({ field, fieldState: { error } }) => (
        <PrincipalPicker
          disableGroups
          required
          disabled={noCreditsLeft}
          error={error?.message}
          label={formatMessage({ id: 'Signatory' })}
          value={field.value ? [{ id: field.value, principalType: PrincipalType.User }] : []}
          onChange={principals => field.onChange(principals.at(0)?.id ?? '')}
        />
      )}
    />

    <Controller
      control={control}
      name='dueDate'
      rules={{
        validate: {
          notInFuture: value => {
            if (value && dayjs(value).isBefore(dayjs())) {
              return formatMessage({ id: 'Due date must be in the future.' });
            }
          },
          notEmpty
        }
      }}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          label={formatMessage({ id: 'Due date' })}
          error={error != null}
          helperText={error?.message}
          InputLabelProps={{ shrink: true }}
          type='date'
          disabled={noCreditsLeft}
        />
      )}
    />

    <Controller
      control={control}
      name='customMessage'
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          label={formatMessage({ id: 'Notes to recipient (optional)' })}
          multiline
          rows={2}
          error={error != null}
          helperText={error?.message}
          disabled={noCreditsLeft}
        />
      )}
    />
  </>;
};