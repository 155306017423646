import { Box, LinearProgress, Paper, PaperProps, Stack, styled, useTheme } from '@mui/material';
import { GridValidRowModel } from '@mui/x-data-grid';
import { DataGridPro, DataGridProProps } from '@mui/x-data-grid-pro';
import { useResponsive } from '@utils/useResponsive';
import { merge } from 'lodash';
import React, { useState } from 'react';
import { ExpandableSearchBar } from './ExpandableSearchBar';

export interface DataTableProps<T extends GridValidRowModel> extends DataGridProProps<T> {
  searchTerm?: string;
  onSearch?: (searchTerm: string) => void;
  searchBarPlaceholder?: string;
  noSearchResultsMessage?: string;
  noDataMessage?: string;
  height?: number | string;
  paperProps?: PaperProps;
  flex?: boolean;
  variant?: 'minimal' | 'standard';
}

const StyledPaper = styled(Paper)(({ theme }) => ({
  boxShadow: theme.shadows[1],
  '& .MuiDataGrid-root': {
    border: 'none',
    '& .MuiDataGrid-columnSeparator': {
      display: 'none'
    },
    '& .MuiDataGrid-cell': {
      outline: 'none !important',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    '& .MuiDataGrid-cell .MuiTypography-root': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    '& .MuiDataGrid-columnHeaders': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}));

const FloatingLinearProgress = styled(LinearProgress)(() => ({
  position: 'absolute',
  top: 0,
  width: '100%',
}));

export const DataTable = <T extends GridValidRowModel>({
  flex,
  searchTerm: searchTermControlled, onSearch, noSearchResultsMessage, noDataMessage, searchBarPlaceholder, loading, height, paperProps, variant,
  ...dataGridProps
}: DataTableProps<T>) => {
  const [searchTermUncontrolled, setSearchTerm] = useState('');
  const theme = useTheme();
  const { isMobile } = useResponsive();

  const searchTerm = searchTermControlled ?? searchTermUncontrolled;

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
    onSearch?.(e.target.value);
  };

  const hasData = dataGridProps.rows.length > 0;
  const heavyLoading = loading && !hasData;

  const [paginationModel, setPaginationModel] = useState(dataGridProps.paginationModel);

  const showFooter = dataGridProps.rows.length > (paginationModel?.pageSize || 100);
  const autoHeightEnabled = !flex && height == null;
  return (
    <Stack flex={flex ? 1 : undefined} height={height}>
      <StyledPaper
        {...paperProps}
        sx={merge(
          {},
          {
            marginBottom: autoHeightEnabled ? 2 : 0,
            display: flex ? 'flex' : undefined,
            flex: flex ? 1 : undefined,
            flexDirection: 'column',
            position: 'relative'
          },
          paperProps?.sx, variant === 'minimal' ? { boxShadow: 'none', border: '1px solid', borderColor: 'divider' } : undefined,
        )}
      >
        <div style={{ position: 'relative' }}>
          {onSearch && (
            <ExpandableSearchBar
              fullWidth
              placeholder={searchBarPlaceholder}
              value={searchTerm}
              onChange={handleSearch}
              sx={{ pt: 1, '& fieldset': { border: 'none' } }}
            />
          )}

          {(loading && !heavyLoading) && (
            <FloatingLinearProgress />
          )}
        </div>

        <Stack flex={1} position={'relative'} height={height}>
          <Box sx={{ height: height ?? '100%', width: '100%', position: flex ? 'absolute' : 'relative' }}>
            <DataGridPro<T>
              autoHeight={autoHeightEnabled}
              scrollbarSize={16} // fixes an issue that would cause the horizontal scrollbar to appear when the vertical scrollbar appears
              disableRowSelectionOnClick
              paginationModel={paginationModel}
              onPaginationModelChange={setPaginationModel}
              loading={heavyLoading}
              localeText={{
                ...theme.components?.MuiDataGrid?.defaultProps?.localeText,
                noRowsLabel: (searchTerm.length > 0 ? noSearchResultsMessage : noDataMessage) ?? theme.components?.MuiDataGrid?.defaultProps?.localeText?.noRowsLabel,
              }}
              hideFooter={!showFooter}
              slotProps={{
                footer: {
                  hidden: true
                }
              }}
              {...dataGridProps}
              pinnedColumns={isMobile ? undefined : dataGridProps.pinnedColumns}
            />
          </Box >
        </Stack>
      </StyledPaper >
    </Stack>
  );
};