import { ArrowBackIos } from '@mui/icons-material';
import { TimelineConnector, TimelineItem, TimelineSeparator } from '@mui/lab';
import { ReactNode } from 'react';

interface OwnProps {
  isFirst: boolean
  children: ReactNode
  id: string
}

export const ActionTimelineConnector: React.FC<OwnProps> = ({ id, children, isFirst }) => {
  return (
    <TimelineItem id={id}>
      <TimelineSeparator>
        {isFirst
          ?
          children
          :
          <>
            <TimelineConnector />
            <ArrowBackIos sx={{ color: theme => theme.palette.grey[400], transform: 'rotate(-90deg)', mb: 2 }} />
            {children}
          </>
        }
      </TimelineSeparator>
    </TimelineItem>
  );
};