import { getDocumentUrl } from '@modules/folders/utils/documentUrls';
import { Controller, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { SignaturePlacementControl } from '../SignaturePlacementControl';
import { AskSignatureFormValues } from './RequestSignatureDialog';

export const RequestSignatureCoordinatesStep = ({ documentId }: { documentId: number; }) => {
  const { formatMessage } = useIntl();

  const { control } = useFormContext<AskSignatureFormValues>();

  return (
    <Controller
      control={control}
      name='signatureCoordinates'
      rules={{
        validate: (field) => field == null
          ? formatMessage({ id: 'You must place the signature field on the document.' })
          : undefined
      }}
      render={({ field, fieldState: { error } }) => (
        <SignaturePlacementControl
          signatureCoordinates={field.value}
          onCoordinatesChanged={p => field.onChange(p)}
          documentUrl={getDocumentUrl(documentId)}
          error={error?.message}
        />
      )}
    />
  );
};