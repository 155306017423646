import { ReactComponent as InboxCleanupSvg } from '@assets/images/inbox-cleanup.svg';
import CheckCircleOutline from '@mui/icons-material/CheckCircleOutline';
import { LoadingButton } from '@mui/lab';
import { Container, Icon, Link as MuiLink, Paper, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { LoadingScreen } from '../../../components/LoadingScreen';
import { useIsEmailsUnsubscribeTokenValidQuery, useUnsubscribeFromEmailsMutation } from '../../../gql';
import { getTenantIdentifier } from '../../../utils/getTenantIdentifier';
import { useResponsive } from '../../../utils/useResponsive';

export const EmailsUnsubscribe = () => {
  const { formatMessage } = useIntl();
  const { isMobile } = useResponsive();
  const { token } = useParams();
  const [params] = useSearchParams();

  const userId = params.get('userId');

  const { data: isTokenValid, isLoading: isValidatingToken } = useIsEmailsUnsubscribeTokenValidQuery({ input: { token: token ?? '', userId: userId ?? '' } });
  const { mutate, isLoading: isUnsubscribing } = useUnsubscribeFromEmailsMutation();
  const [hasUnsubscribed, setHasUnsubscribed] = useState(false);

  if (isValidatingToken) {
    return <LoadingScreen />;
  }

  const unsubscribe = () => {
    mutate({ input: { token: token ?? '', userId: userId ?? '' } }, { onSuccess: () => setHasUnsubscribed(true) });
  };

  const unsubscribedContent = <Stack justifyContent='center' alignItems='center' p={2} py={4} spacing={2}>
    <Icon color='success' style={{ height: 120, width: 120 }} >
      <CheckCircleOutline style={{ height: 120, width: 120 }} />
    </Icon>

    <Typography variant='h5'>
      {formatMessage({ id: 'You are now unsubscribed from email updates.' })}
    </Typography>

    <Typography variant='h6'>
      {formatMessage({ id: 'You can reactivate email updates at any time in your user preferences after signing in.' })}
    </Typography>
  </Stack>;

  return <Container maxWidth='lg' sx={{ height: '100%' }}>

    <Stack alignItems={'center'} justifyContent={'center'} sx={{ height: '100%', width: '100%' }}>
      <Paper sx={{ p: 4, width: '100%' }} >
        {hasUnsubscribed ? unsubscribedContent :
          <Stack gap={4} alignItems={'center'} justifyContent={'center'}>

            <InboxCleanupSvg style={{ height: isMobile ? '180px' : '300px', width: 'auto' }} />

            {isTokenValid &&
              <>
                <Stack spacing={0.5}>
                  <Typography variant='h5' fontWeight={600} textAlign='center'>
                    {formatMessage({ id: 'Are you sure about unsubscribing?' })}
                  </Typography>
                  <Typography variant='h6' color='text.secondary' textAlign='center'>
                    {formatMessage({ id: 'You will no longer receive any project updates if you unsubscribe.' })}
                  </Typography>
                </Stack>


                <LoadingButton loading={isUnsubscribing} variant='contained' onClick={unsubscribe}>{formatMessage({ id: 'Unsubscribe' })}</LoadingButton>
              </>
            }

            {!isTokenValid &&
              <>
                <Stack spacing={0.5}>
                  <Typography variant='h5' fontWeight={600} textAlign='center'>
                    {formatMessage({ id: 'The link you used is no longer valid.' })}
                  </Typography>
                  <Typography variant='h6' color='text.secondary' textAlign='center'>
                    {formatMessage({ id: 'To unsubscribe you may sign in and opt out of email notifications from your user settings.' })}
                  </Typography>
                </Stack>

                <MuiLink component={Link} to={`/${getTenantIdentifier()}/`}>
                  {formatMessage({ id: 'Sign in' })}
                </MuiLink>
              </>
            }
          </Stack>
        }
      </Paper>
    </Stack>
  </Container>;
};