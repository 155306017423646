import { CancelButton } from '@components/Buttons/CancelButton';
import { LoadingButton } from '@mui/lab';
import { Button, Divider, FormHelperText, Stack, useTheme } from '@mui/material';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import SignaturePad from 'react-signature-pad-wrapper';
import { signaturePadHeight } from '.';

interface Props {
  onClose?: () => void;
  loading?: boolean;
  onSignatureComplete?: (signature: string) => void;
}

export const DrawSignature: React.FC<Props> = ({ loading, onClose, onSignatureComplete }) => {
  const theme = useTheme();
  const { formatMessage } = useIntl();

  const signaturePadRef = React.createRef<SignaturePad>();

  const { reset, handleSubmit, register, formState: { errors: { signature: signatureError } } } = useForm();

  register('signature', {
    validate: () => {
      const signaturePadIsEmpty = signaturePadRef.current?.isEmpty();
      if (signaturePadIsEmpty) {
        return formatMessage({ id: 'Please sign the document before submitting.' });
      }
      return true;
    }
  });

  useEffect(() => {
    if (loading) {
      signaturePadRef.current?.off();
    } else {
      signaturePadRef.current?.on();
    }
  }, [loading, signaturePadRef]);

  useEffect(() => {
    signaturePadRef.current?.clear();
    reset();
    // Reacting on signaturePadRef causes an infinite loop.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset]);

  const onClear = () => {
    signaturePadRef.current?.clear();
  };

  const onSubmit = () => {
    const signature = signaturePadRef.current?.toDataURL('image/jpeg');
    onSignatureComplete?.(signature ?? '');
  };

  return <>
    <Stack
      position='relative'
      // The +2 is to ensure the border at the bottom is visible
      height={signaturePadHeight + 2}
      onClickCapture={e => e.stopPropagation()}
      sx={{
        border: 1,
        borderColor: signatureError ? 'error.main' : 'grey.400'
      }}
    >
      <SignaturePad
        ref={signaturePadRef}
        redrawOnResize
        height={signaturePadHeight}
        options={{ backgroundColor: theme.palette.common.white }}
      />
      <Divider sx={{ position: 'absolute', bottom: '25%', left: '10%', right: '10%', borderColor: 'black' }} />
    </Stack>

    {signatureError?.message && (
      <FormHelperText error>
        {signatureError.message.toString()}
      </FormHelperText>
    )}

    <Stack direction='row' justifyContent='space-between' flexWrap='wrap' gap={2} px={{ xs: 2, md: 0 }}>
      <CancelButton onClick={onClose} disabled={loading} />

      <Stack spacing={2} direction='row'>
        <Button onClick={onClear} disabled={loading}>
          {formatMessage({ id: 'Clear' })}
        </Button>
        <LoadingButton variant='contained' onClick={handleSubmit(onSubmit)} loading={loading}>
          {formatMessage({ id: 'Accept and Sign' })}
        </LoadingButton>
      </Stack>
    </Stack>
  </>;
};