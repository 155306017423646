import { useTaskMessages } from '@modules/tasks/taskUtils';
import { Chip, Link as MuiLink, Stack, Typography } from '@mui/material';
import { MessageDescriptor, defineMessages, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { ProjectTaskFragmentFragment, ProjectTaskType } from '../../../../gql';
import { getTenantIdentifier } from '../../../../utils/getTenantIdentifier';
import { useCurrentProject } from '../../../projects/utils/useCurrentProject';

const ChipLabel: Record<ProjectTaskType, MessageDescriptor> = defineMessages({
  [ProjectTaskType.Custom]: { id: 'Custom' },
  [ProjectTaskType.FillForm]: { id: 'Form' },
  [ProjectTaskType.SignDocument]: { id: 'Signature' }
});

type Task = Pick<ProjectTaskFragmentFragment, 'id' | 'type' | 'name' | 'document' | 'projectForm'>;

type Props = {
  task: Task;
  disableLink?: boolean;
};

export const TaskNameCell = ({ task, disableLink }: Props) => {
  const { projectId } = useCurrentProject();
  const { taskNameGetterByType } = useTaskMessages();
  const { formatMessage } = useIntl();

  const name = taskNameGetterByType[task.type](task);

  return <Stack direction='row' alignItems='center' gap={2} overflow='hidden'>
    {!disableLink ? (
      <MuiLink component={Link} to={`/${getTenantIdentifier()}/projects/${projectId}/tasks/${task.id}`} title={name}>
        {name}
      </MuiLink>
    ) : (
      <Typography title={name}>
        {name}
      </Typography>
    )}

    {task.type !== ProjectTaskType.Custom && (
      <Chip color='info' size='small' label={formatMessage(ChipLabel[task.type])} />
    )}
  </Stack>;
};