import { DisableableTooltip } from '@components/Tooltips/DisableableTooltip';
import { PresenceDecorator } from '@components/Tooltips/PresenceDecorator';
import { Tooltip, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { useContextSafe } from '@utils/useContextSafe';
import { MatrixColumnType, MatrixFragmentFragment } from 'gql/index';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { FormFillerContext } from '../../FormFillerContextProvider';
import { FormFillerValues } from '../../types';
import { useFormFillerPresence } from '../../useFormFillerPresence';


type MatrixFillerColumn = MatrixFragmentFragment['columns'][0];

interface Props {
  rowId: number,
  columnId: number,

  value: unknown,
  column: MatrixFillerColumn,
}

export const MatrixFillerCell = ({ rowId, columnId, value, column }: Props) => {
  const { formatMessage } = useIntl();

  const { submissionId } = useContextSafe(FormFillerContext);
  const { presences } = useFormFillerPresence(submissionId);

  const { formState: { isSubmitted } } = useFormContext<FormFillerValues>();

  const presentUser = presences.find(p => p.matrixRowId == rowId && p.matrixColumnId == columnId);

  const hasEmptyValue = value == null || value === '';

  const hasEmptyValueError = hasEmptyValue && column.isRequired && isSubmitted;

  return (
    <Stack
      p='1px'
      overflow={'auto'}
      height='100%'
      width='100%'
      alignItems='center'
      justifyContent='center'
      direction='row'
      sx={{
        border: hasEmptyValueError ? t => `1px solid ${t.palette.error.main}` : 'none',
      }}
    >
      <DisableableTooltip title={hasEmptyValueError ? formatMessage({ id: 'A value is required' }) : undefined} fullWidth>
        <PresenceDecorator fullWidth userDisplayName={presentUser?.displayName}>
          {column.fieldType === MatrixColumnType.Boolean && (
            <span style={{ textAlign: 'center' }}>
              {value === true ? formatMessage({ id: 'Yes' })
                : value === false ? formatMessage({ id: 'No' })
                  : '-'}
            </span>
          )}
          {(column.fieldType === MatrixColumnType.Text) && (
            <Tooltip title={(value as string | number) ?? ''}>
              <Typography sx={{ wordWrap: 'break-word', whiteSpace: 'break-spaces', maxHeight: '100%', maxWidth: '100%', '::-webkit-scrollbar': { height: 2 } }}>
                {(value as string | number) ?? ''}
              </Typography>
            </Tooltip>
          )}
          {(column.fieldType === MatrixColumnType.Numerical) && (
            <Tooltip title={(value as string | number) ?? ''}>
              <Typography align='center'>
                {(value as string | number) ?? ''}
              </Typography>
            </Tooltip>
          )}
        </PresenceDecorator>
      </DisableableTooltip>
    </Stack>
  );
};