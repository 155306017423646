import { FileUpload, UploadManagerContext } from '@components/FileUpload/UploadManagerContext';
import { getTenantIdentifier } from '@utils/getTenantIdentifier';
import { useCallback, useContext } from 'react';
import { BrandingFiles } from '../types';

const brandingUploadBatchId = 'brandingImages';

export const useBrandImagesUploadManager = () => {
  const { uploads, queueUpload: queueFileUpload, cancelUpload } = useContext(UploadManagerContext);

  const queueUpload = useCallback((fileUpload: FileUpload) => queueFileUpload(brandingUploadBatchId, fileUpload), [queueFileUpload]);

  const cancelBrandingImagesUpload = useCallback(() => {
    const uploadState = uploads.filter(p => p.batchId === brandingUploadBatchId).at(0);
    uploadState && cancelUpload(uploadState.id);
  }, [cancelUpload, uploads]);

  const queueFiles = useCallback((files: BrandingFiles, onError: (error: string) => void, onSuccess: () => void) => {

    const formData = new FormData();
    files.logoLight && formData.append('LogoLight', files.logoLight);
    files.logoDark && formData.append('LogoDark', files.logoDark);
    files.loginBackgroundImage && formData.append('LoginBackgroundImage', files.loginBackgroundImage);
    files.squareLogoLight && formData.append('SquareLogoLight', files.squareLogoLight);
    files.squareLogoDark && formData.append('SquareLogoDark', files.squareLogoDark);

    const openRequest = () => {
      const request = new XMLHttpRequest();
      request.open('POST', `/${getTenantIdentifier()}/api/Branding/BrandingImages`);
      return request;
    };

    queueUpload({
      fileName: 'brandingFiles',
      formData,
      onSuccess: onSuccess,
      onError: onError,
      openRequest
    });
  }, [queueUpload]);


  return {
    upload: uploads.filter(p => p.batchId === brandingUploadBatchId).at(0),
    queueFiles,
    cancelBrandingImagesUpload
  };
};