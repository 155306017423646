import { Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, Radio, RadioGroup } from '@mui/material';
import { useValidationRules } from '@utils/useValidationRules';
import React from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { FormFillerValues } from '../types';
import { FieldFillerProps } from './FieldFiller';
import { useFieldPresenceUpdateEvents } from './useFieldPresenceUpdateEvents';
import { useFormFieldFillerForm } from './useFormFieldFillerForm';

export const SelectionFiller: React.FC<FieldFillerProps> = ({ index, field: formField, disabled }) => {
  const { notEmpty } = useValidationRules();

  const options = formField.options ?? [];

  const { control, getValues } = useFormContext<FormFillerValues>();

  const selectedOptions = useWatch({ control, name: `values.${index}.selectedOptions` }) ?? [];

  const updatePresenceEvents = useFieldPresenceUpdateEvents(formField.id);
  const { onSubmit } = useFormFieldFillerForm();

  return (
    <Controller
      key={formField.id}
      control={control}
      name={`values.${index}.selectedOptions`}
      rules={{
        validate: {
          ...formField.isRequired && { notEmpty },
        }
      }}
      render={({ field: { onChange }, fieldState: { error } }) => (
        <FormControl {...updatePresenceEvents} error={!!error}>
          <FormLabel>{formField.name}</FormLabel>
          {formField.isMultiselect ? (
            <FormGroup>
              {options.map(option => (
                <FormControlLabel
                  key={option.id}
                  value={option.id}
                  label={option.label}
                  control={(
                    <Checkbox
                      disabled={disabled}
                      checked={selectedOptions.includes(option.id)}
                      onChange={(_, checked) => {
                        let newValue = [...selectedOptions];
                        if (checked) {
                          newValue.push(option.id);
                        } else {
                          newValue = selectedOptions.filter(id => id !== option.id);
                        }
                        onChange(newValue);
                        setTimeout(() => onSubmit({ ...getValues(`values.${index}`), selectedOptions: newValue }), 1000);
                      }}
                    />
                  )}
                />
              ))}
            </FormGroup>
          ) : (
            <RadioGroup value={selectedOptions[0] ?? ''} onChange={(_, value) => {
              if (!Number(value)) return;
              onChange([Number(value)]);
              setTimeout(() => onSubmit({ ...getValues(`values.${index}`), selectedOptions: [Number(value)] }), 1000);
            }}>
              {options.map(option => (
                <FormControlLabel disabled={disabled} key={option.id} value={option.id.toString()} control={<Radio />} label={option.label} />
              ))}
            </RadioGroup>
          )}
          <FormHelperText error={!!error}>{error?.message}</FormHelperText>
        </FormControl>
      )}
    />
  );
};