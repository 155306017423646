import { ListItemButton, ListItemButtonProps, ListItemIcon, ListItemText, alpha, styled } from '@mui/material';
import React from 'react';

const StyledListItemButton = styled(ListItemButton)<ListItemButtonProps>(({ theme }) => ({
  color: theme.palette.text.secondary,
  'svg': {
    color: theme.palette.text.secondary
  },
  '&:hover': {
    background: alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
  },
  '.active &, .active &:hover': {
    background: alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
    borderRight: `4px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
    'svg': {
      color: theme.palette.primary.main
    },
  },
  '.collapsed & .MuiListItemText-primary': {
    visibility: 'hidden',
  },
  '& .MuiListItemIcon-root': {
    minWidth: '40px'
  }
}));

interface OwnProps {
  icon?: React.ReactNode;
  title?: string;
  children?: React.ReactNode;
  collapsed?: boolean;
}

export type ProjectNavigationMenuItemProps =
  & ListItemButtonProps
  & OwnProps;

export const ProjectNavigationMenuItem: React.FC<ProjectNavigationMenuItemProps> = (props) => {
  const { icon, title, children, collapsed, ...listItemProps } = props;

  return (
    <StyledListItemButton color="primary" {...listItemProps}>
      {icon && <ListItemIcon>{icon}</ListItemIcon>}
      <ListItemText
        primary={title}
        title={title}
        primaryTypographyProps={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
        sx={{ visibility: collapsed ? 'hidden' : 'visible' }}
      />
      {children}
    </StyledListItemButton>
  );
};