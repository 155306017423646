import { WorkflowActionConfigurationFragmentFragment } from 'gql/index';
import { createEvent } from 'react-event-hook';
import { WorkflowEditorValues } from './types';


export interface ChildActionAddedEvent {
  workflow: WorkflowEditorValues,
  createdAction: WorkflowActionConfigurationFragmentFragment;
}

export interface ChildActionAddRequestedEvent {
  parentActionConfigurationId: number;
  destinationId: string;
  createdAtIndex: number;
  actionDefinitionId: string;
}

export const { emitChildActionAddRequested, useChildActionAddRequestedListener } = createEvent('childActionAddRequested')<ChildActionAddRequestedEvent>();

interface ChildActionMovedEvent {
  parentActionConfigurationId: number;
  sourceIndex: number;
  destinationIndex: number;
  movedActionConfigurationId: number;
}

export const { emitChildActionMoved, useChildActionMovedListener } = createEvent('childActionMoved')<ChildActionMovedEvent>();
