import { WorkflowActionStatus, WorkflowVariableType, WorkflowVariableValueTypes } from 'gql/index';

export interface ReactHookArrayElement {
  uniqueId: string;
}

export interface WorkflowEditorValues {
  id: number;
  name: string;
  description: string;
  actions: WorkflowEditorActionValue[];
  variables: WorkflowVariableFormValue[];
}

export interface WorkflowVariableFormValue {
  id: number | '',
  name: string,
  value?: unknown | '',
  variableType: WorkflowVariableType,
  variableValueType: WorkflowVariableValueTypes
}

export interface WorkflowEditorActionValue {
  id: number;
  workflowActionDefinitionId: string;
  name: string;
  fieldValues: WorkflowEditorActionFieldValue[];
  order: number;
  status: WorkflowActionStatus;
}

export type WorkflowEditorActionFieldValue = {
  fieldId: string,
  value: WorkflowVariableFormValue | undefined,
};

export const WorkflowEditorDroppableTypes = {
  step: 'step'
};

export const WorkflowEditorDroppableIds = {
  actionsSelectionPanel: 'actionsSelectionPanel',
  steps: 'steps'
};