import { InfoOutlined } from '@mui/icons-material';
import { Stack, Tooltip, Typography } from '@mui/material';
import React from 'react';

interface Props {
  isRequired?: boolean;
  name: string;
  description?: string | null;
}

export const MatrixColumnHeader: React.FC<Props> = (column) => {
  return (
    <Stack direction='row' alignItems='center' gap={1}>
      <Typography variant='subtitle2'>
        {column.isRequired ? column.name + ' *' : column.name}
      </Typography>

      {column.description?.trim() && (
        <Tooltip title={column.description} arrow disableInteractive>
          <InfoOutlined fontSize='inherit' />
        </Tooltip>
      )}
    </Stack>
  );
};