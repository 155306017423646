import { PageSection } from '@components/PageSection/PageSection';
import { PageSectionSkeleton } from '@components/Skeletons/PageSectionSkeleton';
import { UnstyledLink } from '@components/UnstyledLink';
import { useCurrentProject } from '@modules/projects/utils/useCurrentProject';
import { CalendarMonth, TaskAlt } from '@mui/icons-material';
import { List, ListItemButton, ListItemText } from '@mui/material';
import { getTenantIdentifier } from '@utils/getTenantIdentifier';
import { useGetMe } from '@utils/useGetMe';
import dayjs from 'dayjs';
import { useMeetingsQuery, useMeetingTypesQuery } from 'gql/index';
import { useIntl } from 'react-intl';
import { ProjectSectionListItem } from './ProjectSectionList';

const today = dayjs();

export const MeetingsSection: React.FC = () => {
  const { formatMessage, formatDate } = useIntl();

  const { me } = useGetMe();
  const { projectId } = useCurrentProject();

  const { data: meetingTypes } = useMeetingTypesQuery({ projectId }, { select: d => d.meetingTypes });

  const { data: meetings } = useMeetingsQuery({
    projectId,
    filter: {
      or: [
        { createdById: { eq: me?.id } },
        {
          participants: {
            some: { id: { eq: me?.id } }
          }
        }
      ],
      startTime: {
        gte: today.toISOString()
      }
    }
  }, { select: d => d.meetings });

  if (!meetingTypes?.length && (meetings?.length == null || meetings.length == 0)) {
    return null;
  }

  if (!meetings) {
    return (
      <PageSectionSkeleton icon={<TaskAlt />} title={formatMessage({ id: 'Meetings' })} />
    );
  }

  return <>
    <PageSection
      title={formatMessage({ id: 'Upcoming meetings' })}
      icon={<CalendarMonth />}
      notificationNumber={meetings.length}
      emptyState={formatMessage({ id: 'There are no upcoming meetings.' })}
    >
      {meetings.length > 0 && (
        <List disablePadding sx={{ borderTop: t => `1px solid ${t.palette.divider}`, borderBottom: t => `1px solid ${t.palette.divider}` }}>
          {meetings.map(meeting => (
            <UnstyledLink key={meeting.id} to={`/${getTenantIdentifier()}/projects/${projectId}/meetings/requests/${meeting.id}`}>
              <ProjectSectionListItem disablePadding>
                <ListItemButton>
                  <ListItemText
                    primary={meeting.name}
                    secondary={`${formatDate(dayjs(meeting.startTime).toDate(), { timeStyle: 'short', dateStyle: 'short' })} • ${dayjs.duration(meeting.meetingType?.duration).humanize()}`}
                  />
                </ListItemButton>
              </ProjectSectionListItem>
            </UnstyledLink>
          ))}
        </List>
      )}
    </PageSection>
  </>;
};