import { SwipeDownOutlined } from '@mui/icons-material';
import { Stack, Typography } from '@mui/material';

interface OwnProps {
  placeholderText: string
  visibility?: boolean
}
export const DroppableEmptyState: React.FC<OwnProps> = ({ placeholderText, visibility }) => {
  return (
    <Stack direction='row' py={4} alignItems={'center'} spacing={1} justifyContent='center' visibility={visibility ? 'hidden' : 'visible'}>
      <SwipeDownOutlined sx={{ color: t => t.palette.text.secondary }} />
      <Typography variant='subtitle1' style={{ userSelect: 'none' }}>{placeholderText}</Typography>
    </Stack>
  );
};