import { InfoOutlined } from '@mui/icons-material';
import { Stack, Tooltip, Typography } from '@mui/material';
import { useGetMe } from '@utils/useGetMe';
import { useResponsive } from '@utils/useResponsive';
import { SubscriptionStatus, useTenantSubscriptionDetailsQuery } from 'gql/index';
import { useIntl } from 'react-intl';

export const TrialInfo = () => {
  const { data: subscriptionDetails } = useTenantSubscriptionDetailsQuery(undefined, { select: d => d.tenantSubscriptionDetails });
  const { isMobile } = useResponsive();
  const status = subscriptionDetails?.subscription?.status;

  const trialDaysLeft = subscriptionDetails?.trialDaysRemaining;
  const { hasTenantAdminRole } = useGetMe();
  const { formatMessage } = useIntl();

  if (status === SubscriptionStatus.InTrial && trialDaysLeft && hasTenantAdminRole && !isMobile) {
    return <Stack direction='row' flex={1} spacing={1} justifyContent='center' alignItems='center'>
      <InfoOutlined />
      <Typography>
        {formatMessage({ id: 'You are currently in trial mode and have {days} days left.' }, { days: Math.max(0, trialDaysLeft) })}
      </Typography>
    </Stack>;
  }
  else if (status === SubscriptionStatus.InTrial && trialDaysLeft && hasTenantAdminRole && isMobile) {
    return <Stack direction='row' flex={1} spacing={1} justifyContent='center' alignItems='center'>
      <Tooltip enterTouchDelay={0} title={formatMessage({ id: 'You are currently in trial mode and have {days} days left.' }, { days: Math.max(0, trialDaysLeft) })}>
        <Typography variant='overline' color='text.secondary' sx={{ userSelect: 'none' }}>
          {formatMessage({ id: 'Trial' })}
        </Typography>
      </Tooltip>
    </Stack>;
  }
  else {
    return undefined;
  }
};