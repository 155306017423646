import { DataTable } from '@components/DataTable';
import { Delete } from '@mui/icons-material';
import { Box, Stack, Typography, useTheme } from '@mui/material';
import { GridActionsCellItem, GridColDef, useGridApiRef } from '@mui/x-data-grid-pro';
import { useMoneyFormatter } from '@utils/numberUtils';
import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { AdditionalRevenue, CommercialConstructionCalculatorData } from '../../types';
import { AdditionalRevenuesGridToolbar } from './AdditionalRevenuesGridToolbar';

type Props = {
  onChange: () => void;
};

export const AdditionalRevenuesTable: React.FC<Props> = ({ onChange }) => {
  const apiGridRef = useGridApiRef();
  const { control } = useFormContext<CommercialConstructionCalculatorData>();

  const { fields, update, append, remove } = useFieldArray({ control: control, name: 'revenues.additionalRevenue', keyName: 'uid' });
  const { formatMessage } = useIntl();
  const theme = useTheme();
  const formatMoney = useMoneyFormatter();

  const columns: GridColDef<AdditionalRevenue>[] = [
    {
      field: 'name',
      headerName: formatMessage({ id: 'Name' }),
      type: 'string',
      editable: true,
      cellClassName: 'editable',
      flex: 1,
      minWidth: 200
    },
    {
      field: 'annualCost',
      headerName: formatMessage({ id: 'Annual revenue' }),
      type: 'number',
      width: 200,
      editable: true,
      cellClassName: 'editable',
      valueFormatter: ({ value }) => isNaN(value) ? '' : formatMoney(value)
    },

    {
      field: 'actions',
      type: 'actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => [
        <GridActionsCellItem
          icon={<Delete color='action' />}
          label={formatMessage({ id: 'Delete' })}

          onClick={() => {
            const fieldIndex = fields.findIndex(p => p.id === id);
            if (fieldIndex >= 0) {
              remove(fieldIndex);
              onChange();
            }
          }}
          color="inherit"
        />,
      ]
    }
  ];

  return <Stack sx={{ pt: 4 }} gap={2}>
    <Typography variant='h5'>{formatMessage({ id: 'Additional revenues' })}</Typography>
    <Box
      maxWidth={600}
      sx={{
        '& .editable': {
          fontWeight: 600,
        }
      }}>
      <DataTable<AdditionalRevenue>
        variant='minimal'
        apiRef={apiGridRef}
        columns={columns}
        rows={fields}
        getRowId={row => row.id}
        hideFooter
        disableColumnMenu
        disableColumnPinning
        disableColumnResize
        disableColumnReorder
        slots={{
          toolbar: AdditionalRevenuesGridToolbar,
        }}
        slotProps={{
          toolbar: {
            onAddRow: () => {
              const rowId = crypto.randomUUID();
              append({ id: rowId, name: '', annualCost: 0 });
              onChange();
            }
          }
        }}
        autoHeight
        localeText={{
          ...theme.components?.MuiDataGrid?.defaultProps?.localeText,
          noRowsLabel: formatMessage({ id: 'No revenues entered' }),
        }}
        processRowUpdate={(newRow, oldRow) => {
          const rowIndex = fields.findIndex(p => p.id === oldRow.id);

          if (rowIndex >= 0) {
            update(rowIndex, newRow);
          }

          onChange();
          return newRow;
        }}
        onCellClick={(params) => params.isEditable && apiGridRef.current.startCellEditMode({ field: params.field, id: params.id })}
      />
    </Box>
  </Stack>;
};