import { AppBar, Toolbar, styled } from '@mui/material';
import { ReactNode } from 'react';

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  zIndex: theme.zIndex.drawer + 1,
  boxShadow: 'none',
  borderBottomStyle: 'solid',
  borderBottomWidth: '1px',
  borderBottomColor: theme.palette.divider
}));

interface TopBarBaseProps {
  children: ReactNode
}

export const TopBarBase = (props: TopBarBaseProps) => {
  return <StyledAppBar
    color="default"
    position="sticky"
    elevation={1}
  >
    <Toolbar disableGutters sx={{ paddingX: 2.5, height: '100%' }}>
      {props.children}
    </Toolbar>
  </StyledAppBar>;
};
