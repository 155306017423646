import { Check, LoopOutlined, Schedule } from '@mui/icons-material';
import { WorkflowActionStatus } from 'gql/index';

export const WorkflowActionStatusChip = (props: { actionStatus: WorkflowActionStatus }) => {
  return (
    <>
      {props.actionStatus === WorkflowActionStatus.Completed ?
        <Check color='success' />
        : props.actionStatus === WorkflowActionStatus.InProgress ?
          <LoopOutlined color='info' />
          : <Schedule color='warning' />
      }
    </>
  );
};