import { useContextSafe } from '@utils/useContextSafe';
import { useDebounce } from '@utils/useDebounce';
import { useCallback, useContext } from 'react';
import { useUpdateFormPresenceMutation, useUpdateFormPresencePatMutation } from '../../../../../gql';
import { uniqueClientId } from '../../../../application/apollo-client';
import { AnonymousContext } from '../../../../projectAccessTokens/AnonymousContextProvider';
import { FormFillerContext } from '../FormFillerContextProvider';

export const useFieldPresenceUpdateEvents = (fieldId: number) => {
  const { accessToken } = useContext(AnonymousContext);
  const { submissionId } = useContextSafe(FormFillerContext);

  const { mutate: updatePresence } = useUpdateFormPresenceMutation({ onError: () => {/* prevent default error handling */ } });
  const { mutate: updatePresencePAT } = useUpdateFormPresencePatMutation({ onError: () => {/* prevent default error handling */ } });

  const updatePresenceDebounced = useDebounce(updatePresence, 1000);
  const updatePresencePATDebounced = useDebounce(updatePresencePAT, 1000);

  const onFocus = useCallback(() => {
    if (accessToken) {
      updatePresencePATDebounced({ input: { accessToken, submissionId, uniqueClientId, fieldId } });
    } else {
      updatePresenceDebounced({ input: { uniqueClientId, submissionId, fieldId } });
    }
  }, [accessToken, fieldId, submissionId, updatePresenceDebounced, updatePresencePATDebounced]);

  const onBlur = useCallback(() => {
    if (accessToken) {
      updatePresencePATDebounced({ input: { accessToken, submissionId, uniqueClientId, fieldId: undefined } });
    } else {
      updatePresenceDebounced({ input: { uniqueClientId, submissionId, fieldId: undefined } });
    }
  }, [accessToken, submissionId, updatePresenceDebounced, updatePresencePATDebounced]);

  return { onFocus, onBlur };
};