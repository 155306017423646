import { Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, Radio, RadioGroup } from '@mui/material';
import { useFormContext, useWatch } from 'react-hook-form';
import { FormFillerValues } from '../../FormFiller/types';
import { FieldViewerProps } from './FieldViewer';

export const SelectionViewer: React.FC<FieldViewerProps> = ({ field, index }) => {
  const options = field.options;

  const { control } = useFormContext<FormFillerValues>();

  const selectedOptionIds = useWatch({ control, name: `values.${index}.selectedOptions` });

  return (
    <FormControl disabled>
      <FormLabel>{field.name}</FormLabel>
      {field.isMultiselect ? (
        <FormGroup>
          {options.map(option => (
            <FormControlLabel
              key={option.id}
              value={option.id}
              label={option.label}
              control={(
                <Checkbox checked={selectedOptionIds?.includes(option.id)} />
              )}
            />
          ))}
        </FormGroup>
      ) : (
        <RadioGroup value={selectedOptionIds?.[0] ?? ''}>
          {options.map(option => (
            <FormControlLabel key={option.id} value={option.id.toString()} control={<Radio />} label={option.label} />
          ))}
        </RadioGroup>
      )}
    </FormControl>
  );
};