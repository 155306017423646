import { useIntl } from 'react-intl';
import { ActionConfigurationComponentProps } from '../../WorkflowEditor/Actions/types';
import { WorkflowFieldInput } from '../../WorkflowEditor/Fields/WorkflowFieldInput';
import { TaskActionDefinition } from './TaskActionDefinition';

export const TaskActionConfiguration: React.FC<ActionConfigurationComponentProps> = () => {
  const { formatMessage } = useIntl();

  return (
    <>
      <WorkflowFieldInput
        fieldId={TaskActionDefinition.FieldId_TaskDescription}
        label={formatMessage({ id: 'Description' })}
        textFieldProps={{ multiline: true, rows: 3 }}
      />
      <WorkflowFieldInput fieldId={TaskActionDefinition.FieldId_Assignee} label={formatMessage({ id: 'Assigned to' })} />
    </>
  );
};