import { ReactComponent as CentralDark } from '@assets/images/Via/CentralDark.svg';
import { ReactComponent as CentralLight } from '@assets/images/Via/CentralLight.svg';
import { ReactComponent as ViaDark } from '@assets/images/Via/ViaDark.svg';
import { ReactComponent as ViaLight } from '@assets/images/Via/ViaLight.svg';
import { TenantThemeContext } from '@modules/tenants/components/TenantThemeContextProvider';
import { Stack, useTheme } from '@mui/material';
import { useContext } from 'react';

export const TopBarAppLogo = () => {

  const { palette: { mode } } = useTheme();
  const isDark = mode === 'dark';

  const ViaIcon = isDark ? ViaLight : ViaDark;

  const CentralIcon = isDark ? CentralLight : CentralDark;
  const tenantContext = useContext(TenantThemeContext);

  return (
    <Stack direction='row' width='100%' alignItems='center' spacing={{ xs: 0.5, md: 1 }}>
      <Stack direction='row'>
        {tenantContext.isBrandingEnabled && tenantContext.topBarLogoUrl && <img src={tenantContext.topBarLogoUrl} height={'42px'} width={'auto'} />}
        {!tenantContext.isBrandingEnabled && <>
          <ViaIcon height={'32px'} width='auto' />
          <CentralIcon height={'24px'} width='auto' />
        </>
        }
      </Stack>
    </Stack>
  );
};