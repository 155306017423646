import { PageSection } from '@components/PageSection/PageSection';
import { ResponsiveButton } from '@components/ResponsiveButton';
import { useCurrentProject } from '@modules/projects/utils/useCurrentProject';
import { Assessment, Edit } from '@mui/icons-material';
import { Paper, Stack } from '@mui/material';
import { useGetMe } from '@utils/useGetMe';
import { useGetProjectBudgetProgressQuery } from 'gql/index';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { EditProgressDrawer } from '../ProgressWidget/EditProgressDrawer';
import { ProgressWithTitle } from '../ProgressWidget/ProgressWithTitle';

export const ProgressWidget: React.FC = () => {
  const { formatMessage } = useIntl();
  const [isOpenEditDrawer, setOpenEditDrawer] = useState(false);
  const { isExternalUser } = useGetMe();
  const { projectId, projectProgress, canViewBudgetAndExpenses, canManageProject, hideBudgetFeature } = useCurrentProject();
  const { data: budget } = useGetProjectBudgetProgressQuery({ id: projectId }, { select: d => d.projectBudgetProgress });

  const usedBudget = budget?.totalBudgetedAmount ? budget?.totalExpendedAmount / (budget?.budgetOverrun + budget?.totalBudgetedAmount) * 100 : 0;

  if (isExternalUser && projectProgress == 0 && usedBudget == 0)
    return <></>;

  return (
    <>
      <PageSection
        icon={<Assessment />}
        title={formatMessage({ id: 'Progress' })}
        actionButton={
          canManageProject && <ResponsiveButton size='small' color='primary' variant='contained' icon={<Edit />} onClick={() => setOpenEditDrawer(true)}>
            {formatMessage({ id: 'Edit' })}
          </ResponsiveButton>
        }
      >
        <Paper elevation={0} sx={{ p: 2, border: t => `1px solid ${t.palette.divider}` }}>
          <Stack direction='row' gap={2} justifyContent='center'>
            <ProgressWithTitle title={formatMessage({ id: 'Project' })} value={projectProgress} />
            {(hideBudgetFeature != null && !hideBudgetFeature && canViewBudgetAndExpenses) &&
              <ProgressWithTitle title={formatMessage({ id: 'Budget' })} value={usedBudget} />
            }
          </Stack>
        </Paper>
      </PageSection >
      <EditProgressDrawer
        progressValue={projectProgress?.toString()}
        open={isOpenEditDrawer}
        onClose={() => setOpenEditDrawer(false)}
      />
    </>
  );
};