import { CircularProgress, Stack } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useCommercialProjectCalculatorQuery } from '../../../../../gql';
import { useCurrentProject } from '../../../../projects/utils/useCurrentProject';
import { CommercialConstructionCalculatorData, useDefaultCalculatorValues } from '../types';
import { CommercialConstructionCalculatorForm } from './CommercialConstructionCalculatorForm';

export const CommercialConstructionCalculator = () => {
  const { projectId } = useCurrentProject();
  const defaultValues = useDefaultCalculatorValues();
  const { id } = useParams();

  const { data, isFetching: isFetchingCalculator } = useCommercialProjectCalculatorQuery({ calculatorId: Number(id), projectId: projectId }, {
    select: p => p.commercialProjectCalculator == null
      ? defaultValues
      : {
        ...JSON.parse(p.commercialProjectCalculator?.jsonData) as CommercialConstructionCalculatorData,
        name: p.commercialProjectCalculator.name
      }
  });


  if (isFetchingCalculator) {
    return <Stack width={'100%'} height={'100%'} justifyContent={'center'} alignItems={'center'}><CircularProgress size={100} /></Stack>;
  }

  return (data && <CommercialConstructionCalculatorForm defaultValues={data} />);

};