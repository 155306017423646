import { ForkRight, SettingsSuggest } from '@mui/icons-material';
import { Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Toolbar, useTheme } from '@mui/material';
import { useIntl } from 'react-intl';
import { FieldSelectionPanel } from '../FieldSelectionPanel/FieldSelectionPanel';


export const formSideMenuWidth = 80;


export type FormMenuItem = 'fields' | 'logic';

type Props = {
  selectedItem: FormMenuItem;
  onChange: (item: FormMenuItem) => void;
};

export const FormSideMenu: React.FC<Props> = ({ selectedItem, onChange }) => {
  const { formatMessage } = useIntl();
  const theme = useTheme();
  return (
    <>
      <Drawer sx={{
        width: formSideMenuWidth,
        flexShrink: 0,
        zIndex: theme => theme.zIndex.appBar - 1,
        '& .MuiDrawer-paper': {
          transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
          }),
          width: formSideMenuWidth,
          boxSizing: 'border-box',
        },
      }} open={true} variant='permanent'
      >
        <Toolbar />
        <Toolbar />

        {location.pathname.split('/').indexOf('form') > -1 && <Toolbar />}

        <List>
          <ListItem key={'fields'} disablePadding sx={{ display: 'block' }}>
            <ListItemButton
              selected={selectedItem === 'fields'}
              onClick={() => onChange('fields')}
              sx={{
                minHeight: 48,
                justifyContent: 'center',
                alignItems: 'center',
                px: 2.5,
                flexDirection: 'column'
              }}
            >
              <ListItemIcon
                sx={{
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <SettingsSuggest />
              </ListItemIcon>
              <ListItemText primary={formatMessage({ id: 'Fields' })} />
            </ListItemButton>
          </ListItem>
          <ListItem key={'logic'} disablePadding sx={{ display: 'block' }}>
            <ListItemButton
              selected={selectedItem === 'logic'}
              onClick={() => onChange('logic')}
              sx={{
                minHeight: 48,
                justifyContent: 'center',
                alignItems: 'center',
                px: 2.5,
                flexDirection: 'column'
              }}
            >
              <ListItemIcon
                sx={{
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <ForkRight />
              </ListItemIcon>
              <ListItemText primary={formatMessage({ id: 'Logic' })} />
            </ListItemButton>
          </ListItem>
        </List>
      </Drawer>

      {selectedItem === 'fields' && <FieldSelectionPanel open={true} />}

    </>
  );
};