import UploadFileIcon from '@mui/icons-material/UploadFile';
import { Stack, Typography, useTheme } from '@mui/material';
import { useIntl } from 'react-intl';


export const FormUploadFileInputSample = () => {
  const { formatMessage } = useIntl();
  const { palette } = useTheme();

  return (
    <div>
      <Stack sx={{
        px: 2,
        py: 8,
        borderStyle: 'dashed',
        borderColor: palette.grey[300],
        justifyContent: 'center',
        alignItems: 'center',
        gap: 2
      }}>
        <UploadFileIcon color='primary' />
        <Stack alignItems='center' gap={1}>
          <Typography variant='body1'>{formatMessage({ id: 'Once the form is published, assigned users can click this zone or drag and drop files.' })}</Typography>
          <Typography variant='body1' color={palette.grey[600]}>{formatMessage({ id: '(10MB max)' })}</Typography>
        </Stack>
      </Stack>
    </div>
  );
};