import { useCurrentProject } from '@modules/projects/utils/useCurrentProject';
import { Autocomplete, AutocompleteProps, TextField } from '@mui/material';
import { useGetProjectsQuery } from 'gql/index';
import { FieldError } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { forwardRef } from 'react';

interface ValueProps {
  id: number,
  label?: string
}

type OwnProps = Omit<AutocompleteProps<ValueProps, undefined, undefined, undefined>, 'options' | 'renderInput'> & {
  fieldValue: number | null,
  error?: FieldError
};

export const ImportProjectTextField = forwardRef<HTMLDivElement, OwnProps>((props, ref) => {
  const { fieldValue, error, ...autocompleteProps } = props;
  const { formatMessage } = useIntl();

  const { projectId } = useCurrentProject();
  const { data: projects } = useGetProjectsQuery(undefined, { select: d => d.projects });

  const options = projects?.filter(p => p.id !== projectId).sort().map(p => ({ label: p.name, id: p.id }));
  return (
    <Autocomplete
      {...autocompleteProps}
      ref={ref}
      options={options ?? []}
      value={fieldValue != null ? { label: options?.find(o => o.id == fieldValue)?.label, id: fieldValue } : null}
      renderInput={(params) =>
        <TextField
          {...params}
          label={formatMessage({ id: 'Project' })}
          required
          error={!!error}
          helperText={error?.message}
          sx={{ maxWidth: '400px' }}
        />
      }
    />
  );
});