import { Paper, Stack } from '@mui/material';
import { ExternalLinkFragmentFragment } from 'gql/index';
import React from 'react';
import { useCurrentProject } from '../../../../projects/utils/useCurrentProject';
import { ExternalLinkBasicProperties } from './ExternalLinkBasicProperties';
import { ExternalLinkPermissions } from './ExternalLinkPermissions';

interface Props {
  externalLink: ExternalLinkFragmentFragment;
}

export const ExternalLinkPropertiesList: React.FC<Props> = ({ externalLink }) => {
  const { canManageDocuments } = useCurrentProject();

  if (!externalLink) return;

  return (
    <Stack p={1} gap={2}>
      <Paper sx={{ p: 2 }}>
        <ExternalLinkBasicProperties externalLink={externalLink} />
      </Paper>

      {canManageDocuments && (
        <ExternalLinkPermissions externalLink={externalLink} />
      )}
    </Stack>
  );
};