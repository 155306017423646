import { useDebounce } from '@utils/useDebounce';
import { useCallback, useContext, useEffect, useRef } from 'react';
import { ProjectFormPresence, useFormFillerPresencePatQuery, useFormFillerPresenceQuery, useRemoveFormPresenceMutation, useRemoveFormPresencePatMutation, useUpdateFormPresenceMutation, useUpdateFormPresencePatMutation } from '../../../../gql';
import { useFormPresenceUpdatedSubscription } from '../../../../gql/subscriptions';
import { uniqueClientId } from '../../../application/apollo-client';
import { AnonymousContext } from '../../../projectAccessTokens/AnonymousContextProvider';

type Presence = Pick<ProjectFormPresence, 'userId' | 'displayName' | 'fieldId' | 'matrixColumnId' | 'matrixRowId'>;

export const useFormFillerPresence = (submissionId: number) => {
  const { accessToken } = useContext(AnonymousContext);

  const { mutate: removePresence } = useRemoveFormPresenceMutation({ onError: () => {/* prevent default error handling */ } });
  const { mutate: removePresencePAT } = useRemoveFormPresencePatMutation({ onError: () => {/* prevent default error handling */ } });

  const { mutate: updatePresence } = useUpdateFormPresenceMutation({ onError: () => {/* prevent default error handling */ } });
  const { mutate: updatePresencePAT } = useUpdateFormPresencePatMutation({ onError: () => {/* prevent default error handling */ } });

  const updatePresenceDebounced = useDebounce(updatePresence, 1000);
  const updatePresencePATDebounced = useDebounce(updatePresencePAT, 1000);

  const presences = useRef<Presence[]>([]);

  const { data: presencesData } = useFormFillerPresenceQuery({ formSubmissionId: submissionId }, {
    enabled: accessToken == null,
    staleTime: 1000 * 60 * 5
  });
  const { data: presencesPATData } = useFormFillerPresencePatQuery({ accessToken: accessToken ?? '' }, {
    enabled: accessToken != null,
    staleTime: 1000 * 60 * 5
  });

  useFormPresenceUpdatedSubscription({
    variables: { submissionId, accessToken: accessToken },
    onData: dataEvent => {
      presences.current = (dataEvent.data.data?.formPresenceUpdated ?? [])
        .filter(p => p.uniqueClientId != uniqueClientId);
    }
  });

  const updateFieldPresence = useCallback((fieldId?: number, matrixRowId?: number, matrixColumnId?: number) => {
    if (!accessToken) {
      updatePresenceDebounced({
        input: { submissionId, uniqueClientId, fieldId, matrixRowId, matrixColumnId }
      });
    } else {
      updatePresencePATDebounced({
        input: { accessToken, uniqueClientId, submissionId, fieldId, matrixRowId, matrixColumnId }
      });
    }
  }, [accessToken, submissionId, updatePresenceDebounced, updatePresencePATDebounced]);

  const removeFormPresence = useCallback(() => {
    if (!accessToken) {

      removePresence({
        input: { uniqueClientId, submissionId }
      });
    } else {
      removePresencePAT({
        input: { accessToken, uniqueClientId, submissionId }
      });
    }
  }, [accessToken, removePresence, removePresencePAT, submissionId]);


  const addFormPresence = useCallback(() => {
    if (!accessToken) {
      updatePresence({
        input: { uniqueClientId, submissionId, fieldId: undefined }
      });
    } else {
      updatePresencePAT({
        input: { accessToken, submissionId, uniqueClientId, fieldId: undefined }
      });
    }
  }, [accessToken, submissionId, updatePresence, updatePresencePAT]);

  useEffect(() => {
    presences.current = ((accessToken == null ? presencesData?.formPresences : presencesPATData?.formPresencesPAT) ?? [])
      .filter(p => p.uniqueClientId != uniqueClientId);
  }, [accessToken, presencesData, presencesPATData]);

  return { presences: presences.current ?? [], removeFormPresence, addFormPresence, updateFieldPresence };
};