import { InlineEditPaper } from '@components/InlineEditPaper';
import { Stack, TextField, Typography } from '@mui/material';
import { useValidationRules } from '@utils/useValidationRules';
import { useEffect } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';

interface OwnProps {
  defaultOpen?: boolean;
  isLoading?: boolean;
  values: EditorTitleValues;
  onSubmit: (values: EditorTitleValues) => void;
  disabled?: boolean;
}

export interface EditorTitleValues {
  name: string;
  description: string;
}


export const EditorTitleSection: React.FC<OwnProps> = ({ values, defaultOpen, isLoading, onSubmit, disabled }) => {
  const { formatMessage } = useIntl();
  const { notEmpty } = useValidationRules();

  const { reset, register, control, formState: { errors: { name: nameErrors } } } = useForm<EditorTitleValues>();

  const name = useWatch({ control, name: 'name' });
  const description = useWatch({ control, name: 'description' });

  const nameField = register('name', { validate: notEmpty });

  const onTitleSectionInactivated = () => {
    if (!name.trim()) return;

    onSubmit({ name, description });
  };

  useEffect(() => {
    reset(values);
  }, [reset, values]);

  const active = (
    <Stack p={2} gap={2}>
      <TextField
        {...nameField}
        required
        fullWidth
        autoFocus
        error={Boolean(nameErrors)}
        helperText={nameErrors?.message}
        label={formatMessage({ id: 'Title' })}
        sx={{ '& input': { fontSize: t => t.typography.h4.fontSize } }}
        disabled={isLoading}
        onKeyDown={e => {
          if (e.key === 'Enter') {
            e.preventDefault();
            onTitleSectionInactivated();
          }
        }}
      />

      <Controller
        name='description'
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            onChange={e => field.onChange(e.target.value)}
            fullWidth
            size='small'
            label={formatMessage({ id: 'Description' })}
            sx={{ '& input': { color: 'text.secondary' } }}
            disabled={isLoading}
          />
        )}
      />
    </Stack>
  );

  const inactive = (
    <Stack p={2}>
      <Typography variant='h4' fontStyle={name || 'italic'}>
        {name || formatMessage({ id: 'No title inputted' })}
      </Typography>

      <Typography fontSize={16} color='text.secondary'>
        {description}
      </Typography>
    </Stack>
  );
  return (
    <InlineEditPaper
      defaultOpen={defaultOpen}
      disabled={isLoading || disabled}
      error={Boolean(nameErrors?.message)}
      onInactivate={onTitleSectionInactivated}
    >
      {isActive => isActive ? active : inactive}
    </InlineEditPaper>
  );
};

