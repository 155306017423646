import { Property } from '@components/DataDisplay/Property';
import { PropertyGroup } from '@components/DataDisplay/PropertyGroup';
import { TextProperty } from '@components/DataDisplay/TextProperty';
import { getFileExtension } from '@components/Icons/FileIcon';
import { UserAvatar } from '@components/UserAvatar/UserAvatar';
import { Chip, Stack } from '@mui/material';
import dayjs from 'dayjs';
import { ApplicationUser, Document } from 'gql/index';
import React from 'react';
import { useIntl } from 'react-intl';

interface Props {
  document: Pick<Document, 'fileName' | 'createdOn'> & {
    createdBy?: Pick<ApplicationUser, 'fullName'> | null | undefined;
  };
}

export const DocumentBasicProperties: React.FC<Props> = ({ document }) => {
  const { formatMessage } = useIntl();

  return (
    <PropertyGroup title={formatMessage({ id: 'Properties' })}>

      <TextProperty propertyName={formatMessage({ id: 'Name' })} propertyValue={document.fileName} />

      <TextProperty propertyName={formatMessage({ id: 'File type' })} propertyValue={getFileExtension(document.fileName)} />

      <Property propertyName={formatMessage({ id: 'Created by' })}>
        <Stack direction='row'>
          {document.createdBy && (
            <Chip
              avatar={<UserAvatar displayName={document.createdBy.fullName} />}
              label={document.createdBy.fullName}
            />
          )}
        </Stack>
      </Property>

      <TextProperty propertyName={formatMessage({ id: 'Created on' })} propertyValue={dayjs(document.createdOn).format('LL')} />
    </PropertyGroup>
  );
};