import { Stack, Typography } from '@mui/material';

import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import { useIntl } from 'react-intl';

export const AccessTokenRenewed = (props: { emailSentTo: string }) => {
  const { formatMessage } = useIntl();

  return <Stack alignItems='center' justifyContent='center' p={3} spacing={3}>
    <ForwardToInboxIcon color='success' style={{ height: '100px', width: 'auto' }} />

    <Stack spacing={0.5}>
      <Typography fontWeight={600} variant='h4' textAlign='center'>
        {formatMessage({ id: 'A new access link was sent to {email}.' }, { email: props.emailSentTo })}
      </Typography>
      <Typography variant='h5' textAlign='center'>{formatMessage({ id: 'You should receive the email within a couple of minutes. You may close this tab.' })}</Typography>
    </Stack>
  </Stack>;
};