import { DownloadLinkButton } from '@components/Download/DownloadLinkButton';
import { FileIcon } from '@components/Icons/FileIcon';
import { ListItemText, Stack } from '@mui/material';
import { FormattedFileSize } from '@utils/FormattedFileSize';
import React, { ReactNode } from 'react';
import { useIntl } from 'react-intl';

interface Props {
  fileName: string;
  fileSize?: number;
  modifiedOn?: string;
  downloadButtonVariant?: 'IconButton' | 'Button';
  downloadLink?: string;
  actionButton?: ReactNode;
  disablePadding?: boolean;
}

export const DocumentFileNameAndIcon: React.FC<Props> = ({ fileName, fileSize, modifiedOn, downloadLink, actionButton, disablePadding, downloadButtonVariant }) => {
  const { formatDate } = useIntl();

  const parts = [
    modifiedOn ? formatDate(modifiedOn) : null,
    fileSize ? <FormattedFileSize bytes={fileSize} /> : null
  ].filter(Boolean);

  return (
    <Stack direction='row' width='100%' alignItems='center' spacing={2} p={disablePadding ? 0 : 2}>
      <FileIcon fileName={fileName} fontSize='large' />

      <Stack width='100%' direction='row' alignItems='center' justifyContent='space-between' overflow='hidden' textOverflow='ellipsis' flexWrap='wrap'>
        <ListItemText
          primary={fileName}
          secondary={parts.join(' • ')}
          sx={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}
        />

        <Stack direction='row' gap={1} flexWrap='wrap'>
          {downloadLink && (
            <DownloadLinkButton
              variant={downloadButtonVariant}
              fileName={fileName}
              link={downloadLink}
            />
          )}

          {actionButton}
        </Stack>
      </Stack>
    </Stack>
  );
};