import { DataTable } from '@components/DataTable';
import { Delete, ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Box, Stack, TextField, Typography, useTheme } from '@mui/material';
import { GridActionsCellItem, GridColDef, useGridApiRef } from '@mui/x-data-grid-pro';
import { useMoneyFormatter } from '@utils/numberUtils';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { CommercialConstructionCalculatorData, OperatingCost, OperatingCostCalculationMethod, calculateAnnualBaseRent, calculateAnnualOperatingCost, costCalculationMethodMessages, isPercentageBasedCalculationMethod } from '../../types';
import { OperatingCostsGridToolbar } from './OperatingCostsGridToolbar';
import { useOperatingCostFormatter } from './utils';

type Props = {
  onChange: () => void;
};

export const OperatingCostsSection: React.FC<Props> = ({ onChange }) => {
  const apiGridRef = useGridApiRef();
  const { control } = useFormContext<CommercialConstructionCalculatorData>();
  const { fields, update, append, remove } = useFieldArray({ control: control, name: 'operatingCosts', keyName: 'uid' });
  const { formatMessage } = useIntl();
  const theme = useTheme();
  const { formatCost } = useOperatingCostFormatter();
  const formatMoney = useMoneyFormatter();

  const purchasePrice = useWatch({ control: control, name: 'calculatorValues.totalEstimate' });
  const rentalRevenues = useWatch({ control: control, name: 'revenues.rentalRevenues' });
  const totalUsableSquareFootage = useWatch({ control: control, name: 'revenues.totalUsableSquareFootage' });
  const commonAreaPercentage = useWatch({ control: control, name: 'revenues.commonAreaPercentage' });

  const commonAreaSquareFootage = totalUsableSquareFootage / (1 - commonAreaPercentage) - totalUsableSquareFootage;
  const totalRentableSquareFootage = totalUsableSquareFootage + commonAreaSquareFootage;

  const additionalRevenues = useWatch({ control: control, name: 'revenues.additionalRevenue' });
  const operatingCosts = useWatch({ control: control, name: 'operatingCosts' });
  const potentialRevenue = _.sumBy(rentalRevenues, p => calculateAnnualBaseRent(p, commonAreaPercentage)) + _.sumBy(additionalRevenues, p => p.annualCost);
  const totalOperatingCosts = _.sum(operatingCosts.map(p => calculateAnnualOperatingCost(p, purchasePrice, potentialRevenue, totalRentableSquareFootage)));

  useEffect(() => {
    let wasCostUpdated = false;
    fields.forEach((value, index) => {
      const previousCost = value.annualCost;
      const newCost = calculateAnnualOperatingCost(value, purchasePrice, potentialRevenue, totalRentableSquareFootage);
      if (previousCost != newCost) {
        update(index, { ...value, annualCost: newCost });
        wasCostUpdated = true;
      }
    });

    if (wasCostUpdated) {
      onChange();
    }
  }, [purchasePrice, potentialRevenue, totalRentableSquareFootage, fields, update, onChange]);

  const columns: GridColDef<OperatingCost>[] = [
    {
      field: 'name',
      headerName: formatMessage({ id: 'Name' }),
      type: 'string',
      editable: true,
      cellClassName: 'editable',
      flex: 1,
      minWidth: 250
    },
    {
      field: 'calculationMethod',
      headerName: formatMessage({ id: 'Calculation method' }),
      type: 'singleSelect',
      cellClassName: 'editable',
      width: 200,
      editable: true,
      valueFormatter: ({ value }) => formatMessage(costCalculationMethodMessages[value as OperatingCostCalculationMethod]),

      valueOptions: () => [{
        value: OperatingCostCalculationMethod.FixedCost,
        label: formatMessage(costCalculationMethodMessages[OperatingCostCalculationMethod.FixedCost])
      },
      {
        value: OperatingCostCalculationMethod.CostPerSquareFootage,
        label: formatMessage(costCalculationMethodMessages[OperatingCostCalculationMethod.CostPerSquareFootage])
      },
      {
        value: OperatingCostCalculationMethod.PercentageOfPurchasePrice,
        label: formatMessage(costCalculationMethodMessages[OperatingCostCalculationMethod.PercentageOfPurchasePrice])
      },
      {
        value: OperatingCostCalculationMethod.PercentageOfRevenue,
        label: formatMessage(costCalculationMethodMessages[OperatingCostCalculationMethod.PercentageOfRevenue])
      }],

    },
    {
      field: 'cost',
      headerName: formatMessage({ id: 'Cost' }),
      type: 'number',
      width: 150,
      editable: true,
      cellClassName: 'editable',
      valueFormatter: (params) => {
        if (!params.id) {
          return '-';
        }
        const row = params.api.getRow(params.id);
        return formatCost(row);
      },
      renderEditCell: params => (
        <TextField
          fullWidth
          autoFocus
          defaultValue={isNaN(params.row.cost)
            ? ''
            : isPercentageBasedCalculationMethod(params.row.calculationMethod)
              ? params.row.cost * 100
              : params.row.cost}
          onChange={e => {
            if (params.id == null)
              return;

            if (isNaN(Number(e.currentTarget.value))) {
              return;
            }

            const row = params.api.getRow<OperatingCost>(params.id);
            if (row == null)
              return;

            const newCost = isPercentageBasedCalculationMethod(row.calculationMethod)
              ? Number(e.currentTarget.value) / 100
              : Number(e.currentTarget.value);

            params.api.setEditCellValue({ field: params.field, id: params.id, value: newCost }, undefined);
          }}
          InputProps={{
            endAdornment: isPercentageBasedCalculationMethod(params.row.calculationMethod) ? '%' :
              params.row.calculationMethod === OperatingCostCalculationMethod.CostPerSquareFootage ? '$ per sq. ft.' : '$'
          }}
        />
      )
    },
    {
      field: 'annualCost',
      headerName: formatMessage({ id: 'Annual cost' }),
      type: 'number',
      width: 150,
      editable: false,
      valueGetter: (params) => calculateAnnualOperatingCost(params.row, purchasePrice, potentialRevenue, totalRentableSquareFootage),
      valueFormatter: ({ value }) => isNaN(value) ? '' : formatMoney(value),

    },

    {
      field: 'actions',
      type: 'actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => [
        <GridActionsCellItem
          icon={<Delete color='action' />}
          label={formatMessage({ id: 'Delete' })}

          onClick={() => {
            const fieldIndex = fields.findIndex(p => p.id === id);
            if (fieldIndex >= 0) {
              remove(fieldIndex);
              onChange();
            }
          }}
          color="inherit"
        />,
      ]
    }
  ];
  const [expanded, setExpanded] = useState(false);

  return <Accordion expanded={expanded} onChange={(_, expanded) => setExpanded(expanded)}>
    <AccordionSummary expandIcon={<ExpandMore />}>
      <Stack direction={'row'} gap={2} flexWrap={'wrap'}>
        <Typography variant='h4' mr={4}>{formatMessage({ id: 'Step 3 - Operating Costs' })}</Typography>
        <Typography variant='h4' color='primary' >{formatMoney(totalOperatingCosts, false)}</Typography>
      </Stack>
    </AccordionSummary>
    <AccordionDetails>
      <Stack sx={{ pt: 4 }} gap={2}>
        <Typography variant='h5'>{formatMessage({ id: 'Rental revenues' })}</Typography>
        <Box maxWidth={1200}
          sx={{
            '& .editable': {
              fontWeight: 600,

            },

          }}>
          <DataTable<OperatingCost>
            variant='minimal'
            apiRef={apiGridRef}
            columns={columns}
            rows={fields}

            getRowId={row => row.id}
            hideFooter
            disableColumnMenu
            disableColumnPinning
            disableColumnResize
            disableColumnReorder
            slots={{
              toolbar: OperatingCostsGridToolbar,
            }}
            slotProps={{
              toolbar: {
                onAddRow: () => {
                  const rowId = crypto.randomUUID();
                  append({
                    id: rowId,
                    name: formatMessage({ id: 'Other' }),
                    annualCost: 0,
                    calculationMethod: OperatingCostCalculationMethod.FixedCost,
                    cost: 0,
                  });
                  onChange();
                }
              }
            }}
            autoHeight
            localeText={{
              ...theme.components?.MuiDataGrid?.defaultProps?.localeText,
              noRowsLabel: formatMessage({ id: 'No operating costs entered' }),
            }}
            processRowUpdate={(newRow, oldRow) => {
              const rowIndex = fields.findIndex(p => p.id === oldRow.id);

              newRow.annualCost = calculateAnnualOperatingCost(newRow, purchasePrice, potentialRevenue, totalRentableSquareFootage);

              if (rowIndex >= 0) {
                update(rowIndex, newRow);
              }

              onChange();
              return newRow;
            }}
            onCellClick={(params) => params.isEditable && apiGridRef.current.startCellEditMode({ field: params.field, id: params.id })}
          />
        </Box>

        <Stack direction={'row'} alignItems={'center'} gap={2}>
          <Typography >{formatMessage({ id: 'Total operating cost:' })}</Typography>
          <Typography fontWeight={600}>{formatMoney(totalOperatingCosts)}</Typography>
        </Stack>
      </Stack>
    </AccordionDetails>
  </Accordion>;
};