import { ResponsiveDrawer } from '@components/Drawers';
import { UserActions } from '@modules/users/components/UserActions';
import { UserDetails } from '@modules/users/components/UserDetails';
import { DrawerProps } from '@mui/material';
import { useGetUserQuery } from 'gql/index';
import React from 'react';
import { useIntl } from 'react-intl';

interface Props extends Omit<DrawerProps, 'open'> {
  userId?: string;
}

export const UserDrawer: React.FC<Props> = ({ userId, ...drawerProps }) => {
  const { formatMessage } = useIntl();

  const { data: user } = useGetUserQuery({ id: userId ?? '' }, { select: d => d.user, enabled: Boolean(userId) });

  return (
    <ResponsiveDrawer
      open={Boolean(userId)}
      header={formatMessage({ id: 'User profile' })}
      disablePadding
      actionButtons={user && (
        <UserActions user={user} />
      )}
      {...drawerProps}
    >
      {user && (
        <UserDetails user={user} />
      )}
    </ResponsiveDrawer>

  );
};