import { Box, Skeleton, Stack, Typography, styled } from '@mui/material';
import React, { ReactNode } from 'react';

interface Props {
  icon: ReactNode;
  title: string;
}

const Icon = styled('div')(({ theme }) => ({
  color: theme.palette.primary.main,
  maxHeight: 28,
  '& .MuiSvgIcon-root': {
    fontSize: 28
  }
}));

export const PageSectionSkeleton: React.FC<Props> = ({ title, icon }) => {

  return (
    <Stack>
      <Stack direction='row' spacing={2} alignItems='center' pl={{ xs: 0, md: 2 }} pt={2} pb={1}>
        <Icon>
          {icon}
        </Icon>

        <Box flexGrow={1}>
          <Typography variant='h6'>
            {title}
          </Typography>
        </Box>
      </Stack>

      <Stack pl={{ xs: 1, md: 3 }}>
        <Skeleton height={56} />

        <Skeleton height={56} />

        <Skeleton height={56} />
      </Stack>
    </Stack>
  );
};