import React from 'react';
import { RecoilRoot } from 'recoil';
import appInsights from './Telemetry';
import { LocalizationProvider } from './components/LocalizationProvider';
import { RouterProvider } from './components/RouterProvider';
import { ThemeProvider } from './components/ThemeProvider';

import { ApolloProvider } from '@apollo/client';
import { initializeFileTypeIcons } from '@fluentui/react-file-type-icons';
import '@formatjs/intl-numberformat/locale-data/en';
import '@formatjs/intl-numberformat/locale-data/fr';
import '@formatjs/intl-numberformat/polyfill';
import { TenantThemeProvider } from '@modules/tenants/components/TenantThemeProvider';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import isBetween from 'dayjs/plugin/isBetween';
import isoWeek from 'dayjs/plugin/isoWeek';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import { pdfjs } from 'react-pdf';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { apolloClient } from './apollo-client';
import { QueryProvider } from './components/QueryProvider';
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.js',
  import.meta.url,
).toString();

dayjs.extend(localizedFormat);
dayjs.extend(duration);
dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.extend(isoWeek);
dayjs.extend(isBetween);

// Register icons and pull the fonts from the default Microsoft Fluent CDN:
initializeFileTypeIcons();

appInsights.loadAppInsights();


export const App: React.FC = () => {
  return <>
    <RecoilRoot>
      <LocalizationProvider>
        <ApolloProvider client={apolloClient}>
          <QueryProvider>
            <ThemeProvider>
              <TenantThemeProvider>
                <ToastContainer />
                <RouterProvider />
              </TenantThemeProvider>
            </ThemeProvider>
          </QueryProvider>
        </ApolloProvider>
      </LocalizationProvider>
    </RecoilRoot >
  </>;
};