import { Stack, Typography } from '@mui/material';
import React, { ReactNode } from 'react';

interface Props {
  propertyName: string;
  children: ReactNode;
  emptyState?: string;
}

export const Property: React.FC<Props> = ({ propertyName, children, emptyState }) => {
  const showEmptyState = !children && emptyState;
  return (
    <Stack>
      <Typography variant='body2' color='text.secondary'>{propertyName}</Typography>
      {showEmptyState ? (
        <Typography fontStyle='italic'>
          {emptyState}
        </Typography>
      ) : children}
    </Stack>
  );
};