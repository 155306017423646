import { DisableableTooltip } from '@components/Tooltips/DisableableTooltip';
import { isProjectNavigationMenuCollapsedState } from '@modules/projects/atoms';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Collapse } from '@mui/material';
import { useResponsive } from '@utils/useResponsive';
import React, { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { ProjectNavigationMenuLink, ProjectNavigationMenuLinkProps } from './ProjectNavigationMenuLink';

type Props = {
  mainEntry: ProjectNavigationMenuLinkProps;
  subEntries: (boolean | ProjectNavigationMenuLinkProps)[];
};

export const ProjectNavigationMenuTabGroup: React.FC<Props> = ({ mainEntry, subEntries: unfilteredSubEntries }) => {
  const { isMobile } = useResponsive();

  const isNavigationMenuCollapsedRecoilValue = useRecoilValue(isProjectNavigationMenuCollapsedState);

  const isNavigationMenuCollapsed = isMobile ? false : isNavigationMenuCollapsedRecoilValue;

  const [isExpanded, setIsExpanded] = useState(false);

  const subEntries = unfilteredSubEntries.filter(t => typeof t !== 'boolean');

  if (subEntries.length === 0) {
    return (
      <ProjectNavigationMenuLink {...mainEntry} />
    );
  }

  if (subEntries.length === 1) {
    const [entry] = subEntries;

    return (
      <ProjectNavigationMenuLink {...entry} />
    );
  }

  return <>
    <DisableableTooltip
      arrow
      leaveDelay={150}
      placement='right'
      disabled={!isNavigationMenuCollapsed}
      title={<>
        {subEntries.map((entry, index) => (
          <ProjectNavigationMenuLink disableTooltip key={index} {...entry} />
        ))}
      </>}
      slotProps={{
        tooltip: {
          sx: {
            backgroundColor: t => t.palette.background.paper,
            border: t => `1px solid ${t.palette.divider}`
          }
        }
      }}
    >
      <ProjectNavigationMenuLink
        disableTooltip={isNavigationMenuCollapsed}
        onClick={() => setIsExpanded(!isExpanded)}
        {...mainEntry}
      >
        {isExpanded ? <ExpandLess /> : <ExpandMore />}
      </ProjectNavigationMenuLink>
    </DisableableTooltip>

    {!isNavigationMenuCollapsed && (
      <Collapse in={isExpanded} timeout="auto" unmountOnExit>
        {subEntries.map((entry, index) => (
          <ProjectNavigationMenuLink sx={{ pl: isNavigationMenuCollapsed ? undefined : 4 }} key={index} {...entry} />
        ))}
      </Collapse>
    )}
  </>;
};