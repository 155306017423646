import { DownloadLinkButton } from '@components/Download/DownloadLinkButton';
import { DisableableTooltip } from '@components/Tooltips/DisableableTooltip';
import { DocumentFileNameAndIcon } from '@modules/documents/components/DocumentFilenameAndIcon';
import { Draw, Share } from '@mui/icons-material';
import { Backdrop, Button, Container, Divider, Paper, Stack, Typography } from '@mui/material';
import { getTenantIdentifier } from '@utils/getTenantIdentifier';
import { useResponsive } from '@utils/useResponsive';
import { ProjectAccessTokenQuery } from 'gql/index';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { ZohoSignaturePad } from '../../documents/components/ZohoSignaturePad';
import { AnonymousSignaturePaper } from './AnonymousSignaturePaper';
import { SignUpBanner } from './SignUpBanner';

export interface Props {
  token: string;
  content: NonNullable<ProjectAccessTokenQuery['projectAccessTokenContent']>;
}

export const AnonymousDocumentDetailsDialog: React.FC<Props> = ({ token, content }) => {
  const { isMobile } = useResponsive();
  const { formatMessage } = useIntl();

  const [isSigning, setIsSigning] = useState(false);

  if (!content.document) return null;

  const isSigned = content.document.isSigned;

  const publishedBy = content.document.createdBy;

  const fileNameAndIcon = (
    <Stack direction='row' minWidth={!isMobile ? '400px' : undefined} alignItems='center' gap={2} py={1} px={2} border={t => `1px solid ${t.palette.divider}`} my={2}>
      <DocumentFileNameAndIcon
        {...content.document}
      />
    </Stack>
  );

  const inner = isSigning ? (
    <>
      {(content.isZohoSignEnabled) ?
        <ZohoSignaturePad requestId={content.document.zohoRequestId ?? ''} actionId={content.document.zohoActionId ?? ''} />
        :
        <AnonymousSignaturePaper onClose={() => setIsSigning(false)} token={token} />
      }
    </>
  ) : (
    <Stack direction='column' alignItems='center' pt={8} pb={4} px={2}>
      <Stack alignItems='center' pb={6} gap={3}>
        <Stack fontSize='84px'>
          <Share fontSize='inherit' color='primary' />
        </Stack>

        <Typography variant='h5'>
          {content.document.isUserAuthorizedToSign && !isSigned
            ? formatMessage({ id: '{name} has requested a signature from you' }, { name: publishedBy?.fullName })
            : formatMessage({ id: '{name} has shared a document with you' }, { name: publishedBy?.fullName })}
        </Typography>
      </Stack>

      <Divider sx={{ width: '100%' }} />

      <Typography mt={4}>
        {content.document.isUserAuthorizedToSign && !isSigned
          ? formatMessage({ id: 'Click the button below to start signing the document.' })
          : formatMessage({ id: 'Click the button below to download the document.' })}
      </Typography>

      {fileNameAndIcon}

      <Stack direction='row' gap={2} flexWrap='wrap'>
        <DownloadLinkButton
          variant='Button'
          size='large'
          fileName={content.document.fileName}
          link={`/${getTenantIdentifier()}/api/Documents/ExternalLink?token=${encodeURIComponent(token)}`}
        />

        {content.document.isUserAuthorizedToSign && (
          <DisableableTooltip arrow disabled={!isSigned} title={formatMessage({ id: 'This document is already signed' })}>
            <Button disabled={isSigned} size='large' startIcon={<Draw />} variant='contained' onClick={() => setIsSigning(true)}>
              {formatMessage({ id: 'Start signing' })}
            </Button>
          </DisableableTooltip>
        )}
      </Stack>
    </Stack>
  );

  return isMobile ? (
    <Stack gap={4} height='100%'>
      {inner}

      {!isSigning && (
        <SignUpBanner
          userFullName={content.forUser?.fullName ?? ''}
          tenantDisplayName={content.tenant?.name ?? getTenantIdentifier() ?? ''}
          projectId={content.project?.id ?? 0}
        />
      )}
    </Stack>
  ) : (
    <Backdrop open sx={{ backdropFilter: 'blur(3px)', position: 'absolute' }}>
      <Container maxWidth={content.isZohoSignEnabled && isSigning ? 'xl' : 'lg'} sx={{ height: '100%' }}>
        <Stack spacing={2} height='100%' alignItems='center' justifyContent='center'>
          <SignUpBanner
            userFullName={content.forUser?.fullName ?? ''}
            tenantDisplayName={content?.tenant?.name ?? getTenantIdentifier() ?? ''}
            projectId={content?.project?.id ?? 0}
          />

          <Paper style={{ overflow: 'hidden', height: content.isZohoSignEnabled ? '100%' : 'auto', maxHeight: content.isZohoSignEnabled ? '700px' : 'none', width: '100%' }}>
            {inner}
          </Paper>
        </Stack>
      </Container>
    </Backdrop>
  );
};
