import { DataTable } from '@components/DataTable';
import { UserAvatar } from '@components/UserAvatar/UserAvatar';
import { Chip, Stack } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';
import { getTenantIdentifier } from '@utils/getTenantIdentifier';
import { useGetMe } from '@utils/useGetMe';
import { WorkflowDefinitionFilterInput, useWorkflowTemplatesQuery } from 'gql/index';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { WorkflowTemplatesActions } from './WorkflowTemplatesActions';

interface Props {
  selectedTab: 'mine' | 'sharedWithMe';
}

export const WorkflowTemplatesTable: React.FC<Props> = ({ selectedTab }) => {
  const { me } = useGetMe();
  const { formatMessage } = useIntl();
  const navigate = useNavigate();


  const filter = useMemo((): WorkflowDefinitionFilterInput => {
    if (selectedTab == 'mine') {
      return {
        createdById: {
          eq: me?.id
        }
      };
    } else {
      return {
        createdById: {
          neq: me?.id
        }
      };
    }
  }, [selectedTab, me?.id]);

  const { data: workflows, isFetching } = useWorkflowTemplatesQuery({ filter }, { select: d => d.workflowTemplates });
  type Workflow = NonNullable<typeof workflows>[0];

  const columns: GridColDef<Workflow>[] = [
    {
      field: 'name',
      flex: 1,
      minWidth: 150,
      headerName: formatMessage({ id: 'Name' }),

      valueGetter: ({ row }) => row.name,
      renderCell: ({ value }) => <Stack sx={{ cursor: 'pointer' }} > {value}</Stack>
    },
    {
      field: 'createdBy',
      flex: 1,
      minWidth: 150,
      headerName: formatMessage({ id: 'Created by' }),
      valueGetter: ({ row }) => row.createdBy?.fullName,
      renderCell: ({ value }) => <Chip avatar={<UserAvatar displayName={value} />} label={value} />
    },
    {
      field: 'actions',
      headerName: '',
      sortable: false,
      disableColumnMenu: true,
      disableExport: true,
      maxWidth: 64,
      renderCell: ({ row }) => <WorkflowTemplatesActions template={row} />
    }
  ];

  return (
    <DataTable
      disableColumnMenu
      loading={isFetching}
      columns={columns}
      rows={workflows ?? []}
      noSearchResultsMessage={formatMessage({ id: 'No templates found' })}
      noDataMessage={formatMessage({ id: 'No templates have been created yet' })}
      onCellClick={(params) => params.colDef.field === 'name' && navigate(`/${getTenantIdentifier()}/workflows/edit/${params.row.id}`)}
    />
  );
};