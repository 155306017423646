import { Box, Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { useGetMe } from '@utils/useGetMe';
import { useNotification } from '@utils/useNotification';
import { UpdateMyPreferencesInput, useUpdateMyPreferencesMutation } from 'gql/index';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { SectionTitle } from '../SectionTitle';

export const NotificationsSection = () => {

  const { notifySuccess } = useNotification();
  const { mutate: updateMyPreferences } = useUpdateMyPreferencesMutation();

  const { me, refetch: refetchMe } = useGetMe();
  const { formatMessage } = useIntl();
  const form = useForm<UpdateMyPreferencesInput>({ defaultValues: { preferredLanguage: me?.language, optOutOfWeeklyEmails: me?.optedOutOfWeeklyEmails } });

  const onSubmit = (form: UpdateMyPreferencesInput) => {
    updateMyPreferences({ input: form }, {
      onSuccess: () => {
        refetchMe();
        notifySuccess(formatMessage({ id: 'Preferences have been updated.' }));
      }
    });
  };

  return <Box padding={2}>
    <SectionTitle title={formatMessage({ id: 'Notifications' })} />
    <FormGroup sx={{ px: 2 }}>
      <FormControlLabel
        control={<Checkbox defaultChecked={me?.optedOutOfWeeklyEmails}
          onChange={(_, checked) => {
            form.setValue('optOutOfWeeklyEmails', checked);
            form.handleSubmit(onSubmit)();
          }} />}
        label={formatMessage({ id: 'Opt out of email updates' })} />
    </FormGroup>
  </Box>;
};