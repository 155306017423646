import { useTheme } from '@mui/material';
import { TenantTheme } from 'gql/index';
import React, { createContext, ReactNode, useMemo } from 'react';


type TenantThemeContextProviderType = {
  isBrandingEnabled: boolean;
  topBarLogoUrl: string | null | undefined;
  loginBackgroundUrl: string | null | undefined;
  squareLogoUrl: string | null | undefined;
};

export const TenantThemeContext = createContext<TenantThemeContextProviderType>({
  isBrandingEnabled: false,
  topBarLogoUrl: undefined,
  loginBackgroundUrl: undefined,
  squareLogoUrl: undefined
});

type Props = {
  children: ReactNode;
  tenantTheme: TenantTheme | undefined;
}
export const TenantThemeContextProvider: React.FC<Props> = ({ children, tenantTheme }) => {
  const theme = useTheme();
  const topBarLogoUrl = useMemo(() => theme.palette.mode == 'light' ? tenantTheme?.topBarLogoLightUrl : tenantTheme?.topBarLogoDarkUrl
    , [tenantTheme?.topBarLogoDarkUrl, tenantTheme?.topBarLogoLightUrl, theme.palette.mode]);
  const squareLogoUrl = useMemo(() => theme.palette.mode == 'light' ? tenantTheme?.squareLogoLightUrl : tenantTheme?.squareLogoDarkUrl
    , [tenantTheme?.squareLogoDarkUrl, tenantTheme?.squareLogoLightUrl, theme.palette.mode]);


  return (
    <TenantThemeContext.Provider value={{
      isBrandingEnabled: tenantTheme?.isBrandingEnabled ?? false,
      topBarLogoUrl,
      loginBackgroundUrl: tenantTheme?.loginBackgroundUrl,
      squareLogoUrl
    }}>
      {children}
    </TenantThemeContext.Provider>
  );
};