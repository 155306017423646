import { Chip, Typography } from '@mui/material';
import { useIntl } from 'react-intl';

interface OwnProps {
  title?: string
}

export const TemplateTitle: React.FC<OwnProps> = ({ title }) => {
  const { formatMessage } = useIntl();
  return (
    <>
      <Typography color='inherit' variant='h5' sx={{ maxWidth: '80ch' }}>
        {title ?? ''}
      </Typography>
      <Chip label={formatMessage({ id: 'Template' })} color='secondary' />
    </>
  );
};