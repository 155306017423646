import { MatrixColumnType } from 'gql/index';
import { MessageDescriptor, defineMessages } from 'react-intl';

export const matrixColumnTypeMessages: Record<MatrixColumnType, MessageDescriptor> = defineMessages({
  [MatrixColumnType.Text]: { id: 'Text' },
  [MatrixColumnType.Numerical]: { id: 'Numerical' },
  [MatrixColumnType.Boolean]: { id: 'Yes/No' }
});

export const defaultFormNames = defineMessages({
  'form': { id: 'New form' },
  'template': { id: 'New template' }
});