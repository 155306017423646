import { EmptyState } from '@components/EmptyState';
import { PageContainer } from '@components/Layout/PageContainer';
import { ResponsiveButton } from '@components/ResponsiveButton';
import { Add } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { FieldType } from 'gql/index';
import React, { useContext, useMemo } from 'react';
import { FormProvider, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { FormEditorContext } from '../FormEditorContext';
import { FormEditorValues } from '../types';
import { FormCondition } from './FormCondition';

export type TargetItem = {
  id: number,
  name: string,
  type: 'field' | 'section';
};

type Props = {
  open: boolean;
};

export const FormConditionsForm: React.FC<Props> = ({ open }) => {
  const { formatMessage } = useIntl();
  const form = useFormContext<FormEditorValues>();

  const { conditionsFieldArray } = useContext(FormEditorContext);
  const targetSections = useMemo(() => form.getValues('sections').map<TargetItem>(p => ({ id: p.id ?? -1, name: p.name ?? formatMessage({ id: 'Untitled section' }), type: 'section' })), [form, formatMessage]);
  const targetFields = useMemo(() => form.getValues('sections').flatMap(p => p.fields.map<TargetItem>(field => ({ id: field.id ?? -1, name: field.name, type: 'field' }))), [form]);
  const yesNoFields = useMemo(() =>
    form.getValues('sections').flatMap(p =>
      p.fields
        .filter(p => p.fieldType == FieldType.Boolean)
        .map<TargetItem>(field => ({ id: field.id ?? -1, name: field.name, type: 'field' }))
    ), [form]);

  return <Stack flex={1} sx={{ display: open ? 'inherit' : 'none', height: '100%' }}>
    <PageContainer
      maxWidth='md'
      title={formatMessage({ id: 'Conditions' })}
      action={(
        <ResponsiveButton icon={<Add />} variant='contained' color='primary'
          onClick={() => {
            conditionsFieldArray.append({
              conditionFieldId: '',
              conditionFieldWhenValueBool: false,
              id: '',
              targetFieldId: '',
              targetSectionId: ''
            });

          }}>
          {formatMessage({ id: 'Add condition' })}
        </ResponsiveButton>
      )}
    >
      <Stack gap={4}>
        {conditionsFieldArray.fields.length === 0 &&
          <EmptyState
            title={formatMessage({ id: 'There are currently no logical conditions configured for this form.' })}
            subtitle='You can control field and sections visibility by adding conditions.'
            callToActionComponent={<ResponsiveButton icon={<Add />} variant='contained' color='primary'
              onClick={() => {
                conditionsFieldArray.append({
                  conditionFieldId: '',
                  conditionFieldWhenValueBool: false,
                  id: '',
                  targetFieldId: '',
                  targetSectionId: ''
                });

              }}>
              {formatMessage({ id: 'Add condition' })}
            </ResponsiveButton>}
          />}
        <FormProvider {...form}>
          {conditionsFieldArray.fields.map((p, index) => (<FormCondition
            key={p.uniqueId}
            fieldIndex={index}
            conditionTargets={targetSections.concat(targetFields)}
            yesNoFields={yesNoFields}
          />))}

        </FormProvider>
      </Stack>
    </PageContainer >
  </Stack>;
};