import { CancelButton } from '@components/Buttons/CancelButton';
import { LoadingButton } from '@mui/lab';
import { Stack } from '@mui/material';
import React from 'react';
import { useIntl } from 'react-intl';
import { ResponsiveDialog, ResponsiveDialogProps } from './ResponsiveDialog';

export interface FormDialogProps extends ResponsiveDialogProps {
  saveButtonMessage?: string;
  disabled?: boolean;
  onSubmit?: () => void;
  isSubmitting?: boolean;
}

export const FormDialog: React.FC<FormDialogProps> = ({
  children,
  onClose,
  onSubmit,
  saveButtonMessage,
  disabled: _disabled,
  isSubmitting,
  ...drawerProps
}) => {
  const { formatMessage } = useIntl();

  const disabled = isSubmitting || _disabled;

  const onCloseClicked = () => !isSubmitting && onClose?.({}, 'escapeKeyDown');

  return (
    <ResponsiveDialog {...drawerProps} onClose={onCloseClicked}>
      <Stack overflow='auto' p={2} gap={2}>
        {children}
      </Stack>

      <Stack color='inherit' width='100%'>
        <Stack justifyContent='right' direction='row' spacing={1} p={{ xs: 1, md: 2 }}>
          <CancelButton onClick={onCloseClicked} disabled={disabled} />

          <LoadingButton
            loading={isSubmitting}
            onClick={onSubmit}
            disabled={disabled}
            variant='contained'
            color='primary'
          >
            {saveButtonMessage ?? formatMessage({ id: 'Submit' })}
          </LoadingButton>
        </Stack>
      </Stack>
    </ResponsiveDialog>
  );
};