import { PageContainer } from '@components/Layout/PageContainer';
import { PageTitle } from '@components/Layout/PageTitle';
import { ResponsiveButton } from '@components/ResponsiveButton';
import { DisableableTooltip } from '@components/Tooltips/DisableableTooltip';
import { Add, ViewList } from '@mui/icons-material';
import { Box, Tab, Tabs } from '@mui/material';
import { getTenantIdentifier } from '@utils/getTenantIdentifier';
import { useQueryInvalidator } from '@utils/useQueryInvalidator';
import { useResponsive } from '@utils/useResponsive';
import { useCreateFormTemplateMutation, useFormTemplatesQuery } from 'gql/index';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router';
import { defaultFormNames } from '../../components/FormEditor/messages';
import { FormTemplatesTable } from '../../components/FormTemplatesTable';

export const FormTemplatesPage: React.FC = () => {
  const { formatMessage } = useIntl();
  const invalidateQuery = useQueryInvalidator();
  const navigate = useNavigate();
  const { isMobile } = useResponsive();

  const [selectdTab, setSelectedTab] = useState<'mine' | 'sharedWithMe'>('mine');
  const { isFetching } = useFormTemplatesQuery();
  const { mutate: createFormTemplate } = useCreateFormTemplateMutation();

  const handleCreateNewTemplete = () => {
    createFormTemplate({
      input: {
        name: formatMessage(defaultFormNames.template)
      }
    }, {
      onSuccess: d => {
        navigate(`/${getTenantIdentifier()}/templates/edit/${d.createFormTemplate.formTemplate?.id}`);
        invalidateQuery(useFormTemplatesQuery);
      }
    });
  };

  return <>
    <PageTitle
      backgroundColor='secondary'
      title={formatMessage({ id: 'Form templates' })}
      icon={<ViewList />}
      loading={isFetching}
      actionButton={(
        <DisableableTooltip enterTouchDelay={0} disableInteractive arrow disabled={!isMobile} title={formatMessage({ id: 'Forms cannot be edited on mobile.' })}>
          <ResponsiveButton icon={<Add />} variant='contained' disabled={isMobile} color='primary' onClick={handleCreateNewTemplete}>
            {formatMessage({ id: 'Add template' })}
          </ResponsiveButton>
        </DisableableTooltip>
      )}
    />

    <PageContainer>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
        <Tabs value={selectdTab} onChange={(_, newValue) => setSelectedTab(newValue)}>
          <Tab value='mine' label={formatMessage({ id: 'Mine' })} />
          <Tab value='sharedWithMe' label={formatMessage({ id: 'Shared with me' })} />
        </Tabs>
      </Box>
      <FormTemplatesTable selectedTab={selectdTab} />
    </PageContainer>
  </>;
};