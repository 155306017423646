import { PageContainer } from '@components/Layout/PageContainer';
import { useCurrentProject } from '@modules/projects/utils/useCurrentProject';
import { Stack } from '@mui/material';
import { useBudgetItemsQuery, useCommercialProjectCalculatorsQuery, useGetProjectBudgetQuery, useTenantSettingsQuery } from 'gql/index';
import { useIntl } from 'react-intl';
import { BudgetChart } from '../components/BudgetChart';
import { BudgetItemTable } from '../components/BudgetItemTable';
import { CalculatorsTable } from '../components/CommercialConstructionCalculator/components/CalculatorsTable';

export const ProjectBudgetPage: React.FC = () => {
  const { formatMessage } = useIntl();
  const { projectId } = useCurrentProject();
  const { data: tenantSettings } = useTenantSettingsQuery({}, { staleTime: 600000 });
  const { data: calculators, isFetching: isFetchingCalculators } = useCommercialProjectCalculatorsQuery({ projectId }, { select: d => d.commercialProjectCalculators, enabled: tenantSettings?.tenantSettings.isConstructionAddonEnabled });

  const { data: budget } = useGetProjectBudgetQuery({ id: projectId }, { select: d => d.projectBudget });

  const { data: budgetItems, isFetching: isFetchingBudgetItems } = useBudgetItemsQuery({ projectId }, { select: d => d.budgetItems });

  return (
    <PageContainer title={formatMessage({ id: 'Budget' })}>
      <Stack>
        {budget?.totalBudgetedAmount > 0 && (
          <BudgetChart />
        )}

        {tenantSettings?.tenantSettings?.isConstructionAddonEnabled && (
          <CalculatorsTable isLoading={isFetchingCalculators} calculators={calculators ?? []} />
        )}

        <BudgetItemTable budgetItems={budgetItems ?? []} loading={isFetchingBudgetItems} />
      </Stack>
    </PageContainer>
  );
};