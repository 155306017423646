import { isProjectNavigationMenuCollapsedState, wasProjectNavigationMenuCollapsedTipDismissedState } from '@modules/projects/atoms';
import { useCurrentProject } from '@modules/projects/utils/useCurrentProject';
import { Stack } from '@mui/material';
import { useResponsive } from '@utils/useResponsive';
import { Folder } from 'gql/index';
import { useContext, useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { ExplorerDesktopSidebar } from '../components/ExplorerDesktopSidebar';
import { ExplorerMainView } from '../components/ExplorerMainView';
import { ProjectDocumentsContext } from '../components/ProjectDocumentsContextProvider';
import { FolderEntryPropertiesDrawer } from '../components/Properties/FolderEntryPropertiesDrawer';
import { TreeViewFolderItem } from '../components/TreeViewFolderItem';


export const ProjectDocumentsView = () => {
  const [isNavigationMenuCollapsed, setNavigationMenuCollapsed] = useRecoilState(isProjectNavigationMenuCollapsedState);
  const isNavigationMenuCollapsedTipDismissed = useRecoilValue(wasProjectNavigationMenuCollapsedTipDismissedState);

  const { rootFolder } = useCurrentProject();

  const { setBreadcrumbPath } = useContext(ProjectDocumentsContext);

  const { isMobile } = useResponsive();

  const onTreeFolderClicked = (folder: Pick<Folder, 'path'>) => {
    const path = folder.path.split('/').map(Number).filter(n => !!n);
    // Remove root folder ID
    path.shift();

    setBreadcrumbPath(path);
  };

  useEffect(() => {
    if (!isNavigationMenuCollapsed && !isNavigationMenuCollapsedTipDismissed) {
      setNavigationMenuCollapsed(true);
    }
  }, [isNavigationMenuCollapsed, setNavigationMenuCollapsed, isNavigationMenuCollapsedTipDismissed]);
  return <>
    <Stack bgcolor={'background.paper'} width={'100%'} height={'100%'}>



      <Stack direction='row' flex={1} overflow={'auto'} sx={{ borderTop: '1px solid', borderColor: 'divider' }}>

        {!isMobile && (
          <Stack overflow='auto' width='300px' minWidth='300px' bgcolor='background.paper' sx={{ borderRight: t => `1px solid ${t.palette.divider}` }}>
            {rootFolder && (
              <TreeViewFolderItem folder={rootFolder} onClick={onTreeFolderClicked} />
            )}
          </Stack>
        )}

        <ExplorerMainView />

        {!isMobile && (
          <ExplorerDesktopSidebar />
        )}

        {isMobile && (
          <FolderEntryPropertiesDrawer />
        )}
      </Stack>
    </Stack >
  </>;
};
