import { OrganizationGrantAccessView } from '@modules/securityGroups/components/PermissionsModal/OrganizationResource/OrganizationGrantAccessView';
import { OrganizationManageAccessView } from '@modules/securityGroups/components/PermissionsModal/OrganizationResource/OrganizationManageAccessView';
import { ReactNode, createContext, useEffect, useState } from 'react';
import { BasicModal } from '../../../../components/Utils/BasicModal';
import { RoleAssignmentFragmentFragment } from '../../../../gql';
import { ShareableResource, isOrganizationResourceType } from '../Permissions/types';
import { AccessSummaryView } from './AccessSummaryView';
import { GrantAccessView } from './ProjectResource/GrantAccessView';
import { ManageAccessView } from './ProjectResource/ManageAccessView';
import { SendNotificationView } from './SendNotificationView';

interface Props {
  open: boolean;
  onClose: () => void;
  resource: ShareableResource;
}

type Views = 'manageAccess' | 'grantAccess' | 'sendNotification' | 'accessSummary';

type PermissionsModalContextType = {
  goToManageAccess: () => void;
  goToAccessSummary: (roleAssignment: RoleAssignmentFragmentFragment) => void;
  goToSendNotification: (roleAssignment: RoleAssignmentFragmentFragment) => void;
  goToGrantAccess: () => void;
};

export const PermissionsModalContext = createContext<PermissionsModalContextType>({
  goToManageAccess: () => undefined,
  goToAccessSummary: () => undefined,
  goToGrantAccess: () => undefined,
  goToSendNotification: () => undefined,
});

export const PermissionsModal: React.FC<Props> = ({ open, onClose, resource }) => {
  const [currentView, setCurrentView] = useState<Views>('manageAccess');
  const [selectedRoleAssignment, setSelectedRoleAssignment] = useState<RoleAssignmentFragmentFragment | undefined>();

  useEffect(() => {
    if (!open) {
      setCurrentView('manageAccess');
      setSelectedRoleAssignment(undefined);
    }
  }, [open]);

  const goToManageAccess = () => setCurrentView('manageAccess');

  const goToAccessSummary = (roleAssignment: RoleAssignmentFragmentFragment) => {
    setSelectedRoleAssignment(roleAssignment);
    setCurrentView('accessSummary');
  };

  const goToGrantAccess = () => {
    setCurrentView('grantAccess');
  };

  const goToSendNotification = (roleAssignment: RoleAssignmentFragmentFragment) => {
    setSelectedRoleAssignment(roleAssignment);
    setCurrentView('sendNotification');
  };

  const projectResourceViews: Record<Views, ReactNode> = {
    accessSummary: selectedRoleAssignment && <AccessSummaryView roleAssignment={selectedRoleAssignment} />,
    grantAccess: <GrantAccessView resource={resource} />,
    manageAccess: <ManageAccessView resource={resource} />,
    sendNotification: selectedRoleAssignment && <SendNotificationView resource={resource} roleAssignment={selectedRoleAssignment} />
  };

  const organizationResourceViews: Record<Views, ReactNode> = {
    accessSummary: selectedRoleAssignment && <AccessSummaryView roleAssignment={selectedRoleAssignment} />,
    grantAccess: <OrganizationGrantAccessView resource={resource} />,
    manageAccess: <OrganizationManageAccessView resource={resource} />,
    sendNotification: selectedRoleAssignment && <SendNotificationView resource={resource} roleAssignment={selectedRoleAssignment} />
  };

  return (
    <BasicModal open={open} onClose={onClose}>
      <PermissionsModalContext.Provider value={{ goToManageAccess, goToAccessSummary, goToGrantAccess, goToSendNotification }}>
        {isOrganizationResourceType(resource.resourceType) ? organizationResourceViews[currentView] : projectResourceViews[currentView]}
      </PermissionsModalContext.Provider>
    </BasicModal>
  );
};