import ExpandMore from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Divider, Stack, Typography } from '@mui/material';
import { useMoneyFormatter } from '@utils/numberUtils';
import _ from 'lodash';
import { useMemo, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { CommercialConstructionCalculatorData, calculateAnnualRent } from '../types';

export const SummarySection = () => {
  const { control } = useFormContext<CommercialConstructionCalculatorData>();
  const { formatMessage, formatNumber } = useIntl();

  const formatMoney = useMoneyFormatter();
  const purchasePrice = useWatch({ control: control, name: 'calculatorValues.totalEstimate' });
  const vacancy = useWatch({ control: control, name: 'revenues.vacancy' });

  const selectedMortgageScenario = useWatch({ control: control, name: 'revenues.selectedMortgageScenario' });
  const mortgages = useWatch({ control: control, name: 'mortgageCalculator.scenarios' });
  const mortgage = useMemo(() => mortgages[selectedMortgageScenario], [mortgages, selectedMortgageScenario]);

  const totalUsableSquareFootage = useWatch({ control: control, name: 'revenues.totalUsableSquareFootage' });
  const commonAreaFactor = useWatch({ control: control, name: 'revenues.commonAreaPercentage' });
  const commonAreaSquareFootage = totalUsableSquareFootage / (1 - commonAreaFactor) - totalUsableSquareFootage;
  const totalRentableSquareFootage = totalUsableSquareFootage + commonAreaSquareFootage;
  const operatingCosts = useWatch({ control: control, name: 'operatingCosts' });
  const rentalRevenues = useWatch({ control: control, name: 'revenues.rentalRevenues' });
  const additionalRevenues = useWatch({ control: control, name: 'revenues.additionalRevenue' });
  const totalRevenues = (1 - vacancy) * ((_.sumBy(rentalRevenues, p => calculateAnnualRent(p, commonAreaFactor, _.sumBy(operatingCosts, p => p.annualCost), totalRentableSquareFootage)) + _.sumBy(additionalRevenues, p => p.annualCost)));
  const netRevenue = totalRevenues - _.sumBy(operatingCosts, p => p.annualCost);

  const [expanded, setExpanded] = useState(false);

  return <Accordion expanded={expanded} onChange={(_, expanded) => setExpanded(expanded)}>
    <AccordionSummary expandIcon={<ExpandMore />}>
      <Stack direction={'row'} spacing={4}>
        <Typography variant='h4'>{formatMessage({ id: 'Summary' })}</Typography>
        <Stack direction={'row'} spacing={2}>
          <Typography variant='h4' color={netRevenue >= 0 ? 'primary' : 'error'}>{netRevenue ? formatMoney(netRevenue, false) : ''}</Typography>

        </Stack>
      </Stack>
    </AccordionSummary>
    <AccordionDetails>
      <Stack gap={2} maxWidth={500}>

        <Stack direction={'row'} alignItems={'center'} gap={2} justifyContent={'space-between'} flexWrap={'wrap'}>
          <Typography variant='h6'>{formatMessage({ id: 'Total revenues:' })}</Typography>
          <Typography variant='h6' fontWeight={600}>{formatMoney(totalRevenues, false)}</Typography>
        </Stack>
        <Stack direction={'row'} alignItems={'center'} gap={2} justifyContent={'space-between'} flexWrap={'wrap'}>
          <Typography variant='h6'>{formatMessage({ id: 'Operating costs:' })}</Typography>
          <Typography variant='h6' fontWeight={600}>{formatMoney(_.sumBy(operatingCosts, p => p.annualCost), false)}</Typography>
        </Stack>
        <Stack direction={'row'} alignItems={'center'} gap={2} justifyContent={'space-between'} flexWrap={'wrap'}>
          <Typography variant='h6' fontWeight={600}>{formatMessage({ id: 'Effective net revenue:' })}</Typography>
          <Typography variant='h6' fontWeight={600} color={netRevenue >= 0 ? 'primary' : 'error'}>{formatMoney(netRevenue, false)}</Typography>
        </Stack>
        <Divider />
        <Stack direction={'row'} alignItems={'center'} gap={2} justifyContent={'space-between'} flexWrap={'wrap'}>
          <Typography variant='h6'>{formatMessage({ id: 'Loan:' })}</Typography>
          <Typography variant='h6' fontWeight={600}>{`${formatMoney(mortgage?.loanAmount)}  @ ${formatNumber(mortgage?.annualInterestRate, { style: 'percent' })}`}</Typography>
        </Stack>
        <Stack direction={'row'} alignItems={'center'} gap={2} justifyContent={'space-between'} flexWrap={'wrap'}>
          <Typography variant='h6'>{formatMessage({ id: 'Down payment:' })}</Typography>
          <Typography variant='h6' fontWeight={600}>{`${formatMoney(mortgage?.downPaymentPercent * purchasePrice, false)}`}</Typography>
        </Stack>
        <Stack direction={'row'} alignItems={'center'} gap={2} justifyContent={'space-between'} flexWrap={'wrap'}>
          <Typography variant='h6'>{formatMessage({ id: 'Mortgage annual payment:' })}</Typography>
          <Typography variant='h6' fontWeight={600}>{formatMoney(mortgage?.annualPayment, false)}</Typography>
        </Stack>
      </Stack>
    </AccordionDetails>
  </Accordion>;
};