import { Stack, Typography } from '@mui/material';
import { ReactNode } from 'react';

type PropertyGroupProps = {
  title: string;
  children: ReactNode;
  actionButton?: ReactNode;
};

export const PropertyGroup = (props: PropertyGroupProps) => {
  const { title, children, actionButton } = props;
  return <Stack gap={2}>
    <Stack direction='row' justifyContent='space-between' alignItems='center'>
      <Typography variant='h6'>{title}</Typography>
      {actionButton}
    </Stack>
    <Stack ml={2} gap={2} >
      {children}
    </Stack>
  </Stack>;
};