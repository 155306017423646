import { ActionMenu } from '@components/ActionMenu';
import { ConfirmDialog } from '@components/ConfirmDialog';
import { useCurrentProject } from '@modules/projects/utils/useCurrentProject';
import { Delete, Edit } from '@mui/icons-material';
import { ListItemIcon, MenuItem } from '@mui/material';
import { useNotification } from '@utils/useNotification';
import { useQueryInvalidator } from '@utils/useQueryInvalidator';
import { ExpenseFragmentFragment, useDeleteExpenseMutation, useExpensesQuery, useGetProjectBudgetQuery } from 'gql/index';
import React from 'react';
import { useIntl } from 'react-intl';
import { ExpenseDrawer } from './ExpenseDrawer';

interface Props {
  expense: ExpenseFragmentFragment;
}

export const ExpenseActions: React.FC<Props> = ({ expense }) => {
  const { formatMessage } = useIntl();
  const { projectId } = useCurrentProject();
  const { notifySuccess } = useNotification();
  const invalidateQuery = useQueryInvalidator();

  const [isEditing, setIsEditing] = React.useState(false);
  const [isDeleting, setIsDeleting] = React.useState(false);

  const { mutate: deleteExpense, isLoading } = useDeleteExpenseMutation({
    onSuccess: () => {
      notifySuccess(formatMessage({ id: 'Expense deleted successfully' }));
      invalidateQuery(useExpensesQuery, { projectId });
      invalidateQuery(useGetProjectBudgetQuery, { id: projectId });
    }
  });

  const onDelete = () => {
    deleteExpense({
      input: {
        projectId,
        id: expense.id,
      }
    });
  };

  return <>
    <ActionMenu>
      <MenuItem onClick={() => setIsEditing(true)}>
        <ListItemIcon>
          <Edit />
        </ListItemIcon>
        {formatMessage({ id: 'Edit' })}
      </MenuItem>

      <MenuItem onClick={() => setIsDeleting(true)}>
        <ListItemIcon>
          <Delete />
        </ListItemIcon>
        {formatMessage({ id: 'Delete' })}
      </MenuItem>
    </ActionMenu>

    <ConfirmDialog
      open={isDeleting}
      onCancel={() => setIsDeleting(false)}
      onConfirm={onDelete}
      title={formatMessage({ id: 'Delete expense' })}
      confirmColor='error'
      confirmText={formatMessage({ id: 'Delete' })}
      content={formatMessage({ id: 'Are you sure you want to delete this expense?' })}
      loading={isLoading}
    />

    <ExpenseDrawer
      expense={expense}
      open={isEditing}
      onClose={() => setIsEditing(false)}
    />
  </>;
};