import { ActionMenu } from '@components/ActionMenu';
import { useCurrentProject } from '@modules/projects/utils/useCurrentProject';
import { Draw, RemoveCircle } from '@mui/icons-material';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { getTenantIdentifier } from '@utils/getTenantIdentifier';
import { useQueryInvalidator } from '@utils/useQueryInvalidator';
import { ApplicationRole, SecurityGroup, useDeleteSecurityGroupMutation, useSecurityGroupsForProjectQuery } from 'gql/index';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { ConfirmDialog } from '../../../components/ConfirmDialog';
import { useNotification } from '../../../utils/useNotification';
import { SecurityGroupFormDrawer } from './SecurityGroupFormDrawer';

interface Props {
  securityGroup: Pick<SecurityGroup, 'id' | 'name' | 'description' | 'isDefaultProjectAdminGroup'> & {
    roles: Pick<ApplicationRole, 'name'>[];
  };
  actionMenuVariant?: 'squareIcon';
}

export const SecurityGroupActions: React.FC<Props> = ({ securityGroup, actionMenuVariant }) => {
  const { formatMessage } = useIntl();
  const { notifySuccess } = useNotification();
  const invalidateQuery = useQueryInvalidator();
  const navigate = useNavigate();

  const { projectId, canManageProject } = useCurrentProject();

  const [confirmRemoveDialogOpen, setConfirmRemoveDialogOpen] = useState(false);
  const [updateDrawerOpen, setUpdateDrawerOpen] = useState(false);

  const { mutate: deleteSecurityGroup, isLoading } = useDeleteSecurityGroupMutation({
    onSuccess: () => {
      notifySuccess(formatMessage({ id: 'Security group deleted successfully' }));
      invalidateQuery(useSecurityGroupsForProjectQuery, { projectId });
      navigate(`/${getTenantIdentifier()}/projects/${projectId}/permissions/groups`);
    }
  });

  const onDeleteConfirm = () => {
    deleteSecurityGroup({ input: { projectId, groupId: securityGroup.id } });
  };

  const isDefaultProjectAdminGroup = securityGroup.isDefaultProjectAdminGroup;

  if (!canManageProject) {
    return undefined;
  }

  return (
    <>
      {!isDefaultProjectAdminGroup && (
        <ActionMenu variant={actionMenuVariant}>
          <MenuItem onClick={() => setUpdateDrawerOpen(true)}>
            <ListItemIcon><Draw /></ListItemIcon>
            <ListItemText primary={formatMessage({ id: 'Edit' })} />
          </MenuItem>

          <MenuItem onClick={() => setConfirmRemoveDialogOpen(true)}>
            <ListItemIcon><RemoveCircle /></ListItemIcon>
            <ListItemText primary={formatMessage({ id: 'Delete' })} />
          </MenuItem>
        </ActionMenu>
      )}

      <SecurityGroupFormDrawer
        open={updateDrawerOpen}
        onClose={() => setUpdateDrawerOpen(false)}
        securityGroup={securityGroup}
      />

      <ConfirmDialog
        open={confirmRemoveDialogOpen}
        title={formatMessage({ id: 'Delete security group' })}
        content={formatMessage({ id: 'This security group will be deleted, and all members unassigned. Are you sure?' })}
        confirmText={formatMessage({ id: 'Delete' })}
        confirmColor='error'
        onCancel={() => setConfirmRemoveDialogOpen(false)}
        onConfirm={onDeleteConfirm}
        loading={isLoading}
      />
    </>
  );
};