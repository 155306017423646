import { Draggable } from '@hello-pangea/dnd';
import { Delete, DragHandle } from '@mui/icons-material';
import { IconButton, ListItemIcon, Stack, TextField, styled } from '@mui/material';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormEditorFieldValues } from '../../../types';

interface Props {
  index: number;
  uniqueId: string;
  onDelete: () => void;
}

const DragStack = styled(Stack)({
  flexDirection: 'row',
  alignItems: 'center',
  '&:hover .showOnHoverDeleteOption': {
    visibility: 'visible'
  }
});

export const DraggableSelectionOption: React.FC<Props> = ({ index, uniqueId, onDelete }) => {
  const { control } = useFormContext<FormEditorFieldValues>();

  return (
    <Draggable index={index} draggableId={uniqueId}>{provided => (
      <DragStack {...provided.draggableProps} ref={provided.innerRef}>
        <ListItemIcon {...provided.dragHandleProps}>
          <DragHandle />
        </ListItemIcon>

        <Controller
          control={control}
          name={`selectionOptions.${index}.label`}
          render={({ field }) => (
            <TextField fullWidth {...field} />
          )}
        />

        <IconButton onClick={onDelete} className='showOnHoverDeleteOption' sx={{ ml: 1, visibility: 'hidden' }}>
          <Delete />
        </IconButton>
      </DragStack>
    )}</Draggable>
  );
};