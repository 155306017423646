import { MobileProjectTopBar } from '@components/Layout/MobileProjectTopBar';
import { Box, Stack } from '@mui/material';
import { useGetMe } from '@utils/useGetMe';
import { useResponsive } from '@utils/useResponsive';
import React from 'react';
import { Outlet } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { isProjectNavigationMenuCollapsedState } from '../atoms';
import { projectCollapsedMenuWidth, projectExpandedMenuWidth } from '../components/ProjectNavigation';
import { ProjectNavigationMenu } from '../components/ProjectNavigation/ProjectNavigationMenu';
import { ProjectNavigationMenuSpacer } from '../components/ProjectNavigation/ProjectNavigationMenuSpacer';
import { useCurrentProject } from '../utils/useCurrentProject';

export const ProjectLayout: React.FC = () => {

  const { isMobile } = useResponsive();
  const isNavigationMenuCollapsed = useRecoilValue(isProjectNavigationMenuCollapsedState);

  const { isFetching: isFetchingMe } = useGetMe();
  const { query: { isFetching: isFetchingProject } } = useCurrentProject();

  const isLoading = isFetchingMe || isFetchingProject;

  return <>
    {isMobile && (
      <MobileProjectTopBar loading={isLoading} />
    )}

    <ProjectNavigationMenu />
    <Stack component="main" direction="row" height='100%' overflow='auto'>
      <ProjectNavigationMenuSpacer />
      <Box sx={{ width: isNavigationMenuCollapsed ? `${projectCollapsedMenuWidth}px` : `${projectExpandedMenuWidth}px`, flexGrow: 1 }}>
        <Outlet />
      </Box>
    </Stack>
  </>;
};
