import { FormDrawer } from '@components/Drawers/FormDrawer';
import { DrawerProps, MenuItem, Stack, TextField } from '@mui/material';
import { useGetMe } from '@utils/useGetMe';
import { useNotification } from '@utils/useNotification';
import { useQueryInvalidator } from '@utils/useQueryInvalidator';
import { isExternalUser } from '@utils/userUtils';
import { useEditMemberSecurityGroupMutation, useGetProjectMembersQuery, useGetProjectQuery, useGetUserQuery, useSecurityGroupQuery, useSecurityGroupsForProjectQuery } from 'gql/index';
import { useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useCurrentProject } from '../../utils/useCurrentProject';

interface OwnProps extends Omit<DrawerProps, 'onSave'> {
  userId: string;
}

interface EditMemberSecurityGroupValue {
  securityGroupId: string;
}

const defaultValues: EditMemberSecurityGroupValue = {
  securityGroupId: '',
};

export const EditMemberSecurityGroupDrawer: React.FC<OwnProps> = ({ userId, ...drawerProps }) => {
  const { formatMessage } = useIntl();
  const invalidateQuery = useQueryInvalidator();
  const { notifySuccess } = useNotification();

  const { me } = useGetMe();

  const { projectId } = useCurrentProject();

  const form = useForm<EditMemberSecurityGroupValue>({ defaultValues });
  const { reset, control, handleSubmit, formState: { isDirty } } = form;

  const { data: securityGroupsData } = useSecurityGroupsForProjectQuery({ projectId }, { select: d => d.securityGroupsForProject, enabled: drawerProps.open });
  const { data: user } = useGetUserQuery({ id: userId }, { select: d => d.user, enabled: drawerProps.open });

  const securityGroupId = useMemo(() => {
    return securityGroupsData?.find(g => user && g.members.find(m => m.id == user.id))?.id;
  }, [user, securityGroupsData]);

  const securityGroups = useMemo(() => {
    return securityGroupsData?.filter(g => user && !isExternalUser(user) ? g : !g.isDefaultProjectAdminGroup);
  }, [securityGroupsData, user]);

  useEffect(() => {
    if (securityGroupId) {
      reset({ ...defaultValues, securityGroupId });
    } else {
      reset(defaultValues);
    }
  }, [reset, securityGroupId, drawerProps.open]);

  const editSecurityGroupMutation = useEditMemberSecurityGroupMutation({
    onSuccess: () => {
      notifySuccess(formatMessage({ id: 'Security group updated successfully' }));
      invalidateQuery(useGetProjectQuery, { id: projectId });
      invalidateQuery(useSecurityGroupsForProjectQuery);
      invalidateQuery(useGetProjectMembersQuery, { projectId });
      invalidateQuery(useSecurityGroupQuery, { securityGroupId: securityGroupId });
      drawerProps.onClose?.({}, 'backdropClick');
    }
  });

  const onSubmit = (values: EditMemberSecurityGroupValue) => {
    if (!values.securityGroupId) return;
    editSecurityGroupMutation.mutate({
      input: {
        projectId,
        userId,
        securityGroupId: values.securityGroupId
      }
    });
  };

  return (
    <FormDrawer
      {...drawerProps}
      isSubmitting={editSecurityGroupMutation.isLoading}
      isFormDirty={isDirty}
      header={formatMessage({ id: 'Edit security group' })}
      showFooter
      onSave={handleSubmit(onSubmit)}
    >
      <Stack spacing={2} component='form' noValidate autoComplete='off' p={{ xs: 1, md: 2 }}>
        <Controller
          control={control}
          name='securityGroupId'
          rules={{
            required: formatMessage({ id: 'This field is required' }),
            validate: (id: string) => {
              const securityGroup = securityGroups?.find(sg => sg.id === id);
              if (!securityGroup) return;

              if (securityGroup.isDefaultProjectAdminGroup && user && isExternalUser(user)) {
                return formatMessage({ id: 'You are not allowed to invite users to this security group' });
              }
            }
          }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              select
              label={formatMessage({ id: 'Security group' })}
              {...field}
              disabled={me?.id == userId}
              error={Boolean(error)}
              helperText={error?.message}
            >
              {securityGroups?.map(securityGroup => (
                <MenuItem key={securityGroup.id} value={securityGroup.id}>
                  {securityGroup.name}
                </MenuItem>
              ))}
            </TextField>
          )}
        />
      </Stack>
    </FormDrawer>
  );
};