import { Paper, Stack } from '@mui/material';
import { useGetDocumentQuery } from 'gql/index';
import React from 'react';
import { PropertyGroupSkeleton } from '../../../../../components/DataDisplay/PropertyGroupSkeleton';
import { useCurrentProject } from '../../../../projects/utils/useCurrentProject';
import { DocumentBasicProperties } from './DocumentBasicProperties';
import { DocumentPermissions } from './DocumentPermissions';
import { DocumentSignatureDetails } from './DocumentSignatureDetails';

interface Props {
  documentId: number;
}

export const DocumentPropertiesList: React.FC<Props> = ({ documentId }) => {
  const { data: document } = useGetDocumentQuery({ id: documentId }, { select: d => d.document });
  const { canManageDocuments, isTemplate } = useCurrentProject();

  if (document == null) {
    return (
      <Stack p={1} gap={2}>
        <Paper sx={{ p: 2 }}>
          <PropertyGroupSkeleton propertiesCount={3} />
        </Paper>

        <Paper sx={{ p: 2 }}>
          <PropertyGroupSkeleton propertiesCount={2} />
        </Paper>
      </Stack>
    );
  }

  if (!document) return;

  return (
    <Stack p={1} gap={2}>
      <Paper sx={{ p: 2 }}>
        <DocumentBasicProperties document={document} />
      </Paper>

      {!isTemplate && (
        <Paper sx={{ p: 2 }}>
          <DocumentSignatureDetails document={document} />
        </Paper>
      )}

      {canManageDocuments && document && (
        <DocumentPermissions document={document} />
      )}
    </Stack>
  );
};