import { PageContainer } from '@components/Layout/PageContainer';
import { PageTitle } from '@components/Layout/PageTitle';
import { useCurrentProject } from '@modules/projects/utils/useCurrentProject';
import { Stop } from '@mui/icons-material';
import { Timeline, timelineConnectorClasses, timelineItemClasses, timelineSeparatorClasses } from '@mui/lab';
import { Alert, AlertTitle, Container, Stack, styled, Typography } from '@mui/material';
import { useActionsOfWorkflowInstanceQuery, useWorkflowInstanceQuery, WorkFlowStatus } from 'gql/index';
import _ from 'lodash';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { ActionComponent } from '../components/WorflowInstance/Actions/ActionComponent';
import { WorkflowInstanceActions } from '../components/WorflowInstance/WorkflowInstanceActions';
import { WorkflowStatusChip } from '../components/WorkflowStatusChip';

const StyledTimeline = styled(Timeline)(() => ({
  p: 0,
  [`& .${timelineItemClasses.root}:before`]: {
    display: 'none',
  },
  [`& .${timelineSeparatorClasses.root}`]: {
    flex: 1,
  },
  [`& .${timelineConnectorClasses.root}`]: {
    minHeight: 40,
    mt: 2
  }
}));

export const WorkflowPage: React.FC = () => {
  const { workflowId: workflowIdString } = useParams();
  const workflowId = Number(workflowIdString) || 0;

  const { projectId } = useCurrentProject();
  const { data: workflow, isFetching: isLoadingWorkflow } = useWorkflowInstanceQuery({ projectId, workflowId }, { select: d => d.projectWorkflow });
  const { data: actions, isFetching: isLoadingActions } = useActionsOfWorkflowInstanceQuery({ workflowId }, { select: d => d.workflowActionConfigurationsOfWorkflow });

  const isLoading = isLoadingActions || isLoadingWorkflow;
  const { formatMessage } = useIntl();

  return <Stack height={'100%'}>
    <PageTitle

      title={
        <Stack direction='row' alignItems='center' width='100%' spacing={2}>
          <Typography variant='h5'>{workflow?.name}</Typography>
          {workflow?.workFlowStatus && <WorkflowStatusChip status={workflow?.workFlowStatus} />}
        </Stack>}
      loading={isLoadingWorkflow || isLoadingActions}
      actionButton={workflow && (
        <WorkflowInstanceActions projectWorkflow={workflow} />
      )}
    />
    <PageContainer fullPageLoading={isLoading}>

      <Container maxWidth='sm'>
        {workflow?.workFlowStatus == WorkFlowStatus.Error && (
          <Alert severity='error'>
            <AlertTitle>{formatMessage({ id: 'The workflow encountered an error which prevents it from continuing.' })}</AlertTitle>
            {formatMessage({ id: 'Correlation Id: ' }) + workflow.errorCorrelationId}
          </Alert>
        )}

        {workflow?.workFlowStatus === WorkFlowStatus.Stopped && (
          <Alert severity='warning' icon={<Stop />}>
            {formatMessage({ id: 'This workflow has been stopped by {fullName}. Actions cannot be submitted.' }, { fullName: workflow.stoppedBy?.fullName })}
          </Alert>
        )}

        <StyledTimeline>
          {actions && _.orderBy(actions, p => p.order).map((a) =>
            <ActionComponent key={a.id} workflowId={workflowId} action={a} />
          )}
        </StyledTimeline>
      </Container>
    </PageContainer>
  </Stack>;
};