import { ReactComponent as Subscribe } from '@assets/images/undraw_subscriptions.svg';
import { ResponsiveDialog } from '@components/Dialogs/ResponsiveDialog';
import { Button, Stack, Typography, useTheme } from '@mui/material';
import { getTenantIdentifier } from '@utils/getTenantIdentifier';
import React from 'react';
import { useIntl } from 'react-intl';

export const InactiveSubscriptionModal: React.FC = () => {
  const { formatMessage } = useIntl();
  const onManageSubscriptionClicked = async () => {
    location.replace(`/${getTenantIdentifier()}/api/Subscription/Manage`);
  };

  const theme = useTheme();

  return (
    <ResponsiveDialog open sx={{ backdropFilter: 'blur(3px)' }} maxWidth='md'>
      <Stack alignItems='center' justifyContent='center' p={4} gap={2}>
        <Subscribe fill={theme.palette.primary.main} style={{ width: 'auto', height: 'auto', maxWidth: '300px' }} />

        <Typography mt={4} variant='h4' color='primary.main'>
          {formatMessage({ id: 'Your subscription is paused until a paid plan is selected.' })}
        </Typography>

        <Typography >
          {formatMessage({ id: 'Upgrade to a paid plan to continue using the app.' })}
        </Typography>

        <Button
          onClick={onManageSubscriptionClicked}
          sx={{ mt: 2 }}
          variant='contained'>
          {formatMessage({ id: 'Buy now' })}
        </Button>
      </Stack>
    </ResponsiveDialog>
  );
};