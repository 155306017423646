import { TreeDataGroupingCell } from '@components/TreeDataGroupingCell';
import { useFolderName } from '@modules/folders/utils/folderUtils';
import { Typography } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { FolderFragmentFragment } from 'gql/index';

type Folder = Pick<FolderFragmentFragment, 'id' | 'name' | 'folderType'>;

type Props = GridRenderCellParams<Folder>;

export const FolderTreeGroupingCell: React.FC<Props> = (props) => {
  const { row } = props;
  const { getFolderName } = useFolderName();

  return (
    <TreeDataGroupingCell {...props}>
      <Typography>
        {getFolderName(row)}
      </Typography>
    </TreeDataGroupingCell>
  );
};