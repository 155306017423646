import { DataTable } from '@components/DataTable';
import { FolderTreeGroupingCell } from '@modules/projects/components/TaskImportDrawer/FoldersStep/FolderTreeGroupingCell';
import { useCurrentProject } from '@modules/projects/utils/useCurrentProject';
import { fixHierarchyPaths } from '@modules/tasks/hierarchyHelpers';
import { Box } from '@mui/material';
import { DataGridProProps } from '@mui/x-data-grid-pro';
import { useProjectFoldersQuery } from 'gql/index';
import React, { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';

interface Props {
  selectedFolderId: number | null;
  onFolderSelected: (documentId: number | null) => void;
}

export const FolderPicker: React.FC<Props> = ({ onFolderSelected, selectedFolderId }) => {
  const { projectId } = useCurrentProject();
  const { data: folders } = useProjectFoldersQuery({ projectId: projectId }, { select: p => p.projectFolders });
  const { formatMessage } = useIntl();

  const hierarchyFixedFolders = useMemo(() => fixHierarchyPaths(folders, f => f.path), [folders]);

  const currentValue = selectedFolderId ? Number(selectedFolderId) : null;

  const groupingColDef: DataGridProProps<typeof hierarchyFixedFolders[0]>['groupingColDef'] = {
    headerName: formatMessage({ id: 'Name' }),
    flex: 1,
    renderCell: props => (
      <Box sx={{ cursor: 'pointer' }} width={'100%'}>
        <FolderTreeGroupingCell {...props} />
      </Box>
    )
  };

  const getTreeDataPath = useCallback((row: typeof hierarchyFixedFolders[0]) => row.hierarchyPath.split('/').filter(Boolean), []);

  return (
    <DataTable<typeof hierarchyFixedFolders[0]>
      autoHeight
      columns={[]}
      rows={hierarchyFixedFolders}

      rowSelection
      disableRowSelectionOnClick={false}
      rowSelectionModel={currentValue ? [currentValue] : []}
      onRowSelectionModelChange={(model) => onFolderSelected(Number(model.at(0)))}

      treeData
      groupingColDef={groupingColDef}
      defaultGroupingExpansionDepth={0}
      getTreeDataPath={getTreeDataPath}

      noDataMessage={formatMessage({ id: 'No valid folders were found, or you do not have access to any folders.' })}
    />
  );

};