import { CancelButton } from '@components/Buttons/CancelButton';
import { LoadingButton } from '@mui/lab';
import { FormHelperText, Stack, TextField } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { signaturePadHeight } from '.';

interface Props {
  onClose?: () => void;
  loading?: boolean;
  onSignatureComplete?: (signature: string) => void;
}

interface FormValues {
  signature: string;
}

export const TextualSignature: React.FC<Props> = ({ onClose, loading, onSignatureComplete }) => {
  const { formatMessage } = useIntl();

  const { control, handleSubmit, formState: { errors } } = useForm<FormValues>();
  const signatureError = errors.signature?.message;

  const onSubmit = ({ signature }: FormValues) => {
    onSignatureComplete?.(signature);
  };

  return <>
    <Stack sx={{
      position: 'relative',
      height: signaturePadHeight,
      backgroundColor: 'white',
      border: 1,
      borderColor: signatureError ? 'error.main' : 'grey.400'
    }}>
      <Controller
        name='signature'
        control={control}
        rules={{ required: formatMessage({ id: 'Please enter your signature.' }) }}
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            autoFocus
            variant='standard'
            sx={{
              position: 'absolute',
              left: '10%',
              right: '10%',
              bottom: '25%',
              borderBottom: 1,
              borderColor: error ? 'error.main' : 'black',
              'input': {
                color: 'black',
                fontFamily: 'Dancing Script',
                fontSize: t => t.typography.h3.fontSize,
              }
            }}
          />
        )}
      />
    </Stack>

    {signatureError && (
      <FormHelperText error>
        {signatureError}
      </FormHelperText>
    )}

    <Stack direction='row' justifyContent='space-between' flexWrap='wrap' gap={2} px={{ xs: 2, md: 0 }}>
      <CancelButton onClick={onClose} disabled={loading} />

      <LoadingButton variant='contained' onClick={handleSubmit(onSubmit)} loading={loading}>
        {formatMessage({ id: 'Accept and Sign' })}
      </LoadingButton>
    </Stack>
  </>;
};