import { Stack, useTheme } from '@mui/material';
import { DropzoneRootProps } from 'react-dropzone';

interface Props {
  isDragActive?: boolean,
  dropZoneRootProps?: DropzoneRootProps,
  error?: boolean,
  children?: React.ReactNode;
}

export const UploadZoneRoot: React.FC<Props> = ({ isDragActive, dropZoneRootProps, error, children }) => {
  const { palette } = useTheme();

  const getBorderColor = () => {
    if (isDragActive) return palette.primary.main;
    if (error) return palette.error.main;
    return palette.action.disabled;
  };

  return <div>
    <Stack {...dropZoneRootProps} sx={{
      px: 2,
      py: 8,
      borderStyle: 'dashed',
      borderColor: getBorderColor(),
      justifyContent: 'center',
      alignItems: 'center',
      gap: 2
    }}>
      {children}
    </Stack>
  </div>;
};