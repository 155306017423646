import { DataTable } from '@components/DataTable';
import { UserAvatar } from '@components/UserAvatar/UserAvatar';
import { Chip, ListItemText, Link as MuiLink } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import { MeetingFragmentFragment } from 'gql/index';
import React from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { MeetingActions } from './MeetingActions';


interface Props {
  meetings: MeetingFragmentFragment[];
  loading?: boolean;
}

export const MeetingRequestsTable: React.FC<Props> = ({ meetings, loading }) => {
  const { formatMessage } = useIntl();

  const columns: GridColDef<MeetingFragmentFragment>[] = [
    {
      field: 'name',
      flex: 2,
      minWidth: 150,
      headerName: formatMessage({ id: 'Name' }),
      valueGetter: ({ row }) => row.name,
      renderCell: ({ row }) => (
        <MuiLink component={Link} to={row.id.toString()}>
          <ListItemText
            sx={{ p: 2 }}
            primary={row.name}
            secondary={row.description}
          />
        </MuiLink>
      )
    },

    {
      field: 'duration',
      flex: 1,
      minWidth: 100,
      headerName: formatMessage({ id: 'Duration' }),
      valueGetter: ({ row }) => dayjs.duration(row.meetingType?.duration).humanize()
    },
    {
      field: 'createdBy',
      flex: 1,
      minWidth: 150,
      headerName: formatMessage({ id: 'Created by' }),
      valueGetter: ({ row }) => row.createdBy?.fullName,
      renderCell: ({ value }) => value && <Chip avatar={<UserAvatar displayName={value} />} label={value} />
    },
    {
      field: 'actions',
      headerName: '',
      sortable: false,
      disableColumnMenu: true,
      disableExport: true,
      maxWidth: 64,
      renderCell: ({ row }) => (
        <MeetingActions meeting={row} />
      )
    }
  ];

  return (
    <DataTable
      disableColumnMenu
      loading={loading}
      columns={columns}
      rows={meetings ?? []}

      noDataMessage={formatMessage({ id: 'No meeting requests have been created yet.' })}
    />
  );
};