
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Alert, Stack, Typography } from '@mui/material';
import { AxisConfig } from '@mui/x-charts';
import { BarChart } from '@mui/x-charts/BarChart';
import { MakeOptional } from '@mui/x-date-pickers/internals/models/helpers';
import { useMoneyFormatter } from '@utils/numberUtils';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useRecoilState } from 'recoil';
import { useGetProjectBudgetQuery } from '../../../gql';
import { useCurrentProject } from '../../projects/utils/useCurrentProject';
import { budgetChartState } from '../atoms';

export const BudgetChart = () => {
  const { projectId, canViewBudgetAndExpenses } = useCurrentProject();
  const { data: budget } = useGetProjectBudgetQuery({ id: projectId }, { select: d => d.projectBudget });
  const [isExpanded, setIsExpanded] = useRecoilState(budgetChartState);

  const budgetDetails = {
    initialBudget: budget?.totalBudgetedAmount,
    budgetIncludingOverrun: budget?.totalBudgetedAmount + budget?.budgetOverrun,
    budgetedExpenses: budget?.totalExpendedAmount - budget?.budgetOverrun,
    remainingAmount: budget?.totalBudgetedAmount + budget?.budgetOverrun - budget?.totalExpendedAmount,
    budgetOverrun: budget?.budgetOverrun
  };

  const { formatMessage } = useIntl();

  const formatMoney = useMoneyFormatter();
  const xAxis: MakeOptional<AxisConfig, 'id'> = useMemo(() => ({
    label: formatMessage({ id: 'Budget' }),
    scaleType: 'band' as const,
    data: [''],
    tickSize: 2,

  }), [formatMessage]);

  return <Stack gap={2}>
    {!canViewBudgetAndExpenses && <Alert severity='warning'>{formatMessage({ id: 'You have not been granted access to view expenses. You may contact the project administrator to request access.' })}</Alert>}

    <Accordion expanded={isExpanded} onChange={() => setIsExpanded((prev) => !prev)}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="budget-content"
        id="budget-header">
        <Stack gap={2}>
          {(isExpanded || budgetDetails.budgetIncludingOverrun <= budgetDetails.initialBudget) &&
            <span>
              <Typography variant='h5' display={'inline'}>{formatMessage({ id: 'Initial budget: ' })}</Typography>
              <Typography variant='h5' color={'primary'} display={'inline'}>{formatMoney(budgetDetails.initialBudget, false)}</Typography>
            </span>
          }
          {budgetDetails.budgetIncludingOverrun > budgetDetails.initialBudget && <span>
            <Typography variant='h5' display={'inline'}>{formatMessage({ id: 'Budget (including overrun): ' })}</Typography>
            <Typography variant='h5' color={'warning.main'} display={'inline'}>{formatMoney(budgetDetails.budgetIncludingOverrun, false)}</Typography>
          </span>}
        </Stack>
      </AccordionSummary>
      <AccordionDetails>

        <BarChart
          sx={{ padding: 1 }}
          axisHighlight={{ y: 'line' }}
          slotProps={{

            bar: {
              style: { width: 80 },
            },

            legend: {
              direction: 'column',
              position: { horizontal: 'right', vertical: 'middle' },
            },

          }}
          xAxis={[{ ...xAxis }]}

          series={[
            { data: [budgetDetails.budgetedExpenses], color: '#3498db', valueFormatter: p => formatMoney(p, false), stack: 'A', label: formatMessage({ id: 'Budgeted expenses' }) },
            { data: [budgetDetails.remainingAmount], color: '#2ecc71', valueFormatter: p => formatMoney(p, false), stack: 'A', label: formatMessage({ id: 'Remaining budget' }) },
            { data: [budgetDetails.budgetOverrun], color: '#e74c3c', valueFormatter: p => formatMoney(p, false), stack: 'A', label: formatMessage({ id: 'Budget overrun' }) },

          ]}

          width={450}
          height={250}
        />
      </AccordionDetails>
    </Accordion>
  </Stack>;
};