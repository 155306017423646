import { FormDrawer } from '@components/Drawers/FormDrawer';
import { DrawerProps, Stack, TextField } from '@mui/material';
import { useNotification } from '@utils/useNotification';
import { useQueryInvalidator } from '@utils/useQueryInvalidator';
import { useValidationRules } from '@utils/useValidationRules';
import { useEditProjectMutation, useProjectDocumentsQuery, useProjectFormsQuery } from 'gql/index';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useCurrentProject } from '../utils/useCurrentProject';

interface EditProjectValues {
  name: string,
  description?: string;
}

const defaultValues: EditProjectValues = {
  name: '',
  description: ''
};

export const EditProjectDrawer: React.FC<DrawerProps> = (drawerProps) => {
  const { formatMessage } = useIntl();
  const invalidateQuery = useQueryInvalidator();
  const { notifySuccess } = useNotification();
  const { notEmpty } = useValidationRules();

  const form = useForm<EditProjectValues>();
  const { reset, control, handleSubmit, formState: { isDirty } } = form;

  const { projectId, name, description, query: { refetch } } = useCurrentProject();

  useEffect(() => {
    if (projectId) {
      reset({
        name,
        description: description != null ? description : undefined
      });
    } else {
      reset(defaultValues);
    }
  }, [reset, projectId, drawerProps.open, name, description]);

  const editProjectMutation = useEditProjectMutation({
    onSuccess: () => {
      refetch();
      notifySuccess(formatMessage({ id: 'Project details Updated successfully' }));
      invalidateQuery(useProjectDocumentsQuery, { projectId });
      invalidateQuery(useProjectFormsQuery, { projectId });
      drawerProps.onClose?.({}, 'backdropClick');
    }
  });

  const onSubmit = (values: EditProjectValues) => {
    editProjectMutation.mutate({
      input: {
        projectId,
        ...values
      }
    });
  };

  return <FormDrawer
    {...drawerProps}
    isSubmitting={editProjectMutation.isLoading}
    isFormDirty={isDirty}
    header={formatMessage({ id: 'Edit project details' })}
    onSave={handleSubmit(onSubmit)}
    showFooter
  >
    <Stack spacing={2} p={{ xs: 1, md: 2 }}>
      <Controller
        control={control}
        name='name'
        rules={{
          required: formatMessage({ id: 'This field is required' }),
          validate: notEmpty
        }}
        render={({ field, fieldState: { error } }) => (
          <TextField
            label={formatMessage({ id: 'Name' })}
            required
            error={Boolean(error)}
            helperText={error?.message}
            {...field}
          />
        )}
      />

      <Controller
        control={control}
        name='description'
        render={({ field, fieldState: { error } }) => (
          <TextField
            label={formatMessage({ id: 'Description' })}
            error={Boolean(error)}
            helperText={error?.message}
            {...field}
          />
        )}
      />
    </Stack>
  </FormDrawer>;
};