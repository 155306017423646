import { FormDialog } from '@components/Dialogs/FormDialog';
import { useCurrentProject } from '@modules/projects/utils/useCurrentProject';
import { SecurityGroupPrincipal, UserPrincipal } from '@modules/users/components/types';
import { CircularProgress, DialogProps, Typography } from '@mui/material';
import { useNotification } from '@utils/useNotification';
import { useQueryInvalidator } from '@utils/useQueryInvalidator';
import { AllowedRespondents, FormType, PrincipalType, UpdateFormSettingsInput, useGetProjectMembersQuery, useProjectFormDefinitionQuery, useProjectFormOverviewsQuery, useProjectFormQuery, useProjectFormSettingsQuery, useProjectFormsQuery, useProjectTasksQuery, usePublishFormMutation, useUpdateFormSettingsMutation } from 'gql/index';
import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { FormSettings } from '../FormSettings/FormSettings';
import { FormSettingsType } from '../FormSettings/types';

interface Props extends DialogProps {
  formId: number;
}


export const PublishFormDialog: React.FC<Props> = ({ formId, ...dialogProps }) => {
  const { formatMessage } = useIntl();
  const { projectId } = useCurrentProject();
  const invalidateQuery = useQueryInvalidator();
  const { notifySuccess } = useNotification();

  const { data: formSettings, isFetching: isFetchingSettings } = useProjectFormSettingsQuery({ formId: formId }, { select: p => p.projectFormSettings });
  const { mutate: updateFormSettings, isLoading: isUpdatingSettings } = useUpdateFormSettingsMutation();

  const { mutate: publishForm, isLoading: isPublishingForm } = usePublishFormMutation({
    onSuccess: () => {
      invalidateQuery(useGetProjectMembersQuery, { projectId });
      invalidateQuery(useProjectFormsQuery, { projectId });
      invalidateQuery(useProjectFormQuery, { formId: formId });
      invalidateQuery(useProjectFormDefinitionQuery, { formId: formId });
      invalidateQuery(useProjectTasksQuery, { projectId });
      invalidateQuery(useProjectFormOverviewsQuery, { projectId });
      notifySuccess(formatMessage({ id: 'Form published successfully' }));
      dialogProps.onClose && dialogProps.onClose({}, 'backdropClick');
    }
  });

  const onSubmit = (formSettings: FormSettingsType) => {
    const input: UpdateFormSettingsInput = {
      projectId: projectId,
      projectFormId: formId,
      allowedRespondents: formSettings.allowedResponders,
      allowedSecurityGroupsRespondents: formSettings.allowedResponders === AllowedRespondents.SpecificUsersOrGroups
        ? formSettings.specificResponders.filter(p => p.principalType === PrincipalType.SecurityGroup).map(p => p.id)
        : [],
      allowedUsersRespondents: formSettings.allowedResponders === AllowedRespondents.SpecificUsersOrGroups
        ? formSettings.specificResponders.filter(p => p.principalType === PrincipalType.User).map(p => p.id)
        : []
    };

    updateFormSettings({ input }, {
      onSuccess: () => {
        invalidateQuery(useProjectFormSettingsQuery, { formId: formId });
        publishForm({
          input: {
            projectId: projectId,
            projectFormId: formId,
          }
        });
      }
    });
  };

  const form = useForm<FormSettingsType>({
    defaultValues: {
      allowedResponders: AllowedRespondents.SpecificUsersOrGroups,
      formType: FormType.CollectResponse,
    }
  });

  useEffect(() => {
    if (formSettings) {
      const defaultValues: FormSettingsType = ({
        allowedResponders: formSettings.allowedRespondents,
        formType: formSettings.formType,
        specificResponders: [
          ...formSettings.allowedSecurityGroupsRespondents.map<SecurityGroupPrincipal>(p => ({ id: p.id, name: p.name, principalType: PrincipalType.SecurityGroup })),
          ...formSettings.allowedUsersRespondents.map<UserPrincipal>(p => ({ ...p, principalType: PrincipalType.User }))
        ],
      });

      form.reset(defaultValues);
    }
  }, [form, formSettings]);

  return (
    <FormDialog
      title={formatMessage({ id: 'Review settings' })}
      {...dialogProps}
      maxWidth='xs'
      onSubmit={form.handleSubmit(onSubmit)}
      isSubmitting={isUpdatingSettings || isPublishingForm}
    >
      <FormProvider {...form}>
        <Typography>{formatMessage({ id: 'Once published this self-serve form will be available to all allowed responders.' })}</Typography>

        {isFetchingSettings && <CircularProgress sx={{ alignSelf: 'center' }} />}

        {!isFetchingSettings && <FormSettings />}
      </FormProvider >
    </FormDialog >
  );
};