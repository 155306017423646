import { LoadingScreen } from '@components/LoadingScreen';
import { useCurrentProject } from '@modules/projects/utils/useCurrentProject';
import { getTenantIdentifier } from '@utils/getTenantIdentifier';
import { ReactNode, useMemo } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

type UseCurrentProjectReturn = ReturnType<typeof useCurrentProject>;

type Props = {
  rule: (project: UseCurrentProjectReturn) => boolean;
  children?: ReactNode;
};

export const ProtectedProjectRoute = ({ rule, children }: Props) => {
  const project = useCurrentProject();

  const isAuthorized = useMemo(() => rule(project), [project, rule]);

  if (project.query.isFetching) {
    return <LoadingScreen />;
  }

  if (isAuthorized === false) {
    return <Navigate to={`/${getTenantIdentifier()}/projects/${project.projectId}`} />;
  }

  return children ?? <Outlet />;
};