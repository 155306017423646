import { FormDrawer } from '@components/Drawers/FormDrawer';
import { useCurrentProject } from '@modules/projects/utils/useCurrentProject';
import { DrawerProps, Stack, TextField } from '@mui/material';
import { useNotification } from '@utils/useNotification';
import { useQueryInvalidator } from '@utils/useQueryInvalidator';
import { useValidationRules } from '@utils/useValidationRules';
import { SecurityGroup, useAddSecurityGroupMutation, useSecurityGroupQuery, useSecurityGroupsForProjectQuery, useUpdateSecurityGroupMutation } from 'gql/index';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';

interface Props extends DrawerProps {
  securityGroup?: Pick<SecurityGroup, 'id' | 'name' | 'description'>;
}

interface FormValues {
  name: string;
  description: string;
}

const defaultValues: FormValues = {
  name: '',
  description: ''
};

export const SecurityGroupFormDrawer: React.FC<Props> = ({ securityGroup, ...drawerProps }) => {
  const { formatMessage } = useIntl();
  const { notifySuccess } = useNotification();
  const { notEmpty } = useValidationRules();
  const invalidateQuery = useQueryInvalidator();

  const { projectId } = useCurrentProject();

  const { reset, handleSubmit, control, formState: { isDirty } } = useForm<FormValues>({ defaultValues });

  useEffect(() => {
    if (securityGroup) {
      reset({
        name: securityGroup.name,
        description: securityGroup.description ?? ''
      });
    } else {
      reset(defaultValues);
    }
  }, [reset, drawerProps.open, securityGroup]);

  const { mutate: addSecurityGroup, isLoading: isAddLoading } = useAddSecurityGroupMutation({
    onSuccess: () => {
      drawerProps.onClose?.({}, 'backdropClick');
      notifySuccess(formatMessage({ id: 'Group added successfully' }));
      invalidateQuery(useSecurityGroupsForProjectQuery, { projectId });
    }
  });

  const { mutate: updateSecurityGroup, isLoading: isUpdateLoading } = useUpdateSecurityGroupMutation({
    onSuccess: () => {
      drawerProps.onClose?.({}, 'backdropClick');
      notifySuccess(formatMessage({ id: 'Group udpated successfully' }));
      invalidateQuery(useSecurityGroupsForProjectQuery, { projectId });
      if (securityGroup) {
        invalidateQuery(useSecurityGroupQuery, { projectId, securityGroupId: securityGroup.id });
      }
    }
  });

  const isLoading = isAddLoading || isUpdateLoading;

  const onSubmit = (values: FormValues) => {
    if (securityGroup) {
      updateSecurityGroup({
        input: {
          projectId,
          groupId: securityGroup.id,
          name: values.name,
          description: values.description
        }
      });
    } else {
      addSecurityGroup({
        input: {
          projectId,
          name: values.name,
          description: values.description
        }
      });
    }
  };

  return (
    <FormDrawer
      isFormDirty={isDirty}
      header={securityGroup ? formatMessage({ id: 'Update group' }) : formatMessage({ id: 'Add group' })}
      showFooter
      isLoading={isLoading}
      onSave={handleSubmit(onSubmit)}
      {...drawerProps}
    >
      <Stack p={{ xs: 1, md: 2 }} gap={3}>
        <Controller
          control={control}
          name='name'
          rules={{ validate: notEmpty }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              required
              label={formatMessage({ id: 'Name' })}
              error={!!error}
              helperText={error?.message}
            />
          )}
        />

        <Controller
          control={control}
          name='description'
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              label={formatMessage({ id: 'Description' })}
              multiline
              rows={3}
              error={!!error}
              helperText={error?.message}
            />
          )}
        />
      </Stack>
    </FormDrawer>
  );
};