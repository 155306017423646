import { ResponsiveDialog } from '@components/Dialogs/ResponsiveDialog';
import { MultiStepForm } from '@components/MultiStepForms/MultiStepForm';
import { useCurrentProject } from '@modules/projects/utils/useCurrentProject';
import { DialogProps } from '@mui/material';
import { getTenantIdentifier } from '@utils/getTenantIdentifier';
import { useQueryInvalidator } from '@utils/useQueryInvalidator';
import { FormType, useAddProjectFormFromTemplateMutation, useAddProjectFormMutation, useProjectFormsQuery } from 'gql/index';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { defaultFormNames } from '../FormEditor/messages';
import { FormTypeStep } from './FormTypeStep';
import { TemplateSelectionStep } from './TemplateSelectionStep';

export type AddFormForm = {
  formType: FormType;
  formSource: 'newForm' | 'template';
  selectedTemplateId?: number;
};

export const AddFormDialog: React.FC<DialogProps> = (props: DialogProps) => {
  const { formatMessage } = useIntl();
  const invalidateQuery = useQueryInvalidator();
  const { projectId } = useCurrentProject();
  const navigate = useNavigate();

  const form = useForm<AddFormForm>({ defaultValues: { formSource: 'newForm' } });

  useEffect(() => {
    if (!props.open) {
      form.reset();
    }
  }, [form, props.open]);

  const { mutate: addProjectForm, isLoading: isAddingForm } = useAddProjectFormMutation();
  const { mutate: addProjectFormFromTemplate, isLoading: isAddingFormFromTemplate } = useAddProjectFormFromTemplateMutation();
  const isAdding = isAddingForm || isAddingFormFromTemplate;

  const onSubmit = (values: AddFormForm) => {
    if (values.formSource == 'newForm') {
      addProjectForm({
        input: {
          name: formatMessage(defaultFormNames.form),
          projectId: projectId,
          formType: values.formType
        }
      }, {
        onSuccess: data => {
          invalidateQuery(useProjectFormsQuery, { projectId });
          navigate(`/${getTenantIdentifier()}/projects/${projectId}/form/${data.addProjectForm.projectForm?.id}`);
        }
      });
    } else if (values.selectedTemplateId != null) {
      addProjectFormFromTemplate({
        input: {
          projectId: projectId,
          fromFormTemplateId: values.selectedTemplateId,
          formType: values.formType
        }
      }, {
        onSuccess: data => {
          invalidateQuery(useProjectFormsQuery, { projectId });
          navigate(`/${getTenantIdentifier()}/projects/${projectId}/form/${data.addProjectFormFromTemplate?.projectForm?.id}`);
        }
      });
    }
  };

  return (
    <ResponsiveDialog {...props} maxWidth='md'>
      <FormProvider {...form}>
        <MultiStepForm
          onSubmit={form.handleSubmit(onSubmit)}
          isSubmitting={isAdding}
          steps={[
            { content: ({ nextStep }) => <FormTypeStep onTypeSelected={nextStep} /> },
            { content: () => <TemplateSelectionStep />, }
          ]}
        />
      </FormProvider>
    </ResponsiveDialog>
  );
};