import { Stack, Typography, TypographyVariant } from '@mui/material';
import { SubmissionStatus } from 'gql/index';
import React from 'react';
import { useIntl } from 'react-intl';
import { FormSubmissionStatusIcon, FormSubmissionStatusMessage } from '../types';

interface Props {
  status: SubmissionStatus;
  variant?: TypographyVariant;
  iconOnly?: boolean;
}

export const SubmissionStatusDisplay: React.FC<Props> = ({ status, variant, iconOnly }) => {
  const { formatMessage } = useIntl();

  return (
    <Stack direction='row' alignItems='center' gap={1}>
      {FormSubmissionStatusIcon[status]}

      {!iconOnly && <Typography variant={variant}>
        {formatMessage(FormSubmissionStatusMessage[status])}
      </Typography>}
    </Stack>
  );
};