import { DataTable, DataTableProps } from '@components/DataTable';
import { DownloadLinkButton } from '@components/Download/DownloadLinkButton';
import { UserAvatar } from '@components/UserAvatar/UserAvatar';
import { ChevronRight, ExpandMore } from '@mui/icons-material';
import { ButtonProps, Chip, IconButton, Link as MuiLink, Stack } from '@mui/material';
import { DataGridProProps, GridColDef, GridRenderCellParams, gridFilteredDescendantCountLookupSelector, useGridApiContext, useGridSelector } from '@mui/x-data-grid-pro';
import { getTenantIdentifier } from '@utils/getTenantIdentifier';
import dayjs from 'dayjs';
import { ProjectFormSubmissionsQuery, SubmissionStatus } from 'gql/index';
import React from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { useCurrentProject } from '../../projects/utils/useCurrentProject';
import { SubmissionStatusDisplay } from './FormSubmissions/SubmissionStatusDisplay';
import { SubmissionActions } from './SubmissionActions';
import { FormSubmissionStatusMessage } from './types';

type Submission = ProjectFormSubmissionsQuery['projectFormSubmissions'][number];

type SubmissionWithIndex = Submission & { index: number; };

interface Props extends Omit<DataTableProps<SubmissionRow>, 'columns' | 'rows'> {
  submissions: Submission[];
  hideFormNameColumn?: boolean;
}

const dateTimeValueGetter: GridColDef<SubmissionRow>['valueGetter'] = ({ row }) => {
  if (!row.submittedOn) return undefined;
  const dayjsDate = dayjs(row.submittedOn);
  return dayjsDate.isValid() ? dayjsDate.toDate() : undefined;
};

export interface SubmissionRow {
  id: string;
  formId?: number | null;
  submissionId: number;
  attachmentId?: number;
  path: string[];
  name: string;
  user?: string;
  submittedOn?: string;
  status?: SubmissionStatus;
}

const CustomGridTreeDataGroupingCell = ({ id, field, rowNode, row }: GridRenderCellParams<SubmissionRow>) => {
  const { formatMessage } = useIntl();
  const { projectId } = useCurrentProject();

  const apiRef = useGridApiContext();
  const filteredDescendantCountLookup = useGridSelector(
    apiRef,
    gridFilteredDescendantCountLookupSelector,
  );
  const filteredDescendantCount = filteredDescendantCountLookup[rowNode.id] ?? 0;

  const handleClick: ButtonProps['onClick'] = (event) => {
    if (rowNode.type !== 'group') {
      return;
    }

    apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded);
    apiRef.current.setCellFocus(id, field);
    event.stopPropagation();
  };

  const nameCellNavLink = row.formId
    ? `/${getTenantIdentifier()}/projects/${projectId}/form/${row.formId}/${row.id}`
    : `/${getTenantIdentifier()}/projects/${projectId}/forms/submissions/${row.id}`;

  const isAttachmentRow = rowNode.depth > 0;

  if (isAttachmentRow) {
    const attachmentId = row.id.split('-')[1];

    return (
      <Stack pl={8} direction='row' alignItems='center' gap={1}>
        {row.name}

        <DownloadLinkButton
          size='small'
          variant='IconButton'
          fileName={row.name}
          link={`/${getTenantIdentifier()}/api/Form/Submission/${row.submissionId}/Attachments/${attachmentId}`}
        />
      </Stack>
    );
  }

  const hasNoAttachments = filteredDescendantCount === 0;

  return (
    <Stack direction='row' alignItems='center' gap={1}>
      <IconButton onClick={handleClick} size='small' sx={{ visibility: hasNoAttachments ? 'hidden' : 'visible' }}>
        {'childrenExpanded' in rowNode && rowNode.childrenExpanded ? (
          <ExpandMore />
        ) : (
          <ChevronRight />
        )}
      </IconButton>

      <MuiLink to={nameCellNavLink} component={Link}>
        {row.name}
      </MuiLink>

      {row.status === SubmissionStatus.IsResponding && (
        <Chip variant='filled' color='primary' size='small' label={formatMessage({ id: 'Draft' })} sx={{ ml: 2 }} />
      )}
    </Stack>
  );
};

export const SubmissionsTable: React.FC<Props> = ({ submissions, ...dataTableProps }) => {
  const { formatMessage, formatDate } = useIntl();
  const { isCurrentProjectAdmin } = useCurrentProject();

  const columns: GridColDef<SubmissionRow>[] = [{
    field: 'user',
    headerName: formatMessage({ id: 'Responder' }),
    flex: 1,
    minWidth: 300,
    valueGetter: ({ row }) => row.user,
    renderCell: ({ value }) => value && (
      <Chip icon={<UserAvatar displayName={value} size='small' />} label={value} />
    )
  }, {
    field: 'submittedOn',
    type: 'dateTime',
    flex: 1,
    minWidth: 200,
    headerName: formatMessage({ id: 'Submitted on' }),
    valueGetter: dateTimeValueGetter,
    valueFormatter: ({ value }) => value && formatDate(value, { dateStyle: 'long' })
  }];

  if (isCurrentProjectAdmin) {
    columns.push({
      field: 'status',
      type: 'singleSelect',
      flex: 1,
      minWidth: 200,
      headerName: formatMessage({ id: 'Status' }),
      valueGetter: ({ row }) => row.status && formatMessage(FormSubmissionStatusMessage[row.status]),
      renderCell: ({ row }) => row.status && <SubmissionStatusDisplay status={row.status} />
    });

    columns.push({
      field: 'actions',
      headerName: '',
      disableColumnMenu: true,
      sortable: false,
      maxWidth: 64,
      renderCell: ({ row }) => !row.attachmentId && (
        <SubmissionActions submission={row} />
      )
    });
  }

  const attachmentsRows = submissions
    .flatMap(s => s.attachments.map(a => ({ ...a, submissionId: s.id, projectFormId: s.projectFormId })))
    .map<SubmissionRow>(a => ({
      id: `${a.submissionId}-${a.id}`,
      formId: a.projectFormId,
      submissionId: a.submissionId,
      attachmentId: a.id,
      name: a.fileName,
      path: [a.submissionId.toString(), a.id.toString()]
    }));

  const submissionsRows = submissions
    .map<SubmissionWithIndex>((s, i) => ({ ...s, index: i + 1 }))
    .map<SubmissionRow>(s => ({
      id: s.id.toString(),
      formId: s.projectFormId,
      path: [s.id.toString()],
      submissionId: s.id,
      name: s.projectForm?.formDefinition?.name ?? s.formDefinition?.name ?? '',
      user: s.belongsToUser?.fullName ?? '',
      submittedOn: s.submittedOn,
      status: s.status
    }));

  const rows = [...attachmentsRows, ...submissionsRows];

  const groupingColDef: DataGridProProps['groupingColDef'] = {
    headerName: formatMessage({ id: 'Form' }),
    flex: 3,
    minWidth: 300,
    renderCell: (params) => <CustomGridTreeDataGroupingCell {...params} />,
  };

  return (
    <DataTable<SubmissionRow>
      treeData
      getTreeDataPath={row => row.path}
      groupingColDef={groupingColDef}
      columns={columns}
      rows={rows}
      noDataMessage={formatMessage({ id: 'No submissions yet' })}
      {...dataTableProps}
    />
  );
};