import { Skeleton, Stack } from '@mui/material';
import { useZohoEmbedUrlQuery } from 'gql/index';
import { useIntl } from 'react-intl';

interface OwnProps {
  requestId: string;
  actionId: string;
}
export const ZohoSignaturePad: React.FC<OwnProps> = ({ requestId, actionId }) => {
  const { formatMessage } = useIntl();

  const { data: zohoSignUrl } = useZohoEmbedUrlQuery({ requestId, actionId }, {
    select: d => d.zohoEmbedUrl,
    // The embedUrl has to always be different
    cacheTime: 0
  });

  return (
    <Stack width='100%' height='100%' position='relative'>
      {zohoSignUrl ? <>
        <iframe src={zohoSignUrl} style={{ width: '100%', height: '100%', zIndex: 100 }} />

        <Stack zIndex={50} position='absolute' top='50%' left='50%' sx={{ transform: 'translate(-50%, -50%)' }}>
          {formatMessage({ id: 'If you have finished signing, you may leave this page.' })}
        </Stack>
      </> : (
        <Skeleton width='100%' height='100%' />
      )}
    </Stack>
  );
};