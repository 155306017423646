import { Visibility } from '@mui/icons-material';
import { Stack, TextField, Tooltip, Typography } from '@mui/material';
import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { FormEditorSectionValues } from '../types';

interface Props {
  isActive: boolean;
  onSubmit: (data: FormEditorSectionValues) => void;
  hasVisibilityEffect: boolean;
}

export const FormSectionConfiguration: React.FC<Props> = (props) => {
  const { formatMessage } = useIntl();

  const { control, handleSubmit, register } = useFormContext<FormEditorSectionValues>();

  const name = useWatch({ control, name: 'name' });

  const active = (
    <Stack py={1} px={2}>
      <TextField
        {...register('name')}
        fullWidth
        autoFocus
        label={formatMessage({ id: 'Name' })}
        onKeyDown={e => {
          if (e.key === 'Enter') {
            e.preventDefault();
            handleSubmit(props.onSubmit)();
          }
        }}
      />
    </Stack>
  );

  const inactive = <>
    <Stack direction='row' gap={1} alignItems='center'>
      <Typography variant='h5' p={2} fontStyle={name.trim() ? undefined : 'italic'}>
        {name.trim() || formatMessage({ id: 'Untitled section' })}
      </Typography>

      {props.hasVisibilityEffect && (
        <Tooltip title={formatMessage({ id: 'This field is affected by a visibility condition' })}>
          <Visibility />
        </Tooltip>
      )}
    </Stack>
  </>;

  return (
    <>
      {props.isActive ? active : inactive}
    </>

  );
};