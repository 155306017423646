import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';

const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    connectionString: import.meta.env.VITE_APP_INSIGHTS_CONNECTION_STRING,
    enableAutoRouteTracking: true,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore There is typing issues with the libs that should get fixed in future releases
    extensions: [reactPlugin],
  }
});

export default appInsights;