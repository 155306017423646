import { Add } from '@mui/icons-material';
import { Button } from '@mui/material';
import { GridToolbarContainer } from '@mui/x-data-grid-pro';
import { useIntl } from 'react-intl';

interface EditToolbarProps {
  onAddRow: () => void;
}

export const RevenuesGridToolbar = (props: EditToolbarProps) => {
  const { onAddRow } = props;
  const { formatMessage } = useIntl();
  const handleClick = () => {
    onAddRow();
  };

  return (
    <GridToolbarContainer>
      <Button color="primary" variant='text' startIcon={<Add />} onClick={handleClick}>
        {formatMessage({ id: 'Add rental unit' })}
      </Button>
    </GridToolbarContainer>
  );
};