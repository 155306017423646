import { Delete } from '@mui/icons-material';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { GridColDef, GridColumnMenu, GridColumnMenuProps } from '@mui/x-data-grid';
import { useIntl } from 'react-intl';

interface Props extends GridColumnMenuProps {
  onDelete: (colDef: GridColDef) => void;
}

export const MatrixEditorMenu = (props: Props) => {
  const { formatMessage } = useIntl();
  return (
    <GridColumnMenu
      {...props}
      slots={{
        columnMenuSortItem: null,
        columnMenuFilterItem: null,
        columnMenuColumnsItem: null,
        columnMenuUserItem: () => (
          <MenuItem onClick={() => props.onDelete(props.colDef)}>
            <ListItemIcon>
              <Delete />
            </ListItemIcon>
            <ListItemText>{formatMessage({ id: 'Delete' })}</ListItemText>
          </MenuItem>
        ),
      }}
    />
  );
};
