import { ActionMenu } from '@components/ActionMenu';
import { ConfirmDialog } from '@components/ConfirmDialog';
import { GroupRemove, PersonRemove } from '@mui/icons-material';
import { ListItemIcon, MenuItem } from '@mui/material';
import { useNotification } from '@utils/useNotification';
import { ApplicationUser, RoleAssignment, SecurityGroup, ShareableResourceType, useUnshareOrganizationResourceMutation, useUnshareProjectResourceMutation } from 'gql/index';
import React from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { useShareableResourceInvalidator } from './invalidator';
import { ShareableResource, isOrganizationResourceType } from './types';

interface Props {
  resource: ShareableResource;
  roleAssignment: Pick<RoleAssignment, 'id' | 'isInherited' | 'sharedWithAllProjectMembers'> & {
    user?: Pick<ApplicationUser, 'id'> | null;
    securityGroup?: Pick<SecurityGroup, 'id'> | null;
  };
}

export const RoleAssignmentActions: React.FC<Props> = ({ resource, roleAssignment }) => {
  const { projectId: projectIdString } = useParams();
  const projectId = Number(projectIdString);
  const { formatMessage } = useIntl();
  const { notifySuccess } = useNotification();
  const invalidator = useShareableResourceInvalidator(resource);

  const onSuccess = () => {
    invalidator();
    notifySuccess(formatMessage({ id: 'Assignment removed successfully.' }));
  };
  const { mutate: unshareProjectResource, isLoading: isRemoveRoleAssignmentLoading } = useUnshareProjectResourceMutation({ onSuccess });
  const { mutate: unshareOrganizationResource, isLoading: isUnshareOrganizationResourceLoading } = useUnshareOrganizationResourceMutation({ onSuccess });

  const isLoading = isRemoveRoleAssignmentLoading || isUnshareOrganizationResourceLoading;

  const onUnshare = () => {
    if (!roleAssignment.user?.id && !roleAssignment.securityGroup?.id && !roleAssignment.sharedWithAllProjectMembers) return;

    if (isOrganizationResourceType(resource.resourceType)) {
      unshareOrganizationResource({
        input: {
          roleAssignmentId: roleAssignment.id
        }
      });
    } else {
      unshareProjectResource({
        input: {
          projectId,
          roleAssignmentId: roleAssignment.id
        }
      });
    }

  };

  const [isRemovingAssignment, setIsRemovingAssignment] = React.useState(false);

  if (roleAssignment.isInherited || resource.resourceType === ShareableResourceType.Form) {
    return undefined;
  }

  return <>
    <ActionMenu onClick={(e) => e.preventDefault()}>
      {!roleAssignment.isInherited && (
        <MenuItem onClick={() => setIsRemovingAssignment(true)}>
          <ListItemIcon>
            {roleAssignment.user?.id && (
              <PersonRemove />
            )}
            {(roleAssignment.securityGroup?.id || roleAssignment.sharedWithAllProjectMembers) && (
              <GroupRemove />
            )}
          </ListItemIcon>
          {formatMessage({ id: 'Remove this assignment' })}
        </MenuItem>
      )}
    </ActionMenu>

    <ConfirmDialog
      open={isRemovingAssignment}
      onCancel={() => setIsRemovingAssignment(false)}
      onConfirm={onUnshare}
      title={formatMessage({ id: 'Remove this assignment?' })}
      content={formatMessage({ id: 'The user or group will no longer have these privileges on this resource, and any child resources.' })}
      confirmText={formatMessage({ id: 'Remove' })}
      confirmColor='error'
      loading={isLoading}
    />
  </>;

};