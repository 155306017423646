import { DragDropContext, Droppable, OnDragEndResponder } from '@hello-pangea/dnd';
import { Add } from '@mui/icons-material';
import { Button, Checkbox, FormControlLabel, Radio, RadioGroup, Stack, Switch, TextField } from '@mui/material';
import { useValidationRules } from '@utils/useValidationRules';
import { Chance } from 'chance';
import React from 'react';
import { Controller, useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { FormEditorFieldValues } from '../../../types';
import { InlineEditableComponentProps } from '../../DraggableFormField';
import { FormFieldLabel } from '../../FormFieldLabel';
import { DraggableSelectionOption } from './DraggableSelectionOption';

const chance = Chance();

type Props = InlineEditableComponentProps;

export const SelectionFormFieldConfiguration: React.FC<Props> = (props) => {
  const { formatMessage } = useIntl();
  const { notEmpty } = useValidationRules();

  const { control } = useFormContext<FormEditorFieldValues>();

  const question = useWatch({ control, name: 'name' });
  const required = useWatch({ control, name: 'isRequired' });
  const isMultiselect = useWatch({ control, name: 'isMultiselect' });
  const { fields: options, ...optionsField } = useFieldArray({ control, name: 'selectionOptions', keyName: 'uniqueId' });

  const sampleControl = (
    <RadioGroup value=''>
      {options.map(option => (
        <FormControlLabel
          key={option.uniqueId}
          control={isMultiselect ? (
            <Checkbox disabled />
          ) : (
            <Radio value='notselected' disabled />
          )}
          label={option.label}
        />
      ))}
    </RadioGroup>
  );

  const addOption = () => {
    optionsField.append({ id: chance.guid(), label: '' });
  };

  const onDragEnd: OnDragEndResponder = result => {
    if (!result.destination) return;

    const { source: { index: sourceIndex }, destination: { index: destinationIndex } } = result;

    optionsField.move(sourceIndex, destinationIndex);
  };

  const onOptionDeleted = (index: number) => () => {
    optionsField.remove(index);
  };

  return props.isActive ? <>
    <Stack p={2} pl={4} pb={0} gap={1}>
      <Controller
        name='name'
        control={control}
        rules={{ validate: notEmpty }}
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            autoFocus
            fullWidth
            required
            error={Boolean(error)}
            helperText={error?.message}
            label={formatMessage({ id: 'Question' })}
          />
        )}
      />

      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId='selection-options'>{provided => (
          <Stack gap={2} pt={2} {...provided.droppableProps} ref={provided.innerRef}>
            {options.map((option, index) => (
              <DraggableSelectionOption key={option.uniqueId} uniqueId={option.uniqueId} index={index} onDelete={onOptionDeleted(index)} />
            ))}

            {provided.placeholder}
          </Stack>
        )}</Droppable>
      </DragDropContext>

      <Stack alignItems='end' pb={1} pt={1} gap={1}>
        <Button onClick={addOption} startIcon={<Add />}>
          {formatMessage({ id: 'Add option' })}
        </Button>

        <Controller
          control={control}
          name='isMultiselect'
          render={({ field }) => (
            <FormControlLabel
              control={<Switch checked={field.value} onChange={(_, checked) => field.onChange(checked)} />}
              label={formatMessage({ id: 'Multiselect' })}
              labelPlacement='start'
            />
          )}
        />
      </Stack>
    </Stack>
  </> : (
    <Stack p={2}>
      <FormFieldLabel required={required} italic={!question}>
        {question || formatMessage({ id: 'No name set' })}
      </FormFieldLabel>

      {sampleControl}
    </Stack>
  );
};