import { uniqueClientId } from '@modules/application/apollo-client';
import { MatrixColumnHeader } from '@modules/forms/components/MatrixColumnHeader';
import { Add } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Stack } from '@mui/material';
import { DataGridPro, GridCellParams, GridColDef, GridEditInputCell, GridRenderEditCellParams, GridTreeNodeWithRender, GridValidRowModel, useGridApiRef } from '@mui/x-data-grid-pro';
import { useContextSafe } from '@utils/useContextSafe';
import { MatrixColumnType, MatrixRowEventType } from 'gql/index';
import { useMatrixRowEventSubscription, useMatrixValueEditedSubscription } from 'gql/subscriptions';
import { useCallback, useContext, useMemo } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { AnonymousContext } from '../../../../../projectAccessTokens/AnonymousContextProvider';
import { matrixColumnTypeToGridColType, updateValueToFormValue, valueGetterByColumnType } from '../../../FormEditor/FormFields/configurations/MatrixFormField/MatrixUtils';
import { FormFillerContext } from '../../FormFillerContextProvider';
import { FormFillerValues, MatrixFillerDataGridRow } from '../../types';
import { useFormFillerPresence } from '../../useFormFillerPresence';
import { FieldFillerProps } from '../FieldFiller';
import { MatrixFillerCell } from './MatrixFillerCell';
import { MatrixRowActions } from './MatrixRowActions';
import { useMatrixFiller } from './useMatrixFiller';
import { useMatrixRowFiller } from './useMatrixRowFiller';


export const MatrixFiller: React.FC<FieldFillerProps> = ({ index, field: formField, disabled }) => {
  const { formatMessage } = useIntl();
  const apiRef = useGridApiRef();

  const { submissionId } = useContextSafe(FormFillerContext);
  const { accessToken } = useContext(AnonymousContext);

  const { onValueUpdated, addNewValue } = useMatrixFiller();
  const { addNewRow, isAddRowLoading } = useMatrixRowFiller();

  const { updateFieldPresence } = useFormFillerPresence(submissionId);

  const { control, getValues } = useFormContext<FormFillerValues>();

  const { fields: rows, ...rowsField } = useFieldArray({ name: `values.${index}.matrixRows`, control, keyName: 'useFieldArrayId' });

  const onRowDeleted = useCallback((id: number) => {
    const rowIndex = rows.findIndex(r => r.id == id);
    if (rowIndex >= 0) {
      rowsField.remove(rowIndex);
    }
  }, [rows, rowsField]);

  const updateCellBoolValue = useCallback(<T extends GridValidRowModel>(newValue: boolean | '', params: GridRenderEditCellParams<T, unknown, unknown, GridTreeNodeWithRender>) => {
    if (params.value == newValue) {
      params.api.setEditCellValue({ id: params.id, field: params.field, value: '' });
    } else {
      params.api.setEditCellValue({ id: params.id, field: params.field, value: newValue });
    }
  }, []);

  const valueEditorByColumnType: Record<MatrixColumnType, React.FC<GridRenderEditCellParams>> = useMemo(() => ({
    [MatrixColumnType.Boolean]: (params) => (
      <RadioGroup row value={params.value === '' ? null : params.value} >
        <FormControlLabel value="true" onClick={() => updateCellBoolValue(true, params)} control={<Radio />} label={formatMessage({ id: 'Yes' })} />
        <FormControlLabel value="false" onClick={() => updateCellBoolValue(false, params)} control={<Radio />} label={formatMessage({ id: 'No' })} />
      </RadioGroup>
    ),
    [MatrixColumnType.Text]: (params) => <GridEditInputCell {...params} />,
    [MatrixColumnType.Numerical]: (params) => <GridEditInputCell {...params} />,
  }), [formatMessage, updateCellBoolValue]);

  const dataGridColumns = useMemo((): GridColDef<MatrixFillerDataGridRow>[] => {
    const dataGridCols = [];
    const currentsColumns = formField.matrix?.columns?.map<GridColDef<MatrixFillerDataGridRow>>(column => ({
      field: column.id.toString(),
      renderHeader: () => (
        <MatrixColumnHeader name={column.name} description={column.description} isRequired={column.isRequired} />
      ),
      minWidth: column.fieldType === MatrixColumnType.Text ? 250 : column.fieldType === MatrixColumnType.Boolean ? 200 : 100,
      editable: !disabled,
      disableColumnMenu: true,
      flex: column.fieldType === MatrixColumnType.Text ? 1 : 0,
      disableReorder: true,
      sortable: false,
      type: matrixColumnTypeToGridColType[column.fieldType],
      // align: column.fieldType === MatrixColumnType.Text ? 'left' : column.fieldType === MatrixColumnType.Boolean ? 'center' : 'right',
      valueGetter: ({ row: { values } }) =>
        valueGetterByColumnType[column.fieldType](values.find(d => d.columnId === column.id)),
      renderCell: (params: GridCellParams<MatrixFillerDataGridRow>) => (
        <MatrixFillerCell
          rowId={params.row.id}
          columnId={Number(params.colDef.field)}
          value={params.value}
          column={column}
        />
      ),
      renderEditCell: valueEditorByColumnType[column.fieldType],
      valueSetter: ({ row, value }) => ({ ...row, newValue: value, lastEditedColumnId: column.id }),
    })) ?? [];
    dataGridCols.push(...currentsColumns);

    const actionsColumn: GridColDef<MatrixFillerDataGridRow> = {
      field: 'actions',
      maxWidth: 64,
      minWidth: 64,
      align: 'center',
      editable: false,
      type: 'actions',
      disableColumnMenu: true,
      disableReorder: true,
      sortable: false,
      renderHeader: () => '',
      renderCell: ({ row }) => (
        <MatrixRowActions disabled={disabled} fieldId={formField.id} row={row} onDelete={() => onRowDeleted(row.id)} />
      )
    };
    dataGridCols.push(actionsColumn);

    return dataGridCols.map((v) => {
      if (v.type == 'boolean') return ({
        ...v,
        headerAlign: 'center',
        align: 'center'
      });

      return ({
        ...v,
        headerAlign: 'left',
        align: 'left'
      });
    });
  }, [formField.matrix?.columns, formField.id, disabled, valueEditorByColumnType, onRowDeleted]);

  const dataGridRows = rows.map<MatrixFillerDataGridRow>(row => ({
    id: row.id,
    isDefaultRow: row.isDefaultRow,
    values: row.values
  }));

  const processRowUpdate = useCallback(async (updatedRow: MatrixFillerDataGridRow) => {
    if (submissionId == null) return updatedRow;

    if (!updatedRow.lastEditedColumnId) {
      console.error('lastEditedColumnId is undefined');
      return updatedRow;
    }

    const newBaseValue = updatedRow.newValue;

    if (newBaseValue == null) {
      return updatedRow;
    }

    const rowIndex = rows.findIndex(d => d.id == updatedRow.id);
    const row = rows[rowIndex];

    const oldValueIndex = row.values.findIndex(p => p.columnId == updatedRow.lastEditedColumnId);

    if (oldValueIndex >= 0) {
      const oldValue = row.values[oldValueIndex];
      const newValue = updateValueToFormValue(newBaseValue);

      const newValues = [...row.values];
      newValues.splice(oldValueIndex, 1, { ...oldValue, ...newValue });
      rowsField.update(rowIndex, { ...row, values: newValues });

      if (oldValue.boolean != newValue.boolean ||
        oldValue.number != newValue.number ||
        oldValue.text != newValue.text) {
        onValueUpdated(oldValue.id, { rowId: row.id, columnId: updatedRow.lastEditedColumnId, value: newValue, submissionId });
      }
    } else {
      const newValue = updateValueToFormValue(newBaseValue);
      const addedValueId = await addNewValue({ rowId: row.id, columnId: updatedRow.lastEditedColumnId, value: newValue, submissionId });

      if (!addedValueId) return updatedRow;

      rowsField.update(rowIndex, {
        ...row, values: [...row.values, {
          id: addedValueId,
          columnId: updatedRow.lastEditedColumnId,
          rowId: row.id,
          ...newValue
        }]
      });
    }

    return updatedRow;
  }, [addNewValue, onValueUpdated, rows, rowsField, submissionId]);

  const addRowClicked = useCallback(async () => {
    const addedRowId = await addNewRow(formField.id);

    if (!addedRowId) return;

    rowsField.append({
      id: addedRowId,
      isDefaultRow: false,
      values: []
    });
  }, [addNewRow, formField.id, rowsField]);

  useMatrixRowEventSubscription({
    variables: { submissionId, accessToken }, onData: d => {
      const event = d.data.data?.matrixRowEvent;

      if (!event) return;

      if (event.initiatorUniqueClientId === uniqueClientId) return;

      if (event.eventType === MatrixRowEventType.Removed) {
        onRowDeleted(event.id);
      } else if (event.eventType === MatrixRowEventType.Added) {
        if (getValues(`values.${index}.matrixRows`).some(r => r.id == event.id)) return;

        rowsField.append({
          id: event.id,
          isDefaultRow: false,
          values: []
        });
      }
    }
  });

  useMatrixValueEditedSubscription({
    variables: { submissionId, accessToken }, onData: d => {
      const event = d.data.data?.matrixValueEdited;
      const newValue = event?.matrixValue;

      if (!newValue) return;

      if (event.initiatorUniqueClientId === uniqueClientId) return;

      const rows = getValues(`values.${index}.matrixRows`);

      const rowIndex = rows.findIndex(r => r.id == newValue.matrixRowId);
      if (rowIndex < 0) return;

      const row = rows[rowIndex];
      const valueIndex = row.values.findIndex(v => v.id == newValue.id);

      if (valueIndex < 0) {
        rowsField.update(rowIndex, {
          ...row, values: [...row.values, {
            ...newValue,
            columnId: newValue.matrixColumnId,
            rowId: newValue.matrixRowId,
          }]
        });
      } else {
        const newValues = [...row.values];
        newValues.splice(valueIndex, 1, {
          ...newValue,
          columnId: newValue.matrixColumnId,
          rowId: newValue.matrixRowId,
        });
        rowsField.update(rowIndex, { ...row, values: newValues });
      }
    }
  });

  return (
    <Stack gap={1}>
      <FormControl required={formField.isRequired} disabled={disabled}>
        <FormLabel>{formField.name}</FormLabel>
        <div style={{ height: 400, width: '100%' }}>
          <DataGridPro
            apiRef={apiRef}
            rowHeight={60}
            autosizeOnMount
            disableRowSelectionOnClick
            disableColumnFilter
            disableColumnSelector
            disableMultipleRowSelection
            disableChildrenFiltering
            disableChildrenSorting
            disableColumnReorder={disabled}
            disableDensitySelector
            disableMultipleColumnsFiltering
            disableMultipleColumnsSorting

            disableColumnMenu={disabled}
            processRowUpdate={processRowUpdate}

            onCellClick={(p) => {
              if (disabled) return;

              const isCellEditMode = apiRef.current.getCellMode(p.id, p.field) === 'edit';
              if (isCellEditMode) return;

              apiRef.current.startCellEditMode({ field: p.colDef.field, id: p.row.id });

              updateFieldPresence(formField.id, p.row.id, Number(p.colDef.field));
            }}

            onCellEditStop={() => updateFieldPresence(undefined, undefined, undefined)}
            columns={dataGridColumns}
            rows={dataGridRows}

            hideFooter
            pinnedColumns={{ right: ['actions'] }}
            localeText={{
              noRowsLabel: formatMessage({ id: 'To start adding lines, press the button below.' })
            }}
            sx={{
              '& .MuiDataGrid-cell': {
                p: 0
              },
              '& .MuiDataGrid-virtualScrollerContent': {
                mb: dataGridRows.length === 0 ? 7 : 0,
              }
            }}
          />
        </div>
      </FormControl>
      <Stack direction='row' justifyContent='flex-end'>
        {!disabled && (
          <LoadingButton loading={isAddRowLoading} variant='text' size='small' startIcon={<Add />} onClick={addRowClicked}>
            {formatMessage({ id: 'Add line' })}
          </LoadingButton>
        )}
      </Stack>
    </Stack>
  );
};