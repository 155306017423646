import { ConfirmDialog } from '@components/ConfirmDialog';
import { useCurrentProject } from '@modules/projects/utils/useCurrentProject';
import { PermissionsModal } from '@modules/securityGroups/components/PermissionsModal/PermissionsModal';
import { Delete, Share } from '@mui/icons-material';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { useNotification } from '@utils/useNotification';
import { useQueryInvalidator } from '@utils/useQueryInvalidator';
import { ExternalLinkFragmentFragment, ShareableResourceType, useDeleteExternalLinkMutation, useFolderEntriesQuery } from 'gql/index';
import { useContext, useState } from 'react';
import { useIntl } from 'react-intl';
import { ProjectDocumentsContext } from './ProjectDocumentsContextProvider';

interface Props {
  externalLink: ExternalLinkFragmentFragment;
}

export const ExternalLinkActions = ({ externalLink }: Props) => {
  const { formatMessage } = useIntl();
  const { notifySuccess } = useNotification();
  const invalidateQuery = useQueryInvalidator();
  const { projectId } = useCurrentProject();

  const { setSelectedEntryId } = useContext(ProjectDocumentsContext);

  const { mutate: deleteExternalLink, isLoading } = useDeleteExternalLinkMutation({
    onSuccess: () => {
      notifySuccess(formatMessage({ id: 'External link deleted successfully' }));
      invalidateQuery(useFolderEntriesQuery, { projectId, folderId: externalLink.folderEntry?.folderId });
      setIsDeleting(false);
    }
  });

  const onDelete = () => {
    setSelectedEntryId?.(undefined);

    deleteExternalLink({
      input: {
        linkId: externalLink.id,
        projectId
      }
    });
  };

  const [isDeleting, setIsDeleting] = useState(false);

  const [isAddingRoleAssignment, setIsAddingRoleAssignment] = useState(false);

  return <>
    <MenuItem onClick={() => setIsDeleting(true)}>
      <ListItemIcon>
        <Delete />
      </ListItemIcon>
      {formatMessage({ id: 'Delete' })}
    </MenuItem>


    <MenuItem onClick={() => setIsAddingRoleAssignment(true)}>
      <ListItemIcon><Share /></ListItemIcon>
      <ListItemText primary={formatMessage({ id: 'Share' })} />
    </MenuItem>



    <PermissionsModal
      open={isAddingRoleAssignment}
      resource={({ resourceType: ShareableResourceType.ExternalLink, ...externalLink })}
      onClose={() => setIsAddingRoleAssignment(false)}
    />



    <ConfirmDialog
      loading={isLoading}
      title={formatMessage({ id: 'Delete external link' })}
      confirmColor='error'
      content={formatMessage({ id: 'Are you sure you want to delete this external link?' })}
      open={isDeleting}
      onCancel={() => setIsDeleting(false)}
      onConfirm={onDelete}
    />
  </>;
};