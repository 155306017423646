import { PageContainer } from '@components/Layout/PageContainer';
import { MinimalSelect } from '@components/MinimalSelect';
import { PermissionsProperty } from '@modules/folders/components/PermissionsProperty';
import { CircularProgress, Stack, Typography } from '@mui/material';
import { ProjectTaskStatus, ProjectTaskType, ShareableResourceType, useProjectTaskPermissionsQuery, useProjectTaskQuery, useUpdateTaskStatusMutation } from 'gql/index';
import { mapValues, orderBy } from 'lodash';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { useCurrentProject } from '../../projects/utils/useCurrentProject';
import { TaskActions } from '../components/Actions/TaskActions';
import { TaskDetailsSection } from '../components/Sections/TaskDetailsSection';
import { TaskPredecessorsSection } from '../components/Sections/TaskPredecessorsSection';
import { TaskSubtasksSection } from '../components/Sections/TaskSubtasksSection';
import { getTaskStatusOrderingKey, taskStatusIndicator, taskStatusMessage, useTaskMessages } from '../taskUtils';

export const ProjectTaskPage = () => {
  const { taskId: taskIdString } = useParams();
  const taskId = Number(taskIdString) || 0;
  const { canManageTasks, isArchived, projectId } = useCurrentProject();
  const { formatMessage } = useIntl();
  const { taskNameGetterByType } = useTaskMessages();

  const { data: task, refetch } = useProjectTaskQuery({ taskId, projectId }, { select: d => d.projectTask });
  const { data: permissions } = useProjectTaskPermissionsQuery({ taskId, projectId }, {
    select: d => d.taskPermissions,
    enabled: canManageTasks
  });

  const name = task?.type && taskNameGetterByType[task.type](task);

  const { mutate: updateTaskStatus, isLoading } = useUpdateTaskStatusMutation({
    onSuccess: () => refetch()
  });

  const onStatusChange = (status: ProjectTaskStatus) => {
    if (!task) return;

    updateTaskStatus({
      input: {
        projectId,
        taskId: task.id,
        status
      }
    });
  };

  if (!task) {
    return <>
      <Stack alignItems='center' justifyContent='center' height='100%' width='100%'>
        <CircularProgress />
      </Stack>
    </>;
  }

  return <>
    <PageContainer
      title={name}
      action={(
        <TaskActions
          task={task}
          hideEdit
          variant='squareIcon'
          buttonProps={{ variant: 'contained' }}
        />
      )}
    >

      {(!canManageTasks || isArchived || task.type !== ProjectTaskType.Custom) ? (
        <Stack direction='row' alignItems='center' gap={0.5}>
          {taskStatusIndicator[task.status]}

          <Typography>
            {formatMessage(taskStatusMessage[task.status])}
          </Typography>
        </Stack>
      ) : (
        <MinimalSelect
          value={task.status}
          onChange={onStatusChange}
          options={orderBy(Object.values(ProjectTaskStatus), p => getTaskStatusOrderingKey(p))}
          optionIcons={taskStatusIndicator}
          optionMessages={mapValues(taskStatusMessage, m => formatMessage(m))}
          disabled={isLoading}
        />
      )}

      <Stack gap={2} mt={1} mb={4}>
        <TaskDetailsSection task={task} />

        {task.type === ProjectTaskType.Custom && <>
          <TaskPredecessorsSection task={task} />

          <TaskSubtasksSection task={task} />
        </>}

        {canManageTasks && task.type === ProjectTaskType.Custom && (
          <PermissionsProperty resource={{ ...task, name: name, resourceType: ShareableResourceType.Task }} variant='pageSection' roleAssignments={permissions ?? []} />
        )}
      </Stack>
    </PageContainer>
  </>;
};