import { SupportedLocale } from '@modules/../locales';
import { currentLocaleState } from '@modules/application/atoms';
import { ListItemButton, ListItemButtonProps, ListItemText } from '@mui/material';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useRecoilState } from 'recoil';
import { UpdateMyPreferencesInput, useGetMeQuery, useUpdateMyPreferencesMutation } from '../../../../../../gql';
import { useQueryInvalidator } from '../../../../../../utils/useQueryInvalidator';

interface OwnProps {
  locale: SupportedLocale;
}

export type LanguageSwitcherButtonProps =
  & ListItemButtonProps
  & OwnProps;

export const LanguageSwitcherButton: React.FC<LanguageSwitcherButtonProps> = (props) => {
  const { formatMessage } = useIntl();
  const [currentLocaleCode, setCurrentLocaleCode] = useRecoilState(currentLocaleState);
  const { locale, ...listItemButtonProps } = props;
  const { mutate: updateMyPreferences } = useUpdateMyPreferencesMutation();
  const invalidateQuery = useQueryInvalidator();

  const onSubmit = (form: UpdateMyPreferencesInput) => {
    updateMyPreferences({ input: form }, {
      onSuccess: () => {
        invalidateQuery(useGetMeQuery);
      }
    });
  };

  const { handleSubmit, setValue } = useFormContext<UpdateMyPreferencesInput>();


  const changeLocale = () => {
    setCurrentLocaleCode(locale.code);
    setValue('preferredLanguage', locale.code);
    handleSubmit(onSubmit)();
  };

  return (
    <ListItemButton
      selected={currentLocaleCode === locale.code}
      onClick={changeLocale}
      {...listItemButtonProps}
    >
      <ListItemText primary={formatMessage(locale.nameDescriptor)} />
    </ListItemButton>
  );
};