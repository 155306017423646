import { PageContainer } from '@components/Layout/PageContainer';
import { useIntl } from 'react-intl';
import { PermissionsTable } from '../components/Permissions/PermissionsTable';

export const PermissionsPermissionsView = () => {
  const { formatMessage } = useIntl();

  return (
    <PageContainer title={formatMessage({ id: 'Permissions' })}>
      <PermissionsTable />
    </PageContainer>
  );
};