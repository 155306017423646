import { TextField } from '@mui/material';
import { useValidationRules } from '@utils/useValidationRules';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormFillerValues } from '../types';
import { FieldFillerProps } from './FieldFiller';
import { useFieldPresenceUpdateEvents } from './useFieldPresenceUpdateEvents';
import { useFormFieldFillerForm } from './useFormFieldFillerForm';


export const NumericalFiller: React.FC<FieldFillerProps> = ({ index, field: formField, disabled }) => {
  const { validateNumber, notEmpty } = useValidationRules();

  const { control, getValues } = useFormContext<FormFillerValues>();

  const updatePresenceEvents = useFieldPresenceUpdateEvents(formField.id);
  const { onSubmit } = useFormFieldFillerForm();

  return (
    <Controller
      control={control}
      name={`values.${index}.numerical`}
      rules={{
        validate: {
          ...formField.isRequired && { notEmpty },
          validateNumber
        }
      }}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          {...updatePresenceEvents}
          onChange={(e) => {
            field.onChange(e);
            setTimeout(() => onSubmit({ ...getValues(`values.${index}`), numerical: e.target.value }));
          }}
          type='number'
          disabled={disabled}
          required={formField.isRequired}
          label={formField.name}
          error={!!error}
          helperText={error?.message}
          InputLabelProps={{ shrink: true }}
        />
      )}
    />
  );
};