import { Event, EventAvailable, EventBusy, Timeline } from '@mui/icons-material';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import UploadIcon from '@mui/icons-material/Upload';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { MessageDescriptor, defineMessages } from 'react-intl';
import { DomainEventType } from '../../../../gql';
export interface RecentEvent {
  eventDate: string,
  eventText: React.ReactNode,
  icon: React.ReactNode,
}

export const eventTimelineMessageByTypeThirdPersonVerb: Record<DomainEventType, MessageDescriptor> = defineMessages({
  DocumentSigned: { id: '{user} signed the document {link}' },
  FileUploaded: { id: '{user} uploaded the document {link}' },
  FileDownloaded: { id: '{user} viewed the document {link}' },
  FormFilled: { id: '{user} filled the form {link}' },
  WorkflowActionSubmitted: { id: '{user} completed the workflow action {link}' },
  MeetingRequested: { id: '{user} requested a meeting for {link}' },
  MeetingAccepted: { id: '{user} accepted a meeting for {link}' },
  MeetingDeclined: { id: '{user} declined a meeting for {link}' },
});

export const eventTimelineMessageByTypeSecondPersonVerb: Record<DomainEventType, MessageDescriptor> = defineMessages({
  DocumentSigned: { id: 'You signed the document {link}' },
  FileUploaded: { id: 'You uploaded the document {link}' },
  FileDownloaded: { id: 'You viewed the document {link}' },
  FormFilled: { id: 'You filled the form {link}' },
  WorkflowActionSubmitted: { id: 'You completed the workflow action {link}' },
  MeetingRequested: { id: 'You requested a meeting for {link}' },
  MeetingAccepted: { id: 'You accepted a meeting for {link}' },
  MeetingDeclined: { id: 'You declined a meeting for {link}' },
});

export const iconByEventType: Record<DomainEventType, React.ReactNode> = {
  DocumentSigned: <AssignmentTurnedInIcon color='action' />,
  FileDownloaded: <VisibilityIcon color='action' />,
  FileUploaded: <UploadIcon color='action' />,
  FormFilled: <AssignmentTurnedInIcon color='action' />,
  WorkflowActionSubmitted: <Timeline color='action' />,
  MeetingRequested: <Event />,
  MeetingAccepted: <EventAvailable />,
  MeetingDeclined: <EventBusy />
};