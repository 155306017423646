import { Container, LinearProgress, Skeleton, Stack, Typography } from '@mui/material';
import React, { ReactNode } from 'react';
import { AppTopBar } from './AppTopBar';

export interface PageTitleProps {
  title: string | undefined | ReactNode;
  icon?: ReactNode;
  subtitle?: ReactNode | string;
  actionButton?: ReactNode;
  loading?: boolean;
  pageLoading?: boolean;
  backgroundColor?: 'primary' | 'secondary' | 'disabled';
  size?: 'small';
}



export const PageTitle: React.FC<PageTitleProps> = ({ title, size, pageLoading, icon, subtitle, loading, actionButton, backgroundColor }) => {
  const titleNode = typeof (title) == 'string' ? (
    <Stack direction='row' alignItems='center' width='100%' spacing={1}>
      <Typography color='inherit' variant='h5' sx={{ maxWidth: '80ch' }}>
        {title}
      </Typography>
    </Stack>)
    : title;


  const subtitleIsString = typeof (subtitle) === 'string';

  const backgroundTopBarColor: Record<'primary' | 'secondary' | 'disabled', string> = {
    disabled: 'action.disabled',
    primary: 'primary.main',
    secondary: 'secondary.main'
  };

  const color: Record<'primary' | 'secondary' | 'disabled', string> = {
    disabled: 'text.primary',
    primary: 'primary.contrastText',
    secondary: 'secondary.contrastText'
  };

  return (
    <AppTopBar sx={{
      backgroundColor: backgroundColor ? backgroundTopBarColor[backgroundColor] : 'primary.main',
      color: backgroundColor ? color[backgroundColor] : undefined
    }}>
      <Container maxWidth='xl'>

        <Stack direction='row' justifyContent='space-between' alignItems='center' flex={1} pt={size == 'small' ? 1 : 2} pb={size == 'small' ? 0.5 : 1.5}>
          <Stack direction='row' alignItems='center' flex={1} spacing={2} pl={icon ? 1 : 0}>
            {icon}
            <Stack spacing={pageLoading || !subtitleIsString ? 0 : 1} flexGrow={1}>
              {!pageLoading ? titleNode : <>
                <Skeleton variant='text' width={240} sx={{ fontSize: 32 }} />
                <Skeleton variant='text' width={180} sx={{ fontSize: 12 }} />
              </>}
              {(subtitle && !pageLoading) && (
                subtitleIsString ? (
                  <Typography variant='subtitle1' color='text.secondary'>{subtitle}</Typography>
                ) : subtitle
              )}
            </Stack>
          </Stack>

          <Stack direction='row' alignItems='center'>
            {actionButton}
          </Stack>
        </Stack>

      </Container>
      <LinearProgress style={{ visibility: loading ? 'visible' : 'hidden' }} />
    </AppTopBar>
  );
};