import { DrawerProps, Paper, Stack, Typography } from '@mui/material';
import React, { ReactNode } from 'react';
import { DrawerHeader } from './DrawerHeader';
import { ResponsiveDrawerBase } from './ResponsiveDrawerBase';

export interface ResponsiveDrawerProps extends Omit<DrawerProps, 'title'> {
  header?: ReactNode | string;
  children?: ReactNode;
  defaultWidth?: string;
  disabled?: boolean;
  isLoading?: boolean;
  disablePadding?: boolean;
  actionButtons?: ReactNode;
  drawerHeaderOnClose?: () => void;
}

export const ResponsiveDrawer: React.FC<ResponsiveDrawerProps> = ({ header, children, isLoading, disablePadding, disabled, defaultWidth, actionButtons, drawerHeaderOnClose, ...drawerProps }) => {
  if (!defaultWidth) defaultWidth = '400px';

  return (
    <ResponsiveDrawerBase anchor="right" defaultWidth={defaultWidth} {...drawerProps} onClose={disabled ? undefined : drawerProps.onClose}>
      <DrawerHeader loading={isLoading} disabled={disabled} onClose={drawerHeaderOnClose ?? drawerProps.onClose}>
        {typeof header === 'string' ? (
          <Typography variant='h6' pl={{ xs: 1, md: 2 }} width='100%'>
            {header}
          </Typography>
        ) : header}
        {actionButtons && (
          <Stack direction='row' pr={1} flexGrow={1}>
            {actionButtons}
          </Stack>
        )}
      </DrawerHeader>

      {disablePadding ? children : (
        <Paper elevation={4}>
          <Stack p={2} spacing={1}>
            {children}
          </Stack>
        </Paper>
      )}
    </ResponsiveDrawerBase >
  );
};

ResponsiveDrawer.defaultProps = {
  defaultWidth: '400px',
  anchor: 'right'
};