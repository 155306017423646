import { ActionMenu } from '@components/ActionMenu';
import { ConfirmDialog } from '@components/ConfirmDialog';
import { useCurrentProject } from '@modules/projects/utils/useCurrentProject';
import { Delete } from '@mui/icons-material';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { useNotification } from '@utils/useNotification';
import { useQueryInvalidator } from '@utils/useQueryInvalidator';
import { useDeleteRequestMutation, useProjectFormsQuery, useProjectTasksQuery } from 'gql/index';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { RequestRow } from './RequestTable';

interface OwnProps {
  request: Pick<RequestRow, 'id' | 'formId'>
}

export const RequestActions: React.FC<OwnProps> = ({ request }) => {
  const { formatMessage } = useIntl();
  const { notifySuccess } = useNotification();
  const invalidateQuery = useQueryInvalidator();

  const { projectId, isArchived } = useCurrentProject();

  const [moreMenuDrawerOpen, setMoreMenuDrawerOpen] = useState(false);
  const [confirmRemoveDialogOpen, setConfirmRemoveDialogOpen] = useState(false);

  const { mutate: deleteRequest, isLoading } = useDeleteRequestMutation({
    onSuccess: () => {
      notifySuccess(formatMessage({ id: 'Pending request deleted successfully' }));
      invalidateQuery(useProjectTasksQuery, { projectId });
      invalidateQuery(useProjectFormsQuery, { projectId });
      setConfirmRemoveDialogOpen(false);
    }
  });

  const onDeleteConfirm = () => {
    deleteRequest({
      input: { projectId, formId: request.formId, taskId: request.id }
    });
  };

  return (
    <>
      <ActionMenu open={moreMenuDrawerOpen} onOpen={setMoreMenuDrawerOpen}>
        <MenuItem onClick={() => setConfirmRemoveDialogOpen(true)} disabled={isArchived}>
          <ListItemIcon><Delete /></ListItemIcon>
          <ListItemText primary={formatMessage({ id: 'Delete' })} />
        </MenuItem>
      </ActionMenu>

      <ConfirmDialog
        open={confirmRemoveDialogOpen}
        title={formatMessage({ id: 'Delete pending request' })}
        content={formatMessage({ id: 'This pending request will be deleted. Are you sure?' })}
        confirmText={formatMessage({ id: 'Delete' })}
        confirmColor='error'
        onCancel={() => setConfirmRemoveDialogOpen(false)}
        onConfirm={onDeleteConfirm}
        loading={isLoading}
      />
    </>
  );
};