import { Autocomplete, TextField, TextFieldProps } from '@mui/material';
import { WorkflowVariableValueTypes } from 'gql/index';
import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import { WorkflowEditorContext } from '../WorkflowEditorContext';

type Props = {
  onChange: (roleVariableId: number | undefined) => void;
  value: number | null | undefined | '';
  textFieldProps?: TextFieldProps;
};

export const WorkflowRolePicker: React.FC<Props> = ({ onChange, value, textFieldProps }) => {
  const { formatMessage } = useIntl();

  const { workflowDefinition } = useContext(WorkflowEditorContext);

  const roles = workflowDefinition.variables.filter(p => p.variableValueType === WorkflowVariableValueTypes.User);

  return (
    <Autocomplete
      value={roles.find(p => p.id === value)}
      onChange={(_, newValue) => onChange(newValue?.id)}
      options={roles}
      getOptionLabel={role => role.name ?? ''}
      renderInput={params => (
        <TextField
          label={formatMessage({ id: 'Workflow role' })}
          {...textFieldProps}
          {...params}
        />
      )}
    />
  );
};