import { EmptyState } from '@components/EmptyState';
import { Button, Grid, Stack } from '@mui/material';
import { useGetProjectsQuery } from 'gql/index';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { NewProjectFormValues } from './AddProjectDialog';
import { ProjectTemplateCard } from './ProjectTemplateCard';

type Props = {
  onTemplateSelected: () => void;
  onAddTemplateClicked: () => void;
}

export const SelectProjectTemplateStep: React.FC<Props> = ({ onTemplateSelected, onAddTemplateClicked }) => {
  const { formatMessage } = useIntl();
  const { setValue } = useFormContext<NewProjectFormValues>();
  const { data: projectTemplates, isLoading } = useGetProjectsQuery({
    filter: {
      isTemplate: { eq: true }
    }
  }, {
    select: p => p.projects
  });

  const hasTemplates = !isLoading && projectTemplates && projectTemplates.length > 0;

  if (!hasTemplates) {
    return <>
      <EmptyState
        title={formatMessage({ id: 'There are no templates' })}
        subtitle={formatMessage({ id: 'You can create reusable project templates with default data and configuration to speed up the creation of new projects.' })}
        callToActionComponent={<Button variant='contained' color='primary' onClick={onAddTemplateClicked}>{formatMessage({ id: 'Add a template' })}</Button>}
      />
    </>;
  }

  return (<>
    <Stack pt={1} pb={4} gap={4} alignItems={'center'} flex={1}>
      <Grid container spacing={2} mt={4} flex={1}>
        {projectTemplates?.map(p => (
          <Grid item xs={12} md={6} key={p.id}>
            <ProjectTemplateCard key={p.id} project={p} onClick={p => { setValue('projectTemplateId', p.id); onTemplateSelected(); }} />
          </Grid>
        ))}
      </Grid>
    </Stack>

  </>
  );
};