import { Box, DrawerProps } from '@mui/material';
import { UpdateFormSettingsInput } from 'gql/subscriptions';
import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { FormDrawer } from '../../../../components/Drawers/FormDrawer';
import { AllowedRespondents, FormType, PrincipalType, useProjectFormSettingsQuery, useUpdateFormSettingsMutation } from '../../../../gql';
import { useNotification } from '../../../../utils/useNotification';
import { useQueryInvalidator } from '../../../../utils/useQueryInvalidator';
import { useCurrentProject } from '../../../projects/utils/useCurrentProject';
import { SecurityGroupPrincipal, UserPrincipal } from '../../../users/components/types';
import { FormSettings } from './FormSettings';
import { FormSettingsType } from './types';

type Props = DrawerProps & {
  projectFormId: number;
};

export const FormSettingsDrawer: React.FC<Props> = ({ projectFormId, ...drawerProps }) => {
  const { formatMessage } = useIntl();
  const invalidateQuery = useQueryInvalidator();
  const { projectId } = useCurrentProject();
  const { data: formSettings, isFetching: isFetchingSettings } = useProjectFormSettingsQuery({ formId: projectFormId }, { enabled: drawerProps.open, select: p => p.projectFormSettings });
  const { mutate: updateFormSettings, isLoading: isUpdatingSettings } = useUpdateFormSettingsMutation();
  const { notifySuccess } = useNotification();
  const isLoading = isFetchingSettings || isUpdatingSettings;
  const form = useForm<FormSettingsType>({
    defaultValues: {
      allowedResponders: AllowedRespondents.SpecificUsersOrGroups,
      formType: FormType.CollectResponse,
      specificResponders: [],
    }
  });

  useEffect(() => {
    if (formSettings && drawerProps.open) {
      const defaultValues: FormSettingsType = ({
        allowedResponders: formSettings.allowedRespondents,
        formType: formSettings.formType,
        specificResponders: [
          ...formSettings.allowedSecurityGroupsRespondents.map<SecurityGroupPrincipal>(p => ({ id: p.id, name: p.name, principalType: PrincipalType.SecurityGroup })),
          ...formSettings.allowedUsersRespondents.map<UserPrincipal>(p => ({ ...p, principalType: PrincipalType.User }))
        ]
      });

      form.reset(defaultValues);
    }
  }, [form, formSettings, drawerProps.open]);

  const onSubmit = (form: FormSettingsType) => {
    const input: UpdateFormSettingsInput = {
      projectId: projectId,
      projectFormId: projectFormId,
      allowedRespondents: form.allowedResponders,
      allowedSecurityGroupsRespondents: form.allowedResponders === AllowedRespondents.SpecificUsersOrGroups
        ? form.specificResponders.filter(p => p.principalType === PrincipalType.SecurityGroup).map(p => p.id)
        : [],
      allowedUsersRespondents: form.allowedResponders === AllowedRespondents.SpecificUsersOrGroups
        ? form.specificResponders.filter(p => p.principalType === PrincipalType.User).map(p => p.id)
        : []
    };

    updateFormSettings({
      input
    }, {
      onSuccess: () => {
        notifySuccess(formatMessage({ id: 'Form settings updated.' }));
        drawerProps.onClose && drawerProps.onClose({}, 'backdropClick');
        invalidateQuery(useProjectFormSettingsQuery, { formId: projectFormId });
      }
    });
  };

  return (
    <FormDrawer
      {...drawerProps}
      header={formatMessage({ id: 'Form settings' })}
      disablePadding
      onSave={form.handleSubmit(onSubmit)}
      isLoading={isLoading}
      isFormDirty={form.formState.isDirty}
      isSubmitting={isUpdatingSettings}
      showFooter
    >
      {!isFetchingSettings &&
        <Box px={2} py={4}>
          <FormProvider {...form}>
            <FormSettings disabled={isUpdatingSettings} />
          </FormProvider>
        </Box>
      }
    </FormDrawer>
  );
};