import { UserAvatar } from '@components/UserAvatar/UserAvatar';
import { AvatarGroup, Card, CardActionArea, CardMedia, Divider, Stack, Typography } from '@mui/material';
import { GetProjectsQuery } from 'gql/index';
import { useIntl } from 'react-intl';

type Project = GetProjectsQuery['projects'][number];

interface Props {
  project: Project;
  onClick?: (project: Project) => void;
}

export const ProjectTemplateCard: React.FC<Props> = ({ project, onClick }) => {
  const { formatDate, formatMessage } = useIntl();

  return (
    <Card style={{ width: '100%', height: '200px' }} >
      <CardActionArea sx={{ height: '100%' }} onClick={() => onClick?.(project)}>
        <Stack height='100%'>
          <CardMedia component='div' sx={{ height: 24, backgroundColor: 'secondary.main' }}>
            <Typography color={theme => theme.palette.getContrastText(theme.palette.secondary.main)} textAlign={'center'}>{formatMessage({ id: 'Project template' })}</Typography>
          </CardMedia>

          <Stack flexGrow={1} p={1} gap={2} overflow='hidden'>
            <Stack>
              <Typography variant='h6' maxHeight='80px' style={{ display: '-webkit-box', WebkitBoxOrient: 'vertical', WebkitLineClamp: 2, overflow: 'hidden' }}>
                {project.name}
              </Typography>

              <Typography variant='subtitle2' color='text.secondary'>{formatDate(project.createdOn)}</Typography>
            </Stack>

            {project.description && (
              <Typography variant='body2' color='text.secondary' overflow='hidden' textOverflow='ellipsis' style={{ display: '-webkit-box', WebkitBoxOrient: 'vertical', WebkitLineClamp: 2, overflow: 'hidden' }}>
                {project.description}
              </Typography>
            )}
          </Stack>

          <Divider />

          <Stack alignItems='start' p={1}>
            <AvatarGroup sx={{ '& .MuiAvatar-root': { height: '32px', width: '32px', fontSize: 12 } }}>
              {project.securityGroups.map(group => (
                <UserAvatar key={group.id} displayName={group.name} />
              ))}
            </AvatarGroup>
          </Stack>
        </Stack>
      </CardActionArea>
    </Card>
  );
};