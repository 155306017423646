import { PageSection } from '@components/PageSection/PageSection';
import { PageSectionSkeleton } from '@components/Skeletons/PageSectionSkeleton';
import { UnstyledLink } from '@components/UnstyledLink';
import { People, Settings } from '@mui/icons-material';
import { useGetProjectMembersQuery } from 'gql/index';
import _ from 'lodash';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { ResponsiveButton } from '../../../../components/ResponsiveButton';
import { getTenantIdentifier } from '../../../../utils/getTenantIdentifier';
import { useCurrentProject } from '../../utils/useCurrentProject';
import { MemberInfoDrawer } from '../Members/MemberInfoDrawer';
import { MemberListItem } from '../Members/MemberListItem';
import { ProjectSectionList } from './ProjectSectionList';

export const MembersSection = () => {
  const { formatMessage } = useIntl();

  const { canManageProject, projectId } = useCurrentProject();
  const { data: projectMembers } = useGetProjectMembersQuery({ projectId }, { select: p => p.projectMembers });

  const members = _.sortBy(projectMembers, [p => p.fullName]);

  const [selectedUserId, setSelectedUserId] = useState<string>();
  const selectedMember = members.find(m => m.id === selectedUserId);

  if (projectMembers == null) {
    return (
      <PageSectionSkeleton icon={<People />} title={formatMessage({ id: 'Members' })} />
    );
  }

  return <>
    <PageSection
      icon={<People />}
      title={formatMessage({ id: 'Members' })}
      actionButton={!canManageProject ? undefined :
        <UnstyledLink to={`/${getTenantIdentifier()}/projects/${projectId}/permissions/users`}>
          <ResponsiveButton size='small' color='primary' variant='contained' icon={<Settings />}>
            {formatMessage({ id: 'Manage' })}
          </ResponsiveButton>
        </UnstyledLink>
      }
    >
      <ProjectSectionList disablePadding>
        {members.map(member => (
          <MemberListItem key={member.id} member={member} onClick={() => setSelectedUserId(member.id)} />
        ))}
      </ProjectSectionList>
    </PageSection>

    {selectedMember && (
      <MemberInfoDrawer
        user={selectedMember}
        projectId={projectId}
        onClose={() => setSelectedUserId(undefined)}
      />
    )}
  </>;
};