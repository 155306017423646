import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { IconButton, Stack } from '@mui/material';

interface Props {
  currentIndex: number;
  length: number;
  onIndexChange: (index: number) => void;
}

export const IndexSwitcher: React.FC<Props> = ({ currentIndex, length, onIndexChange }) => {
  return (
    <Stack direction='row' alignItems='center'>
      <IconButton color='inherit' disabled={currentIndex === 0} onClick={() => onIndexChange(currentIndex - 1)}>
        <ChevronLeft />
      </IconButton>

      {`${currentIndex + 1} / ${length}`}

      <IconButton color='inherit' disabled={currentIndex === length - 1} onClick={() => onIndexChange(currentIndex + 1)}>
        <ChevronRight />
      </IconButton>
    </Stack>
  );

};