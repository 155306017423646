import { ActionMenu } from '@components/ActionMenu';
import { ConfirmDialog } from '@components/ConfirmDialog';
import { DisableableTooltip } from '@components/Tooltips/DisableableTooltip';
import { useCanEditUser } from '@modules/projects/utils/useCanEditUser';
import { Delete, Edit, Shield } from '@mui/icons-material';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { useNotification } from '@utils/useNotification';
import { useQueryInvalidator } from '@utils/useQueryInvalidator';
import { ApplicationUser, useGetProjectMembersQuery, useGetProjectQuery, useGetUsersFullQuery, useGetUsersQuery, useRemoveUserMutation } from 'gql/index';
import React from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { EditUserDetailsDrawer } from './EditUserDetailsDrawer';
import { EditAdminRoleDrawer } from './EditUserRoleDrawer';

interface Props {
  user: Pick<ApplicationUser, 'id' | 'fullName'>;
  onUserRemoved?: () => void;
}

export const UserActions: React.FC<Props> = ({ user, onUserRemoved }) => {
  const { projectId } = useParams();
  const { formatMessage } = useIntl();
  const invalidateQuery = useQueryInvalidator();
  const { notifySuccess } = useNotification();

  const { canEditApplicationRole } = useCanEditUser(user.id);

  const [confirmRemoveDialogOpen, setConfirmRemoveDialogOpen] = React.useState(false);
  const [isMoreMenuOpen, setMoreMenuOpen] = React.useState(false);
  const [isEditRoleOpen, setEditRoleOpen] = React.useState(false);
  const [editUserDetailsOpen, setEditUserDetailsOpen] = React.useState(false);

  const removeUserMutation = useRemoveUserMutation({
    onSuccess: () => {
      setConfirmRemoveDialogOpen(false);
      setMoreMenuOpen(false);
      invalidateQuery(useGetUsersQuery);
      invalidateQuery(useGetUsersFullQuery);
      invalidateQuery(useGetProjectQuery, projectId ? { id: Number(projectId) } : undefined);
      invalidateQuery(useGetProjectMembersQuery);
      notifySuccess(formatMessage({ id: 'User removed' }));
      onUserRemoved?.();
    }
  });

  const onRemoveConfirm = () => {
    removeUserMutation.mutate({ input: { id: user.id } });
  };

  return <>
    <ActionMenu open={isMoreMenuOpen} onOpen={setMoreMenuOpen}>
      <MenuItem onClick={() => setEditUserDetailsOpen(true)}>
        <ListItemIcon>
          <Edit />
        </ListItemIcon>
        <ListItemText primary={formatMessage({ id: 'Edit user' })} />
      </MenuItem>

      {canEditApplicationRole !== 'doNotShow' && (
        <DisableableTooltip disabled={canEditApplicationRole === true} title={canEditApplicationRole} arrow>
          <MenuItem onClick={() => setEditRoleOpen(true)} disabled={canEditApplicationRole !== true}>
            <ListItemIcon>
              <Shield />
            </ListItemIcon>
            <ListItemText primary={formatMessage({ id: 'Edit role' })} />
          </MenuItem>
        </DisableableTooltip>
      )}

      <MenuItem onClick={() => setConfirmRemoveDialogOpen(true)}>
        <ListItemIcon>
          <Delete />
        </ListItemIcon>

        <ListItemText primary={formatMessage({ id: 'Delete' })} />
      </MenuItem>
    </ActionMenu>

    <EditUserDetailsDrawer
      open={editUserDetailsOpen}
      userId={user.id}
      onClose={() => setEditUserDetailsOpen(false)}
    />

    <EditAdminRoleDrawer
      open={isEditRoleOpen}
      userId={user.id}
      onClose={() => setEditRoleOpen(false)}
    />

    <ConfirmDialog
      open={confirmRemoveDialogOpen}
      title={formatMessage({ id: 'Remove user' })}
      content={formatMessage({ id: '{fullName} will no longer have access to this application. Are you sure?' }, { fullName: user.fullName })}
      confirmText={formatMessage({ id: 'Remove' })}
      onCancel={() => setConfirmRemoveDialogOpen(false)}
      onConfirm={onRemoveConfirm}
      confirmColor='error'
      loading={removeUserMutation.isLoading}
    />
  </>;
};