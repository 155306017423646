import { Skeleton, Stack } from '@mui/material';

type PropertyGroupProps = {
  propertiesCount: number;
};

export const PropertyGroupSkeleton = (props: PropertyGroupProps) => {
  const { propertiesCount } = props;

  return (
    <Stack gap={2}>
      <Skeleton variant='text' sx={{ fontSize: '32px' }} />
      <Stack ml={2} gap={2} >
        {new Array(propertiesCount).fill(0).map((_, index) => (
          <Skeleton key={index} variant='text' sx={{ fontSize: '16px' }} height={44} />
        ))}
      </Stack>
    </Stack>
  );
};