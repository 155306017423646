import { ChangeEvent } from 'react';

export type BrandingFormValues = {
  isBrandingEnabled: boolean;
  primaryColor: string;
  secondaryColor: string;
  topBarLogoLight: string | undefined;
  topBarLogoDark: string | undefined;
  loginBackground: string | undefined;
  squareLogoLight: string | undefined;
  squareLogoDark: string | undefined;
}

export type BrandingFiles = {
  logoLight: File | undefined,
  logoDark: File | undefined,
  loginBackgroundImage: File | undefined,
  squareLogoLight: File | undefined,
  squareLogoDark: File | undefined,
}

export type ImageInfo = {
  file: File,
  dataURL: string,
  width: number,
  height: number
}

export const readImageContent = (e: ChangeEvent<HTMLInputElement>, onContentLoaded: (imageInfo: ImageInfo) => void) => {
  const file = e.target.files?.item(0);
  if (file) {
    const reader = new FileReader();
    reader.onload = (event) => {
      const contentURL = event.target?.result as string;
      const img = new Image();

      img.onload = (event) => {
        const imageWithInfo: ImageInfo = {
          file: file,
          dataURL: contentURL,
          width: (event.target as HTMLImageElement).width,
          height: (event.target as HTMLImageElement).height
        };

        onContentLoaded(imageWithInfo);
      };

      img.src = contentURL;
    };

    reader.readAsDataURL(file);
  }
};