import { ApplicationUser, BuiltInRoles, PrincipalType, SecurityGroup } from '../../../gql';

export type UserPrincipal = Pick<ApplicationUser, 'id' | 'business' | 'fullName' | 'title' | 'email' | 'phoneNumber'> & { principalType: PrincipalType.User; };

export type SecurityGroupPrincipal = Pick<SecurityGroup, 'id' | 'name'> & { principalType: PrincipalType.SecurityGroup; };

export type AllUsersPrincipal = { id: 'all', principalType: PrincipalType.SharedWithAllProjectMembers; };
export type AnyoneWithLinkPrincipal = { id: 'anyone', principalType: PrincipalType.SharedWithAnyone; };

export type SecurityPrincipal = UserPrincipal | SecurityGroupPrincipal | AllUsersPrincipal | AnyoneWithLinkPrincipal;

export type SecurityPrincipalShim = Pick<SecurityPrincipal, 'id' | 'principalType'>;

export const UserApplicationBuiltInRoles = [BuiltInRoles.Contributor, BuiltInRoles.ProjectAdministrator, BuiltInRoles.TenantAdministrator];

export const ExternalUserRole = BuiltInRoles.LimitedAccess;
