import { PrincipalType } from 'gql/index';
import { defineMessages, MessageDescriptor } from 'react-intl';
import { PrincipalPickerValue, RemovedPrincipal } from './PrincipalPicker';

export { PrincipalPicker } from './PrincipalPicker';

export const removedPrincipalLabelByType: Record<PrincipalType, MessageDescriptor> = defineMessages({
  User: { id: 'Removed user' },
  SecurityGroup: { id: 'Removed group' },
  // Users shouldn't see this since SharedWithAllProjectMembers principal entities aren't handled by the principal picker,
  // but if they do it won't cause issues
  SharedWithAllProjectMembers: { id: 'Removed user' },
  SharedWithAnyone: { id: 'Remove public link access' }
});

export const isRemovedPrincipal = (value: PrincipalPickerValue): value is RemovedPrincipal =>
  typeof value !== 'string' && 'type' in value && value.type === 'removed';