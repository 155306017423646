import React from 'react';
import { useCurrentProject } from '../utils/useCurrentProject';
import { ProjectHomePage } from './ProjectHomePage';
import { ProjectTemplateHomePage } from './ProjectTemplateHomePage';

export const ProjectHomePageSelector: React.FC = () => {
  const { isTemplate } = useCurrentProject();


  return isTemplate ? <ProjectTemplateHomePage /> : <ProjectHomePage />;
};
