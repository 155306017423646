import React from 'react';
import { ReactComponent as MSIcon } from '@assets/icons/logo-microsoft.svg';
import { useIntl } from 'react-intl';
import { LoginButton } from './LoginButton';
import { LoadingButtonProps } from '@mui/lab';

export const MicrosoftLoginButton: React.FC<LoadingButtonProps> = (props) => {
  const { formatMessage } = useIntl();

  return (
    <LoginButton {...props} startIcon={<MSIcon />}>
      {formatMessage({ id: 'Log in with Microsoft' })}
    </LoginButton>
  );
};