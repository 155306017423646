import { FormControlLabel, Stack, Switch, TextField } from '@mui/material';
import { useValidationRules } from '@utils/useValidationRules';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { FormEditorFieldValues } from '../../types';
import { InlineEditableComponentProps } from '../DraggableFormField';
import { FormFieldLabel } from '../FormFieldLabel';

type Props = InlineEditableComponentProps;

export const TextFormFieldConfiguration = (props: Props) => {
  const { formatMessage } = useIntl();
  const { notEmpty } = useValidationRules();

  const { control } = useFormContext<FormEditorFieldValues>();

  const question = useWatch({ control: control, name: 'name' });
  const required = useWatch({ control: control, name: 'isRequired' });
  const multiline = useWatch({ control: control, name: 'isMultiline' });

  const sampleControl = (
    <TextField variant='standard' disabled multiline={multiline} rows={4} placeholder={formatMessage({ id: 'Text answer' })} required={required} />
  );

  return props.isActive ? <>
    <Stack p={2} pb={0} gap={1}>
      <Controller
        name='name'
        control={control}
        rules={{ validate: notEmpty }}
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            autoFocus
            fullWidth
            required
            error={Boolean(error)}
            helperText={error?.message}
            label={formatMessage({ id: 'Question' })}
          />
        )}
      />

      {sampleControl}

      <Controller
        control={control}
        name='isMultiline'
        render={({ field }) => (
          <FormControlLabel
            control={<Switch checked={field.value} onChange={(_, checked) => field.onChange(checked)} />}
            label={formatMessage({ id: 'Multiline' })}
            labelPlacement='start'
          />
        )}
      />
    </Stack>
  </> : (
    <Stack p={2}>
      <FormFieldLabel required={required} italic={!question}>
        {question || formatMessage({ id: 'No name set' })}
      </FormFieldLabel>

      {sampleControl}
    </Stack>
  );
};