import { useMemo } from 'react';
import { FormattedNumber } from 'react-intl';

const units = ['byte', 'kilobyte', 'megabyte'];

export const FormattedFileSize = (props: { bytes: number }) => {

  const { fileSize, unit } = useMemo(() => {
    let fileSize = props.bytes;
    let sizeIndex = 0;
    while (fileSize >= 1024) {
      fileSize /= 1024;
      sizeIndex++;
    }

    return { fileSize, unit: units[sizeIndex] };
  }, [props.bytes]);

  return (<FormattedNumber value={fileSize} style='unit' maximumFractionDigits={0} unit={unit} />);
};