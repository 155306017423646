import { Typography } from '@mui/material';
import React from 'react';
import { Property } from './Property';

interface Props {
  propertyName: string,
  propertyValue: string | undefined | null,
  emptyText?: string;
}

export const TextProperty: React.FC<Props> = ({ propertyName, propertyValue, emptyText }) => {
  return (
    <Property propertyName={propertyName} emptyState={emptyText}>
      {propertyValue && (
        <Typography>
          {propertyValue}
        </Typography>
      )}
    </Property>
  );
};