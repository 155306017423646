import { initializeFromLocalStorage } from '@modules/application/atoms';
import { atom } from 'recoil';

const projectNavigationMenuCollapsedTipDismissedKey = 'wasProjectNavigationMenuCollapsedTipDismissedState';
const projectNavigationMenuCollapsedKey = 'isProjectNavigationMenuCollapsedState';

export const isProjectNavigationMenuCollapsedState = atom<boolean>({
  key: projectNavigationMenuCollapsedKey,
  default: false,
  effects: [
    initializeFromLocalStorage(projectNavigationMenuCollapsedKey),
  ]
});

export const wasProjectNavigationMenuCollapsedTipDismissedState = atom<boolean>({
  key: projectNavigationMenuCollapsedTipDismissedKey,
  default: false,
  effects: [
    initializeFromLocalStorage(projectNavigationMenuCollapsedTipDismissedKey),
  ]
});

export const isProjectNavigationMenuOpenState = atom<boolean>({
  key: 'isProjectNavigationMenuOpenState',
  default: false,
});