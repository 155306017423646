import { DragDropContext, OnDragEndResponder } from '@hello-pangea/dnd';
import { Info } from '@mui/icons-material';
import { Alert, Container, Stack } from '@mui/material';
import { notNullOrUndefined } from '@utils/notNullOrUndefined';
import { useGetMe } from '@utils/useGetMe';
import { WorkflowDefinitionFullFragmentFragment, useWorkflowActionDefinitionsQuery } from 'gql/index';
import _ from 'lodash';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { ActionStaticSidePanel } from './Action/ActionConfigurationPanel/ActionStaticSidePanel';
import { DroppableWorkflowAction } from './Action/DroppableWorkflowAction';
import { emitChildActionAddRequested, emitChildActionMoved } from './events';
import { WorkflowEditorDroppableIds, WorkflowEditorDroppableTypes, WorkflowEditorValues } from './types';
import { mapActionFragmentToForm } from './utils';
import { WorkflowEditorContextProvider } from './WorkflowEditorContext';
import { WorkflowEditorMainAlert } from './WorkflowEditorMainAlert';
import { WorkflowEditorSidePanel } from './WorkflowEditorSidePanel';
import { WorkflowTitleSection } from './WorkflowTitleSection';


interface Props {
  definition: WorkflowDefinitionFullFragmentFragment;
  isLoading: boolean;
}

const defaultValues: WorkflowEditorValues = {
  id: 0,
  actions: [],
  name: '',
  description: '',
  variables: []
};


export const WorkflowEditor: React.FC<Props> = ({ isLoading, definition }) => {
  const { me } = useGetMe();
  const { formatMessage } = useIntl();

  const canEdit = definition.createdBy?.id === me?.id;

  const form = useForm<WorkflowEditorValues>({ mode: 'onBlur', defaultValues });
  const { data: actionDefinitions } = useWorkflowActionDefinitionsQuery(undefined, { select: d => d.workflowActionDefinitions });

  useEffect(() => {
    if (definition != null && actionDefinitions != null) {
      const defaultValues: WorkflowEditorValues = {
        id: definition.id,
        name: definition.name,
        description: definition.description ?? '',
        actions: _.orderBy(definition.rootAction?.childActions, p => p.order).map(action => {
          const actionDefinition = actionDefinitions.find(p => p.actionDefinitionId == action.workflowActionDefinitionId);
          return actionDefinition ? mapActionFragmentToForm(action, actionDefinition) : null;
        })?.filter(notNullOrUndefined) ?? [],
        variables: []
      };

      form.reset(defaultValues, { keepDefaultValues: false });
    }
  }, [form, definition, actionDefinitions]);

  const { getValues } = form;


  const onDragEnd: OnDragEndResponder = ({ source, destination, type }) => {

    if (!destination || definition?.rootAction?.id == null) return;

    if (type === WorkflowEditorDroppableTypes.step) {
      if (source.droppableId === WorkflowEditorDroppableIds.actionsSelectionPanel) {
        const actionDefinition = actionDefinitions?.at(source.index);

        if (!actionDefinition) return;

        return emitChildActionAddRequested({
          actionDefinitionId: actionDefinition.actionDefinitionId,
          destinationId: destination.droppableId,
          createdAtIndex: destination.index,
          parentActionConfigurationId: definition.rootAction.id
        });
      }

      const actions = getValues('actions');
      const action = actions?.at(source.index);

      if (!action) return;

      emitChildActionMoved({
        movedActionConfigurationId: action.id,
        sourceIndex: source.index,
        destinationIndex: destination.index,
        parentActionConfigurationId: definition.rootAction.id
      });
    }
  };

  return (
    <Stack overflow='auto' pb={20}>
      <DragDropContext onDragEnd={onDragEnd}>

        <FormProvider {...form}>
          <WorkflowEditorContextProvider disabled={isLoading || !canEdit} workflowDefinition={definition}>
            <Stack direction={{ xs: 'column', md: 'row', }}>
              <WorkflowEditorSidePanel />

              <ActionStaticSidePanel />

              <Container maxWidth='md' sx={{ pt: 2, flexGrow: 1 }}>
                {isLoading ? <></> : (
                  <form noValidate autoComplete='off'>
                    <input type='submit' disabled style={{ display: 'none' }} />

                    <Stack gap={2}>
                      {!canEdit && (
                        <Alert severity='info' icon={<Info />} sx={{ mb: 2 }}>
                          {formatMessage({ id: 'You cannot edit workflow templates created by another user.' })}
                        </Alert>
                      )}

                      <WorkflowTitleSection />
                      <WorkflowEditorMainAlert />
                      <Stack mt={2}>
                        <DroppableWorkflowAction />
                      </Stack>
                    </Stack>
                  </form>
                )}
              </Container>
            </Stack>

          </WorkflowEditorContextProvider>
        </FormProvider>
      </DragDropContext>
    </Stack>
  );
};