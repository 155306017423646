import { LoadingButton } from '@mui/lab';
import { Button, Stack, TextField, Typography } from '@mui/material';
import { useNotification } from '@utils/useNotification';
import { useValidationRules } from '@utils/useValidationRules';
import { useStartTrialMutation } from 'gql/index';
import { Controller, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { Link, useNavigate } from 'react-router-dom';
import { useAffiliateTracking } from '../useAffiliateTracking';

interface TrialFormValues {
  firstName: string;
  lastName: string;
  email: string;

  tenantName: string;
}

const defaultValues: TrialFormValues = {
  firstName: '',
  lastName: '',
  email: '',

  tenantName: '',
};

export const TrialPage: React.FC = () => {
  const { formatMessage } = useIntl();
  const { validateEmail, notEmpty } = useValidationRules();
  const { notifySuccess } = useNotification();
  const navigate = useNavigate();
  const [referralId] = useAffiliateTracking();

  const { mutate: startTrial, isLoading } = useStartTrialMutation({
    onSuccess: () => {
      notifySuccess(formatMessage({ id: 'Your organization is ready to use, please sign in.' }));
      navigate('/');
    }
  });

  const { control, handleSubmit } = useForm<TrialFormValues>({ defaultValues });

  const onSubmit = (values: TrialFormValues) => {
    startTrial({
      input: {
        adminInput: {
          email: values.email,
          firstName: values.firstName,
          lastName: values.lastName,
          language: 'fr',
        },
        tenantName: values.tenantName,
        referralId: referralId,
      }
    });
  };

  const disabled = isLoading;

  return <>
    <Stack flexGrow={1} spacing={2} pt={4} sx={{ backgroundColor: 'background.paper' }}>
      <Stack gap={1} alignItems='center' px={4} overflow='hidden'>
        <Typography variant='h3' color='primary.main' noWrap>
          {formatMessage({ id: 'Start your trial' })}
        </Typography>
      </Stack>

      <Stack flex={1} gap={2} height='100%' alignItems='center' px={4} py={2}>
        <Stack direction='row' gap={1} width='100%'>
          <Controller
            name='firstName'
            control={control}
            rules={{ validate: { notEmpty } }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                required
                disabled={disabled}
                label={formatMessage({ id: 'First name' })}
                variant='outlined'
                fullWidth
                error={!!error}
                helperText={error?.message}
              />
            )}
          />

          <Controller
            name='lastName'
            control={control}
            rules={{ validate: { notEmpty } }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                required
                disabled={disabled}
                label={formatMessage({ id: 'Last name' })}
                variant='outlined'
                fullWidth
                error={!!error}
                helperText={error?.message}
              />
            )}
          />
        </Stack>

        <Controller
          name='email'
          control={control}
          rules={{ validate: { notEmpty, validateEmail } }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              required
              disabled={disabled}
              label={formatMessage({ id: 'Email' })}
              variant='outlined'
              fullWidth
              error={!!error}
              helperText={error?.message}
            />
          )}
        />


        <Controller
          name='tenantName'
          control={control}
          rules={{ validate: { notEmpty } }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              required
              disabled={disabled}
              label={formatMessage({ id: 'Organization name' })}
              variant='outlined'
              fullWidth
              error={!!error}
              helperText={error?.message}
            />
          )}
        />

        <LoadingButton loading={isLoading} variant='contained' onClick={handleSubmit(onSubmit)}>
          {formatMessage({ id: 'Create trial account' })}
        </LoadingButton>

        <Stack flexGrow={1} justifyContent='end' pb={4}>
          <Stack direction='row' gap={1} alignItems='center' flexWrap='wrap' justifyContent='center'>
            <Typography>
              {formatMessage({ id: 'Already have an account?' })}
            </Typography>

            <Link to='..'>
              <Button variant='text' size='small'>
                {formatMessage({ id: 'Go to login' })}
              </Button>
            </Link>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  </>;
};