import { DesktopDatePicker } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import { Controller, useFormContext } from 'react-hook-form';
import { FormFillerValues } from '../../FormFiller/types';
import { FieldViewerProps } from './FieldViewer';

export const DateViewer: React.FC<FieldViewerProps> = ({ field, index }) => {
  const { control } = useFormContext<FormFillerValues>();

  return (
    <Controller
      control={control}
      name={`values.${index}.date`}
      render={({ field: { value } }) => (
        <DesktopDatePicker<Dayjs>
          value={value ? dayjs(value) : null}
          disableOpenPicker
          selectedSections={null}
          label={field.name}
          disabled
        />
      )}
    />
  );
};