import { CalendarMonth, Description, People, Task, ViewList } from '@mui/icons-material';
import { Container, Grid, ListItem, ListItemIcon, ListItemText, Skeleton, styled } from '@mui/material';
import { PageTitle } from '../../../components/Layout/PageTitle';
import { PageSection } from '../../../components/PageSection/PageSection';
import { useResponsive } from '../../../utils/useResponsive';

interface AnonymousProjectPreviewProps {
  projectName: string;
  isLoading: boolean;
}

const squareSkeleton = <Skeleton height={64} width={64} animation={false} />;

const primarySkeleton = <Skeleton height={24} width='80%' animation={false} />;
const secondarySkeleton = <Skeleton height={24} width='40%' animation={false} />;

const PageListItem = styled(ListItem)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  borderBottomWidth: 0,
  ':last-child': {
    borderBottomWidth: 1
  }
}));

const listItemSkeleton = (
  <PageListItem>
    <ListItemIcon sx={{ pr: 2 }}>
      {squareSkeleton}
    </ListItemIcon>

    <ListItemText
      primary={primarySkeleton}
      secondary={secondarySkeleton}
    />
  </PageListItem>
);

export const AnonymousProjectPreview = (props: AnonymousProjectPreviewProps) => {
  const { isMobile } = useResponsive();
  return (<>
    <PageTitle
      title={props.projectName}
      pageLoading={props.isLoading}
    />

    <div style={{ position: 'relative', overflow: 'hidden' }}>
      {!isMobile && (
        <Container style={{ overflow: 'hidden', height: '100%' }}>
          <Grid container>
            <Grid item xs={12} md={8}>
              <PageSection icon={<Task />} title={'Tasks'}>
                {listItemSkeleton}
                {listItemSkeleton}
                {listItemSkeleton}
              </PageSection>

              <PageSection icon={<Description />} title='Shared Documents'>
                {listItemSkeleton}
                {listItemSkeleton}
                {listItemSkeleton}
              </PageSection>

              <PageSection icon={<ViewList />} title='Forms'>
                {listItemSkeleton}
                {listItemSkeleton}
                {listItemSkeleton}
              </PageSection>
            </Grid>

            <Grid item xs={12} md={4}>
              <PageSection icon={<CalendarMonth />} title='Upcoming Events'>
                {listItemSkeleton}
                {listItemSkeleton}
              </PageSection>

              <PageSection icon={<People />} title='Members'>
                {listItemSkeleton}
                {listItemSkeleton}
              </PageSection>
            </Grid>
          </Grid>
        </Container>
      )}
    </div>
  </>);
};