import { alpha, ListItemButton, ListItemButtonProps, ListItemIcon, ListItemText, styled, useTheme } from '@mui/material';
import React from 'react';

const StyledListItemButton = styled(ListItemButton)<ListItemButtonProps>(({ theme }) => ({
  color: theme.palette.text.secondary,
  'svg': {
    color: theme.palette.text.secondary
  },
  '&:hover': {
    background: alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
  },
  '.active &, .active &:hover': {
    background: alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
    borderRight: `4px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
    'svg': {
      color: theme.palette.primary.main
    },
  },
  '.collapsed & .MuiListItemText-primary': {
    visibility: 'hidden',
  },
  '& .MuiListItemIcon-root': {
    minWidth: '40px'
  }
}));

interface OwnProps {
  icon?: React.ReactNode;
  title?: string;
  children?: React.ReactNode;
  collapsed?: boolean;
}

export type NavigationMenuItemProps =
  & ListItemButtonProps
  & OwnProps;

export const NavigationMenuItem: React.FC<NavigationMenuItemProps> = (props) => {
  const { icon, title, children, collapsed, ...listItemProps } = props;
  const { palette } = useTheme();

  return (
    <StyledListItemButton {...listItemProps} color={palette.primary.light}>
      <ListItemIcon color='inherit'>{icon}</ListItemIcon>
      <ListItemText primary={title}
        sx={{ opacity: collapsed ? 0 : 1 }} />
      {children}
    </StyledListItemButton>
  );
};