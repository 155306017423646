import { TrendingUp } from '@mui/icons-material';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { FolderEntryFragmentFragment } from 'gql/index';
import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import { ProjectDocumentsContext } from './ProjectDocumentsContextProvider';

interface Props {
  entry: FolderEntryFragmentFragment;
  setMoreMenuDrawerOpen?: (open: boolean) => void;
}

export const FolderEntryMoveActions: React.FC<Props> = ({ entry, setMoreMenuDrawerOpen }) => {
  const { formatMessage } = useIntl();

  const { movingEntry, movingFolder, setMovingEntry } = useContext(ProjectDocumentsContext);

  const onMoveBeginClicked = () => {
    setMovingEntry?.(entry);
    setMoreMenuDrawerOpen?.(false);
  };

  const isMoving = movingEntry != null || movingFolder != null;

  if (!setMovingEntry) return;

  return <>
    {!isMoving && (
      <MenuItem onClick={onMoveBeginClicked}>
        <ListItemIcon><TrendingUp /></ListItemIcon>
        <ListItemText primary={formatMessage({ id: 'Move' })} />
      </MenuItem>
    )}
  </>;
};