import { Button, ButtonBaseProps, ButtonPropsSizeOverrides, IconButton } from '@mui/material';
import { OverridableStringUnion } from '@mui/types';
import { useResponsive } from '@utils/useResponsive';
import { SquareIconButton } from './ActionMenu';

type ButtonProps = Pick<ButtonBaseProps, 'disabled' | 'children' | 'onClick'>;

type ButtonColor = 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';

type ButtonTypes = 'button' | 'icon';

interface Props extends ButtonProps {
  icon?: React.ReactNode;
  iconPosition?: 'start' | 'end';
  variant?: 'text' | 'outlined' | 'contained';
  color?: ButtonColor | Partial<Record<ButtonTypes, ButtonColor>>;
  size?: OverridableStringUnion<'small' | 'medium' | 'large', ButtonPropsSizeOverrides>;
}

export const ResponsiveButton: React.FC<Props> = ({ icon, iconPosition, size, variant, color, ...buttonBaseProps }) => {
  const { isMobile } = useResponsive();

  const getColor = (variant: ButtonTypes) =>
    typeof color === 'string' ? color : color?.[variant];

  if (isMobile && (variant === 'contained' || variant === 'outlined')) {
    return (
      <SquareIconButton
        variant={variant}
        endIcon={icon}
        size={size ?? 'medium'}
        color={getColor('icon')}
        sx={{ minWidth: 'unset', p: 1 }}
        {...buttonBaseProps}
        children={undefined}
      />
    );
  }

  if (isMobile && icon) {
    return (
      <IconButton color={getColor('icon')} {...buttonBaseProps}>
        {icon}
      </IconButton>
    );
  }

  return (
    <Button
      startIcon={(iconPosition === 'start' || !iconPosition) && icon}
      endIcon={iconPosition === 'end' && icon}
      variant={variant}
      size={size ?? 'medium'}
      color={getColor('button')}
      {...buttonBaseProps}
    />
  );
};