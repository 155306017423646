import { EmptyState } from '@components/EmptyState';
import { useFolderName } from '@modules/folders/utils/folderUtils';
import { useCurrentProject } from '@modules/projects/utils/useCurrentProject';
import { RichTreeView } from '@mui/x-tree-view/RichTreeView';
import { TreeViewBaseItem } from '@mui/x-tree-view/models';
import { useFilePickerDocumentsQuery, useProjectFoldersQuery } from 'gql/index';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { buildTreeItemHierarchy } from './types';

interface Props {
  selectedDocumentId: number | undefined | '';
  onDocumentSelected: (documentId: number | undefined) => void;
}

export const FilePicker: React.FC<Props> = ({ selectedDocumentId, onDocumentSelected }) => {
  const { projectId } = useCurrentProject();
  const { data: documents, isLoading: isLoadingDocuments } = useFilePickerDocumentsQuery({ filter: { fileName: { endsWith: '.pdf' } }, projectId: projectId }, { select: p => p.projectDocuments });
  const { data: folders, isLoading: isLoadingFolders } = useProjectFoldersQuery({ projectId: projectId }, { select: p => p.projectFolders });
  const isLoading = isLoadingDocuments || isLoadingFolders;
  const { formatMessage } = useIntl();
  const { getFolderName } = useFolderName();
  const structuredDocuments = useMemo<TreeViewBaseItem[]>(
    () =>
      buildTreeItemHierarchy(documents ?? [],
        folders ?? [],
        document => document.id.toString(),
        document => document.fileName,
        folder => folder.id.toString(),
        folder => getFolderName(folder) ?? '',
        item => item.folderEntry?.folder?.path ?? ''),
    [documents, folders, getFolderName]);

  if (!isLoading && structuredDocuments.length == 0) {
    return <EmptyState
      title={formatMessage({ id: 'There are no documents available in this project.' })}
    />;
  }
  return (
    <RichTreeView
      items={structuredDocuments}
      selectedItems={selectedDocumentId?.toString()}
      defaultExpandedItems={[structuredDocuments.at(0)?.id ?? '']}
      onSelectedItemsChange={(_, itemIds) => {
        if (itemIds != null && !itemIds.startsWith('parent')) {
          onDocumentSelected(Number(itemIds));
        } else {
          onDocumentSelected(undefined);
        }
      }} />
  );
};