import { EditorTopBar } from '@components/Editor/TopBar/EditorTopBar';
import { SavingStatusContextProvider } from '@components/Editor/TopBar/SavingStatusContext';
import { TemplateTitle } from '@components/Editor/TopBar/TemplateTitle';
import { FormDefinitionFragmentFragment, FormTemplateFragmentFragment } from 'gql/index';
import React from 'react';
import { FormEditor } from '../../../components/FormEditor/FormEditor';
import { FormEditorPreviewDrawer } from '../../../components/FormEditorPreviewDrawer';
import { FormTemplateActions } from '../../../components/FormTemplateActions';

interface Props {
  template: FormTemplateFragmentFragment;
  definition: FormDefinitionFragmentFragment;
}

export const FormTemplateEditorView: React.FC<Props> = ({ template, definition }) => {
  const [isPreviewing, setPreviewing] = React.useState(false);

  return (
    <SavingStatusContextProvider isEditorLoading={false}>
      <EditorTopBar
        title={<TemplateTitle title={definition.name} />}
        topBarActions={template ? (
          <FormTemplateActions template={template} onPreviewClick={() => setPreviewing(true)} />
        ) : undefined}
      />

      <FormEditor
        formContent={definition}
      />

      <FormEditorPreviewDrawer
        open={isPreviewing}
        onClose={() => setPreviewing(false)}
        templateId={template.id}
      />
    </SavingStatusContextProvider>
  );
};