import { useGetMe } from '@utils/useGetMe';
import { BuiltInRoles, useSecurityGroupForUserInProjectQuery } from 'gql/index';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { useCurrentProject } from './useCurrentProject';

type ErrorMessage = string;

type Result = true | 'doNotShow' | ErrorMessage;

type CanEditUserResult = {
  canEditUserDetails: Result;
  canEditSecurityGroup: Result;
  canEditApplicationRole: Result;
};

export const useCanEditUser = (userId: string): CanEditUserResult => {
  const { projectId: projectIdString } = useParams();
  const projectId = Number(projectIdString) || undefined;

  const { formatMessage } = useIntl();

  const { me, hasTenantAdminRole } = useGetMe();

  const { data: securityGroups } = useSecurityGroupForUserInProjectQuery({ projectId: projectId ?? 0, userId }, {
    select: d => d.securityGroupForUserInProject,
    enabled: !!projectId,
  });

  const { canManageProject } = useCurrentProject({ disabled: !projectId });

  if (!projectId) {
    if (!hasTenantAdminRole) {
      return {
        canEditUserDetails: formatMessage({ id: 'Only tenant admins can edit user details' }),
        canEditSecurityGroup: 'doNotShow',
        canEditApplicationRole: 'doNotShow'
      };
    }

    return {
      canEditUserDetails: true,
      canEditSecurityGroup: 'doNotShow',
      canEditApplicationRole: userId === me?.id ? formatMessage({ id: 'As a tenant administrator, you cannot update your role' }) : true
    };
  }

  if (!canManageProject) {
    return {
      canEditUserDetails: 'doNotShow',
      canEditSecurityGroup: 'doNotShow',
      canEditApplicationRole: 'doNotShow'
    };
  }

  if (userId === me?.id) {
    return {
      canEditUserDetails: 'doNotShow',
      canEditSecurityGroup: formatMessage({ id: 'As a project administrator, you cannot update your security group' }),
      canEditApplicationRole: 'doNotShow'
    };
  }

  const isExternalUser = securityGroups?.some(s => s.roles.some(r => r.name === BuiltInRoles.LimitedAccess));
  if (!isExternalUser) {
    return {
      canEditUserDetails: formatMessage({ id: 'You cannot edit other project admins, only external users.' }),
      canEditSecurityGroup: true,
      canEditApplicationRole: 'doNotShow'
    };
  }

  return {
    canEditUserDetails: true,
    canEditSecurityGroup: true,
    canEditApplicationRole: 'doNotShow'
  };
};