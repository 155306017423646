import { ApplicationUser, BuiltInRoles } from 'gql/index';

export const getInitials = (displayName: string): string => {
  const firstLettersOfEachWords = displayName
    .replaceAll(/[^a-zA-ZÀ-ž0-9 ]/g, '')
    .split(' ')
    .map(word => word[0]);

  if (firstLettersOfEachWords.length > 1) {
    return firstLettersOfEachWords[0] + firstLettersOfEachWords.at(-1) || '';
  }

  return firstLettersOfEachWords[0] || '';
};

const getHashOfString = (str: string) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  hash = Math.abs(hash);
  return hash;
};

const normalizeHash = (hash: number, min: number, max: number) => {
  return Math.floor((hash % (max - min)) + min);
};

const generateHSL = (name: string, saturationRange: [number, number], lightnessRange: [number, number]): [number, number, number] => {
  const hash = getHashOfString(name);
  const h = normalizeHash(hash, 0, 360);
  const s = normalizeHash(hash, saturationRange[0], saturationRange[1]);
  const l = normalizeHash(hash, lightnessRange[0], lightnessRange[1]);
  return [h, s, l];
};

const HSLtoString = ([h, s, l]: [number, number, number]): `hsl(${number}, ${number}%, ${number}%)` => {
  return `hsl(${h}, ${s}%, ${l}%)`;
};

export const getAvatarColor = (id: string) => {
  return HSLtoString(generateHSL(id, [40, 45], [45, 55]));
};

export const isExternalUser = (user: Pick<ApplicationUser, 'builtInRoles'>) => user.builtInRoles.includes(BuiltInRoles.LimitedAccess);