import { useErrorHandler } from '../../../modules/application/useErrorHandler';
import { EditorSavingStatus, SavingStatusContext } from './SavingStatusContext';
import { useCallback, useContext } from 'react';

export const useEditorHelpers = () => {
  const { handleError } = useErrorHandler();
  const { setEditorSavingStatus } = useContext(SavingStatusContext);

  const onEditionError = useCallback((error: unknown) => {
    setEditorSavingStatus(EditorSavingStatus.Failed);
    handleError(error);
  }, [handleError, setEditorSavingStatus]);

  const onEditionSuccess = useCallback(() => {
    setEditorSavingStatus(EditorSavingStatus.Saved);
  }, [setEditorSavingStatus]);

  return { onEditionError, onEditionSuccess };
};