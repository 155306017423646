import { SavingStatusContext } from '@components/Editor/TopBar/SavingStatusContext';
import { Add } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Stack } from '@mui/material';
import { useQueryInvalidator } from '@utils/useQueryInvalidator';
import { WorkflowVariableValueTypes, useAddWorkflowVariableMutation, useWorkflowTemplateQuery } from 'gql/index';
import { useContext } from 'react';
import { useIntl } from 'react-intl';
import { WorkflowRolesTable } from '../Roles/WorkflowRolesTable';
import { WorkflowEditorContext } from '../WorkflowEditorContext';

export const WorkflowRolesPanel: React.FC = () => {
  const { formatMessage } = useIntl();
  const invalidateQuery = useQueryInvalidator();

  const { workflowDefinition: workflowTemplate, disabled } = useContext(WorkflowEditorContext);
  const { isEditorLoading } = useContext(SavingStatusContext);

  const { mutate: addWorkflowVariable, isLoading } = useAddWorkflowVariableMutation({
    onSuccess: () => {
      invalidateQuery(useWorkflowTemplateQuery, { id: workflowTemplate.id });
    }
  });

  const onAddClicked = () => {
    addWorkflowVariable({
      input: {
        name: formatMessage({ id: 'New role' }),
        workflowDefinitionId: workflowTemplate.id,
        valueType: WorkflowVariableValueTypes.User
      }
    });
  };

  const loading = isEditorLoading || isLoading;

  return (
    <>
      <Box style={{ height: '100%' }} mt={1}>
        <WorkflowRolesTable
          disabled={disabled}
          variant='standard'
          paperProps={{ sx: { backgroundImage: 'none', borderRight: 'none', borderLeft: 'none' } }}
          roles={workflowTemplate.variables}
          height='100%'

        />

        {!disabled && (
          <Stack p={2}>
            <LoadingButton loading={loading} startIcon={<Add />} fullWidth variant='contained' onClick={onAddClicked}>
              {formatMessage({ id: 'Add role' })}
            </LoadingButton>
          </Stack>
        )}
      </Box>
    </>
  );
};